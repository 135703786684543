import React, { useContext } from "react";
import { useQueryClient, useMutation } from 'react-query';
import ToastMessage from "../../../components/ToastMessage";
import { PortalizedModalContext } from "../../../contexts/portalizedModalContext";
import { requestKycAML, manuallyApproveKycAML } from "./queries";
import EntityDetailsForm from "../../components/EntityDetailsForm";
import InvestorDetailsForm from "../../components/InvestorDetailsForm";
import LoadingOverlay from "react-loading-overlay";

export default function ActionButtons({ amlException }) {
  const queryClient = useQueryClient();
  const updateKycAmlModel = useContext(PortalizedModalContext);
  const updateClient = useContext(PortalizedModalContext);

  const requestKycAMLmutation = useMutation(() => requestKycAML(amlException.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["aml_exception"], { exact: false });
        ToastMessage.success('KYC/AML successfully re-requested');
      },
      onError: (error, variables, context) => {
        ToastMessage.error(
          error?.error || 'Something went wrong. Please try after sometime.'
        );
      },
      onSettled: updateKycAmlModel.closeModal
    }
  );

  const manuallyApproveKycAMLMutation = useMutation(() => manuallyApproveKycAML(amlException.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["aml_exception"], { exact: false });
        ToastMessage.success('KYC/AML successfully manually approved.');
      },
      onError: (error, variables, context) => {
        ToastMessage.error('Something went wrong. Please try after sometime.');
      },
      onSettled: updateKycAmlModel.closeModal
    }
  );

  const updateKycAmlHandler = ({ mutation, message, description }) => {
    updateKycAmlModel.setModalContent(
      message,
      <>
        <p>Are you sure you want to {message}?</p>
        <p>{description}</p>
        <div className="portalized-modal-footer">
          <button
            disabled={mutation.isLoading}
            className="btn-black"
            onClick={() => { mutation.mutate(); updateKycAmlModel.closeModal() }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => { updateKycAmlModel.closeModal() }}
          >
            Close
          </button>
        </div>
      </>,
      "sm"
    );
    updateKycAmlModel.openModal();
  };

  const onClientUpdateSubmit = () => {
    queryClient.invalidateQueries(["aml_exception"], { exact: false });
    queryClient.invalidateQueries(["client_audit_logs", amlException.client_id]);
    updateClient.closeModal();
  }

  const updateClientHandler = ({ client }) => {
    if (['company', 'trust'].indexOf(client.entity_type) > -1) {
      updateClient.setModalContent(
        `Entity Details`,
        <EntityDetailsForm
          submitButtonText="Update"
          entityDetail={client}
          userScope='admin'
          onSubmit={onClientUpdateSubmit} />,
        'md'
      );
    } else if (client.entity_type === 'person') {
      updateClient.setModalContent(
        'Individual Investor Details',
        <InvestorDetailsForm
          submitButtonText="Update"
          investorDetails={client}
          userScope='admin'
          onSubmit={onClientUpdateSubmit}
        />,
        'md'
      );
    }
    updateClient.openModal()
  };

  const requestKycAMLDesc = "Cost to use this method for Company/Entity client is $75 and for Individual client is $0.75 each time.";
  const requestKycAMLBtnText = amlException.client.entity_type === "person" ? 'Request Auto Check' : 'Request Manual Review';
  const loading = requestKycAMLmutation.isLoading || manuallyApproveKycAMLMutation.isLoading;

  return (
 <LoadingOverlay active={loading} spinner text="Loading">
    <div className="w-100 text-right">
      <button
        onClick={() => updateClientHandler({ client: amlException.client })}
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
      >
        Edit { amlException.client.entity_type === 'person' ? 'Investor' : 'Entity' }
      </button>
      <button
        disabled={requestKycAMLmutation.isLoading}
        onClick={() => updateKycAmlHandler({
          mutation: requestKycAMLmutation,
          message: requestKycAMLBtnText,
          description: requestKycAMLDesc
        })}
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
      >
        {requestKycAMLBtnText}
      </button>
      <button
        disabled={manuallyApproveKycAMLMutation.isLoading}
        onClick={() => updateKycAmlHandler({ mutation: manuallyApproveKycAMLMutation, message: 'Manually approve KYC/AML' })}
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
      >
        Manually Approve
      </button>
    </div>
  </LoadingOverlay>
  )
}
