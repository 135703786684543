import React from 'react';
import { MdClose } from 'react-icons/md';
import "./OverviewHeader.scss";

export default function EditHeader({left, right}) {
  return(
    <div className="overview-header" style={{backgroundColor: '#000'}}>
      <div className="row align-items-center">
        <div className="col-sm-6 overview-header-left" style={{color: '#fff'}}>
          <h5 className="weight-700 mb-0 fs-9 montserrat">{left}</h5>
        </div>
        <div className="col-sm-6 overview-header-right mb-md-0 mb-3">
          {right}
        </div>
      </div>
    </div>
  );
}
