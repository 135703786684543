import { useQuery } from "react-query";
import axiosClient from "config/axiosClient";

const useAmlList = ({ page = 1, per_page = 10, searchStatus, searchQuery }) => {
  const searchParams = new URLSearchParams({
    page: page,
    per_page: per_page,
   });

  if(searchStatus){
    searchParams.append('status', searchStatus);
  }

  if(searchQuery){
    searchParams.append('q', searchQuery);
  }

  return useQuery(
    ["aml_exceptions", page, searchStatus, searchQuery],
    () => {
      return axiosClient.get(
        `/admin/aml_exceptions?${searchParams}`
      );
    },
    {
      keepPreviousData: true,
    }
  );
};

const useExceptionDetails = (clientId) => {
  // Parse int due to sometime clientId get from the url params.
  return useQuery(["aml_exception", parseInt(clientId)], () => {
    return axiosClient.get(`clients/${clientId}/aml_exception`);
  });
};

const updateDocumentStatus = ({ documentId, values }) => {
  return axiosClient.put(`/entity_documents/${documentId}`, values);
};

const useClientInvestments = (clientId) => {
  return useQuery(["client_investments", clientId], () => {
    return axiosClient.get(`/admin/clients/${clientId}/investments/`);
  });
};

const updateVerificationField = ({amlExceptionId, values}) => {
  return axiosClient.put(
    `/admin/aml_exceptions/${amlExceptionId}/verification_field`,
    { verification_field: values}
  );
}

const requestKycAML = (amlExceptionId) => {
  return axiosClient.put(
    `/admin/aml_exceptions/${amlExceptionId}/request_kyc_aml`
  );
}

const manuallyApproveKycAML = (amlExceptionId) => {
  return axiosClient.put(
    `/admin/aml_exceptions/${amlExceptionId}/manually_approve`,
  );
}

export {
  useAmlList,
  useExceptionDetails,
  updateDocumentStatus,
  useClientInvestments,
  updateVerificationField,
  requestKycAML,
  manuallyApproveKycAML
};
