import React from 'react';
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, } from 'react-query';
import ToastMessage from '../../../../../components/ToastMessage';
import HookForm from 'components/HookForm';
import { userApi } from '../../../../../api/user';
import { useUser, updateUser } from 'redux/user';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { updateUserDetail } from '../../../../../redux/user';

export default function UpdateEmailForm({ onSubmit = null }) {
  const user = useUser();
  const FormSchema = Yup.object().shape({
    new_email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .required('Required')
  });
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      new_email: '',
      password: ''
    }
  });

  const changeEmail = useMutation(
    userApi.changeEmail,
    {
      onSuccess: (values) => {
        ToastMessage.success(
          `Confirmation link sent to new email.`
        );
        onSubmit();
        updateUserDetail(values);
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  );

  const handleSubmitSubmit = data => changeEmail.mutate(data);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitSubmit)} className="mt-1">
        <div className="row mt-3">
          <div className="col input-bg-white">
            <HookForm.TextInput
              name="new_email"
              placeholder="New Email"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col input-bg-white">
            <HookForm.TextInput
              type="password"
              name="password"
              placeholder="Current Password"
            />
          </div>
        </div>
        <div className="form-group mt-3 d-flex flex-wrap align-items-center">
          <button type="submit"
            className="mr-3 weight-300 btn btn-outline-dark rounded-0"
            disabled={changeEmail.isLoading}
          >
            Update
          </button>
        </div>
      </form>
    </FormProvider>
  );
}