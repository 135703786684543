import React from "react";
import { useUser } from 'redux/user';
import { toTitlecase } from "../../../../jsUtils/stringUtils";
import {  ApprovdeBadge, DisapprovedBadge, PendingBadge, NeedsMoreInfo } from "../AmlException/Badges";

function DocumentInsturctionList({ client }) {
  return <>
    <div className="my-2">Upload 1 of the below documents to verify the individual accreditation for {client.name}:</div>
    <div className="my-3 p-3 bg-black border-radius-2">
      <b className="fs-default weight-600 mb-1">Income</b>
      <p className="d-block">
        W2's, 1099's or tax returns for the <b>past two years</b> to show your
        individual income exceeds $200,000 for each year.
      </p>
    </div>
    <div className="my-3 p-3 bg-black border-radius-2">
      <b className="fs-default weight-600 mb-1">Assets</b>
      <p className="d-block">Provide recent financial account statements that show the value of your
        assets exceed $1,000,000 excluding your primary residence. The financial
        account statements must include your name and date (Must be dated within the last 3
        months).
      </p>
      <p className="d-block">
        If you are using physical real estate assets (excluding your primary
        residence), you must provide documentation showing you own the
        property and the value.
      </p>
    </div>
    <div className="my-3 p-3 bg-black border-radius-2">
      <b className="fs-default weight-600 mb-1">Professional Letter</b>
      <p className="d-block">
        Provide an official written communication from any of the following
        stating that the professional service provider has a reasonable belief
        that you are an Accredited Investor. The letter must be on the writer's
        letterhead, include your name and date (Must be dated within the last 3 months):
      </p>
      <ul>
        <li>A licensed CPA</li>
        <li>Attorney</li>
        <li>Investment advisor</li>
        <li>Registered broker-dealer</li>
      </ul>
    </div>
    <div className="my-3 p-3 bg-black border-radius-2">
      <b className="fs-default weight-600 mb-1">Professional Designation</b>
      <p className="d-block">
        Provide your CRD Number to verify you are a licensed individual in good
        standing that holds an active Series 7, Series 65, or Series 82 registration.
      </p>
    </div>
  </>
}

function IndividualExplainerText({ client }) {
  return (
    <div className="aml-instructions-container">
      <div>
        <b className="fs-default">1. Upload Investor Accreditation Verification Document</b>
      </div>
      <DocumentInsturctionList client={client} />
      <div className="text-center fs-18 weight-600 my-3 py-1 separator">AND</div>
      <div>
        <b className="fs-default">2. Upload a piece of valid Government ID for {client.name}</b>
      </div>
    </div>
  )
}

function EntityExplainerText({ client }) {
  const user = useUser();
  const individualClient = user?.clients?.find(client => client.entity_type === 'person');
  const clientTypesText = {
    'company': 'Entity',
    'trust': 'Trust'
  }[client.entity_type]

  return (
    <div className="aml-instructions-container">
      <div>
        <b className="fs-default">1. Upload {clientTypesText} Accreditation Verification Document</b>
      </div>
      <div className="my-2">Upload a document that shows assets over $5,000,000 in {client.name} name:</div>
      <div className="my-3 p-3 bg-black border-radius-2">
        <b className="fs-default weight-600 mb-1">{clientTypesText} Assets</b>
        <p className="d-block">Assets over $5,000,000 in Entity Name: {client.name}</p>
      </div>
      <div className="text-center fs-18 weight-600 my-3 py-1 separator">OR</div>
      <DocumentInsturctionList client={individualClient} />
      <div className="text-center fs-18 weight-600 my-3 py-1 separator">AND</div>
      <div>
        <b className="fs-default">2. Upload a piece of valid Government ID for {individualClient.name}</b>
      </div>
    </div>
  )
}

export default function Instructions({ aiRequest }) {
  const clientType = aiRequest.client.entity_type;
  const { status } = aiRequest;
  const showBadge = status === 'need_more_info' || status === 'pending' || status === 'rejected';
  const clientTypesText = {
    'person': 'Individual',
    'company': 'Entity',
    'trust': 'Trust'
  };

  return (
    <div className='bold-card thick-bordered my-2'>
      <div className="bold-card-header d-flex align-items-center flex-wrap">
        <span className="fs-18 weight-800">{clientTypesText[clientType]} Accredited Verification</span>
        { showBadge && <div>
          {status === 'need_more_info' && <NeedsMoreInfo />}
          {status === 'pending' && <PendingBadge />}
          {status === 'rejected' && <DisapprovedBadge />}
        </div>}
      </div>
      <div className="bold-card-body">
        <div className="content-layer bg-muted-black">
          {clientType === 'person' && <IndividualExplainerText client={aiRequest.client} />}
          {(clientType === 'company' || clientType === 'trust') && <EntityExplainerText client={aiRequest.client} />}
        </div>
      </div>
    </div>
  );
}
