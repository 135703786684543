import React, { useEffect, useState } from "react";
import { userApi } from "../../../../api/user";
import "./MyInvestments.scss";
import LoadingOverlay from "react-loading-overlay";
import { CustomScrollSpy } from "../../../../admin/components/shared/CustomScrollSpy";
import Deals from "./Deals";
import Prelaunches from "./Prelaunches";
import Secondaries from "./Secondaries";
import Petitions from "./Petitions";


const MyInvestments = () => {
  const settingsSideBarItems = [
    {
      id: "deals",
      key: "portfolio-deals",
      name: "Deals",
      visibility: true,
      component: <Deals key="deals" />,
    },
    {
      id: "prelaunches",
      key: "portfolio-prelaunches",
      name: "Prelaunches",
      visibility: true,
      component: <Prelaunches key="prelaunches" />
    },
    {
      id: "secondaries",
      key: "portfolio-secondaries",
      name: "Secondaries",
      visibility: true,
      component: <Secondaries key="secondaries" />
    },    
    {
      id: "petitions",
      key: "portfolio-petitions",
      name: "Petitions",
      visibility: true,
      component: <Petitions key="petitions" />
    },
  ];

  return (
    <LoadingOverlay active={false} spinner text="Loading...">
      <div className="tab-content zeitung-micro" id="myTabContent">
        <div className="settings-detail-container">
          <section className="main-content">
            <CustomScrollSpy pitchSidebarItems={settingsSideBarItems} />
          </section>
        </div>
      </div>
    </LoadingOverlay>
  )
};

export default MyInvestments;
