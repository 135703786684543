import React from 'react';
import {DEFAULT_PAGE_SIZE} from './../../config/constants';

export default function Paginator({onPage, theme='light', meta, loading = false}) {
  const wrapperClasses = theme === 'light' ? 'pagination-2' : 'amch-bottom bg-white';
  const paginationRecordsClasses = theme === 'light' ? 'border-white' : 'border-dark';
  const pagesBackgoundClasses = theme === 'light' ? 'bg-transparent' : '';

  if (loading) {
    return (
      <div className={wrapperClasses}>
        <nav aria-label="navigation">
          <ul className={`pagination custom-pagination pr-0 mb-0 ${pagesBackgoundClasses}`}>
            <li className="page-item">
              <Page
                p={'gap'}
                meta={{}}
              />
            </li>
            <li className="page-item">
              <Page
                p={'gap'}
                meta={{}}
              />
            </li>
            <li className="page-item">
              <Page
                p={'gap'}
                meta={{}}
              />
            </li>
          </ul>
        </nav>
      </div>
    )
  }
  const renderPageLinks = () => {
    return meta.series?.map(p => (
      <li key={p} className={`page-item ${p == meta.page ? 'active' : ''}`}>
        <Page
          p={p}
          meta={meta}
          onClick={
            e => {
              if (p === 'gap' || p == meta.page) {
                return
              }
              onPage(p);
            }
        }>
          {p === 'gap' ? '...' : p}
        </Page>
      </li>
    ))
  };

  return (
    <div className={wrapperClasses}>
      <span className={`pagination-records-showing px-3 mr-3 fs-10 weight-500 poppins ${paginationRecordsClasses}`}>
        {meta.from} - {meta.to} of {meta.count}
      </span>
      <nav aria-label="navigation">
        <ul className={`pagination custom-pagination pr-0 mb-0 ${pagesBackgoundClasses}`}>
          <li className="page-item">
          <Page
            p={null}
            meta={meta}
            onClick={
                e => {
                  if (meta.page === 1) {
                    return
                  }
                  onPage( (meta.page > 1 ? meta.page - 1 : 1) , DEFAULT_PAGE_SIZE)
                }
          }>
            <span aria-hidden="true">&lsaquo;</span>
            <span className="sr-only">Previous</span>
          </Page>
          </li>
          {
            renderPageLinks()
          }
          <li className="page-item">
            <Page
              p={null}
              meta={meta}
              onClick={
                e => {
                  if (meta.page === meta.pages) {
                    return
                  }
                  onPage((meta.page < meta.pages ? meta.page + 1 : meta.pages), DEFAULT_PAGE_SIZE)
                }
            }>
              <span aria-hidden="true">&rsaquo;</span>
              <span className="sr-only">Next</span>
            </Page>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const Page = ({p, meta, onClick, children}) => {
  return (
    <span
      style={{
        padding: '5px',
        height: '25px',
        lineHeight: '13px',
        fontSize: '10px',
        minWidth: '25px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: p == meta.page ? '#000' : '#eee',
        border: 'none',
        borderRadius: '0px',
        color: p == meta.page ? '#fff' : '#111',
        fontWeight: '700',
        fontFamily: '"Poppins",sans-serif',
        cursor: 'pointer',
      }} 
      className={`page-link ${p === 'gap' ? 'pagination-gap' : ''}`}
      onClick={onClick}
    >
      {children}
    </span>
  )
}