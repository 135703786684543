import React from 'react';
import Tooltip from './pages/components/Tooltip/Tooltip';

export default function ActionButton({ icon, clickHandler, tooltipText }) {
  return (
    tooltipText ? <Tooltip xAlignment="0px" yAlignment="50%" text={tooltipText} className="single-action-tooltip">
        <div className="single-action cursor-pointer" onClick={() => clickHandler()}>
          <img src={icon} />
        </div>
      </Tooltip > :
      <div className="single-action cursor-pointer" onClick={() => clickHandler()}>
        <img src={icon} />
      </div>
  );
}