import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';

export default  function MessageActions({message, deleteMessage, suspendParticipant}){
  const actions = [];

  if(deleteMessage){
    actions.push(<button key='delete-msg' className='dropdown-item fs-14 weight-800' onClick={() => deleteMessage(message)}>Delete Message</button>);
  }

  if(suspendParticipant && message.chat_participant?.role == 'user' ){
     actions.push(<button key='suspend-participant' className='dropdown-item fs-14 weight-800' onClick={() => suspendParticipant(message)}>Suspend Participant</button>);
  }

  if(!actions.length){
    return <></>;
  }

  return(
    <div className='dropdown show'>
      <button className='btn' data-toggle='dropdown'>
        <FaEllipsisV className='float-right'/>
      </button>
      <div className='dropdown-menu'>
        {actions}
      </div>
    </div>
  )
}