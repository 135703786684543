import React from "react";
import styled from "styled-components"

export default function CustomAnchorLocation ({link}) {
  return (
    <AnchorContainerStles>
    <LinkStyles id={link}>
    </LinkStyles>
  </AnchorContainerStles>  
  )
}

const AnchorContainerStles = styled.div`
 position: relative;
`;

const LinkStyles = styled.div`
  position: absolute;
  top: -90px;
  left: 0px;
  border: 1px solid red;
`