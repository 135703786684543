import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-modal';
import './Page.Spacedbase.scss'
import ShowCompanyPage from './../admin/pages/Page.CompanyProfile'
import { MdClose } from 'react-icons/md';
import { useSpacedbaseStore, fetchChartData, updateFilter } from 'redux/spacedbase'
import CustomSelect from './components/CustomSelect'
import { sectorDescriptions } from '../jsUtils/sectorDescriptions';
import { PageViewFB } from './components/Tracking/facebook';
import LineChart from './components/GoogleCharts/LineChart'
import PieChart from './components/GoogleCharts/PieChart'
import TreeMapChart from './components/GoogleCharts/TreeMapChart';
import TableChart from './components/GoogleCharts/TableChart';

import { history } from 'redux/helpers';
import { Switch, Route, useRouteMatch } from "react-router";

export default function SpacedbasePage(props){
  const [currentPage, setCurrentPage] = useState(0)
  const {loading, chartData, sectorId, fundingStage, isPrivate} = useSpacedbaseStore();
  let { path } = useRouteMatch();


  const openCompanyInNewTab = (companySlug) => {
    window.open(`company/${companySlug}`, "_blank");
  }

  const closeCompanyModal = () => {
    history.push('/spacedbase')
  }

  const sectors = chartData.sectors || []

  if(!sectors.find(s => s.id == null)){
    sectors.unshift({id: null, name: 'All Sectors', children: []})
  }
  window.chartData = chartData

  const selectedSectorName = () => {
    if(!sectorId) return 'All Sectors'
    for(let s of sectors) {
      if (s.id == sectorId) return s.name
      for(let cs of s.children) {
        if (cs.id == sectorId) return `${s.name}: ${cs.name}`
      }
    }
  }


  useEffect(() =>{
    fetchChartData()
  }, [])

  useEffect(() => {
    console.log("use effect ran for google analytics on spacedbase")
    PageViewFB();
  }, [])

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Loading Charts...'
    >
    <div className="header-height-space">
      <div>
        <Helmet>
          <title>SpacedBase</title>
          <meta
            name="description"
            content="Data-driven Space industry insights for investors and researchers on over 1100 Space companies. Including Emerging Markets, Sat Applications, Services, Subsystems, Transportation and traditional sectors. Companies from both Public and Private markets with funding stages from seed to IPO."
          />
        </Helmet>
        <Switch>
          <Route path={`${path}/:companySlug`} component={(props) => {
            const {companySlug} = props.match.params
            return (
              <Modal
                ariaHideApp={false}
                isOpen={true}
                onRequestClose={closeCompanyModal}
                style={modalStyles}
                key={companySlug}
                portalClassName="spacedbase-company-slider"
              >
                <button className="drawer-close-btn pr-2 cursor-pointer" style={{position: "absolute", right: '10px', top: '56px', zIndex: 1}}
                  onClick={closeCompanyModal}
                >
                  <MdClose color="#fff" size="25px" />
                </button>
                <ShowCompanyPage id={companySlug}/>
              </Modal>
            )
          }} />
        </Switch>

        <div className='control-panel'>
          <div className='prompt montserrat weight-700'>
            Displaying:
          </div>
          <div>
            <CustomSelect
              placeholder='All Sectors'
              options={sectors}
              value={sectorId}
              onChange={(value) => updateFilter('sectorId', value)}
              optionLabel='name'
              optionValue='id'
              nested={true}
              parentSelectable={true}
              nestingKey='children'
              nestedLabel='name'
              nestedValue='id'
              debugId="sectorDD"
            />
          </div>
          <span className='v-separator'>&#124;</span>
          <div>
            <CustomSelect
              placeholder='Public & Private Companies'
              options={companyTypeDropdownOptions}
              value={isPrivate}
              onChange={(value) => updateFilter('isPrivate', value)}
              optionLabel='name'
              optionValue='value'
            />
          </div>
          <span className='v-separator'>&#124;</span>
          <div>
            <CustomSelect
              placeholder='All Funding Stages'
              options={fundingStagesDropdownOptions}
              value={fundingStage}
              onChange={(value) => updateFilter('fundingStage', value)}
              optionLabel='name'
              optionValue='value'
            />
          </div>
        </div>
        <div className='content'>
          <div className="charts-container">
            <div className="sector-distribution">
              <div className="stats" >
                  <div className="title montserrat weight-700">Company Count</div>
                  <div className="subtitle montserrat weight-300">{selectedSectorName()}</div>
                  <div className="weight-300 fs-11 text-muted sector-descriptions">{sectorDescriptions(selectedSectorName())}</div>
                  <div className="text-large montserrat weight-700">{chartData?.table_data ? chartData?.table_data.length - 1 : ''}</div>
                  <div className=" montserrat weight-700">Companies</div>
              </div>
              <div className="treemapChart" style={{position: 'relative'}} >
                <div className="treemap-chart-container" style={{height: '100%'}}>
                  <div id="treemap-back-button-container"></div>
                  <div id="sectorDistribution" style={{height: '100%', width: '100%'}}>
                    <div className="flex-cover">Loading...</div>
                  </div>
                </div>
                <TreeMapChart
                  data= {chartData.tree_map}
                  options= {{
                    maxColor: '#3F4E5B',
                    midColor: '#774A58',
                    minColor: '#BA4554',
                    headerHeight: 0,
                    fontColor: '#fff',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 11,
                    showScale: false,
                    highlightOnMouseOver: true,
                    height: '100%',
                    width: '100%',
                    chartArea: {
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                  }}
                  updateSector={(selectedSectorId) => {updateFilter('sectorId', selectedSectorId)}}
                  containerId='sectorDistribution'
                  treeButtonContainerId='treemap-back-button-container'
                />
              </div>
            </div>
            <div className="funding-stages">
              <div className="funding-text">
                <div className="title montserrat weight-700">Funding Stages</div>
                <div className="subtitle montserrat weight-300">{selectedSectorName()}</div>
              </div>
              <div className="pieChart">
                <div style={{height: '100%', width: '100%'}}>
                  <div id="fundingChart" style={{height: '100%', width: '100%'}}>
                    <div className="flex-cover">Loading...</div>
                  </div>
                  <PieChart
                    data= {chartData.funding_data}
                    options={{
                      // title: '# of Companies by Funding Stages',
                      // is3D: true,
                      pieHole: window.innerWidth > 768 ? 0.4 : 0.3,
                      legend: { position: 'right' },
                      legend: {
                        alignment: 'center',
                        textStyle: { color: '#111', fontSize: window.innerWidth > 768 ? 16 : 11, fontFamily: "verdana" }

                      },
                      chartArea: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      },
                      pieSliceTextStyle: {
                        fontName: "zeitung-micro",
                      },
                      backgroundColor: 'transparent',
                      fontName: "zeitung-micro",
                      colors: ['#3E4E5B', '#BB4554', '#3D447C', '#F2D899', '#8C343F', '#BF7777', '#646B56', '#E6A875', '#963B51', '#6E647F', '#928A9F', '#DD824B', '#6F848C', '#3D462C', '#ECBD66'],
                    }}
                    containerId='fundingChart'
                  />
                </div>
              </div>
            </div>
            <div className="trend-over-time">
              <div className="trend-time-text">
                <div className="title montserrat weight-700"># of Companies Over Time</div>
                <div className="subtitle montserrat weight-300">{selectedSectorName()}</div>
              </div>
              <div className="lineChart">
                  <div id="timeTrendChart" style={{height: '270px'}}>
                    <div className="flex-cover">Loading...</div>
                  </div>
                  <LineChart
                    data= {chartData.line_chart}
                    options={{
                      // title: '# of Companies by Sector, Over Time',
                      legend: 'none',
                      colors: ['#3D447C', '#BB4554', '#3E4E5B', '#F2D899', '#8C343F', '#BF7777', '#646B56', '#E6A875', '#963B51', '#6E647F', '#928A9F', '#DD824B', '#6F848C', '#3D462C', '#ECBD66'],
                      chartArea: {
                        width: '100%',
                      },
                      curveType: 'function',
                      vAxis: {
                        gridlines: {
                          color: 'transparent'
                        },
                        baselineColor: 'none',
                        ticks: []
                      },
                      hAxis: {
                        // textPosition: 'none',
                        gridlines: {
                          color: 'transparent'
                        },
                        format: '####',
                        baselineColor: 'none',
                        // ticks: []
                      },
                      focusTarget: 'category',
                      crosshair: {
                        orientation: 'vertical',
                        trigger: 'both'
                      },
                    }}
                    containerId='timeTrendChart'
                  />
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="table-chart">
              {
                chartData?.table_data?.length > 1 ?
                  <div style={{height: '100%', width: '100%'}}>
                    <div id="tableChart" style={{height: '100%', width: '100%'}}>
                      <div className="flex-cover">Loading...</div>
                    </div>
                    <TableChart
                      data= {chartData.table_data}
                      options={{
                        allowHtml: true,
                        showRowNumber: false,
                        alternatingRowStyle: false,
                        page: 'enable',
                        pageSize: 14,
                        startPage: currentPage,
                        cssClassNames: {
                          headerRow: 'company-header',
                          tableRow: 'company-row',
                          hoverTableRow: 'company-row-hover',
                          headerCell: 'company-header-cell',
                          tableCell: 'company-row-cell',
                        },
                        width: '100%',
                        height: '100%',
                      }}
                      onClickRow={openCompanyInNewTab}
                      onPageChange={setCurrentPage}
                      containerId='tableChart'
                    />
                  </div> :
                  <div className="flex-cover">
                    No companies match your query.
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </LoadingOverlay>
  )
}

const fundingStagesDropdownOptions = [
  {name: 'All Funding Stages', value: null},
  {name: 'Pre-Seed', value: 'Pre-Seed'},
  {name: 'Seed', value: 'Seed'},
  {name: 'Series A', value: 'Series A'},
  {name: 'Series B', value: 'Series B'},
  {name: 'Series C+', value: 'Series C+'},
  {name: 'Grant', value: 'Grant'},
  {name: 'Undisclosed', value: 'Undisclosed'},
  {name: 'IPO', value: 'IPO'},
  {name: 'None', value: 'None'},
]

const companyTypeDropdownOptions = [
  { name: 'Public & Private Companies', value: null },
  { name: 'Public Companies', value: false },
  { name: 'Private Companies', value: true }
]

const modalStyles = {
  overlay: {
    position: 'static',
  },
  content : {
    borderRadius: 0,
    position: 'fixed',
    top: '56px',
    left: 0,
    width: window.innerWidth > 991 ? '50%' : '100%',
    right: 'auto',
    bottom: 0,
    overlfow: 'scroll',
    padding: '0px',
    border: 0,
    zIndex: '2'
  }
}
