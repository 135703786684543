import React, { useEffect, useState, useContext } from "react";
import useUserAffiliations from "redux/userAffiliations";
import UserAffiliationsEditor from "./UserAffiliationsEditor";
import { toTitlecase } from "../jsUtils/stringUtils";
import { PortalizedModalContext } from "contexts/portalizedModalContext";

export default function UserAffiliationManager({
  companyId,
  companyName,
  showBadges = true,
}) {
  const userAffiliations = useUserAffiliations();
  const affiliations = userAffiliations.affiliations;
  const manageAffiliationsModal = useContext(PortalizedModalContext);

  useEffect(() => {
    userAffiliations.loadUserAffiliations(companyId);
  }, [companyId]);

  const manageAffiliations = () => {
    manageAffiliationsModal.setModalContent(
      `Manage your affiliations`,
      <UserAffiliationsEditor
        companyId={companyId}
        companyName={companyName}
        onClose={() => manageAffiliationsModal.closeModal()}
      />,
      "sm"
    );
    manageAffiliationsModal.openModal();
  };
  return (
    <div className="manage-affiliations-box affiliation-badges">
      {showBadges &&
        affiliations.map((affiliation, index) => (
          <div key={affiliation.id} className="custom-badge">
            {`${toTitlecase(affiliation.title)} ${
              affiliation.approved ? "" : "(pending)"
            }`}
          </div>
        ))}
      <button
        className="underline cursor-pointer weight-400 border-0 bg-transparent mt-2"
        onClick={manageAffiliations}
      >
        Manage Affiliations
      </button>
    </div>
  );
}
