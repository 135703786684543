import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';
import { pl } from 'date-fns/locale';

function RadioInputGroup({ name, header, options, headerStyle = '', labelStyle = '', inputStyle = '' }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div>
          {header && (<h5 className={`fs-default poppins weight-700 ${headerStyle}`}>{header}</h5>)}
          <ErrorMessage error={error}/>
          {options.map((option, index) => {
            return (
              <div className="form-check my-3" key={index}>
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  checked={String(option.value) == String(field.value)}
                  className={`form-check-input ${inputStyle}`}
                  onChange={(e) => field.onChange(e)}
                />
                <label className={`form-check-label poppins ${labelStyle}`} htmlFor={option.name}>
                  {option.labelTitle && <span className='weight-700'>{option.labelTitle}</span>}
                  {option.labelTitle && ' - '}
                  {option.label}
                </label>
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

RadioInputGroup.defaultProps = {};

const optionPropTypes = {
  value: PropTypes.any.isRequired,
  labelTitle: PropTypes.string,
  label: PropTypes.string.isRequired
};

RadioInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(optionPropTypes)),
  titleStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  inputStyle: PropTypes.string
};

export default RadioInputGroup;