import React from 'react';
import styled from 'styled-components'

import profileImg1 from '../../assets/images/team/aaron-profile-pic.jpg'
import profileImg2 from '../../assets/images/team/andrew-profile-pic.jpg'
import profileImg4 from '../../assets/images/team/kevin-profile-pic.jpg'
import profileImg5 from '../../assets/images/team/deepak-profile-pic.jpg'
import profileImg8 from '../../assets/images/experts/brant.jpg'
import JoseViana from '../../assets/images/team/jose.jpg'
import Alex from '../../assets/images/team/Alex2.jpg'
import JoseArcos from '../../assets/images/team/JoseArcos.jpg'
import JirenPatel from '../../assets/images/team/JirenPatel.jpg'

const teamData = [
  {
    name: "Aaron Burnett",
    role: "Founder, CEO",
    img: profileImg1
  },
  {
    name: "J. Brant Arseneau",
    role: "Founder, Board Director",
    img: profileImg8
  },  
  {
    name: "Andrew Woods",
    role: "Product Lead",
    img: profileImg2
  },
  {
    name: "Alex Darvishian",
    role: "Venture Lead",
    img: Alex
  },      
  {
    name: "Kevin Liu",
    role: "UX Design Lead",
    img: profileImg4
  },
  {
    name: "Deepak Singh",
    role: "Frontend Lead",
    img: profileImg5
  },  
  {
    name: "Jiren Patel",
    role: "Dev Ops/Backend Lead",
    img: JirenPatel
  }, 
  {
    name: "Jose Viana",
    role: "Marketing Lead",
    img: JoseViana
  },  
  {
    name: "Jóse Arcos",
    role: "Visual Design Lead",
    img: JoseArcos
  },                          
]


function TeamTile({ name, role, img, tileBoxBorder, tileBoxShadow, imgBorder }) {
  return (
    <>
      <div className="d-flex text-center flex-column justify-content-center align-items-center">
        <TeamImgContainer
          tileBoxBorder={tileBoxBorder}
          tileBoxShadow={tileBoxShadow}
          imgBorder={imgBorder}
        >
          <img className="team-img" src={img} alt="spaced ventures team member"></img>
        </TeamImgContainer>
        <div className="team-container-text">
          <h3 className="fs-9 mt-3 montserrat weight-900">{name}</h3>
          <span className="subheader-expert-lh fs-11 zeitung-mono">{role}</span>
        </div>
      </div>
    </>
  )
}

export default function SpaceTeam({  bgColor = 'transparent', color = '#111', tileBoxBorder = '#111', tileBoxShadow = '#111', imgBorder = '#fff' }) {
  return (
    <TeamBackground bgColor={bgColor} color={color} >
      <div className="container main-section">
        <h2 className="fs-4 weight-900 text-center">Our Leadership</h2>
        <div className="team-grid mt-5">
            {teamData.map((item, i) => {
              return <TeamTile 
                        tileBoxBorder={tileBoxBorder} 
                        tileBoxShadow={tileBoxShadow} 
                        imgBorder={imgBorder} 
                        key={i} 
                        name={item.name} 
                        role={item.role} 
                        img={item.img} />
            })}
          </div>
      </div>
    </TeamBackground>
  )

}

const TeamBackground = styled.div`
  background: ${props => props.bgColor};
  color: ${props => props.color};
    
  .team-container-text {
    color: ${props => props.color};
    height: 100px;
  }
`;

const TeamImgContainer = styled.div`
  border: 1px solid ${props => props.tileBoxBorder};
  box-shadow: 3px 3px 0px ${props => props.tileBoxShadow};
  border-radius: 4px;
  background: ${props => props.tileBoxBorder};

.team-img {
  height: 129px;
  width: 129px; 
  border: 4px solid ${props => props.imgBorder};
  border-radius: 4px;
}


  @media (max-width: 420px) {
    .team-img {
    height: 90px;
    width: 90px; 
    }
  }

`