import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import MaskedInput from "react-text-mask";
import { phoneNumberMask, taxIdNumberMask } from '../../../jsUtils/stringUtils';
import ToastMessage from '../../../components/ToastMessage';
import { googleAddresses } from '../../../jsUtils/googleAddresses';
import { preventDefaultSubmit } from '../../../jsUtils/formUtils';
import useGeoInfo from '../../../lib/useGeoInfo';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompany } from '../../pages/company/queries';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

function addressChangeHandler(e, handleChange, setFieldValue, setInvalidAddress) {
  handleChange(e);
  const addressElement = document.getElementById('street_address_1');
  const fieldsObj = {postal_code: 'postal_code', address: 'street_address_1'};
  googleAddresses(addressElement, fieldsObj, setFieldValue, setInvalidAddress)
}

export default function IssuerProfile({ issuerDetails, companySlug, editable }) {
  const queryClient = useQueryClient();

  const { State } = useGeoInfo()
  const [invalidAddress, setInvalidAddress] = useState(false);

  const updateCompanyMutation = useMutation(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', 'companies']);
      ToastMessage.success("Issuer profile updated successfully.");
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  })

  return (
    <div id="nameForm">
      <Formik
        enableReinitialize
        initialValues={{
          name: issuerDetails?.name || "",
          title: issuerDetails?.title || "",
          tax_id_number: issuerDetails?.tax_id_number || "",
          region_formed_in: issuerDetails?.region_formed_in || "",
          phone: issuerDetails?.phone || "",
          email: issuerDetails?.email || "",
          contact_name: issuerDetails?.contact_name || "",
          street_address_1: issuerDetails?.street_address_1 || "",
          city: issuerDetails?.city || "",
          region: issuerDetails?.region || "",
          postal_code: issuerDetails?.postal_code || "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Issuer point of contact title is required'),

          name: Yup.string()
            .required('Legal name is required'),

          tax_id_number: Yup.string()
            .required("Tax ID number is required")
            .test('len', 'Must be exactly 9 digits', val => val?.match(/\d/g)?.length === 9),

          region_formed_in: Yup.string()
            .required("Region formed in is required"),

          phone: Yup.string()
            .required("Phone company number is required")
            .test('len', 'Must be exactly 10 digits', val => val?.match(/\d/g)?.length === 10),

          email: Yup.string()
            .required("Email is required"),

          contact_name: Yup.string()
            .required("Point of contact is required"),

          street_address_1: Yup.string()
            .required("Address is required")
            .test("","", function (val) {
              if (invalidAddress === true) return this.resolve(this.createError({message: "Enter the valid address"}))
              else return true;
            }),

          city: Yup.string()
            .required("City is required"),

          region: Yup.string()
            .required("State is required"),

          postal_code: Yup.string()
            .required("Zip code is required")
            .matches(/^\d+$/g , 'Must be only digits')
            .test('len', 'Must be exactly 5 digits', val => val?.match(/\d/g)?.length === 5),
        })}

        onSubmit={async values => {
          if(editable === undefined || editable) {
            if(issuerDetails?.id) values.id = issuerDetails?.id;
            values.country = 'US';
            values.tax_id_number = values.tax_id_number.replace(/-/g, '');
            updateCompanyMutation.mutate({company_slug: companySlug, values: {issuer_detail_attributes: values}})
          } else {
            ToastMessage.error("Can't update published offer");
          }
        }}
      >
        {({ touched, errors, isSubmitting, setFieldValue, handleChange }) => (
          <Form onKeyDown={preventDefaultSubmit}>
            <div className="nameform-content">
              <div className="form-label-group">
                <Field
                  id="name"
                  name="name"
                  className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                  placeholder="Company Legal Name"
                />
                <label htmlFor="name">Company Legal Name</label>
                <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      name="tax_id_number"
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={taxIdNumberMask}
                          id="tax_id_number"
                          placeholder="Tax ID Number"
                          type="text"
                          className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                        />
                      )}
                    />
                    <label htmlFor="tax_id_number">Tax ID Number</label>
                    <ErrorMessage name="tax_id_number" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      type="select"
                      id="region_formed_in"
                      name="region_formed_in"
                      className={`form-control ${touched.region_formed_in && errors.region_formed_in ? "is-invalid" : ""}`}
                      placeholder="State of Forming"
                      component="select"
                    >
                      <option value="">Select State of Forming</option>
                      {
                        State.withISO2CountryCode("US").map(state => {
                          return <option key={state.state_code} value={state.state_code}>{state.name}</option>
                        })
                      }
                    </Field>
                    <label htmlFor="region_formed_in">State of Forming</label>
                    <ErrorMessage name="region_formed_in" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      name="phone"
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={phoneNumberMask}
                          id="phone"
                          placeholder="Company Phone Number"
                          type="text"
                          className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                        />
                      )}
                    />
                    <label htmlFor="phone">Company Phone Number</label>
                    <ErrorMessage name="phone" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      id="email"
                      name="email"
                      className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                      placeholder="Company Email"
                    />
                    <label htmlFor="email">Company Email</label>
                    <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      id="contact_name"
                      name="contact_name"
                      className={`form-control ${touched.contact_name && errors.contact_name ? "is-invalid" : ""}`}
                      placeholder="Point of Contact"
                    />
                    <label htmlFor="contact_name">Point of Contact</label>
                    <ErrorMessage name="contact_name" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      id="title"
                      name="title"
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                      placeholder="Title of Point of Contact"
                    />
                    <label htmlFor="title">Title of Point of Contact</label>
                    <ErrorMessage name="title" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-label-group">
                    <Field
                      type="text"
                      id="street_address_1"
                      name="street_address_1"
                      className={`form-control ${touched.street_address_1 && errors.street_address_1 ? "is-invalid" : ""}`}
                      placeholder="Address"
                      onChange={(e)=> addressChangeHandler(e, handleChange, setFieldValue, setInvalidAddress)}
                      />
                    <label htmlFor="street_address_1">Address</label>
                    <ErrorMessage name="street_address_1" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      id="city"
                      name="city"
                      className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                      placeholder="City"
                    />
                    <label htmlFor="city">City</label>
                    <ErrorMessage name="city" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-label-group">
                      <Field
                        type="select"
                        id="region"
                        name="region"
                        className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                        placeholder="State of Forming"
                        component="select"
                      >
                        <option value="">Select State</option>
                        {
                          State.withISO2CountryCode("US").map(state => {
                            return <option key={state.state_code} value={state.state_code}>{state.name}</option>
                          })
                        }
                      </Field>
                      <label htmlFor="region">State</label>
                      <ErrorMessage name="region" component="div" className="invalid-feedback text-left" />
                    </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-label-group">
                    <Field
                      type="string"
                      id="postal_code"
                      name="postal_code"
                      className={`form-control ${touched.postal_code && errors.postal_code ? "is-invalid" : ""}`}
                      placeholder="Zip code"
                    />
                    <label htmlFor="postal_code">Zip code</label>
                    <ErrorMessage name="postal_code" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
                  Save
                </button>
              </div>
            </div>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  );
}
