import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Marquee from 'react-fast-marquee';
import { promotedEventApi } from '../api/promotedEvent';
import { distanceFromNow } from '../jsUtils/dateTimeUtils';

export default function PromotedEventsBanner({ setHasPromotedEvents }) {
  const {
    isLoading,
    error,
    data: events,
  } = useQuery(
    'promotedEvents',
    () => {
      return promotedEventApi.list();
    },
    { staleTime: 0, refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true }
  );

  useEffect(() => {
    setHasPromotedEvents(events && events.length > 0)
  }, [events]);

  if(isLoading || error || events.length === 0){
    return <></>;
  }

  const eventTitle = (event) => {
    // Offer and pre offer.
    if(event.event_type === 'NewOfferLaunch') {
      return `${event.company} just launched`
    } else if (event.event_type === 'ClosingOffer') {
      return `${event.company} is closing in ${distanceFromNow(event.close_time)}`
    } else if (event.event_type == 'PreofferLaunch'){
      return `${event.company} just Pre-launched`
    }

    // For Plarform event and Offer Live event
    let text = '';

    if(event.status === 'pre_live'){
      text = 'is streaming soon';
    } else if(event.status === 'live'){
      text = 'is streaming live';
    } else if(event.status === 'closed'){
      text = 'recently streamed';
    }

    return `${event.company} ${text}: ${event.name}`
  }

  const EventItem = ({event}) => {
    let path, linkText = 'View Now';

    if(event.event_type === 'NewOfferLaunch' || event.event_type == 'ClosingOffer' ) {
      path = `/offers/${event.slug}/pitch`;
      linkText = 'Invest';
    }else if(event.event_type === 'PreofferLaunch') {
      path = `/prelaunch/${event.slug}`;
      linkText = 'View';
    }else if(event.event_type === 'LiveEvent'){
      path = `/offers/${event.slug}/pitch`;
    }else if (event.event_type === 'PlatformLiveEvent'){
      path = `/resources/${event.slug}`;
    }

    return (
      <Link to={path} className='color-white text-decoration-none'>
        <span className='weight-600'>{eventTitle(event)}</span>
        <FaArrowRight className='mx-2'/>
        <span>{linkText}</span>
      </Link>
    )
  }

  return(
    <div className='promoted-event-banner bg-light-black color-white d-block poppins fs-14 px-4 py-2'>
      <Marquee speed={50} gradient={false} direction='right' pauseOnHover={true} pauseOnClick={true}>
        { events.map((event, index) => <EventItem key={index} event={event}/>) }
      </Marquee>
    </div>
  )
}
