import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchRisksIcon } from '../../../../assets/images';

function RiskAndDisclosureSection({ 
  pitch:{risks_and_disclosures_section}
}) {
  if(!risks_and_disclosures_section) return null;

  return (
    <div id="risks-and-disclosure-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'risks & disclosures'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchRisksIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{risks_and_disclosures_section.title}</div>
          </div>
            <ArticleContent content={risks_and_disclosures_section.content} sectionName="risks_and_disclosures_section" />
        </div>
      </div>
    </div>
  )
}

export default RiskAndDisclosureSection;