import React from 'react';
import IssuerProfile from './OfferForm/IssuerProfile';
import OfferInfoOverview from './OfferForm/OfferInfoOverview';
import './../pages/Page.OfferEdit.scss';
import LoadingOverlay from 'react-loading-overlay';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import { EDIT_OFFER_OVERVIEW, EDIT_OFFER_DOCUMENTS, OFFER_STATUS_CONSTANTS, EDIT_OFFER_SUB_DOC } from '../../config/constants';

export default function OfferCreator({company}){
  const { authData } = React.useContext(AuthContext);

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_OFFER)) {
    return <div>
      You are not authorised to create/edit Offer.
    </div>
  }

  let activeTabConstant;

  return (
    <LoadingOverlay
      active={false}
      spinner
      text='Loading ...' >
      <>
        <div className="custom-topbar offer-editor-topbar">
          <div className="offer-summary">
            Create
            <span className="offer-name">New Offer</span>
            for {company.name}
          </div>
        </div>

        <main>
          <div className="small-section-bottom">
            <div className="offer-editor">
              <div className="offer-editor-tab-nav">
                <ul className="nav nav-tabs" id="settings-tabs" role="tablist">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTabConstant == null ? 'active' : ''} ${company.issuer_detail ? 'complete' : ''}`} data-toggle="tab" href="#issuer-profile" role="tab">
                      Issuer
                    </a>
                  </li>

                  { company.issuer_detail &&
                      <li className="nav-item">
                        <a className={`nav-link ${activeTabConstant === EDIT_OFFER_OVERVIEW ? 'active' : ''}`} data-toggle="tab" href="#offer-overview-info" role="tab">
                          Offer
                        </a>
                      </li>
                  }
                </ul>
              </div>
              <div className="tab-content offer-editor-tab-content" id="myTabContent">

                <div className={`tab-pane fade ${activeTabConstant == null ? 'show active' : '' }`} id="issuer-profile" role="tabpanel">
                  <IssuerProfile issuerDetails={company.issuer_detail} companySlug={company.slug} editable={true} />
                </div>

                <div className={`tab-pane fade ${activeTabConstant === EDIT_OFFER_OVERVIEW ? 'show active' : ''}`} id="offer-overview-info" role="tabpanel">
                  <OfferInfoOverview offer={{}} companySlug={company.slug} editable={true} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </LoadingOverlay>
  )
}
