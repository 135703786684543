import React from 'react';
import { useFormikContext } from 'formik';
import { Prompt } from 'react-router-dom';

export default function FormikPromptIfDirty() {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message={
        JSON.stringify({
          title: 'Save progress on this section?',
          message: "If you don’t save before you leave the page, progress will be lost",
          restrictTransition: false,
        })
      }
    />
  );
};
