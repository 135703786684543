import React from 'react'
import { useParams } from "react-router-dom";
import RaisePetitionViewPage from "./../investor/features/raise_petition/RaisePetition.ViewPage";

import "./../admin/pages/Page.OfferView.scss";

export default function RaisePetitionPage(props){
  let { raisePetitionId } = useParams();

  return(
    <div className='page-container'>
      <RaisePetitionViewPage petitionId={raisePetitionId} />
    </div>
  )
}
