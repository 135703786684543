import React from 'react';
import { deleteIconOutline } from '../../../../assets/images';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import InvestmentCardRow, { InvestmentCardActionRow } from '../../shared/InvestmentCardRow';
import { useQueryClient, useMutation } from 'react-query';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { deleteAchAccount } from "../../../../investor/queries";
import ToastMessage from "components/ToastMessage";

export default function AchAccount({
  clientId,
  account,
  selected,
  onSelect = null,
  onDelete = null,
  children
}) {
  const queryClient = useQueryClient();
  const deleteModal = React.useContext(PortalizedModalContext);

  const deleteAchMutation = useMutation(deleteAchAccount, {
    onSuccess: () => {
      typeof onDelete === "function" && onDelete(account.id);
      queryClient.refetchQueries(['client-payment-methods', clientId])
      ToastMessage.success("ACH account deleted.");
    },
    onError: (error) => {
      renderRailsErrors(error);
    }
  })

  const deleteAch = () => {
    deleteModal.setModalContent(
      `Delete ACH Account`,
      <>
        <p>Do you want to delete your ACH payment method?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteAchMutation.mutate({clientId})
              deleteModal.closeModal();
            }}
          >Confirm</button>
          <button
            className="btn-black-outline"
            onClick={() => {
              deleteModal.closeModal()
            }}
          >Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteModal.openModal();
  }

  return (
    <div className="payment-card mb-3">
      <div className='payment-card-header'>ACH</div>
      <InvestmentCardRow title={'Name on Account'} value={account?.account_name} />
      <InvestmentCardRow title={'Last 4 Digits'} value={account?.account_number_last_four} />
      <InvestmentCardRow title={'Routing Number'} value={account?.details?.routing_number || "-"} />
      {/* Passing action elements as children */}
      <InvestmentCardActionRow>
        {children ??
          <>
            <div className="inner1 d-flex flex-wrap align-items-center">
              { selected != undefined &&
                <label style={{ lineHeight: '16px' }} className="custom-radio-btn fs-10 my-2 mr-3">Use this account
                  <input
                    type="radio"
                    name="radio"
                    checked={selected}
                    onChange={() => typeof onSelect === "function" && onSelect(account.id)} />
                  <span className="radiobtn"></span>
                </label>
              }
              <div onClick={deleteAch} className="delete-ach d-flex align-items-center my-2 cursor-pointer">
                <img src={deleteIconOutline} width="16" className="mr-1" alt="trash icon" />
                <span className="zeitung-micro fs-10 weight-300">delete</span>
              </div>
            </div>
          </>
        }
      </InvestmentCardActionRow>
    </div>
  );
}