import React from 'react';
import PropTypes from 'prop-types';
import { humanize } from 'jsUtils/stringUtils';

const ErrorMessage = ({error}) => {
  if (!error?.message) return null;

  return (
    <div className="invalid-feedback text-left d-inline">
      {humanize(error.message)}
    </div>
  )
}

ErrorMessage.defaultProps = {};

ErrorMessage.propTypes = {
  error: PropTypes.object,
};

export default ErrorMessage;