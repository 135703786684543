import React from 'react';
import EditForm from '../components/CompanyInfo/EditForm';

export default function CompanyCreator(props){
  return (
    <>
      <div className="settings-tab-box">
        <div className="settings-tab-box-nav">
          <ul className="nav nav-tabs" id="company-edit-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="company-info-tab" data-toggle="tab" href="#company-info-form" role="tab">Overview</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="company-edit-tab-content">
          <div className="tab-pane fade show active" id="company-info-form" role="tabpanel">
            <EditForm company={{}}/>
          </div>
        </div>
      </div>
    </>
  )
}

