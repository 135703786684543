import WaitListAPI from "./components/WaitlistAPI";
import React from 'react';
import styled from 'styled-components';

const Waitlist = () => {
  return (  
    <div className="about-img-container">
      <WaitListContainer>
        <div className="container">
          <div className="page-header-1">
            <h2 className="hero-header-main">Spaced Ventures<br></br> Beta Access</h2>
            <div className="py-3">
              <WaitListAPI />
            </div>
            <div >
              <ReferalContainer>
                <h2>We’ll be opening beta investment accounts in batches.</h2>
                <p>When you sign up, you will receive a referral link. Share this link with your network and instantly move up 5 spots on the waitlist when a friend uses your link to sign up! We will reach out to you by email when it’s your turn to set up your investment account.</p>
                <p>Note: all account holders will receive equal access to offerings.</p>
              </ReferalContainer>
            </div>
          </div>
        </div>
      </WaitListContainer>    
    </div>
  );
}
 
export default Waitlist;

const WaitListContainer =  styled.div`
height: 850px;

@media (max-width: 700px) {
  height: 1000px;
}
.container--waitlistapi {
  h1 {
    display: none !important;
  }
}
`;

const ReferalContainer =  styled.div`
  max-width: 600px;
  padding: 20px 30px;
  background-color: #f7f7f7;

  h2 {
    font-family: zeitung-micro, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }

`;