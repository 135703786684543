import React from 'react';
import { useQuery } from 'react-query';
import MessageList from './MessageList';


export default function ChatHistory({ liveEvent, getChatSession, children }){
  const { isLoading, error, data: chatSession } = useQuery(
    [liveEvent.channel_id, 'chatSession'],
    () => getChatSession(liveEvent.slug || liveEvent.id)
  );

  if(isLoading){
    return <div className='text-center color-white montserrat weight-500 fs-8 mt-4'>Loading Messages...</div>
  }

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  if(!chatSession.messages.length){
    return <></>;
  }

  return(
    <div>
      {children}
      <div className="chat-box" id="chat-history">
        <div className="messages p-4">
          <MessageList chatSession={chatSession}/>
        </div>
      </div>
    </div>
  )
}
