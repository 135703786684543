
import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import fireworkIcon from '../../assets/images/icons/fireworks.svg';

export default function InvestmentMessage({ message }){
  const precision = parseInt(message.content) == message.content ? 0 : 2;

  return (
    <div className='investment d-flex'>
      <div className='col-12 px-0 color-white d-flex flex-row'>
        <div className='fs-14 poppins stars px-2 py-1'>
          <img style={{ width: '18px' }} src={fireworkIcon} alt="Offer Launched!"></img>
        </div>
        <div className='fs-14 poppins amount px-3 py-1'>
           <div className='d-inline align-middle'>New!</div>
          <div className='ml-1 weight-700 d-inline align-middle'>{formatMoney(message.content, {precision : precision})} Invested!</div>
        </div>
      </div>
    </div>
  )
}