import React from "react";
import { useUser } from "redux/user";
import "./MyProfileOverview.scss";
import { pluralize } from "jsUtils/stringUtils";
export default function MyProfileOverview() {
  const user = useUser();
  const totalInvestments = user?.investments?.length;
  const currentClient = user?.clients?.find(
    (client) => client.entity_type === "person"
  );
  return (
    <div className="user-box">
      <div className="user-info">
        <h1 className="user-name">{user?.name}</h1>
        <div className="badges">
          {currentClient?.id && (
            <div className="single-badge">
              {`${currentClient.city}, ${currentClient.country}`}
            </div>
          )}
          <div className="single-badge">
            {pluralize(totalInvestments, "Investment", "Investments")}
          </div>
        </div>
      </div>
    </div>
  );
}
