import React from 'react';
import { PusherChannelProvider } from '../../../contexts/PusherChannelContext';
import YoutubeEmbed from '../../../admin/components/shared/YoutubeEmbed';
import GaolProgressBar from './GoalProgressBar';
import OfferProgressBar from './OfferProgressBar';
import LiveChat from '../../LiveChat';
import { liveEventApi } from '../../../api/liveEvent';

export default function LiveEventMobileView({ offer, user, isAdminUser}) {
  const liveEvent = offer.live_event;

  return (
    <PusherChannelProvider>
      <div className='mobile-live-event'>
        <div>
          <YoutubeEmbed videoUrl={liveEvent.youtube_url}/>
        </div>
        <div className='px-2'>
          <GaolProgressBar offer={offer} user={user} />
        </div>
        <div className='bg-light-black px-2 py-4'>
          <LiveChat.ChatBox liveEvent={liveEvent} user={user} company={offer.company} getChatSession={liveEventApi.getChatSession}/>
        </div>
        <div className='py-4 px-2 bg-black'>
          <OfferProgressBar offer={offer} />
        </div>
      </div>
    </PusherChannelProvider>
  )
}
