import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const SelectField= ({name, options, placeholder, labelClass}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className={`form-label-group ${!placeholder && "custom-select-padding"} `}>
          <select
            {...field}
            id={field.name}
            className={`form-control ${ invalid ? "is-invalid" : ""}`}
            placeholder={placeholder}
          >
            { placeholder && <option value="">{placeholder}</option> }
            {
              options.map((o, i) => <option value={o.value || o} key={i}>{o.label || o}</option>)
            }
          </select>
          <label htmlFor={field.name} className={labelClass || ''}>{placeholder}</label>
          <ErrorMessage error={error}/>
        </div>
      )}
    />
  )
}

SelectField.defaultProps = {};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default SelectField;
