import axiosClient from 'config/axiosClient';

export const chatMessageApi = {
  create,
  getChatSession,
  destroy,
  suspendParticipant,
  suspendedParticipants
};

function create({ chatSessionId, attrs }) {
  return axiosClient.post(`/chat_sessions/${chatSessionId}/chat_messages`, { chat_message: attrs });
}

function getChatSession(id) {
  return axiosClient.get(`/chat_sessions/${id}`);
}

function destroy(id) {
  return axiosClient.delete(`/chat_messages/${id}`);
}

function suspendParticipant(participateId){
  return axiosClient.put(`/chat_participants/${participateId}/suspend`, {
    chat_participant_id: participateId
  });
}

function suspendedParticipants(chatSessionId){
  return axiosClient.get(`/chat_sessions/${chatSessionId}/suspended_participants`);
}