import React, {useRef, useState} from 'react';
import ContentEditable from 'react-contenteditable';
import ToastMessage from '../ToastMessage';
import { IoMdSend } from 'react-icons/io';
import { chatMessageApi } from '../../api/chatMessage';
import Utils from './Utils';
import UserAffiliationManager from '../../components/UserAffiliationManager';

export default function SendMessageForm({ chatSession, moderation, company }){
  const maxLength = 200;
  const text = useRef('');
  const editorRef = useRef(null);
  const [messageLength, setMessageLength] = useState(0);
  let lastKey;

  const handleChange = (e) => {
    const ele = document.createElement("div");
    ele.innerHTML = e.target.value;
    const content = ele.textContent || ele.innerText || "";

    if(content.length <= maxLength){
      text.current = content;
      setMessageLength(text.current.length)
    }
  }

  const onKeydown = (e) => {
    // keys: https://css-tricks.com/snippets/javascript/javascript-keycodes/
    const exemptedKeys = [8, 35, 36, 37, 38, 39, 40, 46];

    if(lastKey === 16 && e.which === 13 ){
      text.current = text.current + '\n';
      return;
    }

    lastKey = e.which;

    // Enter key.
    if(e.which === 13){
      sendMessage();
      e.preventDefault();
      return;
    }

    if(text.current.length >= 200 && exemptedKeys.indexOf(e.which)){
      e.preventDefault();
    }
  }

  const onPaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
    const content = clipboardData.getData('text/plain')

    if((text.current.length + content.length) >= 200){
      e.preventDefault();
    }
  }

  const sendMessage = () => {
    let content = editorRef.current.innerText.trim();

    if(!moderation) {
      content = Utils.removeLinks(content);
    }

    if(content.length > 0){
      chatMessageApi.create({
        chatSessionId: chatSession.id, attrs: { content: content }
      }).catch(error => {
        ToastMessage.error(error, {
          toastId: "CHAT_MSG_CREATE_ERROR"
        });
      });
      text.current = '';
      editorRef.current.innerHTML = '';
      setMessageLength(0);
    }
  }

  return (
    <div className='pt-1'>
      <ContentEditable
        className="message-editor mt-1"
        html={text.current}
        disabled={false}
        onChange={handleChange}
        onKeyDown={onKeydown}
        onPaste={onPaste}
        placeholder="Type message here"
        innerRef={editorRef}
      />
      <div className="d-flex justify-content-between py-1">
        <div className='affliation-manager pt-1'>
          { company && <UserAffiliationManager companyId={company.id} companyName={company.name} showBadges={false}/> }
        </div>
        <div>
          <span className="mx-3 p-0 align-middle msg-length">{messageLength}/{maxLength}</span>
          <button className='btn msg-send-btn p-0 m-0' onClick={sendMessage}><IoMdSend/></button>
        </div>
      </div>
    </div>
  )
}