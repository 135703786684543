import React from 'react';
import OverviewSubheader from 'admin/components/OverviewSubheader'
import { formatDate } from '../../../jsUtils/dateTimeUtils';
import { FaDownload } from 'react-icons/fa';

export default function VerificationLetter({ accreditationRequest }) {
  if(!accreditationRequest.verification_letter_url){
    return null;
  }

  return (
    <>
      <OverviewSubheader left={'Accredited Verification Letter'} />
      <div className="highlighted-tile mb-1 d-flex">
        <div className='col-10'>{`Generated On ${formatDate(accreditationRequest.verified_at)}`}</div>
        <div className='col-2'>
          <a
            href={accreditationRequest.verification_letter_url}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-2"
          >
            <span className='mr-1'> Download</span>
            <FaDownload className="fs-18 color-black" />
          </a>
        </div>
      </div>
    </>
  )
}