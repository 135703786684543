import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';
import { downloadFile } from 'jsUtils/fileUtils';

const usePreofferList = ({q=null, page=1, per_page=10}) => {
  return useQuery(
    ['preoffer', 'list', q, page, per_page],
    () => {
      return axiosClient
        .get(`admin/preoffers/`, {params: {q, page, per_page}})
    }, {
      keepPreviousData: true,
    }
  );
}

const usePreoffer = (slug) => {
  return useQuery(
    ['preoffer', slug],
    () => {
      return axiosClient
        .get(`admin/preoffers/${slug}`)
    }
  );
}

const usePreofferPitch = (preoffer_slug) => {
  return useQuery(
    ['preoffer#pitch', preoffer_slug],
    () => {
      return axiosClient
        .get(`admin/preoffers/${preoffer_slug}/pitch`)
    }
  );
}

const usePreofferSubscriberList = (preoffer_slug, pageConfig = {page:1, per_page:25}) => {
  return useQuery(
    ['preoffer', preoffer_slug, 'subscriberlist', pageConfig.page],
    () => {
      return axiosClient
        .get(`admin/preoffers/${preoffer_slug}/subscribers.json`, {params: pageConfig})
    },
    {
      keepPreviousData: true,
    }
  );
}

const useExportPreofferSubscriberList = (preoffer_slug, opts={enabled: false}) => {
  return useQuery(
    ['preoffer', preoffer_slug, 'exportsubscriberlist'],
    () => {
      return axiosClient
        .get(`admin/preoffers/${preoffer_slug}/subscribers.csv`)
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `${preoffer_slug}-subscribers.csv`)
      }
    }
  );
}

const deletePreofferSubscription = ({preoffer_id, subscription_id}) => {
  return axiosClient.delete(`admin/preoffers/${preoffer_id}/subscribers/${subscription_id}`)
}

const useBusinessModelList = () => {
  return useQuery(
    ['businessModelList'],
    () => {
      return axiosClient
        .get('/business_models')
    }
  );
}

const usePitchCustomerList = (pitchable_id) => {
  return useQuery(
    ['preofferCustomers', pitchable_id],
    () => {
      return axiosClient
        .get(`/admin/preoffers/${pitchable_id}/customers`)
    }, {
      enabled: pitchable_id !== undefined
    }
  );
}

const createPreoffer = ({company_id, values}) => {
  return axiosClient.post(`/admin/companies/${company_id}/preoffers`, values)
}

const updatePreoffer = ({preoffer_id, values}) => {
  return axiosClient.put(`/admin/preoffers/${preoffer_id}`, values)
}

const deletePreoffer = ({preoffer_id}) => {
  return axiosClient.delete(`/admin/preoffers/${preoffer_id}`)
}

// status: one of [publish, pause, close]
const updatePreofferStatus = ({preoffer_id, status}) => {
  return axiosClient.patch(`/admin/preoffers/${preoffer_id}/${status}`)
}

const updatePreofferEsignTemplate = ({preoffer_id, values}) => {
  return axiosClient.patch(`/admin/preoffers/${preoffer_id}/esign_document_template`, values)
}

const updatePitch = ({pitchable_id, values}) => {
  return axiosClient.put(`/admin/preoffers/${pitchable_id}/pitch`, values)
}

const addPitchSection = ({pitchable_id, values}) => {
  return axiosClient.post(`/admin/preoffers/${pitchable_id}/pitch/pitch_sections`, values)
}

const updatePitchSection = ({section_id, values}) =>{
  return axiosClient.put(`/admin/pitch_sections/${section_id}`, values)
}

const deletePitchSection = ({section_id, type}) => {
  return axiosClient.delete(`/admin/pitch_sections/${section_id}`, {data: {type: type}})
}

const addCustomer = ({preoffer_id, values}) => {
  return axiosClient.post(`/admin/preoffers/${preoffer_id}/customers`, values);
}

const updateCustomer = ({customer_id, values}) => {
  return axiosClient.put(`/admin/customers/${customer_id}`, values);
}

const deleteCustomer = ({customer_id}) => {
  return axiosClient.delete(`/admin/customers/${customer_id}`);
}

const usePreofferNDADocumentsList = (id, pageConfig = {page:1, per_page:25}) => {
  return useQuery(
    ['preoffer', id, 'nda_list', pageConfig.page],
    () => {
      return axiosClient
        .get(`admin/preoffers/${id}/nda_documents.json`, {params: pageConfig})
    },
    {
      keepPreviousData: true,
    }
  );
}

const exportPreofferNDADocumentsList = (id) => {
  return axiosClient.getUri({
    url: `admin/preoffers/${id}/nda_documents.csv`
  });
}


export {
  usePreofferList,
  usePreoffer,
  updatePreofferEsignTemplate,
  usePreofferPitch,
  usePreofferSubscriberList,
  useExportPreofferSubscriberList,
  deletePreofferSubscription,
  usePreofferNDADocumentsList,
  exportPreofferNDADocumentsList,
  createPreoffer,
  updatePreoffer,
  deletePreoffer,
  updatePreofferStatus,
  updatePitch,
  addPitchSection,
  updatePitchSection,
  deletePitchSection,
  useBusinessModelList,
  usePitchCustomerList,
  addCustomer,
  updateCustomer,
  deleteCustomer,
};