import { marketSize } from "jsUtils/offerUtils";
import React from 'react'


export const CustomerSummaryRow = ({pitch, pitchSection}) =>{
  return ( pitch?.[pitchSection.sectionKey]?.length > 0 ? <Row name={pitchSection.sectionTitle}>
      {pitch?.[pitchSection.sectionKey].map((section, index) => {
        return <span key={index}>{section.name}</span>;
      })} </Row> : null
  )
}

export const BusinessSummaryRow = ({pitch, pitchSection}) =>{
  return ( pitch?.[pitchSection.sectionKey]?.length > 0 ? <Row name={pitchSection.sectionTitle}>
      {pitch?.[pitchSection.sectionKey].map((section, index) => {
        return <span key={index}>{section.name}</span>;
      })} </Row> : null
  )
}

export const MarketSummaryRow = ({pitch, pitchSection}) =>{
  return( pitch?.market_section?.market_size ? <Row name={pitchSection.sectionTitle}>
    <span>Approx. Market Size: </span>{" "}
    {marketSize(pitch?.market_section?.market_size)}
  </Row>: null
  )
}

export const TractionSummaryRow = ({pitch, pitchSection}) =>{
  return ( pitch?.traction_section?.points?.length > 0 ? <Row name={pitchSection.sectionTitle}>
    {pitch?.traction_section?.points.map((value, index) => {
      return <span key={index}>{value}</span>;
    })} </Row> : null
  )
}

export const GeneralSummaryRow = ({pitch, pitchSection}) => {
  return ( pitch?.[pitchSection.sectionKey]?.title ? <Row name={pitchSection.sectionTitle}>
      {pitch?.[pitchSection.sectionKey]?.title}
    </Row> : null
  )
}

const Row = ({children, name}) =>{
  if (!children) return null;

  return <div className="summary-row">
    <span className="section-title">{name}</span> 
    <span className="section-description">{children}</span>
  </div>
}