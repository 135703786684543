import React, { useState } from "react";
import * as Yup from "yup";
import { uploadDocument } from "redux/user";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Icon from "../Icon";
import "./UploadDocsModal.scss";
import { getAttachmentTypes } from "jsUtils/exceptionUtils";
import { useUser } from "../../../redux/user";
import LoadingOverlay from "react-loading-overlay";
let DocSchema = Yup.object().shape({
  title: Yup.string().required("Document title is required").max(150),
  attachment: Yup.mixed().required(
    '"PDF, JPEGS, PNG" type document is required'
  ),
  attachment_type: Yup.string().required("Attachment type is required"),
});

const uploadDocsmodalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  content: {
    borderRadius: 0,
    position: "fixed",
    padding: 0,
    transform: "none",
    width: window.innerWidth > 767 ? "700px" : "calc(100% - 0px)",
    top: window.innerWidth > 991 ? "180px" : "0px",
    right: "40px",
    bottom: window.innerWidth > 991 ? "40px" : "0px",
    left: "calc(50% - 350px)",
    backgroundColor: "rgba(0, 0, 0, 0.0)",
    border: "0",
    zIndex: "2",
  },
};

export default function UploadDocsModal({
  category,
  clientId,
  client_type,
  setShowDocsModal,
  setGetEntityDocuments,
}) {
  const fileReader = new FileReader();
  const [showFileUploader, setShowFileUploader] = useState(true);
  const [attachment, setImage] = useState(null);
  const { loading } = useUser();
  const onSubmit = () => {
    setGetEntityDocuments(true);
    setShowDocsModal(false);
  };

  return (
    <Modal
      portalClassName="portalized-modal"
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={() => setShowDocsModal(false)}
      style={uploadDocsmodalStyles}
    >
      <LoadingOverlay active={loading} spinner text="Loading">
        <div className="portalized-modal-wrapper">
          <div className="portalized-modal-header">
            <h5 className="portalized-modal-title">Upload Verification Docs</h5>
            <button
              className="drawer-close-btn"
              onClick={() => setShowDocsModal(false)}
            >
              <MdClose color="#fff" size="25px" />
            </button>
          </div>
          <div className="portalized-modal-body">
            <Formik
              initialValues={{
                attachment: "",
                title: "",
                attachment_type: "",
              }}
              validationSchema={DocSchema}
              enableReinitialize
              onSubmit={async (values) => {
                const payload = {
                  category: category,
                  name: values.title,
                  attachment_type: values.attachment_type,
                  attachment: values.attachment,
                };
                uploadDocument(clientId, payload, onSubmit);
              }}
            >
              {({
                values,
                touched,
                errors,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form>
                  {!errors.attachment &&
                    values.attachment !== "" &&
                    setShowFileUploader(false)}
                  {showFileUploader ? (
                    <div>
                      <p>PDF, JPEGS, PNG only</p>
                      <div className="form-group">
                        <label
                          htmlFor="attachment"
                          className="file-uploader w-100"
                        >
                          <div className="file-uploader-inner">
                            <div className="file-uploader-actions">
                              <Icon
                                name="fileUpload"
                                style={{
                                  paddingBottom: "0",
                                  filter: "inherit",
                                }}
                              />
                              <div>Upload document</div>
                            </div>
                          </div>
                        </label>
                        <input
                          type="file"
                          id="attachment"
                          name="attachment"
                          className={`form-control d-none ${touched.attachment && errors.attachment
                              ? "is-invalid"
                              : ""
                            }`}
                          onChange={(event) => {
                            setFieldTouched("attachment", true);
                            const file = event.currentTarget.files[0];
                            const type = file.type;
                            const error = !(
                              type.includes("jpg") ||
                              type.includes("png") ||
                              type.includes("pdf") ||
                              type.includes("jpeg")
                            );
                            if (!file || error) {
                              setFieldValue("attachment", "");
                              return;
                            }
                            fileReader.readAsDataURL(file);
                            fileReader.onload = function () {
                              setFieldValue("attachment", {
                                data: fileReader.result,
                                filename: file.name,
                              });
                            };
                            fileReader.onerror = function (error) {
                              console.log("image file Error: ", error);
                            };
                            setImage(URL.createObjectURL(file));
                          }}
                        />
                        <ErrorMessage
                          name="attachment"
                          component="div"
                          className="invalid-feedback text-left"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="uploaded-img-box">
                      <div className="inner1">
                        <div className="img">
                          {attachment && <img src={attachment} />}
                        </div>
                        <span className="file-name">
                          {values.attachment.name}
                        </span>
                      </div>
                      <div className="inner2">
                        <span
                          onClick={() => {
                            setFieldValue("attachment", "");
                            setShowFileUploader(true);
                          }}
                          className="cross-icon"
                        >
                          x
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="form-label-group">
                    <Field
                      type="select"
                      id="attachment_type"
                      className={`form-control ${touched.attachment_type && errors.attachment_type
                          ? "is-invalid"
                          : ""
                        }`}
                      name="attachment_type"
                      placeholder="attachment_type"
                      component="select"
                    >
                      <option value={""}>Attachment type</option>
                      {getAttachmentTypes(
                        client_type === "Individual" ? "person" : "entity",
                        category
                      ).map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Field>
                    <label htmlFor="attachment_type">Check type</label>
                    <ErrorMessage
                      name="attachment_type"
                      component="div"
                      className="invalid-feedback text-left"
                    />
                  </div>
                  <div className="form-label-group">
                    <Field
                      id="document-title"
                      name="title"
                      placeholder="Title"
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""
                        }`}
                    />
                    <label htmlFor="document-title">Title</label>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="invalid-feedback text-left"
                    />
                  </div>
                  <div className="portalized-modal-footer">
                    <button type="submit" className="btn-black">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}