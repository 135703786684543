import React from 'react';
import { MdClose } from 'react-icons/md';
import "./OverviewSubheader.scss";

export default function OverviewSubheader({left, right}) {
  return(
    <div className="overview-subheader bg-black text-white">
      <div className="overview-subheader-left poppins weight-500 fs-9">
        {left}
      </div>
      <div className="overview-subheader-right poppins weight-500 fs-9">
        {right}
      </div>
    </div>
  );
}
