import React from "react";
import { userApi } from "../../../../api/user";
import "./MyInvestments.scss";
import LoadingOverlay from "react-loading-overlay";
import HeaderTitle from "./HeaderTitle";
import { useQuery } from "react-query";
import { PrelaunchInterestCard } from "./Prelaunches";



const Secondaries = () => {
  const portfolio_prelaunches = useQuery(
    ['portfolio', 'prelaunch'],
    () => userApi.portfolioPrelaunches()
  );

  const {data: prelaunches = [], isLoading: loading} = portfolio_prelaunches;

  const secondaries = prelaunches?.filter((item) => item.preoffer.category === 'secondary');

  return (
      <div id="secondaries" className="pt-5">
        <HeaderTitle title="Secondaries" />
        <LoadingOverlay active={false} spinner text="Loading...">
          {secondaries.length > 0 ? (
            <div className="masonry-grid two-cols">
              {secondaries.map((prelaunch, index) => (
                <PrelaunchInterestCard key={index} interest={prelaunch} type="secondary" />
              ))}
            </div>
          ) : (
            <div className="no-data-found py-4 rounded bg-lightgrey fs-9 poppins">
             You haven't subscribed to any Secondaries.
            </div>
          )}
        </LoadingOverlay>
      </div>
  );
};

export default Secondaries;
