import React, {useState, useEffect} from 'react';
import { useRouteMatch, useParams } from "react-router";
import { format, parseJSON } from 'date-fns';
import { useQueryClient } from 'react-query';
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewHeader from 'admin/components/OverviewHeader'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import TextButton from 'admin/components/TextButton'
import ButtonDark from 'admin/components/ButtonDark'
import { offerApi } from 'api/offer'
import { EDIT_OFFER_OVERVIEW, EDIT_OFFER_DOCUMENTS, DEAL_TYPE_DEBT, DEAL_TYPE_EQUITY, OFFER_STATUS_CONSTANTS, OFFER_TYPE } from '../../config/constants';
import { FaEye, FaUserAstronaut, FaExternalLinkAlt, FaLink, } from "react-icons/fa";
import { humanize, toTitlecase } from '../../jsUtils/stringUtils';
import { getTimeZone } from '../../jsUtils/dateTimeUtils';
import OfferUpdates from '../components/OfferUpdates'
import LiveEvents from '../components/LiveEvents'
import InvestmentCloseModal from './InvestmentCloseModal';
import CloseableInvestments from './CloseableInvestments';
import ClosedInvestments from './ClosedInvestments';
import ToastMessage from '../../components/ToastMessage';
import LoadingOverlay from 'react-loading-overlay';
import { AuthContext } from 'contexts/authContext';
import { ACTIONS } from 'config/adminAbilities';
import InvestmentOverview from '../components/InvestmentOverview';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import ResponsiveTableWithRowSelect from './ResponsiveTableWithRowSelect';
import { formatCurrency } from 'jsUtils/currencyUtils';
import { calculateRemainingDays } from 'jsUtils/dateTimeUtils';
import AmountWithCurrency, {
  CurrencyBadge,
  AmountRange,
} from '../../components/AmountWithCurrency';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer'
import OfferDocumentsList from './OfferForm/OfferDocumentsList';
import LinkedData from '../../components/LinkedData';
import TableActionsContainer from './TableActionsContainer';
import TableActionItem from './TableActionItem';
import { hasDirectInvestors, hasSPVInvestors } from '../../jsUtils/offerUtils';
import { formatDate } from '../../jsUtils/dateTimeUtils';
import MaterialChanges from './MaterialChanges';
import { history } from 'redux/helpers';
import {
  useCloseableInvestments,
  useClosedInvestments,
  useOffer,
  useOfferInvestorList,
  exportInvestorDocumemtPackageUrl,
  useExportOfferInvestorsList
} from '../pages/offer/queries';

const getInvSum = (investments) => {
  let invAmountsSum = 0;

  if (!investments)
    return 0;

  investments.forEach(inv => {
    invAmountsSum += parseFloat(inv.amount)
  })
  return invAmountsSum;
}

function PerformanceOverview({ offer }) {
  const deal = offer?.deal;

  if (!deal) {
    return null;
  }

  return (
    <>
      <OverviewSubheader left='Performance' />
      <div className="two-col-layout">
        <div className="first-col p-3">
          <AttributeValue
            name={
              <>
                <span>Raised:</span>
                <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                  <AmountRange
                    start={deal.raised}
                    end={deal.maximum_goal}
                    separator="/"
                  />
                </span>
              </>
            }
            value={
              <div style={{ width: '300px', backgroundColor: '#fff' }}>
                <div style={{
                  width: `${Math.min(Math.max(deal.raised * 100 / deal.maximum_goal, 0), 100) || 0}%`,
                  height: '18px', backgroundColor: '#5AB4AD'
                }} />
              </div>
            }
          />
        </div>
        <div className="second-col p-3">
          <AttributeValue name='Number of investors' value={offer.investor_count || 0} />
        </div>
      </div>
      {hasDirectInvestors(offer) && <div className="two-col-layout">
        <div className="first-col p-3">
          <AttributeValue
            name={<span>Direct Investors Raised:</span>}
            value={<AmountWithCurrency number={deal.direct_raised_amount} />}
          />
        </div>
        <div className="second-col p-3">
          <AttributeValue name='Direct investors' value={offer.direct_investor_count || 0} />
        </div>
      </div>
      }
      {hasSPVInvestors(offer) && <div className="two-col-layout">
        <div className="first-col p-3">
          <AttributeValue
            name={<span>SPV Investors Raised:</span>}
            value={<AmountWithCurrency number={deal.spv_raised_amount} />}
          />
        </div>
        <div className="second-col p-3">
          <AttributeValue name='SPV investors' value={offer.spv_investor_count || 0} />
        </div>
      </div>

      }
    </>
  )
}

export default function OfferOverview() {
  const { authData } = React.useContext(AuthContext);
  let { offerSlug } = useParams()
  let { path, url } = useRouteMatch();

  const [investorsType, setInvestorsType] = useState('all');
  const {data:offer, isLoading } = useOffer(offerSlug)

  const userTimezone = React.useMemo(
    () => {
      return getTimeZone();
    }, []
  );

  const openEditorModal = (activeTabConstant) => {
    history.push({ pathname: `${url}/edit`, state: { activeTabConstant } })
  }

  if (isLoading){
    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading ...' />
    )
  }

  const canEditOffer = authData.permittedActions.has(ACTIONS.WRITE_UPDATE_OFFER)
  const closesTabVisible = offer.status === OFFER_STATUS_CONSTANTS.LIVE || offer.status === OFFER_STATUS_CONSTANTS.CLOSED

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading ...' >
      <OverviewHeader left={toTitlecase(offer.name)} right={ <OfferStatus offer={offer} /> } />
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#offer-overview" role="tab">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#offer-investors" role="tab">Investments</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#offer-updates" role="tab">Updates</a>
          </li>
          {
            offer.type === OFFER_TYPE.reg_cf &&
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#live-events" role="tab">Live Events</a>
            </li>
          }
          {
            offer.type === OFFER_TYPE.reg_cf &&
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#material-changes" role="tab">Material Change</a>
            </li>
          }
          {closesTabVisible && (
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#close-rolling" role="tab">Closes</a>
            </li>
          )}
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="offer-overview" role="tabpanel">
            <OverviewSubheader left='Offer Overview' right={canEditOffer ? <TextButton title='Edit' onClick={() => openEditorModal(EDIT_OFFER_OVERVIEW)} /> : null} />
            <TwoColumnLayout
              left={<AttributeValue name='Offer name' value={toTitlecase(offer.name)} />}
              right={<AttributeValue name='Company name' value={toTitlecase(offer.company.name)} />}
            />
            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Offer type' value={offer.type} />
                  <AttributeValue name='Deal type' value={offer.deal?.type} />
                  {offer?.deal?.type === DEAL_TYPE_DEBT && <>
                    <AttributeValue name='Discount' value={offer.deal?.discount} />
                    <AttributeValue name='Valuation cap' value={<AmountWithCurrency number={offer.deal?.valuation_cap} />} />
                  </>}
                  {
                    offer?.deal?.type === DEAL_TYPE_EQUITY &&
                    <AttributeValue name='Valuation' value={<AmountWithCurrency number={offer.deal?.valuation} />} />
                  }
                </>
              }
              right={
                <>
                  {offer?.deal?.type === DEAL_TYPE_DEBT && <>
                    <AttributeValue name='Type of security' value={humanize(offer.deal?.security_type)} />
                    {offer?.deal?.maturity_date && <AttributeValue name='Maturity date' value={offer?.deal?.maturity_date ? format(parseJSON(new Date(offer?.deal?.maturity_date)), 'MMM dd, yyyy') : "-"} />}
                    {offer?.deal?.interest_rate && <AttributeValue name='Interest rate' value={offer?.deal?.interest_rate ? `${offer?.deal?.interest_rate}%` : "-"} />}
                  </>}
                  {offer?.deal?.type === DEAL_TYPE_EQUITY && <>
                    <AttributeValue name='Price per share' value={<AmountWithCurrency number={offer.deal?.price_per_share} numberFormattingOpts={{ precision: Number.isInteger(Number(offer.deal?.price_per_share)) ? 0 : 2 }} />} />
                    <AttributeValue name='Share type' value={toTitlecase(offer.deal?.share_type)} />
                  </>}
                </>
              }
            />

            <TwoColumnLayout
              left={
                <>
                  {/* <AttributeValue name='Offer minimum goal' value={<AmountWithCurrency number={offer.deal?.minimum_goal} /> }/> */}
                  <AttributeValue name='Offer target goal' value={<AmountWithCurrency number={offer.deal?.goal} />} />
                  <AttributeValue name='Offer maximum goal' value={<AmountWithCurrency number={offer.deal?.maximum_goal} />} />
                </>
              }
              right={
                <>
                  <AttributeValue name='Closing date'
                    value={offer.deal?.close_date ? `${format(parseJSON(new Date(offer.deal?.close_date)), 'MMM dd, yyyy, h:mm a')} ${userTimezone}` : '-'}
                  />
                  {offer.listed_at &&
                        <AttributeValue name='Officially opened'
                        value={offer?.listed_at ? `${format(parseJSON(new Date(offer?.listed_at)), 'MMM dd, yyyy, h:mm a')} ${userTimezone}`: '-'}
                      />}                     
                  {offer.closed_at &&
                        <AttributeValue name='Officially closed'
                        value={offer?.closed_at ? `${format(parseJSON(new Date(offer?.closed_at)), 'MMM dd, yyyy, h:mm a')} ${userTimezone}`: '-'}
                      />}                  
                  <AttributeValue name='Investment minimum' value={<AmountWithCurrency number={offer.deal?.minimum_investment} />} />
                  <AttributeValue name='Investment maximum' value={<AmountWithCurrency number={offer.deal?.maximum_investment} />} />
                </>
              }
            />
            {offer.type === OFFER_TYPE.reg_d506b &&
              <TwoColumnLayout
                left={
                  <AttributeValue name='Investor Type' value={toTitlecase(humanize(offer.investor_type))} />
                }
                right={
                  offer.deal?.minimum_direct_investment ? <>
                    <AttributeValue name='Minimum direct investment' value={<AmountWithCurrency number={offer.deal?.minimum_direct_investment || 0} />} />
                    <AttributeValue name='Maximum direct investor count' value={offer.deal?.maximum_direct_investors_count || 0} />
                  </> : ' '
                }
              />
            }
            <OfferDocumentsList
              offerId={offer.slug}
              accessories={
                <TextButton title='Add Documents' onClick={ () => openEditorModal(EDIT_OFFER_DOCUMENTS) } />
              }
            />
          </div>

          <div className="tab-pane fade show" id="offer-investors" role="tabpanel">
            <PerformanceOverview offer={offer} />
            <InvestorListTable offer={offer} investorsType={investorsType} setInvestorsType={setInvestorsType} />
          </div>
          <div className="tab-pane fade show" id="offer-updates" role="tabpanel">
            <div className="row">
              <div className="col-12 col-md-12">
                <OfferUpdates.List offerId={offer.id} />
              </div>
            </div>
          </div>
          {offer.type === OFFER_TYPE.reg_cf &&
            <div className="tab-pane fade show" id="live-events" role="tabpanel">
              <div className="row">
                <div className="col-12">
                  <LiveEvents.List offerId={offer.slug} />
                </div>
              </div>
            </div>
          }
          <div className="tab-pane fade show" id="material-changes" role="tabpanel">
              <MaterialChanges offer={offer} />
          </div>
          {closesTabVisible && <Closes offerSlug={offer.slug} offerName={offer.name}/>}
        </div>
      </div>
    </LoadingOverlay>
  )
}

function InvestorListTable({offer, investorsType, setInvestorsType}) {
  const offerSlug = offer.slug
  const offerType = offer.type
  const [selectedInvestmentsRows, setSelectedInvestmentsRows] = useState([])
  // const [investorsType, setInvestorsType] = useState('all');
  const queryClient = useQueryClient();
  const { authData } = React.useContext(AuthContext);
  const allowSendMail = ['subscription_agreement_not_signed', 'payment_failed'].indexOf(investorsType) > -1;
  const canDownloadInvestorsCSV = investorsType === 'all' && authData.permittedActions.has(ACTIONS.READ_DOWNLOAD_INVESTORS)
  const isRegDOffer = offerType === OFFER_TYPE.reg_d506b;
  const sendEmailModal = React.useContext(PortalizedModalContext);
  const investmentDetailModal = React.useContext(PortalizedModalContext);
  const {data:investors, isLoading, refetch:refreshInvestorsList } = useOfferInvestorList(offerSlug, investorsType)
  console.log({investorsType});
  const showInvestmentDetail = (investment) => {
    investmentDetailModal.setModalContent(
      `${toTitlecase(
        investment.first_name + " " + investment.last_name
      )} Investment Info`,
      <InvestmentOverview
        investmentId={investment.investment_id}
        onCancel={() => investmentDetailModal.closeModal()}
        refreshInvestments={() => {
          refreshInvestorsList();
          investmentDetailModal.closeModal();
        }}
      />,
      "lg" //size
    );
    investmentDetailModal.openModal();
  };

  const _exportInvestors = useExportOfferInvestorsList({offer_slug: offerSlug, type: investorsType})

  useEffect(() => {
    if (selectedInvestmentsRows.length > 0)
      setSelectedInvestmentsRows([]);
  }, [investorsType]);

  const sendEmails = () => {
    const getNotificationType = (filterValue) => {
      switch (filterValue) {
        case 'subscription_agreement_not_signed': return 'subscription_agreement';
        case 'payment_failed': return 'payment_failure';
        default: return '';
      }
    }
    const attrs = {
      investment_ids: selectedInvestmentsRows,
      type: getNotificationType(investorsType)
    }

    offerApi.sendReminder(attrs).then(
      (response) => {
        ToastMessage.success("Reminder sent succesfully");
      },
      (error) => {
        renderRailsErrors(error)
      }
    );
  };

  const onSendEmail = () => {
    sendEmailModal.setModalContent(
      "Send email reminder?",
      <>
        <p>Are you sure you want to send email reminder to selected investors?</p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              sendEmails();
              sendEmailModal.closeModal();
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => sendEmailModal.closeModal()}
          >
            Cancel
          </button>
        </div>
      </>,
      'sm'
    );
    sendEmailModal.openModal();
  };

  const investorsHeadersColumns = [
    {
      Header: 'Investor name',
      accessor: 'first_name',
      Cell: ({ value, row }) => {
        return <LinkedData onClick={() => showInvestmentDetail(row.original)}>
          <FaUserAstronaut color="#676767" className="mr-2" />{" "}
          {toTitlecase([value, row.original.last_name].filter(Boolean).join(" "))}
        </LinkedData>
      }
    },
    {
      Header: 'Type',
      accessor: 'accredited',
      Cell: ({ value }) => value ? "Accredited" : "Retail"
    },
    {
      Header: <>Amount&nbsp;<CurrencyBadge /></>,
      accessor: 'amount',
      Cell: ({ value }) => formatCurrency(value, { precision: 2 })

    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => toTitlecase(humanize(value))
    },
    {
      Header: 'AML Exception',
      accessor: 'client_aml_status',
      Cell: ({ value }) => value ? toTitlecase(humanize(value)) : "NA"
    },
    {
      Header: 'Invested At',
      accessor: 'created_at',
      Cell: ({ value }) => format(parseJSON(value), "MMM dd, yyyy, hh:mm a")
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value, row }) => {
        return (
          <TableActionsContainer>
            <TableActionItem
              tooltipText="View"
              onClick={(e) => showInvestmentDetail(row.original)}
            >
              <FaEye />
            </TableActionItem>
          </TableActionsContainer>
        );
      }
    }
  ]

  if (isRegDOffer) {
    investorsHeadersColumns.splice(3, 0,
      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ value }) => value === 'none' ? "-" : toTitlecase(value)
      },
      {
        Header: 'BD Status',
        accessor: 'broker_dealer_status',
        Cell: ({ value }) => toTitlecase(humanize(value))
      },
      {
        Header: 'Suitability Questionaire At',
        accessor: 'suitability_questionaire_signed_at',
        Cell: ({ value }) => formatDate(value) || ''
      },
      {
        Header: 'AI Verified At',
        accessor: 'accreditation_verified_at',
        Cell: ({ value }) => formatDate(value) || ''
      }
    )
  }

  const exportInvestors = async () => {
    queryClient.refetchQueries(
      ['admin', 'offers', offerSlug, 'export-investors', investorsType],
      {
        enabled: true,
      }
    );
  }

  const filterOptions = [
    { label: 'All investments', value: 'all' },
    { label: 'Missing Sub Doc', value: 'subscription_agreement_not_signed' },
    { label: 'Payment Failure', value: 'payment_failed' }
  ]

  if (hasDirectInvestors(offer)) {
    filterOptions.push({
      label: 'Direct Investments', value: 'direct'
    })
  }

  if (hasSPVInvestors(offer)) {
    filterOptions.push({
      label: 'SPV Investments', value: 'spv'
    })
  }

  const renderHeader = () => {
    return (
      <OverviewSubheader
        left={
          <div className='investments-heading-wrapper'>
            <span>Investments</span>
            <select onChange={(e) => { setInvestorsType(e.target.value) }} className="custom-dropdown2 ml-2">
              {filterOptions.map((option, index) => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
          </div>
        }
        right={
          <div>
            {allowSendMail ? <ButtonDark title='Send Email' onClick={() => {
              if (selectedInvestmentsRows.length > 0)
                return onSendEmail()
              else ToastMessage.error('Select atleast 1 investor')
            }} /> : canDownloadInvestorsCSV ?
              <ButtonDark title='Export All' onClick={exportInvestors} /> : null}
            {
              offerType === OFFER_TYPE.reg_d506b && (
                <a href={exportInvestorDocumemtPackageUrl(offerSlug)} className="btn-black rounded-0 px-3 py-2 fs-9 text-decoration-none" download>Export Document Package</a>
              )
            }
          </div>
        }
      />
    )
  }
  if (isLoading) {
    return renderHeader()
  }

  return (
    <>
      {renderHeader()}
      {allowSendMail ? <ResponsiveTableWithRowSelect
        data={investors}
        columns={investorsHeadersColumns}
        setSelectedRows={setSelectedInvestmentsRows}
        selectableId={'investment_id'}
        loading={false}
        loadingMsg="Loading investments..."
        noDataMsg="No investments found."
      /> :
        <div className="table-responsive">
          <table className="table table-borderless table-hover table-row-clickable">
            <thead>
              <tr>
                <th scope="col" className="py-0 fs-10">Investor name</th>
                <th scope="col" className="py-0 fs-10">Type</th>
                <th scope="col" className="py-0 fs-10">Amount&nbsp;<CurrencyBadge /></th>
                <th scope="col" className="py-0 fs-10">Status</th>
                { isRegDOffer && (<>
                  <th scope="col" className="py-0 fs-10">Category</th>
                  <th scope="col" className="py-0 fs-10">BD Status</th>
                  <th scope="col" className="py-0 fs-10">Suitability Questionnaire At</th>
                  <th scope="col" className="py-0 fs-10">AI Verified At</th>
                  </>
                )}
                <th scope="col" className="py-0 fs-10">AML/KYC</th>
                <th scope="col" className="py-0 fs-10">Invested At</th>
              </tr>
            </thead>
            <tbody>
              {investors.length ? (
                investors.map((investor, index) => (
                  <InvestorRow
                    key={index}
                    item={investor}
                    isRegDOffer={isRegDOffer}
                    onRowClick={showInvestmentDetail}
                  />
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="20">
                    No investors.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>}
    </>
  );
}

function InvestorRow({ item, isRegDOffer, onRowClick }) {
  return (
    <tr className="f-black fs-10" style={{ backgroundColor: "#fff" }} onClick={() => onRowClick(item)}>
      <td className="d-flex align-items-center">
        <FaUserAstronaut color="#676767" className="mr-2" />{" "}
        <div>
          {toTitlecase(
            [item.first_name, item.last_name].filter(Boolean).join(" ")
          )}
        </div>
      </td>
      <td>{item.accredited ? "Accredited" : "Retail"}</td>
      <td className="text-nowrap">
        {formatCurrency(item.amount, { precision: 2 })}
      </td>

      <td>{toTitlecase(humanize(item.status))}</td>
      { isRegDOffer && <>
        <td>{item.category === 'none' ? '-' : toTitlecase(item.category)}</td>
        <td>{toTitlecase(humanize(item.broker_dealer_status))}</td>
        <td>{formatDate(item.suitability_questionaire_signed_at)}</td>
        <td>{formatDate(item.accreditation_verified_at)}</td>
        </>
      }
      <td>{toTitlecase(humanize(item.client_aml_status || "NA"))}</td>
      <td>{format(parseJSON(item.created_at), "MMM dd, yyyy, hh:mm a")}</td>
    </tr>
  )
}

function OfferStatus({offer}){
  return (
    <div className="action-button-container" style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
      {
        offer?.deal?.close_date && offer.status === 'live' &&
        <span className="poppins f-black fs-10">{calculateRemainingDays(offer.deal?.close_date)} days remaining</span>
      }

      <span
        className="btn-status-border-none text-capitalize ml-2 fs-10 poppins"
      >
      <span className={`user-status-circle ${offer.status === 'live' ? 'bg-success' : 'bg-danger'}`} />
        {offer.status?.replaceAll("_", " ")}
      </span>

      <button
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
        style={{fontSize:'13px'}}
        onClick={() => {
          try{
            navigator.clipboard.writeText(`${window.location.origin}/offers/${offer.slug}`).then(
              success => ToastMessage.success("Copied"),
              error => ToastMessage.error("Copy action not supported.")
            )
          }
          catch {
            ToastMessage.error("Copy action not supported.")
            alert(`Your public link is - ${window.location.origin}/offers/${offer.slug}`)
          }
        }}
      >
        Copy Link <FaLink className='ml-2 mr-2' />
      </button>

      <button
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
        style={{fontSize:'13px'}}
        onClick={() => window.open(`/admin/preview/offers/${offer.slug}`, "_blank") }
      >
          Preview <FaExternalLinkAlt className="ml-2"/>
      </button>
    </div>
  )
}

function Closes({offerSlug, offerName}) {
  const [roleCloseModal, setRoleCloseModal] = useState(false);
  const [investmentsToClose, setInvestmentsToClose] = useState([]);

  const {data:closeableInvestments, isLoading:loadingCloseableInvestments } = useCloseableInvestments(offerSlug)
  const {data:closedInvestments, isLoading:loadingClosedInvestments } = useClosedInvestments(offerSlug)

  if (loadingCloseableInvestments || loadingClosedInvestments) {
    return 'Loading...'
  }

  return (
    <div
      className="tab-pane fade show"
      id="close-rolling"
      role="tabpanel"
    >
      {roleCloseModal && (
        <InvestmentCloseModal
          offer_slug={offerSlug}
          investmentIds={investmentsToClose}
          onClose={() => setRoleCloseModal(false)}
        />
      )}
      <div className="row gap-x-3">
        <div className="col-lg-12">
          <div className="overview-subheader bg-black text-white mt-0">
            <div className="overview-subheader-left poppins weight-500 fs-9">
              List of Closes
            </div>
          </div>
          <ClosedInvestments offerName={offerName} data={closedInvestments} />
        </div> 
        <div className="col-lg-12 mt-lg-2 mt-4">
          <div className="overview-subheader bg-black text-white mt-0">
            <div className="overview-subheader-left poppins weight-500 fs-9">
              Investors Eligible for Close:&nbsp;
              <AmountWithCurrency
                number={getInvSum(closeableInvestments)}
                numberFormattingOpts={{ precision: 0 }}
              />
            </div>
            {closeableInvestments?.length > 0 && <div className="overview-subheader-right poppins weight-500 fs-9">
              <span
                className="cursor-pointer"
                onClick={() => {
                  if (investmentsToClose.length > 0)
                    setRoleCloseModal(true);
                  else
                    ToastMessage.error(
                      "Select atleast 1 investor to close"
                    );
                }}
              >
                Close Selected
              </span>
            </div>}
          </div>
          <CloseableInvestments
            data={closeableInvestments}
            setSelectedRows={setInvestmentsToClose}
          />
        </div>
      </div>
    </div>
  )
}