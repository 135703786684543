import React, { useState,  useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {  usePusherChannel } from '../../contexts/PusherChannelContext';
import { statusLabel } from '../LiveEvent/StatusUtil';

export default function EventStatus({ eventId, initialStatus, channelId }) {
  const pusherChannel = usePusherChannel(channelId);
  const [status, setStatus] = useState(initialStatus);
  const queryClient = useQueryClient();

  const onStatusChange = (status) => {
    setStatus(status);
    const events = queryClient.getQueryData(['svEvents', 1]);
    const event = events ? events.data.find((e) => e.id === eventId) : null;

    if(event){
      event.status = status;
      queryClient.setQueryData(['svEvents', 1], events);
    }

    ['current', eventId].forEach((id) => {
      const currentEvent = queryClient.getQueryData(['svEvent', id]);

      if(currentEvent){
        currentEvent.status = status;
        queryClient.setQueryData(['svEvent', id], currentEvent);
      }
    })
  }

  useEffect(() => {
    pusherChannel.bind('status-change', (data) => { onStatusChange(data.status)} );

    return () => {
      pusherChannel.unbind('status-change', () => {});
    }
  }, []);

  useEffect(() => { setStatus(initialStatus)}, [initialStatus]);

  const indicatorClass = status === 'completed' ? 'color-white achived-indicator' : 'color-success live-indicator';

  return (
    <span className={`poppins fs-12 weight-800 px-3 py-1 align-middle ${indicatorClass}`}>
      {statusLabel(status) || 'Past Event'}
    </span>
  )
}
