import PropTypes from 'prop-types';
import React from "react";
import OfferCardCompanyDescription from "../OfferCardCompanyDescription";
import { pluralize } from "jsUtils/stringUtils";
import {
  progressBarPercentage,
  raisedFundsPercentage,
} from "jsUtils/offerUtils";
import styled from "styled-components";
import regDIcon from "../../../assets/images/logos/icon/regd-logo.svg";

function Banner({ offer }) {
  const company = offer.company;

  return (
    <BannerContainer className="offer-card-banner" banner={company.banner_url}>
      <div className="offer-card-banner-header pb-3">
        <img src={regDIcon} alt="regd offer" />
      </div>
      <div className="offer-card-thumb-container">
        <div className="offer-card-thumb">
          <img
            className="offer-card-thumb-image"
            src={company.logo_url}
            alt="offer"
          ></img>
        </div>
      </div>
      <div className="offer-card-banner-content">
        <h4 className="weight-800 fs-6 montserrat text-white">
          {company.name}
        </h4>
        <div className="offer-card-badge-container">
          <div className="card-badge-layout">
            <div className="card-badge bg-muted-black mb-2 mr-2">
              <span className="card-badge-text color-white poppins">
                {company.parent_sector_name}
              </span>
            </div>
            <div className="card-badge bg-muted-black mb-2 mr-2">
              <span className="card-badge-text color-white poppins">
                {company.sector_name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  background: linear-gradient(to top, #111, transparent 60%),
    url(${(props) => props.banner});
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 230px;
  background-color: #111;
  display: grid;
  align-content: space-between;
  background-position: center 55px;
`;

function Summary({ offer }) {
  const fundsPercentage = raisedFundsPercentage(offer.deal.raised, offer.deal.goal);

  return (
    <div className="medium-progress-bar">
      <div className="progress-box">
        <div className="progressbar">
          <div
            className="progress"
            style={{ width: `${progressBarPercentage(fundsPercentage)}%` }}
          ></div>
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="funded">
          <span className="funded-text ml-1">{fundsPercentage}% Funded</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-text ml-1">{pluralize(offer.investor_count, "Investors", "Investor")}</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-text">BD: {offer.broker_dealer}</span>
        </div>
      </div>
    </div>
  );
};

function RegD506bOfferCard({ offer, onClick }) {
  return (
    <a className="unstyled" href={`/offers/${offer.slug}/pitch`} onClick={onClick} >
      <div className="offer-card-regd offer-card-dark">
        <Banner offer={offer} />
        <div className="offer-card-text-container offer-text-padding">
          <OfferCardCompanyDescription company={offer.company} />
          <Summary offer={offer} />
        </div>
      </div>
    </a>
  );
};


RegD506bOfferCard.defaultProps = {};

RegD506bOfferCard.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default RegD506bOfferCard;
