import React from 'react';
import { useParams, Link } from "react-router-dom";
import "./Pages.common.scss";
import InvestorDetailsForm from '../components/InvestorDetailsForm';
import LoadingOverlay from 'react-loading-overlay';
import { useArchivedIndividualAccount, useOffer } from '../../investor/queries';
import { usePreoffer } from './preoffer/queries';
import { useUser } from 'redux/user'
import { history } from '../../redux/helpers';
import { fetchCurrentUser } from '../../redux/user';

function HeaderTitle({ titlePrefix, titlePostFix, link, companyName }) {
  return (
    <div className="investment-header">
      <div className="container pb-3 pt-first-section">
        <h1 className="fs-2 weight-900 text-white mb-0">
          {titlePrefix && titlePrefix} <Link to={link} target="_blank" rel="noopener noreferrer">{companyName}</Link> {titlePostFix && titlePostFix}
        </h1>
      </div>
    </div>
  )
}

function OfferInvestorDetail({ offerId }) {
  const archivedIndividualAccount = useArchivedIndividualAccount();

  const {data: offer, isLoading: isLoadingOffer} = useOffer(offerId)

  if (isLoadingOffer || archivedIndividualAccount.isLoading) {
    return <LoadingOverlay
      active={true}
      spinner
      text='Loading ...' >
    </LoadingOverlay>
  }

  return (
    <>
      <HeaderTitle titlePrefix='Invest in' link={`/offers/${offer.slug}`} companyName={offer.company?.name} />
      <div className='investor-details-page-form-wrapper'>
        <InvestorDetailsForm
          submitButtonText="Continue"
          header="Before you invest, we need to verify your identity."
          subHeader="Please ensure your info is accurate. We use this info to verify your identity."
          showSidebar={true}
          redirect={true}
          offer={offer}
          type="offer"
          investorDetails={archivedIndividualAccount.data}
        />
      </div>
    </>
  );
}

function PreofferInvestorDetail({ preofferId }) {
  const { data: preoffer, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);

  if (isLoadingPreoffer) {
    return <LoadingOverlay
      active={true}
      spinner
      text='Loading ...' >
    </LoadingOverlay>
  }

  return (
    <>
      <HeaderTitle titlePostFix='Pre-launch' link={`/offers/${preoffer.slug}`} companyName={preoffer.company?.name} />
      <div className='investor-details-page-form-wrapper'>
        <InvestorDetailsForm
          submitButtonText="Continue"
          header="Please fill out your Investor Profile"
          subHeader="You only need to do this once. We will run an automated KYC and AML check when you invest."
          showSidebar={true}
          redirect={false}
          type="preoffer"
          onSubmit={() => {
            fetchCurrentUser().then(() => {
              history.push(`/prelaunches/${preoffer.slug}/indicate-interest`);
            })
          }}
        />
      </div>
    </>
  );
}

export default function InvestorDetails(props) {
  const { offerId, preofferId } = useParams()

  if (useUser().loading) {
    return <LoadingOverlay
      active={true}
      spinner
      text='Loading ...' >
    </LoadingOverlay>
  }

  if (offerId) {
    return <OfferInvestorDetail offerId={offerId} />
  }

  if (preofferId) {
    return <PreofferInvestorDetail preofferId={preofferId} />
  }

  return null;
}
