import React, {useContext} from "react";
import { useLocation } from "react-router-dom";
import OfferCardBanner from "./OfferCardBanner";
import OfferCardCompanyDescription from "./OfferCardCompanyDescription";
import PreofferCardSummary from "./PreOfferCardSummary";
import { preofferNDASigned } from "../../investor/queries";
import { history } from '../../redux/helpers';
import { useUser } from "../../redux/user";
import { PortalizedModalContext } from "../../contexts/portalizedModalContext";

function ndaPopup(preoffer, modal){
  const {name} = preoffer.company;
   modal.setModalContent(
      `Sign NDA to view ${name ?? ""} Pre-Launch `,
      <>
        <p>
        The details in {name ?? ""} pre-launch are confidential. You must sign an NDA to view {name ?? ""} and indicate interest.
        </p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              modal.closeModal();
              preofferNDASigned(preoffer.slug).then((data) => {
                if(data.signed){
                  history.push(`/prelaunch/${preoffer.slug}/pitch`);
                } else {
                  history.push(`/prelaunches/${preoffer.slug}/nda-sign`);
                }
              })
            }}
          >
            Sign NDA
          </button>
          <button
            className="btn-black-outline"
            onClick={() => {
              modal.closeModal();
              history.push("/offerings/all");
            }}
          >
            Go Back
          </button>
        </div>
      </>,
      "sm"
    );
   modal.openModal();
}

const PreofferCard = function ({ preoffer, onClick }) {
  const user = useUser();
  const userLoggedIn = user.authenticated;
  const locationPath = useLocation().pathname;
  const company = preoffer.company;
  //const ndaConfirmationModal = useContext(PortalizedModalContext);

  const handleClick = () => {
    onClick();

    if(!preoffer.nda) {
      return history.push(`/prelaunch/${preoffer.slug}/pitch`);
    }

    if(!userLoggedIn){
      return history.push({ pathname: '/login', state: { afterLoginPath: locationPath } });
    }

    history.push(`/prelaunch/${preoffer.slug}/pitch`);
    // preofferNDASigned(preoffer.slug).then((data) => {
    //   if(data.signed){
    //     history.push(`/prelaunch/${preoffer.slug}/pitch`);
    //   } else {
    //     history.push(`/prelaunches/${preoffer.slug}/nda-sign`);
    //   }
    // })
  }

  return (
    <a
      className="unstyled"
      href="#"
      onClick={handleClick}
    >
      <div className="offer-card">
        <OfferCardBanner offer={preoffer} type="preOffer" />
        <div className="offer-card-text-container offer-text-padding">
          <OfferCardCompanyDescription company={company} />
          <PreofferCardSummary preoffer={preoffer} />
        </div>
      </div>
    </a>
  );
};

export default PreofferCard;
