import axiosClient from 'config/axiosClient';

const admin = {};

admin.create = function({ id, attrs }) {
  return axiosClient.post(`/admin/offers/${id}/offer_updates`, attrs)
}

admin.update = function({ id, attrs }) {
  return axiosClient.put(`/admin/offer_updates/${id}`, attrs)
}

admin.list = function(offerId) {
  return axiosClient.get(`/admin/offers/${offerId}/offer_updates`)
}

admin.delete = function(id) {
  return axiosClient.delete(`admin/offer_updates/${id}`)
}

function list(offerId) {
  return axiosClient.get(`offers/${offerId}/offer_updates`)
}

function like({ id }) {
  return axiosClient.post(
    `offer_updates/${id}/like`,
    {},
    { errorMessage: 'You must login or sign up first to like an offer update'}
  )
}

function show(offerUpdateId){
  return axiosClient.get(`offer_updates/${offerUpdateId}`)
}

export const offerUpdateApi = {
  list,
  like,
  show,
  admin
};