import React from 'react';
import usePortalizedModal from './../hooks/usePortalizedModal';
import PortalizedModal from './../components/PortalizedModal';

const PortalizedModalContext = React.createContext();
const { Provider } = PortalizedModalContext

const ModalProvider = ({ children }) => {
  const { modal, openModal, closeModal, content, setModalContent } = usePortalizedModal();
  return (
    <Provider value={{ modal, openModal, closeModal, content, setModalContent }}>
      <PortalizedModal />
      {children}
    </Provider>
  );
};

export { PortalizedModalContext, ModalProvider };
