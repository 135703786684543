import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex:999
  },
  content: {
    borderRadius: 0,
    position: 'fixed',
    padding: 0,
    top: '50%',
    right: 'unset',
    bottom: 'unset',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 576 ? '500px' : 'calc(100% - 32px)',
    backgroundColor:'rgba(0, 0, 0, 0.0)',
    border:'0',
    zIndex: '2'
  }
}

export default function PopupModal({onRequestClose, children, title, size}) {
  if(size && size === 'lg') {
    modalStyles.content.width = window.innerWidth > 1024 ? '950px' : window.innerWidth > 576 ? '90%' : 'calc(100% - 32px)'
  } else {
    modalStyles.content.width =  window.innerWidth > 576 ? '500px' : 'calc(100% - 32px)'
  }

  return(
    <Modal
      portalClassName="fadein-modal"
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={onRequestClose}
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <div className="bg-white">
        {title && <div className="modal-header border-bottom-0 pt-3 px-3 pb-0 mb-2">
          <h5 className="modal-title title montserrat weight-700">{title}</h5>
        </div>}
        <button className="drawer-close-btn" style={{position: 'absolute', height: '30px',top: '15px', right: '16px', width:'20px', zIndex: 999}} onClick={onRequestClose} >
          <MdClose color="#000" size="25px" />
        </button>
        {children}
      </div>
    </Modal>
  );
}
