import create from 'zustand';
import produce from "immer";
import { discussionBoardApi } from 'api/discussionboard';
import { find } from 'underscore';

const initialState = {
  loading: false,
  postingDiscussion: false,
  deletingDiscussion: false,
  postingReply: false,
  deletingReply: false,
  discussions: [],
};

const findDisussionWithId = (discussions, id) => {
  for (const discussion of discussions) {
    if (discussion.id == id) return discussion

    for (const comment of discussion.comments){
      if (comment.id == id) return comment

      for (const reply of comment.replies) {
        if (reply.id == id) return reply
      }
    }
  }
  return null
}

const useDiscussionBoard = create(set => ({
  ...initialState,

  loadDiscussions: (discussableType, discussableId) => {
    set({loading: true});
    discussionBoardApi.loadDiscussions(discussableType, discussableId).then(
      (discussions) => {
        set({discussions});
      },
      (error) => {

      }
    ).finally(
      () => set({loading: false})
    )
  },

  postDiscussion: (discussableType, discussableId, attrs) => {
    set({postingDiscussion: true});
    discussionBoardApi.postDiscussion(discussableType, discussableId, attrs).then(
      (discussion) => {
        discussion.comments = [];
        set(state => produce(state, draftState => {draftState.discussions.unshift(discussion)}))
      },
      (error) => {

      }
    ).finally(
      () => set({postingDiscussion: false})
    )
  },

  updateDiscussion: (discussableType, discussableId, discussionId, attrs) => {
    set({updatingDiscussion: true});
    discussionBoardApi.updateDiscussion(discussableType, discussableId, discussionId, {discussion: attrs}).then(
      (discussion) => {
        set(state => produce(state, draftState => {
          const _discussion = findDisussionWithId(draftState.discussions, discussionId)
          _discussion.content = discussion.content
          _discussion.edited = discussion.edited
        }))
      },
      (error) => {

      }
    ).finally(
      () => set({updatingDiscussion: false})
    )
  },

  deleteDiscussion: (discussableType, discussableId, deleteId) => {
    set({deletingDiscussion: true});

    discussionBoardApi.deleteDiscussion(discussableType, discussableId, deleteId).then(
      (res) => {
        set(state => produce(state, draftState => {
          const _discussion = findDisussionWithId(draftState.discussions, deleteId)
          _discussion.content = res.content
          _discussion.deleted = res.deleted
        }))
      },
      (error) => {

      }
    ).finally(
      () => {
        set({deletingDiscussion: false})
      }
    )
  },


  postReply: (discussableType, discussableId, repliableId, attrs, type) => {
    set({postingReply: true});
    // const replyId = commentId ? commentId : discussionId;

    discussionBoardApi.postReply(discussableType, discussableId, repliableId, attrs).then(
      (reply) => {
        set(state => produce(state, draftState => {
          const _discussion = findDisussionWithId(draftState.discussions, repliableId)
          if(type === 'reply')
            _discussion.replies.push(reply)
          else
            _discussion.comments.push(reply)
        }))
      },
      (error) => {
      }
    ).finally(
      () => set({postingReply: false})
    )
  },


}))

export default useDiscussionBoard;