import React from 'react';
import { history } from 'redux/helpers'
import ShareButtons from '../../investor/features/raise_petition/components/ShareButtons';
import { rocketImgBlack } from '../../assets/images';

export default function IntestConfirmation ({ preoffer, subscription, onClose }) {
  const urlForOffer = (slug) => `${window.location.host}/prelaunch/${slug}`;
  const isSecondary = preoffer.category === "secondary";
  const preofferTypeText = isSecondary ? "Secondary" : "Pre-launch";
  const header = isSecondary ? `Welcome to the ${preoffer.company.name} Secondary` : `Welcome to ${preoffer.company.name}'s Pre-launch!`;
  const subheader = isSecondary ? `Thank you for your allocation request in ${preoffer.company.name}. We will update you by email with next steps.` : `Thank you for your Indication of Interest in ${preoffer.company.name}. We will update you by email when the offer becomes available.`;


  const handleClose = () => {
    onClose();
    history.push({
      pathname: `/prelaunches/${preoffer.slug}/subscription/${subscription.id}`,
      state: { showModal: false}
    });
  }

  return (
    <div className="investment-confirmation-content-box">
      <h4 className="inv-confirmation-heading">{header}</h4>
      <div className="w-1-dashed border-white mt-4"></div>
        <div className="eccb-fixed-width">
          <div className="inv-confirmation-message-box mt-4">
            <div className="row align-items-center">
              <div className="col-9 col-sm-8">
                <p className="fs-10 mb-2">{subheader}</p>
              </div>
              <div className="col-3 col-sm-4 text-center">
                <img src={rocketImgBlack} className="inv-confirmation-icon" alt="rocket" />
              </div>
            </div>
          </div>
          { preoffer.type !== "RegD" && <div className="mt-3">
            <div className="fs-19 f-white weight-700 mb-1 poppins">Support { preoffer.company?.name || "this company"} by sharing to:</div>
            <div className='d-flex align-items-end mt-2' style={{color: "#fff"}}>
              <ShareButtons offering={{}} targetUrl={urlForOffer(preoffer.slug)} />
            </div>
          </div>}
          <button className="btn-white-outline mt-4" onClick={handleClose} >Got it!</button>
        </div>
    </div>
  )
}

