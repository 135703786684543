import React, { useContext, useEffect } from "react";
import { useQueryClient, useMutation } from 'react-query';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OverviewSubheader from "../../components/OverviewSubheader";
import { updateVerificationField } from "./queries";
import { humanize } from "../../../jsUtils/stringUtils";
import ToastMessage from "../../../components/ToastMessage";
import { PortalizedModalContext } from "../../../contexts/portalizedModalContext";
import HookForm from "../../../components/HookForm";
import StatusBadge from "../../../components/StatusBadge";

function VerificationField({ field, updateHandler }) {
  return (
    <div className="highlighted-tile mb-1 d-flex flex-wrap">
      <div className="col-2">{humanize(field.name)}</div>
      <div className="col-2">
        <StatusBadge status={field.status} />
      </div>
      <div className="col-3">
        <span>{field.note}</span>
      </div>
      <div className="col-5 action-col">
        <div className="w-100 text-right">
          <button
            disabled={field.status === 'valid'}
            onClick={() => updateHandler({ name: field.name, status: 'valid', note: '' })}
            className="btn-black mr-2"
          >
            Mark Valid
          </button>
          <button
            disabled={field.status === 'invalid'}
            onClick={() => updateHandler({ name: field.name, status: 'invalid', note: field.note })}
            className="btn-black mr-2"
          >
            Mark Invalid
          </button>
          <button
            disabled={field.status !== 'invalid'}
            onClick={() => updateHandler({ name: field.name, status: 'invalid', note: field.note })}
            className="btn-black"
          >
            Edit Reason
          </button>
        </div>
      </div>
    </div>
  )
}

export default function VerificationFieldList({ amlException }) {
  const queryClient = useQueryClient();
  const updateModal = useContext(PortalizedModalContext);
  const mutation = useMutation(
    (values) => updateVerificationField({ amlExceptionId: amlException.id, values }),
    {
      onSuccess: (data, variables, context) => {
        const previousAmlException = queryClient.getQueryData(["aml_exception", amlException.client_id]);
        const index = previousAmlException.verification_fields.findIndex((f) => f.name == data.name);

        if (index > -1) {
          previousAmlException.verification_fields[index] = data;
          queryClient.setQueryData(["aml_exception", amlException.client_id], previousAmlException);
        }
      },
      onError: (error, variables, context) => {
        ToastMessage.error('Something went wrong. Please try after sometime.');
      },
      onSettled: () => { updateModal.closeModal() }
    }
  );

  const formValidationSchema = Yup.object().shape({
    status: Yup.string()
    // note: Yup.string().nullable().when('status', {
    //   is: 'invalid',
    //   then: Yup.string().required("Please enter the reason of invalidation.")
    // })
  });

  const methods = useForm({
    resolver: yupResolver(formValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    criteriaMode: "firstError",
    defaultValues: {
      note: "",
      status: null
    },
  });

  const updateHandler = ({ name, status, note }) => {
    const onSubmit = (values) => mutation.mutate({ name, status, note: values.note })

    methods.setValue('status', status);
    methods.setValue('note', note);

    updateModal.setModalContent(
      `Mark status as ${status}`,
      <>
        <p>Are you sure you want to mark it as "{status}"?</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {status === 'invalid' && (
              <div className="row">
                <div className="col">
                  <HookForm.TextArea name="note" placeholder="Reason of marking as invalid" rows={2} />
                </div>
              </div>
            )}
            <HookForm.HiddenInput name="status" type="hidden"/>
            <div className="portalized-modal-footer">
              <button
                type="submit"
                disabled={mutation.isLoading}
                className="btn-black"
              >
                Confirm
              </button>
              <button
                className="btn-black-outline"
                onClick={() => {
                  methods.reset();
                  updateModal.closeModal();
                }}
              >
                Close
              </button>
            </div>
          </form>
        </FormProvider>
      </>,
      "md"
    );

    updateModal.openModal();
  };

  return (
    <>
      <OverviewSubheader left={`${amlException.client.entity_type === 'person' ? 'Person' : 'Company'} Verification Fields Details`} />
      <div className="person-verification-table">
        {amlException.verification_fields.map((field, index) => <VerificationField key={index} field={field} updateHandler={updateHandler} />)}
      </div>
    </>
  );
}