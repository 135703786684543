import PropTypes from "prop-types";
import React from "react";
import { AuthContext } from 'contexts/authContext';
import { rollbarInstance } from 'jsUtils/rollbar';
import {useRaiseChartData} from "./../../../queries";

function LineChart({data, options={}, containerId}) {
  if(data?.length <= 3){ // One for headers, two data points.
    document.getElementById(containerId).innerHTML = `
      <div id="raise-chart">
        <div style="height: 200px;" class="d-flex flex-cover justify-content-center align-items-center">
          Not enough data to plot
        </div>
      </div>
    `
    return null
  }

  if(data) {
    window.google.charts.setOnLoadCallback(function() {
      const chartData = new window.google.visualization.arrayToDataTable(data)

      const lineChart = new window.google.visualization.LineChart(document.getElementById(containerId))

      const errorHandler = (error) => {
        console.log(error)
        rollbarInstance.error("Failed to plot petition data.", { data, options, error });
        document.getElementById(containerId).innerHTML = `
          <div id="raise-chart">
            <div style="height: 200px;" class="d-flex flex-cover justify-content-center align-items-center">
              Something went wrong. Please try again later.
            </div>
          </div
        `
       }

      window.google.visualization.events.addListener(lineChart, 'error', errorHandler);

      lineChart.draw(chartData, options);
    });
  }
  return null;
};

const RaiseChart = ({petitionId}) => {
  const { isAdmin } = React.useContext(AuthContext);
  const {data:chartData, isLoading} = useRaiseChartData(petitionId, isAdmin());

  if (isLoading) {
    return (
      <div id="raise-chart" style={{height: '270px'}}>
        <div className="flex-cover">Loading...</div>
      </div>
    )
  }

  return (
    <div className="line-Chart">
      <div id="raise-chart" style={{height: '270px'}}>
        <div className="flex-cover">Loading...</div>
      </div>
      <LineChart
        data= {chartData.data}
        options={{
          backgroundColor: 'transparent',
          pointsVisible: false,
          legend: 'none',
          colors: ['#FFF'],
          chartArea: {
            width: '100%',
          },
          curveType: 'function',
          vAxis: {
            gridlines: {
              color: 'transparent'
            },
            baselineColor: 'none',
            ticks: []
          },
          hAxis: {
            textPosition: 'none',
            gridlines: {
              color: 'transparent'
            },
            baselineColor: 'none',
            ticks: []
          },
        }}
        containerId='raise-chart'
      />
    </div>
  );
}

RaiseChart.defaultProps = {};

RaiseChart.propTypes = {
requiredProp: PropTypes.string.isRequired,
prop: PropTypes.string,
};
export default RaiseChart;
