import React from "react";
import { EditorState, CompositeDecorator, convertFromRaw, convertToRaw } from "draft-js";

export function createEditorStateWithContent (content) {
  if(content)
    return EditorState.createWithContent(convertFromRaw(content), editorDecorators)
  return EditorState.createEmpty(editorDecorators)
}

export function createEditorSaveContent (content) {
  return convertToRaw(content.getCurrentContent())
}

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK-EMBED"
      );
    },
    callback
  );
}

const RenderLink = (props) => {
  const {href} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={href} target="_blank" style={styles.link}>
      {props.children}
    </a>
  );
};

const editorDecorators = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: RenderLink,
  },
]);

const styles = {
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};