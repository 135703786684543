import React from "react";
import { userApi } from "../../../../api/user";
import "./MyInvestments.scss";
import LoadingOverlay from "react-loading-overlay";
import HeaderTitle from "./HeaderTitle";
import { useQuery } from "react-query";
import { formatMoneyClean } from "../../../../jsUtils/currencyUtils";
import { Link } from "react-router-dom"

const PetitionCard = ({ pledge }) => {
  return (
    <div className="masonry-grid-item">
      <div className="masonry-grid-item-inner">
        <div className="portfolio-card">
          <div
            className="portfolio-card-banner"
            style={{
              background: `linear-gradient(to top,#111,transparent 60%), url(${pledge.company.banner_url})`,
            }}
          >
            <div className="portfolio-card-thumb-container">
              <div className="portfolio-card-thumb">
                <img
                  className="portfolio-card-thumb-image"
                  src={pledge.company.logo_url}
                  alt="offer"
                />
              </div>
            </div>
            <div className="portfolio-card-banner-content">
              <h4 className="title">{pledge.company.name}</h4>
              <div className="portfolio-card-badge-container">
                <div className="card-badge-layout">
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {pledge.company.sector_name}
                    </span>
                  </div>
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {pledge.company.parent_sector_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-card-body">
            <div class="border p-3 rounded">
              <div className="text-muted">Your Pledge</div>
              <div className="weight-900 fs-6">{formatMoneyClean(pledge.amount)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Petitions = () => {
  const portfolio_petitions = useQuery(
    ['portfolio', 'petitions'],
    () => userApi.portfolioPetitions()
  );

  const {data: pledges = [], isLoading: loading} = portfolio_petitions;

  return (
    <div id="petitions" className="pt-5 section-bottom">
      <HeaderTitle title="Petitions" />
      <LoadingOverlay active={false} spinner text="Loading...">
        {pledges.length > 0 ? (
          <div className="masonry-grid two-cols">
            {pledges.map((pledge, index) => (
              <PetitionCard key={index} pledge={pledge} />
            ))}
          </div>
        ) : (
          <div className="no-data-found rounded bg-lightgrey py-4 fs-9 poppins">
            You haven't joined any petitions to raise. <Link to={`/offerings/raise-petitions`}>View Petitions</Link>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default Petitions;
