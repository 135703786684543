import React from 'react'
import { history } from 'redux/helpers'

export default function SuitabilityQuestionaireSection({ client }) {
  if(!client){
    return null;
  }

  const onClickHandler = () => {
    history.push(`/accounts/${client.id}/suitability-questionaire`);
  }

  return (
    <div className="investment-detail-requirement mb-2">
      <h6 className="montserrat weight-700 fs-8 mb-3">Submit Suitability Questionnaire</h6>
      <p>Please submit suitability questionnaire to complete the investment.</p>
      <span onClick={() => onClickHandler()} className="underline text-white fs-10 weight-600 cursor-pointer">Fill suitability questionaire</span>
    </div>
  )
}