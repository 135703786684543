import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import RichEditor from '../RichEditor';
import { createEditorStateWithContent, createEditorSaveContent} from 'admin/components/RichEditor/editorUtils';
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

const VisionSchema = Yup.object().shape({
  content: Yup.object()
    .test(
      'has text',
      'Article content is required',
      value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
    ),
  title: Yup.string()
    .required('Summary is required')
    .max(150),
});

export default function VisionForm({
  pitch:{vision_section={}, pitchable_id}, updateMethod
}) {
  const { offerSlug } = useParams()
  const queryClient = useQueryClient();
  
  if(vision_section === null) vision_section = {};

  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['admin', 'offers', offerSlug, 'pitch'],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
    <div id="visionForm">
      <Formik
        initialValues={{
          id: vision_section.id,
          title: vision_section.title || '',
          content: createEditorStateWithContent(vision_section.content),
        }}
        validationSchema={VisionSchema}
        enableReinitialize

        onSubmit={async values => {
          const { id, title, content } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                vision_section_attributes: {
                  id,
                  title,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <div className="form-group">
                  <div className="form-label-group show-org-placeholder">
                    <Field
                      id="vision-title"
                      name="title"
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                      rows="5"
                      component="textarea"
                      placeholder="Summary Title (max 150 chars)"
                    />
                    <label htmlFor="vision-title">Summary title (max 150 chars)</label>
                    <ErrorMessage name="title" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={vision_section.id} sectionType="vision"/>
            <LastUpdatedAt updatedAt={vision_section.updated_at}/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  );
}
