import React from "react";
import { useUser } from "redux/user";
import "../../Settings.scss";
import UpdateNameForm from "./UpdateNameForm";
import UpdateEmailForm from "./UpdateEmailForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import ResetOauthSignupPassword from "./ResetOauthSignupPassword";
import { PortalizedModalContext } from "contexts/portalizedModalContext";

function accountInfoupdateModal(modalType, accountInfoModal, user) {
  function onSubmit() {
    accountInfoModal.closeModal();
  }
  switch (modalType) {
    case "nameModal":
      accountInfoModal.setModalContent(
        `Change Name`,
        <UpdateNameForm onSubmit={onSubmit} />,
        "md"
      );
      accountInfoModal.openModal();
      break;
    case "emailModal":
      accountInfoModal.setModalContent(
        `Change Email`,
        <UpdateEmailForm onSubmit={onSubmit} />,
        "md"
      );
      accountInfoModal.openModal();
      break;
    default:
      accountInfoModal.setModalContent(
        `Change Password`,
        user.login_mode === "email" ? (
          <UpdatePasswordForm onSubmit={onSubmit} />
        ) : (
          <ResetOauthSignupPassword user={user} onSubmit={onSubmit} />
        ),
        "md"
      );
      accountInfoModal.openModal();
      break;
  }
}

export default function AccountDetails() {
  const user = useUser();
  const accountInfoModal = React.useContext(PortalizedModalContext);

  function fullName({ first_name, last_name }) {
    return first_name + " " + last_name;
  }

  return (
    <div id="account-info">
      <h4 className="weight-700 mb-3 zeitung-micro">Account Info</h4>
      <div className="info-updation-box">
        <div className="iu-box-inner1">
          <label>Name</label>
          <p>{fullName(user)}</p>
        </div>
        <div className="iu-box-inner2 pl-3">
          <span
            className="action-btn"
            onClick={() =>
              accountInfoupdateModal("nameModal", accountInfoModal, user)
            }
          >
            Edit
          </span>
        </div>
      </div>
      <div className="info-updation-box">
        <div className="iu-box-inner1">
          <label>Email:</label>
          <div>
            {user.email}
            {user.new_email && <span className="color-danger border ml-1 p-1">Change Email Requested</span>}
          </div>
        </div>
        <div className="iu-box-inner2">
          <span
            className="action-btn"
            onClick={() =>
              accountInfoupdateModal("emailModal", accountInfoModal, user)
            }
          >
            Edit
          </span>
        </div>
      </div>
      <div className="info-updation-box">
        <div className="iu-box-inner1">
          <label>Password</label>
          <p>****************</p>
        </div>
        <div className="iu-box-inner2">
          <span
            className="action-btn"
            onClick={() =>
              accountInfoupdateModal("passwordModal", accountInfoModal, user)
            }
          >
            Edit
          </span>
        </div>
      </div>
    </div>
  );
}
