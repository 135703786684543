import React from 'react'
import { format, parseJSON } from 'date-fns';
import { ResponsiveTableWithRowSelect } from 'admin/components';
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../components/AmountWithCurrency';
import { toTitlecase } from 'jsUtils/stringUtils';
export default function CloseableInvestments ({data, setSelectedRows}){

  const closeableInvestmentColumns = React.useMemo(
    () => {
      return [
        {
          Header: 'Investor name',
          accessor: 'investor_name',
          Cell: ({value}) => toTitlecase(value)
        },
        {
          Header: 'Inv. date',
          accessor: 'created_at',
          Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
        },
        {
          Header: <>Inv. amount&nbsp;<CurrencyBadge /></>,
          accessor: 'amount',
          Cell: ({value}) => <FormattedNumberWithSymbol number={value} />

        },
        {
          Header: 'Inv. status',
          accessor: 'status',
          Cell: ({value}) => toTitlecase(value)
        }
      ]
    }, []);

  return(
    <ResponsiveTableWithRowSelect
      columns={closeableInvestmentColumns}
      data={data}
      setSelectedRows={setSelectedRows}
      loading={false}
      loadingMsg="Loading investments..."
      noDataMsg="No eligible investments found."
    />
  )
}