import React, { useContext } from 'react'
import { format } from 'date-fns';
import { useQueryClient } from 'react-query'
import ToastMessage from '../../../components/ToastMessage';
import { utcToZonedTime } from 'date-fns-tz'
import { EDT_TIMEZONE_STRING } from 'config/constants';
import { offerApi } from '../../../api/offer';
import { history } from 'redux/helpers';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import moment from 'moment';


export default function CancellationDetail({ investment, closeDate, setLoader, rollingCloseDate }) {
  const queryClient = useQueryClient();
  const modal = useContext(PortalizedModalContext);
  const paymentMethod = investment?.payment_method?.type;
  const showACHWarning = investment?.status === 'funded' && paymentMethod === "ach";

  const onCancelPress = (investmentId) => {
    modal.setModalContent(
      `Cancel your investment`,
      <>
        <p className='instruction-text-for-mobile'>
          This action cannot be undone. Are you sure you want to cancel your
          investment on Spaced Ventures?
        </p>
        {showACHWarning && (<p><b>IMPORTANT:</b> It takes 60 days from the date the funds were first posted to escrow to return the funds. More info can be found <a target="_blank" href="https://spacedventures.zendesk.com/hc/en-us/articles/14550479396891">here</a>.</p>)}
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              setLoader(true);
              offerApi.deleteInvestment(investmentId).then(
                response => {
                  queryClient.invalidateQueries("investment", investmentId);
                  ToastMessage.success("Cancellation request being processed, you will receive an email when its complete.");
                  setLoader(false);
                  // history.push("/account/investments");
                },
                error => {
                  setLoader(false);
                  ToastMessage.error(error.message);
                })
              modal.closeModal();
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => modal.closeModal()}
          >
            Close
          </button>
        </div>
      </>,
      "sm"
    );
    modal.openModal();
  };

  function getXDaysBeforeDate(referenceDate, x) {
    return moment(referenceDate).subtract(x , 'day').format('MMM Do, YYYY');
  }
  
  return (
    <div className="mb-4 investment-handler-btn">
      <h5 className="montserrat weight-700 mb-2">Cancellation</h5>
      <div className="d-flex flex-wrap justify-content-between align-items-center py-2 bg-white montserrat mb-1">
        <div className="px-3 fs-10">Cancellation deadline</div>
        {rollingCloseDate ? 
            <div className="px-3 fs-10 weight-600">{getXDaysBeforeDate(rollingCloseDate, 2) ?? "-"}</div> : 
            <div className="px-3 fs-10 weight-600">{`${format(utcToZonedTime(closeDate, EDT_TIMEZONE_STRING), 'MMMM dd, yyyy, h:mm a', { timeZone: EDT_TIMEZONE_STRING }) ?? "-"} ET`}</div>
          }
      </div>
      <button onClick={() => onCancelPress(investment.id)} className="weight-700 btn-black-outline btn-block fs-9 px-2">Cancel Investment</button>
    </div>
  )
}