import React from "react";
import formatNumber from "accounting-js/lib/formatNumber.js";
import "./AmountWithCurrency.scss";

const DEFAULT_STYLING_CLASSES = {
  symbol: '',
  number: '',
  label: 'badge badge-dark rounded-0'
};

const DEFAULT_NUMBER_FORMATTING_OPTS = {
  precision: 2,
  thousand: ",",
  decimal: ".",
  stripZeros: false,
};

const DAFAULT_CURRENCY = {
  symbol: '$',
  label: 'USD',
};

export function CurrencyBadge ({
  currency,
  styleClasses,
}) {
  if(!currency)
    currency = DAFAULT_CURRENCY;

  if(!styleClasses) {
    styleClasses = DEFAULT_STYLING_CLASSES.label
  }

  return (
    <span className={styleClasses} style={{position: 'relative', bottom: '2px'}}>{currency.label}</span>
  )
}

export function FormattedNumber ({
  number,
  numberFormattingOpts,
}) {
  if(isNaN(Number(number)))
    number = 0
  else
    number = Number(number)

  if (!numberFormattingOpts || typeof(numberFormattingOpts) != 'object')
    numberFormattingOpts = DEFAULT_NUMBER_FORMATTING_OPTS;
  else
    numberFormattingOpts = Object.assign({}, DEFAULT_NUMBER_FORMATTING_OPTS, numberFormattingOpts);

  return (
    <>{formatNumber(number, numberFormattingOpts)}</>
  )
}

export function FormattedNumberWithSymbol ({
  number,
  numberFormattingOpts,
  styleClasses,
  useSymbol='$',
  symbolPosition="leading" // [leading | trailing]
}) {
  if(!styleClasses) {
    styleClasses = DEFAULT_STYLING_CLASSES
  }

  return (
    <>
      {
        symbolPosition === 'leading' && <span className={styleClasses.symbol}>{useSymbol}</span>
      }
      <FormattedNumber
        number={number}
        numberFormattingOpts={numberFormattingOpts}
      />
      {
        symbolPosition === 'trailing' && <span className={styleClasses.symbol}>{useSymbol}</span>
      }
    </>
  )
}

export function AmountRange ({
  start,
  end,
  currency,
  numberFormattingOpts,
  styleClasses,
  separator="-",
}) {

  if (!styleClasses || typeof(styleClasses) != 'object')
    styleClasses = DEFAULT_STYLING_CLASSES;
  else  
    styleClasses = Object.assign({}, DEFAULT_STYLING_CLASSES, styleClasses);

  if(!currency)
    currency = DAFAULT_CURRENCY;

  return (
    <div className="d-inline">
      <FormattedNumberWithSymbol
        number={start}
        numberFormattingOpts={numberFormattingOpts}
        styleClasses={styleClasses}
        useSymbol={currency.symbol}
        symbolPosition="leading"
      />
      {separator}
      <FormattedNumberWithSymbol
        number={end}
        numberFormattingOpts={numberFormattingOpts}
        styleClasses={styleClasses}
        useSymbol={currency.symbol}
        symbolPosition="leading"
      />
      &nbsp;
      <CurrencyBadge currency={currency} styleClasses={styleClasses.label}/>
    </div>
  )
}

export default function AmountWithCurrency ({
  number,
  currency,
  numberFormattingOpts,
  styleClasses,
}) {
  if (!styleClasses || typeof(styleClasses) != 'object')
    styleClasses = DEFAULT_STYLING_CLASSES;
  else  
    styleClasses = Object.assign({}, DEFAULT_STYLING_CLASSES, styleClasses);

  if(!currency)
    currency = DAFAULT_CURRENCY;

  return (
    <div className="d-inline">
      <FormattedNumberWithSymbol
        number={number}
        numberFormattingOpts={numberFormattingOpts}
        styleClasses={styleClasses}
        useSymbol={currency.symbol}
        symbolPosition="leading"
      />
      &nbsp;
      <CurrencyBadge currency={currency} styleClasses={styleClasses.label}/>
    </div>
  )
}
