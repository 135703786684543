import React from 'react';
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import IssuerProfile from './OfferForm/IssuerProfile';
import Pitch from './OfferForm/Pitch';
import Documents from './OfferForm/Documents';
import ButtonOutline from './shared/ButtonOutline'
import './../pages/Page.OfferEdit.scss';
import { calculateRemainingDays } from '../../jsUtils/dateTimeUtils';
import { humanize } from '../../jsUtils/stringUtils';
import { EDIT_OFFER_OVERVIEW, EDIT_OFFER_DOCUMENTS, OFFER_STATUS_CONSTANTS, EDIT_OFFER_SUB_DOC } from '../../config/constants';
import { FaExternalLinkAlt, FaPlay, FaPause, FaStop } from "react-icons/fa";
import LoadingOverlay from 'react-loading-overlay';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import SubDocTemplates from './OfferForm/SubDocTemplates';
import OfferDetailForm from './OfferForm/OfferDetailForm';
import { useOffer, useOfferPitch, updateOfferStatus } from '../pages/offer/queries';
import { useMutation, useQueryClient } from 'react-query';
import { renderRailsErrors } from '../../jsUtils/railsErrorRenderer';
import { usePortalizedModal } from '../../components/PortalModal';

export default function OfferEditor() {
  const { authData } = React.useContext(AuthContext);
  let { offerSlug } = useParams()
  const location = useLocation();
  const {data:offer, isLoading:loadingOffer } = useOffer(offerSlug)
  const {data:pitch, isLoading:loadingPitch } = useOfferPitch(offerSlug)

  const activeTabConstant = location?.state?.activeTabConstant;

  if (loadingOffer || loadingPitch) {
    return 'Loading Offer Editor...'
  }
  
  const company = offer.company;

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_OFFER)) {
    return <div>
      You are not authorised to create/edit Offer.
    </div>
  }

  return (
    <LoadingOverlay
      active={loadingOffer}
      spinner
      text='Loading ...' >
      <>
        <div className="custom-topbar offer-editor-topbar">
          <div className="offer-summary">
            Edit
            <span className="offer-name">{offer.name}</span> for {company?.name}
          </div>
          <div className="action-button-container">
            <OfferStatus offer={offer} />
            { offer.status === OFFER_STATUS_CONSTANTS.CREATED &&
              <span className="montserrat">{`${calculateRemainingDays(offer.deal.close_date)} days remaining`}</span>}
            <OfferControllers offer={offer} />
          </div>
        </div>

        <main>
          <div className="small-section-bottom">
            <div className="offer-editor">
              <div className="offer-editor-tab-nav">
                <ul className="nav nav-tabs" id="settings-tabs" role="tablist">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTabConstant == null ? 'active' : ''} ${company?.issuer_detail ? 'complete' : ''}`} data-toggle="tab" href="#issuer-profile" role="tab">
                      Issuer
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTabConstant === EDIT_OFFER_OVERVIEW ? 'active' : ''} ${offer.deal ? 'complete' : ''}`} data-toggle="tab" href="#offer-overview-info" role="tab">
                      Offer
                    </a>
                  </li>
                  
                  <li className="nav-item">
                    <a className={`nav-link ${pitch?.complete ? 'complete' : ''}`} data-toggle="tab" href="#offer-pitch" role="tab">
                      Pitch
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link complete optional ${activeTabConstant === EDIT_OFFER_DOCUMENTS ? 'active' : ''}`}
                      data-toggle="tab" href="#offer-documents" role="tab">
                        Documents
                      </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${offer.subscription_agreement_template_id ? 'complete' : ''} ${activeTabConstant === EDIT_OFFER_SUB_DOC ? 'active' : ''}`}
                      data-toggle="tab" href="#offer-sub-doc" role="tab">
                        Sub Doc
                      </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content offer-editor-tab-content" id="myTabContent">

                <div className={`tab-pane fade ${activeTabConstant == null ? 'show active' : '' }`} id="issuer-profile" role="tabpanel">
                  <IssuerProfile issuerDetails={company?.issuer_detail} companySlug={company.slug} editable={offer?.editable} />
                </div>

                <div className={`tab-pane fade ${activeTabConstant === EDIT_OFFER_OVERVIEW ? 'show active' : ''}`} id="offer-overview-info" role="tabpanel">
                  <OfferDetailForm offer={offer} companyId={company?.slug} editable={offer?.editable} />
                </div>

                <div className="tab-pane fade" id="offer-pitch" role="tabpanel">
                  <Pitch offer={offer} />
                </div>

                <div className={`tab-pane fade ${activeTabConstant === EDIT_OFFER_DOCUMENTS ? 'show active' : ''}`} id="offer-documents" role="tabpanel">
                  <Documents offer={offer} />
                </div>
                <div className={`tab-pane fade ${activeTabConstant === EDIT_OFFER_SUB_DOC ? 'show active' : ''}`} id="offer-sub-doc" role="tabpanel">
                  <SubDocTemplates offer={offer} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </LoadingOverlay>
  )
}

function OfferStatus({offer}){
  let classNames = 'user-status-circle';
  if(offer.status === OFFER_STATUS_CONSTANTS.LIVE) {
    classNames += ' bg-success';
  }
  else if(offer.status === OFFER_STATUS_CONSTANTS.CLOSED) {
    classNames += ' bg-danger';
  }
  else {
    classNames +=  ' bg-white';
  }
  return (
    <>
      <span className="btn-status text-capitalize fs-10 poppins">
				<span className={classNames} />
          {humanize(offer.status)}
				</span>
		</>
  )
}

function OfferControllers({offer}) {
  const queryClient = useQueryClient();
  const canGoLive = offer?.status === OFFER_STATUS_CONSTANTS.CREATED && offer?.publishable;
  const statusUpdateConfirmModal = usePortalizedModal(`confirm-offer-status-update-${offer.slug}`)

  const updateOfferStatusMutation = useMutation(updateOfferStatus, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['admin', 'offers',]);
      statusUpdateConfirmModal.close();
    },
    onError: (error) => {
      renderRailsErrors(error)
      statusUpdateConfirmModal.close();
    } 
  })

  const mutateStatusWithConfirmation = (nextStatus) => {
    statusUpdateConfirmModal.open({
      title: 'Update offer status',
      body: <>
        <p>Are you sure you want to update offer status to - {nextStatus}?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              updateOfferStatusMutation.mutate({offer_slug: offer.slug, nextStatus})
            }}
          >
            Confirm
          </button>
          <button className="btn-black-outline"
            onClick={statusUpdateConfirmModal.close}
          >
            Cancel
          </button>
        </div>
      </>,
      onRequestClose: statusUpdateConfirmModal.close,
      size: 'sm'
    })
  }

  return (
    <>
      <ButtonOutline icon={<FaExternalLinkAlt className="mr-2" />} text="Preview" onClick={() =>
          window.open( `/admin/preview/offers/${offer?.slug}`, "_blank")
      }/>

      {offer?.status !== OFFER_STATUS_CONSTANTS.CLOSED && offer?.status !== OFFER_STATUS_CONSTANTS.LIVE &&
        <ButtonOutline
          text="GO LIVE"
          disabled={!canGoLive}
          onClick={() => mutateStatusWithConfirmation('live')}/>}

      {(offer?.status === OFFER_STATUS_CONSTANTS.LIVE || offer?.status === OFFER_STATUS_CONSTANTS.PAUSED)&&
        <>
          <ButtonOutline
            text="Close"
            additionalClasses='offer-controller'
            icon={<FaStop className="mr-2" />}
            onClick={() => mutateStatusWithConfirmation('closed')}/>

          <ButtonOutline
            text={offer?.status === OFFER_STATUS_CONSTANTS.PAUSED ? 'Resume' : 'Pause' }
            additionalClasses='offer-controller'
            icon={offer?.status === OFFER_STATUS_CONSTANTS.PAUSED ? <FaPlay className="mr-2" /> : <FaPause className="mr-2" />}
            onClick={() => mutateStatusWithConfirmation(offer?.status === OFFER_STATUS_CONSTANTS.PAUSED ? 'live' : 'paused')}/>
        </>
      }
    </>
  );
}
