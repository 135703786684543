import React, { useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from 'react-query';
import { updateSelfAttestation, useUser } from "../redux/user"
import LoadingOverlay from 'react-loading-overlay';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { userApi } from '../api/user';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { history } from '../redux/helpers';

function InvestorTypeInput({ value, title, description, register }) {
  return (
    <div className='row bg-black py-4 px-2 mb-2'>
      <div className='col-12 col-md-10'>
        <div className='row'>
          <div className='col-1'>
            <div className="form-group">
              <label className={`form-check-label radio-inverted-large`}>
                <input
                  {...register("investor_type")}
                  name='investor_type'
                  type="radio"
                  value={value}
                />
              </label>
            </div>
          </div>
          <div className='col-12 col-md-8'>
            <div className='poppins fs-18 weight-700 color-white'>
              {title}
            </div>
            <div className='poppins fs-default color-white mt-1'>
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AccriditedTypeInput({ value, description, register }) {
  return (
    <div className='row bg-black py-4 px-2 mb-2' >
      <div className='col-12'>
        <div className='row align-self-center'>
          <div className='col-1'>
            <div className="form-group">
              <label className={`form-check-label radio-inverted-large`}>
                <input
                  {...register("accredited_type")}
                  name='accredited_type'
                  type="radio"
                  value={value}
                />
              </label>
            </div>
          </div>
            <div className='col-12 col-md-8 poppins fs-default color-white'>
              <div>{description}</div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default function SelfAttestationPage() {
  const user = useUser();
  const { action } = useParams();

  const investorTypes = [
    {
      fieldName: 'investor_type',
      value: 'retail',
      title: 'I’m a Retail Investor',
      description: 'A retail investor is a non-professional investor with an income of less than $200K and a net worth of less than $1M.'
    },
    {
      fieldName: 'investor_type',
      value: 'accredited',
      title: 'I’m an Accredited Investor',
      description: 'To qualify as an accredited investor, you must have income over $200K or a net worth of over $1M, or a qualified professional designation.'
    }
  ];

  const accreditedTypes = [
    {
      fieldName: 'accredited_type',
      value: 'income',
      description: 'Individuals who have an income greater than $200,000 in each of the past two years or whose joint income with a spouse is greater than $300,000 for those years, and a reasonable expectation of the same income level in the current year'
    },
    {
      fieldName: 'accredited_type',
      value: 'net_worth',
      description: 'Individuals who have an individual net worth, or joint net worth with the person’s spouse, that exceeds $1 million at the time of the investment (The net worth amount cannot include the value of the person’s primary residence.)'
    },
    {
      fieldName: 'accredited_type',
      value: 'certified',
      description: 'Individuals who hold certain certificates, designations, or credentials, such as Series 7, Series 65, and Series 82 licenses'
    },
    {
      fieldName: 'accredited_type',
      value: 'knowledgeable',
      description: 'Individuals who are “knowledgeable employees” of a private fund'
    },
  ];

  const schema = Yup.object().shape({
    investor_type: Yup.string().oneOf(['retail', 'accredited'])
      .required('Please select investor type'),
    accredited_type: Yup.string()
      .typeError('Please select accredited type')
      .when('investor_type', {
        is: 'accredited',
        then: (schema) => schema.oneOf(
          ['income', 'net_worth', 'certified', 'knowledgeable'],
          'Please select accredited type'
        )
      }),
  });

  const mutation = useMutation(userApi.selfAttestation,
    {
      onSuccess: (values) => {
        updateSelfAttestation(values);
        if(action === 'edit'){
          history.push('/account/investor-info')
        };
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      investor_type: user?.investor_type === 'none' ? 'retail' : user?.investor_type,
      accredited_type: user.accredited_type || 'none'
    },
  });

  const watchInvestorType = methods.watch("investor_type", user.investor_type);
  const onSubmit = (values) => {
    mutation.mutate({ user: values });
  }

  return (
    <LoadingOverlay
      active={user.loading}
      spinner
      text='Loading ...'
    >
      <div className="disclaimer-bg min-h-100vh">
        <div id="disclaimerPage" className="main-section">
          <div className="container p-0">
            <div className="disclaimer-header">
              <h1 className="weight-900 fs-3">Investor Type</h1>
              <span className="poppins fs-7">
              Investor type is determined by your income or net worth and is used for compliance reasons to determine investment limits.
              </span>
            </div>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="container mt-5">
                {investorTypes.map((investorType, index) => <InvestorTypeInput key={index} {...investorType} register={methods.register} />)}
              </div>
              {watchInvestorType === 'accredited' && (
                <>
                  <div className="container mt-5 mb-3 p-md-0">
                    <div className='fs-7 weight-800 montserrat'>You can be accredited in the following ways:</div>
                  </div>
                  <div className="container">
                    {accreditedTypes.map((accreditedType, index) => <AccriditedTypeInput key={index} {...accreditedType} register={methods.register} />)}
                    {methods.formState.errors.accredited_type && (
                      <div className="invalid-feedback text-left d-inline">
                        {methods.formState.errors.accredited_type.message}
                      </div>)
                    }
                  </div>
                </>
              )}
              <div className="container mt-4 p-0 center-below-med-sc">
                <button type="submit" className="mx-auto btn-login px-5 col-8 col-sm-4">Continue</button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </LoadingOverlay>
  )
}

