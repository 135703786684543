import React, {useState, useEffect, useContext} from 'react'
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import { history } from 'redux/helpers';
import HookForm from '../../components/HookForm';
import { renderRailsErrors } from '../../jsUtils/railsErrorRenderer';
import { runGAEventWithValue } from '../components/Tracking';
import ToastMessage from '../../components/ToastMessage';
import { fbEvent } from '../components/Tracking/facebook';
import { subscribePreoffer } from '../../investor/queries';
import Terms from './Terms';
import InvestmentSummary from './InvestmentSummary';
import EntityDetailsForm from '../../admin/components/EntityDetailsForm';
import { PortalizedModalContext } from '../../contexts/portalizedModalContext';
import { formatMoneyClean } from '../../jsUtils/currencyUtils';

export default function InvestmentForm({ user, preoffer }) {
  const preofferId = preoffer.slug;
  const individualClient = user.clients.find(c => c.entity_type === "person");
  const newEntityModal = useContext(PortalizedModalContext);
  const preofferType = preoffer?.type;

  const FormSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Invalid amount').positive()
      .integer("Please enter an amount without cents")
      .max(1000000, 'Maximum indication amount is $1,000,000. Email venture@spacedventures.com if you’re interested in a higher allocation.')
      .min(preoffer.minimum_investment_amount, `Minimum investment is ${formatMoneyClean(preoffer.minimum_investment_amount)}`),
    terms: Yup.boolean()
      .required("Please accept the Pre-launch terms.")
      .oneOf([true], "Please accept the Pre-launch terms.")
  });

  const defaultValues = {
    amount: null,
    client_id: individualClient.id,
    terms: false,
    preofferType: preofferType
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: defaultValues
  });
  const watchClientId = methods.watch("client_id", individualClient.id);
  const watchAmount = methods.watch("amount", 0.0);
  const queryClient = useQueryClient();

  const subscribePreofferMutation = useMutation(
    subscribePreoffer,
    {
      onSuccess: (values) => {
        methods.reset()
        runGAEventWithValue(`${preoffer.company.name} subscriber added`, "button", "clicked", values.amount)
        runGAEventWithValue("New Reservation", "form submission", "success", values.amount)
        fbEvent(`${preoffer.company.name} ttw subscriber added`, values.amount);
        queryClient.invalidateQueries(
          ['preoffer', preofferId], { exact: true }
        )
        queryClient.invalidateQueries(['prelaunch'])
        if(values.esign_required){
          history.push(`/prelaunches/${preofferId}/interest-agreement/${values.id}`);
        } else {
          ToastMessage.success(`Thanks for your interest. We will update you by email when the offer becomes available.`);
          history.push({
            pathname: `/prelaunches/${preoffer.id}/subscription/${values.id}`,
            state: {
              showModal: true,
              companyName: preoffer.company.name
            },
        });
        }
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  )

  const onSubmit = (values) => {
    subscribePreofferMutation.mutate({
      preoffer_id: preoffer.slug,
      values: { subscriber: values }
    })
  }

  const clientOptions = individualClient ? [{ label: "Myself / Individual", value: individualClient.id }] : [];

  user.clients.forEach(c => {
    if (c.entity_type !== "person") {
      clientOptions.push({ label: c.name, value: c.id });
    }
  });

  clientOptions.push({ label: "Add New LLC", value: "new-llc-account" });
  clientOptions.push({ label: "Add New Trust", value: "new-trust-account" });

  function onEntitySubmit(investorAccountId) {
    methods.setValue("client_id", investorAccountId)
    newEntityModal.closeModal();
  }

  useEffect(() => {
    if (["new-llc-account", "new-trust-account"].indexOf(watchClientId) > -1) {
      const entityText = watchClientId === 'new-trust-account' ? 'Trust' : 'LLC';
      const entityType = watchClientId === 'new-trust-account' ? 'trust' : 'company';

      newEntityModal.setModalContent(
        `Investing as ${entityText}`,
        <EntityDetailsForm
          submitButtonText={`Add ${entityText}`}
          entityDetail={ { entity_type: entityType }}
          onSubmit={onEntitySubmit}
          type="preoffer" />,
        'md'
      );
      newEntityModal.openModal();
      methods.setValue("client_id", individualClient.id);
    }
  }, [watchClientId]);

  const selectedClient = user.clients.find(c => c.id == watchClientId);
  let btnText = preoffer.category === "secondary" ? "Request Allocation" : "Indicate Interest";


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1" autoComplete="off">
        <h5 className="montserrat weight-700 m-b-15">Amount</h5>
        <div className="row mt-2">
          <div className="col input-bg-white">
            <HookForm.CurrencyInputField name="amount" placeholder="Amount" />
          </div>
        </div>
        <h5 className="montserrat weight-700 m-b-15 mt-4">Investing As</h5>
        <div className="row mt-2">
          <div className="col input-bg-white">
            <HookForm.SelectInput
              name="client_id"
              options={clientOptions}
            />
          </div>
        </div>
        <div className="m-b-45">
          <h5 className="montserrat weight-700 m-b-15 mt-4">Review the Details</h5>
          <div className="bordered-items-box mb-4">
            <Terms preoffer={preoffer} />
            <div className="bordered-item">
              <HookForm.CheckboxField name="terms" label="I understand" />
            </div>
          </div>
        </div>
         <div className="pr-xl-5 m-b-30">
          <InvestmentSummary investingAs={ selectedClient?.entity_type === 'person' ? 'Myself / Individual': selectedClient?.name } amount={watchAmount} />
        </div>
        <div className="form-group mt-3">
          <button type="submit"
            className="poppins weight-700 btn-black"
            disabled={subscribePreofferMutation.isLoading}
          >
           {btnText}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}