import React, { useEffect } from 'react'
import styled from 'styled-components';
import buttonRocketIcon from '../../assets/images/icons/button-rocket-icon-lg.png'

const ApplyToRaise = ({
  link = "https://forms.gle/Va3NzaRbhiFVcwHG7",
  text = "Apply to Raise",
  width = "100%",
  align = "left",
  icon = true,
  eventCategory = "apply to raise",
  eventAction = "apply cta click",
  eventLabel = "cta",

}) => {

  return (
    <NewsLetterContainer width={width} align={align}>
      <div>
        <a className="link-decor-none" href={link} target="_blank" rel="noopener noreferrer">
          <button className="poppins weight-600 btn-block btn-black">
            <div className="newsletter-btn">
              <div className="newsletter-btn-text">{text}</div>
              {icon && <img className="newsletter-btn-icon" src={buttonRocketIcon} alt="rocket"></img>}
            </div>
          </button>
        </a>
      </div>
    </NewsLetterContainer>
  )
}

export default ApplyToRaise;

const NewsLetterContainer = styled.div`
  width: ${props => props.width};

  button {
    text-align: ${props => props.align};
    padding-left: ${props => props.align === 'left' ? "27px" : "20px"};
    border-radius: 2px;
    height: 55px;
  }

  button:hover {
    border-radius: 2px;
  }

  @media (max-width: 776px) {
    width: 100%;
  }

  @media (max-width: 776px) {
    button {
      text-align: center;
      padding-left: 11px;
    }

    .newsletter-btn-icon {
      display: none;
    }

    .newsletter-btn {
      justify-content: center;
    }
  }

`;