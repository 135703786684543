import React from "react";
import { useHistory } from "react-router-dom";

const trackingEnabled = process.env.REACT_APP_HUBSPOT_TRACKING_ENABLED === 'true';

export const setHubspotIdentity = ({ userId, email }) => {
  console.log(userId, email)

  if (trackingEnabled) {
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['identify', { email: email, id: userId }]);
  };
}

const setPageView = (path) => {
  if (trackingEnabled) {
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
  };
}

export function HubspotPageViewTracking(){
  const history = useHistory();

  React.useEffect(() => {
    history.listen(() => {
      console.log('Tracking --- ', history.location.pathname)
      setPageView(history.location.pathname)
    });
  }, [history]);

  return null;
}
