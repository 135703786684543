import React, { useEffect } from 'react'
import { PageViewFB } from './components/Tracking/facebook';
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet";


import SpacedTeam from './components/SpacedTeam'
import workWithUsGraphicTable from '../assets/images/graphics/work-with-us-graphic.png'


function AboutBanner({ children }) {
  return (
    <div>
      <div className="container">
        <div className="page-header-1">
          <h2 className="hero-header-main">We Are<br></br> Spaced<br></br> Ventures</h2>
          <div className="py-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

function WhatWeAreBuilding () {
  return (
    <div className="planet-banner">
      <div className="section-bottom container">
        <div className="about-banner-text">
          <h2 className="fs-4 weight-900">What we’re building</h2>
          <p className="weight-300 m-t-20 fs-default lh-md">We are building the world’s largest community of space investors. A community that comes from a diverse set of social, economic, and national backgrounds, but all sharing a common goal – sending humanity to the stars.</p>
          <p className="weight-300 fs-default lh-md">We are working to build an ecosystem where space startup founders not only get the funds they need, but also the support and guidance to go the distance. We hope that the next name-brand space company will be a Spaced Ventures alum.</p>
        </div>
      </div>
    </div>
  )
}

function SpacedPodcast() {
  return (
    <div id="space-channel" className="podcast-bg">
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div>
         <div className="podcast-text-container">
            <h2 className="fs-4 weight-900">In The News</h2>
            <p className="weight-300 m-t-20 fs-default lh-md">Space Channel invited us on their space economy show to discuss the space industry, opportunities for space investment and what we’re building at Spaced Ventures.</p>
         </div>
          <ReactPlayer
            url="https://vimeo.com/video/442478853"
            controls={true}
            className="podcast-player"
            width="100%"
          />
      </div>
      </div>
    </div>  
  )
}

function WorkWithUs() {
  return (
    <div className="section-divider">
      <div className="container work-with-us-section">
        <div className="work-with-us-section-grid">
          <img className="work-with-us-graphic" src={workWithUsGraphicTable} alt="work with spaced ventures"></img>
          <div className="work-with-us-text">
            <h2 className="fs-4 weight-900">Work With Us!</h2>
            <p className="fs-9 weight-300 m-t-35">If you’re a space geek and have exceptional skills that could help us achieve our vision, we want to hear about it! We are specifically looking to add team members with experience in front-end software development, fintech product management, marketing, business development, operations, data analysis, and research. Drop us a note at <a className="footer-link" href="mailto:hello@spacedventures.com">hello@spacedventures.com.</a></p>
          </div>
        </div>
      </div>
    </div>    
  )
}

function About() {
    useEffect(() => {
      console.log("use effect ran for google analytics on about")
      PageViewFB();
    }, [])
  
    return (
      <>
        <Helmet>
          <title>About</title>
          <meta
            name="description"
            content="The world’s largest community of space investors. We are a group of doers and dreamers who want to live in a world where vacationing on the moon or living on Mars isn’t just science fiction but a real option. We are passionate about entrepreneurship, finance and all things space, and are deeply committed to the Spaced Ventures mission of making the industry accessible to companies and investors of all sizes."/>
        </Helmet>          
        <div className="about-img-container">
          <AboutBanner>
            <div className="about-banner-text m-t-45 section-bottom">
              <h2 className="fs-4 weight-900">Who's Building It</h2>
              <p className="weight-300 m-t-20 fs-default lh-md">We are a group of doers and dreamers who want to live in a world where vacationing on the moon or living on Mars isn’t just science fiction but a real option.</p>
              <p className="weight-300 fs-default lh-md">We are passionate about entrepreneurship, finance and all things space, and are deeply committed to the Spaced Ventures mission of making the industry accessible to companies and investors of all sizes.</p>
           </div>
          </AboutBanner>
        </div>        
        <div className="about-bg-pattern section-divider">
          <SpacedTeam />
          <WorkWithUs />
        </div>
      </>
    );
  
}

export default About;