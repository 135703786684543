import React from 'react'
import Tooltip from '../components/Tooltip/Tooltip'
import AmountWithCurrency from '../../components/AmountWithCurrency'
import Documents from './Documents'

function DealInfoRow({ title, description, info }) {
  return (
    <div className="table-row">
      <div className="table-row-col">
        <div className="deal-attribute">
          <div className="deal-attribute-title">{title}</div>
        </div>
        <div className="deal-attribute-value mt-1">{description}</div>
        {info && <Tooltip xAlignment="-130px" text={info}><span className="deal-attribute-icon"></span></Tooltip>}
      </div>
    </div >
  )
}

export default function DealInfo({ preoffer }) {
  const offerShortHand = {
    'RegD': 'Reg D',
    'RegCf': 'Reg CF',
  }
  const showValuation = preoffer.valuation >= 1 && preoffer.valuation;

  const isSecondary = preoffer.category === "secondary";
  const preofferTypeText = isSecondary ? "Secondary" : "Pre-launch"

  return (
    <div className="side-bar-deal-terms-table mb-4">
      <div className='mb-4'>
        <div className="sidebar-terms-header">
          <div className="header-primary">{preofferTypeText} Info</div>
        </div>
        <div className="table-rows">
          <DealInfoRow title={`${preofferTypeText} Type`} description={offerShortHand[preoffer.type] ?? "-"} />
          {showValuation && <DealInfoRow title="Valuation" description={preoffer.valuation ? <AmountWithCurrency number={preoffer.valuation} /> : '-'} />}
          <DealInfoRow title="Goal Amount" description={preoffer.goal_amount ? <AmountWithCurrency number={preoffer.goal_amount} /> : "-"} />
        </div>
      </div>
      <div className='mb-4'>
        <Documents documents={preoffer.documents} />
      </div>
    </div>
  )
}