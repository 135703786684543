import React from "react";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import AchPaymentForm from './AchForm';
import { FaPlus } from "react-icons/fa";

const DefaultSubheaderText = "Please fill out the bank account information that will be used to fund your investment. We partner with North Capital to process and store your ACH payment info.";
const DefaultSubmitButtonText = "Submit";

const AddACHButton = ({
  clientId,
  onSuccess,
  onError,
  submitButtonText = DefaultSubmitButtonText,
  subHeaderText = DefaultSubheaderText,
  showSidebar = false,
  buttonElement = null,
  extras = null
}) => {
  const AddACHModal = React.useContext(PortalizedModalContext);

  const successCallback = React.useCallback((newAchAccount) => {
    if (typeof onSuccess === "function") {
      onSuccess(newAchAccount)
    }
    AddACHModal.closeModal();
  }, [onSuccess, AddACHModal]);

  const errorCallback = React.useCallback((error) => {
    if (typeof onError === "function") {
      onError(error)
    }
  }, [onError]);

  const addAchPaymentMethod = () => {
    AddACHModal.setModalContent(
      `Add ACH Account`,
      <AchPaymentForm
        clientId={clientId}
        onSuccess={successCallback}
        onError={errorCallback}
        submitButtonText={submitButtonText}
        subHeader={subHeaderText}
        showSidebar={showSidebar}
        extras={extras}
      />,
      'lg'
    );
    AddACHModal.openModal()
  }

  if (buttonElement){
    return React.createElement(buttonElement, {onClick: addAchPaymentMethod});
  }

  return (
    <button
      type='button'
      className="btn-black px-3 py-2 poppins weight-700 fs-9 my-4 mr-sm-3"
      onClick={addAchPaymentMethod}>
      <FaPlus className='mb-1' />&nbsp;&nbsp;ACH Account
    </button>
  )
}

export default AddACHButton;
