import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from 'react-query';
import {
  usePreoffer,
  useRequestPreofferNDA,
  signPreofferNDA
} from 'investor/queries';
import { useUser } from '../redux/user';
import { history } from '../redux/helpers';
import { rollbarInstance } from 'jsUtils/rollbar';
import ToastMessage from '../components/ToastMessage';
import PageLoadingSpinner from '../components/PageLoadingSpinner';

export default function PreofferNDA() {
  let { preofferId } = useParams();
  const user = useUser();
  //const { data: preoffer, isError: isPreofferLoadigError, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);
  const { data: nda, isError: isPreofferNDALoadigError, isLoading: isLoadingPreofferNDA } = useRequestPreofferNDA(preofferId);
  const isLoading = isLoadingPreofferNDA || user.loading;
  const isError = isPreofferNDALoadigError;

  if (isLoading) {
    return <PageLoadingSpinner isLoading={isLoading} message="Loading NDA Document for Esign..." />;
  }

  if (isError) {
    ToastMessage.error('Could not fetch NDA document for esign. Please try reloading the page.');
    return <h5 className="montserrat weight-700">Could not fetch NDA document for esign. Please try reloading the page.'</h5>;
  }

  return (
    <>
      <div className="investment-header">
        <div className="container pb-3 pt-first-section">
          <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">NDA</h1>
        </div>
      </div>
      <div className="investor-formbox subscription-agreement-screen">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-4">
              <h5 className="montserrat weight-700">Please sign your NDA</h5>
              <EsignBox preofferId={preofferId} nda={nda} user={user}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function EsignBox({ preofferId, nda, user }) {
  const [loading, setLoading] = useState(true);

  console.log('NDA Data', nda)
  const mutation = useMutation(
    () => signPreofferNDA({ preofferId: preofferId, id: nda.id }),
    {
      onSuccess: (data, variables, context) => {
        console.log('EsignBox#onSuccess#mutation', data, variables, context)
        rollbarInstance.info("Signed preoffer NDA", { user_id: data.user_id });
        ToastMessage.info('You have successfully signed the NDA.')
        history.push(`/prelaunch/${preofferId}`);
      },
      onError: (error, variables, context) => {
        console.log('EsignBox#onError#mutation', error, variables, context)
        rollbarInstance.error("Error signing NDA agreement", {
          user_id: user.id,
        });
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
  );

  useEffect(() => {
    const handler = (event) => {
      rollbarInstance.info("Docusign page event ", { nda_id: nda.id, callback_event: event.data });

      console.log('EsignBox#useEffect', event)

      if (["signing_complete", "decline", "cancel"].indexOf(event.data) > -1) {
        mutation.mutate();
      } else if (event.data === 'ttl_expired' || event.data === 'viewing_complete') {
        console.log('URL expireed or signed', nda.esign_url)
        history.push("/offerings/all");
      } else {
        // history.push("/offerings/all");
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [nda.id]);

  return (
    <div className="esign-container">
      <PageLoadingSpinner isLoading={mutation.isLoading || loading} message={mutation.isLoading ? 'Loading...' : "Loading NDA Agreement..."} />
      <iframe
        title="NDA Signature"
        src={nda.esign_url}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}