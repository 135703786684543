import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ToastMessage from '../../../components/ToastMessage';
import PopupModal from './../PopupModal';
import {affiliationApi} from 'api/affiliation'
import './affiliation.scss';
import {AFFILIATION_LABELS} from './../../../config/constants';

export default function UpdateAffiliationForm({companyId, affiliation, onClose, onSuccess}) {
  const [loading, setLoading] = useState(false);

  return(
    <PopupModal onRequestClose={onClose}>
      <div className="affiliation-inner">
        <div className="modal-header border-bottom-0 pr-5">
          <h5 className="modal-title title montserrat weight-700">Update Affiliation</h5>
        </div>

          <Formik
            initialValues={{
              title: affiliation.title
            }}
            onSubmit={async (values, { props, setSubmitting }) => {
              setLoading(true);
              affiliationApi.update(companyId, affiliation.id, values).then(
                res => {
                  onSuccess(res)
                },
                error => {
                  console.log(error);
                  ToastMessage.error(error.message || 'Affiliation with title already exists');
                }
              ).finally(() => {
                setLoading(false);
              })
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <div className="form-inner-row px-3">
                  <div className="form-label-group">
                    <input type="email"
                      className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={affiliation.email}
                      readOnly
                    />
                    <label htmlFor="email">Email</label>
                    {/* <ErrorMessage name="email" component="div" className="invalid-feedback text-left"/> */}
                  </div>
                  <div className="form-label-group">
                    <Field type="select"
                      id="title"
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                      name="title"
                      placeholder="Affliation Title"
                      component="select"
                    >
                      <option value="">Select affiliation title</option>
                      {
                        AFFILIATION_LABELS.map(al => <option key={al.value} value={al.value}>{al.label}</option>)
                      }
                    </Field>
                    <label htmlFor="title">Affliation Title</label>
                    <ErrorMessage name="title" component="div" className="invalid-feedback text-left" />
                  </div>
                </div>
                <div className="affiliation-form-footer">
                  <button type="submit" className="btn-black" disabled={isSubmitting}>
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>

      </div>
    </PopupModal>
  );
}
