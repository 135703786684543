import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useUser, confirm_email } from "redux/user"
import { history } from 'redux/helpers';
import styled from 'styled-components';
import {FaRocket} from 'react-icons/fa'

export default function ConfirmEmailPage() {

  let { token } = useParams();
  const user = useUser();
  const confirmationError = user.error?.email_confirmation
  const onError = (
    <div className="confirm-content">
      <div className="pt-2"><FaRocket size="30px" color="#111" /></div>
      <p className="ml-3">This email validation link is no longer valid, you may have resent yourself another link, if so please check your email for the most recent confirmation email OR you can resend the link <Link to="/email-verification">here</Link></p>
    </div>   
  )
  const whileConfirming = (
    <div className="confirm-content">
      <div className="pt-2"><FaRocket size="30px" color="#111" /></div>
      <p className="ml-3">Confirming Email...You will be redirected to login page after successful confirmation.</p>
    </div>
  )

  useEffect( () => {
    confirm_email(token);
  }, [])

  if(user?.is_confirmed)
    history.push('/');

  return (
    <>
      <EmailConfirmContainerStyles id="confirmEmailPage">
        {confirmationError ? onError : whileConfirming}
      </EmailConfirmContainerStyles>
    </>
  )
}

const EmailConfirmContainerStyles = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh;

  .confirm-content {
    background-color: #f7f7f7;
    color: #111;
    display: flex;
    align-items: flex-start;
    max-width: 600px;
    padding: 30px;
    font-family: zeitung-mono, sans-serif;
    font-size: 17px;
    -webkit-box-shadow: 0px 10px 20px #00000029;
    -moz-box-shadow: 0px 10px 20px #00000029;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 8px;
  }
}`