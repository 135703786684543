import React from 'react';
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { updatePreofferStatus } from './queries';
import { OFFER_STATUS_CONSTANTS } from 'config/constants';
import {
  ButtonOutline,
} from 'admin/components';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

export default function StatusManager({preoffer}) {
  const { status, publishable } = preoffer;
  const canPublish = publishable && (status === OFFER_STATUS_CONSTANTS.DRAFT || OFFER_STATUS_CONSTANTS.PAUSED);

  const queryClient = useQueryClient();
  const updateStatusMutation = useMutation(
    updatePreofferStatus,
    {
      onSuccess: () => {
        ToastMessage.success("Pre-launch status updated.")
        queryClient.invalidateQueries(
          ['preoffer', preoffer.slug],
          {
            exact: true
          }
        )
        queryClient.invalidateQueries(['preoffer', 'list']);
      },
      onError: (error) => {
        ToastMessage.error("Pre-launch status update failed.")
        renderRailsErrors(error)
      },
    }
  )

  // status: one of [publish, pause, close]
  const updateStatus = async (status) => {
    const confirmation = window.confirm('Are you sure you want update Pre-launch status?');
    confirmation && updateStatusMutation.mutate({
      preoffer_id: preoffer.slug,
      status,
    })
  }

  return (
    <>
      {
        status === OFFER_STATUS_CONSTANTS.DRAFT &&
          <ButtonOutline
            text="GO LIVE"
            disabled={!canPublish}
            onClick={() => updateStatus('publish')}
          />
      }

      {
        (status === OFFER_STATUS_CONSTANTS.LIVE || status === OFFER_STATUS_CONSTANTS.PAUSED) &&
        <>
          <ButtonOutline
            text="close"
            additionalClasses='offer-controller'
            icon={<FaStop className="mr-2" />}
            onClick={() => updateStatus('close')}
          />

          <ButtonOutline
            text={status === OFFER_STATUS_CONSTANTS.PAUSED ? "resume" : "pause"}
            additionalClasses='offer-controller'
            icon={status === OFFER_STATUS_CONSTANTS.PAUSED ? <FaPlay className="mr-2" /> : <FaPause className="mr-2" />}
            onClick={
              () => updateStatus(status === OFFER_STATUS_CONSTANTS.PAUSED ? 'publish' : 'pause')
            }
          />
        </>
      }
    </>
  );
}