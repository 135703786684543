import { createReduxModule } from 'hooks-for-redux'

const storeKey = 'flashMessages';
const initialState = [];

export const [useFlashMessages, {_addMessage, _removeMessage}, flashMessagesStore] = createReduxModule(storeKey, initialState, {
    _addMessage: (state, message) => state.filter(m => m.key !== message.key).concat(message),
    _removeMessage: (state, messageKey) => state.filter(m => m.key !== messageKey)
  }
)

export const addFlash = ({key, text, type='info', duration=5000, primaryAction, secondaryAction, onCancel}) => {
  const flashMessage = {
    key,
    text,
    type,
    duration,
    primaryAction,
    secondaryAction,
    onCancel
  }
  _addMessage(flashMessage)
}

export const removeFlash = (key) => {
  _removeMessage(key)
}
