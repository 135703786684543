import PropTypes from "prop-types";
import React from "react";
import { useParams, useRouteMatch } from 'react-router-dom';
import { usePlatformEvent } from "./queries";
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewHeader from 'admin/components/OverviewHeader'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import TextButton from 'admin/components/TextButton'
import { toTitlecase } from 'jsUtils/stringUtils';
import { history } from 'redux/helpers'
import Invitations from "./Invitations";

import ToastMessage from "../../../components/ToastMessage";
import { FaExternalLinkAlt, FaLink, } from "react-icons/fa";
import { EditHeader, EditorModal } from "..";
import { platformLiveEventApi } from '../../../api/platformLiveEvent';
import { usePortalizedModal } from '../../../components/PortalModal';
import ChatModeration from "../LiveEvents/ChatModeration";
import ReactTooltip from "react-tooltip";
import { formatDate } from "../../../jsUtils/dateTimeUtils";

const EventDetail = () => {
  let { path, url } = useRouteMatch();
  let { eventSlug } = useParams()
  const {data: event, isLoading } = usePlatformEvent(eventSlug)
  const moderationModal = usePortalizedModal(`moderate-${eventSlug}`)

  if(isLoading) {
    return <>Loading...</>
  }

  const openModerationDialog = () => {
    moderationModal.open({
      title: `Live Chat Moderation - ${event.title}`,
      body: (
        <div className='d-block m-4 p-2'>
          <ChatModeration liveEvent={event} getChatSession={platformLiveEventApi.getChatSession}/>
        </div>
      ),
      onRequestClose: moderationModal.close,
      size: 'page'
    })
  }

  const buttons = (<div>
          <button
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
        style={{fontSize:'13px'}}
        onClick={() => {
          try{
            navigator.clipboard.writeText(`${window.location.origin}/resources/${eventSlug}`).then(
              success => ToastMessage.success("Copied"),
              error => ToastMessage.error("Copy action not supported.")
            )
          }
          catch {
            ToastMessage.error("Copy action not supported.")
            alert(`Your public link is - ${window.location.origin}/resources/${eventSlug}`)
          }
        }}
      >
        Copy Link <FaLink className='ml-2 mr-2' />
      </button>
      { event.status !== "upcoming"
        ? <button
            className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
            style={{fontSize:'13px'}}
            onClick={openModerationDialog}
          >
          {event.status === "live" ? "moderate" : "view"}
          </button>
        : null
      }
      </div>);

  return (
    <>
      <OverviewHeader
        left={event.title}
        right={buttons}
      />
      <div className="overview-content">     
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#overview" role="tab">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#invitations" role="tab">Invitations</a>
          </li>
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="overview" role="tabpanel">
            <OverviewSubheader
              left='Event Info'
              right={
                <TextButton title='Edit' onClick={ () => history.push(`${url}/edit`) } />
              } 
            />
            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Title' value={toTitlecase(event.title)} />
                  <AttributeValue name='Description' value={event.description} />
                </>
              }
              right={
                <>
                  <AttributeValue name='Status' value={event.status} />
                  <AttributeValue name='Scheduled Time' value={formatDate(event.scheduled_time, 'MMM dd, yyyy, hh:mm a')} />
                </>
              }
            />
          </div>
          <div className="tab-pane fade show" id="invitations" role="tabpanel">
            <Invitations eventType="platform_live_events" eventId={eventSlug} />
          </div>
        </div>
      </div>
    </>
  );
};

EventDetail.defaultProps = {};

EventDetail.propTypes = {
  requiredProp: PropTypes.string.isRequired,
  prop: PropTypes.string,
};
export default EventDetail;
