export function googleAddresses(addressFieldElement,filedAttrsObj, setFieldValue, setInvalidAddress, countryCode = 'US') {
	const google = window.google;
	let autocomplete;
	const allowedCountry = [countryCode];
	const editableAddressCounties = {
		"VN": true //Vietnam
	}

	const addressComponentsLength = editableAddressCounties[countryCode] ? 5 : 7;

	autocomplete = new google.maps.places.Autocomplete(addressFieldElement, {
		componentRestrictions: { country : allowedCountry },
		fields: ["address_components", "geometry"],
		types: ["address"],
	});
	autocomplete.addListener("place_changed", fillInAddress);

	function fillInAddress() {
		const place = autocomplete.getPlace();
		let address = '';
		let country = '';
		let city = '';
		let state = '';
		let postalCode = '';


		if(place && place.address_components && place.address_components.length >= addressComponentsLength) {
			for (const component of place.address_components) {
				const componentType = component.types[0];
				if(component.long_name.toLocaleLowerCase().match('po box')) break
				switch (componentType) {
					case "street_number": {
						address = `${component.long_name} ${address}`;
						break;
					}

					case "route": {
						address += component.long_name;
						break;
					}

					case "postal_code": {
						postalCode = `${component.long_name}${postalCode}`;
						break;
					}

					//some addresses don't have locality component.
					// so to get city we use postal town, but locality will be prioritized if present in components
					case "postal_town": {
						city = component.long_name;
						break;
					}
					case "locality": {
						city = component.long_name;
						break;
					}

					case "administrative_area_level_2": {
						if(!city){
							city = component.long_name;
						}
						break;
					}

					case "administrative_area_level_1": {
						state = component.short_name;
						break;
					}

					case "country": {
						country = component.short_name;
						break;
					}
					default: {
					}
				}
			}

		}

		if(place?.address_components?.length >= addressComponentsLength && address.length>0) {
			setInvalidAddress(false)
			setFieldValue(filedAttrsObj.address, address);
			setFieldValue('city', city);

			if(!editableAddressCounties[countryCode]){
				setFieldValue('region', state);
			}

			setFieldValue('country', country);
			setFieldValue(filedAttrsObj.postal_code, postalCode);
		} else {
			if(editableAddressCounties[countryCode]){
				return
			}
			setInvalidAddress(true);
			setFieldValue('city', "");
			setFieldValue('region', "");
			setFieldValue(filedAttrsObj.postal_code, "");
		}
	}
}