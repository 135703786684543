import React, {useState} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useQuery } from 'react-query';
import { platformLiveEventApi } from '../../api/platformLiveEvent';
import usePagination from '../../hooks/usePagination';
import { Paginator } from '../../admin/components';
import ListItem from './ListItem';
import InvestorResources, {BlogResources} from '../../pages/components/ResoucresArticles';
import { SimpleComingSoonCard } from '../../pages/components/OfferCardPreview';

export default function List() {
  const pageConfig = usePagination({ page: 1, per_page: 9});

  const { isLoading, error, data: liveEvents } = useQuery(['svEvents', pageConfig.current.page], () => {
    return platformLiveEventApi.list({ page: pageConfig.current.page, perPage: pageConfig.current.per_page });
  }, { keepPreviousData : true });
  const [resourcesExist, setResourcesExist ] = useState(true)

  if(isLoading){
    return <LoadingOverlay active={isLoading} spinner text='Loading platform events...' />
  }

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong, please try again later.</div>
  }


  return (
    <div className='container platform-events mt-4'>
      
      <InvestorResources setResourcesExist={setResourcesExist} />
      {liveEvents.data.length != 0 &&<h2 className="fs-5 weight-700 poppins">Events</h2>}
      <div className='row m-t-20'>
        { liveEvents.data.map((event, index) => <ListItem key={index} event={event} />) }
      </div>
      <div className='d-flex justify-content-center mb-2'>
        { liveEvents.data.length > 0 &&
          <Paginator
            theme='dark'
            onPage={pageConfig.setPage}
            meta={liveEvents.pagy}
          />
        }
      </div>
      { liveEvents.data.length == 0 && !resourcesExist &&  (
        <div className="card-container mb-5 small-section-bottom">
          <SimpleComingSoonCard/>
          <SimpleComingSoonCard/>
          <SimpleComingSoonCard/>
          <SimpleComingSoonCard/>
          <SimpleComingSoonCard/>
          <SimpleComingSoonCard/>
        </div>
          )}
        <BlogResources />
    </div>
  );
}