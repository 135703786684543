import axiosClient from 'config/axiosClient';

export const userApi = {
    login,
    logout,
    oauthLogin,
    oauthSignup,
    create,
    update,
    update_password,
    changeEmail,
    fetch,
    me,
    resend_confirmation_email,
    confirm_email,
    selfAttestation,
    create_investor_account,
    update_investor_account,
    list_investments,
    portfolioDeals,
    portfolioGroupedDeals,
    portfolioPrelaunches,
    portfolioPetitions,
    getInvestment,
    getAmlException,
    create_ach_payment,
    create_credit_card_payment,
    forgot_password,
    reset_password,
    fetch_ach_authorizations,
    fetch_ach_authorization,
    updateInvestementLimit,
    createEntityInvestment,
    updateEntityAccount,
    getEntityInvestors,
    getEntityDocuments,
    postEntityDocument,
    delete_ach_payment,
    getInvestorsCount,
    createSuitabilityQuestionaires,
    updateSuitabilityQuestionaires,
    getSuitabilityQuestionaires
};

function login(email, password) {
  return axiosClient.post('auth/login', email, password);
}

function logout() {
  return axiosClient.post("/auth/logout");
}

function oauthLogin(provider, params) {
  delete params.provider;
  return axiosClient.post(`oauth/${provider}/login`, params);
}

function oauthSignup(provider, params) {
  delete params.provider;
  return axiosClient.post(`oauth/${provider}/signup`, params);
}

function create(userAttributes) {
  return axiosClient.post('/users', userAttributes);
}

function update(id, attrs) {
  return axiosClient.put(`/users/${id}`, attrs);
}

function update_password(attrs) {
  return axiosClient.put(`/password/`, attrs);
}

function fetch(userId) {
  return axiosClient.get(`/users/${userId}`);
}

function me(options = {}) {
  return axiosClient.get('/auth/me', options);
}

function confirm_email(token) {
  return axiosClient.post('/users/confirm', token);
}

function resend_confirmation_email(attrs) {
  return axiosClient.post('/users/resend_confirmation', attrs);
}

function changeEmail(attrs){
   return axiosClient.put('/users/change_email', { user: attrs });
}

function create_investor_account(userId, attrs) {
  return axiosClient.post(`/users/${userId}/clients`, attrs);
}

function update_investor_account(accountId, attrs, userScope) {
  let endpoint = `/clients/${accountId}`;

  if(userScope === 'admin'){
    endpoint = `/admin/${endpoint}`;
  }

  return axiosClient.put(endpoint, attrs);
}

function list_investments(){
  return axiosClient.get(`investments/grouped_investments`);
}

function portfolioDeals() {
  return axiosClient.get(`portfolio/deals`);
}

function portfolioPrelaunches() {
  return axiosClient.get(`portfolio/preoffers`);
}

function portfolioPetitions() {
  return axiosClient.get(`portfolio/raise_petitions`);
}

function portfolioGroupedDeals() {
  return axiosClient.get(`portfolio/grouped_deals`);
}

function getInvestment(id) {
  return axiosClient.get(`investments/${id}`)
}

function getAmlException(id) {
  return axiosClient.get(`investments/${id}/aml_exception`)
}

function create_ach_payment(clientId, attrs){
  return axiosClient.post(`/clients/${clientId}/ach_authorization`, attrs);
}

function create_credit_card_payment(accountId, values){
  return axiosClient.post(`/clients/${accountId}/credit_card`, values);
}

function delete_ach_payment(achId){
  return axiosClient.delete(`/ach_authorizations/${achId}`);
}

function fetch_ach_authorizations(accountId){
  return axiosClient.get(`/clients/${accountId}/ach_authorization`);
}

function fetch_ach_authorization(achId){
  return axiosClient.get(`/ach_authorizations/${achId}`);
}

function forgot_password(attrs) {
  return axiosClient.post('/password/forgot', attrs);
}

function reset_password(attrs) {
  return axiosClient.post('/password/reset', attrs);
}

function updateInvestementLimit(userId, attrs) {
  return axiosClient.put(`/users/${userId}`, attrs)
}

function createEntityInvestment(userId, attrs) {
  return axiosClient.post(`/users/${userId}/clients`, attrs)
}

function updateEntityAccount(clientId, attrs, userScope) {
  let endpoint = `/clients/${clientId}`;

  if(userScope === 'admin'){
    endpoint = `/admin/${endpoint}`;
  }

  return axiosClient.put(endpoint, attrs)
}

function getEntityInvestors(userId, entityType = 'all') {
  return axiosClient.get(`/users/${userId}/clients?entity_type=${entityType}`)
}

function getEntityDocuments(id){
  return axiosClient.get(`/clients/${id}/entity_documents`)
}

function postEntityDocument(clientId, data){
  return axiosClient.post(`clients/${clientId}/entity_documents`, data)
}

function getInvestorsCount(){
  return axiosClient.get("investors/count")
}

function selfAttestation(attrs){
   return axiosClient.put("/users/self_attestation", attrs)
}

function createSuitabilityQuestionaires({ clientId, attrs }){
  return axiosClient.post(`clients/${clientId}/suitability_questionaire`, attrs);
}

function updateSuitabilityQuestionaires({ clientId, attrs }){
  return axiosClient.put(`clients/${clientId}/suitability_questionaire`, attrs);
}

function getSuitabilityQuestionaires(clientId){
  return axiosClient.get(`clients/${clientId}/suitability_questionaire`);
}