import axios from 'axios';
import { API_BASE_URL, AUTH_TOKEN } from './constants';
import { logoutUser } from 'redux/session'

import ToastMessage from '../components/ToastMessage';
import {useUser, _setAuthStatus} from 'redux/user';
axios.defaults.withCredentials = true;

const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
});

axiosClient.interceptors.request.use(
  config => {
    return config
  },

  error => Promise.reject(error),
)

axiosClient.interceptors.response.use(
  response => response.data,

  error => {
    if(error.response.status === 401) {
      if(error.response.config?.errorMessage){
        ToastMessage.success(error.response.config.errorMessage);
      }

      return Promise.reject(error.response.data.error);
    }
    if(error.response.status === 404){
      return Promise.reject(error.response.data);
    }
    else if(error.response.status === 412) {
      return Promise.reject(error.response.data);
    }
    else if(error.response.status === 419){
      _setAuthStatus(false)

      if(!error.response.config?.hideToast){
        ToastMessage.error("Session expired. Login to continue.", {
          toastId: "SESSION_EXPIRED"
        });
      }

      return Promise.reject(error.response.data);
    }
    else if(error.response.status === 429){
      ToastMessage.error("Too many requests");
    }
    else if((/4\d\d/).test(error.response.status)) { // ErrorCode 4XX
      console.error('Status 4XX')
      return Promise.reject(error.response.data);
    }
    else if(error.response.status === 503){
      ToastMessage.error("You have been banned for one hour");
    }
    else {
      console.error('Status 5XX')
      return Promise.reject({error: 'Internal Server Error'});
    }
  }
);

export default axiosClient;
