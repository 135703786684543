import PropTypes from "prop-types";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "components/HookForm/TextInput";

const InvitationSchema = Yup.object().shape({
  invitation: Yup.object().shape({
    guest_email: Yup.string().email().required(),
  }),
});

const InvitationForm = ({eventType, eventId, createMutation}) => {

  const methods = useForm({
    resolver: yupResolver(InvitationSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      guest_email: '',
    },
  });

  const onSubmit = ({invitation}) => {
    createMutation.mutate({
      invitation
    })
  }

  return (
    <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <TextField
                name="invitation.guest_email"
                placeholder="Guest Email*"
              />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="btn-black"
              disabled={methods.isSubmitting}
            >
              Send Invite
            </button>
          </div>
        </form>
      </FormProvider>
  );
};

InvitationForm.defaultProps = {};

InvitationForm.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  createMutation: PropTypes.func.isRequired,
};

export default InvitationForm;
