import React, {useContext, useState} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useQuery, useQueryClient } from 'react-query';
import { FaExternalLinkAlt, FaPen, FaTrash, FaRocketchat } from "react-icons/fa";
import { formatDate } from 'jsUtils/dateTimeUtils';
import { OverviewModal, ResponsiveTable, EditHeader, EditorModal, Paginator, ButtonDark } from 'admin/components';
import { platformLiveEventApi } from '../../../api/platformLiveEvent';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import Form from './Form';
import StatusUpdateButton from './StatusUpdateButton';
import { statusTitle } from '../../../components/LiveEvent/StatusUtil';
import usePagination from '../../../hooks/usePagination';
import ChatModeration from '../LiveEvents/ChatModeration';
import ToastMessage from '../../../components/ToastMessage';
import TableActionsContainer from '../TableActionsContainer';
import TableActionItem from '../TableActionItem';
import LinkedData from '../../../components/LinkedData';
import { Switch, Route, useRouteMatch } from "react-router";
import EventDetail from './EventDetail';
import { history } from "redux/helpers";
import EventEditor from './EventEditor';
import EventCreator from './EventCreator';

export default function List() {
  let { path, url } = useRouteMatch();

  const pageConfig = usePagination();
  const [currentLiveEvent, setCurrentLiveEvent] = useState(null);
  const [moderatedLiveEvent, setModeratedLiveEvent] = useState(null);
  const deleteModal = useContext( PortalizedModalContext );
  const queryClient = useQueryClient();

  const { isLoading, error, data: liveEvents } = useQuery(['platformLiveEvents', pageConfig.current.page], () => {
    return platformLiveEventApi.admin.list({ page: pageConfig.current.page });
  }, { keepPreviousData : true });

  const openEventDetails = React.useCallback((row) => {
    history.push(`${url}/${row.original.slug}`);
  }, []);

  if(isLoading){
    return <LoadingOverlay active={isLoading} spinner text='Loading platform live events...' />
  }

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const deleteHandler  = (id) => {
    deleteModal.setModalContent(
      'Delete SV Live Event',
      <>
        <p>Are you sure you want to delete the SV live event?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteLiveEvent(id);
              deleteModal.closeModal();
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteModal.openModal();
  }

  const deleteLiveEvent = (id) => {
    const liveEvent = liveEvents.data.find((e) => e.id === id);

    if(!liveEvent){
      return;
    }

    platformLiveEventApi.admin.destroy(liveEvent.id).then(() => {
      queryClient.invalidateQueries(['platformLiveEvents', pageConfig.current.page]);
      ToastMessage.success(`${liveEvent.title} deleted.`);
    }).catch(function (error) {
      ToastMessage.error(error);
    })
  };

  const editLiveEvent = (slug) => {
    history.push(`${url}/${slug}/edit`);
  };

  const openChatModeration = (id) => {
    const liveEvent = liveEvents.data.find((e) => e.id === id);

    if(liveEvent){
      if(liveEvent.status === 'upcoming'){
        ToastMessage.success('Can not moderate upcoming event');
      } else {
        setModeratedLiveEvent(liveEvent);
      }
    }
  }

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({value, row}) => (
        <LinkedData onClick={() => openEventDetails(row)}>
          {value}
        </LinkedData>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => statusTitle(value)
    },
     {
      Header: 'Scheduled Time',
      accessor: 'scheduled_time',
      Cell: ({value}) => value ? formatDate(value) : ''
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({value, row}) => (
        <TableActionsContainer>
          <StatusUpdateButton liveEvent={row.original} page={pageConfig.current.page}/>
          <TableActionItem tooltipText="Event Chat" onClick={ ()=> { openChatModeration(value) }}>
            <FaRocketchat className="fs-14 color-black" />
          </TableActionItem>
          <TableActionItem tooltipText="Event Video" onClick={() => window.open(row.original.youtube_url, "_blank", "noopener noreferrer")}>
            <FaExternalLinkAlt className='fs-14 color-black'/>
          </TableActionItem>
          <TableActionItem tooltipText="Edit Event" onClick={ ()=> { editLiveEvent(row.original.slug) }}>
            <FaPen className='fs-14 color-black'/>
          </TableActionItem>
          <TableActionItem tooltipText="Delete Event" onClick={() => { deleteHandler(value) }}>
            <FaTrash className='fs-14 color-black'/>
          </TableActionItem>
        </TableActionsContainer>
      )
    }
  ];

  return (
    <div className='bg-lightgrey'>
      <div  className='admin-main-content-header'>
        <div className='amch-inner1'></div>
        <div className='amch-inner2'>
          <ButtonDark title='Create New SV Event' onClick={() => history.push(`${url}/create`) } />
        </div>
      </div>
      { moderatedLiveEvent &&
        (<EditorModal onRequestClose={ () => setModeratedLiveEvent(null) }>
          <EditHeader left={ `Live Chat Moderation - ${moderatedLiveEvent.title}`} />
          <div className='d-block m-4 p-2'>
            <ChatModeration liveEvent={moderatedLiveEvent} getChatSession={platformLiveEventApi.getChatSession}/>
          </div>
        </EditorModal>)
      }
      { liveEvents.data.length > 0 ? (
        <div className="admin action-table">
          <Paginator
            theme='dark'
            onPage={pageConfig.setPage}
            meta={liveEvents.pagy}
          />
          <ResponsiveTable columns={columns} data={liveEvents.data} classes="use-md-width" />
        </div>) : ( <div className='text-center montserrat weight-500 fs-8 mt-4'>No SV live events available.</div>)
      }

      <Switch>
        <Route exact path={`${path}/create`} component={
          (props) => <EditorModal><EventCreator /></EditorModal>
        }/>

        <Route exact path={`${path}/:eventSlug`} component={
          (props) => <OverviewModal><EventDetail /></OverviewModal>
        }/>

        <Route exact path={`${path}/:eventSlug/edit`} component={
          (props) => <EditorModal><EventEditor /></EditorModal>
        }/>
      </Switch>
    </div>
  );
}
