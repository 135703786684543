import React from 'react';
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa';


import profileImg1 from '../../assets/images/experts/ChristineTovee.jpg'
import profileImg2 from '../../assets/images/experts/daniel@2x.jpg'
import profileImg3 from '../../assets/images/experts/BrianBelley.jpg'
import profileImg4 from '../../assets/images/experts/Ioana@2x.jpg'
import profileImg5 from '../../assets/images/experts/jose@2x.jpg'
import profileImg6 from '../../assets/images/experts/PeacockDeborah.jpg'
import ReactTooltip from 'react-tooltip';

const expertData = [
  {
    name: "Christine Tovee",
    subheader: "Innovation Leader",
    text: "Ex-CTO, Airbus Group N. America",
    img: profileImg1
  },   
  {
    name: "Daniel Faber",
    subheader: "Aerospace Pioneer",
    text: "Engineer & Space Materials Pioneer. CEO, OrbitFab",
    img: profileImg2
  },  
  {
    name: "Brian Belley",
    subheader: "Founder/CEO, Crowdwise",
    text: "Aerospace and Mechanical Engineer",
    img: profileImg3
  },   
  {
    name: "Dr. Ioana Cozmuta",
    subheader: "15 Years Nasa, CEO of G-space",
    text: "Ph.D. Physics, Stanford Caltech, CEO of G-space & Gravity Free",
    img: profileImg4
  },     
  {
    name: "Jose Morey M.D.",
    subheader: "Forbes Tech Council",
    text: "John’s Hopkins. CMIO Liberty BioSecurity",
    img: profileImg5
  },
  {
    name: "Deborah Peacock",
    subheader: "Engineer+Harvard Law",
    text: "President/CEO, Peacock Law P.C.",
    img: profileImg6
  },  
]

function ExpertTile({ name, role, img, tileBoxBorder, tileBoxShadow, imgBorder}) {
  return (
      <>
      <div className="d-flex text-center flex-column justify-content-center align-items-center">
        <SpaceExpertImgContainer 
            tileBoxBorder={tileBoxBorder} 
            tileBoxShadow={tileBoxShadow}
            imgBorder={imgBorder}
            >       
          <img className="space-experts-img" src={img} alt={name}></img>
        </SpaceExpertImgContainer>
        <div className="space-container-text">
          <h3 className="fs-9 mt-3 montserrat weight-900">{name}</h3>
          <span className="subheader-expert-lh fs-11 zeitung-mono">{role}</span>
        </div>
       </div>
      </>
  )
}

export default function SpaceExperts({ header, text, subheader, bgColor = 'transparent', color = '#111', tileBoxBorder = '#fff', tileBoxShadow = '#111', imgBorder = '#fff' }){
  return (
    <SpaceExpertsBackground className="space-experts-container section-divider" bgColor={bgColor} color={color} >
      <div className="container experts-section-spacing">
        <div className="space-experts-grid">
          <div className="reverse space-experts-team-grid">
            {expertData.map((item, i) => {
              return <ExpertTile tileBoxBorder={tileBoxBorder} tileBoxShadow={tileBoxShadow} imgBorder={imgBorder} key={i} name={item.name} role={item.subheader} img={item.img} />
            })}
          </div>
          <div className="cap-text">
            {subheader && <h4 className="text-uppercase fs-8 weight-300 zeitung-mono">{subheader}</h4>}
          <h2 className="fs-4 weight-900">{header}</h2>
          <p className="fs-6 weight-300 m-t-25">{text}</p>
          <div className="mt-4">
          <div data-for="equityRisksTooltip" data-tip="Investments in Reg CF private companies are particularly risky and you should only consider investing if you can afford to lose your entire investment and are willing to live with the ups and downs within the industry in which you invest. Spaced Ventures does not solicit investments nor does it provide investment advice, recommendations or suitability determinations. Investing involves the risk of loss and liquidity risk. Past performance is not a guarantee of future results.">
              <div className="d-flex align-items-center fs-9">
                <FaInfoCircle className="mr-1" size="1em" />
                Equity Crowdfunding involves risks
              </div>
          </div>
            </div>
          <ReactTooltip id="equityRisksTooltip" backgroundColor="#f5f5f5" textColor="#111" className="tooltip-width" />          
          </div>
        </div>
      </div>
    </SpaceExpertsBackground>
  )
  
}

const SpaceExpertsBackground = styled.div`
  background: ${props => props.bgColor};
  color: ${props => props.color};
    
  .space-container-text {
    color: ${props => props.color};
  }
`;

const SpaceExpertImgContainer = styled.div`
  border: 3px solid ${props => props.tileBoxBorder};
  box-shadow: 3px 3px 0px ${props => props.tileBoxShadow};
  border-radius: 4px;
  background: ${props => props.tileBoxBorder};

.space-experts-img {
  height: 129px;
  width: 129px; 
  border: 4px solid ${props => props.imgBorder};
  border-radius: 4px;
}

`