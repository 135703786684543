import React from 'react';
import { Link } from "react-router-dom";
import { toTitlecase } from 'jsUtils/stringUtils';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import ReCAPTCHA from "react-google-recaptcha";

import { createUser } from "redux/user";
import OauthLogin from '../../components/OauthLogin';

const SITE_KEY = "6LflgKUZAAAAAJfjzKlNyMPO5UAHgrXOWCsYYJXp";

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Please Enter First Name'),

  last_name: Yup.string()
    .required('Please Enter Last Name'),

  email: Yup.string()
    .email('Invalid email')
    .required('Please Enter Email'),

  password: Yup.string()
    .required('Please Enter Password')
    .min(8, 'At least 8 characters required'),
  password_confirmation: Yup.string()
    .required('Please Enter Confirm Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  recaptcha: Yup.string()
    .required('Required')
});

export default function SignupForm() {

  return(
    <div id="signupForm" className="container m-width-medium mt-30 mb-30">
      <h1 className="weight-900 fs-3 text-uppercase">Sign Up</h1>
      <div className='d-block px-md-5'>
        <OauthLogin type='signup'/>
        <div className='poppins fs-12 weight-400 dotted-line-with-text mt-3 mb-1'>OR SIGN UP WITH EMAIL</div>
      </div>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
          recaptcha: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values, { props, setSubmitting }) => {
          values.first_name = toTitlecase(values.first_name.toLowerCase());
          values.last_name = toTitlecase(values.last_name.toLowerCase());
          createUser({user: values})
        }}
      >
        {({ touched, errors, isSubmitting, setFieldValue, handleChange }) => (
          <Form className="py-3 px-0 px-md-5">
            <div className="form-row">
              <div className="col-sm-6">
                <div className="form-label-group">
                  <Field type="text"
                    className={`form-control ${touched.first_name && errors.first_name ? "is-invalid" : ""}`}
                    name="first_name"
                    id="first_name"
                    placeholder="First name"
                  />
                  <div className="white-backlayer"></div>
                  <label htmlFor="first_name">First name</label>
                  <ErrorMessage name="first_name" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-label-group">
                  <Field type="text"
                    className={`form-control ${touched.last_name && errors.last_name ? "is-invalid" : ""}`}
                    name="last_name"
                    id="last_name"
                    placeholder="Last name"
                  />
                  <div className="white-backlayer"></div>
                  <label htmlFor="last_name">Last name</label>
                  <ErrorMessage name="last_name" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>
            </div>

            <div className="form-label-group">
              <Field type="email"
                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                name="email"
                id="email"
                placeholder="Email"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="email">Email</label>
              <ErrorMessage name="email" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                name="password"
                id="password"
                placeholder="Password (min 8 characters)"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="password">Password (min 8 characters)</label>
              <ErrorMessage name="password" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.password_confirmation && errors.password_confirmation ? "is-invalid" : ""}`}
                name="password_confirmation"
                id="password_confirmation"
                placeholder="Password (min 8 characters)"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="password_confirmation">Confirm Password</label>
              <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className="form-group">
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                sitekey={SITE_KEY}
                onChange={(response) => setFieldValue('recaptcha', response)}
              />
              <ErrorMessage name="recaptcha" component="div" className="invalid-feedback text-left"/>
            </div>

            <p className="fs-12">By signing up I agree to Spaced Ventures' <Link className="green-link" target="_blank" rel="noopener noreferrer" to="/terms-of-service">Terms of Service</Link>, <Link className="green-link" target="_blank" rel="noopener noreferrer" to="/privacy-policy">Privacy Policy</Link> and to the use of Cookies.</p>
            <button type="submit" className="mx-auto btn-signup col-md-8 col-12" disabled={isSubmitting}>
              Sign Up
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

