import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const HiddenInput= ({name, ...props}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <input
            {...props}
            {...field}
            value={ field.value || '' }
            id={field.name}
            type='hidden'
          />
          <ErrorMessage error={error}/>
        </>
      )}
    />
  )
}

HiddenInput.defaultProps = {};

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
 };

export default HiddenInput;