import React from 'react';
import ReactTooltip from 'react-tooltip';

export default function Investor ({investor}) {
  let currentText = "An investor in this Reg CF round on Spaced Ventures";
  let pastText = "An investor in a past round"

  return(
    <div className="single-investor">
      <div className="offer-investor-info">
        <div className="offer-investor-name-n-status">
          <h6 className="investor-name mr-2">{investor.name}</h6>
          <div className="investor-current-status poppins" data-for="currentPastTooltip" data-tip={investor.current ? currentText : pastText}>{investor.current ? "Current" : "Past"}</div>
          <ReactTooltip id="currentPastTooltip" effect="solid" place="right" offset={{'right': 0}} backgroundColor="#f5f5f5" textColor="#111" border={true} className="tooltip-width" />
        </div>
        <span className="investor-designation">{investor.title}</span>
      </div>
      <div className="investor-img-box">
        <img src={investor.photo_url} className="investor-img" alt="investor img" />
      </div>
    </div>
  )
}