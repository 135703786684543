import { useQuery } from "react-query";
import axiosClient from "config/axiosClient";

const useAccreditationRequestsList = ({ page = 1, per_page = 10, searchStatus, searchQuery }) => {
  const searchParams = new URLSearchParams({
    page: page,
    per_page: per_page,
   });

  if(searchStatus){
    searchParams.append('status', searchStatus);
  }

  if(searchQuery){
    searchParams.append('q', searchQuery);
  }

  return useQuery(
    ["accreditation_requests", page, searchStatus, searchQuery],
    () => {
      return axiosClient.get(
        `/admin/accreditation_requests?${searchParams}`
      );
    },
    {
      keepPreviousData: true,
    }
  );
};

const useAccreditationRequestDetails = (clientId, requestId) => {
  // Parse int due to sometime clientId get from the url params.
  return useQuery(["accreditation_request", parseInt(clientId)], () => {
    return axiosClient.get(`/admin/accreditation_requests/${requestId}`);
  });
};

const updateDocumentStatus = ({ documentId, values }) => {
  return axiosClient.put(`/entity_documents/${documentId}`, values);
};

const useClientInvestments = (clientId) => {
  return useQuery(["client_investments", clientId], () => {
    return axiosClient.get(`/admin/clients/${clientId}/investments/`);
  });
};

const requestAccreditationVerification = (requestId) => {
  return axiosClient.put(
    `/admin/accreditation_requests/${requestId}/request_verification`
  );
}

export {
  useAccreditationRequestsList,
  useAccreditationRequestDetails,
  requestAccreditationVerification,
  updateDocumentStatus,
  useClientInvestments
};
