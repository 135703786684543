import React from "react";
import CustomContentLoader from "./CustomContentLoader";
export default function OfferListLoader({ loading = true }) {
  return (
    <div className="container">
      <div className="card-container">
        <CustomContentLoader
          loading={loading}
          width={348}
          height={580}
          listCount={3}
          shape={
            <>
              <rect x="0" y="0" rx="8" ry="8" width="348" height="317" />
              <rect x="0" y="330" rx="8" ry="8" width="348" height="80" />
              <rect x="0" y="420" rx="8" ry="8" width="280" height="20" />
              <rect x="0" y="450" rx="8" ry="8" width="280" height="20" />
            </>
          }
        />
      </div>
    </div>
  );
}
