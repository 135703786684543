import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import YoutubeEmbed from '../../admin/components/shared/YoutubeEmbed';
import { AuthContext } from '../../contexts/authContext';
import { PusherChannelProvider } from '../../contexts/PusherChannelContext';
import LiveChat from '../LiveChat';
import { platformLiveEventApi } from '../../api/platformLiveEvent';
import EventStatus from './EventStatus';
import LiveViewerCount from '../LiveEvent/LiveViewerCount';
import ArchivedEvent from './ArchivedEvent';
import { history } from 'redux/helpers'
import { Link } from 'react-router-dom';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import _ from 'lodash';

export default function LiveEvent({ eventId }) {
  const { isUserLoggedin, isAdmin, authData } = useContext(AuthContext);

  const { isLoading, error, data: liveEvent } = useQuery(['svEvent', eventId || 'current'], () => {
    if(eventId){
      return platformLiveEventApi.fetch(eventId);
    } else {
      return platformLiveEventApi.currentEvent();
    }
  }, {
    staleTime: 0
  });

  if(isLoading || error){
    return null;
  }

  if (!isUserLoggedin() && liveEvent == "") {
    return null
  }

  // when user is trying to specific event url with slug/eventId
  // and does not have access to event for any reason [not invited | event not published | invalid eventId]
  if (eventId && (liveEvent == "" || _.isEmpty(liveEvent))) {
    return (
      <div className='m-3 p-3 text-center bg-lightgrey weight-500 fs-18'>
        {
          isUserLoggedin()
            ? <>You do not have access to this live event. Please contact support@spacedventures.com for assitance.</>
            : <>Please <Link to="/login">login</Link> or <Link to="/signup">sign up</Link> to access invite only events.</>
        }
      </div>
    )
  }

  if (liveEvent == "") {
    return null;
  }

  const scheduledTime = Date.parse(liveEvent.scheduled_time)
  if(liveEvent.status === 'upcoming') {
    return scheduledTime > new Date().getTime() ? (
      <div className='countdown-container m-2 d-flex flex-column align-items-center'>
        <div className='countdown-title-text fs-24 weight-900 mt-3'>Event starts in</div>
        <div className='countdown-flipper mt-3'>
          <FlipClockCountdown to={scheduledTime} onComplete={() => window.location.reload(true)}/>
        </div>
      </div>
    ) : (
      <div className='countdown-container m-2 d-flex flex-column align-items-center'>
        <div className='countdown-title-text fs-24 weight-900 mt-3'>Event starting soon...</div>
      </div>
    )
  }

  const user = isUserLoggedin() ? authData.currentUser : null;

  if(liveEvent.status === 'pre_live' || liveEvent.status === 'live'){
    return (
      <PusherChannelProvider>
        <div className='live-event current-platform-event bg-light-black px-2 px-md-0'>
          <div className='container'>
          {liveEvent.listed_at === null && 
            <div className="mb-2">
              <span className="custom-badge bg-golden f-black">Invite Only</span>
            </div>}
            <div className='d-md-flex d-block align-items-start'>
              <h2 className='color-white poppins fs-7 weight-800 mb-3 mr-2'>{liveEvent.title}</h2>
              <EventStatus eventId={liveEvent.id} initialStatus={liveEvent.status} channelId={liveEvent.channel_id}/>
            </div>
          </div>
          <div className="container">
            <div className='row my-2'>
              <div className="col-12 col-lg-8 video-container">
                <div className='for-youtube-vid-container'>
                  <YoutubeEmbed videoUrl={liveEvent.youtube_url}/>
                </div>
              </div>
              <div className='col-12 col-lg-4'>
                <div className='chat-container'>
                  <LiveChat.ChatBox liveEvent={liveEvent} user={user} moderation={isAdmin()} getChatSession={platformLiveEventApi.getChatSession }/>   
                </div>
              </div>
            </div>
            <div className='row m-0'>
              <LiveViewerCount liveEvent={liveEvent }/>
            </div>
          </div>
        </div>
      </PusherChannelProvider>
    )
  }

  return <ArchivedEvent event={liveEvent}/>
}
