import React from 'react'
import './PitchSectionLabel.scss'
const PitchSectionLabel = ({label}) =>(<div className="label">
    <div className="label-inner">
      <div className="label-text">{label}</div>
    </div>
  </div>
)


export default PitchSectionLabel;