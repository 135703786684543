import React from "react";
import { useQueryClient } from 'react-query';
import { FaExternalLinkAlt } from "react-icons/fa";
import OverviewSubheader from "../OverviewSubheader";
import AttributeValue from "../AttributeValue";
import ButtonDark from "./../ButtonDark";
import { UploadDocsModal } from "../InvestmentDetails";
import { useClientDocuments } from "./../../queries/index";
import { humanize, toTitlecase } from "jsUtils/stringUtils";

const ClientDocuments = ({
  investment:{
    client_id,
    investor_name:client_name,
    investing_as:client_type,
    client_aml_status,
  }
}) => {
  const queryClient = useQueryClient();
  const { isLoading, error, data: client_documents } = useClientDocuments({client_id});
  const [uploadDocsShown, showUploadDocs] = React.useState(false);

  const refetchClientDocs = () => {
    queryClient.invalidateQueries(
      ['client-documents', client_id],
      {
        exact: true,
      }
    )
  }

  if(error) {
    return (
      <div className="px-3 text-center">Something went wrong. Please try again later.</div>
    )
  }

  if(isLoading) {
    return (
      <>
        <OverviewSubheader left="Client Documents" />
        <div className="px-3 text-center">Loading...</div>
      </>
    )
  }

  return (
    <>
      <OverviewSubheader left="Client Documents" right={<ButtonDark title='Upload New' onClick={() => showUploadDocs(true)} />}/>
      <AttributeValue name='Client Type' value={client_type} />
      <AttributeValue name='Client Name' value={client_name} />
      <AttributeValue
        name="ID Status"
        value={toTitlecase(humanize(client_aml_status || "record not available"))}
      />

      {
        client_documents && client_documents.map(
          d => <AttributeValue
            key={d.id}
            name={`${d.name}`}
            value={
              <a href={d.url} target="_blank" rel="noopener noreferrer">
                <span className="text-dark">Open in new tab</span>
                <FaExternalLinkAlt className="ml-2 text-dark"/>
              </a>
            }
          />
        )
      }
      {
        uploadDocsShown &&
          <UploadDocsModal
            clientId = {client_id}
            client_type={client_type}
            setShowDocsModal={showUploadDocs}
            setGetEntityDocuments = {refetchClientDocs}
          />
      }
    </>
  );
};

export default ClientDocuments;
