import { createReduxModule } from 'hooks-for-redux'
import ToastMessage from '../components/ToastMessage';
import {rollbarInstance, setRollbarPerson} from "jsUtils/rollbar";
import { userApi } from 'api/user'
import { history } from './helpers';
import { _clearUser } from './user';
import { QueryCache } from 'react-query';

const storeKey = 'userSession';
const initialState = {
  loading: false
};

export const [useSession, {setLoading, createSession, destroySession, setError}, sessionStore] = createReduxModule(storeKey, initialState, {
  createSession: (state, user) => {
    delete state.error
    return {...state, active: true, user}
  },

  destroySession: (state) => {
    delete state.error
    delete state.user
    return { ...state, active: false }
  },

  setError: (state, error) => {
    return {...state, error}
  },

  setLoading: (state, loading) => {
    return {...state, loading}
  }
})


export const logoutUser = (showToast=true) => {
  localStorage.removeItem('afterLoginPath');

  userApi.logout().then(
    () => {
      destroySession();
      _clearUser();
      // Clear full query cache.
      (new QueryCache()).clear();

      if(showToast) {
        ToastMessage.success("You have been logged out.")
      }

      setRollbarPerson({});
      history.push('/login')
    },
    (error) => {
      console.log(error)
      rollbarInstance.error("Error logging out user", {error});
    }
  );
}

