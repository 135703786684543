import React from 'react';
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { updateRaisePetitionStatus } from './queries';
import { OFFER_STATUS_CONSTANTS } from 'config/constants';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import {
  ButtonOutline,
} from 'admin/components';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

export default function StatusManager({raisePetition}) {
  const updatePetitionStatusModal = React.useContext(PortalizedModalContext);
  const { status, publishable } = raisePetition;
  const canPublish = publishable && (status === OFFER_STATUS_CONSTANTS.DRAFT || OFFER_STATUS_CONSTANTS.PAUSED);

  const queryClient = useQueryClient();
  const updateStatusMutation = useMutation(
    updateRaisePetitionStatus,
    {
      onSuccess: () => {
        ToastMessage.success("Raise petition status updated.")
        queryClient.invalidateQueries(
          ['raise-petition', raisePetition.slug],
          {
            exact: true
          }
        )
      },
      onError: (error) => {
        ToastMessage.error("Raise petition status update failed.")
        renderRailsErrors(error)
      },
    }
  )

  // status: one of [publish, pause, close]
  const updateStatus = async (status) => {
    updatePetitionStatusModal.setModalContent(
      "Update Raise Petition status",
      <>
        <p>Are you sure you want update Raise Petition status?</p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              updateStatusMutation.mutate({
                raise_petition_slug: raisePetition.slug,
                status,
              });
              updatePetitionStatusModal.closeModal();
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => updatePetitionStatusModal.closeModal()}
          >
            Cancel
          </button>
        </div>
      </>,
      "sm"
    );
    updatePetitionStatusModal.openModal();
  };
  return (
    <>
      {
        status === OFFER_STATUS_CONSTANTS.DRAFT &&
          <ButtonOutline
            text="GO LIVE"
            disabled={!canPublish}
            onClick={() => updateStatus('publish')}
          />
      }

      {
        (status === OFFER_STATUS_CONSTANTS.LIVE || status === OFFER_STATUS_CONSTANTS.PAUSED) &&
        <>
          <ButtonOutline
            text="close"
            additionalClasses='offer-controller'
            icon={<FaStop className="mr-2" />}
            onClick={() => updateStatus('close')}
          />

          <ButtonOutline
            text={status === OFFER_STATUS_CONSTANTS.PAUSED ? "resume" : "pause"}
            additionalClasses='offer-controller'
            icon={status === OFFER_STATUS_CONSTANTS.PAUSED ? <FaPlay className="mr-2" /> : <FaPause className="mr-2" />}
            onClick={
              () => updateStatus(status === OFFER_STATUS_CONSTANTS.PAUSED ? 'publish' : 'pause')
            }
          />
        </>
      }
    </>
  );
}