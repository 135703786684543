import React, { useState, useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastMessage from "../../../components/ToastMessage";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import TextArea from "components/HookForm/TextArea";
import FileSelect from "components/HookForm/FileSelect";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import _ from 'underscore.string'
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import {
  addPitchSection,
  updatePitchSection,
  deletePitchSection,
} from "./queries";
import { editIcon, closeIcon } from "../../../assets/images/index";
import LoadingOverlay from "react-loading-overlay";
import { usePortalizedModal } from "../../../components/PortalModal";
import { useParams } from "react-router-dom";

const Media =({media, mediaEditHandler, mediaDeleteHandler})=>(
  <div key={media.id} className="single-existing-media-mention mb-3">
    <a href={media.link} target="_blank" className="single-existing-pitch-content">
      <div className="existing-media-mention-icon-box">
        <img src={media.logo_url} alt="icon" />
      </div>
      <div className="existing-media-mention-content-box">
        <div className="inner1">
          <p className="existing-media-mention-title poppins weight-700 mb-0">
            {_.prune(media.title, 25, '...')}
          </p>
          <span className="existing-media-mention-subtitle poppins fs-10">
            {_.prune(media.description, 25, '...')}
          </span>
        </div>
      </div>
    </a>
    <div className="existing-pitch-actions">
      <img
        onClick={() => mediaEditHandler(media) }
        src={editIcon}
        alt="edit icon"
      />
      <img
        onClick={() => mediaDeleteHandler(media.id) }
        src={closeIcon}
        alt="close icon"
      />
    </div>
  </div>
)
const mediaMentionValidationSchema = Yup.object().shape({
  media_mention_title: Yup.string().required("Title is required."),
  media_mention_description: Yup.string().required("Description is required."),
  media_image: Yup.object()
    .required("product image is required.")
    .typeError("product image is required."),
  media_url_link: Yup.string()
    .required("Media url is required.")
    .url("Invalid media url."),
  media_name: Yup.string()
    .required("Media name is required.")
});

const MediaMentions = ({ pitch:{media_mentions=[], pitchable_id} }) => {
  const { offerSlug } = useParams();
  const deleteMediaModal = usePortalizedModal("deleteMediaMentionConfirm");
  const [mediaForEdit, setMediaForEdit] = useState(null);
  const queryClient = useQueryClient();

  useEffect(()=>{
    if(mediaForEdit){
      methods.setValue("media_mention_title", mediaForEdit.title)
      methods.setValue("media_mention_description", mediaForEdit.description)
      methods.setValue("media_image", {data: mediaForEdit.logo_url} )
      methods.setValue("media_url_link", mediaForEdit.link)
      methods.setValue("media_name", mediaForEdit.media_name)
    }else {
      methods.reset()
    }
  },[mediaForEdit])

  const deleteMediaMention = useMutation(deletePitchSection, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', 'offers', offerSlug, 'pitch']);
      ToastMessage.success(`Media deleted successfully.`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function mediaDeleteHandler (id){
    deleteMediaModal.open({
      title: `Delete Media`,
      body: <>
        <p>Are you sure you want to delete the media?</p>
        <div class="portalized-modal-footer">
          <button class="btn-black"
            onClick={() => {
              deleteMediaMention.mutate({
                id: id,
                sectionType: "media_mention",
              });
              if(id === mediaForEdit?.id){
                setMediaForEdit(null)
              }
              deleteMediaModal.close()
            }}
          >Confirm</button>
          <button class="btn-black-outline" onClick={deleteMediaModal.close}>Cancel</button>
        </div>
      </>,
      size: 'sm',
      onRequestClose: deleteMediaModal.close,
    });
  };
  const saveMedia = useMutation(
    mediaForEdit ? updatePitchSection : addPitchSection,
    {
      onSuccess: (values) => {
        methods.reset()
        mediaForEdit && setMediaForEdit(null);
        queryClient.invalidateQueries(['admin', 'offers', offerSlug, 'pitch']);
        ToastMessage.success(
          `Media ${mediaForEdit ? "updated" : "added"} successfully.`
        );
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  );

  const onSubmit = (values) => {
    let id = mediaForEdit?.id ?? offerSlug;
    let pitch_section = {
      title: values.media_mention_title,
      description: values.media_mention_description,
      link: values.media_url_link,
      logo: values.media_image,
      media_name: values.media_name
    }
    if(mediaForEdit?.logo_url === values.media_image.data)
      delete pitch_section.logo;

    saveMedia.mutate({
      id: id,
      values: {
        type: "media_mention",
        pitch_section: pitch_section
      },
    });
  };

  const methods = useForm({
    resolver: yupResolver(mediaMentionValidationSchema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      media_mention_title: "",
      media_mention_description: "",
      media_image: null,
      media_url_link: "",
      media_name: ""
    },
  });

  return (
  <LoadingOverlay active={saveMedia.isLoading || deleteMediaMention.isLoading} spinner text="Loading">
    <div className="media-mention-maker">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="add-media-mention mb-4"
        >
          <h6 className="fs-10 montserrat weight-600">Add new media</h6>
          <TextField
            name="media_mention_title"
            placeholder="Title"
            type="text"
          />
          <TextArea
            name="media_mention_description"
            placeholder="Description"
            type="text"
          />
          {/* fileType is used to restrict the type of files to be selected
            types = image/*, audio/* , video/*
          */}
          <FileSelect
            name="media_image"
            placeholder="Upload media image"
            fileType="image/*"
          />
          <TextField
            name="media_url_link"
            placeholder="Media url link"
            type="text"
          />
          <TextField
            name="media_name"
            placeholder="Media name"
            type="text"
          />
          <button type="submit" className="btn-black">
            {mediaForEdit ? "UPADTE MEDIA" : "ADD MEDIA"}
          </button>
          {mediaForEdit && (
            <button
              onClick={() => {
                setMediaForEdit(null);
              }}
              type="button"
              className="btn-black w-25 ml-3"
            >
              CANCEL
            </button>
          )}
        </form>
      </FormProvider>
      {media_mentions.length > 0 &&
        <div className="existing-media-mentions">
          <h6 className="fs-10 montserrat weight-600">Existing media</h6>
          {media_mentions.map((media) => (
            <Media
              key={media.id}
              media={media}
              mediaEditHandler={setMediaForEdit}
              mediaDeleteHandler={mediaDeleteHandler}
            />
          ))}
        </div> }
    </div>
  </LoadingOverlay>
  );
};

MediaMentions.defaultProps = {};

MediaMentions.propTypes = {
  offer_id: PropTypes.string.isRequired,
  pitch: PropTypes.object.isRequired,
};

export default MediaMentions;
