import ToastMessage from '../components/ToastMessage';
import { humanize } from './stringUtils';

const parseKey = (key) => {
  return key.split('.').map(segment => humanize(segment)).join(' ')
}

export function renderRailsErrors (error) {
  if (typeof error === "string") {
    ToastMessage.error(error);
  }
  else {
    for (const [key, value] of Object.entries(error)) {
      if (Array.isArray(value)) {
        /error/.test(key)
          ? ToastMessage.error(`${value}`)
          : value.forEach(errorMsg => ToastMessage.error(`${parseKey(key)} ${errorMsg}`))
        continue
      }

      if (typeof value === 'object' && value !== null) {
        renderRailsErrors(value)
        continue
      }

      if (typeof value == 'string') {
        ToastMessage.error(/errors?/.test(key) ? value : `${key}: ${value}`)
      }
    }
  }
}
