import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import "./Pages.common.scss";
import LimitCalculatorForm from '../components/LimitCalculatorForm/LimitCalculatorForm';
import LoadingOverlay from 'react-loading-overlay';
import { history } from 'redux/helpers'
import { useOffer } from './offer/queries';

export default function LimitCalculator() {
  const { offerId, accountId } = useParams()

  const {data:offer, isLoading} = useOffer(offerId);

  const onSubmit = () => {
    history.push(`/offers/${offerId}/invest/accounts/${accountId}`)
  }

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading ...' >

        <div className="investment-header">
          <div className="container pb-3 pt-first-section">
            <h1 className="fs-2 weight-900 text-white mb-0">
              Invest in <Link to={`/offers/${offer?.slug}`} target="_blank" rel="noopener noreferrer">{offer?.company?.name}</Link>
            </h1>
          </div>
        </div>
        <LimitCalculatorForm onSubmit={onSubmit} showWhyWeNeedText={true} fullPageView={true}/>

      </LoadingOverlay>
    </>
  );
}
