import React from 'react';
import { useFlashMessages, removeFlash } from "redux/flash";

export default function Flash() {
  const flashMessages = useFlashMessages();

  const render_message = ({key, text, type, primaryAction, secondaryAction, onCancel}) => {
    const executePrimaryAction = () => {
      primaryAction.onClick();
      removeFlash(key)
    }

    const executeSecondaryAction = () => {
      secondaryAction.onClick();
      removeFlash(key)
    }

    const removeMessage = () => {
      removeFlash(key)
    }

    return (
      <div key={key} className={`alert alert-${type} text-center my-1`}>
        <button className="close" onClick={removeMessage}>x</button>

          <span className="mr-4 mb-2 d-inline-block zeitung-micro">{text}</span>
          { primaryAction && <button className="zeitung-mono btn-white-outline px-3" style={{marginRight: '8px'}} onClick={executePrimaryAction}>{primaryAction.text}</button> }
          { secondaryAction && <button className="zeitung-mono btn-white-outline px-3" onClick={executeSecondaryAction}>{secondaryAction.text}</button> }

      </div>
    )
  }

  return (
    <div className="container">
      { flashMessages.map(message => render_message(message)) }
    </div>
  )
}
