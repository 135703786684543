export const statusOptions = () => {
  return [
    { value: 'upcoming', label: 'Upcoming', badgeText:  ''},
    { value: 'pre_live', label: 'Pre-Live', badgeText:  'Streaming Soon' },
    { value: 'live', label: 'Live', badgeText:  'streaming live' },
    { value: 'closed', label: 'Post-Live', badgeText:  'Recent Stream' },
    { value: 'completed', label: 'Complete', badgeText:  '' }
  ]
}

export const statusTitle = (status) => {
  const option = statusOptions().find(o => o.value == status)

  if(option){
    return option.label;
  }
}

export const statusLabel = (status) => {
  const option = statusOptions().find(o => o.value == status)

  if(option){
    return option.badgeText;
  }
}

export const nextStatus = (status) => {
  const nextStatusMapping = {
    'upcoming': { status: 'pre_live', text : 'Go Pre-Live'},
    'pre_live': {status: 'live', text: 'Go Live'},
    'live': {status: 'closed', text: 'Go Post-Live' },
    'closed': {status: 'completed', text: 'Complete' }
  }

  return nextStatusMapping[status];
}

export default {
  statusTitle,
  statusLabel,
  nextStatus
}