import React from "react";
import ReactDOM from "react-dom";
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { PortalizedModalContext } from "./../contexts/portalizedModalContext";
import './PortalizedModal.scss';
import bgVideo from '../assets/videos/petition-bg.mp4'

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex:999
  },
  content : {
    borderRadius: 0,
    position: 'fixed',
    padding: 0,
    top: '50%',
    right: 'unset',
    bottom: 'unset',
    left: '50%',
    transform: 'translate(calc(-50% - 1px), -50%)',
    backgroundColor:'rgba(0, 0, 0, 0.0)',
    border:'0',
    zIndex: '2',
    overflowY: 'auto'
  }
}

const CloseBtn = ({onClick}) =>{
  return(
    <button className="drawer-close-btn"
    onClick={onClick}
    >
    <MdClose color="#fff" size="25px" />
  </button>
  )
}
const getModalStyleClass = (modalType) => {
  let modalStyleClass = "";
  modalType.map((type) => {
    switch (type) {
      case "headless-modal":
        modalStyleClass += "portalized-headless-modal ";
        break;
      case "with-video-background":
        modalStyleClass += "portalized-bg-video-modal ";
        break;
      default:
        break;
    }
  });
  return modalStyleClass;
};
export default function PortalizedModal() {
  const { modal, closeModal, content } = React.useContext(PortalizedModalContext);
  // Possible allowed modalTypes. are ['headless-modal', 'with-video-background']
  // modal type is passed in the form of array
  // If no type is pass it will automatically be the default modal with header.

  const modalType = content.modalType ?? [];
  if(modalType.length === 0 && !content.title) modalType.push('headless-modal');
  const showVideoBg = modalType.includes('with-video-background') ?? false
  if (!modal) return null;

  return ReactDOM.createPortal(
    <Modal
      portalClassName={`fadein-modal portalized-modal-${content.size || 'md'} ${getModalStyleClass(modalType)}`}
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={closeModal}
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <div className="portalized-modal-wrapper">
        {showVideoBg && <div className="portalized-modal-background-video-container">
          <video autoPlay muted playsInline loop>
            <source src={bgVideo} type="video/mp4" />
            Your browser does not support this video.
          </video>
        </div>}
        <div className="portalized-modal-header">
          {content.title && <h5 className="portalized-modal-title">{content.title}</h5>}
          <CloseBtn onClick={closeModal}/>
        </div>
        <div className="portalized-modal-body">
          {content.content}
        </div>
      </div>
    </Modal>,
    document.querySelector("#modal-root")
  );
}
