import React from "react";

const FailedBadge = () => {
  return <span className="custom-badge bg-golden ml-2">unverified</span>;
};

const PendingBadge = () => {
  return <span className="custom-badge bg-grey ml-2">pending</span>;
};

const SuccessBadge = () => {
  return <span className="custom-badge bg-sea-green ml-2">verified</span>;
};

const ApprovdeBadge = () => {
  return <span className="custom-badge bg-success ml-2">Approved</span>;
};

const DisapprovedBadge = () => {
  return <span className="custom-badge bg-danger-dark ml-2">Disapproved</span>;
};

const NeedsMoreInfo = () => {
  return <span className="custom-badge bg-golden f-black ml-2">Needs More Info</span>;
}

export { FailedBadge, SuccessBadge, PendingBadge, ApprovdeBadge, DisapprovedBadge, NeedsMoreInfo };
