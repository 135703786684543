import React from 'react';
import { useMutation } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { createPreoffer } from './queries';
import OverviewForm from './OverviewForm';
import { history } from 'redux/helpers';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

function OverviewCreator({companySlug, onCreateSuccess}) {

  const createPreofferMutation = useMutation(
    createPreoffer,
    {
      onSuccess: (preoffer) => {
        ToastMessage.success("Preoffer created.")
        onCreateSuccess();
        history.push(`/admin/prelaunch/${preoffer.slug}/edit/overview`)
      },
      onError: (error) => {
        ToastMessage.error('Preoffer create failed.')
        renderRailsErrors(error)
      },
    }
  )

  const onSubmit = (values) => {
    createPreofferMutation.mutate({
      company_id: companySlug,
      values
    })
  }

  return (
    <OverviewForm onSubmit={onSubmit}/>
  );
}

export default OverviewCreator;
