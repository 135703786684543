import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FixedButtonBox from '../shared/FixedButtonBox';
import { useUser, updateInvestorAccount } from 'redux/user'
import * as Yup from 'yup';
import { getSSNMasking, getTaxIdLabel } from 'jsUtils/stringUtils';
import { preventDefaultSubmit } from '../../../jsUtils/formUtils';
import MaskedInput from "react-text-mask";
import FormikValidationsRollbarReporter from '../shared/FormikValidationsRollbarReporter';
import LoadingOverlay from 'react-loading-overlay';

export default function SsnEditor({
  clientId,
  countryCode,
  onSubmit=null,
}) {
  const user = useUser();

  return (
    <LoadingOverlay active={user.loading} spinner text="Loading">
      <div className="investor-formbox">
        <Formik
          initialValues={{
            tax_id_number: "",
          }}
          enableReinitialize={true}
          validationSchema={ Yup.object().shape({
            tax_id_number: Yup.string()
              .required("Tax ID required")
              .test('len', 'Must be exactly 9 digits', val => val?.match(/[a-zA-Z0-9]/g)?.length === 9),
          })}
          onSubmit={async (values, { }) => {
            values.tax_id_number = values.tax_id_number.replace(/-/g, '');
            return updateInvestorAccount(clientId, values, onSubmit);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form onKeyDown={preventDefaultSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="invest-form-fields investor-formbox-inner1">
                      <div className="form-row">
                        <div className="col-md-12 px-1">
                          <div className="form-label-group">
                            <Field name="tax_id_number">
                              {({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={getSSNMasking(values.country)}
                                  id="tax_id_number"
                                  placeholder="SSN"
                                  type="text"
                                  className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                                />
                              )}
                            </Field>
                            <label htmlFor="tax_id_number">{getTaxIdLabel(countryCode)}</label>
                            <ErrorMessage name="tax_id_number" component="div" className="invalid-feedback text-left" />
                          </div>
                        </div>
                      </div>
                      <div className="portalized-modal-footer">
                        <FixedButtonBox isSubmitting={isSubmitting} buttonTitle={`Update ${getTaxIdLabel(countryCode)}`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FormikValidationsRollbarReporter form="Investor Details / SSN Editor (Individual)" />
            </Form>
          )}
        </Formik>
      </div>
    </LoadingOverlay>
  )
}
