import React, {useEffect, useState, useRef} from "react";
import formatMoney from "accounting-js/lib/formatMoney.js";
import { FaExternalLinkAlt } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import confetti from "canvas-confetti";
import { progressBarPercentage, raisedFundsPercentage } from "../../jsUtils/offerUtils";
import InvestmentButton from "../../admin/components/OfferView/InvestmentButton";
import { usePusherChannel } from "../../contexts/PusherChannelContext";
import { statusLabel } from "./StatusUtil";

export default function GoalProgressBar({ offer, user }) {
  const pusherChannel = usePusherChannel(offer.live_event.channel_id);
  const company = offer.company;
  const liveEvent = offer.live_event;
  const tooltipRef = useRef(null);
  const progressBarRef = useRef(null);
  const raisedAmountRef = useRef(null);

  const [status, setStatus] = useState(liveEvent.status);
  const [liveStats, setLiveStats] = useState({
    counterStartValue: parseInt(liveEvent.raised_amount),
    raisedAmount: parseInt(liveEvent.raised_amount),
    investmentAmount: 0,
    userTotalInvestment: offer.investments?.reduce((sum, investment) => sum + parseFloat(investment.amount), 0),
  });

   useEffect(() => {
    pusherChannel.bind('new-investment', showLatestInvestment);
    pusherChannel.bind('status-change', (data) => { setStatus(data.status)} );
    toggleTooltipView('hide');

    return () => {
      pusherChannel.unbind('new-investment', () => {});
      pusherChannel.unbind('status-change', () => {});
    }
  }, []);

  const showConfetti = ({x, y}) => {
    confetti({
      particleCount: 70,
      spread: 90,
      origin: { y: y, x: x },
      gravity: 0.3,
      colors: ['#fff', '#eee',  '#f5f5f5', '#cccccc', '#888888']
    });
  }

  const toggleTooltipView = (type)=> {
    const knob = tooltipRef.current.getElementsByClassName('knob')[0];

    if(type == 'show'){
      knob.classList.add('show');
    } else {
      knob.classList.remove('show');
    }
  }

  const showLatestInvestment = (data) => {
    setLiveStats({
      counterStartValue: (parseInt(data.live_event.raised_amount) - parseInt(data.user_investment.amount)),
      raisedAmount: parseInt(data.live_event.raised_amount),
      investmentAmount: parseInt(data.user_investment.amount),
      userTotalInvestment: (user?.id == data.user_investment.user_id && data.user_investment.total_investment),
    });

    tooltipRef.current.dispatchEvent(new CustomEvent('show-new-investment'));
    toggleTooltipView('show');

    setTimeout(() => {
      toggleTooltipView('hide');
      ReactTooltip.hide(tooltipRef.current);
    }, 4000);

    if(progressBarRef.current){
      const raisedProgessBareleBounds = progressBarRef.current.getBoundingClientRect();
      const dotEleBounds = tooltipRef.current.getBoundingClientRect();
      showConfetti({
        y: (raisedProgessBareleBounds.bottom / window.innerHeight).toFixed(2),
        x: ((dotEleBounds.left + (dotEleBounds.width /2)) / window.innerWidth).toFixed(2)
      })
    }

    const raisedAmountEleBounds = raisedAmountRef.current.getBoundingClientRect();
    showConfetti({
      y: (raisedAmountEleBounds.bottom / window.innerHeight).toFixed(2),
      x: ((raisedAmountEleBounds.left + (raisedAmountEleBounds.width /2)) / window.innerWidth).toFixed(2)
    });
  };

  const fundsPercentage = raisedFundsPercentage(liveStats.raisedAmount, liveEvent.goal_amount) || 0;

  return (
    <div className="row mb-4 px-md-0 pt-2">
      <div className="px-3 col-12 col-lg-8">
        { statusLabel(status) && <div className="mb-2"><span className="fs-18 weight-900 montserrat mr-3 px-3 align-middle live-indicator">{statusLabel(status)}</span> </div> }
        <div className='w-100'>
          <span className="align-middle weight-900 fs-5 mr-3">{company.name}</span>
          {company.url && <a className="company-link fs-default" target="_blank" rel="noopener noreferrer" href={company.url}>{<FaExternalLinkAlt/>}</a> }
        </div>
        <div className='w-100'>
          <ReactTooltip id="new-investment" effect="solid" type="dark" className="new-investment-tooltip">
            <span className="d-block fs-14 weight-400 poppins text-center">New Investment</span>
            <span className="d-block fs-18 weight-900 montserrat text-center">{formatMoney(liveStats.investmentAmount, {precision : 0})}</span>
          </ReactTooltip>
          <div className="progress-box">
            <div className="progressbar">
              <div style={{width: `${progressBarPercentage(fundsPercentage)}%`}} className="progress">
                <span className="float-right" data-tip data-for="new-investment" data-event="show-new-investment" data-place="top" ref={tooltipRef}>
                  <span className="knob" />
                </span>
              </div>
            </div>
          </div>
          <div className="row px-0 mt-1">
            <div className="col-12 col-md-6 fs-18 weight-900">
              <span ref={raisedAmountRef}>
                {formatMoney(liveEvent.goal_amount, {precision : 0})} EVENT TARGET
              </span>
            </div>
            { liveStats.investmentAmount > 0 &&
              <span className="col-12 col-md-6 fs-default poppins text-md-right">
                <span className="color-success align-middle mr-1">New Investment:</span>
                <span className="align-middle">{formatMoney( liveStats.investmentAmount, {precision : 0})}</span>
              </span>
            }
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4 mt-4">
        <div className="row px-0 fs-14 poppins">
          <span className="col-md-6 col-12 text-left">{ liveStats.userTotalInvestment > 0 && `Invested: ${formatMoney(liveStats.userTotalInvestment, {precision : 0})}`}</span>
          <span className="col-md-6 col-12 text-muted text-md-right">{formatMoney(offer.deal.minimum_investment, {precision: 0})} Min. Inv.</span>
        </div>
        <InvestmentButton offer={offer} user={user} cssClass="mt-0" openNewWindow={true}/>
      </div>
    </div>
  )
}