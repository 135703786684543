import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function LoginLink(){
  const location = useLocation();
  const state = { afterLoginPath: location.pathname };

  return(
    <div className="poppins fs-14 py-4 text-center">
      <Link className="sign-in-link" to={{ pathname: '/signup', state: state }}>Sign up</Link> or
      <Link className="sign-in-link ml-1" to={{ pathname: '/login', state: state }}>login</Link> to participate
    </div>
  )
}