import React from 'react';

export default function ClientFieldRow({ field, value, onEdit }) {
  return (
    <div className="info-updation-box">
      <div className="iu-box-inner1">
        <label>{field}</label>
        <p>{value || '-'}</p>
      </div>
      <div className="iu-box-inner2 pl-3">
        <span className="action-btn" onClick={onEdit}>Edit</span>
      </div>
    </div>
  )
}