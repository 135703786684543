import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import spacedXScreen from '../../assets/images/graphics/one-side-rounded-SpaceX-promo.png';
import spacedXMobileScreen from '../../assets/images/graphics/all-rounded-SpaceX-promo.png';

export default function SpaceXPromo() {
  return (
    <>
      <SpaceXPromoStyles>
        <div className="grid-section">
          <div className="spaced-x-promo-text-container">
            <div className="spaced-x-promo-text">
              <h2 className="fs-5 weight-900">We’re Petitioning <span className="black-outlined-text">SpaceX</span> to Raise</h2>
              <p style={{marginBottom: '0px', paddingBottom: '0px'}} className="fs-18 weight-400 m-t-15 poppins">Join the petition to get SpaceX to raise on Spaced Ventures.</p>
              <p style={{marginTop: '10px', paddingTop: '0px'}} className="fs-12 text-muted weight-400 poppins">Spaced Ventures is not affiliated with, sponsored by or endorsed by SpaceX.</p>
              <div className="m-t-25">
              <BlackButtonLink to="/raise-petitions/spacex">
                <BlackButtonStyle>View Petition</BlackButtonStyle>
              </BlackButtonLink>
              </div>
            </div>
          </div>
          <img className="space-x-promo-image" src={window.innerWidth > 818 ? spacedXScreen : spacedXMobileScreen} alt="SpacedBase now live"></img>
        </div>
      </SpaceXPromoStyles>
    </>
  )
}

const SpaceXPromoStyles = styled.div`
  padding-bottom: 100px;

  .space-x-promo-image {
    max-width: 1200px;
  }

  .grid-section {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-items: center;
    align-items: center;

      .spaced-x-promo-text-container {
        justify-self: start;
      }

      .space-x-promo-image {
        justify-self: end;
        width: 100%;
        max-width: 800px;

        @media (max-width: 818px) {
          padding: 10px;
          margin-top: 30px;
          justify-self: center;
          order: 1;
        }
      }
  .spaced-x-promo-text-container {
      margin: 0 auto;

    @media (max-width: 818px) {
      order: 2;
      }
  }

  .spaced-x-promo-text {
    padding-left: 100px;
    padding-right: 20px;
    @media (max-width: 818px) {
      padding-left: 14px;
      padding-right: 14px;
      }
  }
}
`


const BlackButtonLink = styled(Link)`
  text-decoration: none;
  color: #111;

  :hover {
    text-decoration: none;
  }
`

const BlackButtonStyle = styled.button`
  background-color: #111;
  border: 1px solid #111;
  font-weight: 600;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  transition: all 0.3s ease 0s;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  cursor: pointer;
  border-radius: 2px;
  font-family: 'Poppins', sans-serif;
  @media (max-width: 700px) {
    margin-top: 25px;
    max-width: 100%;
    width: 100%;
  }
  :hover {
  background-color: #fff;
  border: 1px solid #111;
  color: #111;
  }

`;