import React from 'react';
import { parseYoutubeVideoId } from 'jsUtils/stringUtils';

const YoutubeEmbed = function YoutubeEmbed ({videoUrl}) {
  let url;

  if(videoUrl.indexOf('streamyard.com') > -1){
    url = videoUrl
  } else {
    const videoId = parseYoutubeVideoId(videoUrl);
    url = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0`
  }

  return (
    <div className="youtube-vid-container">
      <iframe width="100%" height="100%"
        title="banner-video"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

export default React.memo(YoutubeEmbed)