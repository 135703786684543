const ACTIONS = {
  READ_PITCH: "READ_PITCH",
  READ_COMPANY: "READ_COMPANY",
  READ_OFFER: "READ_OFFER",
  READ_PREOFFER: "READ_PREOFFER",
  READ_RAISE_PETITION: "READ_RAISE_PETITION",
  READ_INVESTORS: "READ_INVESTORS",
  READ_RAISE_PETITION_PLEDGES: "READ_RAISE_PETITION_PLEDGES",
  READ_DOWNLOAD_INVESTORS: "READ_DOWNLOAD_INVESTORS",
  READ_DOWNLOAD_RAISE_PETITION_PLEDGES: "READ_DOWNLOAD_RAISE_PETITION_PLEDGES",
  READ_PREOFFER_SUBSCRIBERS: "READ_PREOFFER_SUBSCRIBERS",
  READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: "READ_DOWNLOAD_PREOFFER_SUBSCRIBERS",
  READ_OFFER_PERFORMANCE: "READ_OFFER_PERFORMANCE",
  READ_RAISE_PETITION_PERFORMANCE: "READ_RAISE_PETITION_PERFORMANCE",
  READ_MANAGER_ACCOUNTS: "READ_MANAGER_ACCOUNTS",
  READ_OFFER_UPDATES: "READ_OFFER_UPDATES",
 
  WRITE_INVITE_MANAGER_ACCOUNTS: "WRITE_INVITE_MANAGER_ACCOUNTS",
  WRITE_UPDATE_COMPANY: "WRITE_UPDATE_COMPANY",
  WRITE_UPDATE_OFFER: "WRITE_UPDATE_OFFER",
  WRITE_UPDATE_RAISE_PETITION: "WRITE_UPDATE_RAISE_PETITION",
  WRITE_UPDATE_PITCH: "WRITE_UPDATE_PITCH",
  WRITE_UPDATE_OFFER_UPDATE: "WRITE_UPDATE_OFFER_UPDATE",

  PREVIEW_OFFER: "PREVIEW_OFFER",
  PREVIEW_PREOFFER: "PREVIEW_PREOFFER",
}
 
const ABILITIES = {
  "Admin": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  },

  "Content Editor": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  },

  "Marketer": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  },

  "Customer Service": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  },

  "Compliance": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  },

  "Advisor": {
    READ_PITCH: true,
    READ_COMPANY: true,
    READ_OFFER: true,
    READ_RAISE_PETITION: true,
    READ_PREOFFER: true,
    READ_INVESTORS: true,
    READ_RAISE_PETITION_PLEDGES: true,
    READ_DOWNLOAD_INVESTORS: true,
    READ_DOWNLOAD_RAISE_PETITION_PLEDGES: true,
    READ_PREOFFER_SUBSCRIBERS: true,
    READ_DOWNLOAD_PREOFFER_SUBSCRIBERS: true,
    READ_OFFER_PERFORMANCE: true,
    READ_RAISE_PETITION_PERFORMANCE: true,
    READ_MANAGER_ACCOUNTS: true,
    READ_OFFER_UPDATES: true,
    WRITE_INVITE_MANAGER_ACCOUNTS: true,
    WRITE_UPDATE_COMPANY: true,
    WRITE_UPDATE_OFFER: true,
    WRITE_UPDATE_RAISE_PETITION: true,
    WRITE_UPDATE_PITCH: true,
    WRITE_UPDATE_OFFER_UPDATE: true,
    PREVIEW_OFFER: true,
    PREVIEW_PREOFFER: true,
  }
 }

 const isPermitted = (roles, action) => {
  for (const role of roles) {
    if (ABILITIES[role.name]?.[action])
      return true
  }
  return false
}

const permittedActions = (roles) => {
  const permitted = new Set();

  for (const role of roles) {
    if (role.category !== "moderator") {
      continue;
    }

    if (ABILITIES[role.name]){
      Object.keys(ABILITIES[role.name]).forEach(
        key => permitted.add(key.toString())
      )
    }
  }

  if (roles.findIndex(role => role.category === "issuer") !== -1) {
    permitted.add("PREVIEW_OFFER");
    permitted.add("PREVIEW_PREOFFER");
  }

  return permitted;
}

export {
  ABILITIES,
  ACTIONS,
  isPermitted,
  permittedActions,
};