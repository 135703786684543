import React from 'react';
import Modal from 'react-modal';
import { closeIcon } from "assets/images"
import { history } from 'redux/helpers'

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex:1
  },
  content: {
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    position: 'fixed',
    padding: 0,
    top: '0',
    left: '0',
    width: '100%',
    minHeight:'100vh',
    height: '100%',
    zIndex: '4',
    border: 0
  }
}

export default function EditorModal({onRequestClose, children}) {
  const closeRequestHandler = () => {
    if (typeof onRequestClose === 'function')
      onRequestClose();
    else
      history.goBack();
  }

  return(
    <Modal
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={closeRequestHandler}
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <span className="close-btn-white cursor-pointer" onClick={closeRequestHandler}
        style={{
          position: 'absolute',
          top: '16px',
          right: '12px',
          zIndex: 'inherit',
        }}
      >
        <img src={closeIcon} alt="close icon"/>
      </span>
      {children}
    </Modal>
  );
}
