import React from "react";
import AmlSectionsContainer from "./AmlSectionsContainer";
import { formatDate } from '../../../../jsUtils/dateTimeUtils';

export default function UploadedDocuments({ documents }) {
  return (
    <AmlSectionsContainer classes="mt-2" title="Uploaded Documents" tooltipText="
    Uploaded documents will appear here. Our team will review the documents and mark them as valid or invalid. When a document is marked as invalid, we'll provide a rejection reason and you'll need to upload another document.">
      <div className="content-layer">
        <table className="table table-borderless mb-0">
          <thead>
            <tr className="row">
              <th className="col-3">Title</th>
              <th className="col-3">Upload Date</th>
              <th className="col-2">Status</th>
              <th className="col-4">Notes</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document) => (
              <tr key={document.id} className='row'>
                <td className="col-3">{document.name}</td>
                <td className="col-3">{formatDate(document.created_at)}</td>
                <td className="col-2">{document.status}</td>
                <td className="col-4">{document.invalid_reason ?? "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AmlSectionsContainer>
  );
}
