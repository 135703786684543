import React from 'react';
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import { formatSSN, humanize} from '../../../jsUtils/stringUtils';

export default function EntityDetail({ account }) {
  return (
    <>
      <OverviewSubheader left={`[${humanize(account.service_provider)}] ${account.name}`} />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Entity contact name' value={account.contact_name} />
            <AttributeValue name='Entity type' value={account.entity_type} />
            <AttributeValue name='Business / tax ID #' value={formatSSN(account.tax_id_number)} />
            <AttributeValue name='Date of formation' value={account.date_of_formation} />
            <AttributeValue name='City of of formation' value={account.city_of_formation} />
            <AttributeValue name='Country of formation' value={account.country_of_formation?.toLocaleUpperCase()} />
          </>
        }
        right={
          <>
            <AttributeValue name='Address' value={account.street_address_1} />
            <AttributeValue name='City' value={account.city} />
            <AttributeValue name='State' value={account.region} />
            <AttributeValue name='Country' value={account.country} />
            <AttributeValue name='Zip code' value={account.postal_code} />
            <AttributeValue name='Phone number' value={account.phone} />
          </>
        }
      />
    </>
  )
}