import React, {useEffect} from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import HookForm from 'components/HookForm';
import { offerUpdateApi } from 'api/offerUpdate';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { formatDate, parseDateWithoutTimezone } from 'jsUtils/dateTimeUtils';

const FormSchema = Yup.object().shape({
  title: Yup.string().required(),
  content: Yup.object()
    .test(
      'has text',
      'Offer update content is required',
      value => value && value.getCurrentContent().getPlainText().trim().length > 0
    ),
  published_at: Yup.date().required(),
  notify_users: Yup.bool().optional(),
  status: Yup.string().oneOf(['pending', 'approved', 'rejected']).default('pending'),
  rejection_reason: Yup.string().nullable().optional()
});

export default function Form({ offerId, offerUpdate, setCurrentOfferUpdate }) {
  const isEditForm = offerUpdate.id;
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      title: offerUpdate.title,
      content: HookForm.RichTextInput.createState(offerUpdate.content || ''),
      published_at: parseDateWithoutTimezone(offerUpdate.published_at),
      notify_users: true,
      status: 'pending',
      rejection_reason: offerUpdate.rejection_reason || ''
    }
  });

  useEffect(() => {
    if (offerUpdate) {
      methods.setValue('title', offerUpdate.title);
      methods.setValue('content', HookForm.RichTextInput.createState(offerUpdate.content));
      methods.setValue('published_at', parseDateWithoutTimezone(offerUpdate.published_at));
      methods.setValue('status', offerUpdate.status);
      methods.setValue('notify_users', offerUpdate.notify_users);
      methods.setValue('rejection_reason', offerUpdate.rejection_reason);
    }
  }, [offerUpdate]);

  const queryClient = useQueryClient();
  const saveOfferUpdate = useMutation(
    isEditForm ? offerUpdateApi.admin.update : offerUpdateApi.admin.create,
    {
      onSuccess: (values) => {
        setCurrentOfferUpdate(null);
        queryClient.invalidateQueries(['offerUpdates', offerId]);
        ToastMessage.success(
          `Offer update successfully ${isEditForm ? 'updated' : 'posted'}.`
        );
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  )

  const onSubmit = data => {
    const attrs = {
      title: data.title,
      content: HookForm.RichTextInput.contentForSave(data.content),
      published_at: formatDate(data.published_at, 'yyyy-MM-dd'),
      notify_users: data.notify_users,
      status: data.status,
      rejection_reason: data.rejection_reason
    };

    saveOfferUpdate.mutate({ id: offerUpdate.id || offerId, attrs});
  };

  const statusOptions = [
    { value: 'pending', label: 'Pending'},
    { value: 'approved', label: 'Approve'},
    { value: 'rejected', label: 'Reject'},
  ]

  const status = methods.watch('status');

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1">
        <div className="row mt-3">
          <div className="col input-bg-white">
            <HookForm.TextInput
              name="title"
              placeholder="Title"
            />
          </div>
        </div>
        <div className="row">
          <div className="col mb-4">
            <HookForm.RichTextInput name="content" containerStyle={{ height: "400px"}}/>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 input-bg-white">
            <HookForm.DateTimeField name="published_at" placeholder="Published Date" timeFormat={false} />
          </div>
          <div className="col-12 col-md-6 mb-1 mt-2 d-md-block d-none">
            <div className="col">
              <HookForm.CheckboxSwitchField name="notify_users" placeholder="Send email notice?" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 input-bg-white">
            <HookForm.SelectInput
              name="status"
              placeholder="-- Select Status -- "
              options={statusOptions}
              labelClass="z-index-1000"
              className="ml-1"
            />
          </div>
          <div className="col-12 col-md-6 input-bg-white">
            {status === 'rejected' &&
              <HookForm.TextInput name="rejection_reason" placeholder="Rejection Reason" />
            }
          </div>
        </div>
        <div className="form-group mt-4 d-flex flex-wrap align-items-center">
          <button type="submit"
            className="mr-3 weight-300 btn btn-outline-dark rounded-0"
            disabled={saveOfferUpdate.isLoading}
          >
            {isEditForm ? 'Update' : 'Post'}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
