import React from 'react';
import { Waitlist } from 'waitlistapi'
import styled from 'styled-components';

const WaitListAPI = () => {
  return ( 

    <WaitListContainer>
      <Waitlist 
      api_key="T7PQF7" 
      waitlist_link="https://www.spacedventures.com/"
      joinWaitlistHeading="First batch opens June 23rd!"
      joinWaitlistButton="Sign up for Beta"  />
    </WaitListContainer>
   );
}
 
export default WaitListAPI;

const WaitListContainer =  styled.div`
  h1 {
    font-size: 25px !important;
  }

  button {
    border-radius: 2px !important
  }
  .container--waitlistapi {
    padding-left: 0px !important;
  }

  img {
    display: none !important;
  }
  span {
    display:none !important;
    color: #676767 !important;
    a {
      color: #676767 !important;
    }
  }
  .statusToggleLabel {
    display: block !important;
  }
  
`;