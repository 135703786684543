import React from "react"
import { formatWebsiteUrl } from 'jsUtils/stringUtils'
import { NavLink } from "react-router-dom";
import { runGAEvent } from "../../../pages/components/Tracking";
import CompanySocialLinks from "../OfferView/CompanySocialLinks";

function CompanySectionTableRow({label, value, isExternalLink=false,}) {
  return(
    <div className="table-row">
      <div className="deal-attribute">
        <span className="deal-attribute-title">{label}</span>
      </div>
      <span className="deal-attribute-value">{isExternalLink ? <a href={value} target="blank" >{formatWebsiteUrl(value)}</a> : value }</span>
    </div>
  )
}


export default function CompanySectionTable({company, following_company}){
  return(
    <div className="company-inner-section mt-5">
      <div className="deal-terms-table">
        <div  className="table-rows mb-5">
          <CompanySectionTableRow
            label="Website"
            value={company.url}
            isExternalLink={true}
          />
          <CompanySectionTableRow
            label="Employeee Count"
            value={`${company?.employee_count ? company?.employee_count : 0 } People`}
          />
          <CompanySectionTableRow
            label="Founding year"
            value={company?.founding_year}
          />
          <CompanySectionTableRow
            label="Company type"
            value={company?.private ? 'Private' : 'Public'}
          />
        </div>
        <div className="company-preview-btn-section">
          <NavLink
            onClick={() => {
              runGAEvent(`${company.name} view company profile button`, "clicked", "button");
            }}
            className={"btn-black"}
            target="_blank"
            to={`/company/${company?.slug}`}
          >
            View Company Profile
          </NavLink>
        </div>
      </div>
      <div className="mt-3">
          <CompanySocialLinks theme={"dark"} company={company} following_company={following_company} />
        </div>      
    </div>
  )
}