import axiosClient from 'config/axiosClient';

export const investorApi = {
  list,
  search,
  fetch,
  fetchFiltered,
  exportCsv,
};

function search(pattern, page, per_page) {
  return axiosClient.get(`/investors/search?&name=${pattern}&page=${page}&per_page=${per_page}`);
}

function list(filterValue, search_query, page, per_page) {
  const options = { params: {
      query:search_query,
      page,
      per_page
  }}
  
  if (filterValue)
    return axiosClient.get(
      `/investors/${filterValue}`, options);

  return axiosClient.get(
    `/investors`, options);
}

function fetch(investorId) {
  return axiosClient.get(`/investors/${investorId}`);
}

function fetchFiltered( filter, name= null ) {
  return axiosClient.get(`investors/${filter}?name=${name}`);
}

function exportCsv (filter, name = null) {
  return axiosClient.get(`${filter ? `investors/${filter}.csv?name=${name}` : `investors.csv?name=${name}`}`);
}