import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FaExternalLinkAlt } from "react-icons/fa";
import { updateDocumentStatus } from "./queries";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { humanize } from "jsUtils/stringUtils";
import OverviewSubheader from "../../components/OverviewSubheader";
import ToastMessage from "../../../components/ToastMessage";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import UploadDocument from "../../components/shared/AmlException/UploadDocument";
import StatusBadge from "../../../components/StatusBadge";
import HookForm from "../../../components/HookForm";

function DocumentItem({ entityDocument, documentStatusUpdateHandler }) {
  return (
    <div className="highlighted-tile mb-1 d-flex flex-wrap">
      <div className="col-2">{entityDocument.name}</div>
      <div className="col-2">
        {humanize(entityDocument.attachment_type)}
      </div>
      <div className="col-1">
        <StatusBadge status={entityDocument.status} />
      </div>
      <div className="col-2">{entityDocument.invalid_reason}</div>
      <div className="col-5">
        <div className="w-100 text-right">
          <a
            href={entityDocument.url}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link mr-2"
          >
            <FaExternalLinkAlt className="fs-14 color-black" />
            <span> View</span>
          </a>
          <button
            disabled={entityDocument.status === 'valid'}
            onClick={() => {
              documentStatusUpdateHandler({ documentId: entityDocument.id, status: 'valid'});
            }}
            className="btn-black mr-2"
          >
            Valid
          </button>
          <button
            disabled={entityDocument.status === 'invalid'}
            onClick={() => {
              documentStatusUpdateHandler({ documentId: entityDocument.id, status: 'invalid' });
            }}
            className="btn-black"
          >
            Invalid
          </button>
        </div>
      </div>
    </div>
  )
}

export default function EntityDocumentList({ amlException }) {
  const entityDocuments = amlException.entity_documents;
  const statusUpdateModal = useContext(PortalizedModalContext);
  const queryClient = useQueryClient();

  const statusUpdateValidationSchema = Yup.object().shape({
    status: Yup.string(),
    invalid_reason: Yup.string().when('status', {
      is: 'invalid',
      then: Yup.string().required("Please enter the reason of invalidation.")
    })
  });

  const methods = useForm({
    resolver: yupResolver(statusUpdateValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    criteriaMode: "firstError",
    defaultValues: {
      invalid_reason: "",
      status: null
    },
  });

  const updateStatusMutation = useMutation(updateDocumentStatus, {
    onSuccess: (values) => {
      const previousAmlException = queryClient.getQueryData(["aml_exception", amlException.client_id]);
      const index = previousAmlException.entity_documents.findIndex((f) => f.id === values.id);

      if (index > -1) {
        previousAmlException.entity_documents[index] = values;
        queryClient.setQueryData(["aml_exception", previousAmlException.client_id], previousAmlException);
      }

      ToastMessage.success("Document status updated successfully.");
    },
    onError: (error) => {
      renderRailsErrors(error);
    },
    onSettled: () => {
      methods.reset();
      statusUpdateModal.closeModal();
    }
  });

  const documentStatusUpdateHandler = ({ documentId, status }) => {
    const onSubmit = (values) => {
      updateStatusMutation.mutate({
        documentId,
        values: { status: status, invalid_reason: values.invalid_reason}
      });
    };

    statusUpdateModal.setModalContent(
      `Mark status as ${status}`,
      <>
        <p>
          Are you sure you want to mark it as {status}?
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {status === 'invalid' && (
              <div className="row">
                <div className="col">
                  <HookForm.TextInput
                    name="invalid_reason"
                    placeholder="Reason of marking as invalid*"
                  />
                </div>
              </div>
            )}
            <HookForm.HiddenInput name="status" type="hidden"/>
            <div className="portalized-modal-footer">
              <button
                type="submit"
                disabled={updateStatusMutation.isLoading}
                className="btn-black"
              >
                Confirm
              </button>
              <button
                className="btn-black-outline"
                onClick={() => {
                  methods.reset();
                  statusUpdateModal.closeModal();
                }}
              >
                Close
              </button>
            </div>
          </form>
        </FormProvider>
      </>,
      "sm"
    );

    methods.setValue('status', status);
    statusUpdateModal.openModal();
  };

  return (
    <>
      <OverviewSubheader left="Uploaded Documents" right={
        <UploadDocument
          category='aml_exception'
          clientId={amlException.client_id}
          clientType={amlException.client.entity_type}
          isHeaderBtn={true}
        />
      }/>
      { !entityDocuments.length && <span className="highlighted-tile">No documents uploaded yet.</span> }
      <div className="entity-documents-table">
        { entityDocuments.map((entityDocument) => <DocumentItem key={entityDocument.id} entityDocument={entityDocument} documentStatusUpdateHandler={documentStatusUpdateHandler} />) }
      </div>
    </>
  )
}