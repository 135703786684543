import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './SideDrawer.scss'
import { whiteLogoSmall } from '../../../assets/images/index';
import { MdClose } from 'react-icons/md';
import { FaLinkedin, FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';

const PublicSideDrawer = ({onClose, show}) => {
  let drawerClasses = 'side-drawer';

  if (show) {
    drawerClasses = 'side-drawer open';
  }

  const links = [
    // <NavLink onClick={onClose} exact to="/" activeClassName="drawer-nav-selected">Home</NavLink>,
    <NavLink onClick={onClose} to="/offerings" activeClassName="drawer-nav-selected">Offers </NavLink>,
    <a href="https://www.33fg.com/">Mach33 </a>,
    // <label className='drawer-items-label'>Learn</label>,
      // <NavLink onClick={onClose} to="/resources" className='chlid-list-item' activeClassName="drawer-nav-selected">Resources</NavLink>,
      // <NavLink onClick={onClose} to="/accredited-investors" className='chlid-list-item' activeClassName="drawer-nav-selected">For Investors</NavLink>,
      // <NavLink onClick={onClose} to="/raise" className='chlid-list-item' activeClassName="drawer-nav-selected">For Founders </NavLink>,
    // <label className='drawer-items-label'>Resources</label>,
      // <a target="_blank" href="https://blog.spacedventures.com/" className='chlid-list-item' rel="noopener noreferrer">Blog</a>,
      // <a target="_blank" href="https://spacedventures.zendesk.com/hc/en-us" className='chlid-list-item' rel="noopener noreferrer">FAQs</a>,
      // <NavLink onClick={onClose} to="/mediakit" className='chlid-list-item' activeClassName="drawer-nav-selected">Media Kit</NavLink>,
      // <NavLink onClick={onClose} to="/newsletter" className='chlid-list-item mb-2' activeClassName="drawer-nav-selected">Newsletter</NavLink>,
    // <label className='drawer-items-label'>Company</label>,
      // <NavLink onClick={onClose} to="/about" className='chlid-list-item' activeClassName="drawer-nav-selected">About Us</NavLink>,
      // <NavLink onClick={onClose} to="/press" className='chlid-list-item' activeClassName="drawer-nav-selected">Press</NavLink>,
      // <NavLink onClick={onClose} to="/privacy-policy" className='chlid-list-item' activeClassName="drawer-nav-selected">Privacy Policy</NavLink>,
      // <NavLink onClick={onClose} to="/terms-of-service" className='chlid-list-item mb-2' activeClassName="drawer-nav-selected">Terms of Service</NavLink>,
  ]

  return (
    <nav className={drawerClasses}>
      <div className="side-drawer-header">
        <NavLink onClick={onClose} to={'/'}>
          <img src="https://spacedventure-marketing.s3.amazonaws.com/Icon+Stroke+White.png" className='logo' alt='logo' />
        </NavLink>
        <button className="drawer-close-btn" onClick={onClose}>
          <MdClose color="#fff" size="25px" />
        </button>
      </div>
      <div className='entry-buttons'>
        <NavLink onClick={onClose} to="/login" className='login-btn btn-block'>Login</NavLink>
        {/* <NavLink to="/signup" onClick={onClose} className='signup-btn'>Sign up</NavLink> */}
      </div>
      <ul className="mobile-drawer-list">
        {links.map((link, i) => {
          return <li key={i}>{link}</li>
        })}
      </ul>
      {/* <div className="social-icon-box">
        <a href="https://www.facebook.com/SpacedVentures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaFacebookSquare/></a>
        <a href="https://twitter.com/SpacedVentures" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitterSquare/></a>
        <a href="https://www.linkedin.com/company/spaced-ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedin/></a>
        <a href="https://www.instagram.com/spaced.ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagramSquare/></a>
        <a href="https://www.youtube.com/channel/UCw2y-5R3Ywh7Hjy_Anw8wEg" target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutubeSquare/></a>
      </div> */}
    </nav>
  )
}


export default PublicSideDrawer;