import React, { useEffect } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { format, parseJSON } from "date-fns";
import { Helmet } from "react-helmet";
import { defaultUserIcon } from "../../assets/images/index";
import { AuthContext } from "./../../contexts/authContext";
import "./Page.CompanyProfile.scss";
import { CustomScrollSpy } from "../components/shared/CustomScrollSpy";
import CompanySocialLinks from "../components/OfferView/CompanySocialLinks";
import OfferNavMobileDropdown from "../components/shared/OfferNavMobileDropdown";
import { Switch, Route, Redirect } from "react-router-dom";
import CompanySection from "../../investor/components/pitch/sections/CompanySection";
import OfferNavLinkItems from "../components/shared/OfferNavLinkItems";
import LoadingOverlay from "react-loading-overlay";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from "../../components/AmountWithCurrency";
import { useCompany } from "./company/queries";

const CompanyTeamMembersSection = ({ teamMembers }) => {
  return (
    <div className="single-company-team-container">
      {teamMembers?.map((teamMember, index) => (
        <div key={index} className="single-team-member">
          <div className="member-image">
            <img src={teamMember?.image_url ?? defaultUserIcon} alt="img" />
          </div>
          <div className="member-info-box">
            <h5 className="member-name">{teamMember.name}</h5>
            <p className="member-designation">{teamMember.role}</p>
            <div className="member-description">
              <p>{teamMember.bio}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const CompanyFundingSection = ({ fundingRounds }) => {
  return (
    <div className="single-company-funding-container">
      <div className="row">
        {fundingRounds?.map((fundingRound, index) => {
          return (
          <div key={index} className="col-md-6 mb-3">
            <div className="single-funding-box">
              <Link to={`/offers/${fundingRound.slug}`} className="unstyled">
                <p className="funding-label">{fundingRound?.name}</p>
              </Link>
              <h3 className="funding-amount">
                <FormattedNumberWithSymbol number={fundingRound?.deal?.raised} styleClasses={{symbol: 'funding-amount'}} />
                <span>&nbsp;raised</span>
              </h3>
              <div className="funding-badges">
                <CurrencyBadge />
                <span className="badge badge-dark">
                  {fundingRound?.investor_count} Investors
                </span>
                <span className="badge badge-dark">
                  Closed{" "}
                  {format(
                    parseJSON(fundingRound?.deal?.close_date),
                    "MMM dd, yyyy hh:mm a "
                  )}
                </span>
              </div>
            </div>
          </div>
        )})}
      </div>
    </div>
  );
};
const CompanyInfoBadge = ({ badgeTheme = "dark", text }) => {
  const badgeClass = badgeTheme === "dark" ? "badge-dark" : "badge-light";
  return <span className={`badge ${badgeClass}`}>{text}</span>;
};

export default function ShowCompanyPage(props) {
  const { isAdmin } = React.useContext(AuthContext);
  let { offerId, id } = useParams();
  if (!id) id = props.id;
  let { path } = useRouteMatch();
  const companyNavBarItems = [{ label: "OVERVIEW", value: "overview" }];
  const {data: company, isLoading} = useCompany(id)

  let pitchSidebarItems = [];

  if (!company) {
    return null;
  }

  (function buildSidebarItems() {
    if (!company) return [];

    if (company.decription !== null && company.description?.length !== 0) {
      pitchSidebarItems.push({
        id: "about",
        key: "about",
        name: "About",
        visibility: true,
        component: (
          <CompanySection label="about" content={company?.description} />
        ),
      });      
    }


    if (company?.team_members?.length) {
      pitchSidebarItems.push({
        id: "team",
        key: "team",
        name: "Team",
        visibility: true,
        component: (
          <CompanySection
            label="team"
            content={
              <CompanyTeamMembersSection teamMembers={company?.team_members} />
            }
          />
        ),
      });
    }

    if (company?.funding_rounds?.length) {
      pitchSidebarItems.push({
        id: "funding",
        key: "funding",
        name: "Funding",
        visibility: true,
        component: (
          <CompanySection
            label="funding"
            content={
              <CompanyFundingSection fundingRounds={company?.funding_rounds} />
            }
          />
        ),
      });
    }
    return pitchSidebarItems;
  })();

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <div className="single-company-page">
        <Helmet>
          <title>{`${company.name} - SpacedBase by Spaced Ventures`}</title>
          <meta name="description" content={company.tagline} />
        </Helmet>
        <div className="single-conpany-header" id="companyBanner">
          <div className="container single-company-header-container py-4">
            <div className="row">
              <div className="col-lg-6 single-conpany-header-inner1">
                { company?.name && ( <h1 className="company-name">
                  {company?.name}
                  {company.url && <a className="social-icon" target="_blank" rel="noopener noreferrer" href={company?.url}>{<FaExternalLinkAlt />}</a> }
                </h1>)}
               
                { company.tagline && <h3 className="company-tagline">{company.tagline}</h3>}
                <div className="company-info-badges">
                 { company?.parent_sector_name && (<CompanyInfoBadge
                    badgeTheme="light"
                    text={company?.parent_sector_name}
                  />)}
                  { company?.sector_name && (<CompanyInfoBadge
                    badgeTheme="light"
                    text={company?.sector_name}
                  />)}
                  {company?.founding_year && (
                    <CompanyInfoBadge
                      badgeTheme="dark"
                      text={`Founded ${company?.founding_year}`}
                    />
                  )}
                  {company?.employee_count !== null && (
                    <CompanyInfoBadge
                      badgeTheme="dark"
                      text={`${company?.employee_count} Employees`}
                    />
                  )}
                  {company?.city && company?.country && (
                    <CompanyInfoBadge
                      badgeTheme="dark"
                      text={`${company?.city}, ${company?.country}`}
                    />
                  ) }
                  { company?.followers_count !== null && (
                    <CompanyInfoBadge
                      badgeTheme="dark"
                      text={`${company?.followers_count} ${company?.followers_count == 1 ? 'Follower' : 'Followers'}`}
                    />
                  )}
                  { company?.private !== null && (
                    <CompanyInfoBadge
                      badgeTheme="dark"
                      text={`${company?.private ? 'Private Company' : 'Public Company'}`}
                    />
                  )}
                </div>
                <div className="w-100 single-company-social-icons mt-2 mt-lg-5">
                  <CompanySocialLinks company={company} />
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0 single-conpany-header-inner2">
                {company?.banner_url && (
                  <img
                    src={company?.banner_url}
                    className="company-image"
                    alt="company img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="offer-navbar" id="offerNavBar" style={{backgroundColor: "#111"}}>
          <div className="container">
            <div className="tab-buttons">
              <OfferNavLinkItems navBarItems={companyNavBarItems} />
            </div>
          </div>
        </div>
        <Switch>
          <Route
            path={`${path}/overview`}
            component={() => (
              <>
                <OfferNavMobileDropdown
                  navBarItems={companyNavBarItems}
                  currentTab={"OVERVIEW"}
                />
                <section className="main-content">
                  <CustomScrollSpy pitchSidebarItems={pitchSidebarItems} bannerId="companyBanner" navId="offerNavBar"/>
                </section>
              </>
            )}
          />
          <Redirect from={`${path}`} to={`${path}/overview`} />
        </Switch>
      </div>
    </LoadingOverlay>
  );
}
