import React from 'react';
import styled from 'styled-components';

import SearchIcon from './../../../src/assets/images/icons/ic-search.svg';
import EditIcon from './../../../src/assets/images/icons/ic-edit.svg';
import AddIcon from './../../../src/assets/images/icons/ic-add.svg';
import DeleteIcon from './../../../src/assets/images/icons/ic-delete.svg';
import FileUpload from './../../../src/assets/images/icons/ic-file-upload.svg';
import FormatBold from './../../../src/assets/images/icons/ic-format-bold.svg';
import FormatItalic from './../../../src/assets/images/icons/ic-format-italic.svg';
import EmbedVideo from './../../../src/assets/images/icons/ic-youtube.png';
import EmbedImage from './../../../src/assets/images/icons/ic-embed-image.svg';
import EmbedLink from './../../../src/assets/images/icons/ic-embed-link.svg';
import listUl from './../../../src/assets/images/icons/list-ul.svg';
import listOl from './../../../src/assets/images/icons/list-ol.svg';

const Icons = {
  search: SearchIcon,
  edit: EditIcon,
  add: AddIcon,
  delete: DeleteIcon,
  fileUpload: FileUpload,
  formatBold: FormatBold,
  formatItalic: FormatItalic,
  embedVideo: EmbedVideo,
  embedImage: EmbedImage,
  embedLink: EmbedLink,
  listUl:listUl,
  listOl:listOl
}
const iconFor = iconName => Icons[iconName]

export default function Icon(props) {
  return <Tid {...props} src={iconFor(props.name)}/>
}

const Tid = styled.img`
  height: 38px;
  padding: 8px;
  border: "1px solid black";
  filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(345deg) brightness(103%) contrast(103%);
  background: ${props => props.withBackground ? "black" : "none"};
  cursor: pointer;
`