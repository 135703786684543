import React, { useState,useEffect } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { VariableSizeList as List } from "react-window";
import { asyncMap } from "@wojtekmaj/async-array-utils";

const width = window.innerWidth > 1024 ? 950 : window.innerWidth > 576 ? window.innerWidth*0.90 : (window.innerWidth - 32);
const height =  window.innerWidth > 1024 ? window.innerHeight - 100 : window.innerHeight;

function Row({ index, isScrolling, style }) {
  return (
    <div style={style}>
      {isScrolling ? <div className='text-center text-dark p-5'>Loading...</div> :
        <Page
          pageIndex={index}
          width={width}
        />
      }
    </div>
  );
}

export default function PdfViewer({ url, name, zoom = 1 }) {
  const [pdf, setPdf] = useState(null);
  const [pageViewports, setPageViewports] = useState(null);

  /**
   * React-Window cannot get item size using async getter, therefore we need to
   * calculate them ahead of time.
   */
  useEffect(() => {
    setPageViewports(null);

    if (!pdf) {
      return;
    }

    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) =>
        pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 }))
      );

      setPageViewports(nextPageViewports);
    })();
  }, [pdf]);

  function onDocumentLoadSuccess(nextPdf) {
    setPdf(nextPdf);
  }

  function getPageHeight(pageIndex) {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = width / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight;
  }

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<div className='text-center text-dark p-5'>Loading... Please wait</div>}
    >
      {pdf && pageViewports ? (
        <List
          useIsScrolling
          width={width}
          height={height}
          estimatedItemSize={height}
          itemCount={pdf.numPages}
          itemSize={getPageHeight}
        >
          {Row}
        </List >
      ) : null}
    </Document>
  );
}
