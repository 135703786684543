import ReactPixel from 'react-facebook-pixel';
const restrictFB =
  process.env.REACT_APP_ENVIRONMENT == 'development' ||
  process.env.REACT_APP_ENVIRONMENT == 'next' ||
  process.env.REACT_APP_ENVIRONMENT == 'sandbox' ||
  process.env.REACT_APP_ENVIRONMENT == 'staging';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

export const initFB = () => {
  ReactPixel.init('925691841632410', options);
}

export const fbEvent = (event, data) => {
  if (restrictFB) return console.log("development mode FB event");
  ReactPixel.track(event, data);
}

export const fbStandardEvent = (event) => {
  if (restrictFB) return console.log("development mode FB event");
  ReactPixel.track(event);
}

export const PageViewFB = () => {
  ReactPixel.pageView();
}

export const FBEvent = (event, data) => {
  ReactPixel.track(event, data);
}