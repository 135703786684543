import React from "react";
import {
  ResponsiveTable,
  CompanyAccountInviteForm,
  TableActionsContainer,
  TableActionItem,
  Paginator,
  OverviewSubheader,
  TextButton,
} from "admin/components";
import { FaPen, FaTrash } from 'react-icons/fa';
import { useMutation } from 'react-query';
import ToastMessage from 'components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer'
import { useCompanyManagersList, deleteCompanyManager } from "./../queries/index";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import usePagination from "hooks/usePagination";
import {toTitlecase} from "jsUtils/stringUtils";
import CompanyAccountRoleEditForm from "./CompanyAccountRoleEditForm";

function CompanyAccountsList({companyId}) {
  const pageConfig = usePagination();
  const accountList = useCompanyManagersList({company_id: companyId, ...pageConfig.current});
  const inviteManagerModal = React.useContext(PortalizedModalContext);
  const roleEditModal = React.useContext(PortalizedModalContext);
  const deleteConfirmModal = React.useContext(PortalizedModalContext);

  const deleteIssuerMutation = useMutation( deleteCompanyManager, {
    onSuccess: (values) => {
      accountList.refetch();
      ToastMessage.success(`Issuer role deleted successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      deleteConfirmModal.closeModal();
    }
  })

  const handleEdit = (role) => () => {
    roleEditModal.setModalContent(
      `Edit Role for ${toTitlecase(role.user.name)}`,
      <CompanyAccountRoleEditForm
        company_id={companyId}
        role={role}
        onSettle={() => {
          roleEditModal.closeModal()
          accountList.refetch();
        }} />,
      'sm'
    );
    roleEditModal.openModal();
  }

  const handleDelete = (role) => () => {
    deleteConfirmModal.setModalContent(
      "Delete Issuer Role?",
      <>
        <p>{`Are you sure you want to delete the issuer role for- ${toTitlecase(role.user.name)}?`}</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteIssuerMutation.mutate({company_id: companyId, role_id: role.id})
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteConfirmModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (accountList.isSuccess)
        return accountList.data.data
      else
        return []
    },
    [accountList]
  );


  const columns = React.useMemo(
    () => {
      if (accountList.isSuccess){
        return [
          {
            Header: 'User name',
            accessor: 'user.name',
            Cell: ({value}) => toTitlecase(value)
          },{
            Header: 'Email',
            accessor: 'user.email'
          },
          {
            Header: 'Role',
            accessor: 'name'
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ row, value }) => (
              <TableActionsContainer>
                <TableActionItem
                  tooltipText="Edit"
                  onClick={handleEdit(row.original)}
                >
                  <FaPen />
                </TableActionItem>

                <TableActionItem
                  tooltipText="Delete"
                  onClick={handleDelete(row.original)}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            )
          }
        ]
      }
      else{
        return []
      }
    },
    [accountList]
  );

  const inviteManager = () => {
    inviteManagerModal.setModalContent(
      'Add admin account',
      <CompanyAccountInviteForm companyId={companyId} onInviteSuccess={inviteManagerModal.closeModal} />,
      'sm'
    );
    inviteManagerModal.openModal();
  }

  return (
    <>
      <OverviewSubheader left='Manager accounts' right={<TextButton title='Add new manager' onClick={inviteManager} />} />
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={accountList?.data?.pagy}
        loading={accountList.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={accountList.isLoading}
        loadingMsg="Loading accounts..."
        noDataMsg="No accounts found."
      />
    </>
  )
}

export default CompanyAccountsList;
