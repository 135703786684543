import React, { useEffect, useState } from 'react'
import navDarkLogo from '../../../assets/images/logos/Spaced-logo-full-black.svg';
import navWhiteLogo from '../../../assets/images/logos/Spaced-logo-full-white.svg';
import { NavLink, Link } from 'react-router-dom';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';
import { logoutUser } from 'redux/session'
import { useLocation } from 'react-router-dom'
import { FaUserAstronaut } from "react-icons/fa";
import AuthProvider, { AuthContext } from "contexts/authContext";
import PromotedEventsBanner from '../../../components/PromotedEventsBanner';

const InvestorAppToolbar = ({ drawerClickHandler }) => {
  const location = useLocation();
  const { authData, clearAuthContext } = React.useContext(AuthContext);
  const currentUser = authData?.currentUser
  const hasIssuerRole = currentUser?.roles.findIndex(role => role.category === "issuer") != -1
  const [hasPromotedEvents, setHasPromotedEvents] = useState(false)
  const [stickyToolbar, setStickyToolbar] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const pathName = location?.pathname || '';
  const noFixedToolbar = (pathName.match(/\/offerings\/(:\s*)?/g) || pathName.match(/\/offers\/(:\s*)?/g) ||pathName.match(/\/prelaunch\/(:\s*)?/g) || pathName.match(/\/company\/(:\s*)?/g)) ? 'no-fixed-header' : ''

  useEffect(() => {
    checkNavVisibility();
    if (checkStickyNavPages()) {
      window.removeEventListener('scroll', scrollEventListener)
      setStickyToolbar(true);
    } else {
      window.addEventListener('scroll', scrollEventListener);
      setStickyToolbar(false);
    }
    return () => { window.removeEventListener('scroll', scrollEventListener); }
  }, [pathName]);

  const scrollEventListener = () => {
    const pageScroll = window.pageYOffset;
    if (pageScroll > 0) {
      setStickyToolbar(true);
    } else {
      if (!checkStickyNavPages()) {
        setStickyToolbar(false);
      }
    }
  }

  const checkStickyNavPages = () => {
    if (pathName.match(/\/offers/g) ||
      pathName.match(/\/offerings\/(:\s*)?/g) ||
      pathName.match(/\/investor-details\/offers\/(:\s*)?/g) ||
      pathName.match(/\/spacedbase/g) ||
      pathName.match(/\/mediakit/g) ||
      pathName.match(/\/press/g) ||
      pathName.match(/\/offers\/(:\s*)?\/invest\/accounts\/?[1-9]\d*$/g) ||
      pathName.match(/\/offers\/(:\s*)?\/accounts\/?[1-9]\d*\/ach-payments$/g) ||
      pathName.match(/\/offers\/(:\s*)?\/accounts\/?[1-9]\d*\/limit-calculator$/g) ||
      pathName.match(/\/offers\/(:\s*)?\/subscription-agreement\/?[1-9]\d*$/g) ||
      pathName.match(/\/prelaunch\/(:\s*)?/g) ||
      pathName.match(/\/raise-petitions\/(:\s*)?/g) ||
      pathName.match(/\/account\/(:\s*)?/g) ||
      pathName.match(/\/resources/g) ||
      pathName.match(/\/company\/(:\s*)?/g))
    {
      return true;
    }
    else {
      return false;
    }
  }

  const checkNavVisibility = () => {
    if (pathName === '/email-verification') {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }

  const logoutThisUser = () => {
    logoutUser();
    clearAuthContext();
  }

  return (
    showNavbar && (
      <header id="app-navbar" className={`${stickyToolbar ? 'scrolled' : ''} ${noFixedToolbar} toolbar ${hasPromotedEvents ? 'promoted-event-header' : ''}`}>
        <PromotedEventsBanner setHasPromotedEvents={setHasPromotedEvents} />
        <nav className="toolbar-navigation">
          <div className="toolbar-toggle-button">
            <DrawerToggleButton drawerClickHandler={drawerClickHandler} />
          </div>
          <div className="toolbar-logo">
            <Link to="/offerings"><img className="nav-logo" src={navDarkLogo} alt="Spaced Ventures Logo" /><div className='nav-logo fs-10 pl-1'>now Mach33</div></Link>
            <Link to="/offerings"><img className="mobile-nav-logo" src={navWhiteLogo} alt="Spaced Ventures Logo" /></Link>
          </div>
          <div className="spacer" />
          <div className='toolbar-navigation-items investor-toolbar-navigation-items'>
            <ul className="tile-list">
              <li><NavLink exact to="/offerings" activeClassName="nav-selected">Deals</NavLink></li>
              <li><NavLink exact to="/resources" activeClassName="nav-selected">Resources</NavLink></li>
              <li><a target="_blank" href="https://www.33fg.com/" className='chlid-list-item mb-2' rel="noopener noreferrer">Mach33</a></li>
              {/* <li><NavLink exact to="/spacedbase" activeClassName="nav-selected">SpacedBase</NavLink></li> */}
              {/* <li><a target="_blank" href="https://spacedventures.zendesk.com/hc/en-us" rel="noopener noreferrer">FAQ</a></li> */}
              {
                hasIssuerRole &&
                <li><a target="_blank" href={process.env.REACT_APP_ISSUER_APP_URL} rel="noopener noreferrer">Company</a></li>
              }
              <li className="separate-nav-item">
                <Link to='/account/settings'><FaUserAstronaut /></Link>
                <ul className="tile-list-dropdown tile-list-dropdown-right">
                  <li><Link to="/account/investments">My Investments</Link></li>
                  <li><Link to="/account/private-invites">Invites</Link></li>
                  <li><Link to='/account/settings'>Settings</Link></li>
                  <li><Link to='/account/investor-info'>Investor Info</Link></li>
                  <li><NavLink to="/support" activeClassName="nav-selected">Support</NavLink></li>
                  <li><Link onClick={logoutThisUser} to="#!">Logout</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    )
  )
};

export default InvestorAppToolbar;
