import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import RichEditor from '../RichEditor';
import { createEditorStateWithContent, createEditorSaveContent } from 'admin/components/RichEditor/editorUtils';
import Icon from './../Icon';
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import { useOfferPitchCustomers } from '../../pages/offer/queries';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import {
  addCustomer,
  updateCustomer,
  deleteCustomer
} from '../../pages/offer/queries';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

export default function CustomerForm({
  pitch, updateMethod
}) {
  const [reinitialize, setReinitialize] = useState(true);

  return (
    <div id="customerForm">
      <div className="col-xs-12 row">
        <AddCustomerArticle pitch={pitch} updateMethod={updateMethod} reinitialize={reinitialize} setReinitialize={setReinitialize} />
        <AddCustomer pitchable_id={pitch.pitchable_id} reinitialize={reinitialize} setReinitialize={setReinitialize} />
      </div>
    </div>
  );
}

const CustomerArticleSchema = Yup.object().shape({
  content: Yup.object()
    .test(
      'has text',
      'Article content is required',
      value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
    ),
});

const AddCustomerArticle = ({
  pitch:{customers_section={}, pitchable_id}, updateMethod, reinitialize, setReinitialize
}) => {
  const { preofferSlug } = useParams()

  if(customers_section === null) customers_section = {};

  const queryClient = useQueryClient();
  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['preoffer#pitch', preofferSlug],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  function onSubmit() {
    setReinitialize(true);
  }

  return (
    <div className="col-lg-7">
      <Formik
        initialValues={{
          id: customers_section.id,
          content: createEditorStateWithContent(customers_section.content)
        }}
        validationSchema={CustomerArticleSchema}
        enableReinitialize={reinitialize}
        onSubmit={async values => {
          const { id, content } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                customers_section_attributes: {
                  id,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="form-group">
              <label>Article content</label>
              <div className="richEditorContainerForPitchSections">
                <RichEditor
                  className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                  editorState={values.content}
                  onChange={(value) => setFieldValue("content", value)}
                  onBlur={() => setFieldTouched("content")}
                />
              </div>
              <ErrorMessage name="content" component="div" className="invalid-feedback text-left" />
            </div>
            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>
            <DeleteSectionButton onSubmit={onSubmit} sectionId={customers_section.id} sectionType="customers" />
            <LastUpdatedAt updatedAt={customers_section.updated_at}/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const CustomerView = (props) => {
  const { customer } = props
  return (
    <div className="customer-view">
      <img src={`${customer.logo_url}`} alt="" className="logo" />
      <div className="customer-summary">
        <div className="customer-name">{customer.name}</div>
        <div className="customer-status">{customer.status?.replace(/_/g, ' ')}</div>
      </div>
      <div className="actions pr-2">
        <div className="edit" onClick={props.onEdit}></div>
        <div className="remove" onClick={props.onRemove}></div>
      </div>
    </div>
  )
}

const TextButton = (props) => {
  return (
    <button type="button"
      className="btn btn-outline-warning zeitung-mono weight-300 rounded-0 mt-3"
      onClick={props.onClick}
      >
      {props.text}
    </button>
  )
}

const CustomerSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  status: Yup.string().required('Status is required'),
  logo: Yup.mixed().required('Logo is required')
})

const AddCustomer = ({
  pitchable_id, setReinitialize
}) => {
  const queryClient = useQueryClient();
  
  const {data:customers=[], isLoading, isIdle} = useOfferPitchCustomers(pitchable_id)

  const fileReader = new FileReader();
  const customerFormBlankState = { name: '', status: '', logo: '' }
  const [customerforEdit, setCustomerForEdit] = React.useState(customerFormBlankState)
  const [file, setFile] = React.useState(null);
  CustomerSchema.fields.logo = customerforEdit.logo_url ? Yup.string() : Yup.mixed().required('Logo is required')

  const addCustomerMutation = useMutation(
    addCustomer,
    {
      onSuccess: () => {
        ToastMessage.success('Customer added.')
        queryClient.invalidateQueries(
          ['admin', 'pitchable', pitchable_id, 'customers'],
          {
            exact: true
          }
        )
        setCustomerForEdit(customerFormBlankState);
      },
      onError: (error) => {
        ToastMessage.error('Customer add failed.')
        renderRailsErrors(error)
      },
    }
  )

  const updateCustomerMutation = useMutation(
    updateCustomer,
    {
      onSuccess: () => {
        ToastMessage.success('Customer updated.')
        queryClient.invalidateQueries(
          ['admin', 'pitchable', pitchable_id, 'customers'],
          {
            exact: true
          }
        )
        setCustomerForEdit(customerFormBlankState);
      },
      onError: (error) => {
        ToastMessage.error('Customer update failed.')
        renderRailsErrors(error)
      },
    }
  )

  const deleteCustomerMutation = useMutation(
    deleteCustomer,
    {
      onSuccess: () => {
        ToastMessage.success('Customer deleted.')
        queryClient.invalidateQueries(
          ['admin', 'pitchable', pitchable_id, 'customers'],
          {
            exact: true
          }
        )
        setCustomerForEdit(customerFormBlankState);
        setFile(null);
      },
      onError: (error) => {
        ToastMessage.error('Customer delete failed.')
        renderRailsErrors(error)
      },
    }
  )

  if (isLoading || isIdle)
    return 'Loading...'

  return (
    <LoadingOverlay
      active={addCustomerMutation.isLoading || updateCustomerMutation.isLoading ||deleteCustomerMutation.isLoading}
      spinner
      text="Loading"
      className="col-lg-5"
    >
      <Formik
        enableReinitialize
        initialValues={customerforEdit}
        validationSchema={CustomerSchema}

        onSubmit={async (values, { resetForm }) => {
          if (values.id) {
            updateCustomerMutation.mutate({
              customer_id: values.id,
              values: {
                customer: values
              }
            })
          }
          else {
            addCustomerMutation.mutate({
              offer_id: pitchable_id,
              values: {
                customer: values
              }
            })
          }
          resetForm({});
          setFile(null);
        }}
      >
        {({ values, setFieldTouched, setFieldValue, handleReset, isSubmitting }) => (
          <Form className="customer-form">
            <label>Add Customer</label>
            <div className="form-group">
              <div className="form-label-group">
                <Field
                  id="customer-name"
                  name="name"
                  className="form-control"
                  placeholder="Customer name"
                />
                <label htmlFor="customer-name">Customer name</label>
                <ErrorMessage name="name" component="div" className="invalid-feedback text-left d-block" />
              </div>
            </div>
            <div className="d-flex flex-wrap cs-box">
              <div className="form-label-group cs-dropdown pr-3">
                <Field
                  id="status"
                  name="status"
                  className="form-control customer-status"
                  component="select"
                  placeholder="Status"
                >
                  <option value="">Select Status</option>
                  <option value="discovery">Discovery</option>
                  <option value="letter_of_intent">Letter of Intent</option>
                  <option value="pilot">Pilot</option>
                  <option value="sales">Sales</option>
                  <option value="recurring">Recurring</option>
                  <option value="government_contract">Government Contract</option>
                </Field>
                <label htmlFor="status">Status</label>
                <ErrorMessage name="status" component="div" className="invalid-feedback text-left d-block" />
              </div>
              <div className="form-group cs-logo">
                <label htmlFor="image" className="mb-0">
                  <div className="text-center align-middle" style={{
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundColor: '#F4F4F4',
                    border: '1px solid #DDDDDD',
                    display: "table-cell",
                    height: "48px",
                    width: "100px",
                    backgroundSize: "cover",
                    backgroundImage: `url( ${values.logo?.data || values.logo_url})`
                  }}>
                    <div style={{
                      backgroundColor: "#F4F4F4",
                      padding: "0px",
                      fontSize: "13px",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%'
                    }}>
                      <Icon name="fileUpload" />
                      <div>
                        logo
                      </div>
                    </div>
                  </div>
                </label>
                <input type="file"
                  id="image"
                  name="logo"
                  className="form-control"
                  onChange={
                    (event) => {
                      setFieldTouched("logo", true)
                      const file = event.currentTarget.files[0]
                      if (!file) return
                      fileReader.readAsDataURL(event.currentTarget.files[0])
                      fileReader.onload = function () {
                        setFieldValue("logo", { data: fileReader.result })
                        setReinitialize(false);
                        setFile(URL.createObjectURL(file))
                      };
                      fileReader.onerror = function (error) {
                        console.log('logo file Error: ', error);
                      };
                    }
                  }
                />
                <ErrorMessage name="logo" component="div" className="invalid-feedback text-left d-block" />
              </div>
              {file && <div className="customer-view">
                <img src={file} className="logo" />
              </div>}
            </div>
            <div className="form-group">
              <button type="submit" className="btn-black btn-block poppins weight-300 rounded-0 py-3 text-uppercase" disabled={isSubmitting}>
                {values.id ? 'Update Customer' : 'Add Customer'}
              </button>
              {(values.name || values.status || values.logo) &&
                <TextButton text={'clear form'} onClick={() => {
                  setReinitialize(false);
                  setCustomerForEdit(customerFormBlankState);
                  handleReset();
                  setFile(null)
                }} />
              }
            </div>
            <FormikPromptIfDirty />
            <div className="customers-list">
              {customers && customers.map((c) =>
                <CustomerView
                  key={c.id}
                  customer={c}
                  onEdit={() => { setCustomerForEdit(c); setFile(c.logo_url) }}
                  onRemove={() => {
                    setReinitialize(false)
                    if (window.confirm(`Are sure you want to delete customer - ${c.name}`)) {
                      deleteCustomerMutation.mutate({
                        customer_id: c.id,
                      })
                    }
                  }}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </LoadingOverlay>
  )
}
