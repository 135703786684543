import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from 'redux/user';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
});

export default function ForgotPasswordForm() {

  return (
    <div id="loginForm" className="container m-width-medium mt-30 mb-30">
      <h1 className="weight-900 fs-3 text-uppercase">Forgot Password</h1>
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={async (values, { setSubmitting }) => {
          forgotPassword(values)
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form className="py-3 px-0 px-md-5">
            <div className="form-label-group">
              <Field type="email"
                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                name="email"
                id="email"
                placeholder="Email"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="email">Email</label>
              <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
            </div>
            <button type="submit" id="loginBtn" className="mx-auto poppins btn btn-block btn-dark" disabled={isSubmitting}>
              Send Email
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}