import React, { useState, useEffect } from 'react';
import { Formik, Field, useField, ErrorMessage } from "formik";
import { runGAEvent } from "../components/Tracking/index";
import { MdCheck, MdClose } from "react-icons/md";
import { PageViewFB, initFB, fbEvent } from '../components/Tracking/facebook';
import ToastMessage from '../../components/ToastMessage'
let SENDGRID_LIST_EARLY_ACCESS = "e8413bb2-5af5-42a6-af63-bcb2f1996b2b";
let SENDGRID_LIST_ACCREDITED = "a831ab43-7654-4c21-841d-047dc59f7a60";
let SENDGRID_LIST = SENDGRID_LIST_EARLY_ACCESS;
const URL = 'https://api.spacedventures.com/sendgrid/contacts';

export default function SendGrid({ toggleModal, accreditedSignup = false }) {
  useEffect(() => {
    initFB();   
  }, [])  

  if (accreditedSignup) {
    SENDGRID_LIST = SENDGRID_LIST_ACCREDITED;
  } else {
    SENDGRID_LIST = SENDGRID_LIST_EARLY_ACCESS;
  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }  
  return (
 
    <div>
        <Formik 
          initialValues={{firstName: "", lastName: "", email:""}} 
          validate={values => {
            const errors = {};

            var letters = /^[A-Za-z]+$/;

            if (values.firstName && !values.firstName.match(letters)) {
              errors.firstName = "Only alphabet characters";
            }

            if (values.lastName && !values.lastName.match(letters)) {
              errors.lastName = "Only alphabet characters";
            }
            if (values.email && validateEmail(values.email) == false) {
              errors.email = "Incorrect email format";
            }            

            return errors;
          }}
          onSubmit={(data, {setSubmitting, resetForm, setStatus, status}) => {
          setSubmitting(true);
          // make async call
          const response = {
            success: ""
          };

            let body = JSON.stringify({
              list_ids: [SENDGRID_LIST],
              contacts: [
                {
                  email: data.email,
                  first_name: data.firstName,
                  last_name: data.lastName
                }
              ]
            });
            fetch(URL, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body,
            }).then((res) => res.json())
              .then((res) => {
                if (!res.job_id) {
                  toggleModal();
                  ToastMessage.error("Something went wrong. Please try again.")
                } else if (res.job_id) {
                  runGAEvent("newsletter signup", "signup form submission", "success")
                  fbEvent('CompleteRegistration')
                  ToastMessage.success("Successfully Subscribed! Expect a welcome email within the next 5 minutes.")
                  resetForm();
                  toggleModal();
                }
              })    
          setSubmitting(false);    
        }}
        >
        {( { values, errors, status, isSubmitting, touched, handleChange, handleBlur, handleSubmit } ) => (
          <form onSubmit={handleSubmit}>
            <div className="send-grid-form-layout ">
            <div className="px-4">
                <div className="form-label-group">
                  <Field required 
                    name="firstName" 
                    id="firstName"
                    value={values.firstName} 
                    placeholder="First Name" 
                    className="zeitung form-control modal-input" />
                  <label htmlFor="firstName">First Name</label>
                <div className="error-container-sendgrid">
                  <ErrorMessage name="firstName" component="div" className="error-feedback-sendgrid"  />
                </div>
              </div>
  
                <div className="form-label-group">
                  <Field required 
                    name="lastName" 
                    id="lastName"
                    value={values.lastName} 
                    placeholder="Last Name" 
                    className="form-control modal-input zeitung" />
                  <label htmlFor="lastName">Last Name</label>
                  <div className="error-container-sendgrid">
                    <ErrorMessage name="lastName" component="div" className="error-feedback-sendgrid" />
                  </div>
              </div>
              <div className="form-label-group">        
                <Field required 
                    type="text" 
                    id="email"
                    name="email" 
                    value={values.email} 
                    placeholder="Email" 
                    className="form-control  zeitung" />
                  <label htmlFor="email">Email</label>
                <div className="error-container-sendgrid">
                  <ErrorMessage name="email" component="div" className="error-feedback-sendgrid" />
                </div>        
              </div>
            </div>
            
            <div className="py-3 px-4 d-flex justify-content-end modal-foot align-items-center"> 
                <div>
                  <button className="btn-black-modal ml-1" disabled={isSubmitting} type="submit">Submit</button>
                </div>
            </div>
          </div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre> */}      
          </form>
        )}

        </Formik>
    </div>
  )
}