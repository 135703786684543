import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import ErrorMessage from './ErrorMessage';
import { useStableUniqueId } from 'react-stable-uniqueid';

const CurrencyInputField= ({name, placeholder, classes = "", inputClasses = ""}) => {
  const { control} = useFormContext()
  const uniqueId = useStableUniqueId('formatted-number');

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="mb-3">
          <div className="input-group" style={{flexWrap: 'nowrap'}}>
            <div className="input-group-prepend" style={{height: '48px'}}>
              <span className="input-group-text input-group-text-before">$</span>
            </div>
            <div className={`form-label-group mb-0 ${classes}`}>
              <NumberFormat
                id={`${field.name}-${uniqueId}`}
                name={field.name}
                onBlur={field.onBlur}
                value={field.value}
                thousandSeparator={true}
                className={`form-control ${inputClasses} ${(isTouched || invalid) && error ? "is-invalid" : ""}`}
                placeholder={placeholder}
                decimalScale={2}
                onValueChange={(valuesObj) => {
                  const {floatValue} = valuesObj;
                  field.onChange(floatValue)
                }}
              />
              <label htmlFor={`${field.name}-${uniqueId}`}>{placeholder}</label>
              <ErrorMessage error={error}/>
            </div>
          </div>
        </div>
      )}
    />
  )
}

CurrencyInputField.defaultProps = {};

CurrencyInputField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

export default CurrencyInputField;