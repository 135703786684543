import React from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { updatePreofferEsignTemplate } from "./queries";
import ToastMessage from "components/ToastMessage";

export default function InvestmentInterestEsignTemplate({ preoffer }) {
  const queryClient = useQueryClient();
  const schema = Yup.object().shape({
    esign_template_id: Yup.string().required("Docusign template id is required."),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      esign_template_id: '',
    },
  });

  const mutation = useMutation(updatePreofferEsignTemplate, {
    onSuccess: (data) => {
      methods.reset();
      preoffer.esign_template_id = data.esign_template_id
      queryClient.setQueryData(['preoffer', preoffer.slug], preoffer);
      ToastMessage.success('Preoffer interest esign template successfully saved.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  });

  const onSubmit = async (values) => {
    mutation.mutate({
      preoffer_id: preoffer.slug,
      values: values
    })
  };

  if (!preoffer) return null;

  return (
    <div className='col-md-4 col-12'>
       <div className="mb-1">
        <label className="form-input-label fs-9">Investment interest agreement Docusign template</label>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TextField name="esign_template_id" placeholder="DocuSign Esign Template ID" type="text" />
          <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={mutation.isLoading}>
            {mutation.isLoading ? 'Saving...' : 'Save'}
          </button>
        </form>
      </FormProvider>
      <EsignTemplate preoffer={preoffer}/>
    </div>
  );
}

function EsignTemplate({ preoffer }) {
  if (!preoffer.esign_template_id) {
    return null;
  }

  return (
    <div className='mt-3'>
      <div className="file-bar2 mt-1">
        <div className="d-flex align-items-center docs-file-link poppins py-1">
          <span className='weight-600 mr-1'>Docusign Template Id:</span>
          <span className='poppins'>{preoffer.esign_template_id}</span>
        </div>
      </div>
    </div>
  );
}

