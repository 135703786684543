import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PageTitleBanner from '../components/PageTitleBanner';
import PlatformEvent from '../components/PlatformEvent';
import { PageViewFB } from './components/Tracking/facebook';

export default function PlatformEvents() {
  useEffect(() => {
    PageViewFB();
  }, [])

  const { eventId } = useParams();

  return (
    <div className="platform-events">
      <Helmet>
        <title>Spaced Ventures Live Events</title>
        <meta name='description' content='Spaced Ventures Live Events' />
      </Helmet>
      <PageTitleBanner height="225px" title={'Resources'} />
      <PlatformEvent.LiveEvent eventId={eventId}/>
      <PlatformEvent.List/>
    </div>
  )
}


