import React, {useEffect, useState} from "react";
import formatNumber from 'accounting-js/lib/formatNumber.js';
import { usePusherChannel } from '../../contexts/PusherChannelContext';
import { liveEventApi } from "../../api/liveEvent";
import { platformLiveEventApi } from '../../api/platformLiveEvent';

export default function LiveViewerCount({ liveEvent }) {
  const pusherChannel = usePusherChannel(liveEvent.channel_id);
  const liveEventId = liveEvent.slug || liveEvent.id;
  const [viewerCount, setViewerCount] = useState(liveEvent.viewer_count);
  const eventApi = liveEvent.type === 'LiveEvent' ? liveEventApi : platformLiveEventApi;

  useEffect(() => {
    // Fetch viewers count on interval of 60 to 120 seconds.
    const viewerCountInterval = setInterval(() => {
      eventApi.viewerCount(liveEventId).then((data) => {
        if(data && data.viewer_count){
          setViewerCount(data.viewer_count);
        }
      })
    }, Math.floor(Math.random() * (180000 - 120000)) + 120000);

    pusherChannel.bind('viewer-count', (data) => {
      setViewerCount(data.count);
    });

    return () => {
      clearInterval(viewerCountInterval);
      pusherChannel.unbind('viewer-count', () => {});
    }
  }, [liveEventId]);

  if(viewerCount < 20 || liveEvent.status !== 'live'){
    return null;
  }

  const value = formatNumber(viewerCount);

  if(liveEvent.type === 'LiveEvent'){
    return(
      <>
        <span className='weight-700 align-middle mr-1'>{value}</span>
        <span className="align-middle">Watching</span>
      </>
    )
  }

  return (
    <span className='color-white poppins'>{value} watching now</span>
  )
}