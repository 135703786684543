import React from 'react';
import OverviewSubheader from '../OverviewSubheader';
import AttributeValue from '../AttributeValue';
import TwoColumnLayout from '../TwoColumnLayout';
import AmountWithCurrency from '../../../components/AmountWithCurrency';
import { toTitlecase } from '../../../jsUtils/stringUtils';
import { formatDate } from '../../../jsUtils/dateTimeUtils';
import { useAdminInvestmentLimit } from '../../../investor/queries';

const MemberlimitTable = ({ investor, calcuatedLimit }) => {
  const investmentLimit = investor.investment_limit || {};

  return (
    <>
      <OverviewSubheader left='Financial Details / Limit Details' />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Yearly income' value={<AmountWithCurrency number={investmentLimit.annual_income} />} />
            <AttributeValue name='Net worth' value={<AmountWithCurrency number={investmentLimit.net_worth} />} />
            <AttributeValue name='Accreditation' value={investmentLimit.accredited ? 'Yes' : 'No'} />
            <AttributeValue name='Total limit' value={investmentLimit.accredited ? 'No Limit' : <AmountWithCurrency number={calcuatedLimit.max_investment} />} />
            <AttributeValue name='Created at' value={formatDate(investor.investment_terms?.created_at) || '-'} />
            <AttributeValue name='Updated at' value={formatDate(investor.investment_terms?.updated_at) || '-'} />
          </>
        }
        right={
          <>
            <AttributeValue name='Job title' value={toTitlecase(investmentLimit.job_title)} />
            <AttributeValue name='Job industry' value={toTitlecase(investmentLimit.job_industry)} />
            <AttributeValue name='12 month crowdfunding history' value={<AmountWithCurrency number={investmentLimit.outside_investment} />} />
            <AttributeValue name='Invested on Spaced Ventures' value={<AmountWithCurrency number={calcuatedLimit.current_investment} />} />
            <AttributeValue name='Outside investment amount' value={<AmountWithCurrency number={investmentLimit.outside_investment_amount} />} />
          </>
        }
      />
    </>
  )
}

const LimitTable = ({ investor, calcuatedLimit }) => {
  const investmentLimit = investor.investment_limit || {};

  return (
    <>
      <OverviewSubheader left='Financial Details / Limit Details' />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Yearly income' value={<AmountWithCurrency number={investmentLimit.annual_income} />} />
            <AttributeValue name='Net worth' value={<AmountWithCurrency number={investmentLimit.net_worth} />} />
            <AttributeValue name='Accreditation' value={investmentLimit.accredited ? 'Yes' : 'No'} />
            <AttributeValue name='Total limit' value={investmentLimit.accredited ? 'No Limit' : <AmountWithCurrency number={calcuatedLimit.max_investment} />} />
          </>
        }
        right={
          <>
            <AttributeValue name='Job title' value={toTitlecase(investmentLimit.job_title)} />
            <AttributeValue name='Job industry' value={toTitlecase(investmentLimit.job_industry)} />
            <AttributeValue name='12 month crowdfunding history' value={<AmountWithCurrency number={investmentLimit?.outside_investment} />} />
            <AttributeValue name='Invested on Spaced Ventures' value={<AmountWithCurrency number={investmentLimit.current_investment} />} />
          </>
        }
      />
    </>
  )
}

export default function InvestmentLimitDetail({ investor, filterValue }) {
  const calcuatedLimit = useAdminInvestmentLimit(investor.id);

  if (calcuatedLimit.isLoading) return "Loading investment limit details...";

  switch (filterValue) {
    case 'members':
      return <MemberlimitTable investor={investor} calcuatedLimit={calcuatedLimit.data} />
    default:
      return <LimitTable investor={investor} calcuatedLimit={calcuatedLimit.data} />
  }
}