import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import ToastMessage from './components/ToastMessage';
import useHotjar from 'react-use-hotjar';
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
  useRollbarPerson
} from '@rollbar/react';
import { ROLLBAR_CONFIG } from 'config/rollbar';
import { ModalProvider as PortalizedModalProvider } from './contexts/portalizedModalContext';
import { ModalProvider as NewPortalizedModalProvider} from './components/PortalModal'
import './App.scss';
import './css/fonts.css'
import Home from './pages/Home'
import About from './pages/About';
import SignupPage from './pages/Page.Signup';
import LoginPage from './pages/Page.Login';
import ForgotPassword from './pages/Page.ForgotPassword';
import ResetPassword from './pages/Page.ResetPassword';
import Default from './pages/components/Default';
import Raise from './pages/Raise';
import Accredited from './pages/Accredited';
import SpaceLaunch from './pages/Page.SpaceLaunch';
import Press from './pages/Page.Press';
import Support from './pages/Page.Support';
import SpacedbasePage from './pages/Page.Spacedbase'
import InvestorAppFooter from './pages/components/InvestorAppFooter';
import AcceptInvitePage from './pages/Page.AcceptInvite';
import OfferListPage from './pages/Page.OfferList'
import {InvestorFaq} from './pages/Page.InvestorFaq'
import TermsOfServices from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import OfferViewPage from './admin/pages/Page.OfferView'
import PreofferViewPage from './admin/pages/Page.PreofferView';
import RaisePetitionViewPage from './pages/Page.RaisePetitionView';
import CompanyProfilePage from './admin/pages/Page.CompanyProfile'
import PublicAppToolbar from './pages/components/Toolbar/PublicAppToolbar'
import PublicSideDrawer from './pages/components/SideDrawer/PublicSideDrawer'
import Backdrop from './pages/components/Backdrop/Backdrop'
import Modal from './pages/components/Modal'
import SendGrid from './pages/components/SendGrid'
import WaitListAPI from './pages/components/WaitlistAPI'
import EmailVerification from './admin/pages/Page.EmailVerification'
import ConfirmEmailPage from './pages/Page.ConfirmEmail';
import MediaKit from './pages/Page.MediaKit'
import PlatformEvents from './pages/Page.PlatformEvents'
import PaymentsDisclaimer from './pages/PaymentsDisclaimer';

import AdminApp from './AdminApp'
import InvestorApp from './InvestorApp'
import { AuthContext } from './contexts/authContext'

import { useSession } from 'redux/session'

import { initFB, PageViewFB } from '../src/pages/components/Tracking/facebook'
import LinkedInTag from 'react-linkedin-insight';
import Waitlist from './pages/Waitlist'
import NewsletterPage from './pages/NewsletterPage';
import { history } from 'redux/helpers'
import { setRollbarPerson } from 'jsUtils/rollbar';
import { HubspotPageViewTracking } from './pages/components/Tracking/hubspot';

export const routesNotRestricted =
  process.env.REACT_APP_ENVIRONMENT == 'development' ||
  process.env.REACT_APP_ENVIRONMENT == 'next'||
  process.env.REACT_APP_ENVIRONMENT == 'sandbox';

const useHotJar = process.env.REACT_APP_ENVIRONMENT == 'production';

initFB();
PageViewFB();
LinkedInTag.init("3977692", 'dc', false);
const myCustomLogger = console.info;

const AppSwitcher = ({toggleModal, drawerClickHandler, onClose, show}) => {
  const { authData } = React.useContext(AuthContext);
  const currentUser = authData?.currentUser;

  const currentPerson = {
    id: currentUser?.id,
    email: currentUser?.email,
    username: currentUser?.name,
  }
  useRollbarPerson(currentPerson) // for rollbar from rollbar-react
  setRollbarPerson(currentPerson) // for custom Rollbar instance

  if(authData.isAuthenticated) {
    return authData.isAdmin ? <AdminApp /> : <InvestorApp drawerClickHandler={drawerClickHandler} onClose={onClose} show={show}/>
  }
  else {
    return <PublicAppLayout drawerClickHandler={drawerClickHandler} onClose={onClose} show={show}>
      <LoginPage  />
    </PublicAppLayout>
  }
}

const PublicAppLayout = ({ children, drawerClickHandler, onClose, show }) => {
  return (
    <>
      <PublicSideDrawer onClose={onClose} show={show} />
      <PublicAppToolbar drawerClickHandler={drawerClickHandler} />
      <div className="min-h-100vh">
        <Switch>
          {children}
        </Switch>
      </div>
      <InvestorAppFooter />
    </>
  )
}

function AfterLoginPage(props) {
  history.push({ pathname: '/login', state: { afterLoginPath: props.match.url } });

  return (
    <div style={{height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      Redirecting...
    </div>
  );
}

export default () => {
  const __useSession = useSession()
  const { authData } = React.useContext(AuthContext);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [accreditedSignup, setAccreditedSignup] = useState(false)
  const [modalContentType , setmodalContentType ] = useState("newsletter")
  const { initHotjar } = useHotjar();

  const location = useLocation();

  useEffect(() => {
    if (useHotJar){
      initHotjar(2467016, 6, false, myCustomLogger);
    }
  }, [initHotjar]);

  const toggleModal = (accredited, modalContentType = "newsletter") => {
    setmodalContentType(modalContentType)
    setModalOpen(!modalOpen)
    setAccreditedSignup(accredited)
  }

  const baseRoute = () => {
    if (authData.isAuthenticated) {
      return <Route path="/" render={ () => <AppSwitcher
                                              toggleModal={toggleModal}
                                              drawerClickHandler={drawerToggleClickHandler}
                                              onClose={backdropClickHandler}
                                              show={sideDrawerOpen} /> } />
    } else if(location.pathname === '/') {
       return <PublicAppLayout drawerClickHandler={drawerToggleClickHandler} onClose={backdropClickHandler} show={sideDrawerOpen}>
          <Route path="/" render={ () => <LoginPage /> } />
        </PublicAppLayout>
    }
  }

  const drawerToggleClickHandler = () => setSideDrawerOpen(!sideDrawerOpen)
  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
    setModalOpen(false)
  }

  let backdrop;
  if (sideDrawerOpen || modalOpen) {
    backdrop = <Backdrop backdropClickHandler={backdropClickHandler} />;
  }

  return (
    <RollbarProvider config={ROLLBAR_CONFIG}>
      <RollbarErrorBoundary>
          <PortalizedModalProvider>
            <NewPortalizedModalProvider>
            <div className="sticky-footer-container">
              <div className="app-content">
                <Switch>
                  {baseRoute()}
                  <PublicAppLayout drawerClickHandler={drawerToggleClickHandler} onClose={backdropClickHandler} show={sideDrawerOpen}>
                    <Route path="/invitations/:inviteToken" component={AcceptInvitePage} />
                    <Route path="/login" component={LoginPage} />
                    {/* <Route path="/signup" component={SignupPage} /> */}
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/admin" component={AdminApp} />
                    <Route exact path={'/email-verification'} component={EmailVerification} />
                    <Route path="/confirm-email/:token" component={ConfirmEmailPage} />
                    <Route path="/reset-password/:token" component={ResetPassword} />
                    {/* <Route exact path="/waitlist" component={Waitlist} /> */}
                    {/* <Route exact path="/newsletter" component={NewsletterPage} /> */}
                   {/* <Route exact path="/about" component={About} /> */}
                    {/* <Route exact path="/spacelaunch" component={SpaceLaunch} /> */}
                    {/* <Route exact path="/press" component={Press} /> */}
                    <Route exact path="/support" component={Support} />
                    {/* <Route exact path="/raise" component={Raise} /> */}
                    <Route exact path="/offers" component={()=><Redirect to={`/offerings`}/>} />
                    <Route path={`/prelaunch/:preofferId`} component={PreofferViewPage} />
                    {/* <Route exact path="/faqs" component={InvestorFaq} /> */}
                    {/* <Route exact path="/mediakit" component={MediaKit} /> */}
                    <Route path={`/offers/:offerId/company/:id`} component={CompanyProfilePage} />
                    <Route path={`/company/:id`} component={CompanyProfilePage} />
                    <Route path={`/offerings`} component={OfferListPage} />
                    <Route path={`/offers/:offerId`} component={OfferViewPage} />
                    <Route path={`/raise-petitions/:raisePetitionId`} component={RaisePetitionViewPage} />
                    {/* <Route exact path="/accredited-investors" render={(props) => <Accredited toggleModal={toggleModal} accredited={true} />} /> */}
                    {/* <Route path="/spacedbase" component={SpacedbasePage} /> */}
                    <Route exact path="/terms-of-service/" component={TermsOfServices} />
                    <Route exact path="/privacy-policy/" component={PrivacyPolicy} />
                    <Route exact path="/payments-disclaimer/" component={PaymentsDisclaimer} />
                    <Route exact path="/resources/" component={PlatformEvents} />
                    <Route exact path="/resources/:eventId" component={PlatformEvents} />
                    <Route exact path="/investments/:investmentId" component={AfterLoginPage}/>
                    <Route exact path="/account/investor-info" component={AfterLoginPage}/>
                    <Route path="/account" component={AfterLoginPage}/>
                    <Route path="/prelaunches/:preofferId/subscription/:subscriptionId" component={AfterLoginPage} />
                    <Route component={Default} />
                  </PublicAppLayout>
                </Switch>
                { modalContentType == "newsletter" ?
                    <Modal modalOpen={modalOpen} toggleModal={toggleModal}>
                      <SendGrid toggleModal={toggleModal} accreditedSignup={accreditedSignup} />
                    </Modal>
                      :
                    <Modal title="Want to be one of our Beta users?" modalOpen={modalOpen} toggleModal={toggleModal}>
                      <WaitListAPI toggleModal={toggleModal}  />
                    </Modal>
                }
                {backdrop}
              </div>
              <ToastMessage.Store />
              <HubspotPageViewTracking/>
            </div>
            </NewPortalizedModalProvider>
          </PortalizedModalProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
}
