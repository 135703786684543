import React from "react";
import SingleInvestmentDetailsRow from "../shared/SingleInvestmentDetailsRow";
import { toTitlecase } from "../../../jsUtils/stringUtils";
import { useUser } from "../../../redux/user";

export default function WirePaymentDetails({ investment }) {
  const investorClientId = investment.client_id;
  const user = useUser()

  if(user.loading){
    return null;
  }

  if (investment.status === "cancelled"){ 
    return null 
  }

  const investorClient = user.clients.find(
    (client) => client.id === investorClientId
  );

  return (
    <div className="wire-payment-wrapper">
      <div className="important-note arrow-bottom">
        Please use the following info the make your wire transfer.
      </div>
      <SingleInvestmentDetailsRow
        title={"Bank Name"}
        desc={investment?.payment_method?.details?.bank_name}
      />
      <SingleInvestmentDetailsRow
        title={"Bank Address"}
        desc={investment?.payment_method?.details?.bank_address}
        styles={{ whiteSpace: "pre-line" }}
      />
      <SingleInvestmentDetailsRow
        title={"Routing Number"}
        desc={investment?.payment_method?.details?.routing_number}
      />
        <SingleInvestmentDetailsRow
          title={"SWIFT Code"}
          desc={investment?.payment_method?.details?.swift_code}
        />
      <SingleInvestmentDetailsRow
        title={"Account Number"}
        desc={investment?.payment_method?.details?.account_number}
      />
      <SingleInvestmentDetailsRow
        title={"Custodian / Account Name"}
        desc={`${investment?.payment_method?.details?.beneficiary_name}, ${investment?.payment_method?.details?.beneficiary_address}`}
      />
      <SingleInvestmentDetailsRow
        title={
          <div>
            FFCT <span className="custom-badge">IMPORTANT</span>
          </div>
        }
        desc={`${toTitlecase(investment?.offer_name)} and ${investorClient?.name}`}
      />
      <div className="important-note">
        <b>IMPORTANT:</b>This identifies your deposit with your account.
        Include the offer name and your name when submitting the wire transfer.
      </div>
    </div>
  );
}
