import React, { useEffect } from 'react';

import { useUser, fetchCurrentUser } from 'redux/user';
import Settings from './components/Settings';
import Flash from './components/Flash';
import "./Page.Settings.scss";
import LoadingOverlay from 'react-loading-overlay';


export default function SettingsPage(props){
  const usersLoading = useUser().loading;

  useEffect(function() {
    fetchCurrentUser()
  }, [])

  return(
    <LoadingOverlay
      active={usersLoading}
      spinner
      text='Loading ...' >

      <div className="settings-main-container">
      <div className="email-confirm-container"> <Flash /></div>
        <div id="settingsPage">
          <Settings/>
        </div>
      </div>

    </LoadingOverlay>
  )
}
