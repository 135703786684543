import React from "react";
import { parseJSON, format } from "date-fns";
import AmlSectionsContainer from "./AmlSectionsContainer";
import { ApprovdeBadge, DisapprovedBadge, PendingBadge } from "./Badges";

function IssueDetail({ issue, clientType }) {
  const extraDetail = issue.extra_detail;

  if (!extraDetail || extraDetail.length === 0) {
    return null;
  }

  if (clientType === "person") {
    return (
      <div className="bold-card mt-1">
        <div className="bold-card-body bg-muted-black p-2">
          {extraDetail.map((v, index) => (
            <p key={index} className="color-white mb-1 poppins fs-14">
              During the automated check, your name matched a name on the {v.list}.
              This result can be triggered when a person with a similar name is on the list. <b><u>Please upload a piece of government ID.</u></b> We'll manually check the list and clear your AML if possible.
            </p>
          )
          )}
        </div>
      </div>
    )
  }

  if (["company", "trust"].indexOf(clientType) > - 1) {
    return (
      <div className="bold-card mt-1">
        <div className="bold-card-body bg-muted-black p-2">
          {extraDetail.map((v, index) => (
            <p key={index} className="color-white mb-1 poppins fs-14">
              During the automated check, your entity matched a name on the {v.list} with a score of {v.score}.
              This result can be triggered when an entity with a similar name is on the list. Please upload the requested entity documents. We'll manually check the list and clear your entity AML if possible.
            </p>
          )
          )}
        </div>
      </div>
    )
  }

  return null;
}

function DetailedSection({ amlException, clientType }) {
  const issues = amlException.issues.filter(i => i.type === 'aml');

  if (issues.length === 0) {
    return null;
  }

  return (
    <>
      <AmlSectionsContainer title={"Results of AML Check"} tooltipText="The automated AML check confirms that your name is not on any restricted lists. If an AML issue occurs, we will manually check the list using a piece of government ID.">
        <div className="content-layer bg-golden">
          <b>{format(parseJSON(new Date(amlException.updated_at)), "MMM dd, yyyy hh:mm a")}</b>
          <ul className="mt-2 ml-3">
            {issues.map((issue, index) => (<li key={index}>{issue.message}</li>))}
          </ul>
        </div>
      </AmlSectionsContainer>
      {issues.map((issue, index) => <IssueDetail key={index} clientType={clientType} issue={issue} />)}
    </>
  )
}

export default function AMLReviewNote({ amlException, clientType }) {
  const status = amlException.aml_status;

  return (
    <div className="mt-1 mb-2 p-3 bg-lightgrey">
      <div className="weight-700 mb-2 lh-md d-flex flex-wrap align-items-center">
        <span>AML Status</span>
        {status === 'pending' && <PendingBadge />}
        {status === 'cleared' && <ApprovdeBadge />}
        {status === 'failed' && <DisapprovedBadge />}
      </div>
      {status !== 'cleared' && <DetailedSection clientType={clientType} amlException={amlException} />}
    </div>
  );
}
