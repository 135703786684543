import React, { useState } from 'react';
import LiveChat from '../../../components/LiveChat';
import YoutubeEmbed from '../shared/YoutubeEmbed';
import { PusherChannelProvider } from '../../../contexts/PusherChannelContext';
import { AuthContext } from '../../../contexts/authContext';
import BannedParticipant from './BannedParticipants';

export default function ChatModeration({ liveEvent, getChatSession }) {
  const authContext = React.useContext(AuthContext);
  const [dataChangedAt, setDataChangedAt] = useState(null);

  return (
    <PusherChannelProvider>
      <div className='row'>
        <div className='col-10 offset-1'>
          <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#chat" role="tab">Chat</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#banned-users" role="tab">Banned List</a>
            </li>
          </ul>
          <div className="tab-content px-3">
            <div className="tab-pane fade show active" id="chat" role="tabpanel">
              <div className="row">
                <div className="col-12">
                  <div className='row live-event-moderation'>
                    <div className='col-12 col-md-7 section'>
                      <div className='for-youtube-vid-container'>
                        {liveEvent.youtube_url && <YoutubeEmbed videoUrl={liveEvent.youtube_url} />}
                      </div>
                    </div>
                    <div className='col-12 col-md-5 mt-2 mt-md-0 section'>
                      <div className='chat-container col-12'>
                        <LiveChat.ChatBox liveEvent={liveEvent} user={authContext.authData.currentUser} moderation={true} setDataChangedAt={setDataChangedAt} getChatSession={() => getChatSession(liveEvent.slug || liveEvent.id)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="banned-users" role="tabpanel">
              <div className="row">
                <div className='col-12'>
                  <BannedParticipant liveEvent={liveEvent} setDataChangedAt={setDataChangedAt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PusherChannelProvider>
  )
}
