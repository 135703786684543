import React, { useRef, createRef } from 'react';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, AtomicBlockUtils } from 'draft-js';
import Icon from 'admin/components/Icon'

import axiosClient from 'config/axiosClient'
import './css/example.css';
import './css/draft.css';
import './css/rich-editor.css';

// https://www.youtube.com/watch?v=hlkb623McOU
// https://youtu.be/hlkb623McOU
const YOUTUBE_URL_REGEX = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-z0-9_-]{11})/gi

const YoutTubeEmbed = (props) => {
  const entityKey = props.block.getEntityAt(0)
  const entity = props.contentState.getEntity(entityKey)
  if(entity.getType() !== "YOUTUBE-EMBED") return null

  const videoUrl = entity.data.videoUrl;
  const validVideoUrl = videoUrl.match(YOUTUBE_URL_REGEX)
  if (!validVideoUrl) return null
  const videoId  = validVideoUrl[0].match(/[a-zA-z0-9]+$/)[0]
  return (
    <>
      <iframe width="100%" height="348"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        frameBorder="0"
        allow=""
      />
    </>
  )
}

const ImageEmbed = (props) => {
  const entityKey = props.block.getEntityAt(0)
  const entity = props.contentState.getEntity(entityKey)
  if(entity.getType() !== "IMAGE-EMBED") return null

  const src = entity.data.src;
  return (
    <div>
      <img src={src} style={{width: "100%"}}/>
    </div>
  )
}

const AtomicBlockRenderer = (props) => {
  const entityKey = props.block.getEntityAt(0)
  const entity = props.contentState.getEntity(entityKey)
  if(entity.getType() === "YOUTUBE-EMBED")
    return <YoutTubeEmbed {...props} />
  if(entity.getType() === "IMAGE-EMBED")
    return <ImageEmbed {...props} />
  return null
}


export default function RichEditor(props) {
  const { editorState } = props;
  const editor = useRef(null)
  const draftjsFileInput = createRef()

  const onChange = editorState => props.onChange && props.onChange(editorState)
  const focus = () => editor.current.focus();

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const  handleKeyBindings = (e) => {
    switch (e.keyCode) {
      case 9:
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4 /* maxDepth */,
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState)
        }
        return null;
    }
    return getDefaultKeyBinding(e);
  }

  const toggleInlineStyle = inlineStyle => onChange( RichUtils.toggleInlineStyle(editorState, inlineStyle));
  const toggleBlockType = blockType => onChange(RichUtils.toggleBlockType(editorState, blockType));

  const blockRendererFn = contentBlock => {
    const type = contentBlock.getType();
    if (contentBlock.getType() !== 'atomic') return null;

    if (type === "atomic") {
      return {
        component: AtomicBlockRenderer,
        editable: false,
      };
    }
  };

  const insertYoutubeEmbed = () => {
    const ytlink = prompt("Youtube Video Link")
    if (!ytlink) return
    const validVideoUrl = ytlink.match(YOUTUBE_URL_REGEX)
    if (!validVideoUrl) {
      alert('Invalid youtube video url')
      return
    }
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      'YOUTUBE-EMBED',
      'IMMUTABLE',
      {videoUrl: ytlink}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    const t = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " "
    )
    onChange(t)
  };
  window.embed__Youtube = insertYoutubeEmbed;

  const insertImageEmbed = () => {
    const fileInput = draftjsFileInput.current
    const controller = new AbortController();
    const changeListner = (e) => {
      if (fileInput.files.length) {
        let data = new FormData();
        const file = fileInput.files[0]
        const extRegex = /(?:\.([^.]+))?$/
        const extension = extRegex.exec(file.name)[1]
        const originalName = file.name.replace(/\.[^/.]+$/, "")
        const newFileName = `${originalName}-${new Date().getTime()}.${extension}`
        data.append('file', file, newFileName);
        axiosClient.post('/uploads/image', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(
          res => {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
              'IMAGE-EMBED',
              'IMMUTABLE',
              {src: res.url}
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
              currentContent: contentStateWithEntity
            });

            const t = AtomicBlockUtils.insertAtomicBlock(
              newEditorState,
              entityKey,
              " "
            )
            onChange(t)
          },
          err => console.error('axios:', err)
        ).finally(() => {
          controller.abort()
          fileInput.value = ""
        })
      }
    }
    fileInput.addEventListener("change", changeListner, { signal: controller.signal });
    draftjsFileInput.current.click()
  };
  window.embed__Image = insertImageEmbed;

  const insertLinkEmbed = () => {
    let linkUrl = ""
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkEntityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    if (linkEntityKey) { // remove link entity if exists already
      const linkInstance = contentState.getEntity(linkEntityKey);
      onChange(RichUtils.toggleLink(editorState, selectionState, null))
      return
    }

    // Add link entity
    linkUrl = prompt("Link Url", linkUrl)
    if (!linkUrl) return

    const contentStateWithEntity = contentState.createEntity(
      'LINK-EMBED',
      'MUTABLE',
      {href: linkUrl}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    onChange(
      RichUtils.toggleLink(
        newEditorState,
        selectionState,
        entityKey
      )
    )
  };
  window.embed__Link = insertLinkEmbed;

  let className = props.readOnly ? '' : 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className="stylish-textarea">
      <div className="RichEditor-root" style={{backgroundColor: props.backgroundColor || '#fff'}}>
        <input id="draftjs-file-input" type="file" name="draftjs-file-input" ref={draftjsFileInput} style={{display: 'none'}} />
        <div className="RichEditorAllControls">
          {
            !props.readOnly && <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
              hideExternalPlugins={props.hideExternalPlugins || false}
            />
          }
          {
            !props.readOnly && <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
          }
        </div>
        <div className={`${props.className} ${className}`} onClick={focus} style={{height: 'calc(100% - 42px)'}}>
          <Editor
            blockStyleFn={getBlockStyle}
            blockRendererFn={blockRendererFn}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={handleKeyBindings}
            onChange={onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder || "Content..."}
            ref={editor}
            spellCheck={false}
            stripPastedStyles={true}
            readOnly={props.readOnly}
          />
        </div>
      </div>
    </div>
  );
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 16,
    padding: 5,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

var INLINE_STYLES = [
  { label: <Icon name="formatBold" />, style: 'BOLD' },
  { label: <Icon name="formatItalic" />, style: 'ITALIC' },
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls Inline-RichEditor-Controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
      {
        !props.hideExternalPlugins &&
          <>
            <Icon name="embedLink" onClick={window.embed__Link} />
            <Icon name="embedImage" onClick={window.embed__Image} />
            <Icon name="embedVideo" onClick={window.embed__Youtube} />
          </>
      }
    </div>
  );
};

const BLOCK_TYPES = [
  { label: <Icon name="listUl" />, style: "unordered-list-item" },
  { label: <Icon name="listOl" />, style: "ordered-list-item" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls  BlockLevel-RichEditor-Controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
