import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from "react-router";
import OverviewModal from 'admin/components/OverviewModal'
import OfferOverview from 'admin/components/OfferOverview'
import OverviewHeader from 'admin/components/OverviewHeader'
import EditorModal from 'admin/components/EditorModal'
import ResponsiveTable from '../components/ResponsiveTable';
import { FaEye } from "react-icons/fa";
import { format, parseJSON } from 'date-fns';
import OfferEditor from 'admin/components/OfferEditor'
import Paginator from 'admin/components/Paginator'
import { toTitlecase, humanize } from '../../jsUtils/stringUtils';
import { FaPen } from "react-icons/fa";
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../components/AmountWithCurrency';
import TableActionsContainer from '../components/TableActionsContainer';
import TableActionItem from '../components/TableActionItem';
import LinkedData from '../../components/LinkedData';
import { history } from 'redux/helpers';
import { useOffersList } from './offer/queries';
import usePagination from 'hooks/usePagination';
import LoadingOverlay from 'react-loading-overlay';
import useSearchInputManager from '../../hooks/useSearchInputManager';
import SearchInput from '../../components/SearchInput';

export default function ListOffersPage(props) {
  let { path, url } = useRouteMatch();
  const pageConfig = usePagination();

  const searchInputManager = useSearchInputManager()

  React.useEffect(()=> {
    pageConfig.setPage(1);
  }, [searchInputManager.queryString])

  const offerList = useOffersList({q: searchInputManager.queryString, ...pageConfig.current})

  const openShowModal = React.useCallback(
    (row) => {
      history.push(`${url}/${row.original.slug}`)
    },
    []
  )

  const openEditorModal = React.useCallback(
    (row) => {
      history.push(`${url}/${row.original.slug}/edit`)
    },
    []
  )

  const RowActionsFn = (row) => {
    return(
      <TableActionsContainer>
        <TableActionItem
          tooltipText="Edit"
          onClick={() => openEditorModal(row)}>
            <FaPen/>
        </TableActionItem>
        <TableActionItem tooltipText="View" onClick={() => openShowModal(row)}>
          <FaEye />
        </TableActionItem>
      </TableActionsContainer>
    )
  }

  if (offerList.isLoading) {
    return (
      <LoadingOverlay
        active={offerList.isLoading}
        spinner
        text='Loading ...'
      />
    )
  }

  return (
    <div className="bg-lightgrey">
      <div className="admin-main-content-header">
        <SearchInput {...searchInputManager} />
      </div>
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={offerList.data?.pagy}
      />
      <OfferListingTable offers={offerList.data?.data} actionsFn={RowActionsFn} onView={openShowModal} loadingState={offerList.isLoading} />
      <Switch>
        <Route exact path={`${path}/:offerSlug`}
          component={() => (
            <OverviewModal>
              <OfferOverview />
            </OverviewModal>
          )}
        />
        <Route exact path={`${path}/:offerSlug/edit`}
          component={() => (
            <EditorModal>
              <OfferEditor />
            </EditorModal>
          )}
        />
      </Switch>
    </div>
  )
}

export function OfferListingTable({ offers, actionsFn, onView, loadingState = false}) {

  const columns = React.useMemo(
    () => {
      if (offers){
        const colConfig = [
          {
            Header: 'Offer Name',
            accessor: 'name',
            Cell: ({value, row}) => (
              onView ? (
                <LinkedData onClick={() => onView(row)}>
                  {value}
                </LinkedData>
              ) : (
                value
              )
            )
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: ({value}) => humanize(value)
          },
          {
            Header: 'Company',
            accessor: 'company',
            Cell: ({value}) => (
              <div className="d-flex align-items-start">
                <img src={value?.logo_url} alt='' className="company-table-logo mr-2" />
                {toTitlecase(value?.name)}
              </div>
           )
          },
          {
            Header: 'Created',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: <>Goal&nbsp;<CurrencyBadge /></>,
            accessor: 'deal.maximum_goal',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: <>Amt funded&nbsp;<CurrencyBadge /></>,
            accessor: 'deal.raised',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({value}) => humanize(value)
          },
        ]

        if (actionsFn) {
          colConfig.push({
            Header: "",
            accessor: "id",
            Cell: ({ row, value }) => actionsFn(row),
          })
        }

        return colConfig;
      }
      else{
        return []
      }
    },
    [offers]
  );

  return (
    <ResponsiveTable
        columns={columns}
        data={offers}
        loading={loadingState}
        loadingMsg="Loading offers..."
        noDataMsg="No offers found."
        classes="use-xxl-width"
      />
  )
}
