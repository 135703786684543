import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useMutation } from 'react-query';
import {
  usePreoffer,
  usePreofferSubscriptionEsignRequest,
  signPreofferSubscription
} from 'investor/queries';
import { useUser } from '../redux/user';
import { history } from '../redux/helpers';
import { toTitlecase } from '../jsUtils/stringUtils'
import { rollbarInstance } from 'jsUtils/rollbar';
import ToastMessage from '../components/ToastMessage';
import PageLoadingSpinner from '../components/PageLoadingSpinner';


export default function PreofferSubscriptionAgreement() {
  let { preofferId, subscriptionId } = useParams();
  const user = useUser();
  const { data: preoffer, isError: isPreofferLoadigError, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);
  const { data: subscription, isError: isSubscriptionLoadingError, isLoading: isLoadingSubscription } = usePreofferSubscriptionEsignRequest(subscriptionId);
  const isLoading = isLoadingPreoffer || isLoadingSubscription || user.isLoading;
  const isError = isPreofferLoadigError || isSubscriptionLoadingError;


  if (isLoading) {
    return <PageLoadingSpinner isLoading={isLoading} message="Loading Document for Esign..." />;
  }

  if (isError) {
    ToastMessage.error('Could not fetch document for esign. Please try reloading the page.');
    return <h5 className="montserrat weight-700">Could not fetch document for esign. Please try reloading the page.'</h5>;
  }

  return (
    <>
      <div className="investment-header">
        <div className="container pb-3 pt-first-section">
          <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">{preoffer.company?.name} {preoffer.category === "secondary" ? "Secondary" : "Pre-launch"}</h1>
        </div>
      </div>
      <div className="investor-formbox subscription-agreement-screen">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-4">
              <h5 className="montserrat weight-700">Please sign your {preoffer.category === "secondary" ? "Commitment" : "Indication of Interest"}</h5>
              <SubscriberDetail subscription={subscription} />
              <EsignBox preoffer={preoffer} subscription={subscription} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function EsignBox({ preoffer, subscription }) {
  const [loading, setLoading] = useState(true);
  const subscriptionId = subscription.id;

  const mutation = useMutation(
    () => signPreofferSubscription({ subscriptionId: subscriptionId, values: { signed_at: new Date()} }),
    {
      onSuccess: (data, variables, context) => {
        rollbarInstance.info("Signed preoffer subscription agreement", { subscription_id: subscription.id });
        ToastMessage.info('You have successfully signed the Document.')
        history.push({
          pathname: `/prelaunches/${preoffer.id}/subscription/${subscriptionId}`,
          state: {
            showModal: true,
            companyName: preoffer.company.name
          },
        });
      },
      onError: (error, variables, context) => {
        rollbarInstance.error("Error signing subscription agreement", {
          subscription_id: subscriptionId,
        });
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
  );

  useEffect(() => {
    const handler = (event) => {
      console.log('Docusign page event', subscriptionId, event.data);
      rollbarInstance.info("Docusign page event", { subscription_id: subscriptionId, callback_event: event.data });

      if (["signing_complete", "decline", "cancel"].indexOf(event.data) > -1) {
        mutation.mutate();
      } else if (event.data === 'ttl_expired') {
        console.log('URL expireed', subscription.esign_url)
      } else {
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [subscriptionId]);

  return (
    <div className="esign-container">
      <PageLoadingSpinner isLoading={mutation.isLoading || loading} message={mutation.isLoading ? 'Loading...' : "Loading Subscription Agreement..."} />
      <iframe
        title="Indicate Interest Signature"
        src={subscription.esign_url}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}

function InvestorFieldView({ label, value }) {
  return (
    <div className='freez-field mb-2'>
      <label>{label}:</label>
      <div className='freez-field-val'>{value}</div>
    </div>
  )
}

function SubscriberDetail({ subscription }) {
  const isEntity = subscription.client.entity_type !== 'person';

  return (
    <div className='subscriber-info mt-4'>
      <InvestorFieldView label={isEntity ? 'Entity Name' : 'Name'} value={toTitlecase(subscription.client.name)} />
      <InvestorFieldView label="Email" value={subscription.email} />
    </div>
  )
}

