import React from 'react';

export default function PrivacyPolicy(){ 
return (	
  <div className="terms-services-container">
    <div className="container lg-main-section lh-md ">
  		<h1 className="weight-900">Privacy Policy</h1>
      <div className="text-muted zeitung-micro">Last Modified: February 1, 2021</div>
      <div className="m-t-15">
        <h5 className="weight-700 montserrat">About This Website</h5>
        <p>www.spacedventures.com (the “Site”) is a website owned by Spaced Ventures Inc. (“Spaced Ventures” “us,” “our” or “we”). All offerings under Regulation Crowdfunding are conducted by SV Portal LLC. Spaced Ventures is not a registered broker-dealer or an investment adviser. We do not provide financial advice or investment recommendations; any information or representations made on our Site do not constitute and should not be construed as investment advice or recommendations.</p>
        <p>All securities listed here are being offered by, and all information included on this Site is the responsibility of, the applicable issuer of such securities. Spaced Ventures has not taken any steps to verify the adequacy, accuracy or completeness of any information. Neither Spaced Ventures nor any of its officers, directors, agents or employees makes any warranty, express or implied, of any kind whatsoever related to the adequacy, accuracy or completeness of any information on this site or the use of information on this site.</p>
        <p>As explained below, we collect information from you (“you”, “your”, or “user”) in various ways when you use or interact with our Site. Please also see our Terms of Use, which are incorporated herein by reference, for the terms and conditions of visiting and using our Site.</p>
      </div>
      <div className="m-t-15">
        <h5 className="weight-700 montserrat">Your Consent to This Privacy Policy</h5>
        <p className="weight-700">By using our Site, you consent to our collection and use of information as explained in this Privacy Policy, and you agree to be bound by our Terms of Use. If we post an updated version of this Privacy Policy and you continue to use our Site thereafter, your continued use of our Site will also mean that you consent to the updated Privacy Policy. If you do not agree to this Privacy Policy, you must not use our Site. Please note that if you choose to invest in any securities offering posted on our Site, you may be required to accept the terms of a separate agreement, including, but not limited to, new or additional privacy policies or terms of use required by the issuer of such securities.</p> 
        <h5 className="weight-700 montserrat">Purpose of This Privacy Policy</h5>
        <p>Spaced Ventures respects your privacy and understand that personal information must be collected by lawful and fair means. Toward that end, this Privacy Policy explains what information will be collected by Spaced Ventures when you use our Site and related services, how your information will be used, and how you control the collection and correction of information. We will treat all information in accordance with this Privacy Policy unless you have otherwise given express consent, which consent may be withdrawn at any time by notifying us at <span className="weight-700">support@spacedventures.com</span>. However, Spaced Ventures may use or disclose your information when we believe it is necessary for us to comply with a court order or other legal process.</p>
        <h5 className="weight-700 montserrat">Information We Collect About You</h5>
        <p><span className="italic-style">Personally Identifiable Information.</span> “Personally Identifiable Information” is information that may be used to identify you personally and can include financial information as well. Spaced Ventures collects Personally Identifiable Information from you when you use our Site in various ways such as signing up for an account, completing forms or applications on our Site, sending us an email through our Site, or engaging in a transaction through our Site. Spaced Ventures may also collect information which may be contained on server logs, such as your Internet protocol (“IP”) address.</p>
        <p>The types of Personally Identifiable Information that we collect through our Site may include, but not be limited to, your: (1) personal name; (2) email address; (3) physical address; (4) phone number; (5) date of birth; (6) Social Security Number; (7) information from an identity document such as a passport or driver’s license; (8) business name; (9) business address; (10) articles of incorporation; (11) bank account information; and (12) any other Personally Identifiable Information you may voluntarily provide.</p>
        <p><span className="italic-style">Non-Personally Identifiable Information.</span> Spaced Ventures also collects Non-Personally Identifiable Information from you when you use our Site. “Non-Personally Identifiable Information” is information which, by itself, cannot be used to identify or contact you. Non- Personally Identifiable Information which we collect and may store in our log files may include, without limitation, frequency of visits to our Site, time spent viewing pages on our Site, date(s) and time(s) of visits to our Site, clickstream data, cookies existing on your computer, operating system, browser type, device type used to visit our Site (desktop or mobile), and sites visited before arriving at our Site. We collect Non-Personally Identifiable Information through automated tracking technologies, some of which are discussed below, and we may combine this automatically collected information with other information we collect about you. We do this to improve services we offer you and to improve our Site.</p>
        <p>Personally Identifiable Information may be supplemented with other information, including Non- Personally Identifiable Information and Personally Identifiable Information from third party sources. Types and uses of such third party sources may include: (1) services used to verify identity and backgrounds; (2) services used for anti-money laundering screening; or (3) payment processing services.</p>
        <h5 className="weight-700 montserrat">How We Collect Information about You</h5>
        <p>We collect the above information about you when you voluntarily provide it through our Site. For example, we collect information you provide to us when you complete forms on our Site such as when you create your user account, or submit inquiries, feedback, or comments to us by email. We also collect other information or content from you when you upload it voluntarily to our Site such as if you upload a photograph of yourself to our Site.</p>
        <p>We and our service providers may also collect information about you using various automated technologies on our Site such as those described below:</p>
        <ul className="pl-5 zeitung-micro">
         <li><span className="weight-700">Cookies (or browser cookies).</span> A cookie is a small text file placed on the hard drive of your computer. You may refuse to accept cookies by activating the appropriate “Do Not Track” setting on your browser or delete or “flush” all cookies stored on your device. However, if you select this setting or delete all cookies you may be unable to access certain parts of our Site or certain features of our Site may be disabled. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Site. Cookies placed on your computer by our Site are called “first-party” cookies, and those placed by third parties would be “third party” cookies. We use cookies for purposes such as tracking user visits, tracking webpages viewed, storing user preferences, gathering statistics on the number of users or visitors to our Site, implementing automatic authentication (which allows users to sign in to their account without having to enter their password every time), and analyzing parts of our Site in order to improve its speed, performance, or functionality.</li>
        </ul>
        <p className="weight-700">By visiting or registering for our Site, you consent to our use of first-party and third-party cookies and other automated technologies that we or third parties may use to collect information from our Site.</p>
        <ul className="pl-5 zeitung-micro">
         <li><span className="weight-700">Web Beacons.</span> Some pages of our Site may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages of our Site and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
         <li><span className="weight-700">Log File Data.</span> Log files are automatically reported by your web browser and may include information such as your Internet protocol (“IP”) address, frequency and timing of clicks you make on webpages, browser type and configuration, or webpages viewed or visited. This information helps us with improving our Site.</li>
         <li><span className="weight-700">Analytics.</span> We may use analytics programs and service providers, such as Google Analytics and Mixpanel, which can use first party cookies to report on your interactions with the Site. Google Analytics collects information including, but not limited to, the time of each of your visits to the Site, whether you have visited the Site before, what website referred you to the Site, and what pages you viewed on the Site. If you wish to learn more about Google Analytics and how it collects and processes data, please visit the following link: <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy/partners/</a>. You can also choose to opt-out of Google Analytics by visiting <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a> or, with regard to Mixpanel’s automatic collection of data, by visiting <a href="https://mixpanel.com/optout" target="_blank" rel="noopener noreferrer">https://mixpanel.com/optout</a>.</li>
        </ul>
        <p>The above technologies are used in analyzing trends, administering our Site, tracking users’ movements around our Site and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by third-party companies on an individual as well as aggregated basis. This information helps us with improving our Site.</p>
        <p>This Privacy Policy does not cover the collection of information by cookies or other methods by third parties. Any information submitted by you to a third party will be controlled by that third party’s privacy policy. We do not control how these third parties collect information or by what means such third parties may use their own cookies to collect information about you. We encourage you to review the privacy policy of any third party before submitting your personal information.</p>
        <h5 className="weight-700 montserrat">How We Use Information We Collect About You</h5>
        <p>Spaced Ventures will use information collected about you consistent with this Privacy Policy. Except as may result from a transaction described in the “Transfer of Your Information” section below, we do not rent or sell your information to anyone. We will use information you provide to us, including Non-Personally Identifiable Information or Personally Identifiable Information, to better serve you and enhance your experience with our Site. This may include having to modify or copy such Non-Personally Identifiable Information or Personally Identifiable Information, or other information such as images or textual content you provide, in order to provide you with a seamless and enjoyable experience in using our Site.</p>
        <p>Spaced Ventures will use Non-Personally Identifiable Information and Personally Identifiable Information: (1) to market our Site or our affiliates’ websites, products, and services to you and other users of our Site; (2) analyze data to send more targeted messages to users of our Site; (3) to conduct research and analyze data to improve our products, services, and Site; (4) to enable our vendors and contractors to provide and assist us in the marketing, operation, troubleshooting and analysis of our Site and other services; (5) to fulfill product and service requests; (6) to deliver email messages like special offers, updates, newsletters, customer service, and service announcements; (7) to comply with and meet any regulatory requirements for the offer or sale of securities required by law, rules or regulations; (8) to protect our Site or its users; (9) to engage in any other lawful activities we believe are consistent with the foregoing; and (10) when required by law (e.g., to comply with a subpoena or similar legal process), when we deem it necessary to protect against legal liability, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>
        <p>We may provide or obtain any of the above services through third parties, which may necessitate sharing Non-Personally Identifiable Information or Personally Identifiable Information with them. For example, we may use a third party website developer who will need access to your information for development and analysis purposes in connection with improving our Site. With regard to use of your information for marketing, none of the information is shared beyond Spaced Ventures’s affiliates.</p>
        <p>In addition to other uses described in this Privacy Policy, your Personally Identifiable Information will be used to facilitate activities such as (1) maintaining communications; (2) verifying identities; (3) working with third parties to process payments and other transactions; (4) guarding against potential fraud; (5) performing statistical analysis and market research; and (6) if necessary, to work with affiliates and third parties to enforce our agreements, including working with any third-party service providers.</p>
        <p>In addition to other uses described in this Privacy Policy, the Personally Identifiable Information will be used by an individual or entity providing debt financing to users of our Site to facilitate activities such as: (1) maintaining communications; (2) carrying out regulatory checks; (3) verifying identity, including working with third-party service providers; (4) working with third parties to process payments and other transactions; (5) posting an investor profile on our Site, which may include Personally Identifiable Information such as name, relationship to the issuer of securities, if applicable and photo (if provided).</p>
        <p>Spaced Ventures employs or contracts with third-party companies or individuals to perform certain functions or services on its behalf. Examples of these services from such third parties, companies, or individuals, may include ID verification, accreditation/financial/credit verification, electronic document signing, payment storing and processing, sending e-mail, escrow services, disbursement of funds, analyzing data, providing marketing assistance, and providing customer service. Spaced Ventures may share with these third parties, and these third parties may have access to, your information, including Non-Personally Identifiable Information or Personally Identifiable Information needed to perform their functions or services. However, these third parties may not use your information for other purposes. To the extent that your Non-Personally Identifiable Information or Personally Identifiable Information is provided to such third parties, the information will be collected and used by the third parties only to perform their prescribed functions or services.</p>
        <h5 className="weight-700 montserrat">Transfer of Your Information</h5>
        <p>We may have to transfer your information to a buyer or other successor to our business in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Spaced Ventures’s assets, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding, in which Personally Identifiable Information held by us about our Site user is among the assets transferred.</p>
        <h5 className="weight-700 montserrat">Third Party Websites and Services</h5>
        <p>Spaced Ventures’s Site may contain links to other websites and services not maintained by us. In addition, other websites and services may also reference or link to our Site. These other websites are not subject to our Privacy Policy and we are not responsible for their content or for the policies or practices they apply to the treatment of your information. We encourage you to be aware of this when you enter or leave our Site and to read the privacy statements of each website that you visit. We do not endorse, screen, or approve, and are not responsible for the privacy practices or the content of, other websites or services.</p>
        <h5 className="weight-700 montserrat">Messaging</h5>
        <p>The Site permits you and other users to communicate with companies and each other through communications channels included on the Site. We may read, collect, and analyze information transmitted between users for compliance and marketing purposes. We do not guarantee that information provided by other users on the Site is accurate or complete, nor do we assume any responsibility for correcting such information.</p>
        <h5 className="weight-700 montserrat">Opt-out Policy</h5>
        <p>We or our affiliates may at times send you e-mail communications with general marketing information. If you prefer not to receive such marketing or promotional e-mails, you may unsubscribe via your account dashboard or by emailing us at: support@spacedventures.com or you may choose to stop receiving our newsletter or marketing emails by following the “unsubscribe” instructions included in these emails. However, you cannot opt out of receiving certain types of email communications such as emails regarding specific transactions you have participated in on our Site, legal notices we may be required to give, or emails responding to specific inquiries from you.</p>
        <p>Please note that opt-out requests may take up to one (1) week to process. Please also note that at times we may need to send you e-mail communications which are transactional in nature and which you will not be able to opt-out of, such as service or termination announcements or payment confirmations.</p>
        <h5 className="weight-700 montserrat">Accessing or Changing Your Information</h5>
        <p>You will be able to view all of your own information that you submit. Certain sensitive information (e.g., SSN or bank account numbers) may be blocked for security reasons with only the last four digits displayed. You will, subject to our Site’s Terms of Use, be able to access, update and modify the information concerning your account by visiting your profile page and editing certain information you wish to change. For example, you will be able to change your e- mail address, your notification preferences, password, contact information, or other login information by visiting your account’s settings page. We will retain your information for as long as your account is active or as needed to provide you with services.</p>
        <p>You can choose to delete your account. However, we will have the right to retain and use your information as necessary to comply with our legal obligations, provide our services, resolve disputes, and enforce our agreements. For example, we may need to retain archival copies of your information for legal or compliance purposes even after you have requested its deletion.</p>
        <p>We are not responsible for removing your Personally Identifiable Information from the lists of any third-party websites or other third parties who have previously been provided your information in accordance with this Privacy Policy.</p>
        <h5 className="weight-700 montserrat">Social Media Widgets</h5>
        <p>Our Site may include certain social media features, such as the Facebook Follow Us button and widgets, such as the Share this button or interactive mini-programs that run on our Site. These features may collect your IP address, which page you are visiting on our Site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these features are governed by the privacy policy of the third party website providing such features.</p>
        <h5 className="weight-700 montserrat">Security</h5>
        <p>We use reasonable efforts to secure access to Personally Identifiable Information in our possession, but will not be liable for any failure to safeguard such information, whether inadvertent or otherwise. Information you provide to us is encrypted and stored securely on private cloud servers through third-party service providers that we use. We also take commercially reasonable efforts to store as little sensitive information as possible. For example, information regarding bank accounts will not be stored on our servers, but may be transferred to third-party payment processors and stored by them. Information regarding identification documents such as passports or driver’s licenses will not be stored on our servers but be transferred to third-party service providers who must use that information to perform verification services for us.</p>
        <p>Though we and our service providers, who have informed us that they also use reasonable efforts to secure Personally Identifiable Information, take commercially reasonable efforts to keep secure any Personally Identifiable Information, there is no guarantee that any communication over the public Internet is 100% secure at all times. Therefore, neither we nor our service providers shall be held liable for the failure to safeguard Personally Identifiable Information, whether inadvertent or otherwise.</p>
        <p>It is also important for you to protect against unauthorized access to your password and to your computer. Be sure to log off any computer or device you use to access your account on our Site after you have finished.</p>
        <h5 className="weight-700 montserrat">Children’s Privacy Policy</h5>
        <p>The Spaced Ventures Site is not intended for use by children, especially those under eighteen (18) years of age. No one under eighteen (18) years of age is allowed to use our Site, provide any Personally Identifiable Information or receive our email distributions. We do not knowingly solicit or collect Personally Identifiable Information from children under the age of thirteen (13) or anyone under eighteen years of age. If you believe that anyone under these ages has disclosed Personally Identifiable Information to Spaced Ventures, please report this to us immediately by emailing us at support@spacedventures.com.</p>
        <h5 className="weight-700 montserrat">Changes to This Privacy Policy</h5>
        <p>We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy Policy at any time. Any changes or updates will be effective immediately upon posting to our Site. If we make any material changes to our Site we will take commercially reasonable efforts to notify you by email (sent to the e-mail address specified in your account) or by means of a notice posted on our Site. Notwithstanding that, you should review this Privacy Policy regularly for changes, and you can determine if changes have been made by checking the effective date at the beginning of the Privacy Policy. Your continued use of our Site following the posting of any modified versions of this Privacy Policy means you accept such changes, and agree to be bound by such updated Privacy Policy.</p>
        <h5 className="weight-700 montserrat">Applicable Law and Jurisdiction</h5>
        <p>This Site is maintained in the United States of America. By accessing our Site, you consent to and authorize the export of your Personally Identifiable Information to the United States of America and its storage and use as specified in this Privacy Policy.</p>
        <p>This Privacy Policy shall be governed by, construed and entered in accordance with the laws of the State of Florida applicable to contracts deemed to be made within such state, without regard to choice-of-law or the conflicts-of-laws principles that are applied by courts of that state or of any other jurisdiction. All disputes with respect to this Privacy Policy shall be brought and heard either in the Florida state courts located in Melbourne, Florida or the federal district court located in Melbourne, Florida You consent to the in person jurisdiction and venue of such courts.</p>
        <p>YOU HEREBY WAIVE YOUR RIGHT TO A TRIAL BY JURY WITH RESPECT TO ANY CLAIM, ACTION OR PROCEEDING, DIRECTLY OR INDIRECTLY, ARISING OUT OF, OR RELATING TO, THIS PRIVACY POLICY TO THE FULLEST EXTENT PERMITTED BY LAW.</p>



      </div>
    </div>
  </div>)
}