import React from "react";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import CreditCardPaymentForm from "./CcForm";
import { FaPlus } from "react-icons/fa";

const DefaultSubheader = (
  <span>
    Please fill out the following info for the Credit Card that you'd like to use to fund the investment.
    <br />
    <span className="weight-700">
      Your Credit Card will be charged a processing fee of 3.75% on top of your investment amount.
    </span>
  </span>
);

const AddCcAccountButton = ({
  clientId,
  onSuccess,
  onError,
  subHeader = DefaultSubheader,
  buttonElement = null,
  extras = null,
}) => {
  const AddCCModal = React.useContext(PortalizedModalContext);

  const successCallback = React.useCallback((newCcAccount) => {
    if (typeof onSuccess === "function") {
      onSuccess(newCcAccount)
    }
    AddCCModal.closeModal();
  }, [onSuccess, AddCCModal]);

  const errorCallback = React.useCallback((error) => {
    if (typeof onError === "function") {
      onError(error)
    }
  }, [onError]);

  const addCcPaymentMethod = () => {
    AddCCModal.setModalContent(
      `Add Credit Card`,
      <CreditCardPaymentForm
        clientId={clientId}
        onSuccess={successCallback}
        onError={errorCallback}
        subHeader={subHeader}
        extras={extras}
      />,
      'lg'
    );
    AddCCModal.openModal()
  }

  if (buttonElement){
    return React.createElement(buttonElement, {onClick: addCcPaymentMethod});
  }

  return (
    <button
      type='button'
      className="btn-black px-3 py-2 poppins weight-700 fs-9 my-4 mr-sm-3"
      onClick={addCcPaymentMethod}>
      <FaPlus className='mb-1' />&nbsp;&nbsp;Credit Card
    </button>
  )
}

export default AddCcAccountButton;
