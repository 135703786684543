import React, { useEffect, useState } from 'react';
import { history } from 'redux/helpers'
import { AuthContext } from './../../../../contexts/authContext';
import { runGAEvent } from '../../../../pages/components/Tracking';
import { fbEvent } from '../../../../pages/components/Tracking/facebook';
import useGeoInfo from '../../../../lib/useGeoInfo';

export default function InvestmentButton({ offer, user, cssClass = '', openNewWindow = false }) {
  const { Country } = useGeoInfo();
  const { isAdmin } = React.useContext(AuthContext);
  let userLoggedIn = user.hasOwnProperty("id");
  let liveOffer = offer.status === 'live';
  const [navbarInvBtnClass, setNavbarInvBtnClass] = useState('invisible-offer-nav-btn');

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => { window.removeEventListener('scroll', scrollEventListener); }
  }, []);

  const scrollEventListener = () => {
    const offerNavbarOffset = document.getElementById('companyBanner')?.clientHeight + document.getElementById('app-navbar')?.clientHeight;
    const pageScroll = window.pageYOffset;
    if (pageScroll >= offerNavbarOffset) {
      setNavbarInvBtnClass('regd-investment-btn')
    }
    else {
      setNavbarInvBtnClass('invisible-offer-nav-btn')
    }
  }

  // user is admin or offer is not live, not accredited investor.
  if (!userLoggedIn || isAdmin() || !liveOffer || user.investor_type !== 'accredited') {
    return null;
  }

  // Max investor count.
  if (offer.investor_count >= offer.maximum_investor_count) {
    return (<div className="max-goal-reached zeitung-mono f-white">Maximum investor count {offer.maximum_investor_count} reached!</div>);
  }

  // goal reached
  if (Number(offer.deal.raised) >= Number(offer.deal.maximum_goal)) {
    return <div className="max-goal-reached zeitung-mono f-white">Maximum goal reached!</div>
  }

  // When offer is not allowed in the investor country, we disable invest button
  if (userLoggedIn) {
    const currentClient = user.clients.find(client => client.entity_type === 'person');
    const currentInvestorCountry = Country.findByISO2Code(currentClient?.country ?? "US")
    if (currentInvestorCountry.iso2 !== 'US' && !offer.international_investors) {
      return (
        <button disabled={true} type="button" className={`investment-btn ${navbarInvBtnClass} ${cssClass}`}>
          NOT AVAILABLE IN {currentInvestorCountry.name.toUpperCase()}
        </button>
      )
    }
  }

  const handleBtnOnClick = () => {
    runGAEvent(`${offer.company.name} invest button`, "button", "clicked");
    fbEvent(`${offer.company.name} invest button clicked`);

    const individualInvestmentAccount = user.clients.find(c => c.entity_type === 'person');
    let url;

    if (individualInvestmentAccount) {
      if (!user.broker_dealer_terms_accepted) {
        url = `/broker-dealer-terms/offers/${offer.slug}`;
      } else {
        url = `/offers/${offer.slug}/invest/accounts/${individualInvestmentAccount.id}`;
      }
    } else {
      url = `/investor-details/offers/${offer.slug}`;
    }

    if (openNewWindow) {
      window.open(url);
    } else {
      history.push(url);
    }
  };

  // default case user can make a investment
  return (
    <button type="button" className={`investment-btn ${navbarInvBtnClass} ${cssClass}`}
      onClick={handleBtnOnClick}>
      INVEST
    </button>
  )
}
