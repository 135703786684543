import axiosClient from 'config/axiosClient';
import { platformLiveEventApi as admin } from './admin/platformLiveEvent';

export const platformLiveEventApi = {
  fetch,
  list,
  getChatSession,
  viewerCount,
  currentEvent,
  admin
};

function fetch(id) {
  return axiosClient.get(`/platform_live_events/${id}`)
}

function list({ page, perPage }) {
  return axiosClient.get(
    '/platform_live_events',
    { params: { page: page, per_page: perPage } }
  )
}

function getChatSession(id){
  return axiosClient.get(`/platform_live_events/${id}/chat_session`)
}

function viewerCount(id){
   return axiosClient.get(`/platform_live_events/${id}/viewer_count`)
}

function currentEvent(){
  return axiosClient.get(`/platform_live_events/current_event`)
}