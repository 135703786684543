import React from 'react';
import Tooltip from '../../../pages/components/Tooltip/Tooltip';
import { DEAL_TYPE_DEBT, DEAL_TYPE_EQUITY, OFFER_TYPE } from '../../../config/constants';
import AmountWithCurrency from '../../../components/AmountWithCurrency';
import { investmentLimitByInvestorType } from '../../../jsUtils/offerUtils';


function DealInfoRow({ title, description, toolTip }) {
  return (
    <div className="table-row">
      <div className="table-row-col">
        <div className="deal-attribute">
          <div className="deal-attribute-title">{title}</div>
        </div>
        <div className="deal-attribute-value mt-1">{description}</div>
      </div>
      {toolTip && <Tooltip xAlignment="-130px" text={toolTip}><span className="deal-attribute-icon"></span></Tooltip>}
    </div >
  )
}

function DealInfoRowExplainer ({ title, description }) {
  return (
    <div className="table-row">
      <div className="table-row-col">
        <div className="deal-attribute">
          <div className="deal-attribute-title" style={{fontWeight: "700"}}>{title}</div>
        </div>
        <div className="deal-attribute-value mt-1" style={{fontWeight: "400", lineHeight: "1.5"}}>{description}</div>
      </div>
    </div >
  )
}

function DealInfo({ deal }) {
  if (deal?.type === DEAL_TYPE_DEBT) {
    return (
      <>
        <DealInfoRow
          title="Valuation cap"
          description={deal.valuation_cap ? <AmountWithCurrency number={deal.valuation_cap} /> : '-'}
          toolTip="The maximum valuation that your investment will convert into equity. If the conversion happens at a lower price your discount will take precedence over the valuation cap."
        />
        <DealInfoRow
          title="Discount"
          description={deal.discount ? `${deal.discount}%` : '-'}
          toolTip="The discount on valuation you will receive when your debt converts to equity, unless the valuation cap results in a greater discount."
        />
      </>
    )
  }

  if (deal.type === DEAL_TYPE_EQUITY) {
    return (
      <>
        <DealInfoRow
          title="Valuation"
          description={deal.valuation ? <AmountWithCurrency number={deal.valuation} /> : '-'}
          toolTip="A valuation is the total monetary value of the company, it's assets and future growth potential. Valuation is determined by the company."
        />
        <DealInfoRow
          title="Price per share"
          description={
            deal.price_per_share ? <AmountWithCurrency number={deal?.price_per_share} numberFormattingOpts={{ precision: Number.isInteger(Number(deal.price_per_share)) ? 0 : 2 }} /> : '-'
          }
          toolTip="The value of an individual share in the company. Total Valuation / Total outstanding shares."
        />
      </>
    )
  }

  return null;
}

function RegCfInvestmentLimit({ deal, currentInvestorCountry }) {
  const dealInfoMax =  <DealInfoRow title="Max. investment" description={deal.maximum_investment ? <AmountWithCurrency number={deal.maximum_investment} /> : '-'}/>        

  if (currentInvestorCountry && currentInvestorCountry.iso2 === "US") {
    return (
      <>
        <DealInfoRow
          title="Min. individual investment"
          description={deal.minimum_investment ? <AmountWithCurrency number={deal.minimum_investment} /> : '-'}
        />
        <DealInfoRow
          title="Min. entity investment"
          description={deal.entity_minimum_investment ? <AmountWithCurrency number={deal.entity_minimum_investment} /> : '-'}
        />
      {dealInfoMax}     
      </>
    )
  }

  if (currentInvestorCountry && currentInvestorCountry.iso2 !== "US") {
    return (
      <>
      {deal.minimum_international_investment &&
      <DealInfoRow
        title="Min. international investment"
        description={deal.minimum_international_investment ? <AmountWithCurrency number={deal.minimum_international_investment} /> : '-'}
    /> }
      {deal.entity_minimum_international_investment &&
      <DealInfoRow
        title="Min. international entity investment"
        description={deal.entity_minimum_international_investment ? <AmountWithCurrency number={deal.entity_minimum_international_investment} /> : '-'}
    /> } 
      {dealInfoMax}
      </>
      
    )
  }  

  if (!currentInvestorCountry) {
    return null
  }
}

function RegD506bInvestmentLimit({ offer }) {
  const limits = investmentLimitByInvestorType(offer);

  return (limits.map((limit, index) => {
    return (
      <React.Fragment key={index}>
        <DealInfoRow
           title={limit.type === 'spv' ? 'SPV Investment Spots Left' : 'Direct Investment Spots left'}
          description={limit.remainingSpots}
          toolTip={limit.type === 'spv' ? `The remaining spots in the Special Purpose Vehicle (SPV) for ${offer.company.name ?? "the company"}.` : `The remaining spots as a direct investment in ${offer.company.name ?? "the company"}.`}
        />
        <DealInfoRow
          title={limit.type === 'spv' ? 'SPV Investment Minimum' : 'Direct Investment Minimum'}
          description={<AmountWithCurrency number={limit.minAmount ? limit.minAmount : offer.deal.minimum_investment} numberFormattingOpts={{stripZeros: true}} />}
          toolTip={limit.type === 'spv' ? `The minimum amount required to join the SPV for ${offer.company.name ?? "the company"}.` : `The minimum amount required for a direct investment in ${offer.company.name ?? "the company"}`}
        />
      </React.Fragment>
    )
  }))
}

function RegD506bInvestmentExplainer ({offer}) {
  if (!offer) {
    return null;
  }

  if (offer.investor_type === "direct_and_spv")
  return (
    <div>
      <DealInfoRowExplainer
      title={"What is a Direct Investment?"}
      description={`The investment is made directly into the company which means there is a higher potential for reward and also a higher risk. `}
      />    
      <DealInfoRowExplainer
      title={"What is an SPV Investment?"}
      description={`The investment is made into a Special Purpose Vehicle (SPV). An SPV consolidates investments under a legal entity and provides investors with increased legal protection.`}
      />            
    <DealInfoRowExplainer
      title={"Will my investment be Direct or SPV?"}
      description={`An investment qualifies as a direct investment if the amount meets the direct investment minimum and there are available direct investment spots. If either condition is not met, your investment will be allocated to the SPV.`}
      />
    </div>
  )
  if (offer.investor_type === "direct")
  return (
    <DealInfoRowExplainer
    title={"What is a Direct Investment?"}
    description={`The investment is made directly into the company which means there is a higher potential for reward and also a higher risk. `}
    />    
  )  
  if (offer.investor_type === "spv")
  return (
    <DealInfoRowExplainer
    title={"What is an SPV Investment?"}
    description={`The investment is made into a Special Purpose Vehicle (SPV). An SPV consolidates investments under a legal entity and provides investors with increased legal protection.`}
    />         
  )   
  return null 
}

export default function DealTermsSm({ offer, currentInvestorCountry }) {
  const deal = offer.deal;

  if (!deal) {
    return null;
  }

  const debtTypeText = deal.type === DEAL_TYPE_DEBT && deal.security_type === "crowdsafe" ? "SAFE" : "Debt";

  return (
    <div className="side-bar-deal-terms-table mb-4">
      <div className='mb-4'>
        <div className="sidebar-terms-header">
          <div className="header-primary">Investment Info</div>
        </div>
        <div className="table-rows">
          {offer.type === OFFER_TYPE.reg_cf && <RegCfInvestmentLimit deal={deal} currentInvestorCountry={currentInvestorCountry} />}
          {offer.type === OFFER_TYPE.reg_d506b && <RegD506bInvestmentLimit offer={offer} />}
          {offer.type === OFFER_TYPE.reg_d506b && <RegD506bInvestmentExplainer offer={offer} />}
        </div>
      </div>
      <div>
        <div className="sidebar-terms-header">
          <div className="header-primary">Deal Terms</div>
          { offer.type === OFFER_TYPE.reg_cf && 
          <div className="header-secondary">
            <div className="mr-2">How it works</div>
            <Tooltip xAlignment="-130px" text="Unlike reward-based crowdfunding, your investment on Spaced Ventures entitles you to an economic stake in companies. These deal terms explain the details of what this company is offering in exchange for investment.">
              <div className="info-icon"></div>
            </Tooltip>
          </div>}
        </div>
        <div className="table-rows">
          <DealInfoRow
            title="Deal Type"
            description={deal.type === DEAL_TYPE_EQUITY ? 'Equity' : debtTypeText}
            toolTip={deal.type === DEAL_TYPE_EQUITY ? 'An equity deal is an offer to exchange investment for ownership in a company, usually in the form of company shares. ' : 'Debt is a loan from you (the investor) to the company, which is intended to automatically convert to equity in that company at a later date or event.'}
          />
          <DealInfo deal={deal} />
        </div>
      </div>
    </div>
  )
}