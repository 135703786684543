import React, { useContext } from 'react';
import { useUser } from 'redux/user'
import "../../Settings.scss";
import InvestorDetailsForm from '../../../../../admin/components/InvestorDetailsForm';
import { formatSSN, toTitlecase, getTaxIdLabel } from '../../../../../jsUtils/stringUtils'
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import AmlException from '../../../../../admin/components/shared/AmlException';
import PaymentInfo from '../PaymentInfo';
import ClientFieldRow from '../ClientFieldRow';
import CustomAnchorLocation from '../../../../../components/CustomAnchorLocation';
import useGeoInfo from '../../../../../lib/useGeoInfo';
import SuitabilityQuestionaireSection from '../SuitabilityQuestionSection';
import AccreditationVerification from '../../../../../admin/components/shared/AccreditationVerificaton';
import SelfAttestationInfo from '../SelfAttestationInfo';
import SsnEditor from '../../../../../admin/components/InvestorDetailsForm/SsnEditor';

export default function IndividualInvestorInfo() {
  const user = useUser();
  const { Country } = useGeoInfo();
  const investorDetailsModal = useContext(PortalizedModalContext);
  const ssnEditorModal = useContext(PortalizedModalContext);
  const individualClient = user.clients.find(client => client.entity_type === 'person') || {};
  const hasAmlException = individualClient.aml_exception?.id ? true : false;

  const onSubmit = () => {
    investorDetailsModal.closeModal();
  }

  const onSubmitSsnEditor = () => {
    ssnEditorModal.closeModal();
  }

  const openEditModal = () => {
    investorDetailsModal.setModalContent(
      `Individual Investor Details`,
      <InvestorDetailsForm
        submitButtonText="Update"
        investorDetails={individualClient}
        onSubmit={onSubmit}
      />,
      'md'
    );
    investorDetailsModal.openModal();
  }

  const openSsnEditorModal = () => {
    ssnEditorModal.setModalContent(
      `Individual Investor Details`,
      <SsnEditor
        clientId={individualClient.id}
        countryCode={individualClient.country}
        onSubmit={onSubmitSsnEditor}
      />,
      'md'
    );
    ssnEditorModal.openModal();
  }

  return (
    <div id="investor-info">
      <h4 className="weight-900 mb-3 lh-md d-flex flex-wrap align-items-center">
        Individual Investor Details
        {individualClient?.aml_exception?.status === 'cleared' && <span className="custom-badge ml-3 bg-white text-dark border-dark px-3 border">Verified</span>}
      </h4>
      <SelfAttestationInfo user={user} />
      {hasAmlException && (
        <div className="aml-section-wrapper">
          <AmlException
            clientId={individualClient.id}
            clientType={individualClient.entity_type}
          />
        </div>
      )}
      { individualClient?.id && <AccreditationVerification client={individualClient} /> }
      <CustomAnchorLocation link="account-settings-aml-link" />
      <h4 className="m-b-15 fs-18 weight-800">Investor Info</h4>
      <p>Please ensure your personal info is accurate. We use this info to verify your identity.</p>
      <div className={individualClient?.aml_exception?.status === 'failed' ? 'border-warning p-4 pb-2 aml-client-box' : ''}>
        <ClientFieldRow field="Full Legal Name" value={toTitlecase(individualClient.name)} onEdit={openEditModal} />
        <ClientFieldRow field="Birthday" value={individualClient.date_of_birth} onEdit={openEditModal} />
        <ClientFieldRow field={getTaxIdLabel(individualClient.country)} value={individualClient.tax_id_number ? formatSSN(individualClient.tax_id_number, individualClient.country) : "-"} onEdit={openSsnEditorModal} />
        <ClientFieldRow field="Domicile" value={individualClient.domicile} onEdit={openEditModal} />
        <ClientFieldRow field="Address" value={[individualClient.street_address_1, individualClient.city, individualClient.region, individualClient.postal_code].filter(Boolean).join(', ')} onEdit={openEditModal} />
        <ClientFieldRow field="Country" value={individualClient.country ? Country.findByISO2Code(individualClient.country).name : '-'} onEdit={openEditModal} />
        <ClientFieldRow field="Phone number" value={individualClient.phone} onEdit={openEditModal} />
      </div>
      {individualClient.suitability_questionaire_signed_at && <SuitabilityQuestionaireSection client={individualClient} />}
      {individualClient.id && <PaymentInfo name={toTitlecase(individualClient.name)} clientId={individualClient.id} />}
    </div>
  )
}
