import React, { useContext } from 'react'
import { ResponsiveTable } from 'admin/components'
import { format, parseJSON } from 'date-fns'
import { toTitlecase } from 'jsUtils/stringUtils';
import { useQueryClient } from "react-query";
import { FaDownload, FaEye } from "react-icons/fa";
import { useExportCloses } from '../pages/offer/queries';
import ToastMessage from '../../components/ToastMessage';
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../components/AmountWithCurrency';
import PopupModal from './PopupModal';
import TableActionsContainer from './TableActionsContainer';
import TableActionItem from './TableActionItem';
import LinkedData from '../../components/LinkedData';
import { PortalizedModalContext } from '../../contexts/portalizedModalContext';

const DownloadButton = ({offerName, row})=>{
  const close_date = row.original.date;
  const id = row.original.id;
  const file_name = offerName + "-" + close_date;
  const queryClient = useQueryClient();
  const { error } = useExportCloses({ close_id: id, file_name: file_name });

  if(error) {
    ToastMessage.error(error?.message ?? "Something went wrong")
  }

  return(
    <TableActionItem
      onClick={() => queryClient.refetchQueries(['closes', id, 'rollingClosesCsv'])}>
      <FaDownload />
    </TableActionItem>
  )
}

export default function ClosedInvestments({offerName, data}) {
  const closedInvestmentModal = useContext(PortalizedModalContext);
  const openDetailsModal = (row) => {
    closedInvestmentModal.setModalContent(
      'Closed Investment Details',
      <div className="closed-investment-table">
        <ResponsiveTable
          columns={investmentDetailColumns}
          data={row?.original?.investments}
          loading={false}
          loadingMsg="Loading investments..."
          noDataMsg="No investments found."
        />
      </div>,
      'sm'
    )
    closedInvestmentModal.openModal();
  }

  const totalInvsInRollingClose = data?.reduce((total, rollingClose) => total + rollingClose.investments_count , 0);
  const totalInvsSum = data?.reduce((total, rollingClose) => total + parseFloat(rollingClose.closing_sum) , 0);
  const totalAdminFee = data?.reduce((total, rollingClose) => total + parseFloat(rollingClose.total_admin_fee) , 0);

  const TableSummary = () =>{
    return <tr className='tableSummary'>
      <td>Total</td>
      <td>{totalInvsInRollingClose}</td>
      <td></td>
      <td><FormattedNumberWithSymbol number={totalInvsSum}/></td>
      <td><FormattedNumberWithSymbol number={totalAdminFee}/></td>
      <td><FormattedNumberWithSymbol number={(totalInvsSum/100)*92} /></td>
      <td><FormattedNumberWithSymbol number={(totalInvsSum/100)*8} /></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  }
  const investmentDetailColumns = React.useMemo(
    () => {
      return [
        {
          Header: 'Investor name',
          accessor: 'investor_name',
        },
        {
          Header: <>Amount&nbsp;<CurrencyBadge /></>,
          accessor: 'amount',
          Cell: ({value}) => <FormattedNumberWithSymbol number={value}/>
        },
        {
          Header: <>Admin fee&nbsp;<CurrencyBadge /></>,
          accessor: 'admin_fee',
          Cell: ({value}) => <FormattedNumberWithSymbol number={value}/>
        }
      ]
    }, [])

  const closedInvestmentColumns = React.useMemo(
    () => {
      return [
        {
          Header: 'Closing date',
          accessor: 'date',
          Cell: ({row, value}) => (
            <LinkedData onClick={() => openDetailsModal(row)}>
              { format(new Date(value ? value+'T00:00' : null), 'MMM dd yyyy') }
            </LinkedData>
          )
        },
        {
          Header: 'Inv count',
          accessor: 'investments_count'
        },
        {
          Header: 'Type',
          accessor: 'type'
        },
        {
          Header: <>Closing sum&nbsp;<CurrencyBadge /></>,
          accessor: 'closing_sum',
          Cell: ({value}) => <FormattedNumberWithSymbol number={value} />

        },
        {
          Header: <>Admin fee&nbsp;<CurrencyBadge /></>,
          accessor: 'total_admin_fee',
          Cell: ({value}) => <FormattedNumberWithSymbol number={value} />

        },
        {
          Header: <>92%&nbsp;<CurrencyBadge /></>,
          accessor: 'closing_92_percentage',
          Cell: ({row, value}) => <FormattedNumberWithSymbol number={(row.original.closing_sum/100)*92}/>

        },
        {
          Header: <>8%&nbsp;<CurrencyBadge /></>,
          accessor: 'closing_8_percentage',
          Cell: ({row, value}) => <FormattedNumberWithSymbol number={(row.original.closing_sum/100)*8} />
        },
        {
          Header: 'Notice sent at',
          accessor: 'notification_sent_at',
          Cell: ({value}) => value ? format(parseJSON(value), 'MMM dd yyyy') : 'Pending'
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({value}) => toTitlecase(value)
        },
        {
          Header: "",
          accessor: "id",
          Cell: ({ row, value }) => (
            <TableActionsContainer>
                <DownloadButton offerName={offerName} row={row} />
                <TableActionItem onClick={() => openDetailsModal(row)}>
                  <FaEye />
                </TableActionItem>
            </TableActionsContainer>
          ),
        },
      ]
    }, []);

  return (
    <>
      <ResponsiveTable
        columns={closedInvestmentColumns}
        data={data}
        tableSummary = {<TableSummary />}
        loading={false}
        classes={'use-4xl-width'}
        loadingMsg="Loading closes..."
        noDataMsg="No closes found."
      />
    </>
  )
}