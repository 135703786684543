import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from 'react-query';
import { useCurrentOffer, fetchOffer } from 'redux/offers'
import { useUser } from 'redux/user'
import LoadingOverlay from 'react-loading-overlay';
import { history } from 'redux/helpers';
import HookForm from '../components/HookForm';
import { formatDate } from '../jsUtils/dateTimeUtils';
import { updateBrokerDealerTerms } from '../redux/user';
import { renderRailsErrors } from '../jsUtils/railsErrorRenderer';
import { acceptBrokerDealerTerms } from '../investor/queries';

function SignatureForm({ signatureValue }) {
  const [signatureAccepted, setSignatureAccepted] = useState(false);

  const onAcceptSignature = () => {
    if (signatureValue && signatureValue.trim().length > 0 && !signatureAccepted) {
      setSignatureAccepted(true);
    } else {
      setSignatureAccepted(false);
    }
  }

  return (
    <div>
      <h5 className="fs-default poppins weight-700 my-3">Confirm receipt and consent to electronic delivery:</h5>
      <div className="row">
        <div className="col-12">
          <HookForm.TextInput
            name="full_name"
            placeholder="Full name"
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <div className="form-label-group">
            <input
              type="text"
              name="signed_at_display"
              id="signed_at_display"
              placeholder="Date of Signature "
              className="form-control"
              defaultValue={formatDate(new Date(), 'MM/dd/yyyy')}
              readOnly={true}
            />
            <label htmlFor="signed_at_display">Date of Signature</label>
          </div>
        </div>
      </div>
      <div className="row signature-box">
        <div className="col-7">
          <HookForm.TextInput
            name="signature"
            placeholder="Signature"
            inputStyle={`signature-input ${signatureAccepted ? 'is-valid' : ''}`}
            hint="Please accept signature"
          />
        </div>
        <div className="col-5">
          <button type="button" className={`mx-auto btn-black-outline btn-signature rounded-0 weight-600 accept-signature-text ${signatureAccepted ? 'signature-accepted' : ''}`} onClick={onAcceptSignature}>
            {signatureAccepted ? 'Signature Accepted' : 'Accept Signature'}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ml-0 mt-2">
          <button type="submit" className="mx-auto btn-login px-5" disabled={!signatureAccepted}>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default function BrokerDealerTerms() {
  const { offerId } = useParams();
  const offer = useCurrentOffer();
  const user = useUser();

  useEffect(() => {
    if (offerId && offer?.slug !== offerId) {
      fetchOffer(offerId)
    }
  }, [offerId])

  const schema = Yup.object().shape({
    signature: Yup.string().required()
  });

  const mutation = useMutation(acceptBrokerDealerTerms,
    {
      onSuccess: (values) => {
        updateBrokerDealerTerms(values);

        if (values.broker_dealer_terms_accepted) {
          console.log(values)
          const individualInvestmentAccount = user.clients.find(c => c.entity_type === 'person');
          history.push(`/offers/${offer.slug}/invest/accounts/${individualInvestmentAccount.id}`);
        }
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  );

  const onSubmit = (values) => {
    return mutation.mutate(values);
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      full_name: user?.name,
      signature: user?.name
    },
  });

  const watchSignature = methods.watch("signature", user?.name);

  return (
    <>
      <LoadingOverlay
        active={user.loading}
        spinner
        text='Loading ...' >
        <div className="investment-header">
          <div className="container pb-3 pt-first-section">
            <h1 className="fs-2 weight-900 text-white mb-0">
              {offerId && <>Invest in <Link to={`/offers/${offerId}`} target="_blank" rel="noopener noreferrer">{offer.company?.name}</Link> </>}
            </h1>
          </div>
        </div>
        <div className="limit-calc-wrapper limit-calc-screen" >
          <div className="limit-calc-screen-header" style={{paddingBottom: '0px'}}>
            <h5 className="montserrat fs-6 weight-900 color-black">Dalmore Group Consent for Electronic Delivery</h5>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row limit-calc-content-row">
                <div className="limit-calc-content px-3 poppins">
                  <div className="hint zeitung-micro weight-600 mb-2 fs-9 instruction-text-for-mobile">
                    <a href="https://dalmorefg.com/" target="_blank" rel="noopener noreferrer">Dalmore Group</a> is the broker dealer for Reg D offers on Spaced Ventures
                  </div>
                  <div className='mb-2 fs-12 lh-lg'>
                  From time to time, the DALMORE GROUP or their advisors may be required to deliver certain
documents to clients including regulatory disclosures. Examples of such disclosures include but are
not limited to Reg BI, Form CRS, Form ADV, the firm’s Privacy Policy, and the firm’s Business
Continuity Summary. I hereby consent to the use of electronic means, such as email, to make such
delivery. This delivery may include notification of the availability of such document(s) on a website,
and I agree that such notification will constitute “delivery.” There may be potential costs with
electronic delivery, such as on-line charges.
                  </div>
                  <div className='mb-2 fs-12 lh-lg'>
                  I further agree to provide the FIRM or my advisor with an email address and to keep this information
current at all times by promptly notifying Advisor of any change in email address.
                  </div>
                  <div className="mb-2 fs-12 lh-lg">
                  This consent will remain in effect until revoked in writing to DALMORE GROUP located at 530 7th
Avenue, Suite 902, New York, NY 10018
                  </div>
                            
                  <SignatureForm signatureValue={watchSignature} />
                  <div className='fs-12 mt-3 text-muted'>
                      For more info on Dalmore please review: <a className="text-muted" href="https://spacedventure-marketing.s3.amazonaws.com/Customer-Relationship-Summary-Dalmore-Group-LLC.pdf" target="_blank" rel="noopener noreferrer">Customer Relationship Summary</a>, <a className="text-muted" href="https://spacedventure-marketing.s3.amazonaws.com/Regulation-Best-Interest-Disclosure-Dalmore-Group-LLC.pdf" target="_blank" rel="noopener noreferrer">Regulation Best Interest Disclosure</a>, <a className="text-muted" href="https://spacedventure-marketing.s3.amazonaws.com/Privacy-Policy-Dalmore-Group-LLC.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a className="text-muted" href="https://spacedventure-marketing.s3.amazonaws.com/+Patriot-Act-Disclosure-Dalmore-Group-LLC.pdf" target="_blank" rel="noopener noreferrer">Patriot Act Disclosure</a>, <a className="text-muted" href="https://spacedventure-marketing.s3.amazonaws.com/Business-Continuity-Summary-Dalmore-Group-LLC.pdf" target="_blank" rel="noopener noreferrer">Business Continuity Summary</a>
                    </div>       
                </div>
                <div className='limit-calc-sidebar px-3'>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </LoadingOverlay>
    </>
  );
}
