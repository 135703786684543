import React from 'react';
import EditHeader from 'admin/components/EditHeader'
import Form from './Form';
import { useHistory } from 'react-router-dom';

export default function EventCreator() {
  const history = useHistory();

  const afterCreate = (liveEvent) => {
    history.replace(`/admin/platform-live-events/${liveEvent.slug}/edit`);
  }

  return (
    <>
      <EditHeader left="Create Event" />
      <div className="settings-tab-box">
        <div className="settings-tab-box-nav">
          <ul className="nav nav-tabs" id="event-edit-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="event-info-tab" data-toggle="tab" href="#event-info-form" role="tab">Overview</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="company-edit-tab-content">
          <div className="tab-pane fade show active" id="event-info-form" role="tabpanel">
            <Form liveEvent={{}} afterCreate={afterCreate}/>
          </div>
        </div>
      </div>
    </>
  )
}
