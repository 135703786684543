import React from 'react';
import PropTypes from 'prop-types';
import { rollbarInstance } from '../jsUtils/rollbar';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // You can also log error messages to an error reporting service here
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    rollbarInstance.error('Error', { error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="card">
          <div className="card-header">
            <h4 className='text-danger'>Something went wrong.</h4>
            <span style={{ cursor: 'pointer', color: '#0077FF' }}
              onClick={() => { window.location.reload();}}
            >
              Click to reload this page.
            </span>
          </div>
          <div className="card-body">
            <details className="error-details">
              <summary>Click for error details</summary>
              <pre className='m-1 pl-4'>{this.state.error && JSON.stringify(this.state.error, null, 2)}</pre>
              <pre>{this.state.errorInfo.componentStack}</pre>
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default ErrorBoundary;