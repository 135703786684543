import React from 'react';
import ReactTooltip from 'react-tooltip';
import { toTitlecase } from '../../../../src/jsUtils/stringUtils';

export default function Customer({customer}) {
  const customerToolTipText =  `${customer.name} - ${toTitlecase(customer.status?.replace(/_/g, ' '))}`;
  const customerShortHand = {
    'recurring': 'Recurring',
    'sales': 'Sales',
    'pilot': 'Pilot',
    'letter_of_intent': 'LOI',
    'government_contract': "Gov't Contract",
    'discovery': 'Discovery',
  }
  return(
    <div className="single-customer">
        <div data-for="customerTooltip" data-tip={customerToolTipText} className="single-customer-img-box">
          <img src={customer.logo_url} alt={customer.name} />
        </div>
      <ReactTooltip id="customerTooltip" effect="solid" place="right" offset={{'right': 0}} backgroundColor="#f5f5f5" textColor="#111" border={true} className="tooltip-width" />
      <span className="customer-department text-capitalize">{customerShortHand[customer.status] || customer.status?.replace(/_/g, ' ')}</span>
    </div>
  )
}