import React from 'react';
import Tooltip from './Tooltip/Tooltip';


export default function InvestmentDetailTableRow({ label, value, tooltip, isExternalLink = false, icon = false }) {
  if (!value) {
    return null;
  }

  return (
    <div className="table-row">
      <div className="deal-attribute">
        {tooltip &&
          <div className="deal-terms-tooltip">
            <Tooltip text={tooltip}><span className="deal-attribute-icon"></span></Tooltip>
          </div>
        }
        <span className="deal-attribute-title">{label}</span>
        {icon && <span className="deal-attribute-title-icon">{icon}</span>}
      </div>
      <span className="deal-attribute-value">{value ? value : '-'}</span>
    </div>
  )
}
