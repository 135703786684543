import React from 'react';
import AmountWithCurrency from '../../components/AmountWithCurrency';

export default function InvestmentSummary({ investingAs, amount }){
  return(
     <>
      <h5 className="montserrat weight-700 m-b-15">Summary</h5>
      <div className="investment-summary">
        <span className="left">Investing as</span>
        <span className="right">{investingAs}</span>
      </div>
       <div className="investment-summary">
        <span className="left">Amount</span>
        <span className="right"><AmountWithCurrency number={amount} /></span>
      </div>
    </>
  )
}
