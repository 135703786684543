import React, { useEffect } from 'react'
import styled from 'styled-components';
import { runGAEvent } from "../components/Tracking/index";

export default function ButtonWithoutIcon({  
    link,
    width = "100%", 
    text = "Sign up to Learn More", 
    align = "center",
    eventCategory = "newsletter signup", 
    eventAction = "newsletter cta", 
    eventLabel = "cta",
    newWindow = false
  }){


if (newWindow) {
  return (
    <ButtonWithoutIconContainer width={width} align={align}>
    <a target="_blank" rel="noopener noreferrer" className="link-decor-none" href={link ? link : ""}>
      <button onClick={() => runGAEvent(eventCategory, eventAction, eventLabel)} className="poppins weight-600 btn-black">
        <div className="newsletter-btn">
          <div className="newsletter-btn-text">{text}</div>
        </div>
      </button>
    </a>
  </ButtonWithoutIconContainer>  
  ) 
  } else {
  return (
    <ButtonWithoutIconContainer width={width} align={align}>
      <a className="link-decor-none" href={link ? link : ""}>
        <button onClick={() => runGAEvent(eventCategory, eventAction, eventLabel)} className="poppins weight-600 btn-black">
          <div className="newsletter-btn">
            <div className="newsletter-btn-text">{text}</div>
          </div>
        </button>
      </a>
    </ButtonWithoutIconContainer>  
  )
  }
}


const ButtonWithoutIconContainer = styled.div`
  button {
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 2px;
      text-align: ${props => props.align};
      padding-right: 25px;
      padding-left: 25px;
  }

`;