import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';
import { downloadFile } from 'jsUtils/fileUtils';

const useSpacedAdminList = ({ page = 1, per_page = 10 }) => {
  return useQuery(
    ['spacedAdminList', page],
    () => {
      return axiosClient
        .get(`/admin/admins?&page=${page}&per_page=${per_page}`)
    }, {
    keepPreviousData: true,
  }
  );
}

const deleteSpacedAdmin = ({ role_id }) => {
  return axiosClient.delete(`/admin/admins/${role_id}`)
}

const useAdminInvitesList = ({ page = 1, per_page = 10 }) => {
  return useQuery(
    ['spacedAdminInvitesList', page],
    () => {
      return axiosClient
        .get(`/admin/invites?&page=${page}&per_page=${per_page}`)
    }, {
    keepPreviousData: true,
  }
  );
}

const useCompanyAccountInvitesList = ({ company_id, page = 1, per_page = 10 }) => {
  return useQuery(
    ['companyAccountInvitesList', company_id, page],
    () => {
      return axiosClient
        .get(`/admin/companies/${company_id}/managers/invites?&page=${page}&per_page=${per_page}`)
    }, {
    keepPreviousData: true,
  }
  );
}

const useCompanyManagersList = ({ company_id, page = 1, per_page = 10 }) => {
  return useQuery(
    ['companyManagersList', company_id, page],
    () => {
      return axiosClient
        .get(`/admin/companies/${company_id}/managers?&page=${page}&per_page=${per_page}`)
    }, {
    keepPreviousData: true,
  }
  );
}

const deleteCompanyManager = ({ company_id, role_id }) => {
  return axiosClient.delete(`/admin/companies/${company_id}/managers/${role_id}`)
}

const createUserInvite = ({ values }) => {
  return axiosClient
    .post("/user_invitations", values)
}

const updateAdminRole = ({ id, values }) => {
  return axiosClient
    .put(`/admin/admins/${id}`, values)
}

const updateCompanyAccountRole = ({ company_id, id, values }) => {
  return axiosClient
    .put(`/admin/companies/${company_id}/managers/${id}`, values)
}


const deleteUserInvite = ({ slug }) => {
  return axiosClient
    .delete(`/user_invitations/${slug}`)
}

const useExportOfferInvestorsList = ({ offer_id, type, opts = { enabled: false } }) => {
  return useQuery(
    ['offer', offer_id, 'export-investors', type],
    () => {
      return axiosClient
        .get(`/offers/${offer_id}/investors.csv`, { params: { type } })
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `offer-${offer_id}-investors-${type}.csv`)
      }
    }
  );
}

const useAmlException = ({ investment_id }) => {
  return useQuery(
    ['aml-exceptions', investment_id],
    () => {
      return axiosClient
        .get(`/investments/${investment_id}/aml_exception`)
    }
  );
}

const useClientDocuments = ({ client_id }) => {
  return useQuery(
    ['client-documents', client_id],
    () => {
      return axiosClient
        .get(`/clients/${client_id}/entity_documents`)
    }, {
    enabled: !!client_id
  }
  );
}

const removeCompanyLogo = ({ company_id }) => {
  return axiosClient.delete(`/companies/${company_id}/remove_logo`);
}

const removeCompanyBanner = ({ company_id }) => {
  return axiosClient.delete(`/companies/${company_id}/remove_banner`);
}

const confirmEmail = ({ userId }) => {
  return axiosClient.post(`/admin/users/${userId}/confirm`);
}

const bulkSendReminderEmail = (payload) => {
  return axiosClient.post('admin/users/confirmation_reminder', payload);
}

const exportInvestorDocumemtPackageUrl = (offer_id) => {
  return axiosClient.getUri({
    url: `admin/offers/${offer_id}/export_client_documents`
  });
}

const useResourceDocuments = ({ resourceType, resourceId }) => {
  return useQuery(
    ['resourceDocuments', resourceType, resourceId],
    () => {
      return axiosClient
        .get("/admin/resource_documents", { params: { resource_type: resourceType, resource_id: resourceId } })
    },
  );
}

const createResourceDocument = ({ resourceType, resourceId, values }) => {
  return axiosClient.post(
    "/admin/resource_documents",
    values,
    {
      params: { resource_type: resourceType, resource_id: resourceId },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

const deleteResourceDocument = (documentId) => {
  return axiosClient.delete(`/admin/resource_documents/${documentId}`)
}

const getMaterialChanges = (offer_slug) => {
  return axiosClient.get(`/admin/offers/${offer_slug}/material_changes`)
}

const createMaterialChange = ({offer_slug, values}) => {
  return axiosClient
    .post(`/admin/offers/${offer_slug}/material_changes`,values)
}

const acceptMaterialChange = (id) => {
  return axiosClient.patch(`/material_change_announcements/${id}/accept/`)
}

const sendMaterialChangeReminder = (id) => {
  return axiosClient.post(`/admin/material_changes/${id}/send_reminder`)
}

const getAllMaterialChangeAnnoucements = (id) => {
  return axiosClient.get(`/admin/material_changes/${id}/material_change_announcements.json`)
}

const cancelAllMaterialChange = (id) => {
  return axiosClient.post(`/admin/material_changes/${id}/cancel_investments`)
}

const useExportFollowersList = (companyId, opts = { enabled: false }) => {
  return useQuery(
    ['company', companyId, 'exportfollowersList'],
    () => {
      return axiosClient.get(`/companies/${companyId}/followers.csv`)
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `company-${companyId}-followers.csv`)
      }
    }
  )
}

const archiveUser = ({userId, values}) => {
  return axiosClient.patch(`/admin/users/${userId}/archive`, values)
}

const deleteUser = ({userId}) => {
  return axiosClient.delete(`/admin/users/${userId}`)
}

const unArchiveUser = ({userId}) => {
  return axiosClient.patch(`/admin/users/${userId}/restore`)
}

export {
  useSpacedAdminList,
  deleteSpacedAdmin,
  useAdminInvitesList,
  useCompanyManagersList,
  deleteCompanyManager,
  updateCompanyAccountRole,
  useCompanyAccountInvitesList,
  createUserInvite,
  updateAdminRole,
  deleteUserInvite,
  useExportOfferInvestorsList,
  useAmlException,
  useClientDocuments,
  removeCompanyLogo,
  removeCompanyBanner,
  confirmEmail,
  bulkSendReminderEmail,
  exportInvestorDocumemtPackageUrl,
  useResourceDocuments,
  createResourceDocument,
  deleteResourceDocument,
  getMaterialChanges,
  createMaterialChange,
  acceptMaterialChange,
  sendMaterialChangeReminder,
  getAllMaterialChangeAnnoucements,
  cancelAllMaterialChange,
  useExportFollowersList,
  archiveUser,
  deleteUser,
  unArchiveUser
};
