import React from 'react';
import styled from 'styled-components'

const previewOfferNames = [
  {
    company: "Astrea",
    preview: "Geospatial Analytics"
  },
  {
    company: "Equatorial Space",
    preview: "Hybrid Rocket Tech"
  }, 
  {
    company: "Canopy",
    preview: "Thermal Protection"
  },         
];

const OfferCardPreview = ({offerName, showName = true}) => {
  return ( 
    <>
      <ComingSoonStyles>
        <div className="coming-soon-offer-card-banner">
          <div className="coming-soon-offer-card-banner-top">
            <div className="coming-soon-badge-container">
              <div className="weight-400 fs-8 montserrat preview-offer-card-text">Coming soon</div>
            </div>
            <div>
              <div className="coming-soon-offer-card-thumb"></div>
            </div>
          </div>
          <div className="coming-soon-offer-card-banner-content">
            {showName ? <h4 className="weight-700 fs-24 montserrat preview-offer-card-text">{offerName}</h4> : <div style={{ marginBottom: '10px', height: '30px', width: '280px', backgroundColor: '#DDD', borderRadius: '2px'}}></div>}
            
            <div className="coming-soon-offer-card-badge-container">
                <div className="card-badge-layout">
                  <div className="card-badge-preview mb-2 mr-2"></div>
                  <div className="card-badge-preview mb-2 mr-2"></div>
                </div>
            </div>
          </div>
        </div>
        <div className="coming-soon-offer-card-text-container coming-soon-offer-card-text-padding">
          <div>
            <div style={{ height: '20px', width: '200px', backgroundColor: '#F7F7F7', borderRadius: '2px'}}></div>
            <div style={{ height: '20px', width: '280px', backgroundColor: '#F7F7F7', marginTop: '19px', borderRadius: '2px'}}></div>
            <div style={{ height: '20px', width: '280px', backgroundColor: '#F7F7F7', marginTop: '7px', borderRadius: '2px'}}></div>
            <div style={{ height: '20px', width: '250px', backgroundColor: '#F7F7F7', marginTop: '7px', borderRadius: '2px'}}></div>      
          </div>
          <div>
            <div style={{ height: '12px', width: '280px', backgroundColor: '#F7F7F7', borderRadius: '2px'}}>
              <div style={{ height: '100%',  width: '80px', backgroundColor: '#F7F7F7'}}></div>
            </div>
          </div>
        </div>
      </ComingSoonStyles>
    </>    
   );
}

const OfferPreviewCardsArray = ({showName = true}) => {
  let cards = []

  cards = previewOfferNames.map((offer, i) => {
    return <OfferCardPreview key={i} offerName={offer.preview} showName={showName} />
  } )

  return cards;
}

const OfferCardPreviewRow = ({numberOfCardsToShow = 3}) => {
  let offerCards = []
  for (let i = 0; i < numberOfCardsToShow; i++ ) {
    offerCards.push(<OfferCardPreview showName={false} />)
  }
  return offerCards
}

export default OfferCardPreview;

export {
  OfferPreviewCardsArray,
  OfferCardPreviewRow
};

const ComingSoonStyles = styled.div`
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 20px #00000029;
  -moz-box-shadow: 0px 10px 20px #00000029;
  box-shadow: 0px 10px 20px #00000029;
  /* position: relative; */
  background: #fff;
  /* height: 350px; */

  .coming-soon-offer-card-banner {
    height: 375px;
    background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }

  .coming-soon-offer-card-banner-top {
    display: flex;
    justify-content: space-between;
    padding: 23px 23px 0px;
  }

  .coming-soon-badge-container {
    background-color: #fff;
    width: 140px;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  .coming-soon-offer-card-banner-content {
  padding: 20px 24px 8px;
    }  

  .coming-soon-offer-card-thumb {
    width: 65px;
    height: 65px;
    // border: 1px solid #111;
    background: #DDDDDD;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
  }
  
    .coming-soon-offer-card-thumb-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
  }

  .coming-soon-offer-card-text-container {
      height: 196px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  .offer-card-vert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .coming-soon-offer-card-text-padding {
    padding: 24px;
  }

  .preview-badge-1 {
    width: 30px;
    height: 30px;
  }

  .preview-offer-card-text {
    color: #DDDDDD;
  }

  // Card Badge
  .card-badge-preview {
    font-size: 11px;
    border-radius: 2px;
    background-color: #DDDDDD;
    padding: 3px 8px;
    height: 22px;
    width: 78px;
    display: inline-block;
    font-weight: 700;

    .card-badge-preview-layout {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }  
`;

export function SimpleComingSoonCard () {
  return (
    <SimpleComingSoonStyles>
    <div className="coming-soon-offer-card-banner">
      <div className="coming-soon-offer-card-banner-top">
        <div className="coming-soon-badge-container">
          <div className="weight-400 fs-8 montserrat preview-offer-card-text"></div>
        </div>
      </div>
    </div>
    <div className="coming-soon-offer-card-banner-content">
        <div style={{ marginBottom: '10px', height: '30px', width: '280px', backgroundColor: '#DDD', borderRadius: '2px'}}></div>        
    </div>
    <div className="coming-soon-offer-card-text-container coming-soon-offer-card-text-padding">
      <div>
        <div style={{ height: '20px', width: '280px', backgroundColor: '#F7F7F7', marginTop: '19px', borderRadius: '2px'}}></div>
        <div style={{ height: '20px', width: '280px', backgroundColor: '#F7F7F7', marginTop: '7px', borderRadius: '2px'}}></div>
        <div style={{ height: '20px', width: '250px', backgroundColor: '#F7F7F7', marginTop: '7px', borderRadius: '2px'}}></div>      
      </div>
    </div>
  </SimpleComingSoonStyles>
  )
}


export const SimpleOfferCardPreviewRow = ({numberOfCardsToShow = 3}) => {
  let offerCards = []
  for (let i = 0; i < numberOfCardsToShow; i++ ) {
    offerCards.push(<SimpleComingSoonCard />)
  }
  return offerCards
}

const SimpleComingSoonStyles = styled.div`
    border-radius: 8px;
    overflow: hidden;
    -webkit-box-shadow: 0px 10px 20px #00000029;
    -moz-box-shadow: 0px 10px 20px #00000029;
    box-shadow: 0px 10px 20px #00000029;
    background: #fff;

  .coming-soon-offer-card-banner {
    height: 220px;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .coming-soon-offer-card-banner-top {
    display: flex;
    justify-content: space-between;
    padding: 23px 23px 0px;
  }
  .coming-soon-offer-card-banner-content {
    padding: 20px 24px 8px;
    }  
  .coming-soon-offer-card-text-container {
    height: 196px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .coming-soon-offer-card-text-padding {
    padding: 0px 24px 24px 24px;
  }
  .preview-offer-card-text {
    color: #DDDDDD;
  }
`;