import React from 'react';
import {
  RichEditor,
  createEditorStateWithContent
} from 'admin/components';

import "./ArticleContent.scss";

const ArticleContent = ({content, sectionName=null}) => {
    const editorState = createEditorStateWithContent(content)
    const hasContent = editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0

    if(hasContent){
      return (
        <RichEditor
          editorState = { editorState }
          readOnly={true}
        />
      )
    } else {
      return (
        <div className="comming-soon m-t-25">
          Coming soon.
        </div>
      );
    }
}

export default ArticleContent;