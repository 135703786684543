import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { useOfferSubDocTemplate, upsertOfferSubDocTemplate } from "./queries";
import ToastMessage from "components/ToastMessage";
import { FaExternalLinkAlt } from 'react-icons/fa';

export default function SubDocTemplates({ offer }) {
  if (!offer) return null;

  const requiredSpvSubscriotionTemplate = offer.investor_type === 'direct_and_spv';

  return (
    <>
      <SubDocTemplate offer={offer} templateId={offer.subscription_agreement_template_id} />
      {
        requiredSpvSubscriotionTemplate && <>
          <hr />
          <SubDocTemplate offer={offer} templateId={offer.spv_subscription_agreement_template_id} investorType="spv" />
        </>
      }
    </>
  );
}

function SubDocTemplate({ offer, templateId, investorType }) {
  const [subDocTemplateId, setSubDocTemplateId] = useState(templateId);
  const queryClient = useQueryClient();
  const schema = Yup.object().shape({
    template_id: Yup.string().required("Docusign template id is required."),
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      template_id: '',
    },
  });

  const mutation = useMutation(upsertOfferSubDocTemplate, {
    onSuccess: (data) => {
      methods.reset();
      queryClient.setQueryData(['offer', 'subdoc', offer.slug, investorType], data);
      setSubDocTemplateId(data.template_id);
      ToastMessage.success('Offer subscription agreement template successfully saved.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  });

  const onSubmit = async (values) => {
    mutation.mutate({
      offer_id: offer.slug,
      investor_type: investorType,
      values: values
    })
  };

  if (!offer) return null;

  let title = 'Subscription Agreement Template';

  if (investorType) {
    title = `${title} (${investorType})`;
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-input-label fs-9">{title}</label>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TextField name="template_id" placeholder="DocuSign Sub Doc Template ID" type="text" />
          <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={mutation.isLoading}>
            {mutation.isLoading ? 'Saving...' : 'Save'}
          </button>
        </form>
      </FormProvider>
      {subDocTemplateId && <ExistingSubDocTemplate offerId={offer.slug} investorType={investorType} />}
    </>
  );
}

function ExistingSubDocTemplate({ offerId, investorType }) {
  const { data: subDocTemplate, loading, error } = useOfferSubDocTemplate({
    offer_id: offerId, investor_type: investorType
  });

  if (error) {
    return (
      <div className="px-3 text-center">Something went wrong. Please try again later.</div>
    )
  }

  if (loading) {
    return (
      <div className="px-3 text-center">Loading...</div>
    )
  }

  if (!subDocTemplate) {
    return null;
  }

  let title = 'Current Subscription Agreement Template';

  if (investorType) {
    title = `${title} (${investorType})`;
  }

  return (
    <div className='mt-3'>
      <div className="mb-1">
        <label className="form-input-label fs-9">{title}</label>
      </div>
      <div className="file-bar2 mt-1">
        <div className="d-flex align-items-center docs-file-link poppins py-1">
          <span className='weight-600 mr-1'>Docusign Template Id:</span>
          <span className='poppins'>{subDocTemplate.docusign_template_id}</span>
        </div>
      </div>
      <div className="file-bar2 mt-1">
        <div className="d-flex align-items-center docs-file-link poppins">
          <a href={subDocTemplate.template_url} target="_blank" rel="noopener noreferrer" className='py-1'>
            Document URL<FaExternalLinkAlt className='ml-1 mb-1' />
          </a>
        </div>
      </div>
    </div>
  );
}

