import React from "react";
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';

export default function CompanySection({ label, content }) {
  if(!content) return null;

  return (
    <div id={label} className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={label} />
        <div className="pitch-section-body">
          <div className="summary-inner">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}