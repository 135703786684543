import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ErrorMessage from './ErrorMessage';
import { getTimeZone } from '../../jsUtils/dateTimeUtils';

const DateTimeField = ({
  name,
  placeholder,
  showTimeSelect = true,
  showYearSelect = false,
  showMonthSelect = false,
  dateFormat = 'MM/dd/yyyy h:mm aa',
  showTimeZoneHint = true
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        let value = field.value;

        if (!(value instanceof Date)) {
          value = value ? new Date(value) : '';
        }

        return (
          <div className='form-label-group react-datetime-group'>
            <DatePicker
              id={field.name}
              autoComplete='off'
              selected={value}
              dateFormat={dateFormat}
              onChange={(date) => field.onChange(date)}
              showTimeSelect={showTimeSelect}
              showMonthDropdown={showMonthSelect}
              showYearDropdown={showYearSelect}
              dropdownMode="select"
              className={`date-picker form-control ${error?.message ? 'is-invalid' : ''}`}
            />
            {placeholder && <label htmlFor={field.name}>{placeholder}</label>}
            <ErrorMessage error={error} />
            {showTimeZoneHint && <p className='fs-11 text-muted'>Your Timezone: {getTimeZone()}</p>}
          </div>
        )
      }}
    />
  )
}

DateTimeField.defaultProps = {};

DateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showTimeSelect: PropTypes.bool,
  showYearSelect: PropTypes.bool,
  showMonthSelect: PropTypes.bool,
  dateFormat: PropTypes.string
};

export default DateTimeField;