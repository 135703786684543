import React from 'react';
import PropTypes from 'prop-types';
import { useTabs } from 'components/ReactTabs'

function VerticalTabs({
  tabs,
  tabIdAccessor,
  targetPanelIdAccessor,
  defaultTabId,
  tabRenderer = (tab) => tab.title,
  contentRenderer = (tab) => tab.content,
}) {
  const defaultTab = defaultTabId || tabs[0]?.[tabIdAccessor]
  const {
    activeTab,
    tabs:tablist,
    getContainerProps,
    getTabProps,
    getPanelProps,
  } = useTabs({tabs, tabIdAccessor, targetPanelIdAccessor, defaultTabId: defaultTab})

  return (
    <div className="pitch-editor" {...getContainerProps}>
      <div className="px-md-3 px-0 pitch-sidenav">
        <ul className="nav flex-md-column nav-tabs" id="nav-tab">
          {
            tablist.map(tab => (
              <li className="nav-item" {...getTabProps(tab[tabIdAccessor])}>
                <span className={`nav-link ${activeTab === tab[tabIdAccessor] ? 'active': ''}`}>
                  { tabRenderer(tab) }
                </span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="px-md-3 px-0 pitch-right-content">
        <div className="tab-content" id="v-pills-tabContent">
          {
            tablist.map(tab => (
              <div className={`tab-pane ${ activeTab === tab[tabIdAccessor] ? 'show active' : 'fade'}`} {...getPanelProps(tab[tabIdAccessor])}>
                { contentRenderer(tab) }
              </div>    
            ))
          }
        </div>
      </div>
    </div>
  );
}

VerticalTabs.defaultProps = {};

VerticalTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string,
  tabRenderer: PropTypes.func,
  contentRenderer: PropTypes.func,
};

export default VerticalTabs;