import React from 'react';
import { Formik, Form } from 'formik';
import { useUser, updateInvestmentTerms } from "../redux/user"
import * as Yup from 'yup';
import '.././css/buttons.scss';
import styled from 'styled-components';
import "./Page.Disclaimer.scss";
import LoadingOverlay from 'react-loading-overlay';
import { Prompt } from 'react-router-dom';

const DisclaimerSchema = Yup.object().shape({
  risk_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  limited_transfer_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  diversification_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  cancellation_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  limits_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  research_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
  electronic_delivery_acceptance: Yup.boolean().oneOf([true], "Must Accept"),
});

const DisclaimerPage = () => {
  const user = useUser();

  const disclaimers = [
    {
      title: "Risk",
      body: "Investing in startups is very risky. You should only invest an amount you can afford to lose completely without changing your lifestyle.",
      disclaimer_text: "I understand that I can lose the money I'm investing",
      key: "risk_acceptance"
    },
    {
      title: "Limited Transfer",
      body: "It may be difficult to transfer the securities acquired on Spaced Ventures. In addition, there are federal restrictions on selling, trading, or transferring the securities for the first 12 months and potentially additional state and foreign restrictions. Some securities may not allow transfers altogether.",
      disclaimer_text: "Yes, I understand that it may be difficult to transfer my investments",
      key: "limited_transfer_acceptance"
    },
    {
      title: "Diversification",
      body: "Crowdfunding investing is highly speculative and every investment may result in a loss. By investing small amounts across multiple deals, you can reduce your risk compared to a large investment in a single company.",
      disclaimer_text: "Yes, I understand that it’s safer to split money across many investments across asset classes",
      key: "diversification_acceptance"
    },
    {
      title: "Cancellation",
      body: "You can cancel your investment up to 48 hours before the deal deadline. After that, your investment will be final, and you will not be able to get your money back.",
      disclaimer_text: "Yes, I understand that I can’t cancel after the 48-hour cancellation deadline",
      key: "cancellation_acceptance"
    },
    {
      title: "Limits",
      body: "Generally investors are limited to $2,200 per year — depending on their net-worth and income, this limit may go up to $107,000 — across all crowdfunding offerings on all platforms including Spaced Ventures.",
      disclaimer_text: "Yes, I understand that I can’t invest more than my limits allow",
      key: "limits_acceptance"
    },
    {
      title: "Research",
      body: "Do your own research. Read the documents provided by each company. Get independent legal, accounting, and financial advice. If you have any questions or need more information, ask the company in the discussion section.",
      disclaimer_text: "Yes, I understand that doing research is my own responsbility",
      key: "research_acceptance"
    },
    {
      title: "Electronic Communications",
      body: "You agree and authorize us to provide investment-related information and communications electronically in lieu of a paper version for any investment or service that you participate in on the Spaced Ventures platform, either now or in the future. ",
      disclaimer_text: "Yes, I consent to receiving electronic communications",
      key: "electronic_delivery_acceptance"
    }
  ]

  const otherCards = [
    {
      title: "Promoters",
      body: "The relationship of all founders, employees and paid promoters to a fundraising company should be clearly disclosed."
    },
    {
      title: "Commission",
      body: "Spaced Ventures is free for investors. Companies pay a 10% blended fee of cash and equity after a successful raise"
    }
  ]

  const investment_terms = user.investment_terms || {}
  const {
    risk_acceptance = false,
    limited_transfer_acceptance = false,
    diversification_acceptance = false,
    cancellation_acceptance = false,
    limits_acceptance = false,
    research_acceptance = false,
    electronic_delivery_acceptance = false,
  } = investment_terms

  return (
      <LoadingOverlay
        active={user?.loading}
        spinner
        text='Loading ...'
      >
      <div className="disclaimer-bg">
        <div id="disclaimerPage" className="main-section">
            <div className="container-right">
            <div className="disclaimer-header">
              <h1 className="weight-900 fs-3">Review Terms</h1>
              <span className="zeitung-micro fs-7">To invest on Spaced Ventures, you must understand the basics of crowdinvesting.<br /> <span className="weight-700">Please read and answer the questions below.</span></span>
            </div>
            </div>
          <Formik
            enableReinitialize
            initialValues={{
              risk_acceptance,
              limited_transfer_acceptance,
              diversification_acceptance,
              cancellation_acceptance,
              limits_acceptance,
              research_acceptance,
              electronic_delivery_acceptance,
            }}
            validationSchema={DisclaimerSchema}
            onSubmit={async (values, { props, setSubmitting }) => {
              updateInvestmentTerms(user.id, { user: { investment_terms_attributes: values } })
            }}
          >
            {({
              handleChange,
              handleSubmit,
              touched,
              isValid,
              dirty,
              values,
              errors,
              submitCount,
            }) => (
              <Form onSubmit={handleSubmit}>
                {
                  disclaimers.map(function (disclaimer, index) {
                    return (
                      <div className="disclaimer section-top" style={{ paddingTop: "60px", paddingBottom: "60px" }} key={index}>
                        <div className=" container-right">
                          <DisclaimerBox>
                            <div className="disclaimer-text-box">
                              <div className="fs-5 montserrat"><span className="weight-300">#{index + 1} </span><span className="weight-900">{disclaimer.title}</span></div>
                              <div className="fs-8 zeitung-micro m-t-10 weight-300">{disclaimer.body}</div>
                            </div>
                          </DisclaimerBox>
                          <div className="toggle-mobile-size m-t-15">
                            <div className="inline-switch-box mt-4">
                              <div>
                                <label className="switch">
                                  <input name={disclaimer.key} type="checkbox" checked={values[disclaimer.key]} id={disclaimer.key} onChange={handleChange} />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="zeitung-micro pl-2 switch-title">
                                {disclaimer.disclaimer_text}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div style={{ borderBottom: "2px dotted black", paddingBottom: "40px", paddingTop: "40px" }}>
                  <div className="container-right disclaimers-other-cards">
                    <div className="zeitung-micro px-2">And two last things we need to tell you:</div>
                    <div className="disclaimer-cards-other-row m-t-30">
                      {
                        otherCards.map(function (card, index) {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="card mt-3" style={{ background: "#F7F7F7", border: "none" }}>
                                <div className="card-body flex-fill">
                                  <h5 className="card-title fs-5 montserrat weight-900">{card.title}</h5>
                                  <p className="card-text fs-8 zeitung-micro">{card.body}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="section-top px-2 disclaimer-button-section">
                  <button disabled={!isValid || !dirty} type="submit" className="btn-black">
                    Start Investing
                  </button>
                  {!isValid && <div className="mx-auto text-center zeitung-micro" style={{ maxWidth: "626px", marginTop: "30px", fontWeight: '700' }}>
                    Please accept all terms to continue.
                  </div>
                  }
                  <div className="mx-auto text-center zeitung-micro" style={{ maxWidth: "626px", marginTop: "10px" }}>
                    By continuing I agree to submit any and all disputes involving Spaced Ventures, any of its users or employees, or any offering on Spaced Ventures, to binding arbitration.
                  </div>
                </div>
                <Prompt
                  when={!investment_terms.id || (dirty && submitCount === 0)}
                  message={
                    JSON.stringify({
                      title: 'Disclaimers',
                      message: "You must agree to all disclaimers to continue using Spaced Ventures",
                      restrictTransition: true,
                    })
                  }
                />
              </Form>)}
          </Formik>
        </div>
      </div>
    </LoadingOverlay>
  )
}

const DisclaimerBox = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 33px;
  color: #fff;
  background-color: #111;

  .disclaimer-text-box {
    max-width: 860px;
  }

`;
export default DisclaimerPage;
