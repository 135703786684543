import React from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from 'components/HookForm/TextInput';
import DateTimeField from 'components/HookForm/DateTimeField';


const MaterialChangeSchema = Yup.object().shape({
  description: Yup.string().required(),
  deadline: Yup.date().required('deadline is required')
  .test("close_date_in_past", "cannot set deadline in the past", function (value) {
    return value >= new Date();
  }),
});

function MaterialChangeForm({ materialChange={}, onSubmit }) {
  const {
    deadline='',
    description='',
  } = materialChange

  const methods = useForm({
    resolver: yupResolver(MaterialChangeSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {deadline, description}
  });

  return (
    <div className="deal-form-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* temporary hack for button style fix, will have to fix this once we add the full page context modals */}
          <div className='portalized-modal-wrapper'>
            <TextField
              name="description"
              placeholder="What changed?"
            />
          <DateTimeField
            id="deadline"
            name="deadline"
            placeholder="Deadline"
            showTimeSelect={false}
            showYearSelect={true}
            showMonthSelect={true}
          />
            <div className='portalized-modal-footer'>
              <button type="submit"
                className="btn-black"
                disabled={methods.isSubmitting}
              >
                Create
              </button>
            </div>
          </div> 
        </form>
      </FormProvider>
    </div>
  );
}

export default MaterialChangeForm;
