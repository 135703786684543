import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const TextField= ({name, type='text', placeholder, hint, inputStyle, labelStyle, ...props}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="form-label-group">
          <input
            {...props}
            {...field}
            value={ field.value || '' }
            id={field.name}
            type={type}
            placeholder={placeholder || ''}
            className={`form-control ${inputStyle || ''}${invalid ? "is-invalid" : ""}`}
          />
          { placeholder && <label htmlFor={field.name} className={ labelStyle || ''}>{placeholder}</label> }
          { hint && <small className='hint zeitung-micro weight-300 fs-10 d-block'>{hint}</small> }
          <ErrorMessage error={error}/>
        </div>
      )}
    />
  )
}

TextField.defaultProps = {};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default TextField;