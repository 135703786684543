import React from 'react';
import { rocketImgBlack } from "../../assets/images";
import './Modal.InvestmentConfirmation.scss';
import { history } from 'redux/helpers'
import { useQueryClient } from "react-query";
import ShareButtons from '../../investor/features/raise_petition/components/ShareButtons';
import { OFFER_TYPE } from '../../config/constants';

const urlForOffer = (slug) => `${window.location.host}/offers/${slug}`

const InvestmentConfirmation = ({ investmentId, offer, companyName, onClose }) => {
  const isEditInvestment = history?.location?.state?.isEditInvestment;
  const heading = isEditInvestment ? 'Investment Updated.' : 'Investment Confirmed.'
  const queryClient = useQueryClient();
  const isRegDOffer = offer?.type === OFFER_TYPE.reg_d506b;

  function onClickHandler () {
    onClose();
    history.push({
      pathname: `/investments/${investmentId}`,
      state: { showModal: false}
    });
    queryClient.invalidateQueries("investment", investmentId);
  }
  return (
    <div className="investment-confirmation-content-box">
      <h4 className="inv-confirmation-heading">{heading}</h4>
      <div className="w-1-dashed border-white mt-4"></div>
        <div className="eccb-fixed-width">
          <div className="inv-confirmation-message-box mt-4">
            <div className="row align-items-center">
              <div className="col-9 col-sm-8">
                <h6 className="fs-8 weight-700 mb-3">Next steps.</h6>
                <p className="fs-10 mb-2">{`We will confirm your payment details and conduct an AML/ID verification${isRegDOffer ? ` and Accredited Verification check` : ""}.`}</p>
                <p className="fs-10 mb-2 weight-600">{`The AML/ID check${isRegDOffer ? `, Accredited Verification check` : ""} and payment process typically takes 5-10 days to clear.`}</p>
                <p className="fs-10 mb-2 italic-style">The transaction on your bank statement will be from "Spaced Ventures".</p>
              </div>
              <div className="col-3 col-sm-4 text-center">
                <img src={rocketImgBlack} className="inv-confirmation-icon" alt="rocket image" />
              </div>
            </div>
          </div>
          <p className="mt-4 text-white fs-10 instruction-text-for-mobile">If there are any issues while processing your investment, we will email you.</p>
          { offer?.type === OFFER_TYPE.reg_cf &&
          <div>
            <div className="fs-19 f-white weight-700 mb-1 poppins">Support {companyName ? companyName : "this company"} by sharing to:</div>
            <div className='d-flex align-items-end mt-2' style={{color: "#fff"}}>
              <ShareButtons offering={{}} targetUrl={urlForOffer(offer.slug)} /> 
            </div>
          </div>
          }
          <button className="btn-white-outline mt-4" onClick={() => { onClickHandler() }} >Got it!</button>
        </div>
    </div>
  )
}

export default InvestmentConfirmation;