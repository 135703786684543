import React from 'react';
import PortalizedModal from './PortalizedModal';

const PortalizedModalContext = React.createContext();
const { Provider } = PortalizedModalContext

const ModalProvider = ({ children }) => {
  const [ modalEntries, setModalEntries ] = React.useState([])

  const openModal = React.useCallback((modal) => {
    if (modalEntries.includes(modal)) {
      console.warn('tried to open a modal that was already opened')
    } else {
      setModalEntries(openModals => [...openModals, modal ])
    }
  }, [modalEntries])
  
  const closeModal = React.useCallback((modal) => {
    if (modalEntries.includes(modal)) {
      setModalEntries(openModals => {
        const newModals = [...openModals]
        newModals.splice(newModals.indexOf(modal), 1)
        return newModals
      })
    } else {
      console.warn(`tried to close a modal that isn't open`)
    }
  }, [modalEntries])

  const contextValue = React.useMemo(() => ({ openModal, closeModal }), [modalEntries]);

  return (
    <Provider value={contextValue}>
      {children}
      <PortalizedModal openModals={modalEntries} />
    </Provider>
  );
};

export { PortalizedModalContext, ModalProvider };
