import React from 'react'
import rocketLaunch from "../../assets/images/icons/laptop-launch-lg.png";
import NewsLetter from './NewsLetter';
import ButtonWithoutIcon from "./Buttons";


export default function LaunchingSoon({ text, link, eventCategory, eventAction, eventLabel, children, toggleModal }){
  
  if (children){
  return (
    <div className="main-section container">
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <img className="laptop-launch-icon" src={rocketLaunch} alt="spaced ventures launching soon"></img>
        <h2 className="fs-5 weight-900 text-center m-t-20 pb-3">Join Spaced Ventures. <br></br>Don’t miss out.</h2>
          {children}
      </div>
    </div>
  )}

  return (
    <div className="main-section container">
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <img className="laptop-launch-icon" src={rocketLaunch} alt="spaced ventures launching soon"></img>
        <h2 className="fs-5 weight-900 text-center m-t-20 pb-3">Join Spaced Ventures. <br></br>Don’t miss out.</h2>
        <ButtonWithoutIcon
          text={text}
          link={link}
          width="36%"
          align="center"
          eventCategory={eventCategory}
          eventAction={eventAction}
          eventLabel={eventLabel}
        />
      </div>
    </div>
  )
}