import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../pages/Pages.common.scss";
import "./LimitCalculatorForm.scss";
import InvestmentLimitSidebar from "../shared/InvestmentLimitSidebar";
import FixedButtonBox from "../shared/FixedButtonBox";
import { updateInvestementLimit, useUser } from 'redux/user';
import { useInvestmentLimit, fetchInvestmentLimit } from 'redux/investmentLimit';
import CurrencyInputField from "../../../components/Formik/CurrencyInputField";

const investmentLimitSchema = Yup.object().shape({
  annual_income: Yup.number()
    .typeError('Please enter yearly income')
    .required("Please enter yearly income"),
  net_worth: Yup.number()
    .typeError('Please enter net worth')
    .required("Please enter net worth"),
  job_title: Yup.string()
    .required('Please enter Job Title'),
  job_industry: Yup.string()
    .required('Please enter Job Industry'),
  outside_amount: Yup.number()
    .typeError('Please enter outside amount')
    .required('Please enter outside amount'),
  certify_info: Yup.boolean()
    .oneOf([true], "You must certify the above information to update your limit"),
  spouse_first_name: Yup.string()
    .test("", "Please enter spouse first name", function (value) {
      const isSpouseIncluded = this.resolve(Yup.ref('include_spouse'))
      if (isSpouseIncluded) {
        return value && value.trim() != '' ? true : false;
      } else {
        return true;
      }
    }),
  spouse_last_name: Yup.string()
    .test("", "Please enter spouse last name", function (value) {
      const isSpouseIncluded = this.resolve(Yup.ref('include_spouse'))
      if (isSpouseIncluded) {
        return value && value.trim() != '' ? true : false;
      } else {
        return true;
      }
    })
})

export default function LimitCalculator({ onSubmit, showWhyWeNeedText, fullPageView }) {
  const [showSpousFields, setSpouseFieldsVisibility] = useState(false);
  const [investmentLimitValues, setInvestmentLimitValues] = useState({});

  const user = useUser();
  const investmentLimit = useInvestmentLimit().investment_limit_info;

  useEffect(() => {
    const attrs = {
      annual_income: user?.investment_limit?.annual_income || 0,
      net_worth: user?.investment_limit?.net_worth || 0,
      outside_investment_amount: user?.investment_limit?.outside_investment_amount || 0
    }
    fetchInvestmentLimit(attrs)
    if (user && user?.investment_limit) setInvestmentLimitValues(user?.investment_limit)
  }, [user])

  useEffect(() => {
    setSpouseFieldsVisibility(investmentLimitValues.spouse_included_income_and_worth)
  }, [investmentLimitValues])

  const amountChangeHandler = (values, lastChangedAttrVal) => {
    // HACK:: the values object is old and does have the last updated attribute value, pass in manually
    // FIX:: move from formik to rect-hook-forms, it has better api to handle this scenario
    const {annual_income = 0, net_worth = 0, outside_investment_amount = 0} = values
    // beacuase the naming is hard, inconsistent api param names.
    if (lastChangedAttrVal.outside_amount) {
      lastChangedAttrVal.outside_investment_amount = lastChangedAttrVal.outside_amount;
      delete lastChangedAttrVal.outside_amount
    }

    const attrs = {
      annual_income,
      net_worth,
      outside_investment_amount,
      ...lastChangedAttrVal
    }
    fetchInvestmentLimit(attrs)
  }

  const isAccredited = user?.investor_type === 'accredited' || investmentLimitValues?.accredited || false

  return (
    <div className={`limit-calc-wrapper ${fullPageView ? 'limit-calc-screen' : ''}`}>
      {fullPageView && <div className="limit-calc-screen-header">
        <h5 className="limit-calc-screen-header-title zeitung-micro weight-700">Calculate your Investor Limit</h5>
      </div> }
      <Formik
        enableReinitialize
        initialValues={{
          include_spouse: investmentLimitValues.spouse_included_income_and_worth || false,
          annual_income: investmentLimitValues.annual_income || 0,
          net_worth: investmentLimitValues.net_worth || 0,
          job_title: investmentLimitValues.job_title || "",
          job_industry: investmentLimitValues.job_industry || "",
          outside_amount: investmentLimitValues.outside_investment_amount,
          certify_info: false,
          invested_in_crowdfunding: investmentLimitValues.invested_in_crowdfunding || false,
          spouse_first_name: investmentLimitValues.spouse_first_name || "",
          spouse_last_name: investmentLimitValues.spouse_last_name || "",
        }}
        validationSchema={investmentLimitSchema}
        onSubmit={async (values, { }) => {
          const attrs = {
            investment_limit_attributes: {
              annual_income: values.annual_income || 0,
              net_worth: values.net_worth || 0,
              outside_investment_amount: values.outside_amount || 0,
              job_title: values.job_title,
              job_industry: values.job_industry,
              spouse_included_income_and_worth: values.include_spouse,
              invested_in_crowdfunding: values.invested_in_crowdfunding,
              spouse_first_name: values.spouse_first_name,
              spouse_last_name: values.spouse_last_name
            }
          }
          updateInvestementLimit(user?.id, attrs, onSubmit)
        }}
      >
        {({ values, touched, errors, isSubmitting, handleChange }) => (
          <Form>
            <>
              <div className="row limit-calc-content-row">
                <div className="limit-calc-content px-3">
                  <div className="hint zeitung-micro weight-300 mb-4 fs-10 instruction-text-for-mobile">
                    How much you can invest per 12 months depends on the information below. This information will not be disclosed or used for any other commercial or marketing purposes. <a href="/terms-of-service" style={{color:'#008FB1'}} target="_blank" rel="noopener noreferrer">Learn more.</a>
                        </div>
                  <div className="form-group inline-switch-box">
                    <label className="switch">
                      <Field name='include_spouse' type="checkbox" onChange={(e) => {
                        handleChange(e);
                        setSpouseFieldsVisibility(!showSpousFields);
                      }} />
                      <span className="slider round"></span>
                    </label>
                    <span className="px-2 zeitung-micro switch-title">Include spousal income and net worth</span>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <CurrencyInputField
                        id="annual_income"
                        name="annual_income"
                        label="Yearly Income"
                        placeholder="Yearly Income"
                        onChange={(currentValue)=>amountChangeHandler(values, {annual_income: currentValue})}
                      />
                    </div>
                    <div className="col-md-6">
                      <CurrencyInputField
                        id="net_worth"
                        name="net_worth"
                        label="Net Worth"
                        placeholder="Net Worth"
                        onChange={(currentValue)=>amountChangeHandler(values, {net_worth: currentValue})}

                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field type="text"
                          id="job_title"
                          className={`form-control ${touched.job_title && errors.job_title ? "is-invalid" : ""}`}
                          name="job_title"
                          placeholder="My job title"
                        />
                        <label htmlFor="job_title">My job title</label>
                        <ErrorMessage name="job_title" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field type="text"
                          id="job_industry"
                          className={`form-control ${touched.job_industry && errors.job_industry ? "is-invalid" : ""}`}
                          name="job_industry"
                          placeholder="My job industry"
                        >
                        </Field>
                        <label htmlFor="job_industry">My job industry</label>
                        <ErrorMessage name="job_industry" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                  </div>

                  {showSpousFields && <div className="row">
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field
                          id="spouse_first_name"
                          className={`form-control ${touched.spouse_first_name && errors.spouse_first_name ? "is-invalid" : ""}`}
                          name="spouse_first_name"
                          placeholder="Spouse First Name"
                        />
                        <label htmlFor="spouse_first_name">Spouse First Name</label>
                        <ErrorMessage name="spouse_first_name" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field
                          id="spouse_last_name"
                          className={`form-control ${touched.spouse_last_name && errors.spouse_last_name ? "is-invalid" : ""}`}
                          name="spouse_last_name"
                          placeholder="Spouse Last Name"
                        />
                        <label htmlFor="spouse_last_name">Spouse Last Name</label>
                        <ErrorMessage name="spouse_last_name" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                  </div>}

                  <hr className="w-1-dashed mb-4" />

                  <div className="hint zeitung-micro weight-300 mb-4 instruction-text-for-mobile" style={{ fontSize: 13, lineheight: 19 }}>
                  Outside of Spaced Ventures, in the last 12 months, how much have you invested in regulation crowdfunding offerings?
                  </div>
                  <div className="form-group">
                    <CurrencyInputField
                      id="outside_amount"
                      name="outside_amount"
                      label="Amount"
                      placeholder="Amount"
                      onChange={(currentValue)=>amountChangeHandler(values, {outside_amount: currentValue})}
                    />
                    <div className="hint zeitung-micro weight-300 mb-4 instruction-text-for-mobile" style={{ fontSize: 13, lineheight: 19 }}>
                      Enter $0 if you haven't made any other crowdfunding investments.
                    </div>
                  </div>
                  <label className={`custom-checkbox form-group d-flex flex-wrap position-relative mt-4 ${errors.terms ? 'is-invalid' : ''}`}>
                    <input id="info-certify-checkbox" type="checkbox" name="certify_info" className="mr-2" onChange={handleChange} />
                    <span className="custom-checkbox-check"></span>
                    <span className="custom-checkbox-tagline">I certify that all the above information is accurate and correct</span>
                    <div style={{marginLeft:'32px'}} className="failed-error-message">{errors.certify_info}</div>
                  </label>
                  {console.log({errors})}
                  {fullPageView ?
                    <FixedButtonBox isSubmitting={isSubmitting} buttonTitle='Update Limit' />
                    :
                    <>
                      <div className="portalized-modal-footer">
                        <div className="submition-box">
                          <div className="submition-box-inner py-0">
                            <button type="submit" className="poppins weight-700 btn-black my-0">Update Limit</button>
                          </div>
                        </div>
                      </div>
                    </>}
                </div>
                <div id="sidebar" className="limit-calc-sidebar px-3">
                  <InvestmentLimitSidebar investmentLimitInfo={investmentLimit} isAccredited={isAccredited} />
                  {showWhyWeNeedText && <>
                    <h5 className="montserrat weight-700 mt-4">Why do we need this?</h5>
                    <p className="fs-10">We use this information to determine your investment limit.</p>
                  </>}
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  )
}
