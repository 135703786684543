import React from 'react';
import ToastMessage from '../../../../../components/ToastMessage';
import { useMutation } from 'react-query';
import { userApi } from '../../../../../api/user';

export default function ResetOauthSignupPassword({ user, onSubmit }) {
  const resetPasswordRequest = useMutation(
    userApi.forgot_password,
    {
      onSuccess: (values) => {
        ToastMessage.success(`Reset password request link sent to ${user.email} email address.`);
        onSubmit();
      },
      onError: (error) => {
        ToastMessage.error("Error sending reset password link");
        onSubmit();
      },
    }
  )

  const forgotPasswordSubmit = () => resetPasswordRequest.mutate({ email: user.email });

  return(
    resetPasswordRequest.isLoading ? (
      "Sending reset password link via email."
    ) : (
      <div>
        <div className="d-block">
          You signed up with {user.login_mode}, please continue to use this to
          login and access Spaced Ventures. If you want to set a new password and
          login with email, please click this button to do so
        </div>
        <div className='portalized-modal-footer'>
          <button
            type="submit"
            className="btn-black"
            onClick={forgotPasswordSubmit}
          >
            Request Reset Password
          </button>
        </div>
      </div>
    )
  );
}
