import React from 'react';
import moment from 'moment';
import Tooltip from '../../../pages/components/Tooltip/Tooltip';
import { DEAL_TYPE_DEBT, DEAL_TYPE_EQUITY, EDT_TIMEZONE_STRING, OFFER_TYPE } from '../../../config/constants';
import { toTitlecase, humanize } from '../../../jsUtils/stringUtils';
import { FaExternalLinkAlt } from "react-icons/fa";
import { getTimeZone } from '../../../jsUtils/dateTimeUtils';
import { format, utcToZonedTime } from 'date-fns-tz'
import AmountWithCurrency, {
  AmountRange, FormattedNumberWithSymbol
} from '../../../components/AmountWithCurrency';

export default function DealTerms({ offer, dealInfoHeading = null}) {

  const userTimezone = React.useMemo(
    () => {
      return getTimeZone();
    }, []
  );

  const convertibleDebtText = "Convertible Notes are a specific type of convertible debt with specific a maturation date, interest rate, discount, and valuation cap."
  const safeNoteText = 'A SAFE note is a “Simple Agreement for Future Equity”, it entitles the holder to a conversion into equity at a future date based on a financing or IPO event. Typically SAFEs will include a discount and a valuation figure.';
  const debtTypeText = offer?.deal?.type === DEAL_TYPE_DEBT && offer?.deal?.security_type === "crowdsafe" ? "SAFE" : "Debt";

  function TableRow({label, value, tooltip, isExternalLink=false, icon=false}) {
    return(
      <div className="table-row">
        <div className="deal-attribute">
          <div className="deal-terms-tooltip"><Tooltip text={tooltip}><span className="deal-attribute-icon"></span></Tooltip></div>
          <span className="deal-attribute-title">{label}</span>
          {icon && <span className="deal-attribute-title-icon">{icon}</span>}
        </div>
        <span className="deal-attribute-value">{value ? value : '-'}</span>
      </div>
    )
  }

  function renderDealTypeAttrs() {
    if(offer?.deal?.type === DEAL_TYPE_DEBT){
      return (
        <>
          <TableRow
            label="Valuation cap"
            tooltip="The maximum valuation that your investment will convert into equity. If the conversion happens at a lower price your discount will take precedence over the valuation cap."
            value={offer?.deal?.valuation_cap ? <AmountWithCurrency number={offer?.deal?.valuation_cap} /> : '-'}
          />

          <TableRow
            label="Discount"
            tooltip="The discount on valuation you will receive when your debt converts to equity, unless the valuation cap results in a greater discount."
            value={offer?.deal?.discount ? `${offer?.deal?.discount}%` : '-'}
          />

          {offer?.deal?.maturity_date && <TableRow
            label="Maturity date"
            tooltip="The date when your debt will automatically convert into equity, unless it happens sooner."
            value={offer?.deal?.maturity_date ? moment(offer?.deal?.maturity_date)?.format("MMMM DD, YYYY") : '-'}
          />}

          <TableRow
              label="Type of security"
              tooltip={offer?.deal?.security_type === "convertible_debt" ? convertibleDebtText : safeNoteText}
              value={humanize(offer?.deal?.security_type ? offer?.deal?.security_type : '-')}
            />
          {offer?.deal?.interest_rate && <TableRow
            label="Interest rate"
            tooltip="The charge you will receive for loaning the company money. This interest will accrue and convert into equity."
            value={offer?.deal?.interest_rate ? `${offer?.deal?.interest_rate}%` : '-'}
          />}
        </>
      )
    } else if(offer?.deal?.type === DEAL_TYPE_EQUITY) {
      return (
        <>
          <TableRow
            label="Valuation"
            tooltip="A valuation is the total monetary value of the company, it's assets and future growth potential. Valuation is determined by the company."
            value={offer?.deal?.valuation ? <AmountWithCurrency number={offer?.deal?.valuation} /> : '-'}
          />

          <TableRow
            label="Price per share"
            tooltip="The value of an individual share in the company. Total Valuation / Total outstanding shares."
            value={offer?.deal?.price_per_share ? <AmountWithCurrency number={offer?.deal?.price_per_share} /> : '-'}
          />

          <TableRow
            label="Share type"
            tooltip="Common Class C shares tend to have little or no voting rights but still share in the economic benefit when a company is successful."
            value={offer?.deal?.share_type ? toTitlecase(offer?.deal?.share_type) : '-'}
          />
        </>
      )
    }
  }

  return (
    <div className="deal-terms-table">
      <div className="table-headers">
        <div className="header-primary">
          { dealInfoHeading && <h5 className="montserrat weight-700 mr-2">{dealInfoHeading}</h5> }
        </div>
        <div className="header-secondary justify-content-between">
          <div className="mr-2">How it works</div>
          <Tooltip xAlignment="-130px" text="Unlike reward-based crowdfunding, your investment on Spaced Ventures entitles you to an economic stake in companies. These deal terms explain the details of what this company is offering in exchange for investment.">
            <div className="info-icon"></div>
          </Tooltip>
        </div>
      </div>
      <div  className="table-rows">
        <TableRow
          label="Deal type"
          tooltip={offer?.deal?.type === DEAL_TYPE_EQUITY ? 'An equity deal is an offer to exchange investment for ownership in a company, usually in the form of company shares. ' : 'Debt is a loan from you (the investor) to the company, which is intended to automatically convert to equity in that company at a later date or event.'}
          value={offer?.deal?.type === DEAL_TYPE_EQUITY ? 'Equity' : debtTypeText}
        />

        {renderDealTypeAttrs()}

        <TableRow
          label="Investment range"
          tooltip="The minimum and maximum amount this company will accept from individual investors."
          value={
            offer?.deal?.minimum_investment && offer?.deal?.maximum_investment  ?
              <AmountRange
                start={offer?.deal?.minimum_investment}
                end={offer?.deal?.maximum_investment}
                numberFormattingOpts={{precision: 0}}
              /> :
              '-'
          }
        />

        { offer?.type === OFFER_TYPE.reg_d506b ?
          <TableRow
            label="Funding Target"
            tooltip="Funding traget for this offer"
            value={
              offer?.deal?.goal ? <FormattedNumberWithSymbol number={offer?.deal?.goal} /> : '-'
            }
          />
          :
          <TableRow
            label="Funding goal"
            tooltip="The amount that the issuing company is willing to accept in the offering."
            value={
              offer?.deal?.goal && offer?.deal?.maximum_goal  ?
                <AmountRange
                  start={offer?.deal?.goal}
                  end={offer?.deal?.maximum_goal}
                  numberFormattingOpts={{precision: 0}}
                /> :
                '-'
              }
          />
        }

        <TableRow
          label="Closing date"
          tooltip="The date when this offering will close, if the maximum goal has not already been reached."
          value={offer?.deal?.close_date ? `${format(utcToZonedTime(offer.deal.close_date, EDT_TIMEZONE_STRING), 'MMMM dd, yyyy, h:mm a', { timeZone: EDT_TIMEZONE_STRING })} ET` : '-' }
        />

        <TableRow
          label="Carry"
          tooltip="There is no share of the profits paid to us. If there is a return on this investment, you keep 100% of the profits your investment makes."
          value="0%"
        />

        <TableRow
          label="Investor Fees"
          tooltip="There are no fees paid by investors on this investment, depending on your payment method."
          value="$0"
        />

        {offer?.formc_url && <TableRow
          label="FORM C"
          tooltip="A Form C, also known as the Offering Statement is completed by the Issuer. The purpose of the Form C is for issuers to provide all the information as may be required by investors to make a decision before investing in the company’s crowdfunding offering."
          value={<span onClick={() => window.open(offer?.formc_url)} className="cursor-pointer d-flex flex-wrap align-items-center">FORM C <FaExternalLinkAlt className="ml-2" /></span>}
        />}
      </div>
    </div>
  )
}
