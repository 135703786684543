import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { MdDelete, MdCheckCircle, MdCancel, MdOutlinePrivateConnectivity, MdOutlinePublic, MdPublic, MdLockOutline, MdLock } from "react-icons/md";
import ToastMessage from "components/ToastMessage";
import { useOfferDocumentsList, removeDocument } from "./queries";
import { FaExternalLinkAlt } from "react-icons/fa";
import TextButton from 'admin/components/TextButton'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import { EDIT_OFFER_DOCUMENTS } from "../../../config/constants";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";

const OfferDocumentsList = ({offerId, accessories}) => {
  const queryClient = useQueryClient();
  const {data:documents, loading, error } = useOfferDocumentsList({offer_id: offerId})

  const removeDocumentMutation = useMutation(removeDocument, {
    onSuccess: (values) => {
      queryClient.invalidateQueries(['offer', 'documents', offerId], {exact: true})
      ToastMessage.success('Document deleted.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  })

  const deletehandler = (document_id) => {
    removeDocumentMutation.mutate({document_id})
  }

  if(error) {
    return (
      <div className="px-3 text-center">Something went wrong. Please try again later.</div>
    )
  }

  if(loading) {
    return (
      <div className="px-3 text-center">Loading...</div>
    )
  }

  if(!documents?.length){
    return null;
  }
  return (
    <div className='mt-5'>
      <OverviewSubheader left='Documents' right={ accessories } />
      {documents && documents.map((d, index) => <DocumentView key={index} document={d} deletehandler={deletehandler}/>)}
    </div>
  );
}

const DocumentView = ({document, deletehandler}) => {
  const href = document.attachment_url;

  return (
    <div className="d-flex justify-content-between bg-lightgrey2 mt-1 py-2 px-3 poppins">
      <div className="mt-1">
        <a href={href} className="color-black fs-10" target="blank">
          {document.visibility == "public" ? <MdPublic className="mr-2 mb-1"/> : <MdLock className="mr-2 mb-1" />}
          {document.name}
          <FaExternalLinkAlt className='ml-1 mb-1'/>
        </a>
      </div>
      <small className='ml-4'>{ document.downloadable ? "Downloadable" : ""}</small>
      <MdDelete className="cursor-pointer" color="#111" size="17px" onClick={() => {
        if (window.confirm('Are you sure want to remove the document?')){
          deletehandler(document.id)
        }
      }}/>
    </div>
  )
}

export default OfferDocumentsList;