import React from "react";
import OverviewSubheader from "../../components/OverviewSubheader";
import { formatDate } from "jsUtils/dateTimeUtils";

function NoteItem({ note }) {
  return (
    <div className="highlighted-tile mb-1">
      <div className="inner1">{formatDate(note.created_at)}</div>
      <div className="inner2">{note.content}</div>
    </div>
  )
}

export default function NoteList({ amlException }) {
  return (
    <>
      <OverviewSubheader left="Status Notes" />
      { !amlException.notes.length  && <span className="highlighted-tile">No status notes available.</span> }
      { amlException.notes.map(note => <NoteItem note={note} />)}
    </>
  );
}