import Rollbar from "rollbar";
import { ROLLBAR_CONFIG } from "config/rollbar"

const rollbarInstance = new Rollbar(ROLLBAR_CONFIG);

const setRollbarPerson  = (person) => {
  rollbarInstance.configure({
    payload: {person}
  });
}

export {
  rollbarInstance, 
  setRollbarPerson
};
