import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { history } from 'redux/helpers'
import NestedSelect from './../../../pages/components/NestedSelect'
import { createCompany, updateCompany, useSectorsList } from '../../pages/company/queries';
import Icon from './../../components/Icon';
import "../components.Common.scss";
import FixedButtonBox from '../shared/FixedButtonBox';
import { crossIcon } from '../../../assets/images';
import YearPickerField from '../../../components/Formik/YearPickerField';
import ToastMessage from '../../../components/ToastMessage';
import { useMutation, useQueryClient } from 'react-query';
import { removeCompanyLogo, removeCompanyBanner } from '../../queries';
import useGeoInfo from '../../../lib/useGeoInfo';
import { removeUrlProtocol } from '../../../jsUtils/stringUtils';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

const MAX_LOGO_FILE_SIZE = 200 * 1024;
const MAX_BANNER_FILE_SIZE = 10 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png"
];

const CompanyInfoSchema = Yup.object().shape({
  logo: Yup.string(),

  banner: Yup.mixed(),

  name: Yup.string()
    .required("Company name is required")
    .max(255, "Name too long, max-length 255 characters"),

  legal_name: Yup.string()
    .required("Company's legal name is required")
    .max(255, "Company legal name too long, max-length 255 characters"),

    url: Yup.string().matches(/^(www.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+\/)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    'Invalid website URL'),

  private: Yup.boolean()
    .required("Company type is required"),

  employee_count: Yup.number()
    .nullable()
    .typeError('Employee count must be a number')
    .integer("Employee count must be an integer"),
  //   .required("Employee count is required")
  //   .moreThan(0, "Employee count must be greater than 0"),

  // founding_year: Yup.number()
  //   .required("Funding year is required")
  //   .typeError("Funding year must be an integer")
  //   .integer("Funding year must be an integer")
  //   .moreThan(0, "Funding year must be greater than 0")
  //   .lessThan(new Date().getFullYear() + 1, "Founding year must be before or same as current year"),

  sector_id: Yup.number()
    .required("Sector is required"),

  // last_funding_type: Yup.string()
  //   .required("Last funding type is required"),

  // tagline: Yup.string()
  //   .max(255, "Tagline too long, max-length 255 characters"),

  // description: Yup.string()
  //   .required('Description is required'),

  // city: Yup.string()
  //   .required('City is required'),

  // state: Yup.string()
  //   .required('State/Province is required'),

  // country: Yup.string()
  //   .required('Country is required')
  twitter_url: Yup.string()
  .typeError("")
  .matches( /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/, 'enter valid url!' ),

  linkedin_url: Yup.string()
  .typeError("")
  .matches( /http(?:s)?:\/\/(?:www\.)?linkedin\.com\/([a-zA-Z0-9_]+)/, 'enter valid url!' )

});

export default function EditForm({company, onSubmitSuccess}) {
  const queryClient = useQueryClient();
  const { Country } = useGeoInfo();
  const { id } = company
  const fileReader = new FileReader();
  const minYear = 1900;
  const maxYear = new Date().getFullYear();


  const {data: allSectors = [], isLoading:isLoadingSectors} = useSectorsList();

  const [urlScheme, setUrlScheme]=React.useState("https://");

  React.useEffect(() => {
    if (company?.url?.match(/^http:\/\//)) {
      setUrlScheme("http://");
    }
  }, [])

  const companyCreateMutation = useMutation(createCompany, {
    onSuccess: () => {
      ToastMessage.success('Company created.');
      queryClient.invalidateQueries(['admin', 'companies',])
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {},
  });

  const companyUpdateMutation = useMutation(updateCompany, {
    onSuccess: () => {
      ToastMessage.success('Company updated.');
      queryClient.invalidateQueries(['admin', 'companies',])
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {},
  });

  const removeLogoMutation = useMutation(removeCompanyLogo, {
    onSuccess: (values) => {
      ToastMessage.success('Company logo removed.');
    },
    onError: (error) => {
      ToastMessage.error('Could not remove company logo.');
    }
  })

  const removeBannerMutation = useMutation(removeCompanyBanner, {
    onSuccess: (values) => {
      ToastMessage.success('Company banner removed.');
    },
    onError: (error) => {
      ToastMessage.error('Could not remove company banner.');
    }
  })

  return(
    <>
    <div className="section-bottom">
      <Formik
        initialValues={{
          logo: company?.logo,
          logo_url: company?.logo_url,
          banner: company?.banner,
          banner_url: company?.banner_url,
          name: company?.name,
          legal_name: company?.legal_name,
          url: removeUrlProtocol(company?.url),
          private: company?.private,
          employee_count: company?.employee_count,
          founding_year: company?.founding_year,
          company_type: company?.company_type,
          last_funding_type: company?.last_funding_type,
          sector_id: company?.sector_id,
          tagline: company?.tagline,
          description: company?.description,
          city: company?.city,
          state: company?.state,
          country: company?.country,
          twitter_url: company?.twitter_url,
          linkedin_url: company?.linkedin_url,
        }}
        validationSchema={CompanyInfoSchema}
        onSubmit={async (values, {}) => {
          let payload = {...values}
          if(values.url) payload={...values, url:urlScheme+((values.url).replace(/\/$/,""))}
          values = {...company, ...payload}

          if(id) {
            companyUpdateMutation.mutate({
              company_slug: company.slug,
              values: {company: values}
            })
          }
          else {
            companyCreateMutation.mutate({
              values: {company: values}
            })
          }
          // if(id)
          //   dispatch(updateCompany(company.slug, {company: values}, onSubmitSuccess))
          // else
          //   dispatch(createCompany({company: values}, onSubmitSuccess))
        }}
      >
        {({values, touched, errors, setFieldValue, setFieldTouched, isSubmitting, handleChange }) => (
          <Form>
            <div className="mini-container" style={{minHeight:'calc(100vh - 201px)'}}>
              <div className="form-group">
                <div className="logo-uploader">
                <label className="mb-0" htmlFor="logo">
                  <div className="upload-logo bg-lightgrey cursor-pointer"
                    style={{
                      backgroundImage: `url( ${values.logo?.data || values.logo_url})`
                    }}
                  >
                    <div className="upload-logo-inner">
                      <Icon name="fileUpload" />
                      <div className="w-100">
                        Upload Logo
                      </div>
                    </div>
                  </div>
                </label>
                {(values.logo || values?.logo_url) &&
                  <span
                    onClick={()=>{
                      setFieldValue('logo', "")
                      if(values?.logo_url) {
                        removeLogoMutation.mutate({company_id: company.slug})
                        setFieldValue("logo_url", "")
                      }
                    }}
                    className='close-icon'>
                    <img src={crossIcon} alt="X"/>
                </span>} 
                <input type="file"
                  accept='.jgp, .png, .jpeg'
                  id="logo"
                  name="logo"
                  className="form-control d-none"
                  onChange={
                    (event) => {
                      setFieldTouched("logo", true)
                      const file = event.currentTarget.files[0]
                      const type = file.type;
                      const error = !(type.includes('jpg') || type.includes('png') || type.includes('jpeg'))
                      if(!file || error) {
                        ToastMessage.error("File type not supported")
                        return;
                      }
                      fileReader.readAsDataURL(event.currentTarget.files[0])
                      fileReader.onload = function () {
                        setFieldValue("logo", {data: fileReader.result})
                      };
                      fileReader.onerror = function (error) {
                        console.log('logo file Error: ', error);
                      };
                    }
                  }
                />
                </div>
                <ErrorMessage name="logo" component="div" className="invalid-feedback text-left"/>
              </div>

              <div className="form-group row">
                <div className="col-md-9 banner-uploader">
                  <label className="d-block mb-0" htmlFor="banner">
                    <div className="upload-banner bg-lightgrey cursor-pointer"
                      style={{
                        backgroundImage: `url(${ values.banner?.data || values.banner_url })`
                      }}
                    >
                      <div className="upload-banner-inner">
                        <Icon name="fileUpload" />
                        <div className="w-100">
                          Upload Banner
                        </div>
                      </div>
                    </div>
                  </label>
                  {(values.banner || values?.banner_url) && <span onClick={()=>{
                      setFieldValue("banner", "");
                      if(values?.banner_url) {
                        removeBannerMutation.mutate({company_id: company.slug})
                        setFieldValue("banner_url", "");
                      }
                    }}
                    className='close-icon'
                  >
                    <img src={crossIcon} alt="X"/>
                  </span> } 
                  <input type="file"
                    accept='.jgp, .png, .jpeg'
                    id="banner"
                    name="banner"
                    className="form-control d-none"
                    onChange={(event) => {
                      setFieldTouched("banner", true)
                        const file = event.currentTarget.files[0]
                        const type = file.type;
                        const error = !(type.includes('jpg') || type.includes('png') || type.includes('jpeg'))
                        if(!file || error) {
                          ToastMessage.error("File type not supported")
                          return;
                        }
                        fileReader.readAsDataURL(event.currentTarget.files[0])
                        fileReader.onload = function () {
                          setFieldValue("banner", {data: fileReader.result})
                        };
                        fileReader.onerror = function (error) {
                          console.log('banner file Error: ', error);
                        };
                    }}
                  />
                  <ErrorMessage name="banner" component="div" className="invalid-feedback text-left"/>
                <div class='fs-10'>PNG, JPG & JPEG types only*</div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="name"
                      name="name"
                      className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                      placeholder="Company Name"
                    />
                    <label htmlFor="name">Company Name</label>
                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="legal_name"
                      name="legal_name"
                      className={`form-control ${touched.legal_name && errors.legal_name ? "is-invalid" : ""}`}
                      placeholder="Legal Name"
                    />
                    <label htmlFor="legal_name">Legal Name</label>
                    <ErrorMessage name="legal_name" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div class="field-with-dropdown">
                    <div className='form-group'>
                      <select
                          id="url_scheme"
                          name="url_scheme"
                          className='form-control custom-dropdown'
                          value={urlScheme}
                          onChange={(event)=>{setUrlScheme(event.target.value)}}>
                          <option value={'https://'}>https://</option>
                          <option value={'http://'}>http://</option>
                        </select>
                      </div> 
                    <div className="form-label-group">
                      <Field type="text"
                        id="url"
                        className={`form-control ${touched.url && errors.url ? "is-invalid" : ""}`}
                        name="url"
                        placeholder="Website"
                      />
                      <label htmlFor="url">Website</label>
                      <ErrorMessage name="url" component="div" className="invalid-feedback text-left"/>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="private"
                      id="private"
                      className={`form-control ${touched.private && errors.private ? "is-invalid" : ""}`}
                      name="private"
                      placeholder="Private or Public company"
                      component="select"
                    >
                      <option value="">Select company type</option>
                      <option value="true">Private</option>
                      <option value="false">Public</option>
                    </Field>
                    <label htmlFor="private">Private or Public company</label>
                    <ErrorMessage name="private" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="employee-count"
                      name="employee_count"
                      className={`form-control ${touched.employee_count && errors.employee_count ? "is-invalid" : ""}`}
                      placeholder="Employee Count"
                    />
                    <label htmlFor="employee-count">Employee Count</label>
                    <ErrorMessage name="employee_count" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="col-sm-6">
                <div className={`form-datepicker-group ${values?.founding_year ? 'date-selected' : ''}`}>
                  <YearPickerField
                    name="founding_year"
                    placeholder="Founding Year"
                    initialValue={values?.founding_year}
                    min={minYear}
                    max={maxYear}
                  />
                </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <NestedSelect
                      id='sector_id'
                      placeholder='Sector & Subsector'
                      className={`form-control ${touched.sector_id && errors.sector_id ? "is-invalid" : ""}`}
                      options={allSectors}
                      value={values.sector_id}
                      onChange={ value => setFieldValue("sector_id", value) }
                      childrenNestingKey='children'
                      parentOptionLabel='name'
                      parentOptionValue='id'
                      optionLabel='name'
                      optionValue='id'
                      parentSelectable={false}
                      onBlur= { (value) => setFieldTouched("sector_id", value) }
                    />
                    <label htmlFor="sector_id">Sector & Subsector</label>
                    <ErrorMessage name="sector_id" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="col-sm-6">
                <div className="form-label-group">
                    <Field type="text"
                      id="last_funding_type"
                      name="last_funding_type"
                      className={`form-control ${touched.last_funding_type && errors.last_funding_type ? "is-invalid" : ""}`}
                      placeholder="Last funding stage"
                      component="select"
                    >
                      <option value="">Select last funding type</option>
                      <option value="Pre-Seed">Pre-Seed</option>
                      <option value="Seed">Seed</option>
                      <option value="Series A">Series A</option>
                      <option value="Series B">Series B</option>
                      <option value="Series C">Series C</option>
                      <option value="Series C+">Series C+</option>
                      <option value="Grant">Grant</option>
                      <option value="IPO">IPO</option>
                      <option value="Undisclosed">Undisclosed</option>
                      <option value="None">None</option>
                    </Field>
                    <label htmlFor="last_funding_type">Last funding stage</label>
                    <ErrorMessage name="last_funding_type" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>

              <div className="form-label-group">
                <Field type="text"
                  id="tagline"
                  name="tagline"
                  className={`form-control ${touched.tagline && errors.tagline ? "is-invalid" : ""}`}
                  placeholder="Tagline"
                />
                <label htmlFor="tagline">Tagline</label>
                <ErrorMessage name="tagline" component="div" className="invalid-feedback text-left"/>
              </div>

              <div className="form-label-group">
                <Field type="text"
                  id="description"
                  name="description"
                  component="textarea"
                  rows="5"
                  className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`}
                  placeholder="Description"
                />
                <label htmlFor="description">Description</label>
                <ErrorMessage name="description" component="div" className="invalid-feedback text-left"/>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="city"
                      name="city"
                      className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                      placeholder="City"
                    />
                    <label htmlFor="city">City</label>
                    <ErrorMessage name="city" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field
                      id="country"
                      name="country"
                      component="select"
                      className={`form-control ${touched.country && errors.country ? "is-invalid" : ""}`}
                      placeholder="Country"
                    >
                      <option value="">Select Country</option>
                      {
                        Country.list().map(c => <option value={c.name} key={c.name}>{c.name}</option>)
                      }
                    </Field>
                    <label htmlFor="country">Country</label>
                    <ErrorMessage name="country" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="linkedin_url"
                      name="linkedin_url"
                      className={`form-control ${touched.linkedin_url && errors.linkedin_url ? "is-invalid" : ""}`}
                      placeholder="https://www.linkedin.com"
                    />
                    <label htmlFor="linkedin_url">LinkedIn URL</label>
                    <ErrorMessage name="linkedin_url" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <Field type="text"
                      id="twitter_url"
                      name="twitter_url"
                      className={`form-control ${touched.twitter_url && errors.twitter_url ? "is-invalid" : ""}`}
                      placeholder="https://www.twitter.com"
                    />
                    <label htmlFor="twitter_url">Twitter URL</label>
                    <ErrorMessage name="twitter_url" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
            <FixedButtonBox
              isSubmitting={isSubmitting}
              buttonTitle="Save"
              disabled={isSubmitting}
            />
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </>
  );
}
