import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchVisionIcon } from '../../../../assets/images';

function VisionSection({ 
  pitch:{vision_section}
}) {
  if(!vision_section) return null;

  return (
    <div id="vision-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'vision'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchVisionIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{vision_section.title}</div>
          </div>
            <ArticleContent content={vision_section.content}/>
        </div>
      </div>
    </div>
  )
}

export default VisionSection;