import React from "react";
import _ from 'lodash';

export default function useSearchInputManager() {
  const [queryString, setQueryString] = React.useState(undefined);

  const onChange = React.useCallback(_.debounce((qs) => setQueryString(qs), 500), [])

  return { queryString, onChange };
};
