import axiosClient from 'config/axiosClient';

export const investmentLimitApi = {
  fetchInvestmentLimit,
  updateInvestementLimit
};

function fetchInvestmentLimit(attrs) {
  return axiosClient.post(`investment_limits/calculate`, attrs)
}

function updateInvestementLimit(clientId, attrs) {
  return axiosClient.put(`/clients/${clientId}`, attrs)
}
