import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from "../../components/ToastMessage";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import SelectField from "components/HookForm/SelectInput";
import {
  updateAdminRole
} from "../queries/index";

const MODERATOR_ROLE_NAMES = [
  "Admin", "Content Editor", "Marketer",
  "Customer Service", "Compliance", "Advisor", "Operations",
];

const AdminRoleSchema = Yup.object().shape({
  name: Yup.string()
    .oneOf(MODERATOR_ROLE_NAMES)
    .required(),
});

function AdminRoleEditForm({role, onSettle}) {
  const queryClient = useQueryClient();

  const updateRoleMutation = useMutation(
    updateAdminRole,
    {
      onSuccess: (values) => {
        methods.reset()
        queryClient.invalidateQueries(
          ['spacedAdminInvitesList'],
          {
            exact: false,
          }
        )
        ToastMessage.success('Role updated.');
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
      onSettled: () => {
        typeof onSettle === 'function' && onSettle();
      }
    }
  )

  const methods = useForm({
    resolver: yupResolver(AdminRoleSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      name: role.name,
    },
  });

  const onSubmit = (values) => {
    updateRoleMutation.mutate({
      id: role.id,
      values
    })
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <SelectField
                name="name"
                placeholder="Select Role*"
                options={MODERATOR_ROLE_NAMES}
              />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="btn-black"
              disabled={methods.isSubmitting}
            >
              Update Role
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default AdminRoleEditForm;
