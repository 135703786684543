import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchProductIcon } from '../../../../assets/images';

function ProductSection({ 
  pitch:{product_section}
}) {
  if(!product_section) return null;

  return (
    <div id="product-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'product'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchProductIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{product_section.title}</div>
          </div>
          <img src={product_section.image_url} alt="Product Image" className="product-image"/>
          <div className="product-description italic-style">
            {product_section.desc}
          </div>
            <ArticleContent content={product_section.content}/>
        </div>
      </div>
    </div>
  )
}

export default ProductSection;