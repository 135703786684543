import React from "react";
import OverviewSubheader from "../../components/OverviewSubheader";
import { useClientAuditLogs } from "../../../investor/queries";
import { formatDate } from "../../../jsUtils/dateTimeUtils";
import { humanize } from "../../../jsUtils/stringUtils";

function Row({ auditLog, fieldName }) {
  return (
    <tr>
      <td>{humanize(fieldName)}</td>
      <td>{auditLog.audited_changes[fieldName][0]}</td>
      <td>{auditLog.audited_changes[fieldName][1]}</td>
      <td>{auditLog.author.email}</td>
      <td>{formatDate(auditLog.created_at)}</td>
    </tr>
  )
}

function AuditLog({ record }) {
  return (
    Object.keys(record.audited_changes).map((fieldName, index) => {
      return <Row key={index} auditLog={record} fieldName={fieldName} />
    })
  )
}

function AuditLogTable({ auditLogs }) {
  return (
    <div className="table-responsive px-3 bg-white">
      <table className="table table-borderless table-hover">
        <thead>
          <tr>
            <th scope="col" className="weight-500">Attribute</th>
            <th scope="col" className="weight-500">Old Value</th>
            <th scope="col" className="weight-500">New Value</th>
            <th scope="col" className="weight-500">Author</th>
            <th scope="col" className="weight-500">Updated At</th>
          </tr>
        </thead>
        <tbody>
          {auditLogs.map((record, index) => <AuditLog key={index} record={record} />)}
        </tbody>
      </table>
    </div>
  )
}

export default function ClientAuditLogs({ clientId }) {
  const auditLogs = useClientAuditLogs(clientId);

  if (auditLogs.isLoading) return "Loading Audit Logs...";

  return (
    <>
      <OverviewSubheader left="Edit History" />
      {auditLogs.data.length === 0 ? (
        <div className='d-flex justify-content-center align-items-center p-3 bg-white'>
          No edit history found.
        </div>)
        : <AuditLogTable auditLogs={auditLogs.data} />
      }
    </>
  );
}