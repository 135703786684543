import React from 'react';
import { Link } from "react-router-dom";
import ForgotPasswordForm from './components/ForgotPasswordForm';
import './Pages.Common.Style.scss';
import { useUser } from 'redux/user';
import LoadingOverlay from 'react-loading-overlay';

export default function ForgotPasswordPage() {

  return (
    <LoadingOverlay
      active={useUser().loading}
      spinner
      text='Loading ...'
    >
      <div id="forgotPasswordPage" className="banner-img-container text-center flex-center pt-first-section">
        <ForgotPasswordForm />
        {/* <span className="zeitung-micro fs-7 w-100 question">Do not have an account? <Link to="/signup">Sign up</Link></span> */}
      </div>
    </LoadingOverlay>
  )
}
