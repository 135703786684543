import React, { useState, useEffect } from 'react';
import { usePusherChannel } from '../../../contexts/PusherChannelContext';
import { raisedFundsPercentage } from '../../../jsUtils/offerUtils';
import LiveViewerCount from '../LiveViewerCount';
import {
  FormattedNumberWithSymbol,
} from '../../AmountWithCurrency';

export default function OfferProgressBar({ offer }){
  const pusherChannel = usePusherChannel(offer.live_event.channel_id);
  const maximumGoal = offer.deal.maximum_goal || 0;
  const dealRaisedAmount = parseInt(offer.deal.raised || 0);
  const [status, setStatus] = useState(offer.live_event.status);

  const [liveStats, setLiveStats] = useState({
    raisedAmount: dealRaisedAmount,
    investorsCount: offer.investor_count || 0
  });

  useEffect(() => {
    pusherChannel.bind('new-investment', (data) => {
      setLiveStats({
        raisedAmount: parseInt(data.offer.raised_amount),
        investorsCount: data.offer.investor_count
      })
    });
    pusherChannel.bind('status-change', (data) => setStatus(data.status));

    return () => {
      pusherChannel.unbind('new-investment', () => {});
      pusherChannel.unbind('status-change', () => {});
    }
  }, []);

  const fundsPercentage = raisedFundsPercentage(liveStats.raisedAmount, maximumGoal) || 0;

  return (
    <div className='d-flex flex-column'>
      <div className='px-2'>
        <div className='weight-700 fs-14 pb-2 seperator'>
          <FormattedNumberWithSymbol number={liveStats.raisedAmount} numberFormattingOpts={{precision: 0}} />
          TOTAL RAISED
        </div>
      </div>
      <div className='px-2'>
        <div className='fs-14 weight-400 py-2 seperator'>
          <span className="weight-700">{fundsPercentage}%</span> of Max Goal
        </div>
      </div>
       <div className='px-2 mb-1'>
        <div className='fs-14 weight-400 py-2 seperator'>
          <span className='weight-700 mr-1'>{liveStats.investorsCount} </span>Investors
        </div>
      </div>
       <div className='px-2'>
          { status == 'live' && <>
              <div className='fs-14 weight-400 py-2'>
                <span className='weight-700 mr-1'>
                  <LiveViewerCount liveEvent={offer.live_event} />
                </span>
                Watching
              </div>
            </>
          }
      </div>
    </div>
  )
}