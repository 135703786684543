import React from 'react';
import styled from 'styled-components'
import { BsDiamondFill } from 'react-icons/bs'

import thumb1 from '../../assets/images/cards/thumb/launch-thumb-lg.png'
import thumb2 from '../../assets/images/cards/thumb/satellite-thumb-lg.png'
import thumb3 from '../../assets/images/cards/thumb/habitat-thumb-lg.png'
import thumb4 from '../../assets/images/cards/thumb/data-thumb-lg.png'
import thumb5 from '../../assets/images/cards/thumb/propulsion-thumb-lg.png'
import thumb6 from '../../assets/images/cards/thumb/planetary-thumb-lg.png'

const cardData = [
  { 
    title: "Launch",
    text: "Transport of cargo and passengers from the ground to orbit.",
    thumb: thumb1,
    subSectors: ["Rockets", "Fuel", "Launch Support", "Engines"]
  },
  {
    title: "Satellite",
    text: "The orbital infrastructure that makes life on earth better.",
    thumb: thumb2,
    subSectors: ["Space Tugs", "Optical Comms", "Power Systems", "Debris Avoidance"]
  },
  {
    title: "Habitat",
    text: "Life-sustaining shelters in the harshest environments.",
    thumb: thumb3,
    subSectors: ["Orbital Stations", "Planetary shelters", "3D printed structures", "Robotic Construction"]
  },
  {
    title: "Data & analytics",
    text: "Information collection and processing from space.",
    thumb: thumb4,
    subSectors: ["Weather Prediction", "GeoSpatial Data Collection", "Environmental Tracking", "Space-Based A.I."]
  },
  {
    title: "Propulsion",
    text: "Advanced engine technology to move around the solar system.",
    thumb: thumb5,
    subSectors: ["Ion Thrusters", "Nuclear Propulsion", "Solar Sail", "Plasma Propulsion engines"]
  },
  {
    title: "Planetary",
    text: "Support human presence on the Moon, Mars and other celestial bodies.",
    thumb: thumb6,
    subSectors: ["In-situ resource utilization", "Swarm robotics", "Energy collection", "Planetary observation"]
  } 
]

function Listicle({title, text,thumb, subSectors}){
    return (
      <div>
        <div className="d-flex align-items-start">
          <PropThumbStyles> 
              <img className="prop-thumb-image" src={thumb} alt="thumb"></img>
          </PropThumbStyles>   
          <div>
            <h3 className="fs-8 text-uppercase montserrat weight-900">{title}</h3>
            <p className="zeitung-micro weight-600 fs-9">{text}</p>
          </div>
        </div>     
        <PropListStyles>
          <div className="d-flex align-items-center technologies-header"><BsDiamondFill size="8px" /><div className="ml-2 f-black0 weight-600 text-uppercase zeitung-micro fs-11">Technologies</div></div>
          <ul>
            {subSectors.map((sector,key) => {
              return (
              <li className="zeitung-micro fs-10 weight-300" key={key}>{sector}</li>
              )
            })}
          </ul>
        </PropListStyles>
      </div>
    )
  }

export default function ValueProps() {
  return (
    <div className="value-props-background">
    <div className="container main-section">
          <div className="d-flex justify-content-start"> 
           <h2 className="fs-5 weight-900 pb-2 center-mobile">Invest in Every Facet of Space</h2>
          </div>
          <PropsContainerGridStyles>
            {cardData.map((item, key) => (
              <Listicle key={key} title={item.title} text={item.text} thumb={item.thumb} subSectors={item.subSectors} />
            )) }
          </PropsContainerGridStyles>
    </div>
    </div>
  )
}

const PropThumbStyles  = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #111;
  margin-right: 10px;
  background-color: #fff;

  .prop-thumb-image {
    width: 45px;
  }
`;


const PropListStyles  = styled.div`
  padding-top: 0;
  padding-left:55px;


 ul {
  list-style: none;
  font-size: 14px;
}

.technologies-header {
  border-top: 1px dotted #111;
  border-bottom: 1px dotted #111;
  padding-top: 10px;
  padding-bottom:11px;
}

 ul > li {
  padding: 15px 0;
}

 ul > li:not(:first-child) {
  border-top: 1px dotted #111;
}
`;

const PropsContainerGridStyles = styled.div`
  display: grid;
  margin-top: 48px;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;