import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createMaterialChange } from '../queries/index';
import MaterialChangeForm from './MaterialChangeForm';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import ToastMessage from '../../components/ToastMessage';

function MaterialChangeCreator({offerId, onCreateSuccess}) {
  const queryClient = useQueryClient();
  
  const createMaterialChangeMutation = useMutation( createMaterialChange, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["material_changes"])
      onCreateSuccess()
      ToastMessage.success('Material change successfully created.');
    },
    onError: (error) => {
      renderRailsErrors(error);
    }
  })

  const onSubmit = (values) => {
    createMaterialChangeMutation.mutate({
      offer_slug: offerId,
      values
    })
  }

  return (
    <MaterialChangeForm onSubmit={onSubmit}/>
  );
}

export default MaterialChangeCreator;
