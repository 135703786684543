import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from 'react-query';
import ToastMessage from '../../components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import TextField from "components/HookForm/TextInput";
import CheckboxField from "components/HookForm/Checkbox";
import GoogleRecaptcha from "components/HookForm/GoogleRecaptcha";
import { history } from 'redux/helpers';
import {
  acceptInvite
} from "./../../investor/queries";

const AcceptInviteSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Please Enter First Name'),

  last_name: Yup.string()
    .required('Please Enter Last Name'),

  password: Yup.string()
    .required('Please Enter Password')
    .min(8, 'At least 8 characters required'),

  password_confirmation: Yup.string()
    .required('Please Enter Confirm Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  // recaptcha: Yup.string()
  //   .required('Required')
});

function AcceptInviteForm() {
  const { inviteToken } = useParams();

  const acceptInviteMutation = useMutation(
    acceptInvite,
    {
      onSuccess: (values) => {
        methods.reset()
        ToastMessage.success(`Invite accepted`)
        if (values.email_confirmed)
          history.push('/login')
        else
          history.push('/email-verification')

      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const methods = useForm({
    resolver: yupResolver(AcceptInviteSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {},
  });

  const onSubmit = (values) => {
    acceptInviteMutation.mutate({
      invite_token: inviteToken,
      values
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form-row">

          <div className="col-sm-6">
            <TextField
              name="first_name"
              placeholder="First Name"
            />
          </div>

          <div className="col-sm-6">
            <div className="form-label-group">
              <TextField
                name="last_name"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              type="password"
              name="password"
              placeholder="Password (min 8 characters)"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              type="password"
              name="password_confirmation"
              placeholder="Retype your password"
            />
          </div>
        </div>

        {/* <div className="form-group">
          <GoogleRecaptcha
            name="recaptcha"
          />
        </div> */}

        <p className="fs-12">By signing up I agree to Spaced Ventures' <Link target="_blank" rel="noopener noreferrer" to="/terms-of-service">Terms of Service</Link> and <Link target="_blank" rel="noopener noreferrer" to="/privacy-policy">Privacy Policy</Link></p>
        <div className="form-group">
          <button type="submit"
            id="inviteAcceptBtn"
            className="mx-auto zeitung-mono weight-300 btn btn-block btn-dark"
            style={{borderRadius: 24}}
            disabled={methods.isSubmitting}
          >
            Accept Invite
          </button>
        </div>

      </form>
    </FormProvider>
  )
}

export default AcceptInviteForm;
