import React from 'react'
import UpdatePaymentSection from './UpdatePaymentSection'
import SubscriptionAgreementSection from './SubscriptionAgreementSection'
import { AiFillWarning } from "react-icons/ai";
import { useUser } from '../../../redux/user';
import SuitabilityQuestionaireSection from './SuitabilityQuestionaireSection';
import { useCurrentOffer } from '../../../redux/offers';
import { OFFER_TYPE } from '../../../config/constants';
import AccreditationVerificationSection from './AccreditationVerificationSection';
import MaterialChangeActionRequired from '../MaterialChangeActionRequired';

const ActionRequiredSection = ({ investment }) => {
  const user = useUser();
  const offer = useCurrentOffer();
  if (user.loading) {
    return null;
  }
  
  const client = user.clients.find((c) => c.id === investment.client_id);
  const suitabilityQuestionaireRequired = offer?.type === OFFER_TYPE.reg_d506b && client.suitability_questionaire_expired
  const aiVerificationRequired = offer?.type === OFFER_TYPE.reg_d506b && client.accreditation_verification_expired
  const needsToAcceptMaterialChange = investment?.cancellation_requested_at === null && investment?.material_change &&  !investment?.material_change.accepted;
  const actionsExist = suitabilityQuestionaireRequired || aiVerificationRequired || investment.payment_failed || !investment.subscription_agreement_signed || needsToAcceptMaterialChange;
 
  return (
    <div>
      {actionsExist && investment.cancelled_at === null ? <div className="p-3 bg-black mb-3 text-white box-required-actions">
        <h6 className="montserrat weight-700 fs-8 mb-3 title-with-icon"><AiFillWarning color="#fff" /> <span>Action Required</span></h6>
        <p className="fs-10">Actions required on the following items to complete your investment:</p>
        { suitabilityQuestionaireRequired && <SuitabilityQuestionaireSection client={client} />}
        { aiVerificationRequired && <AccreditationVerificationSection client={client} />}
        {needsToAcceptMaterialChange && <MaterialChangeActionRequired investmentId={investment?.id} material_change={investment?.material_change} />}
        {((!investment.subscription_agreement_signed || investment.payment_failed) && investment.cancelled_at === null) &&
          <>
            {investment.payment_failed && !investment.payment_resolution_requested_at &&
              <UpdatePaymentSection
                investment={investment}
              />
            }
            {!investment.subscription_agreement_signed &&
              <SubscriptionAgreementSection
                investment={investment}
                investmentId={investment.id}
              />
            }
          </>
        }
      </div> : null}
    </div>
  )
}

export default ActionRequiredSection