import React from 'react';
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import { DEAL_TYPE_EQUITY, DEAL_TYPE_DEBT, OFFER_TYPE } from '../../../config/constants';
import ToastMessage from '../../../components/ToastMessage';
import HookForm from '../../../components/HookForm';
import { offerApi } from '../../../api/offer';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import { setCurrentOffer } from "redux/offers";

function isValidDealTypeAttr(value, context, dealTypeStr) {
  const dealType = context.resolve(Yup.ref('type'))

  if (dealType === dealTypeStr) {
    return value ? true : false;
  } else {
    return true;
  }
}

const minValueError = (text) => `${text || "value"} must be greater than 0`;

const DealInfoSchema = Yup.object().shape({
  name: Yup.string()
    .required('Offer name is required'),
  type: Yup.string()
    .oneOf([OFFER_TYPE.reg_cf, OFFER_TYPE.reg_d506b])
    .required('offer type is required'),
  investor_type: Yup.string()
    .test("investor_type_required", "investor type is required", function (value, context) {
      const offerType = this.resolve(Yup.ref("type"));

      if (offerType === OFFER_TYPE.reg_d506b) {
        return [OFFER_TYPE.investor_direct, OFFER_TYPE.investor_spv, OFFER_TYPE.investor_direct_and_spv].indexOf(value) > -1;
      } else {
        return true;
      }
    }),
  spv_type: Yup.string()
    .test("spv_type_required", "SPV type is required", function (value) {
      const offerType = this.resolve(Yup.ref("type"));
      const investorType = this.resolve(Yup.ref("investor_type"));

      if (offerType === OFFER_TYPE.reg_d506b && [OFFER_TYPE.investor_spv, OFFER_TYPE.investor_direct_and_spv].indexOf(investorType) > -1) {
        return OFFER_TYPE.spv_standard_3c1 === value;
      } else {
        return true;
      }
    }),
  deal_attributes: Yup.object().shape({
    maximum_goal: Yup.number()
      .typeError('offer maximum goal must be numeric')
      .required('offer maximum goal is required')
      .moreThan(0, minValueError("offer maximum goal"))
      .test("maximum_goal_gt_goal", "offer maximum goal must be greater than or equal to offer goal target", function (val) {
        const goal = this.resolve(Yup.ref("goal"));
        return isNaN(goal) ? true : val >= goal;
      }),
    goal: Yup.number()
      .required('offer target goal is required')
      .typeError('offer target goal must be numeric')
      .moreThan(0, minValueError("offer target goal"))
      .test("goal_lt_maximum_goal", "offer goal target must be less than or equal to offer maximum goal", function (val) {
        const maxGoal = this.resolve(Yup.ref("maximum_goal"));
        return isNaN(maxGoal) ? true : val <= maxGoal;
      }),
    minimum_investment: Yup.number()
      .required('minimum investment is required')
      .typeError('minimum investment must be numeric')
      .moreThan(0, minValueError("minimum investment"))
      .test("minimum_investment", "minimum investment must be less than maximum investment", function (val) {
        const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
        return isNaN(maxInvestment) ? true : maxInvestment >= val;
      }),
    entity_minimum_investment: Yup.number()
      .required('entity minimum investment is required')
      .typeError('entity minimum investment must be numeric')
      .moreThan(0, minValueError("entity minimum investment"))
      .test("entity_minimum_investment_gt_minimum_investment", "entity minimum investment must be greater than minimum investment for the offer", function (val) {
        const minInvestment = this.resolve(Yup.ref("minimum_investment"));
        return isNaN(minInvestment) ? true : val >= minInvestment;
      })
      .test("entity_minimum_investment_lt_maximum_investment", "entity minimum investment must be less than maximum investment for the offer", function (val) {
        const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
        return isNaN(maxInvestment) ? true : val <= maxInvestment;
      }),
    maximum_investment: Yup.number()
      .required('maximum investment is required')
      .typeError('maximum investment must be numeric')
      .moreThan(0, minValueError("maximum investment"))
      .test("maximum_investment_gt_minimum_investment", "maximum investment must be greater than minimum investment", function (val) {
        const minInvestment = this.resolve(Yup.ref("minimum_investment"));
        return isNaN(minInvestment) ? true : val >= minInvestment;
      }),
    minimum_international_investment: Yup.number().nullable()
      .moreThan(0, minValueError("minimum international investment"))
      .test("minimum_international_investment_required", "minimum international investment is required", function (val) {
        if (this.options.context.international_investors) {
          return val ? true : false;
        }
        return true;
      })
      .test("minimum_international_investment_gt_minimum_investment", "minimum international investment must be greater than minimum investment for offer", function (val) {
        if (this.options.context.international_investors) {
          const minInvestment = this.resolve(Yup.ref("minimum_investment"));
          return isNaN(minInvestment) ? true : val >= minInvestment;
        }
        return true;
      })
      .test("minimum_international_investment_lt_maximum_investment", "minimum international investment must be less than maximum investment for offer", function (val) {
        if (this.options.context.international_investors) {
          const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
          return isNaN(maxInvestment) ? true : val <= maxInvestment;
        }
        return true;
      }),
    entity_minimum_international_investment: Yup.number().nullable()
      .moreThan(0, minValueError("international entity minimum investment"))
      .test("entity_minimum_international_investment_required", "minimum international entity investment is required", function (val) {
        if (this.options.context.international_investors) {
          return val ? true : false;
        }
        return true;
      })
      .test("entity_minimum_international_investment_minimum_international_investment", "minimum international entity investment must be greater than minimum international investment for offer", function (val) {
        if (this.options.context.international_investors) {
          const minInternationalInvestment = this.resolve(Yup.ref("minimum_international_investment"));
          return isNaN(minInternationalInvestment) ? true : val >= minInternationalInvestment;
        }
        return true;
      })
      .test("entity_minimum_international_investment_minimum_international_investment", "minimum international entity investment must be greater than minimum entity investment", function (val) {
        if (this.options.context.international_investors) {
          const entityMinInvestment = this.resolve(Yup.ref("entity_minimum_investment"));
          return isNaN(entityMinInvestment) ? true : val >= entityMinInvestment;
        }
        return true;
      })
      .test("entity_minimum_international_investment_maximum_investment", "minimum international entity investment must be greater than minimum entity investment", function (val) {
        if (this.options.context.international_investors) {
          const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
          return isNaN(maxInvestment) ? true : val <= maxInvestment;
        }
        return true;
      }),
    close_date: Yup.date()
      .required('closing date is required')
      .test("close_date_in_past", "cannot set closing date in the past", function (value) {
        return value > new Date();
      }),
    type: Yup.string()
      .oneOf([DEAL_TYPE_DEBT, DEAL_TYPE_EQUITY])
      .required('deal type is required'),
    security_type: Yup.string()
      .test("security_type_required", "security type is required", function (value) {
        if (this.resolve(Yup.ref("type")) === DEAL_TYPE_DEBT) {
          return ['crowdsafe', 'convertible_debt'].indexOf(value) > -1;
        } else {
          return true;
        }
      }),
    discount: Yup.number()
      .nullable()
      .test("discount_required", "discount is required", function (value) {
        return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
      }),
    valuation_cap: Yup.number()
      .nullable()
      .moreThan(0, minValueError("valuation cap"))
      .test("", "valuation cap is required", function (value) {
        return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
      }),
    maturity_date: Yup.date()
      .nullable(),
    // .test("", "maturity date is required", function (value) {
    //   return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
    // }),
    interest_rate: Yup.number()
      .nullable(),
    // .test("", "interest rate is required", function (value) {
    //   return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
    // }),
    valuation: Yup.number()
      .nullable()
      .moreThan(0, minValueError("valuation"))
      .test("", "valuation is required", function (value) {
        return isValidDealTypeAttr(value, this, DEAL_TYPE_EQUITY);
      }),
    price_per_share: Yup.number()
      .nullable()
      .moreThan(0, minValueError("price per share"))
      .test("", "price per share required", function (value) {
        return isValidDealTypeAttr(value, this, DEAL_TYPE_EQUITY);
      }),
    share_type: Yup.string()
      .nullable()
      .test("share_type_required", "share type is required", function (value) {
        if (this.resolve(Yup.ref("type")) === DEAL_TYPE_EQUITY) {
          return ['common', 'preferred'].indexOf(value) > -1;
        } else {
          return true;
        }
      }),
    maximum_direct_investors_count: Yup.number()
      .nullable()
      .test("maximum_direct_investors_count_required", "Maximum number of direct investor is required", function (value) {
        const investorType = this.options.context.investor_type;
        const offerType = this.options.context.type;

        if (offerType === OFFER_TYPE.reg_d506b && [OFFER_TYPE.investor_direct, OFFER_TYPE.investor_direct_and_spv].indexOf(investorType) > -1) {
          return parseInt(value) > 0
        } else {
          return true;
        }
      }),
    minimum_direct_investment: Yup.number()
      .nullable()
      .test("minimum_direct_investment_required", "maximum number of direct investor is required", function (value) {
        const investorType = this.options.context.investor_type;
        const offerType = this.options.context.type;

        if (offerType === OFFER_TYPE.reg_d506b && OFFER_TYPE.investor_direct_and_spv === investorType) {
          return parseInt(value) > 0
        } else {
          return true;
        }
      })
      .test("minimum_direct_investment_gt_minimum_investment", "minimum direct investment must be greater than minimum investment", function (value) {
        const investorType = this.options.context.investor_type;
        const offerType = this.options.context.type;

        if (offerType === OFFER_TYPE.reg_d506b && OFFER_TYPE.investor_direct_and_spv === investorType) {
          return parseFloat(value) > this.resolve(Yup.ref("minimum_investment"))
        } else {
          return true;
        }
      }),
  }),
})

function EquityDealInputs() {
  return (
    <>
      <strong><p className='montserrat'>Equity deal info</p></strong>
      <div className="row">
        <div className="col-md-6">
          <HookForm.CurrencyInputField
            id="valuation"
            name="deal_attributes.valuation"
            label="Valuation*"
            placeholder="Valuation*"
          />
        </div>
        <div className="col-md-6">
          <HookForm.CurrencyInputField
            id="price_per_share"
            name="deal_attributes.price_per_share"
            label="Price per share*"
            placeholder="Price per share*"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <HookForm.SelectInput
            id='share_type'
            name="deal_attributes.share_type"
            placeholder="Share Type*"
            options={[{ value: 'common', label: 'Common' }, { value: 'preferred', label: 'Preferred' }]}
          />
        </div>
      </div>
    </>
  )
}

function DebtDealInputs() {
  return (
    <>
      <strong><p>Convertible debt deal info</p></strong>
      <div className="row">
        <div className="col-md-6">
          <HookForm.TextInput
            name="deal_attributes.discount"
            type="number"
            placeholder="Discount*"
          />
        </div>
        <div className="col-md-6">
          <HookForm.CurrencyInputField
            id="valuation_cap"
            name="deal_attributes.valuation_cap"
            label="Valuation cap"
            placeholder="Valuation cap"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <HookForm.DateTimeField
            id="maturity_date"
            name="deal_attributes.maturity_date"
            placeholder="Maturity date"
            showTimeSelect={false}
            showYearSelect={true}
            showMonthSelect={true}
          />
        </div>
        <div className="col-md-6">
          <HookForm.TextInput
            name="deal_attributes.interest_rate"
            type="number"
            placeholder="Interest rate"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <HookForm.SelectInput
            id="security_type"
            name="deal_attributes.security_type"
            placeholder="Type of security"
            options={[{ value: 'crowdsafe', label: 'CrowdSAFE' }, { value: 'convertible_debt', label: 'Convertible Debt' }]}
          />
        </div>
      </div>
    </>
  )
}

function RegD506dInputs({ investorType }) {
  const spvTypeRequired = [
    OFFER_TYPE.investor_spv,
    OFFER_TYPE.investor_direct_and_spv
  ].indexOf(investorType) > -1;

  return (
    <>
      <strong><p>Reg D50b Deal Info</p></strong>
      <div className="row">
        <div className="col">
          <HookForm.SelectInput
            id='investor_type'
            name="investor_type"
            placeholder="Investor Type*"
            options={[{ value: OFFER_TYPE.investor_direct, label: 'Direct' }, { value: OFFER_TYPE.investor_spv, label: 'SPV' }, { value: OFFER_TYPE.investor_direct_and_spv, label: 'Direct and SPV' }]}
          />
        </div>
      </div>
      {spvTypeRequired && (
        <div className="row">
          <div className="col">
            <HookForm.SelectInput
              id='spv_type'
              name="spv_type"
              placeholder="SPV Type*"
              options={[{ value: OFFER_TYPE.spv_standard_3c1, label: 'Standard 3c1' }]}
            />
          </div>
        </div>
      )}
    </>
  )
}

function DirectInvestmentInputs({ investorType }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <HookForm.TextInput
          type='number'
          id="maximum_direct_investors_count"
          name="deal_attributes.maximum_direct_investors_count"
          placeholder="Maximum number of direct investors*"
        />
      </div>
      {investorType === OFFER_TYPE.investor_direct_and_spv &&
        <div className="col-md-6">
          <HookForm.CurrencyInputField
            id="minimum_direct_investment"
            name="deal_attributes.minimum_direct_investment"
            placeholder="Minimum direct investment*"
          />
        </div>
      }
    </div>
  )
}

function InternationalInvestor() {
  return (
    <div className="row">
      <div className="col-md-6">
        <HookForm.CurrencyInputField
          name="deal_attributes.minimum_international_investment"
          label="Minimum investment for international investors"
          placeholder="Minimum investment for international investors"
        />
      </div>
      <div className="col-md-6">
        <HookForm.CurrencyInputField
          name="deal_attributes.entity_minimum_international_investment"
          label="Minimum investment for international entities"
          placeholder="Minimum investment for international entities"
        />
      </div>
    </div>
  )
}

export default function OfferDetailForm({ companyId, offer, editable }) {
  const currentOffer = offer || {};
  const deal = currentOffer.deal || {};
  const isEditForm = currentOffer.id;

  const formDataResolver = async (formData, context, options) => {
    //formData is context for Yup
    return yupResolver(DealInfoSchema)(formData, formData, options);
  };
  const methods = useForm({
    resolver: formDataResolver,
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      name: currentOffer.name || "",
      type: currentOffer.type || "",
      investor_type: currentOffer.investor_type || "",
      spv_type: currentOffer.spv_type || "",
      unlisted: currentOffer.listed_at ? false : true,
      international_investors: currentOffer?.international_investors || false,
      deal_attributes: {
        goal: parseFloat(deal.goal) || '',
        maximum_goal: parseFloat(deal.maximum_goal) || '',
        minimum_investment: parseFloat(deal.minimum_investment) || '',
        entity_minimum_investment: parseFloat(deal.entity_minimum_investment) || '',
        maximum_investment: parseFloat(deal.maximum_investment) || '',
        minimum_international_investment: parseFloat(deal.minimum_international_investment) || null,
        entity_minimum_international_investment: parseFloat(deal.entity_minimum_international_investment) || null,
        close_date: deal.close_date || '',
        valuation_cap: parseFloat(deal.valuation_cap) || null,
        security_type: deal.security_type || '',
        discount: parseFloat(deal.discount) || null,
        maturity_date: deal.maturity_date || null,
        interest_rate: parseFloat(deal.interest_rate) || null,
        price_per_share: parseFloat(deal.price_per_share) || null,
        share_type: deal.share_type || '',
        type: deal.type || '',
        valuation: parseFloat(deal.valuation) || null,
        minimum_direct_investment: parseFloat(deal.minimum_direct_investment) || null,
        maximum_direct_investors_count: parseInt(deal.maximum_direct_investors_count) || null,
      }
    }
  });

  const watchOfferType = methods.watch("type", null);
  const watchInvestorType = methods.watch("investor_type", null);
  const watchDealType = methods.watch("deal_attributes.type", null);
  const watchInternationalInvestor = methods.watch("international_investors", null);

  const queryClient = useQueryClient();
  const saveOffer = useMutation(({ id, attrs }) => {
    return isEditForm ? offerApi.update(id, attrs) : offerApi.create(id, attrs)
  },
    {
      onSuccess: (values) => {
        queryClient.invalidateQueries(['offer', values.id]);
        ToastMessage.success(
          `Offer successfully ${isEditForm ? 'updated' : 'created'}.`
        );
        setCurrentOffer(values);
      },
      onError: (error) => {
        ToastMessage.error("Something went wrong. Please try again!");
        renderRailsErrors(error);
      },
    }
  )

  const onSubmit = (attrs) => {
    attrs.listed_at = attrs.unlisted ? null : (currentOffer.listed_at || new Date());
    delete attrs.unlisted

    if (currentOffer.id) {
      attrs.deal_attributes.id = deal.id;
    }

    return saveOffer.mutate({ id: offer.slug || companyId, attrs: attrs });
  };

  return (
    <div className="deal-form-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1">
          <div className="row mt-3">
            <div className="col">
              <HookForm.TextInput
                name="name"
                placeholder="Offer Name*"
                labelStyle="n1-zindex"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <HookForm.SelectInput
                id='offer_type'
                name="type"
                placeholder="Offer Type*"
                options={[{ value: OFFER_TYPE.reg_cf, label: 'Reg CF' }, { value: OFFER_TYPE.reg_d506b, label: 'Reg D506b' }]}
              />
            </div>
          </div>
          {watchOfferType === OFFER_TYPE.reg_d506b && <RegD506dInputs investorType={watchInvestorType} />}
          <div className="row">
            <div className="col-md-6">
              <HookForm.CurrencyInputField
                id="goal"
                name="deal_attributes.goal"
                label="Offering goal target*"
                placeholder="Offering goal target*"
              />
            </div>
            <div className="col-md-6">
              <HookForm.CurrencyInputField
                id="maximum_goal"
                name="deal_attributes.maximum_goal"
                label="Offering goal max*"
                placeholder="Offering goal max*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HookForm.CurrencyInputField
                id="minimum_investment"
                name="deal_attributes.minimum_investment"
                label="Investment minimum*"
                placeholder="Investment minimum*"
              />
            </div>
            <div className="col-md-6">
              <HookForm.CurrencyInputField
                id="maximum_investment"
                name="deal_attributes.maximum_investment"
                label="Investment maximum*"
                placeholder="Investment maximum*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <HookForm.CurrencyInputField
                id="entity_minimum_investment"
                name="deal_attributes.entity_minimum_investment"
                label="Entity minimum investment*"
                placeholder="Entity minimum investment*"
              />
            </div>
          </div>
          {([OFFER_TYPE.investor_direct, OFFER_TYPE.investor_direct_and_spv].indexOf(watchInvestorType) > -1) && (
            <DirectInvestmentInputs investorType={watchInvestorType} />
          )}
          <div className="row">
            <div className="col">
              <HookForm.DateTimeField
                name="deal_attributes.close_date"
                showTimeSelect={false}
                showYearSelect={true}
                showMonthSelect={true}
                placeholder="Close date*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <HookForm.SelectInput
                id='deal_type'
                name="deal_attributes.type"
                placeholder="Deal Type*"
                options={[{ value: DEAL_TYPE_EQUITY, label: 'Equity' }, { value: DEAL_TYPE_DEBT, label: 'Debt' }]}
              />
            </div>
          </div>
          {watchDealType === DEAL_TYPE_EQUITY && <EquityDealInputs />}
          {watchDealType === DEAL_TYPE_DEBT && <DebtDealInputs />}
          <div className="row">
            <div className="col-md-6">
              <HookForm.CheckboxSwitchField name="unlisted" placeholder="Unlisted" />
            </div>
            <div className="col-md-6">
              <HookForm.CheckboxSwitchField
                name="international_investors"
                placeholder="Allow international investors"
              />
            </div>
          </div>
          {watchInternationalInvestor && <InternationalInvestor />}
          <div className="form-group">
            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={saveOffer.isLoading}>
              Save
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
