import React from "react";
import {
  AdminInviteForm,
  ButtonDark,
} from "admin/components";
import AdminInvitesList from "admin/components/AdminInvitesList";
import AdminsList from "admin/components/AdminsList";
import { PortalizedModalContext } from "contexts/portalizedModalContext";

export default function AdminListPage(props) {
  const inviteAdminModal = React.useContext(PortalizedModalContext);


  const inviteAdmin = () => {
    inviteAdminModal.setModalContent(
      'Add admin account',
      <AdminInviteForm onInviteSuccess={inviteAdminModal.closeModal} />,
      'sm'
    );
    inviteAdminModal.openModal();
  }

  return (
    <div className="bg-lightgrey">
      <div  className="admin-main-content-header">
        <div className="amch-inner1">
        </div>
        <div className="amch-inner2">
          <ButtonDark title='Add Admin' onClick={inviteAdmin} />
        </div>
      </div>
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#admin-list" role="tab">Admins</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#admin-invites" role="tab">Invitations</a>
          </li>
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="admin-list" role="tabpanel">
            <AdminsList />
          </div>
          <div className="tab-pane fade show" id="admin-invites" role="tabpanel">
            <AdminInvitesList />
          </div>
        </div>
      </div>
    </div>
  )
}
