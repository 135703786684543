import axiosClient from 'config/axiosClient';

export const liveEventApi = {
  create,
  fetch,
  list,
  update,
  destroy,
  getChatSession,
  viewerCount,
  suspendedParticipants
};

function create({ id, attrs }) {
  return axiosClient.post(`/offers/${id}/live_events`, { live_event: attrs })
}

function fetch(id) {
  return axiosClient.get(`live_events/${id}`)
}

function list(offerId) {
  return axiosClient.get(`offers/${offerId}/live_events`)
}

function update({ id, attrs }) {
  return axiosClient.put(`/live_events/${id}`, { live_event: attrs })
}

function destroy(id) {
  return axiosClient.delete(`/live_events/${id}`)
}

function getChatSession(id){
  return axiosClient.get(`live_events/${id}/chat_session`)
}

function viewerCount(id){
   return axiosClient.get(`live_events/${id}/viewer_count`)
}

function suspendedParticipants(liveEventId){
  return axiosClient.get(`/live_events/${liveEventId}/suspended_users`);
}
