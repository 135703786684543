import React, {useState} from 'react';
import styled from 'styled-components';
import SendGridPage from './components/SendgridPage';
import LoadingOverlay from 'react-loading-overlay';
import { Helmet } from "react-helmet";

const NewsletterPage = () => {
  const [loading, setLoading] = useState(false)
  return (  

    <LoadingOverlay
    active={loading}
    spinner
    text='Submitting...'
  >    
  <Helmet>
    <title>Newsletter</title>
    <meta
      name="description"
      content="Subscribe to Spaced Ventures newsletter to get updates on the latest space companies raising funds on our platform. Space industry news, helpful guides, company updates and more."
    />
  </Helmet>      
    <div className="about-img-container">
      <NewsletterPageContainer>
        <div className="container">
          <div className="page-header-1">
            <h2 className="hero-header-main">Spaced Ventures<br></br> Newsletter</h2>
            <div className="py-3">
              <SendGridPage setLoading={setLoading} />
            </div>
          </div>
        </div>
      </NewsletterPageContainer>    
    </div>
    </LoadingOverlay>
  );
}
 
export default NewsletterPage;

const NewsletterPageContainer =  styled.div`
height: 100vh;

@media (max-width: 700px) {
  height: 700px;
}
`;
