import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FixedButtonBox from '../shared/FixedButtonBox';
import { useUser, createInvestorAccount, updateInvestorAccount } from 'redux/user'
import * as Yup from 'yup';
import { getSSNMasking, getStatesLabel, getStatesOption, getTaxIdLabel, getZipCodelabel, getphoneNumberMask } from 'jsUtils/stringUtils';
import { preventDefaultSubmit } from '../../../jsUtils/formUtils';
import { diffYearsFromNow, formatDate } from '../../../jsUtils/dateTimeUtils';
import MaskedInput from "react-text-mask";
import {googleAddresses} from '../../../jsUtils/googleAddresses'
import _ from 'underscore.string'
import postalCodes from 'postal-codes-js'
import useGeoInfo from "../../../lib/useGeoInfo";
import { PO_BOX_REGEX, RESTRICTED_STATES } from 'config/constants';
import FormikValidationsRollbarReporter from '../shared/FormikValidationsRollbarReporter';
import ReactTooltip from 'react-tooltip';
import DatePickerField from '../../../components/Formik/DatePickerField';
import LoadingOverlay from 'react-loading-overlay';
import { OFFER_TYPE, INVESTOR_TYPE } from '../../../config/constants';

export default function InvestorDetailsForm({
  submitButtonText="Continue",
  header="",
  subHeader="",
  showSidebar=false,
  onSubmit=null,
  investorDetails,
  redirect=false,
  offer = null,
  type,
  userScope
}) {
  const { Country, State } = useGeoInfo();
  const { offerId } = useParams()
  const user = useUser();
  const [states, setStates] = useState([])
  const [countryCode, setCountryCode] = useState('US');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const allowInternationalInvestors = offer?.international_investors || false;
  const COUNTRIES = useMemo(
    () => {
      if(type === 'preoffer'){
        return Country.list();
      } else  {
        return !offerId || (allowInternationalInvestors && offerId) ? Country.forInternationalOffers() : [Country.findByISO2Code("US")]
      }
    },
    [allowInternationalInvestors, offerId, Country]
  );

  useEffect(() => {
    if (investorDetails?.country) {
      setCountryCode(investorDetails?.country)
    }
  }, [investorDetails])

  useMemo(()=>{
    setStates(State.withISO2CountryCode(countryCode));
  },[countryCode])

  function addressChangeHandler(e, handleChange, setFieldValue,setInvalidAddress, selectedCountry) {
    handleChange(e);
    const addressElement = document.getElementById('street_address_1');
    const fieldsObj = {postal_code: 'postal_code', address: 'street_address_1'};
    googleAddresses(addressElement, fieldsObj, setFieldValue,setInvalidAddress, selectedCountry)

  }
  function countryChangeHandler(e, handleChange, setCountryCode, setFieldValue){
    const fieldsObj = {postal_code: 'postal_code', address: 'street_address_1', city:'city'};
    handleChange(e);
    if(e.target.value !== 'US')
      setFieldValue('domicile', 'non-resident')
    else setFieldValue('domicile', 'U.S. Citizen')
    setFieldValue('phone' , '')
    setCountryCode(e.target.value);
    setFieldValue('region', '');
    setFieldValue(fieldsObj.address,'')
    setFieldValue(fieldsObj.city,'')
    setFieldValue(fieldsObj.postal_code,'')
  }

  return (
  <LoadingOverlay active={user.loading} spinner text="Loading">
    <div className="investor-formbox">
      <Formik
        initialValues={{
          first_name: investorDetails?.first_name || user.first_name,
          last_name: investorDetails?.last_name || user.last_name,
          date_of_birth: investorDetails?.date_of_birth || "",
          gender: investorDetails?.gender || "",
          street_address_1: investorDetails?.street_address_1 || "",
          street_address_2: investorDetails?.street_address_2 || "",
          tax_id_number: "",
          country: investorDetails?.country || "US",
          city: investorDetails?.city || "",
          region: investorDetails?.region || "",
          postal_code: investorDetails?.postal_code || "",
          phone: investorDetails?.phone || "",
          domicile: investorDetails?.domicile
        }}
        enableReinitialize={true}
        validationSchema={ Yup.object().shape({
          first_name: Yup.string()
            .required("Legal first name is required")
            .max(255, "Legal first name too long, max-length 255 characters"),

          last_name: Yup.string()
            .required("Legal last name is required")
            .max(255, "Legal last name too long, max-length 255 characters")
            .min(2,"Legal last name needs to be atleast 2 characters long"),

          street_address_1: Yup.string()
            .test("","", function (val) {
              if(val && val.match(PO_BOX_REGEX)) return this.resolve(this.createError({message: "You cannot use a PO box address, please use a residential address."}))
              else if (invalidAddress === true) return this.resolve(this.createError({message: "Invalid Address. Please ensure that you are entering valid address."}))
              else return true;
            })
            .required("Address is required")
            .max(255, "Address too long, max-length 255 characters"),

          street_address_2: Yup.string()
          .test("", "", function(val) {
            if(!val?.trim()) return true

            if(val && val.length >= 1 && val.match(/^[\w\s-]*$/g)){
              return true;
            }

            return this.resolve(this.createError({message: "Invalid Unit/Suite. Only allowed number, letter."}))

          }),
          tax_id_number: Yup.string()
          .withMutation((currentSchema) => investorDetails?.id ? currentSchema : currentSchema.required("Tax ID required"))
          .test('len', 'Invalid Tax Id', val => {
            if(val){
              const length = val.match(/[a-zA-Z0-9]/g).length;
              return length >= 6 && length <= 20;
            }

            return true;
          }),

          city: Yup.string()
            .required('City is required'),

          region: Yup.string()
            .test('restrict', '', function (value) {
              if(type === 'preoffer') { return true }

              const countryCode = this.resolve(Yup.ref('country'))
              if(RESTRICTED_STATES[countryCode].includes(value)) {
                return this.resolve(this.createError({message: `Cannot currently support investors from ${State.findByStateCodeAndISO2CountryCode(value, countryCode).name}`}))
              } else {
                return true;
              }
            })
            .required(`${getStatesLabel(countryCode)} is required`
            ),

          postal_code: Yup.string()
            .required('Zip code is required')
            .test('validatePostalCode',  'Invalid postal code', val => postalCodes.validate(countryCode, val) === true),

          phone: Yup.string()
            .required('Phone number is required')
            .test('len',function (val) {          
              if(val?.match(/\d/g)?.length < 7 && val?.match(/\d/g)?.length <= 11)
              return this.resolve(this.createError({message: "Please enter a valid phone number"}))
              else return true      
            }),

          date_of_birth: Yup.date()
            .typeError('Date of Birth is required')
            .required('Date of Birth is required')
            .test("", "", function (value) {
              if(diffYearsFromNow(value) < 18) {
                return this.resolve(this.createError({message: "You must be 18 years of age to open an investment account"}))
              } else {
                return true;
              }
            }),

          gender: Yup.string()
            .oneOf(['male', 'female', 'other'])
            .required('Gender is required'),

          country: Yup.string()
            .required('Country is required'),

          domicile: Yup.string()
            .required('Domicile is required')
            .typeError('Domicile is required')
        })}
        onSubmit={async (values, { }) => {
          values.name = _.clean(values.name)
          values.entity_type = 'person';
          values.tax_id_number = values.tax_id_number.replace(/-/g, '');
          values.phone = values.phone.replace(/_/g, '');

          if(values.date_of_birth instanceof Date){
            values.date_of_birth = formatDate(values.date_of_birth, 'yyyy-MM-dd');
          }

          if(countryCode === 'US'){
            values.us_resident = true;
          } else {
            values.us_resident = false;
          }

          if (investorDetails?.id) {
            delete values.tax_id_number;
            return updateInvestorAccount(investorDetails.id, values, onSubmit, userScope);
          }
          else if(offer){
            let nextStep;

            if (offer?.type === OFFER_TYPE.reg_d506b && user.investor_type === INVESTOR_TYPE.accredited) {
              if(user.broker_dealer_terms_accepted){
                nextStep = 'invest';
              } else {
                nextStep = 'broker_dealer_terms';
              }
            } else {
              nextStep = 'limit-calculator'
            }
            return createInvestorAccount(user.id, values, offerId, redirect, onSubmit, nextStep)
          } else{
            return createInvestorAccount(user.id, values, null, redirect, onSubmit)
          }
        }}
      >
        {({ values, touched, errors, setFieldValue, isSubmitting, handleChange, handleBlur }) => (
          <Form onKeyDown={preventDefaultSubmit}>
            <div className="container">
              <div className="row">
                <div className={`${showSidebar ? 'col-md-9 py-5 pr-xl-5' : 'col-12'}`}>
                  <div className={`invest-form-fields investor-formbox-inner1 ${showSidebar ? 'pr-xl-5' : ''}`}>
                    {header !== "" && <h5 className="zeitung-micro weight-700">{ header }</h5>}
                    {subHeader !== "" && <span className="hint-large zeitung-micro">{ subHeader }</span>}
                    { offer && <div className="mt-3 important-note arrow-bottom">
                      <span className="poppins fs-14">You must reside in one of the following countries to invest in {offer?.company?.name}</span>
                    </div> }

                    <div className="form-row mt-3">
                      <div className="col-md-6 px-1">
                        <div className="form-label-group">
                          <Field type="select"
                            id="country"
                            className={`form-control ${touched.country && errors.country ? "is-invalid" : ""}`}
                            name="country"
                            placeholder="Country"
                            component="select"
                            onChange={(e) => countryChangeHandler(e, handleChange, setCountryCode, setFieldValue)}
                          >
                            <option value="">Country</option>
                            {
                              COUNTRIES.map((country, index) => <option key={index} value={country.iso2}>{country.name}</option>)
                            }
                          </Field>
                          <label htmlFor="country">Eligible country of residence</label>
                          <ErrorMessage name="country" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="select"
                            id="region"
                            className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                            name="region"
                            placeholder="Country"
                            component="select"
                          >
                          <option value="">{getStatesOption(countryCode)}</option>
                          {
                            states?.map((state, index) => {
                              if(state)
                                return <option key={index} value={state.state_code}>{state.name}</option>
                            })
                          }
                          </Field>
                          <label htmlFor="region">{getStatesLabel(countryCode)}</label>
                          <ErrorMessage name="region" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      {values.country === "US" ? (
                        <>
                          <div className="form-check form-check-inline">
                            <Field
                              type="radio"
                              name="domicile"
                              value="U.S. Citizen"
                              id="domicileUSCitizen"
                            />
                            <label
                              className="ml-1 form-check-label"
                              htmlFor="domicile"
                            >
                              U.S. Citizen
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Field
                              type="radio"
                              name="domicile"
                              value="U.S. Resident"
                            />
                            <label
                              className="ml-1 form-check-label"
                              htmlFor="domicile"
                            >
                              U.S. Resident
                            </label>
                          </div>
                        </>
                      ) : (
                        <div className="form-check form-check-inline">
                          <Field
                            checked={true}
                            type="radio"
                            name="domicile"
                            value="non-resident"
                          />
                          <label
                            className="ml-1 form-check-label"
                            htmlFor="domicile"
                          >
                            None US Resident
                          </label>
                        </div>
                      )}
                      <ErrorMessage
                        name="domicile"
                        component="div"
                        className="d-block invalid-feedback text-left"
                      />
                    </div>
                    <div className="form-row">
                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="text"
                            id="first_name"
                            name="first_name"
                            className={`form-control ${touched.first_name && errors.first_name ? "is-invalid" : ""}`}
                            placeholder="Legal first name"
                          />
                          <label htmlFor="first_name">Legal first name</label>
                          <ErrorMessage name="first_name" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="text"
                            id="last_name"
                            name="last_name"
                            className={`form-control ${touched.last_name && errors.last_name ? "is-invalid" : ""}`}
                            placeholder="Legal last name"
                          />
                          <label htmlFor="last_name">Legal last name</label>
                          <ErrorMessage name="last_name" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <div className={`form-datepicker-group ${values?.date_of_birth ? 'date-selected' : ''}`}>
                          <DatePickerField name="date_of_birth" placeholder="Birthday" initialValue={values?.date_of_birth} autocomplete="off"/>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="select"
                            id="gender"
                            className={`form-control ${touched.gender && errors.gender ? "is-invalid" : ""}`}
                            name="gender"
                            placeholder="Gender"
                            component="select"
                          >
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </Field>
                          <label htmlFor="gender">Select Gender</label>
                          <ErrorMessage name="gender" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    {!investorDetails?.id && <div className="form-row">
                      <div className="col-md-12 px-1">
                        <div className="form-label-group">
                          <Field name="tax_id_number">
                            {({ field }) => {
                              const maskValue = getSSNMasking(values.country);

                              if(maskValue) {
                                return <MaskedInput
                                  {...field}
                                  mask={maskValue}
                                  id="tax_id_number"
                                  placeholder="SSN"
                                  type="text"
                                  className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                                />
                              } else {
                                return <input
                                  {...field}
                                  type="text"
                                  id="tax_id_number"
                                  className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                                />
                              }
                            }}
                          </Field>
                          <label htmlFor="tax_id_number">{getTaxIdLabel(countryCode)}</label>
                          <ErrorMessage name="tax_id_number" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>}
                    <div className="form-row">
                      <div className="col">
                        <div className="form-label-group">
                          <Field
                            type="text"
                            disabled={!values?.country}
                            id="street_address_1"
                            name="street_address_1"
                            className={`form-control ${touched.street_address_1 && errors.street_address_1 ? "is-invalid" : ""}`}
                            placeholder="Address"
                            onChange={(e)=> addressChangeHandler(e, handleChange, setFieldValue, setInvalidAddress, values.country)}
                          />
                          {!values?.country && <ReactTooltip offset="{'left':60}" id="street_address_1" type="light" effect="solid" className="spaced-tooltip zeitung" arrowColor='#000'>
                            <span>{`Please select country first.`}</span>
                          </ReactTooltip> }
                          <label className={`${!values?.country ? 'disabled-field' : ''}`} htmlFor="street_address_1" data-event-off='dblclick' data-tip data-for='street_address_1'>Address</label>
                          <ErrorMessage name="street_address_1" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-label-group">
                          <Field
                            type="text"
                            id="street_address_2"
                            name="street_address_2"
                            className={`form-control ${touched.street_address_2 && errors.street_address_2 ? "is-invalid" : ""}`}
                            placeholder="Unit/Suite #"
                          />
                          <label htmlFor="street_address_2">Unit/Suite #</label>
                          <ErrorMessage name="street_address_2" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="text"
                            id="city"
                            name="city"
                            className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                            placeholder="City"
                          />
                          <label htmlFor="city">City</label>
                          <ErrorMessage name="city" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="string"
                            id="postal_code"
                            name="postal_code"
                            className={`form-control ${touched.postal_code && errors.postal_code ? "is-invalid" : ""}`}
                            placeholder="Zip code"
                          />
                          <label htmlFor="postal_code">{getZipCodelabel(countryCode)}</label>
                          <ErrorMessage name="postal_code" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-label-group">
                          <Field name="phone">
                            {({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={getphoneNumberMask(countryCode)}
                                id="phone"
                                placeholder="Phone number"
                                type="text"
                                className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                              />
                            )}
                          </Field>
                          <label htmlFor="phone">Phone number</label>
                          <ErrorMessage name="phone" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className={`${!showSidebar ? 'portalized-modal-footer': ''}`}>
                      <FixedButtonBox disabled={!values?.country} isSubmitting={isSubmitting} buttonTitle={submitButtonText } />
                    </div>
                  </div>
                </div>
                {showSidebar && <div className="col-md-3 py-5 bg-lightgrey investor-formbox-inner2">
                  <h5 className="zeitung-micro weight-700">Why do we need this?</h5>
                  <p>We need your info to verify your identity for compliance and legal reasons.</p>
                  <div className="mt-4">
                    <h5 className="zeitung-micro weight-700">Not available in your country?</h5>
                    <p><a href="https://airtable.com/shrWXBhFeEeUkhB5D" target="_blank" rel="noopener noreferrer">Join</a> our international investors waiting list and we will let you know when Spaced Ventures becomes available in your location.</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="zeitung-micro weight-700">Investing as an Entity?</h5>
                    <p>We still need your personal information. If you need to invest through an LLC that you control, that option will be available on the investment form.</p>
                  </div>
                </div>}
              </div>
            </div>
            <FormikValidationsRollbarReporter form="Investor Details (Individual)" />
          </Form>
        )}
      </Formik>
    </div>
  </LoadingOverlay>
  )
}
