import { createReduxModule } from 'hooks-for-redux'
import ToastMessage from '../components/ToastMessage';
import { investmentLimitApi } from 'api/investmentLimit';

const storeKey = 'investmentLimit'
const initialState = {
  error: null,
  loading: false,
  investment_limit_info: {}
};

export const [useInvestmentLimit, {_setError, _setLoading, _setInvestmentLimitInfo}, investmentLimitStore] = createReduxModule(storeKey, initialState, {
  _setError: (state, error) => {
    return {...state, error}
  },

  _setLoading: (state, loading) => {
    return {...state, loading}
  },

  _setInvestmentLimitInfo: (state, investment_limit_info) => {
    return { ...state, investment_limit_info }
  },
})

export const fetchInvestmentLimit = (attrs = {}) => {
  return investmentLimitApi.fetchInvestmentLimit(attrs).then(
    response => {
      _setInvestmentLimitInfo(response);
    },
    error => {
      console.error(error)
      ToastMessage.error("Failed to fetch investment limit.")
    }
  )
}