import PropTypes from "prop-types";
import React from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { FaEnvelope, FaTrash } from "react-icons/fa";
import {
  ResponsiveTable,
  TableActionsContainer,
  TableActionItem,
  Paginator,
  OverviewSubheader,
  TextButton,
} from "admin/components";
import usePagination from "hooks/usePagination";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import ToastMessage from "../../../components/ToastMessage";
import { usePortalizedModal } from '../../../components/PortalModal';
import axiosClient from "config/axiosClient"
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import InvitationForm from "./InvitationForm";
import BulkInvitationForm from "./BulkInvitationForm";
import { formatDate } from "../../../jsUtils/dateTimeUtils";
import { downloadFile } from 'jsUtils/fileUtils';

const Invitations = ({eventType, eventId}) => {
  const pageConfig = usePagination();
  const deleteModal = usePortalizedModal(`confirm-delete-invite-${eventType}-${eventId}`)
  const resendModal = usePortalizedModal(`confirm-resend-invite-${eventType}-${eventId}`)
  const createInviteModal = usePortalizedModal(`create-invite-${eventType}-${eventId}`)
  const bulkInviteModal = usePortalizedModal(`create-bulk-invite-${eventType}-${eventId}`)
  const queryClient = useQueryClient();

  const invitations = useQuery(
    ['event-guest-list', eventType, eventId, pageConfig.current.page],
    () => {
      return axiosClient.get(`/admin/${eventType}/${eventId}/invitations`, {params: {...pageConfig.current}});
    },
    { keepPreviousData : true }
  );

  const resendMutation = useMutation(
    [],
    (invitationId) => {
      return axiosClient.post(`/admin/${eventType}/${eventId}/invitations/${invitationId}/resend`);
    },
    {
      onSuccess: () => {
        ToastMessage.success(`Invitation resent successfully.`);
      },
      onError: (error) => {
        console.log(error);
        renderRailsErrors(error);
      },
      onSettled: () => {
        resendModal.close();
      }
    },
  )

  const deleteMutation = useMutation(
    [],
    (invitationId) => {
      return axiosClient.delete(`/admin/${eventType}/${eventId}/invitations/${invitationId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['event-guest-list', eventType, eventId]);
        ToastMessage.success(`Invitation deleted successfully.`);
      },
      onError: (error) => {
        console.log(error);
        renderRailsErrors(error);
      },
      onSettled: () => {
        deleteModal.close();
      }
    },
  )
  
  const createMutation = useMutation(
    [],
    (values) => {
      return axiosClient.post(`/admin/${eventType}/${eventId}/invitations`, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['event-guest-list', eventType, eventId]);
        ToastMessage.success(`Invitation created successfully.`);
      },
      onError: (error) => {
        console.log(error);
        renderRailsErrors(error);
      },
      onSettled: () => {
        createInviteModal.close();
      }
    },
  )

  const createBulkInviteMutation = useMutation(
    [],
    ({values}) => {
      return axiosClient.post(
        `/admin/${eventType}/${eventId}/invitations/bulk_invite`, values,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
    },
    {
      onSuccess: (response) => {
        console.log({response});
        queryClient.invalidateQueries(['event-guest-list', eventType, eventId]);
        ToastMessage.success(`Bulk Invitations created successfully.`);
        downloadFile(response, `bulk_invitation_log.csv`)
      },
      onError: (error) => {
        console.log(error);
        renderRailsErrors(error);
      },
      onSettled: () => {
        bulkInviteModal.close();
      }
    },
  )

  const resendHandler  = (invitation) => {
    resendModal.open({
      title: 'Resend Invitation',
      body: (
        <>
          <p>Are you sure you want to resend the invitation to - {invitation.guest_email}?</p>
          <div className="portalized-modal-footer">
            <button className="btn-black"
              onClick={() => {
                resendMutation.mutate(invitation.id);
              }}>Rensend Invite</button>
            <button className="btn-black-outline" onClick={resendModal.close }>Cancel</button>
          </div>
        </>
      ),
      onRequestClose: resendModal.close,
      size: 'sm'
    });
  }

  const deleteHandler  = (invitation) => {
    deleteModal.open({
      title: 'Delete Invitation',
      body: (
        <>
          <p>Are you sure you want to delete the invitation to - {invitation.guest_email}?</p>
          <div className="portalized-modal-footer">
            <button className="btn-black"
              onClick={() => {
                deleteMutation.mutate(invitation.id);
              }}>Delete Invitation</button>
            <button className="btn-black-outline" onClick={deleteModal.close }>Cancel</button>
          </div>
        </>
      ),
      onRequestClose: deleteModal.close,
      size: 'sm'
    });
  }

  const tableData = React.useMemo(
    () => {
      if (invitations?.isSuccess)
        return invitations.data.data
      else
        return []
    },
    [invitations]
  );

  const columns = [
    {
      Header: 'Guest Email',
      accessor: 'guest_email',
    },
    {
      Header: 'Last Sent At',
      accessor: 'sent_at',
      Cell: ({value}) => value ? formatDate(value) : null,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({value, row}) => (
        <TableActionsContainer>
          <TableActionItem tooltipText="Resend Invitation" onClick={() => { resendHandler(row.original) }}>
            <FaEnvelope className='fs-14 color-black'/>
          </TableActionItem>
          <TableActionItem tooltipText="Delete Invitation" onClick={() => { deleteHandler(row.original) }}>
            <FaTrash className='fs-14 color-black'/>
          </TableActionItem>
        </TableActionsContainer>
      )
    }
  ];

  if (invitations?.isLoading) {
    return (
      <>Loading</>
    )
  }

  const createInvite = () => {
    createInviteModal.open({
      title: 'Create Invite',
      body: <InvitationForm eventType={eventType} eventId={eventId} createMutation={createMutation} />,
      onRequestClose: createInviteModal.close,
      size: 'sm'
    });
  }

  const createBulkInvite = () => {
    bulkInviteModal.open({
      title: 'Bulk Invite',
      body: <BulkInvitationForm eventType={eventType} eventId={eventId} bulkInviteMutation={createBulkInviteMutation} />,
      onRequestClose: bulkInviteModal.close,
      size: 'sm'
    });
  }

  return (
    <>
      <OverviewSubheader
        left='Manager invitations'
        right={
          <>
            <TextButton title='Bulk Invite' onClick={createBulkInvite} />
            &nbsp;|
            <TextButton title='Add Invitation' onClick={createInvite} />
          </>
        }
      />
      <Paginator
        theme="dark"
        onPage={pageConfig.setPage}
        meta={invitations?.data?.pagy}
        loading={invitations?.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={invitations?.isLoading}
        loadingMsg="Loading invitations..."
        noDataMsg="No invitations found."
      />
    </>
  );
};

Invitations.defaultProps = {};

Invitations.propTypes = {
  requiredProp: PropTypes.string.isRequired,
  prop: PropTypes.string,
};
export default Invitations;
