import React, {useEffect} from 'react';
import { MdChevronLeft } from 'react-icons/md'

const sectorIdColIndex = 4
const parentSectorIdColIndex = 5

export default function TreeMapChart({ data, options = {}, updateSector, containerId, treeButtonContainerId}) {
  useEffect( () =>{
    if (data?.length > 2 && data[1][0] != 'All Sectors') {
      const button = document.createElement('div');
      button.setAttribute('class', 'roll-up-btn')
      button.setAttribute('type', 'button')
      button.addEventListener('click', () => { updateSector(data[1][parentSectorIdColIndex]) })
      button.innerHTML = `<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-90deg-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
      </svg>`;
      document.getElementById(treeButtonContainerId) && document.getElementById(treeButtonContainerId).appendChild(button);
    }
    return () => {
      if (document.getElementById(treeButtonContainerId))
        document.getElementById(treeButtonContainerId).innerHTML = ""
    }
  })

  if(data?.length <= 1){
    document.getElementById(containerId).innerHTML = `
      <div class="flex-cover">
        No companies match your query.
      </div>
    `
    return null
  }

  if(data) {
    window.google.charts.setOnLoadCallback(function() {
      const chartData = window.google.visualization.arrayToDataTable(data)
      const tree = new window.google.visualization.TreeMap(document.getElementById(containerId))

      const selectHandler = () => {
        const selection = tree.getSelection()
        if (selection.length === 1) {
          const [selectedItem] = selection
          const { row } = selectedItem
          tree.setSelection([])

          const sectorId = chartData.getValue(row, sectorIdColIndex)
          if(sectorId) updateSector(sectorId)
        }
      }

      const errorHandler = (error) => {
        document.getElementById(containerId).innerHTML = `
          <div class="flex-cover">
            No companies match your query. tree
          </div>
        `
      }

      window.google.visualization.events.addListener(tree, 'select', selectHandler);
      window.google.visualization.events.addListener(tree, 'error', errorHandler);

      tree.draw(chartData, options);
    });
  }
  return null

};

