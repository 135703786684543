import React from 'react'
import { OFFER_TYPE } from '../../../config/constants';

export default function OfferTerms ({offerType, openUpdateLimitModal}){
  const isRegDOffer = offerType === OFFER_TYPE.reg_d506b;
  if (isRegDOffer) {
    return (
      <div>
      <div className="bordered-item">
        <p>I, (the “Investor”) hereby represent and warrant as of the date of this investment that:</p>
      </div>
      <div className="bordered-item term-item">
        <p>1. The Investor hereby authorizes the Company to debit the funds necessary to purchase the securities from the payment source provided by the Investor.</p>
      </div>
      <div className="bordered-item term-item">
        <p>2. The Investor will truthfully complete all documentation requests regarding Investor’s identity and qualifications with respect to the investment, if requested by the Company, in fifteen (15) days or less.</p>
      </div>
      <div className="bordered-item term-item">
        <p>3. The Investor's consideration for the securities will not be derived from money laundering or similar activities deemed illegal under federal laws and regulations.</p>
      </div>
      <div className="bordered-item term-item">
        <p>4. The Investor has reviewed the Spaced Ventures’ educational materials delivered, understands that the entire amount of his or her investment may be lost, and is in a financial condition to bear the loss of the investment.</p>
      </div>
      <div className="bordered-item term-item">
        <p>5. The Investor understands that investing in securities involves risk, and the investor should not invest any funds in an offering unless he or she can afford to lose the entire amount of his or her investment.</p>
      </div>
      <div className="bordered-item term-item">
        <p>6. The Investor understands that there are restrictions on his or her ability to cancel an investment commitment and obtain a return of his or her investment; and the investor only has the ability to cancel their investment up to 48 hours before the target date.</p>
      </div>
      <div className="bordered-item term-item">
        <p>7. The investor resides within an eligible region for this offer and attests that they are abiding by their local jurisdiction's securities and investment regulation.</p>
      </div>      
    </div>      
    )
  } else {
    return (
      <div>
        <div className="bordered-item">
          <p>I, (the “Investor”) hereby represent and warrant as of the date of this investment that:</p>
        </div>
        <div className="bordered-item term-item">
          <p>1. The Investor hereby authorizes the Company to debit the funds necessary to purchase the securities from the payment source provided by the Investor.</p>
        </div>
        <div className="bordered-item term-item">
          <p>2. The Investor’s investment is within their investment limits based the Rule 100 A (2) of Regulation Crowdfunding. Your investment limit is calculated in the <span className="text-primary cursor-pointer zeitung-micro update-limit-text" onClick={() => openUpdateLimitModal()}>Investment Limit Calculator</span> which is also available in your Profile > Account Settings > Investment Limit.</p>
        </div>
        <div className="bordered-item term-item">
          <p>3. The investor hereby acknowledges that they have indicated all Reg CF investment made on other platforms within the past 12 months. If not, please update this information on the <span className="text-primary cursor-pointer zeitung-micro update-limit-text" onClick={() => openUpdateLimitModal()}>Investment Limit Calculator</span>.</p>
        </div>
        <div className="bordered-item term-item">
          <p>4. The Investor will truthfully complete all documentation requests regarding Investor’s identity and qualifications with respect to the investment, if requested by the Company, in fifteen (15) days or less.</p>
        </div>
        <div className="bordered-item term-item">
          <p>5. The Investor understands that it may be difficult for them to resell securities acquired in reliance on section 4(a)(6) of the Securities Act and Regulation Crowdfunding.</p>
        </div>
        <div className="bordered-item term-item">
          <p>6. The Investor's consideration for the securities will not be derived from money laundering or similar activities deemed illegal under federal laws and regulations.</p>
        </div>
        <div className="bordered-item term-item">
          <p>7. The Investor has reviewed the Spaced Ventures’ educational materials delivered, understands that the entire amount of his or her investment may be lost, and is in a financial condition to bear the loss of the investment.</p>
        </div>
        <div className="bordered-item term-item">
          <p>8. The Investor understands that investing in securities offered and sold in reliance on section 4(a)(6) of the Securities Act and Regulation Crowdfunding involves risk, and the investor should not invest any funds in an offering made in reliance on section 4(a)(6) of the Securities Act unless he or she can afford to lose the entire amount of his or her investment.</p>
        </div>
        <div className="bordered-item term-item">
          <p>9. The Investor understands that there are restrictions on his or her ability to cancel an investment commitment and obtain a return of his or her investment; and the investor only has the ability to cancel their investment up to 48 hours before the target date.</p>
        </div>
        <div className="bordered-item term-item">
          <p>10. The investor resides within an eligible region for this offer and attests that they are abiding by their local jurisdiction's securities and investment regulation.</p>
        </div>      
      </div>
    )  
  }

}