import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { NavLink } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import DealTerms from '../components/DealTerms';
import DocumentSection from '../components/shared/DocumentSection';
import Modal from 'react-modal';
import { fetchEntityDocuments, _clearInvestment } from 'redux/user'
import InvestmentConfirmation from '../Modals/Modal.InvestmentConfirmation';
import { UploadDocsModal, InvestmentSection, InvestmentBanner} from '../components/InvestmentDetails/'
import "./Page.investmentDetails.scss";
import { useInvestment, useOffer } from 'investor/queries';
import ActionRequiredSection from '../components/InvestmentDetails/ActionRequiredSection';


const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999
  },
  content: {
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: '0',
    padding: '0',
    overFlow: 'unset',
    backgroundColor: '#111',
    zIndex: '2',
    minHeight: '100vh'
  }
}


export default function InvestmentDetails({ location }) {
  const { investmentId } = useParams()
  const [loader, setLoader] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [getEntityDocuments, setGetEntityDocuments] = useState(false);
  const { data: investment, isLoading: isLoadingInvestment } = useInvestment(investmentId);
  const {data: offer, isLoading: isLoadingOffer} = useOffer(investment?.offer_slug);

  useEffect(() => {
    const showModal = location?.state?.showModal;
    setConfirmationModal(showModal || false)

    return () => {
      _clearInvestment();
    }
  }, []);

  useEffect(() => {
    if (investment) {
      fetchEntityDocuments(investment.client_id)
    }
  }, [investment])

  useEffect(() => {
    if (getEntityDocuments) {
      fetchEntityDocuments(investment.client_id)
      setGetEntityDocuments(false)
    }
  }, [getEntityDocuments])

  const loading = isLoadingOffer || loader || isLoadingInvestment;

  if (isLoadingOffer || isLoadingInvestment) {
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading ...'
      />
    );
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Loading ...'
    >
      <Modal
        ariaHideApp={false}
        isOpen={showConfirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
        style={modalStyles}
      >
        <div className="modal-inner">
          <InvestmentConfirmation companyName={investment?.company_name} investmentId={investmentId} offer={offer} onClose={() => setConfirmationModal(false)} />
        </div>
      </Modal>

      {showDocsModal && <UploadDocsModal
        clientId={investment.client_id}
        setShowDocsModal={setShowDocsModal}
        setGetEntityDocuments={setGetEntityDocuments}
      />
      }

      {investment !== undefined &&
        <div className="pt-first-section pb-last-section investment-details-page">
          <div className="container">
            <div className="investment-details-inner">
              <ul className="sv-breadcrumb">
                <li><NavLink to={'/account/investments'}>My Investments</NavLink></li>
                <li className="active"><NavLink to={`/offers/${investment.offer_slug}`}>{investment.offer_name}</NavLink></li>
              </ul>
              <div className="row">
                <div className="col-md-7 mb-md-0 mb-4 investment-head">
                  <div className='hide-on-mobile'>
                    <ActionRequiredSection investment={investment} />
                  </div>

                  <InvestmentBanner
                    investment={investment}
                    offer={offer}
                  />

                  <div className="deal-terms-table mt-3">
                    <DealTerms offer={offer} dealInfoHeading={"Deal Info"} />
                  </div>

                  {(offer?.formc_url ||
                    offer?.documents?.length ||
                    offer?.security_document ||
                    offer?.other_documents?.length > 0) && (
                    <div className="mt-4">
                      <h5 className="montserrat weight-700 ">Documents</h5>
                      <DocumentSection offer={offer} showDocumentText={false} />
                    </div>
                  )}
                </div>
                <div className="col-md-5 bg-lightgrey3 py-3 investment-details">
                  <div className="show-on-mobile">
                    <ActionRequiredSection investment={investment} />
                  </div>
                  <InvestmentSection
                    offer={offer}
                    investment={investment}
                    closeDate={offer?.deal?.close_date}
                    setLoader={setLoader}
                    adminFee={offer?.admin_fee}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </LoadingOverlay>
  )
}