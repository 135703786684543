import React, { useState, useEffect, useContext } from 'react';
import { useUser, fetchCurrentUser } from 'redux/user';
import { useInvestmentLimit, fetchInvestmentLimit } from 'redux/investmentLimit';
import "../../Settings.scss";
import LimitCalculator from '../../../../../admin/components/LimitCalculatorForm/LimitCalculatorForm';
import InvestmentLimitSidebar from '../../../../../admin/components/shared/InvestmentLimitSidebar';
import AmountWithCurrency from '../../../../../components/AmountWithCurrency';
import { PortalizedModalContext } from "contexts/portalizedModalContext";


export default function InvestmentLimit() {
  const user = useUser();
  const investmentLimit = useInvestmentLimit().investment_limit_info;

  const [investmentLimitValues, setInvestmentLimitValues] = useState({});
  const limitCalculatorModal = useContext(PortalizedModalContext);


  const openLimitCalculatorModal = () =>{
    limitCalculatorModal.setModalContent(
      `Calculate your Investor Limit`,
      <LimitCalculator
        onSubmit={onSubmit}
      />,
      'lg'
    );
    limitCalculatorModal.openModal();
  }

  const onSubmit = () => {
    limitCalculatorModal.closeModal();
  }
  useEffect(() => {
    getInvestmentLimits();
  }, [user])

  function getInvestmentLimits() {
    const attrs = {
      annual_income: user?.investment_limit?.annual_income || 0,
      net_worth: user?.investment_limit?.net_worth || 0,
      outside_investment_amount: user?.investment_limit?.outside_investment_amount || 0
    }
    fetchInvestmentLimit(attrs)
    if (user && user?.investment_limit) setInvestmentLimitValues(user?.investment_limit)
  }

  return (

      <div id="investment-limit">
        <h4 className="weight-900 mb-3">Investor Limit Details</h4>
        <p>Investor limits affect how much you can invest in Reg CF deals per year.</p>
        <div className="investor-limit-detail-content">
          <div className="ildc-inner1">
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>Yearly Income</label>
                <div>{investmentLimitValues?.annual_income ? <AmountWithCurrency number={investmentLimitValues?.annual_income} /> : "-"}</div>
              </div>
              <div className="iu-box-inner2 pl-3">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>Networth</label>
                <div>{investmentLimitValues?.net_worth ? <AmountWithCurrency number={investmentLimitValues?.net_worth} /> : "-"}</div>
              </div>
              <div className="iu-box-inner2">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>Job title</label>
                <p>{investmentLimitValues?.job_title ? investmentLimitValues?.job_title : "-"}</p>
              </div>
              <div className="iu-box-inner2">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>Job industry</label>
                <p>{investmentLimitValues?.job_industry ? investmentLimitValues?.job_industry : "-"}</p>
              </div>
              <div className="iu-box-inner2">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>12 month crowdfunding history</label>
                <div>{investmentLimitValues?.outside_investment_amount ? <AmountWithCurrency number={investmentLimitValues?.outside_investment_amount} /> : "-"}</div>
              </div>
              <div className="iu-box-inner2">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
            <div className="info-updation-box">
              <div className="iu-box-inner1">
                <label>Accreditation</label>
                <p>{investmentLimitValues?.accredited ? "Yes" : "No"}</p>
              </div>
              <div className="iu-box-inner2">
                <span className="action-btn" onClick={() => openLimitCalculatorModal()}>Edit</span>
              </div>
            </div>
          </div>
          <div className="ildc-inner2">
            <p className="weight-700 poppins">Limit Summary</p>
            <div className="row limit-info-box-container">
              <InvestmentLimitSidebar investmentLimitInfo={investmentLimit} isAccredited={investmentLimitValues?.accredited} isSettingsView={true}/>
            </div>
          </div>
        </div>
      </div>

  )
}
