const ROLLBAR_SCRUB_FEILDS = [
  'old_password',
  'password',
  'password_confirmation',
  'passwordConfirmation',
  'credit_card_number',
  'credit_card_exp_month',
  'credit_card_exp_year',
  'credit_card_cvv',
  'tax_id_number',
  'code',
];

const ROLLBAR_SCRUB_PATHS = [
  'client.javascript.plugins'
];

export const ROLLBAR_CONFIG = {
  enabled: process.env.REACT_APP_ENVIRONMENT === 'development' ? false : true,
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT,
  scrubFields: ROLLBAR_SCRUB_FEILDS,
  scrubPaths: ROLLBAR_SCRUB_PATHS,
  overwriteScrubFields: false,
  autoInstrument: true,
  maxTelemetryEvents: 25,
  scrubTelemetryInputs: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  itemsPerMinute: 180,
  captureIp: 'anonymize',
};