import React from "react";
import { ResponsiveTable, Paginator, OverviewModal } from "admin/components";
import { useAmlList } from "./queries";
import usePagination from "hooks/usePagination";
import { toTitlecase, humanize } from "jsUtils/stringUtils";
import { formatDate } from "jsUtils/dateTimeUtils";
import { history } from "redux/helpers";
import { Switch, Route, useRouteMatch } from "react-router";
import Overview from "./Overview";
import TableActionsContainer from "../../components/TableActionsContainer";
import TableActionItem from "../../components/TableActionItem";
import { FaEye } from "react-icons/fa";
import LinkedData from "../../../components/LinkedData";

function AmlList({ searchQuery, searchStatus }) {
  let { path, url } = useRouteMatch();

  const openShowModal = React.useCallback((row) => {
    history.push(
      `${url}/${row.original.id}/client_id/${row.original.client_id}`
    );
  }, []);

  const closeShowModal = React.useCallback(() => {
    history.push(`${url}`);
  }, []);

  const pageConfig = usePagination();
  const amlList = useAmlList({
    page: pageConfig.current.page,
    per_page: pageConfig.current.per_page,
    searchQuery: searchQuery,
    searchStatus: searchStatus
  });

  const tableData = React.useMemo(() => {
    if (amlList.isSuccess) return amlList.data.data;
    else return [];
  }, [amlList]);

  const columns = React.useMemo(() => {
    if (amlList.isSuccess) {
      return [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ row }) => (
            <LinkedData onClick={() => openShowModal(row)}>
              {toTitlecase(humanize(row.original.client?.name ?? "-"))}
            </LinkedData>
          )
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => toTitlecase(humanize(value)),
        },
        {
          Header: "Entity type",
          // accessor: "id",
          Cell: ({ row }) =>
            toTitlecase(humanize(row.original.client?.entity_type ?? "-")),
        },
        {
          Header: "Created at ",
          accessor: "created_at",
          Cell: ({ value }) => formatDate(value),
        },
        {
          Header: "Notified at",
          accessor: "notified_at",
          Cell: ({ value }) => (value ? formatDate(value) : "-"),
        },
        {
          Header: "Updated at",
          accessor: "updated_at",
          Cell: ({ value }) => (value ? formatDate(value) : "-"),
        },
        {
          Header: "",
          accessor: "id",
          Cell: ({ row, value }) => (
            <TableActionsContainer>
              <TableActionItem tooltipText="View" onClick={() => openShowModal(row)}>
                <FaEye />
              </TableActionItem>
            </TableActionsContainer>
          ),
        },
      ];
    } else {
      return [];
    }
  }, [amlList]);

  return (
    <>
      <Paginator
        theme="dark"
        onPage={pageConfig.setPage}
        meta={amlList?.data?.pagy}
        loading={amlList.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={amlList.isLoading}
        loadingMsg="Loading AMLS..."
        noDataMsg="No AMLs found."
      />
      <Switch>
        <Route
          path={`${path}/:exception_id/client_id/:client_id`}
          component={(props) => (
            <OverviewModal onRequestClose={closeShowModal}>
              <Overview />
            </OverviewModal>
          )}
        />
      </Switch>
    </>
  );
}

export default AmlList;
