import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from "react-router";
import { Redirect, NavLink } from "react-router-dom";
import { useTabs } from 'components/ReactTabs'

function VerticalRoutedTabs({
  basePath,
  baseUrl,
  tabs,
  tabIdAccessor,
  targetPanelIdAccessor,
  defaultTabId,
  tabRenderer = (tab) => tab.title,
  contentRenderer = (tab) => tab.content,
}) {

  const {
    activeTab,
    tabs:tablist,
    getContainerProps,
    getTabProps,
    getPanelProps,
  } = useTabs({
    tabs, tabIdAccessor, targetPanelIdAccessor, defaultTabId
  })

  const defaultTab = tablist.find((tab) => tab[tabIdAccessor] === defaultTabId)

  return (
    <div className="pitch-editor" {...getContainerProps}>
      <div className="px-md-3 px-0 pitch-sidenav">
        <ul className="nav flex-md-column nav-tabs" id="nav-tab">
          {
            tablist.map(tab => (
              <li className="nav-item" {...getTabProps(tab[tabIdAccessor])}>
                <NavLink to={`${baseUrl}/${tab.pathName}`}
                  className={`nav-link ${tab.decoratorClass}`}
                  activeClassName="active"
                  exact
                >
                  { tabRenderer(tab) }
                </NavLink>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="px-md-3 px-0 pitch-right-content">
        <div className="tab-content" id="v-pills-tabContent">
          <Switch>
            {
              tablist.map(tab => (
                <Route key={tab.pathName}
                  path={`${basePath}/${tab.pathName}`}
                  component={(props) => (
                    <div className="tab-pane active" {...getPanelProps(tab[tabIdAccessor])}>
                      { contentRenderer(tab) }
                    </div>
                  )}
                />
              ))
            }
            <Redirect to={`${basePath}/${defaultTab.pathName}`} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

VerticalRoutedTabs.defaultProps = {};

VerticalRoutedTabs.propTypes = {
  basePath: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  tabIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string,
  defaultTabId: PropTypes.string.isRequired,
  tabRenderer: PropTypes.func,
  contentRenderer: PropTypes.func,
};

export default VerticalRoutedTabs;