import { COUNTRIES_FOR_INTERNATION_OFFERS, COUNTRIES_SORTING_ORDER, bannedStatesForCountry } from "./constants";

const STATES = require("./states.json").states;
const COUNTRIES = require("./countries.json").countries
  .sort((current, next) => {
    return ((COUNTRIES_SORTING_ORDER[next.iso2] ?? 0) - (COUNTRIES_SORTING_ORDER[current.iso2] ?? 0));
  });

const useGeoInfo = (context = {}) => {
  const Countries = {
    list: () => COUNTRIES,
    findByISO2Code: (iso2Code) => COUNTRIES.find(c => c.iso2 === iso2Code.toUpperCase()),
    forInternationalOffers: () => COUNTRIES.filter(c => COUNTRIES_FOR_INTERNATION_OFFERS.includes(c.iso2)),
  }

  const States = {
    list: () => STATES,
    withISO2CountryCode: (iso2Code) => {
      const BANNED_STATES = bannedStatesForCountry(iso2Code);
      const validStates = STATES
        .filter(s => s.country_code === iso2Code.toUpperCase())
        .filter(s => !BANNED_STATES.includes(s.state_code))
        return validStates;
    },
    findByStateCodeAndISO2CountryCode: (stateCode, iso2CountryCode) =>
      STATES.find(s => s.country_code === iso2CountryCode.toUpperCase() && s.state_code === stateCode.toUpperCase()),
  };

  return {
    Country: Countries,
    State: States,
  }
}

export default useGeoInfo;
