import React from "react";
import AmountWithCurrency from "../../components/AmountWithCurrency";
import { getProcessingFee } from "jsUtils/currencyUtils";
import { humanize, toTitlecase } from "jsUtils/stringUtils";
import { paymentTypeText } from "../../jsUtils/offerUtils";
import { AuthContext } from "../../contexts/authContext";
import { OFFER_TYPE } from '../../config/constants';
import { useUser } from '../../redux/user';

const InvestmentSummaryRow = ({left, right}) => {
  return(
    <div className="investment-summary">
        <span className="left">{left}</span>
        <span className="right">{right}</span>
      </div>
  )
}

function limitHelper (limits, invAmount = 0) {
  if (!limits) {return null}
  if (!invAmount) {return "-"}

  const spvLimits = limits.filter((limit) => limit.type === "spv")[0];
  const directLimits = limits.filter((limit) => limit.type === "direct")[0];
  const directLimitMinAmountNumber = Number(directLimits?.minAmount);

  if (spvLimits && !directLimits) { return "SPV" }
  if (directLimits && !spvLimits) { return "Direct" }

  if (directLimits && invAmount >= directLimitMinAmountNumber && directLimits?.remainingSpots >= 1 ) {
    return "Direct"
  } else if ( directLimits && invAmount >= directLimitMinAmountNumber && directLimits?.remainingSpots === 0) {
    return "SPV"
  } else if ( directLimits && invAmount < directLimitMinAmountNumber) {
    return "SPV"
  } else if (spvLimits?.remainingSpots === 0) {
    return "SPV"
  }
  return "-"
}

export default function InvestmentSummary({ investingAs, getPaymentType, invAmount = 0, investmentAccountId, adminFee, limits, offerType }) {
  const user = useUser();
  const { authData } = React.useContext(AuthContext);
  const currentClient = user.clients?.find(
    (client) => client.id == investmentAccountId
  );

  const paymentType = getPaymentType();
  const processingFee = getProcessingFee(invAmount, paymentType, currentClient?.country, adminFee);
  return (
    <>
      <h5 className="montserrat weight-700 m-b-15">Investment Summary</h5>
      <InvestmentSummaryRow left={'Investing as'} right={investingAs() ?? '-'} />
      <InvestmentSummaryRow
        left={'Investment Amount'}
        right={<AmountWithCurrency number={invAmount} /> }
      />
      <InvestmentSummaryRow left={'Payment Type'} right={toTitlecase(humanize(paymentTypeText(paymentType) ?? '-'))} />
      {offerType === OFFER_TYPE.reg_d506b && limits && <InvestmentSummaryRow left={'Investment Type'} right={limitHelper(limits, invAmount, offerType) ?? "-"} />}
      <InvestmentSummaryRow left={'Processing Fee'} right={paymentType ? <AmountWithCurrency number={processingFee} /> : '-'} />
      <InvestmentSummaryRow left={'Total Payment'} right={<AmountWithCurrency number={invAmount + processingFee} />} />
    </>
  );
}
