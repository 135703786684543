import React, { useEffect, useState } from "react";
import { userApi } from "../../../../api/user";
import "./MyInvestments.scss";
import { history } from "redux/helpers";
import LoadingOverlay from "react-loading-overlay";
import { FaExclamationTriangle } from "react-icons/fa";
import { humanize } from "../../../../jsUtils/stringUtils";
import { FormattedNumberWithSymbol } from "../../../../components/AmountWithCurrency";
import HeaderTitle from "./HeaderTitle";
import { useQuery } from "react-query";
import { Link } from "react-router-dom"

const onClickHandler = (id) => {
  history.push(`/investments/${id}`);
};

const InvestmentRowWarning = ({ investment }) => {
  return (
    <tr
      onClick={() => onClickHandler(investment.id)}
      className="cursor-pointer"
    >
      <td className="bg-warning color-black">
        <div className="investment-table-row-status">
          <div>{new Date(investment.created_at).toDateString().slice(4)}</div>
          <div>{humanize(investment.status)}</div>
          <div>Pending Issues <FaExclamationTriangle className="ml-1" /></div>
        </div>
      </td>
      <td className="bg-warning color-black weight-700 text-right">
        <FormattedNumberWithSymbol number={investment.amount} />
      </td>
    </tr>
  );
};

const InvestmentRow = ({ investment }) => {
  return (
    <tr
      className="cursor-pointer"
      onClick={() => onClickHandler(investment.id)}
    >
      <td>
        <div className="investment-table-row-status">
          <div>{new Date(investment.created_at).toDateString().slice(4)}</div>
          <div>
            {humanize(investment.status)}
            {investment.cancellation_requested_at != null && investment.cancelled_at === null && <span>&nbsp;(Cancellation Requested)</span>}
          </div>
        </div>
      </td>
      <td className="weight-700 text-right">
        <FormattedNumberWithSymbol number={investment.amount} />
      </td>
    </tr>
  );
};

const TotalInvestmentRow = ({ investmentGroup }) => {
  let totalInvAmout = 0;
  const countableInvestments = investmentGroup.investments.filter(
    (inv) => (inv.status !== "voided" && inv.status !== "cancelled" && inv.status !== "canceled")
  );
  if (countableInvestments.length > 1) {
    totalInvAmout = countableInvestments.reduce(
      (acc, inv) => parseFloat(acc.amount ?? acc) + parseFloat(inv.amount)
    );
  } else if (countableInvestments.length > 0) {
    totalInvAmout = countableInvestments[0].amount;
  } else {
    totalInvAmout = 0;
  }
  return (
    <tr>
      <td className="bg-black text-white">Total Investment</td>
      <td className="bg-black text-white weight-700 text-right">
        <FormattedNumberWithSymbol number={totalInvAmout} />
      </td>
    </tr>
  );
};

const GroupedInvestmentsCard = ({ investmentGroup }) => {
  return (
    <div className="masonry-grid-item">
      <div className="masonry-grid-item-inner">
        <div className="portfolio-card">
          <div
            className="portfolio-card-banner"
            style={{
              background: `linear-gradient(to top,#111,transparent 60%), url(${investmentGroup.company.banner_url})`,
            }}
          >
            <div className="portfolio-card-thumb-container">
              <div className="portfolio-card-thumb">
                <img
                  className="portfolio-card-thumb-image"
                  src={investmentGroup.company.logo_url}
                  alt="offer"
                />
              </div>
            </div>
            <div className="portfolio-card-banner-content">
              <h4 className="title">{investmentGroup.company.name}</h4>
              <div className="portfolio-card-badge-container">
                <div className="card-badge-layout">
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {investmentGroup.company.sector_name}
                    </span>
                  </div>
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {investmentGroup.company.parent_sector_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-card-body">
            <p className="body-title">Your Investments</p>
            <table className="table table-borderless table-hover mb-0">
              <tbody>
                {investmentGroup.investments.map((investment, index) => {
                  const has_payment_failure =
                    investment.payment_failed && investment.status !== "voided";
                  const has_pending_issues = investment.cancelled_at === null &&
                    investment.cancellation_requested_at === null && (
                    !investment.subscription_agreement_signed
                    || has_payment_failure
                    || investment.suitability_questionaire_expired
                    || investment.accreditation_verification_expired
                  );

                  if (has_pending_issues) {
                    return <InvestmentRowWarning key={index} investment={investment} />;
                  } else {
                    return <InvestmentRow key={index} investment={investment} />;
                  }
                })}
                <TotalInvestmentRow investmentGroup={investmentGroup} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Deals = () => {
  const grouped_deals = useQuery(
    ['portfolio', 'deals'],
    () => userApi.portfolioGroupedDeals()
  );

  const {data: dealgroups = [], isLoading: loading} = grouped_deals;

  return (
    <div id="deals" className="pt-5">
      <HeaderTitle title="Deals" />
      <LoadingOverlay active={loading} spinner text="Loading...">
        {dealgroups.length > 0 ? (
          <div className="masonry-grid two-cols">
            {dealgroups.map((investmentGroup, index) => (
              <GroupedInvestmentsCard key={index} investmentGroup={investmentGroup} />
            ))}
          </div>
        ) : (
          <div className="no-data-found rounded py-4 bg-lightgrey fs-9 poppins">
            You haven't invested in any deals. <Link to={`/offerings/live-offers`}>View Deals</Link>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default Deals;
