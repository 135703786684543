import React, { useEffect } from "react";
import './Pages.Common.Style.scss';
import LoadingOverlay from 'react-loading-overlay';
import AcceptInviteForm from './components/AcceptInviteForm';

function AcceptInvitePage() {
  return (
    <LoadingOverlay
      // active={useUser().loading}
      spinner
      text='Loading ...'
      >

      <div id="accept-invite"  className="banner-img-container flex-center pt-first-section">
        <div id="signupForm" className="container m-width-medium mt-30 mb-30">
          <h1 className="weight-900 fs-1 text-center">Accept Invite</h1>
          <AcceptInviteForm />
        </div>
      </div>

    </LoadingOverlay>
  )
}

export default AcceptInvitePage;
