import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import OfferInvestPage from './admin/pages/Page.OfferInvest'
import OfferSubscriptionAgreement from './admin/pages/Page.OfferSubscriptionAgreement'
import InvestorDetailsPage from './admin/pages/Page.InvestorDetails'
import AcceptInvitePage from './pages/Page.AcceptInvite';
import ConfirmEmailPage from './pages/Page.ConfirmEmail';
import DisclaimerPage from './pages/Page.Disclaimer';
import SelfAttestationPage from './pages/Page.SelfAttestation';
import SettingsPage from './pages/Page.Settings'
import PreofferViewPage from './admin/pages/Page.PreofferView'
import OfferListPage from './pages/Page.OfferList'
import OfferViewPage from './admin/pages/Page.OfferView'
import RaisePetitionViewPage from './pages/Page.RaisePetitionView';
import CompanyProfilePage from './admin/pages/Page.CompanyProfile'
import { useUser, fetchCurrentUser } from 'redux/user';
import Footer from './pages/components/InvestorAppFooter';
import InvestorAppToolbar from './pages/components/Toolbar/InvestorAppToolbar';
import SpacedbasePage from './pages/Page.Spacedbase';
import { InvestorFaq } from './pages/Page.InvestorFaq';
import TermsOfServices from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';
import Raise from './pages/Raise';
import InvestorSideDrawer from './pages/components/SideDrawer/InvestorSideDrawer'
import { history } from 'redux/helpers'
import EmailVerification from './admin/pages/Page.EmailVerification'
import LimitCalculator from './admin/pages/Page.LimitCalculator';
import Waitlist from './pages/Waitlist'
import InvestmentDetails  from './admin/pages/Page.InvestmentDetails'
import NewsletterPage from './pages/NewsletterPage'
import SpaceLaunch from './pages/Page.SpaceLaunch'
import Press from './pages/Page.Press'
import Support from './pages/Page.Support';
import MediaKit from './pages/Page.MediaKit'
import { AuthContext } from "contexts/authContext";
import Accredited from './pages/Accredited'
import ResetPassword from './pages/Page.ResetPassword';
import PlatformEvents from './pages/Page.PlatformEvents';
import PaymentsDisclaimer from './pages/PaymentsDisclaimer';
import SuitabilityQuestionaire from './pages/Page.SuitabilityQuestionaires';
import BrokerDealerTerms from './pages/Page.BrokerDealerTerms';
import PreofferIndicateInterest from './pages/Page.PreofferIndicateInterest';
import PreofferSubscriptionAgreement from './pages/Page.PreofferSubscriptionAgreement';
import PreofferInterestDetails from './pages/Page.PreofferInterestDetails'
import PreofferNDA from './pages/Page.PreofferNDA'

const Layout = ({children, drawerClickHandler}) => {
  return (
    <>
      <InvestorAppToolbar drawerClickHandler={drawerClickHandler} />
        <div className='min-h-100vh'>
          <div className="investor-app-main-content">{children}</div>
        </div>
      <Footer/>
    </>
  )
}

export default function InvestorApp({ drawerClickHandler, onClose, show }) {
  let { path } = useRouteMatch();
  const user = useUser();
  const [currentSignupStep, setCurrentSignupStep] = useState(null);

  useEffect(() => {
    fetchCurrentUser()
  }, []);

  useEffect(() => {
    let stepPath = null;

    if(!user?.id){
      return;
    }

    if(!user.is_confirmed) {
      stepPath = '/email-verification'
    } else if (user.investor_type === 'none') {
      stepPath = '/self-attestation/new';
    } else if (!user.investment_terms?.id) {
      stepPath = '/disclaimer';
    }

    setCurrentSignupStep(stepPath);
  }, [user]);

  if(!user?.id) {
    return null;
  }

  const pathName = window.location.pathname;

  if(currentSignupStep && currentSignupStep !== pathName){
    history.push(currentSignupStep);
  }

  return (
    <Layout drawerClickHandler={drawerClickHandler} >
      <Switch>
        <Route path={`${path}invitations/:inviteToken`} component={AcceptInvitePage} />
        <Route path={`${path}account`} component={SettingsPage} />
        <Route path={`${path}prelaunch/:preofferId`} component={PreofferViewPage} />
        <Route exact path={`/prelaunches/:preofferId/nda-sign`} component={PreofferNDA} />
        <Route exact path={`/prelaunches/:preofferId/indicate-interest`} component={PreofferIndicateInterest} />
        <Route exact path={`/prelaunches/:preofferId/interest-agreement/:subscriptionId`} component={PreofferSubscriptionAgreement} />
        <Route exact path={`/prelaunches/:preofferId/subscription/:subscriptionId`} component={PreofferInterestDetails} />
        <Route path={`${path}raise-petitions/:raisePetitionId`} component={RaisePetitionViewPage} />
        <Route exact path={`${path}offers`} component={()=><Redirect to={`${path}offerings`} />} />
        <Route path={`${path}offers/:offerId/company/:id`} component={CompanyProfilePage} />
        <Route exact path={`${path}offers/:offerId/invest/accounts/:defaultInvestmentAccountId`} component={OfferInvestPage} />
        <Route exact path={`${path}offers/:offerId/subscription-agreement/:investmentId`} component={OfferSubscriptionAgreement} />
        <Route exact path={`${path}investor-details/offers/:offerId`} component={InvestorDetailsPage} />
        <Route exact path={`${path}investor-details/prelaunches/:preofferId`} component={InvestorDetailsPage} />
        <Route exact path={`${path}investments/:investmentId`} component={InvestmentDetails} />
        <Route exact path={`${path}offers/:offerId/accounts/:accountId/limit-calculator`} component={LimitCalculator} />
        <Route exact path={`/accounts/:accountId/suitability-questionaire`} component={SuitabilityQuestionaire } />
        <Route path={`/offers/:offerId/accounts/:accountId/suitability-questionaire/:investmentId`} component={SuitabilityQuestionaire } />
        <Route path={`/broker-dealer-terms/offers/:offerId`} component={BrokerDealerTerms } />
        <Route path={`${path}offerings`} component={OfferListPage} />
        <Route path={`${path}offers/:offerId`} component={OfferViewPage} />
        <Route exact path={`${path}email-verification`} component={EmailVerification} />
        <Route path="/confirm-email/:token" component={ConfirmEmailPage} />
        <Route path={`${path}spacedbase`} component={SpacedbasePage} />
        <Route exact path={`${path}faqs/`} component={InvestorFaq} />
        <Route path={`${path}self-attestation/:action`} component={SelfAttestationPage} />
        <Route path={`${path}disclaimer`} component={DisclaimerPage} />
        <Route path={`${path}payments-disclaimer`} component={PaymentsDisclaimer} />
        <Route exact path={`${path}terms-of-service/`} component={TermsOfServices} />
        <Route exact path={`${path}privacy-policy/`} component={PrivacyPolicy} />
        {/* <Route exact path={`${path}spacelaunch/`} component={SpaceLaunch} /> */}
        {/* <Route exact path={`${path}accredited-investors/`} component={Accredited} /> */}
        {/* <Route exact path={`${path}press/`} component={Press} /> */}
        <Route exact path={`${path}support/`} component={Support} />
        {/* <Route exact path={`${path}mediakit/`} component={MediaKit} /> */}
        {/* <Route exact path={`${path}raise`} component={Raise} /> */}
        {/* <Route exact path={`${path}waitlist`} component={Waitlist} /> */}
        <Route exact path={`${path}companies/:id`} component={CompanyProfilePage} />
        <Route path={`/company/:id`} component={CompanyProfilePage} />
        {/* <Route exact path={`${path}about`} component={About} /> */}
        {/* <Route exact path="/newsletter" component={NewsletterPage} /> */}
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/resources/" component={PlatformEvents} />
        <Route exact path="/resources/:eventId" component={PlatformEvents} />
        <Redirect to={`${path}offerings`} />
      </Switch>
      <InvestorSideDrawer onClose={onClose} show={show}/>
    </Layout>
  )
}
