import React, { useEffect, useState } from 'react';
import PopupModal from '../PopupModal';
import { addBusinessDays } from 'date-fns'
import { formatDate } from 'jsUtils/dateTimeUtils';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { closeInvestments } from '../../pages/offer/queries';
import * as Yup from 'yup';
import { useOffer } from '../../pages/offer/queries';
import { useMutation, useQueryClient } from 'react-query';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

export default function InvestmentCloseModal({offer_slug, investmentIds, onClose }) {
  const queryClient = useQueryClient();
  const today = new Date();
  const [minClosingDate, setMinClosingDate]= useState('')
  const {data: offer, isLoading: isLoadingOffer} = useOffer(offer_slug);
  const offerDeal = offer?.deal;
  const isBulkClose = offer?.status === 'closed' ?? false;
  const maxCloseDate = formatDate(offerDeal?.close_date, 'yyyy-MM-dd')

  const getRollingCloseValidationSchema = () => {
    if(isBulkClose)
      return null;
    return Yup.object().shape({
      close_date: Yup.date()
        .typeError('Closing date is required')
        .required('Closing date is required')
      })
  }

  useEffect(()=>{
    setMinClosingDate(formatDate(addBusinessDays(today, 6), 'yyyy-MM-dd'))
  },[today])

  const closeInvestmentsMutation = useMutation(closeInvestments, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', 'offers', offer_slug]);
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  })

  return (
    <PopupModal onRequestClose={() => onClose()} title={isBulkClose ? 'Are you sure you want to Bulk close these investments?' : 'Close Selected Investments'}>
      <Formik
        enableReinitialize
        initialValues={{
          close_date:''
        }}
        validationSchema={getRollingCloseValidationSchema()}
        onSubmit={async (values) => {
          let attrs = {
            close: {
              investment_ids: investmentIds,
              type: isBulkClose ? 'Bulk' : 'Rolling'
            }
          }
          if(!isBulkClose)
            attrs.close.date = values?.close_date;

          closeInvestmentsMutation.mutate({offer_slug, values: attrs})
          onClose()
        }}
      >
        {({touched, errors}) => (
        <Form>
          { !isBulkClose && <div className="px-3 mt-4">
            <div className="form-label-group">
              <Field type="date"
                id="close_date"
                min={minClosingDate}
                max={maxCloseDate}
                className={`form-control ${touched.close_date && errors.close_date ? "is-invalid" : ""}`}
                name="close_date"
              />
              <label htmlFor="close_date">Close date</label>
              <ErrorMessage name="close_date" component="div" className="invalid-feedback text-left" />
            </div>
          </div> }
          <div className="alert-modal-footer">
            <button className="zeitung-mono weight-300 mr-3 btn-black-outline" onClick={() => onClose()}>Cancel</button>
            <button type='submit' className="zeitung-mono weight-300 btn-black">Confirm</button>
          </div>
        </Form>
        )}
      </Formik>
    </PopupModal>
  )
}