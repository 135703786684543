import React from 'react'
import { format, parseJSON } from 'date-fns';
import { formatDateWithoutTimezone } from 'jsUtils/dateTimeUtils';

export default function RollingCloseDetail({ investment }) {
  const rollingCloseDate = new Date(formatDateWithoutTimezone(investment?.close_date))
  const today = new Date();
  const rollingCloseInProgress = rollingCloseDate >= today;

  return (
    <div className="mb-4">
      <h5 className="montserrat weight-700 mb-2">Rolling Close Details</h5>
      <div className="d-flex flex-wrap justify-content-between align-items-center py-2 bg-white montserrat mb-1">
        { rollingCloseInProgress ? <div className="px-3 fs-10">{`Your investment will be in a rolling close on ${formatDateWithoutTimezone(investment?.close_date)}. You can cancel up until 48 hours before ${formatDateWithoutTimezone(investment?.close_date)}`}</div> :
        <div className="px-3 fs-10">{`Your investment was closed on ${formatDateWithoutTimezone(investment?.close_date)}. Please contact support@spacedventures.com if you have any questions.`}</div> }
      </div>
    </div>
  )
}