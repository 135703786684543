import PropTypes from "prop-types";
import React from "react";
import "./LinkedData.scss";

const LinkedData = ({children, onClick}) => {
  return (
    <span className="linked-data" onClick={onClick}>
      {children}
    </span>
  );
};

LinkedData.defaultProps = {};

LinkedData.propTypes = {
children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default LinkedData;
