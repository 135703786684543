import React from "react";
import { format, parseJSON } from "date-fns";
const ClosedTimeBadge = ({ closeDate, prefix = "" }) => {
  return (
    <div>
      {prefix}&nbsp;
      <span>{format(parseJSON(closeDate), "MMM dd, yyyy")}</span>
    </div>
  );
};

export default ClosedTimeBadge;
