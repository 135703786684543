import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { NavLink } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-modal';
import { useUser } from 'redux/user'
import InterestConfirmation from './preoffer/InterestConfirmation';
import { usePreoffer, usePreofferSubscription } from '../investor/queries';
import SubscriptionProgressBanner from './preoffer/SubscriptionProgressBanner';
import InvestmentDetailDocumentList from './components/InvestmentDetailDocumentSection';
import ActionRequiredSection from './preoffer/ActionRequiredSection';
import CompnyInfo from './preoffer/CompanyInfo';
import IndicativeInterestDetail from './preoffer/IndicativeInterestDetails';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999
  },
  content: {
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: '0',
    padding: '0',
    overFlow: 'unset',
    backgroundColor: '#111',
    zIndex: '2',
    minHeight: '100vh'
  }
}

export default function PreofferInterestDetails({ location }) {
  let { preofferId, subscriptionId } = useParams();
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const user = useUser();
  const { data: preoffer, isError: isPreofferLoadigError, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);
  const { data: subscription, isError: isSubscriptionLoadingError, isLoading: isLoadingSubscription } = usePreofferSubscription(subscriptionId);
  const isLoading = isLoadingPreoffer || isLoadingSubscription || user.isLoading;
  const isError = isPreofferLoadigError || isSubscriptionLoadingError;

  useEffect(() => {
    const showModal = location?.state?.showModal;
    setConfirmationModal(showModal || false)
  }, []);

  if(isError){
    return <h4>Something went wrong please try after sometime.</h4>
  }

  const isSecondary = preoffer?.category === "secondary";

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading ...'
    >
      <Modal
        ariaHideApp={false}
        isOpen={showConfirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
        style={modalStyles}
      >
        <div className="modal-inner">
          { !isLoading && <InterestConfirmation preoffer={preoffer} subscription={subscription} onClose={() => setConfirmationModal(false)} /> }
        </div>
      </Modal>

      {!isLoading &&
        <div className="pt-first-section pb-last-section investment-details-page">
          <div className="container">
            <div className="investment-details-inner">
              <ul className="sv-breadcrumb">
                <li>{isSecondary ? "Secondary" : "Pre-launch"}</li>
                <li className="active"><NavLink to={`/prelaunch/${preoffer.slug}/pitch`}>{preoffer.name}</NavLink></li>
              </ul>
              <div className="row">
                <div className="col-md-7 mb-md-0 mb-4 investment-head">
                  <div className='hide-on-mobile'>
                    <ActionRequiredSection preoffer={preoffer} subscription={subscription} />
                  </div>
                  <SubscriptionProgressBanner preoffer={preoffer} />
                  <div className="deal-terms-table mt-3">
                    <CompnyInfo preoffer={preoffer} />
                  </div>
                  <div className='mt-2'>
                  <InvestmentDetailDocumentList title="Documents" documents={preoffer.documents} showDocumentText={false}/>
                  </div>
                </div>
                <div className="col-md-5 bg-lightgrey3 py-3 investment-details">
                  <IndicativeInterestDetail subscription={subscription} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </LoadingOverlay>
  )
}