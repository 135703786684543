import React from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import HookForm from '../../../components/HookForm';
import { PREOFFER_TYPE } from '../../../config/constants';

const PreofferOverviewSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf([PREOFFER_TYPE.reg_cf, PREOFFER_TYPE.reg_d])
    .required('Preoffer type is required'),
  name: Yup.string().required(),
  goal_amount: Yup.number().typeError('Goal amount must be a number').positive().integer().required(),
  valuation: Yup.number().typeError('Valuation amount must be a number').positive().integer().nullable(true).default(null),
  minimum_investment_amount: Yup.number().typeError('Minimum investment amount must be a number').positive().integer().required(),
  secondary: Yup.bool().required()
});

function OverviewForm({ preoffer = {}, onSubmit }) {
  const {
    name = '',
    goal_amount = '',
    valuation = null,
    type = '',
    nda = false,
    minimum_investment_amount,
    listed_at,
    category
  } = preoffer

  const methods = useForm({
    resolver: yupResolver(PreofferOverviewSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      name,
      goal_amount,
      valuation,
      type,
      nda,
      minimum_investment_amount,
      listed: listed_at ? true : false,
      secondary: category === "secondary"
    }
  });

  const handleSubmit = (values) => {
    values.listed_at = values.listed ? (values.listed_at || new Date()) : null;
    values.category = values.secondary ? "secondary" : "none";
    delete values.listed;
    delete values.secondary;
    onSubmit(values);
  }

  return (
    <div className="deal-form-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {/* temporary hack for button style fix, will have to fix this once we add the full page context modals */}
          <div className='portalized-modal-wrapper'>
            <HookForm.SelectInput
              name="type"
              label="Type*"
              placeholder="Select Type"
              options={["RegCf", "RegD"]}
            />
            <HookForm.TextInput
              name="name"
              placeholder="Test Offer Name*"
            />
            <HookForm.CurrencyInputField
              id="goal_amount"
              name="goal_amount"
              label="Goal Amount*"
              placeholder="Goal Amount*"
            />
            <HookForm.CurrencyInputField
              id="valuation"
              name="valuation"
              label="Valuation"
              placeholder="Valuation"
            />
            <HookForm.CurrencyInputField
              id="minimum_investment_amount"
              name="minimum_investment_amount"
              label="Minimum Investment Amount*"
              placeholder="Minimum Investment Amount**"
            />
            <HookForm.CheckboxSwitchField name="listed" placeholder="Listed" />
            <HookForm.CheckboxSwitchField name="nda" placeholder="Enable NDA" />
            <HookForm.CheckboxSwitchField name="secondary" placeholder="Secondary" />
            <div className='portalized-modal-footer'>
              <button type="submit"
                className="btn-black"
                disabled={methods.isSubmitting}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default OverviewForm;
