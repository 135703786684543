import PropTypes from 'prop-types';
import React from "react";
import OfferCardBanner from "../../../pages/components/OfferCardBanner";
import OfferCardCompanyDescription from "../../../pages/components/OfferCardCompanyDescription";
import { pluralize } from "jsUtils/stringUtils";
import {
  progressBarPercentage,
  raisedFundsPercentage,
} from "jsUtils/offerUtils";

const RaisePetitionCard = function ({ raisePetition, onClick }) {
  const company  = (({
    tagline, description, name, logo_url, banner_url, parent_sector_name, sector_name
  }) => ({ tagline, description, name, logo_url, banner_url, parent_sector_name, sector_name }))(raisePetition.company)

  return (
    <a className="unstyled" href={`/raise-petitions/${raisePetition.slug}`} onClick={onClick} >
      <div className="offer-card offer-card-dark">
        <OfferCardBanner offer={{company}} type="petitionToRaise" />
        <div className="offer-card-text-container offer-text-padding">
          <OfferCardCompanyDescription company={company} />
          <PetitionOfferCardSummary raisePetition={raisePetition} />
        </div>
      </div>
    </a>
  );
};

const PetitionOfferCardSummary = ({ raisePetition }) => {
  const {
    pledged_amount,
    goal_amount,
    pledge_count
  } = raisePetition;

  const fundsPercentage = raisedFundsPercentage(pledged_amount, goal_amount);

  return (
    <div className="medium-progress-bar">
      <div className="progress-box">
        <div className="progressbar">
          <div
            className="progress"
            style={{ width: `${progressBarPercentage(fundsPercentage)}%` }}
          ></div>
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="funded">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-1">Pledged</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-text ml-1">{pluralize(pledge_count, "Pledger", "Pledgers")}</span>
        </div>
      </div>
    </div>
  );
};


RaisePetitionCard.defaultProps = {};

RaisePetitionCard.propTypes = {
  raisePetition: PropTypes.object.isRequired,
};

export default RaisePetitionCard;
