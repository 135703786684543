import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { getBusinessModelIcon } from 'jsUtils/offerUtils';

function BusinessModelSection({ 
  pitch:{business_model_section, business_models}, 
 }) {
  if(!business_model_section) return null;

  if(business_models === null) business_models = [];

  return (
    <div id="business-model-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'business model'} />
        <div className="pitch-section-body">
          { business_models.map(m=> <div className="content-summary">
            <BusinessModel key={m.name} businessModel={m} />
          </div>)}
            <ArticleContent content={business_model_section.content}/>
        </div>
      </div>
    </div>
  )
}

function BusinessModel({businessModel}) {
  return (
    <div className="business-model">
        <div className="bm-logo-box">
          <img src={getBusinessModelIcon(businessModel?.name)} alt={businessModel?.name}/>
        </div>
        <div className="business-summary">
          <div className="name">{businessModel.name}</div>
          <div className="description">{businessModel.desc}</div>
        </div>
    </div>
  )
}

export default BusinessModelSection;