import React from "react";
import './index.scss'

export default ({
  options,
  value,
  onChange,
  childrenNestingKey='children',
  parentOptionLabel='label',
  parentOptionValue='value',
  optionLabel='label',
  optionValue='value',
  parentSelectable=false,
  onBlur,
  placeholder='',
  id='',
  className
}) => {
  return (
    <select
      id={id}
      className={className}
      value={value}
      onChange={e => onChange(e.target.value)}
      onBlur={ onBlur }
    >
      <option key="__prompt__">{placeholder}</option>
      {options.map((o) => {
        const opts = [
          <option key={o[parentOptionValue]} className="parent" value={o[parentOptionValue]} disabled={!parentSelectable}>
            {o[parentOptionLabel]}
          </option>
        ];
        o[childrenNestingKey].forEach((c) =>
          opts.push(
            <option key={c[optionValue]} className="child" value={c[optionValue]}>
              {`${o[parentOptionLabel]}: ${c[optionLabel]}`}
            </option>
          )
        );
        return opts;
      })}
    </select>
  )
};
