import React from "react";
import { parseJSON, format } from "date-fns";
import { useAccreditationRequest } from "investor/queries";
import Instructions from "./Instructions";
import UploadDocument from "../AmlException/UploadDocument";
import UploadedDocuments from "../AmlException/UploadedDocuments";
import { ApprovdeBadge } from "../AmlException/Badges";
import AmlSectionsContainer from "../AmlException/AmlSectionsContainer";
import InvestmentCardRow from "../InvestmentCardRow";
import { formatDate } from "../../../../jsUtils/dateTimeUtils";

function StatusNotes({ aiRequest }) {
  if (aiRequest.notes.length === 0) {
    return null;
  }

  return (
    <AmlSectionsContainer title="Review Notes" tooltipText="We found some issues with the document(s) you uploaded. Please review the notes below and upload another document to verify your accreditation.">
      <div className="content-layer bg-golden">
        <b>{format(parseJSON(new Date(aiRequest.updated_at)), "MMM dd, yyyy hh:mm a")}</b>
        <ul className="mt-2 ml-3">
          {aiRequest.notes.map((note, index) => (<li key={index}>{note}</li>))}
        </ul>
      </div>
    </AmlSectionsContainer>
  )
}

function UnVerifiedRequestDetails({ client, aiRequest }) {
  const entityDocuments = aiRequest.entity_documents;

  return (<>
    <StatusNotes aiRequest={aiRequest} />
    <Instructions aiRequest={aiRequest} />
    <div className="content-layer bg-white p-4 border border-dark my-4">
      <p className="d-flex flex-wrap align-items-center mb-1 weight-700">Upload documents for our team to review</p>
      <UploadDocument
        category="accreditation_verification"
        clientId={client.id}
        clientType={client.entity_type}
        isVerified={false}
      />
    </div>
    {entityDocuments.length > 0 && <UploadedDocuments documents={entityDocuments} />}
  </>)
}

function VerifiedRequestDetail({ client, aiRequest }) {
  return (
    <div>
      <div className="mb-2 p-3 bg-lightgrey">
        <div className="weight-700 d-flex flex-wrap align-items-center">
          <span>Accredited Status</span>
          <ApprovdeBadge></ApprovdeBadge>
        </div>
      </div>
        <div className="payment-card mb-3">
          <div className='payment-card-header d-flex justify-content-between'>
            <div>Accreditation Verification for {client.name}</div>
          </div>
          <InvestmentCardRow title='Created At' value={formatDate(aiRequest.created_at)} />
          <InvestmentCardRow title='Updated At' value={formatDate(aiRequest.updated_at)} />
          <InvestmentCardRow title='Verified At' value={formatDate(aiRequest.verified_at)} />
          <InvestmentCardRow title='Expires On' value={formatDate(aiRequest.expires_on)} />
        </div>
    </div>
  )
}

export default function AccreditationVerification({ client }) {
  const { isLoading, data: aiRequest, isError } = useAccreditationRequest(client.id);

  if (isLoading) {
    return (<div className="weight-600">Loading...</div>);
  }

  if (isError || !aiRequest?.id) {
    return <></>;
  }

  return (
    <div className="aml-section-wrapper">
      <h4 className="m-b-15 fs-18 weight-800">Accredited Verification</h4>
      <div className="mb-4">
        {aiRequest.status === 'verified' ? <VerifiedRequestDetail client={client} aiRequest={aiRequest} /> : <UnVerifiedRequestDetails client={client} aiRequest={aiRequest} />}
      </div>
    </div>
  );
}
