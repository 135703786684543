import React from "react";
import Tooltip from "../../pages/components/Tooltip/Tooltip";

const TableActionItem = ({ onClick, children, tooltipText, disabled=false }) => {
  return (
    tooltipText ? <Tooltip xAlignment="0px" yAlignment="50%" text={tooltipText} className="single-action-tooltip">
      <button onClick={onClick} className="btn btn-sm btn-link single-action" disabled={disabled}>
        {children}
      </button>
    </Tooltip> :
    <button onClick={onClick} className="btn btn-sm btn-link single-action" disabled={disabled}>
      {children}
    </button>
  );
};

export default TableActionItem;
