import axiosClient from 'config/axiosClient';

export const offerApi = {
  list,
  search,
  create,
  update,
  fetch,
  fetchPitch,
  addCustomer,
  purgeCustomer,
  updateCustomer,
  updatePitch,
  fetchBusinessModels,
  fetchCompetitions,
  changeOfferStatus,
  live_or_closed,
  makeInvestment,
  fetchInvestorAgreementContent,
  signInvestorAgreementContent,
  listInvestors,
  fetchInvestment,
  deleteInvestment,
  deleteInvestmentByAdmin,
  sendReminder,
  deletePitchSection,
  fetchCloseableInvestments,
  fetchClosedInvestments,
  closeInvestments,
};

const offerStatusApiMap = {
  live: 'publish',
  paused: 'pause',
  closed: 'close',
}

function list(page, per_page) {
  return axiosClient.get(`/admin/offers/?&page=${page}&per_page=${per_page}`);
}

function search(pattern, page, per_page) {
  return axiosClient.get(`/admin/offers/search?&name=${pattern}&page=${page}&per_page=${per_page}`);
}

function live_or_closed() {
  return axiosClient.get(`/offers/grouped_by_status`);
}

function create(companyId, attrs) {
  return axiosClient.post(`/admin/companies/${companyId}/offers`, attrs);
}

function update(id, attrs) {
  return axiosClient.put(`/admin/offers/${id}`, attrs);
}

function fetch(id) {
  return axiosClient.get(`/offers/${id}`);
}

function fetchPitch(offerId) {
  return axiosClient.get(`/offers/${offerId}/pitch`);
}

function updatePitch(offerId, pitchAttrs) {
  return axiosClient.put(`admin/offers/${offerId}/pitch`, pitchAttrs);
}

function fetchBusinessModels() {
  return axiosClient.get("/business_models");
}

function fetchCompetitions() {
  return axiosClient.get("/competitions");
}

function addCustomer(offerId, attrs) {
  return axiosClient.post(`/admin/offers/${offerId}/customers`, attrs);
}

function purgeCustomer(customerId) {
  return axiosClient.delete(`/admin/customers/${customerId}`);
}

function updateCustomer(customerId, attrs) {
  return axiosClient.put(`/admin/customers/${customerId}`, attrs);
}

function changeOfferStatus(offerId, nextStatus) {
  return axiosClient.patch(`/admin/offers/${offerId}/${offerStatusApiMap[nextStatus]}`)
}

function makeInvestment(attrs, investmentAccountId) {
  return axiosClient.post(`/clients/${investmentAccountId}/investments`, attrs)
}

function fetchInvestorAgreementContent(investmentId) {
  return axiosClient.get(`/investments/${investmentId}/subscription_agreement`)
}

function signInvestorAgreementContent(investmentId, attrs) {
  return axiosClient.post(`/investments/${investmentId}/subscription_agreement/sign`, attrs)
}

function listInvestors(offerId, type="all") {
  return axiosClient.get(`/offers/${offerId}/investors`, {params: {type}})
}

function fetchInvestment(investmentId) {
  return axiosClient.get(`/admin/investments/${investmentId}`)
}

function deleteInvestment(investmentId) {
  return axiosClient.delete(`/investments/${investmentId}`)
}

function deleteInvestmentByAdmin(investmentId) {
  return axiosClient.delete(`/admin/investments/${investmentId}`)
}
function sendReminder(attrs) {
  return axiosClient.post(`/admin/investors/send_reminder`, attrs)
}

function deletePitchSection(id, type) {
  return axiosClient.delete(`admin/pitch_sections/${id}`, {data: {type: type}})
}

function fetchCloseableInvestments(offer_slug){
  return axiosClient.get(`admin/offers/${offer_slug}/investments/eligible_for_closing`);
}

function fetchClosedInvestments(offer_slug){
  return axiosClient.get(`admin/offers/${offer_slug}/closes`);
}

function closeInvestments(offer_slug, attrs){
  return axiosClient.post(`admin/offers/${offer_slug}/closes`, attrs)
}