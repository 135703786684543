import React from 'react';
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import {  humanize } from '../../../jsUtils/stringUtils';
import { formatDate } from '../../../jsUtils/dateTimeUtils';

export default function VerificationDetail({ accreditationRequest  }) {
  return (
    <>
      <OverviewSubheader left={'Verification Details'} />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Status' value={humanize(accreditationRequest.status)} />
            <AttributeValue name='Service Provider Status' value={accreditationRequest.sp_request_status} />
            <AttributeValue name='Created At' value={formatDate(accreditationRequest.created_at, 'MMM dd, yyyy')} />
            <AttributeValue name='Expires On' value={accreditationRequest.expires_on ? formatDate(accreditationRequest.expires_on, 'MMM dd, yyyy') : ''} />
          </>
        }
        right={
          <>
            {
              accreditationRequest.verification_letter_url && <AttributeValue name='Verification Letter' value={
                <a href={accreditationRequest.verification_letter_url} target="_blank" rel="noopener noreferrer">Click to Download</a>
              } />
            }
            <AttributeValue name='Verified At' value={accreditationRequest.verified_at ? formatDate(accreditationRequest.verified_at, 'MMM dd, yyyy') : ''} />
            <AttributeValue name='Updated At' value={formatDate(accreditationRequest.updated_at, 'MMM dd, yyyy')} />
          </>
        }
      />
    </>
  )
}