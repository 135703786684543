import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const SearchInput = ({onChange, placeholder}) => {
  const onChangeHandler = (e) => {
    const qs = e.currentTarget.value
    onChange(qs)
  }

  return (
    <div className="search-input">
      <div className="input-group">
        <input type="search"
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
};

SearchInput.defaultProps = {
  placeholder: "Search",
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SearchInput;
