
import React from 'react';
import MessageActions from './MessageActions';
import Utils from './Utils';

export default function UserMessage({ message, moderation, deleteMessage, suspendParticipant}){
  const chatParticipant = message.chat_participant;
  let content;

  if(message.deleted_at){
    content = '-- removed --'
  } else {
    content = <span dangerouslySetInnerHTML={{ __html: Utils.linkify(message.content)}}></span>
  }

  return (
    <div className='message d-flex flex-row'>
      <div className={`col-11 px-0 ${(moderation && chatParticipant.status) || ''}`}>
       <span className="fs-14 weight-700 poppins mr-2 user">{ Utils.chatUserName(chatParticipant) }</span>
        <span className={`fs-14 poppins content ${message.deleted_at ? 'deleted-msg' : ''}`}>
          { content }
        </span>
      </div>
      <div className='col-1 px-0 actions'>
        <MessageActions message={message} deleteMessage={deleteMessage} suspendParticipant={suspendParticipant}/>
      </div>
    </div>
  )
}