import React from 'react'
import { Switch, Route, useParams, useRouteMatch, Redirect } from "react-router-dom"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import ToastMessage from '../../components/ToastMessage';
import YoutubeEmbed from 'admin/components/shared/YoutubeEmbed'
import ReactTooltip from 'react-tooltip';
import { FaExternalLinkAlt } from "react-icons/fa";
import Discussion from './Page.Discussion';
import { progressBarPercentage, raisedFundsPercentage } from '../../jsUtils/offerUtils'
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { AuthContext } from 'contexts/authContext';
import { runGAEvent } from '../../pages/components/Tracking';
import { fbEvent } from '../../pages/components/Tracking/facebook';
import { Helmet } from "react-helmet";
import { history } from 'redux/helpers'
import { useUser } from 'redux/user';
import OfferNavLinkItems from '../components/shared/OfferNavLinkItems';
import OfferNavMobileDropdown from '../components/shared/OfferNavMobileDropdown';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import CompanySectionTable from '../components/shared/CompanySectionTable';
import { CustomScrollSpy } from '../components/shared/CustomScrollSpy'
import {
  ProblemSection,
  SolutionSection,
  ProductSection,
  TractionSection,
  CustomerSection,
  BusinessModelSection,
  MarketSection,
  CompetitionSection,
  TeamSection,
  VisionSection,
  UseOfFundSection,
  RiskAndDisclosureSection,
  SummarySection,
  HighlightsSection,
} from './../../investor/components/pitch/sections';
import ShareButtons from '../../investor/features/raise_petition/components/ShareButtons';
import {
  usePreoffer,
  usePreofferPitch,
  usePreofferNDARequirement
} from 'investor/queries';
import { ACTIONS } from 'config/adminAbilities';
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../components/AmountWithCurrency';
import { formatMoneyClean } from '../../jsUtils/currencyUtils';
import _ from 'underscore.string';
import './Page.OfferView.scss'
import useGeoInfo from '../../lib/useGeoInfo';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { INVESTOR_TYPE, PREOFFER_TYPE } from '../../config/constants';
import InvestmentDetailDocumentSection from '../../pages/components/InvestmentDetailDocumentSection';

function NDARequirement({ requirement }) {
  const instructions = requirement.category === "secondary" ? `The details in ${requirement.company.name} secondary are confidential. You must sign an NDA to view ${requirement.company.name} and subscribe.` : `The details in ${requirement.company.name} pre-launch are confidential. You must sign an NDA to view ${requirement.company.name} and indicate interest.`
  return (
    <>
      <div className="investment-header">
        <div className="container pb-3 pt-first-section">
          <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">
            Sign NDA to view {requirement.company.name}
          </h1>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-8 py-5 pr-xl-5">
              <div className="pr-xl-5"></div>
              <div className="pr-xl-5">
                <p className="fs-8">
                  {instructions}
                </p>
                <div className="portalized-modal-footer mt-4">
                  <button
                    className="btn-black-modal rounded-0"
                    onClick={() => {
                      history.push(`/prelaunches/${requirement.slug}/nda-sign`);
                    }}
                  >
                    Sign NDA
                  </button>
                  <button
                    className="btn-black-outline rounded-0 ml-3 px-3"
                    onClick={() => {
                      history.push("/offerings/all");
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function PreofferDetail() {
  let { path } = useRouteMatch();
  let { preofferId } = useParams();
  const { data: preoffer, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);
  const { data: pitch, isLoading: isLoadingPitch } = usePreofferPitch(preofferId);
  const user = useUser();
  const { authData } = React.useContext(AuthContext);
  const canPreview = authData.permittedActions.has(ACTIONS.PREVIEW_PREOFFER)
  const preOfferNavBarItems = [
    { label: 'PITCH', value: 'pitch' },
    { label: 'DISCUSSION', value: 'discussion' }
  ]

  if (isLoadingPreoffer || isLoadingPitch || user.loading)
    return 'Loading...'

  if (preoffer.status == 'live' || preoffer.status == 'closed' || canPreview) {
    // do nothing, user can view the page
  }
  else {
    history.push(`/offers`)
  }

  const { company } = preoffer;
  const prelaunchInfo = "Pre-launch lets companies gauge investor interest prior to completing the legal compliance work to do a fundraise. Indicating interest is non-binding, which means no money is sent to the company. Interested persons will receive an email asking them to complete the investment process when the company finalizes terms, legal disclosures and other requirements.";
  const secondaryInfo = "A secondary sale is when an existing shareholder of a private company sells their shares to a third-party buyer, and the proceeds go to the seller, not the company. This is different from a primary sale of equity, where the company issues new shares to investors and uses the money for its own purposes. In this case, we are the third-party buyer.";

  let pitchSidebarItems = [];

  (function buildSidebarItems() {

    if (!pitch) return [];

    if (pitch.highlights?.length > 0) {
      pitchSidebarItems.push({
        id: 'highlights-section-component', key: 'highlights', name: 'Highlights', visibility: true,
        component: <HighlightsSection pitch={pitch} key="highlights" />
      });
    }

    if (preoffer.documents.length) {
      pitchSidebarItems.push({ id: 'documents', key: 'documents', name: 'Documents', visibility: true, component: <InvestmentDetailDocumentSection documents={preoffer.documents} key="documents" /> });
    }

    if (preoffer.type === PREOFFER_TYPE.reg_cf) {
      pitchSidebarItems.push({
        id: 'summary', key: 'summary', name: 'Summary', visibility: true,
        component: <SummarySection pitch={pitch} key="summary" />
      });
    }

    if (pitch.problem_section)
      pitchSidebarItems.push({
        id: 'problem-section-component', key: 'problem', name: 'Problem', visibility: true,
        component: <ProblemSection pitch={pitch} key="problem" />
      });

    if (pitch.solution_section)
      pitchSidebarItems.push({
        id: 'solution-section-component', key: 'solution', name: 'Solution', visibility: true,
        component: <SolutionSection pitch={pitch} key="solution" />
      });

    if (pitch.product_section)
      pitchSidebarItems.push({
        id: 'product-section-component', key: 'product', name: 'Product', visibility: true,
        component: <ProductSection pitch={pitch} key="product" />
      });

    if (pitch.traction_section)
      pitchSidebarItems.push({
        id: 'traction-section-component', key: 'traction', name: 'Traction', visibility: true,
        component: <TractionSection pitch={pitch} key="traction" />
      });

    if (pitch.customers_section || pitch.customers?.length > 0)
      pitchSidebarItems.push({
        id: 'customers-section-component', key: 'customers', name: 'Customers', visibility: true,
        component: <CustomerSection pitch={pitch} key="customers" />
      });

    if (pitch.business_model_section)
      pitchSidebarItems.push({
        id: 'business-model-section-component', key: 'business-model', name: 'Business model', visibility: true,
        component: <BusinessModelSection pitch={pitch} key="business-model" />
      });

    if (pitch.market_section)
      pitchSidebarItems.push({
        id: 'market-section-component', key: 'market', name: 'Market', visibility: true,
        component: <MarketSection pitch={pitch} key="market" />
      });

    if (pitch.competition_section)
      pitchSidebarItems.push({
        id: 'competition-section-component', key: 'competition', name: 'Competition', visibility: true,
        component: <CompetitionSection pitch={pitch} key="competition" />
      });

    if (pitch.team_section)
      pitchSidebarItems.push({
        id: 'team-section-component', key: 'team', name: 'Team', visibility: true,
        component: <TeamSection pitch={pitch} key="team" />
      });

    if (pitch.vision_section)
      pitchSidebarItems.push({
        id: 'vision-section-component', key: 'vision', name: 'Vision', visibility: true,
        component: <VisionSection pitch={pitch} key="vision" />
      });

    if (pitch.use_of_funds_section)
      pitchSidebarItems.push({
        id: 'use-of-fund-section-component', key: 'use-of-fund', name: 'Use of Funds', visibility: true,
        component: <UseOfFundSection pitch={pitch} key="use-of-fund" />
      });

    pitchSidebarItems.push({
      id: 'company-overview', key: 'company-overview', name: 'Company', visibility: true,
      component: <CompanyOverview company={company} key="company-overview" following_company={preoffer.following_company} />
    });

    if (pitch.risks_and_disclosures_section)
      pitchSidebarItems.push({
        id: 'risks-and-disclosure-section-component', key: 'risks-and-disclosure', name: 'Risks & Disclosures', visibility: true,
        component: <RiskAndDisclosureSection pitch={pitch} key="risks-and-disclosure" />
      });
    return pitchSidebarItems;
  })();

  const showValuation = preoffer.valuation !== 0 && preoffer.valuation;
  let canInvest = true;

  if (preoffer.type === PREOFFER_TYPE.reg_d && user.investor_type !== INVESTOR_TYPE.accredited) {
    canInvest = false;
  }

  const isSecondary = preoffer.category === "secondary";
  const preofferTypeText = isSecondary ? "Secondary" : "Pre-launch";

  return (
    <div className="offer-view-page">
      <Helmet>
        <title>{company.name} {preofferTypeText}</title>
        <meta
          name="description"
          content={`${preoffer.name} ${preofferTypeText} on Spaced Ventures`}
        />
      </Helmet>
      <section className="offer-view-banner pt-first-section" id="companyBanner">
        <div className='container offer-view-banner-title-container'>
          <div className="row px-sm-0 px-3">
            <div className="col-12">
              <span className="preoffer-badge mb-1">{preofferTypeText}</span>
              <h1 className="montserrat">
                <span className="mr-3">{company.name}</span>
                {company?.url &&
                  <a className="social-icon" target="_blank" rel="noopener noreferrer" href={company.url}>
                    {<FaExternalLinkAlt />}
                  </a>}
              </h1>
              {showValuation && <div className="text-muted poppins fs-14 weight-700 pb-3">Estimated Valuation: {formatMoneyClean(preoffer.valuation)}</div>}
            </div>
          </div>
        </div>
        {company?.tagline && <div className='container offer-view-banner-tagline-container'>
          <div className='px-sm-0 px-3'>
            <h5 className='company-tagline'>{company?.tagline}</h5>
          </div>
        </div>}
        <div className="container offer-view-banner-content-container">
          <div className="deal-info row">
            {pitch.main_video_section?.link && <div className="col-lg-7 pr-lg-4 deal-info-col1 mt-lg-0 mt-4">
              <div className="for-youtube-vid-container">
                <YoutubeEmbed videoUrl={pitch.main_video_section?.link} />
              </div>
            </div>}
            <div className={`${pitch?.main_video_section ? 'col-lg-5 pl-lg-4 ' : 'col-12 '}deal-info-col2`}>
              <div className="w-100">
                <ProgressBar preoffer={preoffer} />
              </div>
              <div className='about-prelaunch'>
                <SubscribeButton preoffer={preoffer} user={user} disableInterestBtn={!canInvest} />
                {!canInvest && <p className='montserrat fs-10 weight-400 m-t-20 lh-md'>Only available to accredited investors.</p>}
                { !isSecondary && <p className="montserrat fs-10 weight-400 m-t-20 lh-md">Be the first to know when {company.name} launches. An indication of interest involves no obligation or commitment of any kind.</p>}

                {isSecondary ?
                  <div data-for="prelaunchInfoTooltip" data-tip={secondaryInfo} className="d-inline-flex align-items-center m-t-10">
                    <div className="attention-icon mr-1"></div>
                    <div className="fs-12 weight-400 montserrat text-muted">What is a Secondary?</div>
                    <ReactTooltip id="prelaunchInfoTooltip" place="right" backgroundColor="#f5f5f5" textColor="#111" border={true} className="tooltip-width" />
                  </div> :
                  <div data-for="prelaunchInfoTooltip" data-tip={prelaunchInfo} className="d-inline-flex align-items-center m-t-10">
                  <div className="attention-icon mr-1"></div>
                  <div className="fs-12 weight-400 montserrat text-muted">What is a Pre-launch offer?</div>
                  <ReactTooltip id="prelaunchInfoTooltip" place="right" backgroundColor="#f5f5f5" textColor="#111" border={true} className="tooltip-width" />
                 </div>
                }
              </div>
              {preoffer.type !== "RegD" &&
                <div className="offer-social-icons mt-2">
                  <ShareButtons showTitle={true} offering={preoffer} />
                </div>}
            </div>
          </div>
        </div>
      </section>
      <div className="offer-navbar" id="offerNavBar">
        <div className="container">
          <div className="tab-buttons">
            <OfferNavLinkItems offer={preoffer} navBarItems={preOfferNavBarItems} />
          </div>
          <SubscribeButton preoffer={preoffer} user={user} disableInterestBtn={!canInvest} />
        </div>
      </div>

      <Switch>
        <Route path={`${path}/pitch`} component={() => (
          <>
            <OfferNavMobileDropdown navBarItems={preOfferNavBarItems} currentTab={'PITCH'} />
            <section className="main-content">
              <CustomScrollSpy pitchSidebarItems={pitchSidebarItems} />
            </section>
          </>
        )} />

        <Route path={`${path}/discussion`} component={() => (
          <>
            <OfferNavMobileDropdown navBarItems={preOfferNavBarItems} currentTab={'DISCUSSSION'} />
            <section className="main-content">
              <div className="container">
                <div className="bg-white">
                  <div className="row" style={{ height: '100%' }}>
                    <Discussion
                      discussableType="preoffers"
                      discussableId={preoffer.slug}
                      companyId={company.slug}
                      companyName={company.name}
                      openForPostings={preoffer.status != 'closed'}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )} />

        <Redirect from={`${path}`} to={`${path}/pitch`} />
      </Switch>
    </div>
  )
}

export default function ShowPreoffer(props) {
  let { preofferId } = useParams();
  const { data: ndaRequirement, isLoading: isLoadingNDARequirement } = usePreofferNDARequirement(preofferId);
  const user = useUser();
  const isLoading = isLoadingNDARequirement || user.loading;
  const { isAdmin } = React.useContext(AuthContext);

  if (isLoading) {
    return 'Loading...'
  }

  if (!user?.authenticated) {
    history.push({ pathname: '/login', state: { afterLoginPath: props.match.url } });
    return <></>
  }

  if (!isAdmin())  {
    if (ndaRequirement && ndaRequirement.type === 'RegD' && user.investor_type !== INVESTOR_TYPE.accredited) {
      history.push({ pathname: '/account/investor-info' });
      return <></>
    }
  }

  if (!isAdmin() && ndaRequirement.nda_required) {
    return <NDARequirement requirement={ndaRequirement} />
  }

  return <PreofferDetail />
}

function CompanyOverview({ company, following_company }) {
  return (
    <div id='company-overview' className="component-box" >
      <div className="pitch-section-width">
        <PitchSectionLabel label={'Company'} />
        <div className="pitch-section-body">
          <div className="component-box-header">
            <div className="cbh-logo">
              <img src={company?.logo_url} alt="" className="logo" />
            </div>
            <div className="customer-summary">
              <div className="name">{company?.legal_name ? company?.legal_name : '-'}</div>
              <div className="custom-badge">{company?.parent_sector_name}/{company?.sector_name}</div>
            </div>
          </div>
          <div className="company-inner-section mt-4">
            <div className="company-inner-section-description">
              <p>{company.description}</p>
            </div>
          </div>
          <CompanySectionTable company={company} following_company={following_company} />
        </div>
      </div>
    </div>
  )
}

const SubscriptionSchema = Yup.object().shape({
  amount: Yup.number()
    .positive()
    .integer()
    .min(100, 'Minimum interest amount is $100')
    .max(25000, 'Maximum interest amount is $25,000')
    .transform(value => (isNaN(value) ? 0 : parseInt(value)))
    .required(),
  country: Yup.string().required(),
  phone: Yup.string().required(),
});

const SubscribeButton = ({ preoffer, disableInterestBtn, user = {} }) => {
  const location = useLocation();
  const { Country } = useGeoInfo();
  const allCountries = Country.list().map(c => c.name)
  const { authData } = React.useContext(AuthContext);
  const { name = '', email = '', amount } = { ...user, name: [user.first_name, user.last_name].filter(Boolean).join(' ') };
  const [navbarInvBtnClass, setNavbarInvBtnClass] = React.useState('invisible-offer-nav-btn')
  const loginModal = React.useContext(PortalizedModalContext);

  const scrollEventListener = () => {
    const offerNavbarOffset = document.getElementById('companyBanner')?.clientHeight + document.getElementById('app-navbar')?.clientHeight;
    const pageScroll = window.pageYOffset;
    if (pageScroll >= offerNavbarOffset) {
      setNavbarInvBtnClass('')
    }
    else {
      setNavbarInvBtnClass('invisible-offer-nav-btn')
    }
  }
  const methods = useForm({
    resolver: yupResolver(SubscriptionSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: { name, email, amount }
  });

  React.useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    methods.setValue("country", allCountries[0])

    return () => { window.removeEventListener('scroll', scrollEventListener); }
  }, [allCountries, methods]);

  if (preoffer.status !== 'live') {
    return null;
  }

  const onIndicateInterest = () => {
    runGAEvent(`${preoffer.company.name} indicate interest button clicked`, "button", "clicked");
    fbEvent(`${preoffer.company.name} indicate interest button clicked`);

    if (!authData.isAuthenticated) {
      ToastMessage.error("You must sign up or login to indicate interest.");
      loginModal.setModalContent(
        `Indicate Interest in ${preoffer.company.name}`,
        <>
          <p>
            You must login or sign up to indicate interest in {preoffer.company.name}
          </p>
          <div className="portalized-modal-footer">
            <button
              className="btn-black"
              onClick={() => {
                loginModal.closeModal();
                history.push("/login");
              }}
            >
              Login
            </button>
            <button
              className="btn-black-outline"
              onClick={() => {
                loginModal.closeModal();
                history.push("/signup");
              }}
            >
              Sign Up
            </button>
          </div>
        </>,
        "sm"
      );
      loginModal.openModal();
      return;
    }

    if (user.clients.length === 0) {
      history.push({ pathname: `/investor-details/prelaunches/${preoffer.slug}`, state: { afterLoginPath: location.pathname } })
    } else {
      history.push({ pathname: `/prelaunches/${preoffer.slug}/indicate-interest`, state: { afterLoginPath: location.pathname } })
    }
  }
  let btnText = preoffer.category === "secondary" ? "Request Allocation" : "Indicate Interest";

  if (!authData.isAuthenticated) {
    disableInterestBtn = false;
    btnText = "INDICATE INTEREST";
  }

  return (
    <button type="button" disabled={disableInterestBtn} className={`investment-btn ${navbarInvBtnClass} text-uppercase`}
      onClick={onIndicateInterest}>
      {btnText}
    </button>
  )
}

const ProgressBar = ({ preoffer, bgLight = false, showRaisedAmount = true }) => {
  const raisedAmount = preoffer.committed;
  const goalAmount = preoffer.goal_amount;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, goalAmount);
  const textShown = preoffer.category === "secondary" ? "subscribed" : "interested";

  return (
    <>
      {
        showRaisedAmount &&
        <div className="amount-raised">
          <span className="amount">
            <FormattedNumberWithSymbol number={raisedAmount} numberFormattingOpts={{ precision: 0 }} />
          </span>
          <span className="raised-text">&nbsp;{textShown}</span>
          <CurrencyBadge />
        </div>
      }
      <div className="progressbar-summary">
        <div className="funded mb-3 mb-sm-2">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-2">of goal reached</span>
        </div>
      </div>
      <div className="progress-box">
        <div className={`progressbar ${bgLight ? 'bg-light' : ''}`}>
          <div style={{ width: `${progressBarPercentage(fundsPercentage)}%` }} className="progress" />
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="investors">
          <span className="investors-count">{preoffer.preoffer_subscribers_count}</span>
          <span className="investors-text ml-2">{textShown}</span>
        </div>
      </div>
    </>
  )
}
