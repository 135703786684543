import React from 'react'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import { progressBarPercentage, raisedFundsPercentage } from '../../jsUtils/offerUtils';

function ProgressBar({ preoffer }) {
  const raisedAmount = parseInt(preoffer.committed) || 0;
  const targetGoal = preoffer.goal_amount;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, targetGoal);

  return (
    <div className="investment-detail-banner-content">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="amount-raised mb-2">
          <span className="amount"><b>{formatMoney(raisedAmount, { precision: 0 })}</b></span>
          <span className="raised-text">&nbsp;Raised</span>
        </div>
        <div>
          <span className="status-badge">{preoffer.status}</span>
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="funded mb-2">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-2">of target: {formatMoney(targetGoal, { precision: 0 })}</span>
        </div>
      </div>
      <div className="progress-box">
        <div className={`progressbar`}><div style={{ width: `${progressBarPercentage(fundsPercentage)}%` }} className="progress"></div></div>
      </div>
      <div className="progressbar-summary">
        <div className="investors">
          <span className="investors-count">{preoffer.preoffer_subscribers_count || 0}</span>
          <span className="investors-text ml-2">Investors</span>
        </div>
      </div>
    </div>
  )
}

export default function SubscriptionProgressBanner({ preoffer }) {
  return (
    <div className="investment-detail-banner">
      <div className="investment-detail-banner-thumb mb-lg-0 mb-3">
        <div className="investment-detail-thumbnail-box">
          <img src={preoffer.company.logo_url} className="investment-detail-thumbnail" alt="company logo" />
        </div>
      </div>
      <ProgressBar preoffer={preoffer} />
    </div>
  )
}