import React from "react";
import styled from "styled-components";
import LiveEventIndicator from "../../components/LiveEvent/LiveEventIndicator";
import RemainingTimeBadge from "../../components/RemainingTimeBadge";
import ClosedTimeBadge from "../../components/ClosedTimeBadge";
import { formatMoneyClean } from '../../jsUtils/currencyUtils';
import { convertNumberToShortHand } from "../../jsUtils/stringUtils";

const OfferCardBanner = function ({ offer, type }) {
  if(!offer) return null;

  const company = offer.company;
  const deal = offer.deal;
  const showValuationOrValuationCap = deal?.valuation ? `Valuation: $${convertNumberToShortHand(deal?.valuation)}` : `Valuation Cap: $${convertNumberToShortHand(deal?.valuation_cap)}`;
  const bannerTopTextForOffers = deal?.valuation || deal?.valuation_cap ? showValuationOrValuationCap : "Accepting investors";

  const getHeaderContent = () => {
    switch (type) {
      case "offer":
        return (
          <>
            {/* {offer.status !== "closed" ? (
              <RemainingTimeBadge
                closeDate={deal?.close_date}
              />
            ) : <ClosedTimeBadge prefix="Closed on" closeDate={deal?.close_date}/>} */}
            {offer.status !== "closed" ? bannerTopTextForOffers : <ClosedTimeBadge prefix="Closed on" closeDate={deal?.close_date}/>}
          </>
        );
      case "preOffer":
        return <>Pre-launch</>;
      case "petitionToRaise":
        return <>Petition to raise</>;
      default:
        break;
    }
  };

  return (
    <Banner className="offer-card-banner" banner={company.banner_url}>
      <div className="offer-card-banner-header">
        {getHeaderContent()}
      </div>
      {offer.live_event && type==="offer" && <LiveEventIndicator liveEvent={offer.live_event} />}
      <div className="offer-card-thumb-container">
        <div className="offer-card-thumb">
          <img
            className="offer-card-thumb-image"
            src={company.logo_url}
            alt="offer"
          ></img>
        </div>
      </div>
      <div className="offer-card-banner-content">
        <h4 className="weight-800 fs-6 montserrat text-white">
          {company.name}
        </h4>
        <div className="offer-card-badge-container">
          <div className="card-badge-layout">
            <div className="card-badge mb-2 mr-2">
              <span className="card-badge-text poppins">
                {company.parent_sector_name}
              </span>
            </div>
            <div className="card-badge mb-2 mr-2">
              <span className="card-badge-text poppins">
                {company.sector_name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
};

const Banner = styled.div`
  background: linear-gradient(to top, #111, transparent 60%),
    url(${(props) => props.banner});
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 230px;
  background-color: #111;
  display: grid;
  align-content: space-between;
  background-position: center 55px;
`;

export default OfferCardBanner;
