import React, {useEffect, useState} from "react";
import { raisedFundsPercentage } from "../../jsUtils/offerUtils";
import { usePusherChannel } from '../../contexts/PusherChannelContext';
import LiveViewerCount from "./LiveViewerCount";
import {
  FormattedNumberWithSymbol,
} from "../AmountWithCurrency";
import ShareButtons from "../../investor/features/raise_petition/components/ShareButtons";

export default function OfferProgressBar({ offer, isAdminUser }) {
  const pusherChannel = usePusherChannel(offer.live_event.channel_id);
  const maximumGoal = offer.deal.maximum_goal || 0;
  const dealRaisedAmount = parseInt(offer.deal.raised || 0);
  const [status, setStatus] = useState(offer.live_event.status);

  const [liveStats, setLiveStats] = useState({
    counterStartValue: dealRaisedAmount,
    raisedAmount: dealRaisedAmount,
    investorsCount: offer.investor_count || 0
  });

  useEffect(() => {
    pusherChannel.bind('new-investment', (data) => {
      setLiveStats({
        counterStartValue: (parseInt(data.offer.raised_amount) - parseInt(data.user_investment.amount)),
        raisedAmount: parseInt(data.offer.raised_amount),
        investorsCount: data.offer.investor_count
      })
    });
    pusherChannel.bind('status-change', (data) => setStatus(data.status));

    return () => {
      pusherChannel.unbind('new-investment', () => {});
      pusherChannel.unbind('status-change', () => {});
    }
  }, []);

  const fundsPercentage = raisedFundsPercentage(liveStats.raisedAmount, maximumGoal) || 0;
  const showFundsPercentage = fundsPercentage >= 10;

  return (
    <div className="offer-stats-container row col-12 col-md-12 mx-0 mt-4">
      <div className="col-md-9 d-flex my-3">
        <div className="poppins fs-14 weight-700 mr-2 pr-3 seperator">
          <span className="mr-1">
            <FormattedNumberWithSymbol number={liveStats.raisedAmount} numberFormattingOpts={{precision: 0}} />
          </span>
          <span>TOTAL RAISED</span>
        </div>
          { showFundsPercentage && (
              <div className="poppins fs-14 weight-400 mx-2 pr-3 seperator">
                <span className="weight-700">{fundsPercentage}%</span> of Max Goal
              </div>
            )}
        <div className={`poppins fs-14 mx-2 pr-3 ${status == 'live' ? 'seperator' : ''}`}>
          <span className="weight-700 mr-1">{liveStats.investorsCount}</span>
          <span>Investors</span>
        </div>
        <div className="poppins fs-14 mx-lg-2 pr-lg-3">
          <LiveViewerCount liveEvent={offer.live_event} />
        </div>
      </div>
      <div className="col-md-3 deal-info-col1 pb-0 d-flex justify-content-end align-items-center">
        <div className="offer-social-icons d-flex">
          <ShareButtons offering={offer} />
        </div>
      </div>
    </div>
  )
}