export const getAttachmentTypes = (clientType, category) => {
  if (category === 'accreditation_verification') {
    const types = [];

    if (clientType === 'company' || clientType === 'trust') {
      types.push({ label: "Entity Assets Proof", value: "entity_assets_proof" });
      types.push({ label: "Operating Agreement", value: "operating_agreement" });
      types.push({
        label: "Member's list and authorized signers list (if not included in the documents)",
        value: "members_or_authorized_signers_list"
      });
    }

    types.push({ label: "Income Proof", value: "investor_income_proof" })
    types.push({ label: "Assets Proof", value: "investor_assets_proof" })
    types.push({ label: "Professional Letter", value: "professional_letter" })
    types.push({ label: "Professional Designation", value: "professional_designation" })
    types.push({ label: "Government ID", value: "government_id" })
    types.push({ label: "Other", value: "other" })

    return types;
  }

  switch (clientType) {
    case "person":
      return [
        { label: "Proof of Address", value: "proof_of_address" },
        { label: "Government ID", value: "government_id" },
        { label: "Tax ID", value: "tax_id" },
        { label: "Other", value: "other" },
      ];
    case "trust":
      return [
        { label: "Full trust document or a certification of trust", value: "certification_of_trust" },
        { label: "For irrevocable trusts, their EIN", value: "irrevocable_trusts_ein" },
        { label: "List of all trustees, grantors (if still living), and protectors, including their full legal name, DOB, SSN, and residential address", value: "list_of_trustees" },
        { label: "Other", value: "other" },
      ];
    default:
      return [
        {
          label: "Operating Agreement",
          value: "operating_agreement",
        },
        {
          label: "Articles of Organization",
          value: "articles_of_organization",
        },
        {
          label: "Certificate of Formation",
          value: "certificate_of_formation",
        },
        {
          label: "Member's list and authorized signers list (if not included in the documents)",
          value: "members_or_authorized_signers_list"
        },
        {
          label: "Other",
          value: "other"
        }
      ];
  }

};
