import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    setTimeout(() => {
      if(history?.location?.state?.scroll){
        const appNavbarHeight = document.getElementById('app-navbar')?.clientHeight;
        const companyBannerHeight = document.getElementById('companyBanner')?.clientHeight;
        const offerNavBarOffSet = companyBannerHeight + (appNavbarHeight ?? 0 );
        window.scrollTo(0, offerNavBarOffSet)
      }
      else{
        !history?.location?.hash && window.scrollTo(0, 0)
      }
    }, 100);
  },[history?.location?.pathname]);
  return (null);
}

export default withRouter(ScrollToTop);
