import React from "react";
import { useParams } from "react-router-dom";
import OverviewHeader from "../../components/OverviewHeader";
import { useExceptionDetails } from "./queries";
import AMLOverview from "./AMLOverview";
import VerificationFieldList from "./VerificationFieldList";
import InvestmentList from "./InvestmentsList";
import EntityDocumentList from "./EntityDocumentList";
import NoteList from "./NoteList";
import KycAMLIssueList from "./KycAmlIssueList";
import ActionButtons from "./ActionButtons";
import IndividualDetail from "../../components/InvestorDetail/IndividualDetail";
import EntityDetail from "../../components/InvestorDetail/EntityDetail";
import ClientAuditLogs from "./ClientAuditLogs";
import LoadingOverlay from "react-loading-overlay";
function Overview() {
  const { client_id: clientId } = useParams();
  const { data: amlException, isLoading, isError } = useExceptionDetails(clientId);

  if (isLoading) return <LoadingOverlay active={true} spinner text="Loading" />

  if (isError) return null;

  return (
    <div className="aml-details-container">
      <OverviewHeader left={"AML Details"} right={
        amlException.status !== 'cleared' && <ActionButtons amlException={amlException} />
      } />
      <div
        className="tab-pane fade show active"
        id="offer-overview"
        role="tabpanel"
      >
        {amlException.client.entity_type === "person" ?
          <IndividualDetail account={amlException.client} />
          : <EntityDetail account={amlException.client} />
        }
        <ClientAuditLogs clientId={amlException.client_id} />
        <AMLOverview amlException={amlException} />
        {amlException.client.entity_type === "person" && (
          <KycAMLIssueList amlException={amlException} />
        )}
        {amlException.client.entity_type === "company" && (
          <NoteList amlException={amlException} />
        )}
        <EntityDocumentList amlException={amlException} />
        <VerificationFieldList amlException={amlException} />
        <InvestmentList clientId={clientId} />
      </div>
    </div>
  );
}

Overview.defaultProps = {};

Overview.propTypes = {};

export default Overview;
