import React from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import {BsInfoCircle} from 'react-icons/bs'

import { AuthContext } from 'contexts/authContext';
import ShareButtons from "./components/ShareButtons";
import { useRaisePetition } from "../../queries";
import HowItWorks from "./components/HowItWorks";
import PetitionBanner from "./components/PetitionBanner";
import PledgeForm from "./components/PledgeForm";
import RaiseChart from "./components/RaiseChart";
import OfferListMarketing from '../../../pages/OffersListMarketing';
import "./RaisePetition.ViewPage.scss";
import { formatMoneyClean } from "../../../jsUtils/currencyUtils";
import { PageViewFB } from "../../../pages/components/Tracking/facebook";

import {numberWithCommas} from '../../../../src/jsUtils/stringUtils';

const GoogleFormLink = "https://forms.gle/fAajFNaTMTngN3x5A";

export default function RaisePetitionViewPage({petitionId}){
  const { isAdmin } = React.useContext(AuthContext);
  const { data:raisePetition, isLoading } = useRaisePetition(petitionId, isAdmin());
  const [progressValue, setProgressValue] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setProgressValue(Math.min((raisePetition?.pledged_amount / raisePetition?.goal_amount)*100, 100));
    }, 500)
  }, [raisePetition?.pledged_amount, raisePetition?.goal_amount, progressValue]);

  React.useEffect(() => {
    PageViewFB();
  }, [])  

  if (isLoading)
    return <>Loading...</>

  const { company } = raisePetition;
  const pageTitle = `Petition ${company.name} to raise funds on Spaced Ventures`;
  const pageDescription = `Join the petition to get ${company.name} to raise funds on Spaced Ventures by pledging your investment interest`;

  return(
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charset="utf-8" />
        <meta property="type" content="website" />
        <meta property="url" content={window.location.href} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta property="title" content={pageTitle} />
        <meta property="description" content={pageDescription} />
        <meta property="quote" content="quote" />
        <meta property="image" content={company.banner_url} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:quote" content="quote" />
        <meta property="og:hashtag" content="hashtag" />
        <meta property="og:image" content={company.banner_url} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="SpacedVentures" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={company.banner_url} />
        <meta name="twitter:url" content={window.location.href} />

        <meta content="image/*" property="og:image:type" />
      </Helmet>

      <div className="petitions-banner">
        <div className="container">
          <div className="company-details-box">
            <div className="company-details-box-inner">
              <div className="company-logo border border-white">
                <img
                  src={company.logo_url}
                  alt={`${company.name} raise petition logo image`}
                />
              </div>
              <div className="company-details">
                <p className="joined-count">Join {numberWithCommas(raisePetition.pledge_count)} {raisePetition.pledge_count == 1 ? 'other:' : 'others:'}</p>
                <h1 className="company-details-title">
                  Petition
                  <span className="white-outlined-text"> {company.name} </span>
                  to Raise
                </h1>
                <div className="petition-badge poppins">With No Carry &amp; No Fees</div>
                {/* <a href={GoogleFormLink} target="_blank" className="color-grey fs-10 text-decoration-none">Is this your company?</a> */}
              </div>
            </div>
          </div>
          {
            raisePetition.status == "live" && raisePetition.pledge_count >= 3 &&
              (<div className="chart-section">
                  <span className="fs-12 text-muted weight-400 poppins">Spaced Ventures is not affiliated with, sponsored by or endorsed by {company.name}.</span>
                  <div className="chart-box">
                    <RaiseChart petitionId={raisePetition.slug} />
                  </div>
              </div>)
          }
          <div className="large-progress-bar">
            <div className="progress-box">
              <div className={`progressbar ${raisePetition.pledge_count >= 3 ? '' : 'mt-3'}`}>
                <div className="progress" style={{ width: `${progressValue}%` }}></div>
              </div>
            </div>
          </div>
          <div className="chart-summary">
            <h6 className="montserrat weight-900">{formatMoneyClean(raisePetition.pledged_amount)} Total Pledged <span className="color-grey">of a {formatMoneyClean(raisePetition.goal_amount)} Goal</span></h6>
            <div className="share-details d-flex">
              <div className="mr-1">Share to:</div><ShareButtons offering={raisePetition}/>
            </div>
          </div>
        </div>
      </div>
      <div className="investment-form-section">
        <div className="container">
            <div className="investment-form-section-content">
                <h4 className="section-title">How much do you want to invest? 
                    <span className="inv-tooltip-box">
                    <ReactTooltip id="inv-tooltip" type="light" effect="solid" className="spaced-tooltip zeitung">
                        <span>
                        Your investment amount is non-binding and will not be shared with the company. An indication of interest involves no obligation or commitment of any kind.
                        </span>
                        </ReactTooltip>
                        <BsInfoCircle style={{marginLeft: '7px'}} color="#ccc" size="0.75em" data-tip data-for='inv-tooltip' data-event-off='dblclick' />
                    </span>
                </h4>
                <PledgeForm raisePetition={raisePetition}/>
            </div>
        </div>
      </div>
      <div className="how-petition-raise-work-section">
        <HowItWorks company={company} />
      </div>
      <div className="community-joining-section">
        <div className="container">
          <div className="community-joining-details-box">
            <div className="community-joining-details-box-inner">
              <div className="company-logo">
                <img
                  src={company.logo_url}
                  alt={`${company.name} raise petition logo image`}
                />
              </div>
              <div className="community-joining-details">
                <h3 className="community-joining-title">Petition {company.name} to raise funds on Spaced Ventures</h3>
                <PledgeForm raisePetition={raisePetition} theme="light" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"/>
      <div className="trending-companies-section">
        <OfferListMarketing />
      </div>
    </>

  )
}