import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useQuery } from 'react-query';
import { formatDate } from 'jsUtils/dateTimeUtils';
import { ResponsiveTable } from 'admin/components'
import {Paginator, OverviewSubheader, TextButton} from 'admin/components'
import usePagination from 'hooks/usePagination'
import { companyApi } from 'api/company';
import { queryClient } from 'index';
import { useExportFollowersList } from '../../queries';

function List({ companyId }) {
  const pageConfig = usePagination();

  const followersList = useQuery(
    ['company/followers', companyId, pageConfig.current.page],
    () => companyApi.followersList(companyId, pageConfig.current.page, pageConfig.current.per_page)
  );

const _exportFollowersList = useExportFollowersList(companyId)
const exportFollowers = async () => {
  queryClient.refetchQueries(
    ['company', companyId, 'exportfollowersList'],
    {
      enabled: true,
    }
  )
}

  const columns = [
    {
      Header: 'First Name',
      accessor: 'user.first_name'
    },
    {
      Header: 'Last Name',
      accessor: 'user.last_name'
    },
    {
      Header: 'Followed On',
      accessor: 'created_at',
      Cell: ({value}) => formatDate(value, 'MMM dd, yyyy')
    }
  ];

  const tableData = React.useMemo(
    () => {
      if (followersList.isSuccess)
        return followersList.data.data
      else
        return []
    },
    [followersList]
  );

  if(followersList.error){
    return <h5 className='text-center text-danger'>Something went wrong please try again after some time.</h5>
  }

  return(
    <div>
       <OverviewSubheader left='Followers' right={<TextButton title='Export' onClick={exportFollowers} />} />
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={followersList?.data?.pagy}
        loading={followersList.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={followersList.isLoading}
        loadingMsg="Loading company followers..."
        noDataMsg="Company has no followers yet."
      />
    </div>
  );
}

List.defaultProps = {};

List.propTypes = {
  companyId: PropTypes.string
};

export default List;
