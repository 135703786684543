import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useQueryClient, useMutation } from 'react-query';
import { toTitlecase } from 'jsUtils/stringUtils';
import { preventDefaultSubmit, scrollIntoView } from '../../../jsUtils/formUtils';
import { createAchPayment } from 'redux/user';
import FormEffect from '../shared/FormEffect'
import { useUser } from 'redux/user'
import LoadingOverlay from 'react-loading-overlay';
import "./AchPaymentForm.scss";
import { addAchAccount } from "../../../investor/queries";
import ToastMessage from "components/ToastMessage";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";

export default function AchForm({
  clientId,
  onSuccess = null,
  onError = null,
  submitButtonText = "Submit",
  subHeader = "",
  showSidebar = false,
  extras = null,
  // adjustHeight,
}) {
  const queryClient = useQueryClient();
  const user = useUser();
  const loading = user.loading;

  const addAchMutation = useMutation(addAchAccount, {
    onSuccess: (account) => {
      typeof onSuccess === "function" && onSuccess(account);
      queryClient.refetchQueries(['client-payment-methods', clientId])
      ToastMessage.success("ACH account added.");
    },
    onError: (error) => {
      typeof onError === "function" && onError(error);
      renderRailsErrors(error);
    }
  })

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Loading ...'>
      {/* <div className={`ach-formbox ${adjustHeight ? 'ach-formbox-with-dropdown' : ''}`}> */}
      <div className="ach-formbox">
        <Formik
          initialValues={{
            name_on_account: "",
            account_number: "",
            routing_number: "",
            agree: false,
          }}
          validationSchema={Yup.object().shape({
            name_on_account: Yup.string()
              .required("Name on account is required")
              .max(255, "Name on account too long, max-length 255 characters"),

            account_number: Yup.string()
              .required("Account number is required"),

            routing_number: Yup.string()
              .required("Routing number is required")
              .matches(/^\d+$/g, 'Must be only digits')
              .length(9, 'Must be exactly 9 digits'),

            agree: Yup.boolean()
              .oneOf([true], 'Please agree to continue'),
          })}

          onSubmit={async (values, { }) => {
            if (extras) {
              values = {
                ...values,
                ...extras,
              }
            }

            addAchMutation.mutate({
              clientId, values
            })
          }}
        >
          {({ values, touched, errors, setFieldValue, setFieldTouched, submitCount, isSubmitting, isValid, dirty, handleChange }) => (
            <Form onKeyDown={preventDefaultSubmit}>
              <FormEffect
                submitCount={submitCount}
                isValid={isValid}
                isSubmitting={isSubmitting}
                errors={errors}
                name="agree"
                onSubmissionError={() => scrollIntoView("info-certify-checkbox")}
              />
              <div className="container pl-0">
                <div className="row">
                  <div className={`ach-formbox-inner1-col ${showSidebar ? 'col-md-9 py-5 pr-xl-5' : 'col-12'}`}>
                    <div className={`ach-formbox-inner1 ${showSidebar ? 'pr-xl-5' : ''}`}>
                      <div className="ach-frombox-inner1-body">
                        {subHeader && <span className="zeitung-micro fs-10 weight-300 instruction-text-for-mobile">{subHeader}</span>}
                        <div className="form-label-group mt-3">
                          <Field type="text"
                            id="name_on_account"
                            name="name_on_account"
                            className={`form-control ${touched.name_on_account && errors.name_on_account ? "is-invalid" : ""}`}
                            placeholder="Name on account"
                          />
                          <label htmlFor="name_on_account">Name on account</label>
                          <ErrorMessage name="name_on_account" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="form-label-group">
                              <Field type="number"
                                id="account_number"
                                name="account_number"
                                className={`form-control ${touched.account_number && errors.account_number ? "is-invalid" : ""}`}
                                placeholder="Account number"
                              />
                              <label htmlFor="account_number">Account number</label>
                              <ErrorMessage name="account_number" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>
                          <div className="col-md-6 px-1">
                            <div className="form-label-group">
                              <Field type="string"
                                id="routing_number"
                                name="routing_number"
                                className={`form-control ${touched.routing_number && errors.routing_number ? "is-invalid" : ""}`}
                                placeholder="Routing number"
                              />
                              <label htmlFor="routing_number">Routing number</label>
                              <ErrorMessage name="routing_number" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>
                        </div>

                        <div>
                          <p className="hint poppins weight-300 m-t-10 instruction-text-for-mobile" style={{ fontSize: 13, lineheight: 19, color: "#777777" }}>
                          By checking this box you authorize this transaction and agree to our terms of use and EFT disclosure.
                          Full details on terms of use and EFT disclosure can be reviewed <Link to="/payments-disclaimer" target="_blank"> here</Link>.</p>

                          <label className={`custom-checkbox form-group d-flex flex-wrap position-relative mt-4 ${errors.terms ? 'is-invalid' : ''}`}>
                            <input id="info-certify-checkbox" type="checkbox" name="agree" className="mr-2" onChange={handleChange} />
                            <span className="custom-checkbox-check"></span>
                            <span className="custom-checkbox-tagline" style={{ fontSize: 13, lineheight: 19, color: "#777777" }}>I authorize this transaction and agree to EFT terms of use</span>
                            <div style={{ marginLeft: '32px' }} className="failed-error-message">{errors.agree}</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showSidebar && <div className="col-md-3 py-5 bg-lightgrey ach-formbox-inner2">
                    <h5 className="poppins weight-700">How do we use this info?</h5>
                    <p>We use a third party payment provider to carry out the payment.</p>
                  </div>}
                </div>
                <div className="portalized-modal-footer">
                  <button type='submit' disabled={addAchMutation.isLoading} className="btn-black">{submitButtonText}</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LoadingOverlay>
  )
}
