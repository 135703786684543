import PropTypes from "prop-types";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FileSelect from "components/HookForm/FileSelect";

const BulkInvitationSchema = Yup.object().shape({
  invitation: Yup.object().shape({
    csv_file: Yup.mixed().required(),
  }),
});

const BulkInvitationForm = ({eventType, eventId, bulkInviteMutation}) => {

  const methods = useForm({
    resolver: yupResolver(BulkInvitationSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      guest_email: '',
    },
  });

  const onSubmit = ({invitation}) => {
    const fd = new FormData();
    fd.append('invitation[csv_file]', invitation.csv_file)

    bulkInviteMutation.mutate({
      values: fd
    })
  }

  return (
    <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <FileSelect
                name="invitation.csv_file"
                placeholder="Upload CSV File"
                fileType="text/csv"
                method="multipart"
              />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="btn-black"
              disabled={methods.isSubmitting}
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
  );
};

BulkInvitationForm.defaultProps = {};

BulkInvitationForm.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  bulkInviteMutation: PropTypes.func.isRequired,
};

export default BulkInvitationForm;
