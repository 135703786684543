import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

const feautredMediaLogos = [
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/medialogo-politico%402x.png",
    name: "Politico",
    logoWidth: "110px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/the-hill-logo.jpg",
    name: "hill",
    logoWidth: "150px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/medialogoaxios.png",
    name: "Axios",
    logoWidth: "110px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/medialogo-fortune%402x.png",
    name: "Fortune",
    logoWidth: "110px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/medialogo-benzinga%402x.png",
    name: "Bezinga",
    logoWidth: "120px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/medialogo-spacenews%402x.png",
    name: "Space News",
    logoWidth: "150px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/2f7334deb2f284283dc1c38b55205932c3c20870-840.png",
    name: "Morning Brew",
    logoWidth: "160px"
  },
  {
    img_url: "https://spacedventure-marketing.s3.amazonaws.com/5a1863973a18271ae9cd636e.png",
    name: "Tech Crunch",
    logoWidth: "160px"
  },    
]


function FeaturedMediaMentions ({showLink = false, showBorder = false}) {
  return (
    <FeaturedLogoContainerStyles showBorder={showBorder}>
      <div class="featured-media-section">
        <div className="container">
        <div className="d-flex justify-content-center">
          <h2 className="fs-5 weight-900 montserrat ">Featured In</h2>
        </div>
        </div>
        <div class="px-2 m-t-50">
          <Marquee speed={50} gradient={false} direction='right'>
            {feautredMediaLogos.map((item, key) => {
              return (
                <FeaturedMediaCardStyles logoWidth={item.logoWidth} key={key}>
                  <img className={`featured-media-logo-${item.name}`} src={item.img_url} alt={item.name} />
                </FeaturedMediaCardStyles>
                )
            })}
          </Marquee>
        </div>
      </div>
        { showLink && <FeaturedMediaLinkStyles><Link to="press">See More Coverage ></Link></FeaturedMediaLinkStyles> }
    
    </FeaturedLogoContainerStyles>
  )
}

export default FeaturedMediaMentions;

const FeaturedMediaCardStyles = styled.div`
   margin-right: 90px;
   display: flex;
   align-items: center;
    img {
      max-width: ${props => props.logoWidth};
      /* max-width: 150px; */
    }
`

const FeaturedLogoContainerStyles = styled.div`
  /* border-top: ${props => props.showBorder ? "1px dotted #111" : "none"}; */
  border-bottom: ${props => props.showBorder ? "1px dotted #111" : "none"};
  .featured-media-section {
  padding-top: 100px;
  padding-bottom: 100px;
  }
`

const FeaturedMediaLinkStyles = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
    pointer: cursor;
    color: #888;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`