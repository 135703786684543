import React, { useState, useEffect, useMemo } from 'react';
import { useUser, createEntityInvestment, updateEntityAccount } from 'redux/user'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FixedButtonBox from '../shared/FixedButtonBox';
import * as Yup from 'yup';
import { toTitlecase, getStatesOption, getStatesLabel, getZipCodelabel, getphoneNumberMask, getEntityTaxIdMask } from 'jsUtils/stringUtils';
import MaskedInput from "react-text-mask";
import { googleAddresses } from '../../../jsUtils/googleAddresses';
import postalCodes from 'postal-codes-js'
import { preventDefaultSubmit } from '../../../jsUtils/formUtils';
import { PO_BOX_REGEX, RESTRICTED_STATES } from 'config/constants';
import FormikValidationsRollbarReporter from '../shared/FormikValidationsRollbarReporter';
import DatePickerField from '../../../components/Formik/DatePickerField';
import { formatDate } from '../../../jsUtils/dateTimeUtils';
import useGeoInfo from '../../../lib/useGeoInfo';
import LoadingOverlay from 'react-loading-overlay';

export default function EntityDetailsForm({
  submitButtonText = "Add Entity",
  allowInternationalInvestors = false,
  entityDetail = {},
  onSubmit = null,
  userScope,
  type
}) {
  const { Country, State } = useGeoInfo();

  const COUNTRIES = useMemo(
    () => {
      if(type === 'preoffer'){
        return Country.list();
      } else  {
        return allowInternationalInvestors ? Country.forInternationalOffers() : [Country.findByISO2Code("US")]
      }
    },
    [allowInternationalInvestors]
  )

  const user = useUser();
  const [countryCode, setCountryCode] = useState('US');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [states, setStates] = useState([]);
  useEffect(() => {
    if (entityDetail?.country) {
      setCountryCode(entityDetail?.country)
    }
  }, [entityDetail])

  useMemo(() => {
    setStates(State.withISO2CountryCode(countryCode));
  }, [countryCode])

  function addressChangeHandler(e, handleChange, setFieldValue, setInvalidAddress, countryCode) {
    handleChange(e);
    const addressElement = document.getElementById('street_address_1');
    const fieldsObj = { postal_code: 'postal_code', address: 'street_address_1' };

    googleAddresses(addressElement, fieldsObj, setFieldValue, setInvalidAddress, countryCode)
  }
  const countryChangeHandler = (e, handleChange, setCountryCode, setFieldValue) => {
    handleChange(e);
    setFieldValue('phone', '')
    setFieldValue('tax_id_number', '')
    setCountryCode(e.target.value);
    setFieldValue('region', '');
    setFieldValue('region_formed_in', '');
    setFieldValue('postal_code', '');
    setFieldValue('city', '');
    setFieldValue('street_address_1', '');
  }

  const entityLabel = entityDetail.entity_type === 'trust' ? 'Trust' : 'LLC';

  return (
  <LoadingOverlay active={user.loading} spinner text="Loading">
    <div className="investor-formbox">
      <Formik
        initialValues={{
          name: entityDetail?.name || "",
          date_of_formation: entityDetail?.date_of_formation || "",
          street_address_1: entityDetail?.street_address_1 || "",
          street_address_2: entityDetail?.street_address_2 || "",
          tax_id_number: entityDetail?.tax_id_number || "",
          country: entityDetail?.country || "US",
          city: entityDetail?.city || "",
          region: entityDetail?.region || "",
          region_formed_in: entityDetail?.region_formed_in || "",
          postal_code: entityDetail?.postal_code || "",
          country_of_formation: entityDetail?.country_of_formation || "",
          phone: entityDetail?.phone || "",
          sole_owner_acceptance: entityDetail?.sole_owner_acceptance || false,
          sole_signatory_acceptance: entityDetail?.sole_signatory_acceptance || false,
          entity_type: entityDetail?.entity_type || 'company',
          entity_sub_type: entityDetail?.entity_sub_type || "none"
        }}
        validationSchema={Yup.object().shape({
          entity_type: Yup.string().required(),

          name: Yup.string()
            .required("Entity name is required")
            .max(255, "Entity name too long, max-length 255 characters"),

          street_address_1: Yup.string()
            .required("Address is required")
            .max(255, "Address too long, max-length 255 characters")
            .test("", "", function (val) {
              if (val && val.match(PO_BOX_REGEX)) return this.resolve(this.createError({ message: "You cannot use a PO box address for an Entity account, please use another address" }))
              else if (invalidAddress === true) return this.resolve(this.createError({ message: "Invalid Address. Please ensure that you are entering valid address." }))
              else return true;
            }),

          street_address_2: Yup.string()
            .test("", "", function (val) {
              if (!val?.trim()) return true
              if (val.match(/^\d+$/g)) {
                return true;
              } else if (val.length === 1 && !val.match(/^\d+$/g)) {
                return this.resolve(this.createError({ message: "Can't contain only letter" }))
              }
              else {
                if (val.match(/^[A-Za-z0-9]*$/g)?.toString()?.match(/[A-Za-z]/g).length !== 1)
                  return this.resolve(this.createError({ message: "Can only contain 1 letter for unit ex. 303a or a303" }))

                else if (!(val.charAt(0).match(/[A-Za-z]/g) || val.charAt(val.length - 1).match(/[A-Za-z]/g))) {
                  return this.resolve(this.createError({ message: "Can't contain a letter in between numbers" }))
                }
              }
            }),

          tax_id_number: Yup.string()
            .required("Tax ID is required")
            .test('len', function (val) {
              if(!val){
                return true
              }

              const length = val.match(/[a-zA-Z0-9]/g).length;

              if (countryCode === 'GB' && length >= 9 && length <= 15)
                return true
              else if (countryCode === 'US' && length !== 9)
                return this.resolve(this.createError({ message: "Must be exactly 9 digits" }))
              else if (countryCode === 'GB' && length < 9)
                return this.resolve(this.createError({ message: "Must be between 9 and 15 characters" }))
              else if (!(length >= 6 && length <= 20))
                return this.resolve(this.createError({ message: "Invalid Tax Id" }))
              else return true
            }),

          city: Yup.string()
            .required('City is required'),

          region: Yup.string()
            .test('restrict', '', function (value) {
              if(type === 'preoffer'){
                return true;
              }
              const countryCode = this.resolve(Yup.ref('country'))
              if (RESTRICTED_STATES[countryCode].includes(value))
                return this.resolve(this.createError({ message: `Cannot currently support entities from ${State.findByStateCodeAndISO2CountryCode(value, countryCode).name}` }))
              else
                return true;
            })
            .required(`${getStatesLabel(countryCode)} is required`
            ),

          postal_code: Yup.string()
            .required('Zip code is required')
            .test('validatePostalCode', 'Invalid postal code', val => postalCodes.validate(countryCode, val) === true),
          phone: Yup.string()
            .required('Phone number is required')
            .test('len', function (val) {
              if (countryCode === 'GB' && val?.match(/\d/g)?.length >= 9 && val?.match(/\d/g)?.length <= 11)
                return true
              else if (countryCode !== 'GB' && val?.match(/\d/g)?.length !== 10)
                return this.resolve(this.createError({ message: "Must be exactly 10 digits" }))
              else if (countryCode === 'GB' && val?.match(/\d/g)?.length < 9)
                return this.resolve(this.createError({ message: "Must be between 9 and 11 digits" }))
              else return true
            }),


          country: Yup.string()
            .required('Country is required'),

          date_of_formation: Yup.date().when("entity_type", {
            is: (entity_type) => entity_type === 'company',
            then: (schema) => schema.typeError('Date of formation is required').required("Date of formation is required")
          }),

          country_of_formation: Yup.string().when("entity_type", {
            is: (entity_type) => entity_type === 'company',
            then: (schema) => schema.required("Country of formation is required")
          }),

          region_formed_in: Yup.string().when("entity_type", {
            is: (entity_type) => entity_type === 'company',
            then: (schema) => schema
              .test('restrict', '', function (value, context) {
                if(type === 'preoffer'){ return true }
                const countryCode = this.resolve(Yup.ref('country'))
                if (RESTRICTED_STATES[countryCode].includes(value))
                  return this.resolve(this.createError({ message: `Cannot currently support entities from ${State.findByStateCodeAndISO2CountryCode(value, countryCode).name}` }))
                else
                  return true;
              })
              .required(`${getStatesLabel(countryCode)} formed in required`)
          }),

          entity_sub_type: Yup.string().when("entity_type", {
            is: (entity_type) => entity_type === 'trust',
            then: (schema) => schema.oneOf(['revocable_trust', 'irrevocable_trust'], "Select trust type")
          }),

          sole_owner_acceptance: Yup.boolean()
            .oneOf([true], 'Unfortunately you have to be the sole owner of the entity to invest with it.'),

          sole_signatory_acceptance: Yup.boolean()
            .oneOf([true], 'You must be sole authorised signatory of the entity to invest with it.')
        })}
        onSubmit={async (values, { }) => {
          values.tax_id_number = values.tax_id_number.replace(/[-_]/g, '');
          values.phone = values.phone.replace(/_/g, '');

          if(values.date_of_formation instanceof Date){
            values.date_of_formation = formatDate(values.date_of_formation, 'yyyy-MM-dd');
          }

          if (entityDetail?.id) {
            return updateEntityAccount(entityDetail.id, values, onSubmit, userScope)
          }
          const currentClient = user.clients.find(client => client.entity_type === 'person');
          values.contact_name = currentClient.name;
          values.email = user.email;
          //values.entity_type = 'company';

          return createEntityInvestment(user.id, values, onSubmit);
        }}
      >
        {({ values, touched, errors, setFieldValue, isSubmitting, handleChange }) => (
          <Form onKeyUp={preventDefaultSubmit}>
            <div className="container pl-0">
              <div className="row">
                <div className="col-12">
                  <div className="invest-form-fields investor-formbox-inner1">
                    <div className="rounded zeitung-micro fs-9 mb-3 instruction-text-for-mobile">
                      <span>Please fill out the following info for your { entityLabel } entity to invest with it.</span>
                    </div>
                    <Field type="hidden" name="entity_type"/>
                    { entityDetail.entity_type === 'trust' &&
                      <div className="form-row">
                        <div className="col-sm-12">
                          <div className="form-label-group">
                            <Field type="select"
                              className={`form-control ${touched.entity_sub_type && errors.entity_sub_type ? "is-invalid" : ""}`}
                              name="entity_sub_type"
                              placeholder="Trust Type"
                              component="select"
                            >
                              <option value="">Trust type</option>
                              <option value="revocable_trust">Revocable Trust</option>
                              <option value="irrevocable_trust">Irrevocable Trust</option>
                            </Field>
                            <label htmlFor="entity_sub_type">Select Trust Type</label>
                            <ErrorMessage name="entity_sub_type" component="div" className="invalid-feedback text-left" />
                          </div>
                        </div>
                      </div>
                    }
                    <div className="form-row">
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="text"
                            id="name"
                            name="name"
                            className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                            placeholder="Name"
                          />
                          <label htmlFor="name">Name</label>
                          <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-6">
                        <div className="form-label-group">
                           <Field name="tax_id_number">
                            {({ field }) => {
                              const maskValue = getEntityTaxIdMask(values.country);

                              if(maskValue) {
                                return <MaskedInput
                                  {...field}
                                  mask={maskValue}
                                  id="tax_id_number"
                                  placeholder="SSN"
                                  type="text"
                                  className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                                />
                              } else {
                                return <input
                                  {...field}
                                  type="text"
                                  id="tax_id_number"
                                  className={`form-control ${touched.tax_id_number && errors.tax_id_number ? "is-invalid" : ""}`}
                                />
                              }
                            }}
                          </Field>
                          <label htmlFor="tax_id_number">Tax ID number</label>
                          <ErrorMessage name="tax_id_number" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field name="phone">{({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={getphoneNumberMask(countryCode)}
                                id="phone"
                                placeholder="Phone number"
                                type="text"
                                className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                              />
                            )}
                          </Field>
                          <label htmlFor="phone">Phone number</label>
                          <ErrorMessage name="phone" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <label className={`custom-checkbox form-group d-flex flex-wrap position-relative mt-4 ${errors.terms ? 'is-invalid' : ''}`}>
                      <input id="sole_owner_acceptance" type="checkbox" name="sole_owner_acceptance" className="mr-2" onChange={handleChange} checked={values.sole_owner_acceptance} />
                      <span className="custom-checkbox-check"></span>
                      <span className="custom-checkbox-tagline">Are you the sole owner of this entity?</span>
                      <div style={{ marginLeft: '32px' }} className="failed-error-message">{errors.sole_owner_acceptance}</div>
                    </label>
                    <h5 className="montserrat weight-700 mb-4">Principle place of business</h5>
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-label-group">
                          <Field
                            type="text"
                            id="street_address_1"
                            name="street_address_1"
                            className={`form-control ${touched.street_address_1 && errors.street_address_1 ? "is-invalid" : ""}`}
                            placeholder="Address"
                            onChange={(e) => addressChangeHandler(e, handleChange, setFieldValue, setInvalidAddress, values.country)}
                          />
                          <label htmlFor="street_address_1">Address (No PO boxes allowed)</label>
                          <ErrorMessage name="street_address_1" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-label-group">
                          <Field
                            type="text"
                            id="street_address_2"
                            name="street_address_2"
                            className={`form-control ${touched.street_address_2 && errors.street_address_2 ? "is-invalid" : ""}`}
                            placeholder="Unit/Suite #"
                          />
                          <label htmlFor="street_address_2">Unit/Suite #</label>
                          <ErrorMessage name="street_address_2" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="select"
                            id="country"
                            className={`form-control ${touched.country && errors.country ? "is-invalid" : ""}`}
                            name="country"
                            placeholder="Country"
                            component="select"
                            onChange={(e) => {
                              countryChangeHandler(e, handleChange, setCountryCode, setFieldValue);
                            }}
                          >
                            <option value="">Country</option>
                            {
                              COUNTRIES.map((country, index) => <option key={index} value={country.iso2}>{country.name}</option>)
                            }
                          </Field>
                          <label htmlFor="country">Select Country</label>
                          <ErrorMessage name="country" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="text"
                            id="city"
                            name="city"
                            className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                            placeholder="City"
                          />
                          <label htmlFor="city">City</label>
                          <ErrorMessage name="city" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="select"
                            id="region"
                            className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                            name="region"
                            placeholder="States"
                            component="select"
                          >
                            <option value="">{getStatesOption(countryCode)}</option>
                            {
                              states?.map((state, index) => {
                                return <option key={index} value={state?.state_code}>{state?.name}</option>
                              })
                            }
                          </Field>
                          <label htmlFor="region">{getStatesLabel(countryCode)}</label>
                          <ErrorMessage name="region" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-label-group">
                          <Field type="string"
                            id="postal_code"
                            name="postal_code"
                            className={`form-control ${touched.postal_code && errors.postal_code ? "is-invalid" : ""}`}
                            placeholder="Zip code"
                          />
                          <label htmlFor="postal_code">{getZipCodelabel(countryCode)}</label>
                          <ErrorMessage name="postal_code" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>

                    { entityDetail.entity_type === 'company' && <>
                      <h5 className="montserrat weight-700 mb-4">Formation Info</h5>
                      <div className="form-row">
                        <div className="col-sm-6">
                          <div className={`form-datepicker-group ${values?.date_of_formation ? 'date-selected' : ''}`}>
                            <DatePickerField name="date_of_formation" placeholder="Date of formation" initialValue={values?.date_of_formation} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-label-group">
                            <Field type="select"
                              id="country_of_formation"
                              className={`form-control ${touched.country_of_formation && errors.country_of_formation ? "is-invalid" : ""}`}
                              name="country_of_formation"
                              placeholder="country_of_formation"
                              component="select"
                              onChange={(e) => { handleChange(e); }}
                            >
                              <option value="">Country of formation</option>
                              <option value={countryCode}>
                                {Country.findByISO2Code(countryCode).name}
                              </option>
                            </Field>
                            <label htmlFor="country_of_formation">Select Country</label>
                            <ErrorMessage name="country_of_formation" component="div" className="invalid-feedback text-left" />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-label-group">
                            <Field type="select"
                              id="region_formed_in"
                              className={`form-control ${touched.region_formed_in && errors.region_formed_in ? "is-invalid" : ""}`}
                              name="region_formed_in"
                              placeholder="Region formed in"
                              component="select"
                            >
                              <option value="">{getStatesOption(countryCode)} formed in</option>
                              {
                                states?.map((state, index) => {
                                  return <option key={index} value={state.state_code}>{state.name}</option>
                                })
                              }
                            </Field>
                            <label htmlFor="region_formed_in">{getStatesLabel(countryCode)} formed in</label>
                            <ErrorMessage name="region_formed_in" component="div" className="invalid-feedback text-left" />
                          </div>
                        </div>
                      </div>
                      </>
                    }
                  </div>
                </div>
              </div>
              <label className={`custom-checkbox form-group d-flex flex-wrap position-relative mt-4 ${errors.terms ? 'is-invalid' : ''}`}>
                <input id="info-certify-checkbox" type="checkbox" name="sole_signatory_acceptance" className="mr-2" onChange={handleChange} checked={values.sole_signatory_acceptance} />
                <span className="custom-checkbox-check"></span>
                <span className="custom-checkbox-tagline">I am an authorized signatory for the entity</span>
                <div style={{ marginLeft: '32px' }} className="failed-error-message">{errors.sole_signatory_acceptance}</div>
              </label>
              <div className='portalized-modal-footer'>
                <FixedButtonBox isSubmitting={isSubmitting} buttonTitle={submitButtonText} className="py-0" />
              </div>
            </div>
            <FormikValidationsRollbarReporter for="EntityDetails" />
          </Form>
        )}
      </Formik>
    </div>
  </LoadingOverlay>
  )
}