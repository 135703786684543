import React from 'react';
import { FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import FollowButton from '../../../pages/components/FollowButton';
import { AuthContext } from '../../../contexts/authContext';
import "./CompanySocialLinks.scss";

export default function CompanySocialLinks({ company, following_company = false, theme = "dark", showSocialIcons=true }) {
  const { isAdmin } = React.useContext(AuthContext);

  const classWithTheme = `social-icon ${theme}`
  return(
    <div className='single-company-social-icons'>
      {company.twitter_url && showSocialIcons && <a className={classWithTheme} target="_blank" rel="noopener noreferrer" href={company.twitter_url}>{<FaTwitterSquare/>}</a> }
      {company.linkedin_url && showSocialIcons && <a className={classWithTheme} target="_blank" rel="noopener noreferrer" href={company.linkedin_url}>{<FaLinkedin/>}</a> }
      { !isAdmin() && <FollowButton following={following_company} theme={theme} company={company} /> }
    </div>
  )
}
