import React from "react";
import { useField, useFormikContext, ErrorMessage } from "formik";
import NumberFormat from "react-number-format";

const CurrencyInputField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);
  const onChange = props.onChange
  const showError = props.showError ?? true;
  return (
    <div className="mb-3">
      <div className="input-group" style={{flexWrap: 'nowrap'}}>
        <div className="input-group-prepend" style={{height: '48px'}}>
          <span className="input-group-text input-group-text-before">$</span>
        </div>
        <div className='form-label-group mb-0'>
          <NumberFormat
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            id={props.id}
            thousandSeparator={true}
            className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
            placeholder={props.placeholder}
            decimalScale={2}
            onValueChange={(valuesObj) => {
              const {floatValue} = valuesObj;
              if (typeof onChange === 'function') {
                onChange(floatValue)
              }
              setFieldTouched(field.name)
              setFieldValue(field.name, floatValue)
            }}
          />
          <label htmlFor={props.id}>{props.label}</label>
        </div>
        <div className="input-group-append" style={{height: '48px', border: 'none'}}>
          <span className="input-group-text input-group-text-after">USD</span>
        </div>
      </div>
      {showError && <ErrorMessage name={field.name} component="div" className="invalid-feedback text-left d-block" /> }
    </div>
  );
};

export default CurrencyInputField;