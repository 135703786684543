import React from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import FileSelect from "components/HookForm/FileSelect";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { uploadDocument } from "./queries";
import ToastMessage from "components/ToastMessage";
import CheckboxSwitchField from '../../../components/HookForm/CheckboxSwitchField';
import OfferDocumentsList from './OfferDocumentsList';

const VisibilityDefault = "private";

const DocumentSchema = Yup.object().shape({
  name: Yup.string().required("Document name is required."),
  attachment: Yup.mixed()
    .required("Document attachment is required"),
    // .test('fileFormat', 'Select PDF file only', (value) => {
    //   return value && ['application/pdf'].includes(value.type);
    // }),
  visibility: Yup.string()
    .oneOf(["private", "public"])
    .required("visibility type is required")
    .default(VisibilityDefault),
  downloadable: Yup.bool().default(false)
});

export default function Documents({offer}) {
  const queryClient = useQueryClient();
  const methods = useForm({
    resolver: yupResolver(DocumentSchema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      name: "",
      attachment: null,
      visibility: VisibilityDefault,
      downloadable: false
    },
  });

  const addDocumentMutation = useMutation(uploadDocument, {
    onSuccess: (values) => {
      methods.reset({
        name: "",
        attachment: null,
        visibility: VisibilityDefault,
        downloadable: false
      });
      queryClient.invalidateQueries(['offer', 'documents', offer.slug], {exact: true})
      ToastMessage.success('Uploaded document successfully.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  });

  const onSubmit= async (values) => {
    const fd = new FormData();
    fd.append('document[name]', values.name)
    fd.append('document[attachment]', values.attachment, values.attachment.name)
    fd.append('document[visibility]', values.visibility)
    fd.append('document[downloadable]', values.downloadable)
    addDocumentMutation.mutate({
      offer_id: offer.slug,
      values: fd
    })
  }

  return(
    <div id="dealForm">
      <div className="documents-content-box">
        <div className="mb-3"><label className="form-input-label fs-9">Offering Documents</label></div>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <TextField name="name" placeholder="Document Name" type="text" labelStyle="n1-zindex"/>
              <FileSelect
                name="attachment"
                placeholder="Upload Document"
                fileType="application/pdf"
                method="multipart"
              />
              <CheckboxSwitchField  name="visibility" placeholder="Public" checkedValue="public" uncheckedValue="private" />
              <CheckboxSwitchField name="downloadable" placeholder="Downloadable" />
              <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={addDocumentMutation.isLoading}>
                Upload Document
              </button>
            </form>
          </FormProvider>
          <OfferDocumentsList offerId={offer.slug} />
        </div>
    </div>
  );
}

