import React, { useEffect } from "react";
import { useField, useFormikContext, ErrorMessage } from "formik";
import { getTimeZone } from "../../jsUtils/dateTimeUtils";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./DateTimeField.scss";
const DateTimeField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched, handleBlur, validateField } =
    useFormikContext();
  const [field] = useField(props);
  const [value, setValue] = React.useState(props?.initialValue ? new Date(props.initialValue) : "");
  const userTimezone = React.useMemo(() => {
    return getTimeZone();
  }, []);

  useEffect(() => {
    validateField(field.name);
  }, [field.value, field.name, validateField]);
  return (
    <>
      <DatePicker
        id={field.name}
        name={field.name}
        selected={value}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isClearable={true}
        clearButtonClassName="clearable-btn"
        shouldCloseOnSelect={true}
        className={`date-picker form-control ${
          props.isInValid ? "is-invalid" : ""
        }`}
        onSelect={(event) => {
          setFieldValue(field.name, event);
          setValue(event);
        }}
        onBlur={handleBlur}
        onChange={(date) => {
          let value;
          if (date) value = date;
          else value = "";
          setFieldValue(field.name, value);
          setValue(value);
          setFieldTouched(field.name, true);
        }}
      />
      <label htmlFor={field.name}>Close date *</label>
      <ErrorMessage
        name={field.name}
        component="div"
        className="invalid-feedback text-left date-picker-error"
      />
      <p className="fs-11" style={{ color: "#777" }}>
        Your Timezone: {userTimezone}
      </p>
    </>
  );
};

export default DateTimeField;
