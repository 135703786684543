import React from "react";

import { formatDate } from "../../../jsUtils/dateTimeUtils";
import OverviewSubheader from "../OverviewSubheader";
import { ResponsiveTable } from "admin/components";
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from "../../../components/AmountWithCurrency";

const InvestmentAuditHistory = ({ audits }) => {
  if(!audits) {
    audits = [];
  }

  //ignore first (initial) record
  const amountAuditRecords = audits.filter(a => a.audited_changes.hasOwnProperty('amount')).slice(1,)

  const columns = [
    {
      Header: "Edit Date",
      accessor: "created_at",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: <>Prev. Amt.&nbsp;<CurrencyBadge /></>,
      accessor: "audited_changes.amount[0]",
      Cell: ({ value }) => <FormattedNumberWithSymbol number={value} />,
    },
    {
      Header: <>New. Amt.&nbsp;<CurrencyBadge /></>,
      accessor: "audited_changes.amount[1]",
      Cell: ({ value }) => <FormattedNumberWithSymbol number={value} />,
    },
  ];

  return (
    <>
      <OverviewSubheader left="Edit History" />
      <ResponsiveTable
        columns={columns}
        data={amountAuditRecords}
        loading={false}
        loadingMsg="Loading company followers..."
        noDataMsg="No edit history found."
      />
    </>
  );
};

export default InvestmentAuditHistory;
