import React, { useState, useEffect } from 'react';
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewHeader from 'admin/components/OverviewHeader'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import TextButton from 'admin/components/TextButton'
import EditorModal from 'admin/components/EditorModal'
import PreOfferOverviewCreator from 'admin/pages/preoffer/OverviewCreator';
import RaisePetitionCreator from 'admin/pages/raise_petition/OverviewCreator';
import EditHeader from 'admin/components/EditHeader'
import OfferCreator from 'admin/components/OfferCreator'
import ButtonDark from 'admin/components/ButtonDark'
import { OfferListingTable } from './../pages/Page.OfferList'
import { FaPlus, FaExternalLinkAlt } from 'react-icons/fa'
import IssuerProfileEditor from './IssuerProfileEditor';
import { formatTaxID, toTitlecase } from '../../jsUtils/stringUtils';
import Affiliations from './Affiliations';
import TeamMembers from './TeamMembers';
import CompanyAccountsList from "./CompanyAccountsList";
import CompanyAccountInvitesList from "./CompanyAccountInvitesList";
import { formatDate } from '../../jsUtils/dateTimeUtils';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import CompanyFollowers from './CompanyFollower';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import useGeoInfo from '../../lib/useGeoInfo';
import { useCompany, useOffersListForCompany } from '../pages/company/queries';
import { history } from 'redux/helpers'

export default function CompanyOverview() {
  let { companySlug } = useParams();
  let { path, url } = useRouteMatch();
  const { State } = useGeoInfo();
  const preofferModal = React.useContext(PortalizedModalContext);
  const raisePetitionModal = React.useContext(PortalizedModalContext);
  const {data:company, isLoading:isLoadingCompany } = useCompany(companySlug)
  const {data: offers, isLoading: isLoadingOffers} = useOffersListForCompany(companySlug);
  const [createOffer, setCreateOffer] = useState(false);
  const [editIssuerProfile, setIssuerProfileEdit] = useState(false);

  const addOffer = () => {
    setCreateOffer(true)
  }

  if(isLoadingCompany)
    return 'Loading...'

  const issuerDetails = company?.issuer_detail;

  return (
    <>
      {
        createOffer &&
          <EditorModal onRequestClose={ () => setCreateOffer(false) }>
            <OfferCreator company={company} />
          </EditorModal>
      }
      {
        editIssuerProfile &&
        <EditorModal onRequestClose={() => setIssuerProfileEdit(false)}>
          <EditHeader left={company?.issuer_detail ? `Edit Issuer Profile` : 'Create Issuer Profile'} />
          <IssuerProfileEditor companySlug={company?.slug} issuerDetails={company?.issuer_detail} />
        </EditorModal>
      }
      <OverviewHeader
        left={company.name}
        right={
          <>
            <NavLink
              className={"btn-black rounded-0 px-3 py-2 fs-9 company-overview-btn d-flex align-items-center"} to={`${path}/${companySlug}`}
            >
              <FaExternalLinkAlt className="mr-1" /> View
            </NavLink>
            <div>&nbsp;&nbsp;</div>
            <ButtonDark title='Petition' iconName={<FaPlus />} onClick={() => {
              raisePetitionModal.setModalContent(
                'Create Raise Petition',
                <RaisePetitionCreator companyId={company.id} onCreateSuccess={raisePetitionModal.closeModal} />,
                'sm'
              );
              raisePetitionModal.openModal();
            }} />
            <div>&nbsp;&nbsp;</div>
            <ButtonDark title='Prelaunch' iconName={<FaPlus />} onClick={() => {
              preofferModal.setModalContent(
                'Create Prelaunch',
                <PreOfferOverviewCreator companySlug={companySlug} onCreateSuccess={preofferModal.closeModal} />,
                'sm'
              );
              preofferModal.openModal();
            }} />
            <div>&nbsp;&nbsp;</div>
            <ButtonDark title='Offer' iconName={<FaPlus />} onClick={addOffer} />
          </>
        }
      />
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#company-overview" role="tab">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#company-offers" role="tab">Offers</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#company-accounts" role="tab">Accounts</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#company-account-invites" role="tab">Accounts Invites</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#company-followers" role="tab">Followers</a>
          </li>
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="company-overview" role="tabpanel">
            <OverviewSubheader
              left='Company Info'
              right={
                <TextButton title='Edit' onClick={ () => history.push(`${url}/edit`) } />
              } 
            />
            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Company name' value={toTitlecase(company.name)} />
                  <AttributeValue name='Legal name' value={toTitlecase(company.legal_name)} />
                  <AttributeValue name='Website' value={company.url} isExternalLink={true} />
                  <AttributeValue name='Company type' value={company.private ? 'Private' : 'Public'} />
                  <AttributeValue name='Sector' value={[company.parent_sector_name, company.sector_name].filter(Boolean).join(' - ')} />
                  <AttributeValue name='Last funding stage' value={company.last_funding_type} />
                </>
              }
              right={
                <>
                  <AttributeValue name='City' value={toTitlecase(company.city)} />
                  <AttributeValue name='Country' value={company.country} />
                </>
              }
            />

            <OverviewSubheader left='Issuer Profile' right={<TextButton title='Edit' onClick={() => setIssuerProfileEdit(true)} />} />
            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Company legal name' value={toTitlecase(issuerDetails?.name)} />
                  <AttributeValue name='Company email' value={issuerDetails?.email} />
                  <AttributeValue name='Company phone number' value={issuerDetails?.phone} />
                  <AttributeValue name='State formed in' value={issuerDetails?.region_formed_in ? State.findByStateCodeAndISO2CountryCode(issuerDetails?.region_formed_in, "US").name : null} />
                  <AttributeValue name='Tax ID number' value={issuerDetails?.tax_id_number ? formatTaxID(issuerDetails.tax_id_number) : "-"} />
                  <AttributeValue name='Contact name' value={toTitlecase(issuerDetails?.contact_name)} />
                </>
              }
              right={
                <>
                  <AttributeValue name='Address' value={toTitlecase(issuerDetails?.street_address_1)} />
                  <AttributeValue name='City' value={toTitlecase(issuerDetails?.city)} />
                  <AttributeValue name='State' value={issuerDetails?.region ? State.findByStateCodeAndISO2CountryCode(issuerDetails?.region, "US").name : null} />
                  <AttributeValue name='Country' value={issuerDetails?.country || "US"} />
                  <AttributeValue name='Zip code' value={issuerDetails?.postal_code} />
                </>
              }
            />

            <OverviewSubheader left='Company Content' />
            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Created on' value={formatDate(company.created_at)} />
                </>
              }
            />
            <TeamMembers companyId={companySlug}/>
            <Affiliations companyId={companySlug}/>
          </div>
          <div className="tab-pane fade show mx-n3" id="company-offers" role="tabpanel">
            <OfferListingTable offers={offers} showDetails={false} loadingState={isLoadingOffers} />
          </div>
          <div className="tab-pane fade show" id="company-accounts" role="tabpanel">
            <CompanyAccountsList companyId={companySlug}/>
          </div>
          <div className="tab-pane fade show" id="company-account-invites" role="tabpanel">
            <CompanyAccountInvitesList companyId={companySlug}/>
          </div>
          <div className="tab-pane fade show" id="company-followers" role="tabpanel">
            <CompanyFollowers.List companyId={companySlug} showDetails={false} />
          </div>
        </div>
      </div>
    </>
  )
}
