// { iso2CountryCode: [StateCodes....]}
const COUNTRIES_FOR_INTERNATION_OFFERS = [
  "US", 'GB', 'CA'
];

// { iso2CountryCode: sortRank}
const COUNTRIES_SORTING_ORDER = {
  "US": 11,
  "GB": 10,
  "CA": 9,
  "AU": 8,
  "DE": 7,
  "ES": 6,
  "BE": 5,
  "BR": 4,
  "IN": 3,
  "IT": 2,
  "AD": 1,
};

// Do not show up in states listing for country
// { iso2CountryCode: [StateCodes....]}
const BANNED_STATES = {
  US: [
    "AS",
    "UM-81",
    "DC",
    "GU",
    "UM-84",
    "UM-86",
    "UM-67",
    "UM-89",
    "UM-71",
    "UM-76",
    "MP",
    "UM-95",
    "PR",
    "UM",
    "VI",
    "UM-79",
  ],
};

function bannedStatesForCountry(iso2Code) {
  return BANNED_STATES[iso2Code.toUpperCase()] ?? [];
};

export {
  COUNTRIES_FOR_INTERNATION_OFFERS,
  COUNTRIES_SORTING_ORDER,
  BANNED_STATES,
  bannedStatesForCountry,
};
