import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, useParams } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import { format, parseJSON } from 'date-fns';
import ErrorBoundary from 'components/ErrrorBoundary';
import Editor from './Editor';
import {
  EditorModal,
  OverviewHeader,
  AttributeValue,
  TwoColumnLayout,
  OverviewSubheader,
  ButtonDark,
  TextButton,
  ResponsiveTable,
  Paginator,
  TableActionsContainer,
  TableActionItem,
} from 'admin/components';
import ToastMessage from 'components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { FaTrash } from 'react-icons/fa';
import StatusIndicator from './StatusIndicator';
import { toTitlecase } from 'jsUtils/stringUtils';
import {
  usePreoffer,
  usePreofferSubscriberList,
  deletePreofferSubscription,
  useExportPreofferSubscriberList
} from './queries';
import usePagination from 'hooks/usePagination';
import { history } from 'redux/helpers';
import { queryClient } from 'index';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import AmountWithCurrency, {
  AmountRange,
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../components/AmountWithCurrency';
import NDADocuments from './NDADocuments';

function Overview() {
  const { authData } = React.useContext(AuthContext);
  let { path, url } = useRouteMatch();
  let { preofferSlug } = useParams()
  const {data:preoffer, isLoading } = usePreoffer(preofferSlug)
  const canEditOffer = authData.permittedActions.has(ACTIONS.WRITE_UPDATE_OFFER)

  const openEditorModal = () => {
    history.push(`${url}/edit`)
  }

  const closeEditorModal = () => {
    history.push(`${url}`)
  }

  if (isLoading)
    return 'Loading...'

  return (
    <ErrorBoundary>
      <OverviewHeader
        left={toTitlecase(preoffer.name)}
        right={<StatusIndicator preoffer={preoffer} theme='dark' />}
      />
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#preoffer-overview" role="tab">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#preoffer-subscribers" role="tab">Subscribers</a>
          </li>
          { preoffer.nda &&
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#preoffer-ndas" role="tab">NDA</a>
            </li>
          }
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="preoffer-overview" role="tabpanel">
            <OverviewSubheader left='Offer Overview' right={canEditOffer ? <TextButton title='Edit' onClick={openEditorModal} /> : null } />
            <TwoColumnLayout
              left={<AttributeValue name='Offer name' value={toTitlecase(preoffer.name)} />}
              right={<AttributeValue name='Company name' value={toTitlecase(preoffer.company.name)} />}
            />
            <TwoColumnLayout
              left={<AttributeValue name='Type' value={preoffer.type} />}
              right={<AttributeValue name='Listed' value={preoffer.listed_at ? 'Yes' : 'No'} />}
            />

            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Offer goal' value={<AmountWithCurrency number={preoffer.goal_amount} />} />
                </>
              }
              right={
                <>
                  <AttributeValue name='Valuation' value={<AmountWithCurrency number={preoffer.valuation} />} />
                </>
              }
            />

            <OverviewSubheader left='Performance' />
            <div className="two-col-layout">
              <div className="first-col p-3">
                <AttributeValue
                  name={
                    <>
                      <span>Raised:</span>
                      <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                        <AmountRange
                          start={preoffer.committed}
                          end={preoffer.goal_amount}
                          separator="/"
                        />
                      </span>
                    </>
                  }
                  value={
                    <div style={{ width: '300px', backgroundColor: '#fff', flexDirection: 'row'}}>
                      <div style={{width: `${Math.min(Math.max(preoffer.committed*100 / preoffer.goal_amount, .5), 100) || 0}%`, height: '18px', backgroundColor: '#5AB4AD'}} />
                    </div>
                  }
                />
              </div>
              <div className="second-col p-3">
                <AttributeValue name='Number of subscribers' value={preoffer.preoffer_subscribers_count} />
              </div>
            </div>

          </div>
          <div className="tab-pane fade" id="preoffer-subscribers" role="tabpanel">
             <Subscribers preofferSlug={preoffer.slug} />
          </div>
          <div className="tab-pane fade" id="preoffer-ndas" role="tabpanel">
            <NDADocuments preofferId={preoffer.slug} />
          </div>

        </div>
      </div>
      <Switch>
        <Route path={`${path}/edit`} component={(props) => (
            <EditorModal onRequestClose={closeEditorModal}>
              <Editor />
            </EditorModal>
          )}
        />
      </Switch>
    </ErrorBoundary>
  )
}

function Subscribers({preofferSlug}) {
  const { authData } = React.useContext(AuthContext);
  const queryClient = useQueryClient();
  const deleteConfirmModal = React.useContext(PortalizedModalContext);
  const canDownloadSubscribersCSV = authData.permittedActions.has(ACTIONS.READ_DOWNLOAD_PREOFFER_SUBSCRIBERS)
  const pageConfig = usePagination();
  const subscribers = usePreofferSubscriberList(preofferSlug, pageConfig.current)

  const _exportPreofferSubscriberList = useExportPreofferSubscriberList(preofferSlug)

  const deleteInviteMutation = useMutation(deletePreofferSubscription, {
    onSuccess: () => {
      subscribers.refetch();
      queryClient.invalidateQueries(['preoffer', preofferSlug], { exact: false })
      ToastMessage.success(`Subscriber deleted successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      // deleteConfirmModal.closeModal();
    }
  });

  const handleDelete = (subscriber) => () => {
    // deleteConfirmModal.setModalContent(
    //   "Delete Invitation?",
    //   <>
    //     <p>{`Are you sure you want to delete subscriber - ${subscriber.name}?`}</p>
    //     <div className="portalized-modal-footer">
    //       <button className="btn-black"
    //         onClick={() => {
    //           deleteInviteMutation.mutate({preoffer_id: preofferSlug , subscription_id: subscriber.id})
    //         }}>Confirm</button>
    //       <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
    //     </div>
    //   </>,
    //   'sm'
    // );
    // deleteConfirmModal.openModal();
  }

  const exportSubscribers = async () => {
    queryClient.refetchQueries(
      ['preoffer', preofferSlug, 'exportsubscriberlist'],
      {
        enabled: true,
      }
    );
  }

  const tableData = React.useMemo(
    () => {
      if (subscribers.isSuccess)
        return subscribers.data.data
      else
        return []
    },
    [subscribers]
  );

  const columns = React.useMemo(
    () => {
      if (subscribers.isSuccess){
        return [
          {
            Header: 'Subscriber name',
            accessor: 'name'
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: <>Amount&nbsp;<CurrencyBadge /></>,
            accessor: 'amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: 'Country',
            accessor: 'country',
            Cell: ({value, row}) => {
              const showCountry = value ? value : row.original.client.country;
              return  showCountry ? showCountry : "-";
            }
          },
          {
            Header: 'Subscribed At',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: 'Doc Status',
            accessor: 'esign_request',
            Cell: ({value}) => {
              return value ? value.status : "NA"}
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({row, value}) => (
              <TableActionsContainer>
                <TableActionItem
                  tooltipText="Delete"
                  // onClick={handleDelete(row.original)}
                  onClick={() => {
                    if (window.confirm(`Are you sure want to delete subscriber - ${row.original.name}?`)) {
                      deleteInviteMutation.mutate({preoffer_id: preofferSlug , subscription_id: row.original.id})
                    }
                  }}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            )
          }
        ]
      }
      else{
        return []
      }
    },
    [subscribers]
  );

  return (
    <ErrorBoundary>
      <OverviewSubheader
        left='Subscribers'
        right={
          canDownloadSubscribersCSV ?
            <ButtonDark title='Export All' onClick={exportSubscribers}/> : null
        }
      />
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={subscribers?.data?.pagy}
        loading={subscribers.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={subscribers.isLoading}
        loadingMsg="Loading subscribers..."
        noDataMsg="No subscribers found."
      />
    </ErrorBoundary>
  );
}
Overview.defaultProps = {};

Overview.propTypes = {};

export default Overview;