import React from 'react'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import { FaTrophy } from "react-icons/fa";
import { pitchModelCommissionIcon,
  pitchModelFreemiumIcon,
  pitchModelHybridIcon,
  pitchModelServiceIcon,
  pitchModelSubscriptionIcon,
  pitchModelTransactionalIcon,
  highlightMonetaryIcon,
  highlightTractionIcon,
  highlightTeamIcon,
  highlightTechnologyIcon,
  highlightAwardIcon} from '../assets/images'
import { OFFER_TYPE } from '../config/constants';
export function progressBarPercentage(fundsPercentage) {
  if (fundsPercentage && fundsPercentage < 100) {
    return fundsPercentage
  } else if (fundsPercentage >= 100) {
    return '100'
  } else {
    return '0'
  }
}

export function raisedFundsPercentage(raisedAmount,goal) {
  if(goal === 0) return 0;
  const percentage = (raisedAmount/goal) * 100;
  return parseInt(percentage);
}

export const marketSize = (size) => {
  if(size) {
    if (/^\d+$/.test(size))
      return formatMoney(size);
    else
      return size;
  } else {
    return "";
  }
}

export const calculatePricePerShare = (offer, investmentAmount = 0) => {
  const pps = (investmentAmount / offer?.deal?.price_per_share).toFixed(2);
    return pps;
  }

export const getIcon = (type) => {
  switch(type){
    case 'monetary':
      return highlightMonetaryIcon
    case 'award':
      return highlightAwardIcon ;
    case 'technology':
      return highlightTechnologyIcon
    case 'team':
      return highlightTeamIcon
    case 'traction':
      return highlightTractionIcon
    default:
      return null;
  }
}

export const getBusinessModelIcon = (name) => {
  switch(name){
    case 'Subscription/recurring payment':
      return pitchModelSubscriptionIcon
    case 'Transactional':
      return pitchModelTransactionalIcon
    case 'Service':
      return pitchModelServiceIcon
    case 'Hybrid Pricing':
      return pitchModelHybridIcon
    case 'Freemium':
      return pitchModelFreemiumIcon
    case 'Commission':
      return pitchModelCommissionIcon
    default:
      return <h6>Icon</h6>;
  }
}

export const paymentTypeText = (paymentMehod) => {
  switch(paymentMehod) {
    case "ach":
      return "ACH"
    case "credit_card":
      return "Credit Card"
    case "wire":
     return "Wire"
    default:
     return "-"
  }
}

export function investmentLimitByInvestorType(offer){
  const limits = [];
  const deal = offer.deal;

  if (offer.investor_type === OFFER_TYPE.investor_direct) {
    limits.push({
      type: 'direct',
      remainingSpots: deal.maximum_direct_investors_count - offer.direct_investor_count,
      minAmount: deal.minimum_direct_investment
    })
  } else if (offer.investor_type === OFFER_TYPE.investor_spv) {
    limits.push({
      type: 'spv',
      remainingSpots: offer.maximum_total_investors_count - offer.spv_investor_count,
      minAmount: deal.minimum_investment
    })
  } else if (offer.investor_type === OFFER_TYPE.investor_direct_and_spv) {
    limits.push({
      type: 'spv',
      remainingSpots: offer.maximum_total_investors_count - (offer.spv_investor_count + deal.maximum_direct_investors_count),
      minAmount: deal.minimum_investment
    })
    limits.push({
      type: 'direct',
      remainingSpots: deal.maximum_direct_investors_count - offer.direct_investor_count,
      minAmount: deal.minimum_direct_investment
    })
  }

  return limits;
}

export function hasSPVInvestors(offer){
  return [OFFER_TYPE.investor_spv, OFFER_TYPE.investor_direct_and_spv].indexOf(offer.investor_type) > -1;
}

export function hasDirectInvestors(offer) {
 return [OFFER_TYPE.investor_direct, OFFER_TYPE.investor_direct_and_spv].indexOf(offer.investor_type) > -1;
}
