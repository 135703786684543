import React from "react";
import OfferCardBanner from "./OfferCardBanner";
import OfferCardCompanyDescription from "./OfferCardCompanyDescription";
import OfferCardSummary from "./OfferCardSummary";

const OfferCard = function ({ offer, onClick }) {
  if(!offer) return null;
  const company = offer.company;
  return (
    <a
      className="unstyled"
      href={`/offers/${offer.slug}/pitch`}
      onClick={onClick}
    >
      <div className="offer-card">
        <OfferCardBanner offer={offer} type="offer"/>
        <div className="offer-card-text-container offer-text-padding">
          <OfferCardCompanyDescription company={company}/>
          <OfferCardSummary offer={offer} />
        </div>
      </div>
    </a>
  );
};

export default OfferCard;
