import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import Icon from "../../admin/components/Icon";
import ErrorMessage from "./ErrorMessage";
import { crossIcon } from '../../assets/images';
import './FileSelect.scss'

const FileSelect = ({ name, placeholder, method="base64", classes = "", fileType = "" }) => {
  const fileReader = new FileReader();
  const { control } = useFormContext();
  const [fileName, setFileName] = useState(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
      <div className={`mb-3 ${classes}`}>
        <div className={`custom-file-uploader`}>
          {(field?.value?.data || field?.value?.name) &&
            <span
              onClick={() => {
                field.onChange(null);
                setFileName(null);
              }}
              className='close-icon'
            >
              <img src={crossIcon} alt="X"/>
            </span>
          }
          <label
            className={`${invalid ? "is-invalid" : ""}`}
            htmlFor={field.name}
          >
            <div
              className="image-layer"
              style={{
                backgroundImage: `url( ${field?.value?.data})`,
              }}
            >
              <div className="upload-btn">
                <Icon name="fileUpload" />
                <div>{placeholder}</div>
                {<span className="fs-10">{fileName || field.value?.name}</span> }
              </div>
            </div>
          </label>
          <input
            id={field.name}
            type="file"
            name={field.name}
            ref={field.ref}
            onBlur={field.onBlur}
            className={`d-none ${ invalid ? "is-invalid" : ""}`}
            placeholder={placeholder}
            // for multiple types acceptance use following
            // multiple accept="image/*,audio/*,video/*"
            accept={fileType}
            onChange={(event) => {
              const file = event.currentTarget.files[0];
              if (!file) return;

              if (method == "base64"){
                setFileName(file.name);
                fileReader.readAsDataURL(file);
                fileReader.onload = function () {
                  const data = { data: fileReader.result, fileName: file.name };
                  field.onChange(data);
                };
                fileReader.onerror = function (error) {
                  console.log("image file Error: ", error);
                };
              }
              else {
                field.onChange(file);
              }
              event.target.value = null;
            }}
          />
        </div>
        <ErrorMessage error={error} />
      </div>
      )}
    />
  );
};

FileSelect.defaultProps = {};

FileSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.string,
  fileType: PropTypes.string,
};

export default FileSelect;
