import React from 'react';
import { useRouteMatch, useParams } from "react-router";
import {
  VerticalRoutedTabs,
} from 'admin/components'
import { usePreofferPitch, updatePitch } from './queries';
import {
  MainVideoForm,
  ProblemForm,
  SolutionForm,
  ProductForm,
  TractionForm,
  CustomerForm,
  BusinessModelForm,
  MarketForm,
  CompetitionForm,
  VisionForm,
  TeamForm,
  UseOfFundForm,
  RiskAndDisclosureForm,
  HighlightsForm,
} from './pitch/sections';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';

function PitchEditor() {
  let { path, url } = useRouteMatch();
  const { authData } = React.useContext(AuthContext);
  let { preofferSlug } = useParams()
  const {data:pitch={}, isLoading} = usePreofferPitch(preofferSlug)

  const pitchSections = React.useMemo(
    () => {
      return [
        { tabId: 'video', title: 'Main Video', targetId: 'video-panel', pathName: 'main-video',
          decoratorClass: pitch.main_video_section?.id ? 'complete' : '',
          content: <MainVideoForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'highlights', title: 'Highlights', targetId: 'highlights-panel', pathName: 'highlights',
          decoratorClass: pitch.highlights?.length > 0 ? 'complete' : '',
          content: <HighlightsForm pitch={pitch} />
        },
        { tabId: 'problem', title: 'Problem', targetId: 'problem-panel', pathName: 'problem',
          decoratorClass: pitch.problem_section?.id ? 'complete' : '',
          content: <ProblemForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'solution', title: 'Solution', targetId: 'solution-panel', pathName: 'solution',
          decoratorClass: pitch.solution_section?.id ? 'complete' : '',
          content: <SolutionForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'product', title: 'Product', targetId: 'product-panel', pathName: 'product',
          decoratorClass: pitch.product_section?.id ? 'complete' : '',
          content: <ProductForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'traction', title: 'Traction', targetId: 'traction-panel', pathName: 'traction',
          decoratorClass: pitch.traction_section?.id ? 'complete' : '',
          content: <TractionForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'customers', title: 'Customers', targetId: 'customers-panel', pathName: 'customers',
          decoratorClass: pitch.customers_section?.id ? 'complete' : '',
          content: <CustomerForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'businessmodel', title: 'Business Model', targetId: 'businessmodel-panel', pathName: 'business-model',
          decoratorClass: pitch.business_model_section?.id ? 'complete' : '',
          content: <BusinessModelForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'market', title: 'Market', targetId: 'market-panel', pathName: 'market',
          decoratorClass: pitch.market_section?.id ? 'complete' : '',
          content: <MarketForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'competition', title: 'Competition', targetId: 'competition-panel', pathName: 'competition',
          decoratorClass: pitch.competition_section?.id ? 'complete' : '',
          content: <CompetitionForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'vision', title: 'Vision', targetId: 'vision-panel', pathName: 'vision',
          decoratorClass: pitch.vision_section?.id ? 'complete' : '',
          content: <VisionForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'team', title: 'Team', targetId: 'team-panel', pathName: 'team',
          decoratorClass: pitch.team_section?.id ? 'complete' : '',
          content: <TeamForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'useoffunds', title: 'Use of Funds', targetId: 'useoffunds-panel', pathName: 'use-of-funds',
          decoratorClass: pitch.use_of_funds_section?.id ? 'complete' : '',
          content: <UseOfFundForm pitch={pitch} updateMethod={updatePitch} />
        },
        { tabId: 'risksanddisclosures', title: 'Risks and Disclosures', targetId: 'risksanddisclosures-panel', pathName: 'risks-and-disclosures',
          decoratorClass: pitch.risks_and_disclosures_section?.id ? 'complete' : '',
          content: <RiskAndDisclosureForm pitch={pitch} updateMethod={updatePitch} />
        },
      ]
    },
    [pitch, updatePitch]
  );

  if (isLoading)
    return 'Loading...'

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_PITCH)) {
    return <div>
      You are not authorised to edit Pitch.
    </div>
  }

  return (
    <VerticalRoutedTabs 
      basePath={path}
      baseUrl={url}
      tabs={pitchSections} 
      tabIdAccessor={'tabId'}
      targetPanelIdAccessor={'targetId'}
      defaultTabId="video"
    />
  )
}

export default PitchEditor;
