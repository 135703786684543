import React, {useEffect, useState} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { usePusherChannel } from '../../contexts/PusherChannelContext';
import MessageList from './MessageList';
import LoginLink from './LoginLink';
import SendMessageForm from './SendMessageForm';
import ChatSessionClosed from './ChatSessionClosed';

export default function ChatBox({ liveEvent, user, moderation, setDataChangedAt, company, getChatSession }){
  const pusherChannel = usePusherChannel(liveEvent.channel_id);
  const queryClient = useQueryClient();
  const chatSessionDataKey = [liveEvent.channel_id, 'chatSession'];
  const { isLoading, error, data: chatSession } = useQuery(chatSessionDataKey, () => {
    return getChatSession(liveEvent.slug || liveEvent.id)
  });
  const [status, setStatus] = useState(liveEvent.status);

  const incomingMessageHandler = (message) => {
    const currentChatSession = queryClient.getQueryData(chatSessionDataKey);
    const index = currentChatSession.messages.findIndex(m => m.id == message.id);

    if(index > -1){
      currentChatSession.messages[index] = message
    } else {
      currentChatSession.messages.push(message);
    }

    queryClient.setQueryData(chatSessionDataKey, currentChatSession);
  }

  const suspendParticipantHandler = (chatParticipant) => {
    const bannedParticipants = queryClient.getQueryData([liveEvent.channel_id, 'bannedParticipants']);

    if(chatParticipant.status === 'suspended'){
      bannedParticipants.push(chatParticipant);
      queryClient.setQueryData([liveEvent.channel_id, 'bannedParticipants'], bannedParticipants)
    } else {
      queryClient.setQueryData([liveEvent.channel_id, 'bannedParticipants'], bannedParticipants.filter(v => v.id != chatParticipant.id));
    }

    const currentChatSession = queryClient.getQueryData(chatSessionDataKey);

    currentChatSession.messages.forEach((m) => {
      if(m.chat_participant?.id === chatParticipant.id){
        m.chat_participant.status = chatParticipant.status;
      }
    })
    queryClient.setQueryData(chatSessionDataKey, currentChatSession);

    setDataChangedAt(new Date());
  }

  const handleParticipantUpdate = (chatParticipant) => {
    const currentChatSession = queryClient.getQueryData(chatSessionDataKey);

    currentChatSession.messages.forEach((m) => {
      if(m.chat_participant?.id === chatParticipant.id){
        m.chat_participant = chatParticipant;
      }
    })

    queryClient.setQueryData(chatSessionDataKey, currentChatSession);
  }

  useEffect(() => {
    pusherChannel.bind('chat', incomingMessageHandler);
    pusherChannel.bind('status-change', (data) => setStatus(data.status));
    pusherChannel.bind('participant-update', (data) => handleParticipantUpdate(data) );

    if(moderation){
      pusherChannel.bind('suspend-participant', (data) => suspendParticipantHandler(data));
    }

    return () => {
      pusherChannel.unbind('chat', () => {});
    }
  }, [liveEvent]);

  useEffect(() => { setStatus(liveEvent.status) }, [liveEvent]);

  if(isLoading){
    return <div className='text-center color-white montserrat weight-500 fs-8 mt-4'>Loading Messages...</div>
  }

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  let actionBox;

  if(status !== 'live'){
    actionBox = <ChatSessionClosed status={status}/>;
  } else if(user?.id) {
    actionBox = <SendMessageForm chatSession={chatSession} moderation={moderation} company={company}/>;
  } else {
    actionBox = <LoginLink/>;
  }

  return(
    <div className="chat-box">
      <div className={`chat-box-scroller ${chatSession.messages.length < 3 ? 'overflow-visible' : ''}` }>
        <MessageList chatSession={chatSession} moderation={moderation} user={user}/>
      </div>
      <div className="send-message col-12 pt-2 mb-0">
        { actionBox }
      </div>
    </div>
  )
}
