import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from "../../components/ToastMessage";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import TextField from "components/HookForm/TextInput";
import SelectField from "components/HookForm/SelectInput";
import {
  createUserInvite
} from "../queries/index";

const ROLE_CATEGORIES = [
  {value: "issuer", label: "Issuer"},
  {value: "fund_manager", label: "Fund Manager"}
]

const ISSUER_ROLE_NAMES = [
  "Manager",
  "Content Editor",
  "Communications",
];

const FUND_MANAGER_ROLE_NAMES = [
  "Fund Manager",
  "Fund Organizer"
]

const CompanyAccountInviteSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  entity_id: Yup.string().required(),
  role_category: Yup.string().required(),
  role_name: Yup.string()
    .when("role_category", {
      is: (role_category) => role_category === 'issuer',
      then: (schema) => schema.oneOf(ISSUER_ROLE_NAMES)
    })
    .when("role_category", {
      is: (role_category) => role_category === 'fund_manager',
      then: (schema) => schema.oneOf(FUND_MANAGER_ROLE_NAMES)
    })
    .required(),
});

function CompanyAccountInviteForm({companyId, onInviteSuccess}) {
  const queryClient = useQueryClient();

  const createUserInviteMutation = useMutation(
    createUserInvite,
    {
      onSuccess: (values) => {
        methods.reset()
        queryClient.invalidateQueries(
          ['companyAccountInvitesList', values.entity_slug],
          {
            exact: false,
          }
        )
        if (typeof onInviteSuccess === 'function')
          onInviteSuccess(values)
        ToastMessage.success(`Account invite sent.`)
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const methods = useForm({
    resolver: yupResolver(CompanyAccountInviteSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      email: '',
      entity_id: companyId,
      role_category: "issuer",
      role_name: "",
    },
  });

  const roleCategory = methods.watch("role_category")

  React.useEffect(() => {
    methods.setValue("role_name", "")
    methods.clearErrors("role_name")
  }, [methods, roleCategory])

  const ROLE_OPTIONS = React.useMemo(() => {
    if(roleCategory == "issuer")
      return ISSUER_ROLE_NAMES
    else if (roleCategory == "fund_manager")
      return FUND_MANAGER_ROLE_NAMES
    else
      return []
  }, [roleCategory])

  const onSubmit = (values) => {
    createUserInviteMutation.mutate({
      values
    })
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <TextField
                name="email"
                placeholder="Email*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SelectField
                name="role_category"
                placeholder="Select Category*"
                options={ROLE_CATEGORIES}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SelectField
                name="role_name"
                placeholder="Select Role*"
                options={ROLE_OPTIONS}
              />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="btn-black"
              disabled={methods.isSubmitting}
            >
              Send Invite
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default CompanyAccountInviteForm;
