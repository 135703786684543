import React from 'react';
import './AttributeValue.scss';
import { formatWebsiteUrl } from '../../jsUtils/stringUtils'

export default function AttributeValue({name, value, isExternalLink=false}) {
  return(
    <div className="attr fs-10">
      <span className="attr-name">{name}</span>
      {isExternalLink
        ? <span className="attr-value cursor-pointer" onClick={() => window.open(value)}>{formatWebsiteUrl(value) || '-'}</span>
        : <span className="attr-value">{typeof value === 'number' ? value : value || '-'}</span>
    }
    </div>
  );
}
