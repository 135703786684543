import React from "react";
import {
  ResponsiveTable,
  TableActionsContainer,
  TableActionItem,
  Paginator,
} from "admin/components";
import { formatDate } from 'jsUtils/dateTimeUtils';
import { useCompanyAccountInvitesList, deleteUserInvite } from "../queries/index";
import { useMutation } from "react-query";
import ToastMessage from 'components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import usePagination from "hooks/usePagination";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { FaTrash } from 'react-icons/fa';

function CompanyAccountInvitesList({companyId}) {
  const pageConfig = usePagination();
  const invitesList = useCompanyAccountInvitesList({company_id: companyId, ...pageConfig.current});
  const deleteConfirmModal = React.useContext(PortalizedModalContext);

  const deleteInviteMutation = useMutation(deleteUserInvite, {
    onSuccess: () => {
      invitesList.refetch();
      ToastMessage.success(`Invitation deleted successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      deleteConfirmModal.closeModal();
    }
  });

  const handleDelete = (invitation) => () => {
    deleteConfirmModal.setModalContent(
      "Delete Invitation?",
      <>
        <p>{`Are you sure you want to delete invitation to- ${invitation.email}?`}</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteInviteMutation.mutate({slug: invitation.slug})
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteConfirmModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (invitesList.isSuccess)
        return invitesList.data.data
      else
        return []
    },
    [invitesList]
  );


  const columns = React.useMemo(
    () => {
      if (invitesList.isSuccess){
        return [
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Role',
            accessor: 'role_name'
          },
          {
            Header: 'Sent at',
            accessor: 'sent_at',
            Cell: ({value}) => formatDate(value, 'MMM dd, yyyy, hh:mm a') 
          },
          {
            Header: 'Expired',
            accessor: 'expired',
            Cell: ({value}) => value ? "Yes" : "No"
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ row, value }) => (
              <TableActionsContainer>
                <TableActionItem
                  tooltipText="Delete"
                  onClick={handleDelete(row.original)}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            )
          }
        ]
      }
      else{
        return []
      }
    },
    [invitesList]
  );

  return (
    <>
      <Paginator
        theme="dark"
        onPage={pageConfig.setPage}
        meta={invitesList?.data?.pagy}
        loading={invitesList.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={invitesList.isLoading}
        loadingMsg="Loading account invites..."
        noDataMsg="No invites found."
      />
    </>
  )
}

export default CompanyAccountInvitesList;
