import React from 'react'
import FileBar from './FileBar'
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';

function FileThumbnail({ name, url, downloadable }) {
  if (!url) {
    return null;
  }

  return (
    <div className='mb-1'>
      <FileBar name={name} url={url} customIcon={true} downloadable={downloadable}/>
    </div>
  )
}

export default function DocumentSection({ offer, showDocumentText = true }) {
  if (!offer) {
    return null;
  }

  return (
    <div id="documents" className="component-box">
      <div className="pitch-section-width">
        {showDocumentText && <PitchSectionLabel label={'documents'} />}
        <div className="pitch-section-body">
          <ul className="uploaded-files">
            <FileThumbnail name="Form C" url={offer.formc_url}/>
            <FileThumbnail name={offer.security_document?.name} url={offer.security_document?.attachment_url} />
            {offer.documents?.map(doc => {
              return <FileThumbnail key={doc.id} name={doc.name} url={doc.attachment_url} downloadable={doc.downloadable}/>
            })}
            {offer.other_documents?.map(doc => {
              return <FileThumbnail key={doc.id} name={doc.name} url={doc.attachment_url} downloadable={doc.downloadable}/>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}