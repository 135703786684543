import React from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import ToastMessage from "components/ToastMessage";
import HookForm from '../../components/HookForm';
import { createResourceDocument, deleteResourceDocument, useResourceDocuments } from '../queries';
import OverviewSubheader from './OverviewSubheader';
import { MdDelete, MdLock, MdPublic } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';

function DocumentSchema() {
  return Yup.object().shape({
    name: Yup.string().required("Document name is required."),
    attachment: Yup.mixed()
      .required("Document attachment is required"),
    visibility: Yup.string()
      .oneOf(["private", "public"])
      .required("visibility type is required")
      .default('public'),
    downloadable: Yup.bool().default(false)
  });
}

export default function ResourceDocuments({ resourceType, resourceId, enableVisibility = false, title }) {
  const queryClient = useQueryClient();
  const methods = useForm({
    resolver: yupResolver(DocumentSchema()),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      name: "",
      attachment: null,
      visibility: 'public',
      downloadable: false
    },
  });

  const addDocumentMutation = useMutation(createResourceDocument, {
    onSuccess: (values) => {
      methods.reset({
        name: "",
        attachment: null,
        visibility: 'public',
        downloadable: false
      });
      queryClient.invalidateQueries(['resourceDocuments', resourceType, resourceId], { exact: true })
      ToastMessage.success('Uploaded document successfully.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  });

  const onSubmit = async (values) => {
    const fd = new FormData();
    fd.append('resource_document[name]', values.name)
    fd.append('resource_document[attachment]', values.attachment, values.attachment.name)
    fd.append('resource_document[visibility]', values.visibility)
    fd.append('resource_document[downloadable]', values.downloadable)
    addDocumentMutation.mutate({
      resourceId: resourceId,
      resourceType: resourceType,
      values: fd
    })
  }

  return (
    <div>
      <div className="documents-content-box">
        <div className="mb-3"><label className="form-input-label fs-9">{title || 'Documents'}</label></div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <HookForm.TextInput name="name" placeholder="Document Name" type="text" />
            <HookForm.FileSelect
              name="attachment"
              placeholder="Upload Document"
              fileType="application/pdf"
              method="multipart"
            />
            { enableVisibility &&
              <HookForm.CheckboxSwitchField name="visibility" placeholder="Public" checkedValue="public" uncheckedValue="private" />
            }
            <HookForm.CheckboxSwitchField name="downloadable" placeholder="Downloadable" />
            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={addDocumentMutation.isLoading}>
              Upload Document
            </button>
          </form>
        </FormProvider>
        <List resourceId={resourceId} resourceType={resourceType} />
      </div>
    </div>
  );
}

function List({ resourceId, resourceType }) {
  const queryClient = useQueryClient();
  const { data: documents, loading, error } = useResourceDocuments({ resourceId, resourceType });

  const removeDocumentMutation = useMutation(deleteResourceDocument, {
    onSuccess: (values) => {
      queryClient.invalidateQueries(['resourceDocuments', resourceType, resourceId], { exact: true })
      ToastMessage.success('Document deleted.');
    },
    onError: (error) => {
      renderRailsErrors(error)
    }
  })

  const deletehandler = (documentId) => {
    removeDocumentMutation.mutate(documentId)
  }

  if (error) {
    return (
      <div className="px-3 text-center">Something went wrong. Please try again later.</div>
    )
  }

  if (loading) {
    return (
      <div className="px-3 text-center">Loading...</div>
    )
  }

  if (!documents?.length) {
    return null;
  }

  return (
    <div className='mt-5'>
      <OverviewSubheader left='Documents' />
      {documents && documents.map((d, index) => <View key={index} document={d} deletehandler={deletehandler} />)}
    </div>
  );
}

function View({ document, deletehandler }) {
  const href = document.attachment_url;

  return (
    <div className="d-flex justify-content-between bg-lightgrey2 mt-1 py-2 px-3 poppins">
      <div className="mt-1">
        <a href={href} className="color-black fs-10" target="blank">
          {document.visibility === "public" ? <MdPublic className="mr-2 mb-1" /> : <MdLock className="mr-2 mb-1" />}
          {document.name}
          <FaExternalLinkAlt className='ml-1 mb-1' />
        </a>
      </div>
      <small className='ml-4'>{ document.downloadable ? "Downloadable" : ""}</small>
      <MdDelete className="cursor-pointer" color="#111" size="17px" onClick={() => {
        if (window.confirm('Are you sure want to remove the document?')) {
          deletehandler(document.id)
        }
      }} />
    </div>
  )
}
