import React, { useState, useEffect } from "react";
import ToastMessage from "../../../components/ToastMessage";
import { FaTrash } from "react-icons/fa";
import OverviewSubheader from "../OverviewSubheader";
import TwoColumnLayout from "../TwoColumnLayout";
import AttributeValue from "../AttributeValue";
import InvestmentStatus from "./InvestmentStatus";
import InvestmentAuditHistory from "./InvestmentAuditHistory";
import InvestmentPayments from "./InvestmentPayments";

import { offerApi } from "api/offer";
import { userApi } from "api/user";
import { INVESTMENT_STATUSES } from "../../../config/constants";
import { formatDate } from "../../../jsUtils/dateTimeUtils";
import { toTitlecase } from "jsUtils/stringUtils";
import { paymentTypeText } from "../../../jsUtils/offerUtils"
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import ClientDocuments from "./ClientDocuments";
import AmlExceptions from "./AmlExceptions";
import ClearingFailures from "./ClearingFailures";
import AmountWithCurrency from "../../../components/AmountWithCurrency";
import LoadingOverlay from "react-loading-overlay";

const InvestmentOverview = ({ investmentId, onCancel, refreshInvestments }) => {
  const [loading, setLoading] = React.useState(true)
  const [investment, setInvestment] = useState({});
  const cancelInvestmentModal = React.useContext(PortalizedModalContext);

  useEffect(() => {
    if (investmentId) {
      setLoading(true);

      offerApi.fetchInvestment(investmentId).then(
        (response) => setInvestment(response),
        (error) => {
          ToastMessage.error(error.message || "Error Fetching Investments!");
        }
      ).finally(() => {
        setLoading(false);
      });
    }
  }, [investmentId]);

  const cancelInvestment = (id) => {
    offerApi.deleteInvestmentByAdmin(id).then(
      (response) => {
        ToastMessage.success("Cancellation request being processed, you will receive an email when its complete.");
        refreshInvestments();
        if (typeof onCancel == "function") {
          onCancel();
        }
      },
      (error) => {
        ToastMessage.error(error.message);
      }
    );
  };

  const onCancelInvestment = (id) => {
    cancelInvestmentModal.setModalContent(
      "Cancel Investment",
      <>
        <p>This action cannot be undone. Are you sure you want to cancel this investment?</p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              cancelInvestment(id);
              cancelInvestmentModal.closeModal();
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => cancelInvestmentModal.closeModal()}
          >
            Cancel
          </button>
        </div>
      </>,
      'sm'
    );
    cancelInvestmentModal.openModal();
  };

  if (loading) {
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading ...'
      />
    )
  }
  return (
    <div className="px-3 pb-3 pt-1 bg-lightgrey">
      <OverviewSubheader
        left={<InvestmentStatus investment={investment} />}
        right={
          [
            INVESTMENT_STATUSES.PENDING,
            INVESTMENT_STATUSES.NOT_RECEIVED,
            INVESTMENT_STATUSES.RECEIVED,
            INVESTMENT_STATUSES.FUNDED,
          ].includes(investment.status) && (
            <button
              className="btn btm-sm text-white p-0 d-flex"
              onClick={() => onCancelInvestment(investment.id)}
            >
              <FaTrash />
            </button>
          )
        }
      />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue
              name="Company"
              value={toTitlecase(investment.company_name)}
            />
            <AttributeValue
              name="Offer"
              value={toTitlecase(investment.offer_name)}
            />
            <AttributeValue
              name="Investment Date"
              value={formatDate(investment.created_at)}
            />
            <AttributeValue
              name="Subscription Agreement Signed"
              value={investment.subscription_agreement_signed ? "Yes" : "No"}
            />
            {investment.cancellation_requested_at && <AttributeValue
              name="Cancellation Request Date"
              value={formatDate(investment.cancellation_requested_at)}
            />}
            {investment.cancelled_at && <AttributeValue
              name="Cancellation Date"
              value={formatDate(investment.cancelled_at)}
            />}
            {investment?.category !== 'none' && <AttributeValue
            name="Broker Dealer Status"
            value={toTitlecase(investment.broker_dealer_status)}
            />}
            {investment?.category !== 'none' && <AttributeValue
              name="Broker Dealer Notes"
              value={
                <ul className="fs-10 weight-400 mb-0 ml-3">{investment.notes.map((note) => <li>{note}</li>)}</ul>
              }
            />}
          </>
        }
        right={
          <>
            <AttributeValue
              name="Invested as"
              value={toTitlecase(investment.investing_as)}
            />
            <AttributeValue
              name="Investment amount"
              value={<AmountWithCurrency number={investment.amount} />}
            />
            <AttributeValue
              name="Unit price"
              value={<AmountWithCurrency number={investment?.unit_price} />}
            />            
            <AttributeValue
              name="Payment type"
              value={paymentTypeText(investment.payment_method?.type)}
            />
            <AttributeValue
              name="Payment used"
              value={
                investment.payment_method && investment.payment_method?.account_name && investment.payment_method?.account_number_last_four
                  ? `${investment.payment_method?.account_name} (${investment.payment_method?.account_number_last_four})`
                  : "-"
              }
            />
            <AttributeValue
              name="Admin fees"
              value={<AmountWithCurrency number={investment.admin_fee} />}
            />
          </>
        }
      />
      <ClientDocuments investment={investment}/>
      <InvestmentAuditHistory audits={investment.audits} />
      {
        investment.investment_payments && <InvestmentPayments payments={investment.investment_payments}/>
      }

      <ClearingFailures investment={investment}/>
      {/* {investment?.client_aml_status=="failed" && <AmlExceptions investmentId={investment.id}/>} */}
    </div>
  );
};

export default InvestmentOverview;
