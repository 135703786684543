import { GeneralSummaryRow, TractionSummaryRow, MarketSummaryRow, CustomerSummaryRow, BusinessSummaryRow  } from "../investor/components/pitch/sections/SummarySection/SummarySectionRow/row";

export const PITCH_CONSTANTS = [
  {sectionKey: 'problem_section',  sectionTitle: 'Problem', component: GeneralSummaryRow},
  {sectionKey: 'solution_section', sectionTitle: 'Solution', component: GeneralSummaryRow },
  {sectionKey: 'product_section', sectionTitle: 'Product', component: GeneralSummaryRow},
  {sectionKey: 'traction_section', sectionTitle: 'Traction', component: TractionSummaryRow},
  {sectionKey: 'customers', sectionTitle: 'Customers', component: CustomerSummaryRow},
  {sectionKey: 'business_models', sectionTitle: 'Business Model', component: BusinessSummaryRow},
  {sectionKey: 'market_section', sectionTitle: 'Market', component: MarketSummaryRow},
  {sectionKey: 'competition_section', sectionTitle: 'Competition', component: GeneralSummaryRow},
  {sectionKey: 'team_section', sectionTitle: 'Team', component: GeneralSummaryRow},
  {sectionKey: 'vision_section', sectionTitle: 'Vision', component: GeneralSummaryRow},
  {sectionKey: 'use_of_funds_section', sectionTitle: 'Use of Funds', component: GeneralSummaryRow},
];