import React from 'react';
import IssuerProfile from './OfferForm/IssuerProfile';

export default function IssuerProfileEditor({companySlug, issuerDetails}) {
  return (
    <>
      <div className="settings-tab-box">
        <div className="settings-tab-box-nav">
          <ul className="nav nav-tabs" id="issuer-profile-edit-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="issuer-profile-tab" data-toggle="tab" href="#issuer-profile-form" role="tab">Issuer Profile</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="company-edit-tab-content">
          <div className="tab-pane fade show active" id="issuer-profile-form" role="tabpanel">
            <div className="mini-container">
              <IssuerProfile companySlug={companySlug} issuerDetails={issuerDetails} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
