import React from 'react';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSession, createSession, setLoading, setError } from 'redux/session';
import { _setAuthStatus } from 'redux/user';
import { toTitlecase } from 'jsUtils/stringUtils';
import { AuthContext } from './../../contexts/authContext';
import { userApi } from 'api/user';
import ToastMessage from '../../components/ToastMessage';
import { history } from './../../redux/helpers';
import OauthLogin from '../../components/OauthLogin';
import { rollbarInstance } from '../../jsUtils/rollbar';
import { setHubspotIdentity } from './Tracking/hubspot';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required')
});

export default function LoginForm(){
  const { setCurrentUser } = React.useContext(AuthContext);
  const location = useLocation();
  const sessionErrors = useSession().error

  return(
    <div id="loginForm" className="container m-width-medium mt-30 mb-30">
      <h1 className='weight-900 fs-3 text-uppercase'>Log In</h1>
      <div className='d-block px-md-5'>
        <OauthLogin type='login'/>
        <div className='poppins fs-12 weight-400 dotted-line-with-text my-3'>OR LOG IN WITH EMAIL</div>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true)
          userApi.login(values).then(
            sessionUser => {
              const authToken = sessionUser.token
              delete sessionUser.token
              setCurrentUser(sessionUser)
              createSession(sessionUser)
              _setAuthStatus(true)
              ToastMessage.success("Logged in successfully.")
              setLoading(false)
              setHubspotIdentity({ userId: sessionUser.id, email: sessionUser.email })
              if (sessionUser.admin) {
                history.push('/admin/companies')
              }
              else {
                const afterLoginPath = location?.state?.afterLoginPath;
                afterLoginPath
                  ? history.push(afterLoginPath)
                  : history.push('/')
              }
            },
            error => {
              setLoading(false)
              setError(error)
              _setAuthStatus(false)
              ToastMessage.error(error?.message)
              rollbarInstance.warn("Error authenticating with email", {error})
            }
          )
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form className="pt-1 pb-3 px-0 px-md-5">
            {
              sessionErrors && sessionErrors.user_authentication &&
                <div>{toTitlecase(sessionErrors.user_authentication)}</div>
            }
            <div className="form-label-group">
              <Field type="email"
                className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                name="email"
                id="email"
                placeholder="Email"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="email">Email</label>
              <ErrorMessage name="email" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                name="password"
                id="password"
                placeholder="Password"
              />
              <div className="white-backlayer"></div>
              <label htmlFor="password">Password</label>
              <ErrorMessage name="password" component="div" className="invalid-feedback text-left"/>
            </div>
            <button type="submit" className="mx-auto btn-login col-md-8 col-12" disabled={isSubmitting}>
              Log In
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
