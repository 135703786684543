import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';

const useOfferDocumentsList = ({offer_id}) => {
  return useQuery(
    ['offer', 'documents', offer_id],
    () => {
      return axiosClient
        .get(`admin/offers/${offer_id}/documents`)
    }
  );
}

const uploadDocument = ({offer_id, values}) => {
  return axiosClient
    .post(`admin/offers/${offer_id}/documents`, values, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}

const useBusinessModelList = () => {
  return useQuery(
    ['businessModelList'],
    () => {
      return axiosClient
        .get('/business_models')
    }
  );  
}

const removeDocument = ({document_id}) => {
  return axiosClient
    .delete(`admin/documents/${document_id}`)
}

const upsertOfferSubDocTemplate = ({offer_id, investor_type, values}) => {
  return axiosClient.post(
    subscriptionAgreementTemplatesUrl(offer_id, investor_type),
    values
  );
}

const useOfferSubDocTemplate= ({offer_id, investor_type}) => {
  return useQuery(
    ['subdoc', offer_id, investor_type || ''],
    () => {
      return axiosClient
        .get(subscriptionAgreementTemplatesUrl(offer_id, investor_type))
    }
  );
}

const subscriptionAgreementTemplatesUrl = (offerId, investorType) => {
  let url = `/admin/offers/${offerId}/subscription_agreement_templates`;

  if(investorType){
    url = `${url}?investor_type=${investorType}`;
  }

  return url
}

const addPitchSection = ({ id, values }) => {
  return axiosClient.post(`admin/offers/${id}/pitch/pitch_sections`, values)
}

const addEntityDocument = ({ clientId, data }) => {
  return axiosClient.post(`/clients/${clientId}/entity_documents`, data)
}

const updatePitchSection = ({ id, values }) => {
  return axiosClient.put(`admin/pitch_sections/${id}`, values)
}

const deletePitchSection = ({ id, sectionType }) => {
  return axiosClient.delete(`admin/pitch_sections/${id}`, { data: { type: sectionType } })
}

export {
  useOfferDocumentsList,
  uploadDocument,
  removeDocument,
  useOfferSubDocTemplate,
  upsertOfferSubDocTemplate,
  addPitchSection,
  addEntityDocument,
  updatePitchSection,
  deletePitchSection,
  useBusinessModelList,
};
