import { createReduxModule } from 'hooks-for-redux'
import ToastMessage from '../components/ToastMessage';
import { spacedbaseApi } from 'api/spacedbase'

const storeKey = 'spacedbase'
const initialState = {
  loading: false,
  chartData: { sectors: [] },
  sectorId: null,
  fundingStage: null,
  isPrivate: null,
  errors: {},
};

export const [useSpacedbaseStore, {_setChartData, _updateFilter, _setloading, _addErrors}, spacedbaseStore] = createReduxModule(storeKey, initialState, {
  _setChartData: (state, chartData) => {
    return {
      ...state,
      chartData
    }
  },

  _updateFilter: (state, filter) => {
    return {
      ...state,
      [filter.key]: filter.value
    }
  },

  _setloading: (state, loading) => {
    return  {
      ...state,
      loading
    }
  },

  _addErrors: (state, errors) => {
    return {
      ...state,
      errors: errors
    }
  },
})

export const updateFilter = (key, value) => {
  _updateFilter({key, value})
  const {sectorId, isPrivate, fundingStage} = spacedbaseStore.getState()
  fetchChartData({sector_id:  sectorId, last_funding_type: fundingStage, private: isPrivate})
}

export const fetchChartData = (params) => {
  _setloading(true)
  spacedbaseApi.fetch(params).then(
    resp => {
      _setChartData(resp);
      _setloading(false)
    },
    error => {
      _addErrors({fetch: error})
      _setloading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}