import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';


export default function Modal({ title = "Sign up to Learn More", modalOpen, toggleModal, children }) {


  if (!modalOpen) {
    return null;
  } else {  
    
  return (

      <ModalContainer>
        <div id="modal">
          <div className="d-flex justify-content-between px-4 pt-4 pb-3">
          <h3 className="fs-9 weight-700">{title}</h3>
          <MdClose size="1.5em" onClick={toggleModal} className="modal-close"/>
          </div>
            {children}
        </div>
      </ModalContainer>

  )}

}


  const ModalContainer = styled.div`

  #modal{
    position: fixed;
    width: 500px;
    height: 360px;
    top: 50%;
    left: 50%;
    margin-top: -180px; /* Negative half of height. */
    margin-left: -250px;
    z-index: 1000;
    background: #fff;
    border-radius: 2px;
    
  }

  @media (max-width: 700px) {
    #modal{
      width: 400px;
      margin-left: -200px;
    }
}
`