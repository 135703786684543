import React from 'react';
import { useParams } from "react-router";
import ToastMessage from '../../../../../components/ToastMessage';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RichEditor,
  createEditorStateWithContent,
  createEditorSaveContent,
  FormikPromptIfDirty,
} from 'admin/components'
import DeleteSectionButton from './../DeleteSectionButton';
import LastUpdatedAt from './../LastUpdatedAt';
import { closeIcon } from "./../../../../../assets/images";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import './../OfferForm.scss';
import LoadingOverlay from 'react-loading-overlay';

const TractionSchema = Yup.object().shape({
  // content: Yup.object()
  //   .test(
  //     'has text',
  //     'Article content is required',
  //     value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
  //   ),
  points: Yup.array()
    .of(Yup.string().required("Can't be blank"))
    .required('Must have tractios points') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Minimum of 1 traction point')
    .max(5, 'Maximum of 5 traction point'),
  });

export default function TractionForm({
  pitch:{traction_section={}, pitchable_id}, updateMethod
}) {
  if(traction_section === null) traction_section = {};

  const { preofferSlug } = useParams()
  
  const defaultValues = {
    id: traction_section.id,
    points: traction_section.points || [],
    content: createEditorStateWithContent(traction_section.content),
  }

  const queryClient = useQueryClient();
  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['preoffer#pitch', preofferSlug],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
  <LoadingOverlay active={updatePitchMutation.isLoading} spinner text="Loading">
    <div id="tractionForm">
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        validationSchema={TractionSchema}
        enableReinitialize

        onSubmit={async values => {
          const { points, content, id } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                traction_section_attributes: {
                  id,
                  points,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <FieldArray
                  name='points'
                  render={arrayHelpers => (
                    <>
                      { values.points.map((point, index) => {
                          return (
                            <div className="traction-point mb-3" key={index.toString()}>
                              <div className="form-label-group show-org-placeholder mb-0 mr-3">
                                <Field
                                  id={`points-${index}`}
                                  name={`points.${index}`}
                                  placeholder="Traction Point"
                                  className={`form-control ${errors?.points && errors?.points[index] && typeof(errors?.points) !== 'string' ? "is-invalid" : ""}`} />
                                <label htmlFor={`points-${index}`}>Traction Point</label>
                                <ErrorMessage name={`${typeof(errors?.points) !== 'string' ? `points.${index}` : null}`} component="div" className="invalid-feedback text-left" />
                              </div>
                              <img src={closeIcon} onClick={() => arrayHelpers.remove(index)} className="cursor-pointer" alt="close icon" />
                            </div>
                          )
                        })
                      }
                      {values.points.length < 5 && <span onClick={() => arrayHelpers.push('')} className={`cursor-pointer fs-10 d-block poppins ${typeof(errors?.points) === 'string' ? "is-invalid" : ""}`}>+ Add new point</span>}
                    </>
                  )}
                >
                </FieldArray>
                <ErrorMessage name={`${typeof(errors?.points === 'string') ? 'points' : null}`} component="div" className="invalid-feedback text-left" />
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={ traction_section.id } sectionType="traction"/>
            <LastUpdatedAt updatedAt={ traction_section.updated_at }/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  </LoadingOverlay>
  );
}
