import React from "react";
import { PITCH_CONSTANTS } from '../../../../../config/pitchConstants'
import SummarySectionRow from "./SummarySectionRow";
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';

export default function SummarySection({ pitch }) {
  if(!pitch) return null;

  return (
    <div id="summary" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'Summary'} />
        <div className="pitch-section-body">
          <div className="summary-inner">
            {PITCH_CONSTANTS.map((pitchSection, index) => {
              return <SummarySectionRow key={index} pitch={pitch} pitchSection={pitchSection}/>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}