import React from "react";
import { formatCurrency } from "../../../jsUtils/currencyUtils";
import { toTitlecase } from "../../../jsUtils/stringUtils";
import { formatDate } from "../../../jsUtils/dateTimeUtils";
import { useClientInvestments } from "./queries";
import OverviewSubheader from "../../components/OverviewSubheader";

function InvestmentItem({ investment }) {
  return (
    <div className="highlighted-tile mb-1 d-flex flex-wrap">
      <div className="col-5">{toTitlecase(investment.offer_name)}</div>
      <div className="col-2">
        {formatCurrency(investment.amount, { precision: 2 })}
      </div>
      <div className="col-2">
        <div className="w-100 text-right"> {investment.status ?? "-"}</div>
      </div>
      <div className="col-3">
        <div className="w-100 text-right"> {formatDate(investment.created_at)}</div>
      </div>
    </div>
  )
}

export default function InvestmentList({ clientId }) {
  const { isLoading, isError, data: investments } = useClientInvestments(clientId);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <h5 className="montserrat weight-700">Could not fetch investment agreement. Please try reloading the page.'</h5>;
  }

  return (
    <>
      <OverviewSubheader left="Investments" />
      {investments.map((investment) => <InvestmentItem key={investment.id} investment={investment} />)}
    </>

  )
}