import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import {BsInfoCircle} from 'react-icons/bs'
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from "yup";
import FormattedNumberInput from "components/HookForm/FormattedNumberInput";
import ToastMessage from 'components/ToastMessage';
import { AuthContext } from 'contexts/authContext';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { history } from 'redux/helpers';
import ShareButtons from "./ShareButtons";
import {createPledge, updatePledge} from "../../../queries";
import { runGAEventWithValue } from '../../../../pages/components/Tracking';
import { fbEvent } from '../../../../pages/components/Tracking/facebook'; 
import bgVideo from "../../../../assets/videos/petition-bg.mp4"
import { formatMoneyClean } from "../../../../jsUtils/currencyUtils";
import AmountWithCurrency from "../../../../components/AmountWithCurrency";
import TextButton from 'admin/components/TextButton';
import { FaPen } from "react-icons/fa";

const PledgeSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Please enter a pledge amount")
    .positive()
    .integer("Please enter an amount without cents")
    .min(100, 'Minimum pledge amount is $100')
    .max(1000000, 'Maximum pledge amount is $1,000,000. Email venture@spacedventures.com if you’re interested in a higher allocation.')
    .required("Please enter a pledge amount"),
  raise_petition_id: Yup.number().positive().integer().required(),
});

function PledgeForm({raisePetition={}, theme="dark"}) {
  const { authData } = React.useContext(AuthContext);
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing]= useState(false)
  const loginModal = React.useContext(PortalizedModalContext);
  const pledgeModal = React.useContext(PortalizedModalContext);
  const { company, user_pledge } = raisePetition
  const raise_petition_id = raisePetition.id;
  const amount = '';

  const createPledgeMutation = useMutation(
    createPledge,
    {
      onSuccess: (values) => {
        methods.reset()
        runGAEventWithValue(`${company.name} pledge created`, "button", "clicked", values.amount)
        runGAEventWithValue("New Pledge", "form submission", "success", values.amount )
        fbEvent(`${company.name} pledge created`, values.amount);
        queryClient.invalidateQueries(
          ['raise-petition-chart-data', raisePetition.slug],
          {
            exact: true,
          }
        )
        queryClient.invalidateQueries(
          ['raise-petitions', raisePetition.slug],
          {
            exact: true,
          }
        )
        ToastMessage.success(`You’ve pledged ${formatMoneyClean(values.amount)} to ${company.name}`)
        pledgeModal.setModalContent(
          null,
          <>
            <div className="pledge-content">
              <p className='fs-14 mb-1'>If {company.name} raises on Spaced Ventures</p>
              <h6 className='montserrat weight-900'>You’ve pledged to invest {formatMoneyClean(values.amount)}</h6>
              <div className="logo-box">
                <img
                  src={company.logo_url}
                  alt={`${company.name} raise petition logo`}
                  className="logo"
                />
              </div>
              <p className="fs-9 mb-0 weight-700 mb-2">Share your pledge to further the cause!</p>
              <ShareButtons offering={raisePetition}/>
            </div>
          </>,
          'sm',
          ['headless-modal', 'with-video-background']
        )
        pledgeModal.openModal();
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const updatePledgeMutation = useMutation(
    updatePledge,
    {
      onSuccess: (values) => {
        setIsEditing(false)
        methods.reset()
        runGAEventWithValue(`${company.name} pledge updated`, "button", "clicked", values.amount)
        runGAEventWithValue("Pledge Update", "form submission", "success", values.amount )
        fbEvent(`${company.name} pledge updated`, values.amount);
        queryClient.invalidateQueries(
          ['raise-petition-chart-data', raisePetition.slug],
          {
            exact: true,
          }
        )
        queryClient.invalidateQueries(
          ['raise-petitions', raisePetition.slug],
          {
            exact: true,
          }
        )
        ToastMessage.success(`Your pledge amount has been updated to ${formatMoneyClean(values.amount)}.`)
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const onSubmit = (values) => {
    if(!authData.isAuthenticated) {
      ToastMessage.error("You must sign up or login to pledge.")
      loginModal.setModalContent(
        `Join the ${company.name} Petition`,
        <>
          <div className="pt-3">
            <p>You must login or sign up to join the {company.name} Petition</p>
          </div>
          <div className="portalized-modal-footer">
            <button className='btn-black' onClick={() => {
                loginModal.closeModal()
                history.push('/signup')
              }}>Sign Up</button>
            <button className='btn-black-outline' onClick={() => {
                loginModal.closeModal()
                history.push('/login')
              }}>Login</button>
          </div>
        </>
      )
      loginModal.openModal();
      return
    }

    user_pledge ?
      updatePledgeMutation.mutate({
        values,
        pledge_id: user_pledge.id
      })
      :
      createPledgeMutation.mutate({
        values
      })
  }

  const methods = useForm({
    resolver: yupResolver(PledgeSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      raise_petition_id, amount,
    },
  });

  methods.setValue('raise_petition_id', raisePetition.id);

  const handleEdit = () => {
    setIsEditing(true)
    methods.setValue("amount", user_pledge.amount)
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        { user_pledge &&
          <div className="mb-2">
            You've pledged <AmountWithCurrency number={user_pledge.amount} />
            <span className="text-capitalize font-weight-bold border-bottom ml-2">
              <TextButton icon={<FaPen className="mb-1" size="12px"/>} title='Edit' theme={theme} onClick={handleEdit} />
            </span>
          </div>
        }
        <div className="field-with-btn">
          <FormattedNumberInput
            name="amount"
            placeholder="$100 or more"
            inputClasses={theme == "dark" ? "" : "light"}
          />
          <button type="submit"
            className="weight-300 btn btn-outline-dark rounded-0 weight-600"
            disabled={methods.isSubmitting}
          >
            {isEditing ? 'Update Pledge' : 'Ask to Invest'}
          </button>
        </div>
      </form>
      <div className="d-flex align-items-center poppins m-t-10 fs-14">
        <span className="poppins weight-700">First come, first serve</span> 
        <BsInfoCircle data-for="firstcome-tooltip" data-tip={`Spaced Ventures CF campaigns are conducted on a first come, first serve basis. Investors will be notified in the order of the pledges submitted if ${company.name} raises on Spaced Ventures.`} style={{marginLeft: '7px'}} color="#ccc" size="1em"  />
        <ReactTooltip id="firstcome-tooltip" type="light" effect="solid" className="spaced-tooltip zeitung"/>
      </div>
    </FormProvider>
  );
}

export default PledgeForm;
