import React from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";
export default function CustomContentLoader({
  loading = "false",
  shape,
  width = 250,
  height = 250,
  listCount = 1,
}) {
  if(!loading) return null;
  return [...Array(listCount)].map((value, index) => (
    <ContentLoader
      key={index}
      speed={2}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#dddddd"
      foregroundColor="#c7c7c7"
    >
      {shape}
    </ContentLoader>
  ))
}

CustomContentLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  shape: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  listCount: PropTypes.number.isRequired,
};
