import React, { useState, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FaPen, FaTrash } from "react-icons/fa";
import { formatDateWithoutTimezone } from 'jsUtils/dateTimeUtils';
import { ResponsiveTable } from 'admin/components'
import OverviewSubheader from 'admin/components/OverviewSubheader';
import Form from './Form';
import EditorModal from 'admin/components/EditorModal';
import EditHeader from 'admin/components/EditHeader';
import TextButton from 'admin/components/TextButton';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import ToastMessage from 'components/ToastMessage';
import { offerUpdateApi } from 'api/offerUpdate';
import TableActionsContainer from '../TableActionsContainer';
import TableActionItem from '../TableActionItem';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

export default function List({ offerId }) {
  const queryClient = useQueryClient();
  const [currentOfferUpdate, setCurrentOfferUpdate] = useState(null);
  const deleteModal = useContext( PortalizedModalContext );
  const { isLoading, error, data: offerUpdates } = useQuery(['offerUpdates', offerId], () => {
    return offerUpdateApi.admin.list(offerId);
  });

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const editOfferUpdate = (id) => {
    const offerUpdate = offerUpdates.find((e) => e.id === id);

    if(offerUpdate){
      setCurrentOfferUpdate(offerUpdate);
    }
  };

  const deleteOfferUpdate = (id) => {
    const offerUpdate = offerUpdates.find((e) => e.id === id);

    if(!offerUpdate){
      return;
    }

    offerUpdateApi.admin.delete(offerUpdate.id).then(() => {
      queryClient.invalidateQueries(['offerUpdates', offerId]);
      ToastMessage.success(`${offerUpdate.title} deleted.`);
    }).catch(function (error) {
      renderRailsErrors(error)
    })
  };

  const deleteHandler = (id) => {
    const offerUpdate = offerUpdates.find((e) => e.id === id);

    if(offerUpdate){
      deleteModal.setModalContent(
        'Delete Offer Update',
        <>
          <p>Are you sure you want to delete the offer update?</p>
          <div className="portalized-modal-footer">
            <button className="btn-black"
              onClick={() => {
                deleteOfferUpdate(id);
                deleteModal.closeModal();
              }}>Confirm</button>
            <button className="btn-black-outline" onClick={() => deleteModal.closeModal()}>Cancel</button>
          </div>
        </>,
        'sm'
      );
      deleteModal.openModal();
    }
  };

  const columns = [
     {
      Header: 'Title',
      accessor: 'title'
    },
    {
      Header: 'Date posted',
      accessor: 'published_at',
      Cell: ({value}) => formatDateWithoutTimezone(value) || ''
    },{
      Header: 'Likes',
      accessor: 'likes_count'
    },
    {
      Header: 'Comments',
      accessor: 'discussions_count'
    },
    {
      Header: 'Type',
      Cell: ({value, row}) => {
        return row.original.resourceable_type ? 'Live Event' : 'Offer Update';
      }
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({value, row}) => {
        return(
          <TableActionsContainer>
            { !row.original.resourceable_type && <>
                <TableActionItem tooltipText="Update" onClick={ ()=> editOfferUpdate(value)}>
                  <FaPen className='fs-14 color-black'/>
                </TableActionItem>
                <TableActionItem tooltipText="Delete" onClick={ ()=> deleteHandler(value)}>
                  <FaTrash className='fs-14 color-black'/>
                </TableActionItem>
              </>
            }
          </TableActionsContainer>
        )
      }
    }
  ];

  if(currentOfferUpdate){
    return (
      <EditorModal onRequestClose={ () => setCurrentOfferUpdate(null) }>
        <EditHeader left={ currentOfferUpdate.id ? 'Edit Offer Update' : 'New Offer Update'} />
        <div className='container'>
          <Form offerId={offerId} offerUpdate={currentOfferUpdate} setCurrentOfferUpdate={setCurrentOfferUpdate}/>
        </div>
      </EditorModal>
    )
  }

  return(
    <>
      <OverviewSubheader left='Offer Updates' right={<TextButton title='New Offer Update' onClick={ () => setCurrentOfferUpdate({}) } />}/>

      <div className="action-table">
        <ResponsiveTable
          columns={columns}
          data={offerUpdates || []}
          loading={isLoading}
          loadingMsg="Loading offer updates..."
          noDataMsg="No Updates have been posted yet."
        />
      </div>
    </>
  );
}
