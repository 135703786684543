import React, {useContext, useState} from 'react'
import { history } from 'redux/helpers';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import companyApi from '../../api/company';
import { AuthContext } from 'contexts/authContext'
import {BsInfoCircle} from 'react-icons/bs'
import { PortalizedModalContext } from 'contexts/portalizedModalContext'

export default function FollowButton({ following, company, loginMessage, followText = 'Follow', unfollowText = 'Unfollow', theme = 'light'}){
  const [followingStatus, setFollowingStatus] = useState(following);
  const { isUserLoggedin } = React.useContext(AuthContext);
  const loginModal = useContext(PortalizedModalContext)

  const bgColor = theme === 'light' ? "#fff" : "#111";
  const textColor = theme === 'light' ? "#111" : "#fff";

  const follow = () => {
    if(!isUserLoggedin()){
      loginModal.setModalContent(
        'Follow company',
        <>
          <p>You must login or sign up to follow a company</p>
          <div className="portalized-modal-footer">
            <button className='btn-black' onClick={() => {
              loginModal.closeModal()
              history.push('/login')
            }}>Login</button>
            <button className='btn-black-outline' onClick={() => {
                loginModal.closeModal()
                history.push('/signup')
              }}>Sign Up</button>
          </div>
        </>,
        'sm'
      )
      loginModal.openModal();
      return
    }

    companyApi.follow(company.slug).then(
      (response) => { setFollowingStatus(response.following) },
      (error) => {}
    );
  };

  return (
    <>
   <div className="d-flex align-items-start">
      <Button bgColor={bgColor} textColor={textColor} className={`btn btn-${theme} btn-sm poppins`} onClick={follow}>
        { followingStatus ? unfollowText : followText }
      </Button>
      {!followingStatus &&
          <div className="d-flex align-items-start">
            <ReactTooltip id="follow-company" type="light" effect="solid" className="spaced-tooltip poppins"/>
            <BsInfoCircle style={{marginLeft: '7px'}} color={theme === 'dark' ? "#111" : "#ccc"} size="0.75em" data-tip={`Follow ${company.name} to receive notifications on fundraising progress and updates from founders.`} data-for='follow-company' data-event-off='dblclick' />
          </div>
        }
    </div>
    </>
  )
}

const Button = styled.button`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  min-width:110px;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 16px;
  margin-left: 2px;
  text-transform: none !important;
  &:focus, &:active {
   outline: none !important;
   box-shadow: none;
`;
