import { createReduxModule } from 'hooks-for-redux'
import { investorApi } from 'api/investor';
import { downloadFile } from '../jsUtils/fileUtils';

const storeKey = 'investor'
const initialState = {
  error: null,
  loading: false,
  investor: null,
  data: [],
  pagy:{pagination: {current_page: 1}},
};

export const [useInvestor, { _setError, _setLoading, _setData, _setListMeta, _setInvestor, _clearInvestor, _clearInvestors }, investorStore] = createReduxModule(storeKey, initialState, {

  _setError: (state, error) => {
    return {...state, error}
  },

  _setLoading: (state, loading) => {
    return {...state, loading}
  },

  _setData: (state, data) => {
    return {
      ...state,
      data
    }
  },
  _setListMeta: (state, pagy) => {
    return {
      ...state,
      pagy
    }
  },
  _setInvestor:(state, investor) =>{
    return {
      ...state,
      investor
    }
  },
  _clearInvestor: (state) => {
    return {
      ...state,
      investor: null
    }
  },
  _clearInvestors:(state)=>{
    return {
      ...state,
      investors: null
    }
  },

})

export const fetchFiltered = (filterValue, searchText=null) => {
  _setLoading(true);
  investorApi.fetchFiltered(filterValue, searchText).then(
    (response) => {
      _setData(response.data)
      _setListMeta(response.pagy)
    _setLoading(false);
  },
  error => {
    console.error(error)
    _setLoading(false);
  })
}

export const search = (pattern, page, per_page) => {
  _setLoading(true);
  investorApi.search(pattern, page, per_page).then(
    (response) => {
      _setData(response.data)
      _setListMeta(response.pagy)
    _setLoading(false);
  },
  error => {
    console.error(error)
    _setLoading(false);
  })
}

export const list = (filterValue, search_query, page, per_page) => {
  _setLoading(true);
  investorApi.list(filterValue, search_query, page, per_page).then(
    (response) => {
      _setData(response.data)
      _setListMeta(response.pagy)
    _setLoading(false);
  },
  error => {
    console.error(error)
    _setLoading(false);
  })
}


export const fetch = (investorId) => {
  _setLoading(true);
  investorApi.fetch(investorId).then(
    (response) => {
      _setInvestor(response)
    _setLoading(false);
  },
  error => {
    console.error(error)
    _setLoading(false);
  })
}

export const exportList = (filterValue, queryString ) => {
  _setLoading(true);
  investorApi.exportCsv(filterValue, queryString).then(
    (blob) => {
      if(filterValue === '') filterValue = 'investors';
      filterValue +='.csv';
      downloadFile(blob, filterValue);
      _setLoading(false);
  },
  error => {
    console.error(error)
    _setLoading(false);
  })
}
