import React, {useEffect} from "react";
import styles from './index.module.scss'
import { useRef } from "react";

export default ({
  options,
  value,
  onChange,
  optionLabel='label',
  optionValue='value',
  nested=false,
  parentSelectable=false,
  nestingKey='children',
  nestedLabel='label',
  nestedValue='value',
  onBlur,
  placeholder='',
  id='',
  className,
  debugId=''
}) => {
  const selectWrapper = useRef(null)
  const customSelect = useRef(null)
  const customOptions = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectWrapper && !selectWrapper.current.contains(event.target)) {
        customSelect.current.classList.remove(styles['open'])
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  // Show selected option
  useEffect(() => {
    for (const option of customOptions.current.querySelectorAll("div")) {
      if (option.classList.contains(styles['selected'])) {
        const optionSpan = option.querySelector('span')
        option.closest(`.${styles['custom-select']}`).querySelector(`.${styles['custom-select__trigger']} span`).textContent = optionSpan.dataset.labelcontent;
      }
    }
  })

  useEffect(() =>{
    const optionClickHandler = function () {
        const option = this.querySelector('span')
        if (option.dataset.cindex)
          onChange(options[option.dataset.index][nestingKey][option.dataset.cindex][nestedValue])
        else
          onChange(options[option.dataset.index][optionValue])
    };

    const handleSelectWrapperClick = () => {
      customSelect.current.classList.toggle(styles['open'])
      let maxWidth = 0;
      for (const option of customOptions.current.querySelectorAll("div")) {
        option.addEventListener('click', optionClickHandler)
        maxWidth = maxWidth > option.querySelector('span').offsetWidth ? maxWidth : option.querySelector('span').offsetWidth
      }
      customOptions.current.style.width = `${maxWidth+48}px`
    };

    selectWrapper.current.addEventListener('click', handleSelectWrapperClick)

    return () => {
      for (const option of customOptions.current.querySelectorAll("span")) {
        option.removeEventListener('click', optionClickHandler)
      }
      selectWrapper.current.removeEventListener('click', handleSelectWrapperClick)
    }
  })

  return (
    <div ref={selectWrapper} className={styles["custom-select-wrapper"]}>
      <div ref={customSelect} className={styles["custom-select"]}>
        <div className={styles["custom-select__trigger"]}><span>{placeholder}</span>
          <div className={styles["arrow"]}></div>
        </div>
        <div ref={customOptions} className={styles["custom-options"]}>
          {
            options.map((o, index) => {
              let classNames = styles["custom-option"]
              if(value == o[optionValue]){
                classNames = classNames+ ' ' + styles['selected']
              }

              const opts = [
                <div key={o[optionValue]} className={classNames} >
                  <span
                    data-value={o[optionValue]}
                    data-labelcontent={o[optionLabel]}
                    data-index={index}
                    style={nested ? parentSelectable ? {} : {pointerEvents: 'none'} : {}}
                  >
                    {o[optionLabel]}
                  </span>
                </div>
              ];
              nested && o[nestingKey].forEach((c, cindex) =>{
                let classNames = `${styles["custom-option"]} ${styles["custom-option-child"]}`
                if(value == c[nestedValue]){
                  classNames = classNames+ ' ' + styles['selected']
                }

                opts.push(
                  <div key={c[nestedValue]} className={classNames}>
                    <span
                      data-value={c[nestedValue]}
                      data-labelcontent={`${o[optionLabel]}: ${c[nestedLabel]}`}
                      data-index={index}
                      data-cindex={cindex}
                    >
                      {c[nestedLabel]}
                    </span>
                  </div>
                )
              });
              return opts;
            })
          }
        </div>
      </div>
    </div>
  )
};
