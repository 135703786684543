import React from 'react';
import { useParams } from "react-router";
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { useRaisePetition, updateRaisePetition } from './queries';
import OverviewForm from './OverviewForm';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

function OverviewEditor() {
  let { raisePetitionSlug } = useParams()
  const queryClient = useQueryClient();

  const {data:raisePetition={}, isLoading} = useRaisePetition(raisePetitionSlug)

  const updateRaisePetitionMutation = useMutation(
    updateRaisePetition,
    {
      onSuccess: (updatedRaisepetition) => {
        ToastMessage.success("Raise petition updated.")
        queryClient.invalidateQueries(
          ['raise-petition', 'list'],
          {
            exact: false,
          }
        )
        queryClient.setQueryData(
          ['raise-petition', raisePetitionSlug],
          updatedRaisepetition
        )
      },
      onError: (error) => {
        ToastMessage.error('Raise petition update failed.')
        renderRailsErrors(error)
      },
    }
  )

  const onSubmit = (values) => {
    updateRaisePetitionMutation.mutate({
      raise_petition_slug: raisePetition.slug,
      values
    })
  }

  if(isLoading)
    return 'Loading...'

  return (
    <OverviewForm companyId={raisePetition.company_id} raisePetition={raisePetition} onSubmit={onSubmit}/>
  );
}

export default OverviewEditor;
