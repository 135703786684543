import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchTeamIcon } from '../../../../assets/images';

function TeamSection({ 
  pitch:{team_section}
}) {
  if(!team_section) return null;
  
  return (
    <div id="team-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'team'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchTeamIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{team_section.title}</div>
          </div>
            <ArticleContent content={team_section.content} sectionName="team_section" />
        </div>
      </div>
    </div>
  )
}

export default TeamSection;