
import React from 'react'

export default function InvestmentCardRow({ title, value }) {
  return <div className="cl-row">
    <div className="inner1 fs-10">{title}</div>
    <div className="inner2 fs-10">{value}</div>
  </div>
}


export function InvestmentCardActionRow({children}) {
  return (
    <div className="cl-row cl-actions-row">
      {children}
    </div>
  )
}