import differenceInDays from 'date-fns/differenceInDays'
import { format, parseJSON, parse, differenceInHours, differenceInMinutes, differenceInYears } from 'date-fns';

export const calculateRemainingDays = (endDate) => {
  const remainingDays = differenceInDays(parseJSON(new Date(endDate)), new Date());
  return remainingDays > 0 ? remainingDays : 0
}

export const formatDate = (date, pattern) => {
  if (date) return format(parseJSON(date), pattern || 'MMM dd, yyyy hh:mm a');
}

export const getTimeZone = () => {
  const d = new Date();
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });
  return dtf.formatToParts(d).find((part) => part.type === 'timeZoneName').value;
}

export const parseDate = (date) => {
  return date ? parseJSON(date) : date;
}

export const parseDateWithFormat = (date, format) => {
  if(date instanceof Date){
    return date;
  }

  if(date){
    return parse(date, format || 'yyyy-MM-dd', new Date());
  }

  return date;
}

/**
 * Parse and format date without timezone.
 * @param {Date} date
 * @param {String} inputFormat - Input date string format.
 *  default value: 'yyyy-MM-dd'
 * @param {String} outputFormat - Output date format.
 *  default value: 'MMM dd, yyyy'
 * @returns {String, undefined}
 */
export const formatDateWithoutTimezone = (date, inputFormat, outputFormat) => {
  if (date) {
    return format(
      parseDateWithoutTimezone(date, inputFormat),
      outputFormat || 'MMM dd, yyyy'
    );
  }
}

/**
 * Parse date without timezone.
 * @param {Date} date
 * @param {String} format - Input date string format.
 *  default value: 'yyyy-MM-dd'
 * @returns {Date}
 */
export const parseDateWithoutTimezone = (date, format) => {
  if (date) {
    return parse(date, format || 'yyyy-MM-dd', new Date());
  }
}

/**
 * Time distance from now
 * @param {Date} date
 * @returns {String}
 */
export const distanceFromNow = (date, isClosingSoon = true) => {
  if (!date) { return '' }

  let toDate = typeof date == 'string' ? new Date(date) : date
  let fromDate = new Date();

  if (fromDate > toDate) {
    [fromDate, toDate] = [toDate, fromDate];
  }

  const diffInHours = differenceInHours(toDate, fromDate)
  const diffInMinutes = differenceInMinutes(toDate, fromDate);
  const days = Math.floor(diffInHours / 24);
  const minutes = diffInMinutes % 60;
  const hours = diffInHours % 24;
  let text = ''

  if (days > 0) {
    text = `${days} ${days > 1 ? "days" : "day"} `;
  }

  if (hours > 0 && isClosingSoon) {
    text = `${text} ${hours} h `;
  }

  if (days === 0 && minutes > 0) {
    text = `${text} ${minutes} m `;
  }

  return text;
}

/**
 * Time distance from now
 * @param {Date} date
 * @returns {Number}
 */
export const diffYearsFromNow = (date) => {
  if (date) {
    return differenceInYears(new Date(), date)
  }
}