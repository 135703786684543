import React from 'react'

export default function Terms({ preoffer }) {
  const isSecondary = preoffer.category === "secondary";
  if (isSecondary) return (
    <div>
      <div className="bordered-item">
        <p>I, (the “Investor”) understand that:</p>
      </div>
      <div className="bordered-item term-item">
        <p>1. The information presented here may not be complete or final, and that all final documentation and information will be presented at the time of closing.</p>
      </div>
      <div className="bordered-item term-item">
        <p>2. To secure my allocation and finalize my investment, I will need to complete the investment process once {preoffer?.company?.name ?? "the"} deal is officially confirmed and documents are finalized.</p>
      </div>
    </div>
  )
  if (!isSecondary) return (
    <div>
      <div className="bordered-item">
        <p>I, (the “Investor”) understand that:</p>
      </div>
      <div className="bordered-item term-item">
        <p>1. Indicating interest is non-binding, which means no money is sent to the company at this time.</p>
      </div>
      <div className="bordered-item term-item">
        <p>2. Once {preoffer.company.name ?? "the offer"} is officially listed I will need to complete the investment process.</p>
      </div>
    </div>
  )
}