import React from 'react';
import './DrawerToggleButton.css';
import { MdMenu } from 'react-icons/md'
import { IconContext } from "react-icons";

const DrawerToggleButton = ({drawerClickHandler}) => {
  return(
    <button onClick={drawerClickHandler} className="toggle-button-drawer">
      <IconContext.Provider value={{ size: '20px'}}>
        <div>
          <MdMenu className="nav-menu-stack" />
        </div>
      </IconContext.Provider>
    </button>
  )
}

export default DrawerToggleButton;