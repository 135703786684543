export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const DEFAULT_PAGE_SIZE = 25
export const AUTH_TOKEN = "AUTH_TOKEN";
export const AUTHENTICATED_ID = "AUTHENTICATED_ID";
export const AUTHENTICATED_IS_ADMIN = "AUTHENTICATED_IS_ADMIN";

export const DEAL_TYPE_DEBT = "Debt";
export const DEAL_TYPE_EQUITY = "Equity";

export const EDIT_OFFER_INFO = "EDIT_OFFER_INFO";
export const EDIT_OFFER_OVERVIEW = "EDIT_OFFER_OVERVIEW";
export const EDIT_OFFER_DOCUMENTS = "EDIT_OFFER_DOCUMENTS";
export const EDIT_OFFER_SUB_DOC = "EDIT_OFFER_SUB_DOC";

export const AML_CONTACT_ISSUER_STATUS = 'pending';
export const AML_PENDING_STATUS = 'pending';
export const AML_CLEARED_STATUS = 'cleared';

export const AFFILIATION_LABELS = [
  {label: 'Employee', value: 'employee'},
  {label: 'Issuer', value: 'issuer'},
  {label: 'Founder', value: 'founder'},
  {label: 'Investor', value: 'investor'},
  {label: 'Promoter', value: 'promoter'},
  {label: 'Affiliate', value: 'affiliate'},
]
export const EDT_TIMEZONE_STRING = 'America/New_York';

export const INVESTOR_TYPE = {
  retail: 'retail',
  accredited: 'accredited'
};

export const OFFER_STATUS_CONSTANTS = {
  DRAFT: "draft",
  OFFER_CREATED: "offering_created",
  ESCROW_AGREEMENT_CREATED: "escrow_agreement_created",
  ESCROW_AGREEMENT_SIGNED: "escrow_agreement_signed",
  TECH_SERVICE_AGREEMENT_CREATED: "tech_services_agreement_created",
  ESCROW_SERVICE_AGREEMENT_CREATED: "escrow_service_application_created",
  PENDING_ESCROW: "pending_escrow",
  PAUSED: "paused",
  CREATED: "created",
  LIVE: "live",
  CLOSED: "closed",
  INACTIVE: "inactive"
}

export const OFFER_TYPE = {
  reg_cf: 'RegCf',
  reg_d506b: 'RegD506b',
  labels: {
    'RegCf': 'Reg CF',
    'RegD506b': 'Reg D506b'
  },
  investor_direct: 'direct',
  investor_spv: 'spv',
  investor_direct_and_spv: 'direct_and_spv',
  spv_standard_3c1: 'standard_3c1'
}

export const AML_STATUSES = {
  contact_issuer: AML_CONTACT_ISSUER_STATUS,
  pending: AML_PENDING_STATUS,
  cleared: AML_CLEARED_STATUS
}

export const PUSHER_CONFIG = {
  app_key: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
}

export const INVESTMENT_STATUSES = {
  NOT_RECEIVED: "not_received",
  RECEIVED: "received",
  FUNDED: "funded",
  PENDING: "pending",
  SETTLED: "settled",
  VOIDED: "voided",
  CANCELLED: "cancelled",
  REFUNDED: "refunded",
};
export const WIRE_TRANSFER_FEES = {
  USA : 25,
  INTERNATIONAL: 45,
}

export const PO_BOX_REGEX = /\bP(ost||ostal)?([ \.]*(O||0)(ffice)?)?([ \.]*Box)?\b/i;

export const RESTRICTED_STATES = {
  'CA': ['ON'],
  'US': [],
  'GB': [],
}

export const OAUTH_CONFIG = {
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  linkedin_client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID
}

export const PREOFFER_TYPE = {
  reg_cf: 'RegCf',
  reg_d: 'RegD',
}
