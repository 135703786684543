import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import { OfferPreviewCardsArray, OfferCardPreviewRow } from "./components/OfferCardPreview";
import { runGAEvent } from "./components/Tracking";
import { usePreofferList, useRaisePetitionList } from "investor/queries";
import { useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import OfferCard from "./components/OfferCard";
import RegD506bOfferCard from "./components/Offer/RegD506bOffer.Card";
import PreofferCard from "./components/PreOfferCard";
import RaisePetitionCard from "../investor/features/raise_petition/RaisePetition.Card";
import LaptopLaunch from "../assets/images/icons/laptop-launch-wht.svg";
import HorizontalRoutedTabs from "../admin/components/HorizontalRoutedTabs";
import OfferSectionLoader from "../components/OfferSectionLoader";
import OfferListLoader from "../admin/components/shared/OfferListLoader";
import { FaLock, FaUnlock, FaLockOpen } from "react-icons/fa";
import { OFFER_TYPE } from "../config/constants";
import { useUser } from "../redux/user";
import { useOfferList } from "../investor/queries";
import ReactTooltip from 'react-tooltip'

const OffersListingBanner = ({ title }) => {
  return (
    <div className="offers-page-banner">
      <div className="container pt-5">
        <div className="d-flex align-items-center flex-wrap">
          <h1 className="banner-title" style={{ margin: 0 }}>
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};

const LiveOffersSection = ({ liveItems, loading, regCFPlaceHolderItems }) => {

  if (loading) {return  (
    <div className="container pt-5">
    <OfferSectionHeader loading={loading} />
    </div>
  )}

  return (
    <div className="container pt-5">
        <div>
          <OfferSectionHeader
            title="Deals"
            description="Companies raising funds."
            count={getOfferCount(liveItems.length)}
            loading={loading}
          />
          <div className="card-container ">
            {liveItems.length >= 1 ? liveItems : <OfferCardPreviewRow />}
            {liveItems.length < 3 && regCFPlaceHolderItems}
          </div>
        </div>
    </div>
  );
};

const getOfferCount = (...nums) => {
  let numbersArr = [...nums];
  let count = 0;
  let reducer = function (accumlator, item) {
    return accumlator + item;
  };
  const sum = numbersArr.reduce(reducer, count);
  return sum;
};

const OfferSectionHeader = ({ title, description, loading, count }) => {
  let showCount = count >= 3 && !loading;
  if (loading)
    return <OfferSectionLoader />
  return <div className="live-offers-promo mb-4">
    <h2
      className="weight-900"
      style={{ fontSize: "30px", marginBottom: "0px" }}
    >
      {title}{" "}
      {showCount && (
        <span className="weight-900 montserrat" style={{ color: "#CCC" }}>
          {count}
        </span>
      )}
    </h2>
    <span className="poppins weight-400" style={{ color: "#888" }}>
      {description}
    </span>
  </div>
};

const AllOffersSection = ({
  isPrivate = false,
  liveItems,
  preLaunches,
  raisePetitions,
  closedItems,
  placeholderItems,
  prelaunchPlaceHolderItems,
  loading,
}) => {

  let totalItems = liveItems.length + preLaunches.length +  raisePetitions.length
  let content;

  if (!loading && (liveItems.length >= 1 ||
    preLaunches.length >= 1 ||
    raisePetitions.length >= 1 ||
    placeholderItems.length >= 1) ) {
      content = <div className="card-container">
      {liveItems}
      {preLaunches}
      {raisePetitions}
      {!isPrivate && <OfferCardPreviewRow numberOfCardsToShow={3 - totalItems}  />}
      {isPrivate && placeholderItems}
    </div>
    } else (
      content = <div className="card-container">
      {!isPrivate && <OfferPreviewCardsArray showName={false} />}
      {isPrivate && placeholderItems}
    </div>
    )

  return (
    <div className="container">
      <div className="mt-5">
      {closedItems.length >= 1 ? (
        <div className="mt-5">
          <OfferSectionHeader
            title="Funded Offers"
            description="Companies that have successfully raised funds."
            count={getOfferCount(closedItems.length)}
            loading={loading}
          />
          <div className="card-container ">{closedItems}</div>
        </div>
      ) : null}        
        <div class="mt-5">
          <OfferSectionHeader
            title="All"
            description={isPrivate ? "Space opportunities for Accredited Investors." : "Publicly available space opportunities."}
            count={getOfferCount(
              liveItems.length,
              preLaunches.length,
              raisePetitions.length
            )}
            loading={loading}
          />
          {content}
        </div>
      </div>
    </div>
  );
};

const PrelaunchesSection = ({ preLaunches, loading, prelaunchPlaceHolderItems }) => {
  return (
    <div className="container">
      <div className="mt-5">
        <OfferSectionHeader
          title="Pre-launches"
          description="Companies collecting interest ahead of their launch."
          count={getOfferCount(preLaunches.length)}
          loading={loading}
        />
        <div className="card-container ">{preLaunches.length >= 1 ? preLaunches : <OfferCardPreviewRow key="preoffer-card-preview-row"/>}
        {preLaunches.length < 3 && prelaunchPlaceHolderItems}</div>
      </div>
    </div>
  );
};

const FundedOffersSection = ({ fundedItems, loading }) => {
  return (
    <div className="container">
      <div className="mt-5">
        <OfferSectionHeader
          title="Funded"
          description="Companies that have successfully raised funds."
          count={getOfferCount(fundedItems.length)}
          loading={loading}
        />
        <div className="card-container ">{fundedItems}</div>
      </div>
    </div>
  );
};

const CommingSoonOffersSection = () => {
  return (
    <div className="container pt-5">
      <OfferSectionHeader
        title="Coming Soon"
        description="Sneak peek at the type of companies launching soon."
        loading={false}
      />
      <div className="card-container">
        <OfferPreviewCardsArray />
      </div>
    </div>
  );
};

const RaisePetitionsSection = ({ raisePetitions, loading }) => {
  return (
    <div className="container">
      <div className="mt-5">
        <OfferSectionHeader
          title="Petitions"
          description="Companies our community wants to fundraise on Spaced Ventures."
          count={getOfferCount(raisePetitions.length)}
          loading={loading}
        />
        <div className="card-container ">{raisePetitions.length >= 1 ? raisePetitions : <OfferCardPreviewRow />}
        </div>
      </div>
    </div>
  );
};

function tooltipAttestTextHelper(userLoggedIn = false,userIsAccredited = false){
  if (userLoggedIn && userIsAccredited) { return "Available exclusively to Accredited Investors." }
  if (userLoggedIn && !userIsAccredited) { return "Only available to Accredited Investors. Self-attest in Settings -> Investor Info." }
  if (!userLoggedIn) { return "Only available to Accredited Investors. Login to self-attest or sign up." }
  return "Only available to Accredited Investors."
}


function OfferTypeTabs({ offerVisibility, setOfferVisibility }) {
  const user = useUser();
  const privateTabDisabled = user?.investor_type !== "accredited";
  const userLoggedIn = user.authenticated;
  const userIsAccredited = user?.investor_type === "accredited";
  const showUnlocked = offerVisibility === 'public' ? <FaUnlock className="mr-2 align-baseline" /> : <FaLockOpen className="mr-2 align-baseline" />

  return (
    <div className="header-tab-select-container pb-3">
      <div className="container">
        <div className="btn-group btn-group-toggle">
          <label className={`btn poppings fs-18 ${offerVisibility === 'public' ? 'active' : ''}`}>
            <input type="radio" name="offer-visibility" id="public-offer" defaultChecked onClick={() => setOfferVisibility('public')} /> <span className="private-public-text">PUBLIC DEALS</span>
          </label>
          <label data-tip={tooltipAttestTextHelper(userLoggedIn,userIsAccredited)} data-for='private-offer-tip' className={`btn poppings fs-18 ${privateTabDisabled ? 'disabled cursor-disabled' : (offerVisibility === 'private' ? 'active' : '')}`}>
            <input type="radio" name="offer-visibility" id="private-offer" disabled={privateTabDisabled} onClick={() => setOfferVisibility('private')} />
            {privateTabDisabled ? <FaLock className="mr-2 align-baseline" /> :
              showUnlocked}<span className="private-public-text">PRIVATE DEALS</span>
          </label>
          <ReactTooltip id="private-offer-tip" place="right" type="light" effect="solid" className="spaced-tooltip ml-3 poppins" />
        </div>
      </div>
    </div>
  )
}

function filterOffer(offers, visibility) {
  const type = visibility === 'private' ? OFFER_TYPE.reg_d506b : OFFER_TYPE.reg_cf;

  return offers.filter(o => o.type === type);
}

function PublicOffers({ loading, offers, preoffers, raisePetitions }) {
  let { path, url } = useRouteMatch();

  const offerVisibility = 'public';
  const liveItems = [];
  const closedItems = [];
  const preLaunches = [];
  const prelaunchPlaceHolderItems = []
  const regCFPlaceHolderItems = []
  const raisePetitionsRenders = [];
  const liveRegCFPreOffersLength = preoffers?.live?.filter((item) => item.type === 'RegCf').length
  const liveRegCFOffersLength = offers?.live?.filter((item) => item.type === 'RegCf').length

  if (offers?.live) {
    const liveOffers = filterOffer(offers.live, offerVisibility).sort((o1, o2) => (o1.live_event ? -1 : 1));
    liveOffers.forEach((offer) => {
      liveItems.push(
        <OfferCard
          key={`offer-${offer.id}`}
          offer={offer}
          onClick={() =>
            runGAEvent(`${offer?.company?.name} offer card`, "clicked", "card")
          }
        />
      );
    });
  }

  if (preoffers?.live) {
    preoffers.live.filter(po => po.type === 'RegCf').forEach((preoffer) => {
      preLaunches.push(
        <PreofferCard
          key={`preoffer-${preoffer.id}`}
          preoffer={preoffer}
          onClick={() =>
            runGAEvent(
              `${preoffer?.company.name} preoffer card`,
              "clicked",
              "card"
            )
          }
        />
      );
    });
  }

  if (liveRegCFPreOffersLength < 3 && liveRegCFPreOffersLength >= 1) {
    prelaunchPlaceHolderItems.push(<OfferCardPreviewRow numberOfCardsToShow={3 - liveRegCFPreOffersLength}/>)
  }

  if (liveRegCFOffersLength < 3 && liveRegCFOffersLength >= 1) {
    regCFPlaceHolderItems.push(<OfferCardPreviewRow numberOfCardsToShow={3 - liveRegCFOffersLength} />)
  }


  if (offers?.closed) {
    const closedOffers = filterOffer(offers.closed, offerVisibility).sort((o1, o2) => new Date(o2.deal.close_date) - new Date(o1.deal.close_date));
    closedOffers.forEach((offer) => {
      closedItems.push(
        <OfferCard
          key={`offer-${offer.id}`}
          offer={offer}
          onClick={() =>
            runGAEvent(`${offer?.company?.name} offer card`, "clicked", "card")
          }
        />
      );
    });
  }

  if (raisePetitions?.data) {
    raisePetitions.data.forEach((rp) =>
      raisePetitionsRenders.push(
        <RaisePetitionCard
          key={rp.id}
          raisePetition={rp}
          onClick={() =>
            runGAEvent(
              `${rp?.company.name} raise-petition card`,
              "clicked",
              "card"
            )
          }
        />
      )
    );
  }

  const offerFilters = React.useMemo(() => {
    const offeringTabs = [
      {
        tabId: "all",
        title: "ALL",
        targetId: "all",
        pathName: "all",
        decoratorClass: "",
        content: (
          <AllOffersSection
            liveItems={liveItems}
            preLaunches={preLaunches}
            raisePetitions={raisePetitionsRenders}
            closedItems={closedItems}
            placeholderItems={[]}
            loading={loading}
          />
        ),
      },
      {
        tabId: "live-offers",
        title: "DEALS",
        targetId: "live-offers",
        pathName: "live-offers",
        decoratorClass: "",
        content: <LiveOffersSection liveItems={liveItems} loading={loading} regCFPlaceHolderItems={regCFPlaceHolderItems} />,
      },
      {
        tabId: "pre-launches",
        title: "PRE-LAUNCHES",
        targetId: "pre-launches",
        pathName: "pre-launches",
        decoratorClass: "",
        content: (
          <PrelaunchesSection
            preLaunches={preLaunches}
            loading={loading}
            prelaunchPlaceHolderItems={prelaunchPlaceHolderItems}
          />
        ),
      },
      {
        tabId: "raise-petitions",
        title: "PETITIONS",
        targetId: "raise-petitions",
        pathName: "raise-petitions",
        decoratorClass: "",
        content: (
          <RaisePetitionsSection
            raisePetitions={raisePetitionsRenders}
            loading={false}
          />
        ),
      },
      {
        tabId: "funded",
        title: "FUNDED",
        targetId: "funded",
        pathName: "funded",
        decoratorClass: "",
        content: (
          <FundedOffersSection fundedItems={closedItems} loading={loading} />
        ),
      },
      {
        tabId: "coming-soon",
        title: "COMING SOON",
        targetId: "coming-soon",
        pathName: "coming-soon",
        content: <CommingSoonOffersSection />,
      },
    ];

    return offeringTabs;
  }, [closedItems, liveItems, preLaunches]);

  return (
    <div className="offer-page-tabs-container">
      <HorizontalRoutedTabs
        basePath={path}
        baseUrl={url}
        tabs={offerFilters}
        tabIdAccessor={"tabId"}
        targetPanelIdAccessor={"targetId"}
        defaultTabId="all"
      />
    </div>
  )
}

function PrivateOffers({ loading, offers, preoffers }) {
  let { path, url } = useRouteMatch();
  const liveItems = []
  const placeholderItems = []
  const prelaunchPlaceHolderItems = []
  const liveOffersLength = filterOffer(offers.live, 'private').length;
  const liveRegDPreOffersLength =  preoffers?.live?.filter((item) => item.type === "RegD").length;
  const totalCards = liveOffersLength + liveRegDPreOffersLength;

  if (offers?.live && liveOffersLength >= 1) {
    const liveOffers = filterOffer(offers.live, 'private');

    liveOffers.forEach((offer) => {
      liveItems.push(
        <RegD506bOfferCard
          key={`offer-${offer.id}`}
          offer={offer}
          onClick={() =>
            runGAEvent(`${offer?.company?.name} offer card`, "clicked", "card")
          }
        />
      );
    });
  }

  if (totalCards < 3 ) {
    placeholderItems.push(<OfferCardPreviewRow numberOfCardsToShow={3 - totalCards} />)
  }

  const preLaunches = [];

  if (preoffers?.live) {
    preoffers.live.filter(po => po.type === 'RegD').forEach((preoffer) => {
      preLaunches.push(
        <PreofferCard
          key={`preoffer-${preoffer.id}`}
          preoffer={preoffer}
          onClick={() =>
            runGAEvent(
              `${preoffer?.company.name} preoffer card`,
              "clicked",
              "card"
            )
          }
        />
      );
    });
  }

  if (liveRegDPreOffersLength < 3 && liveRegDPreOffersLength >= 1) {
    prelaunchPlaceHolderItems.push(<OfferCardPreviewRow numberOfCardsToShow={3 - liveRegDPreOffersLength} key="preoffer-preview-row"/>)
  }

  const offeringTabs = [{
    tabId: "all",
    title: "ALL",
    targetId: "all",
    pathName: "all",
    decoratorClass: "",
    content: (
      <AllOffersSection
        isPrivate={true}
        liveItems={liveItems}
        placeholderItems={placeholderItems}
        preLaunches={preLaunches}
        raisePetitions={[]}
        closedItems={[]}
        loading={loading}
      />
    ),
  }, {
    tabId: "pre-launches",
    title: "PRE-LAUNCHES",
    targetId: "pre-launches",
    pathName: "pre-launches",
    decoratorClass: "",
    content: (
      <PrelaunchesSection
        preLaunches={preLaunches}
        loading={loading}
        prelaunchPlaceHolderItems={prelaunchPlaceHolderItems}
      />
    ),
  }];

  return (
    <div className="offer-page-tabs-container">
      <HorizontalRoutedTabs
        basePath={path}
        baseUrl={url}
        tabs={offeringTabs}
        tabIdAccessor={"tabId"}
        targetPanelIdAccessor={"targetId"}
        defaultTabId="all"
      />
    </div>
  )
}

export default function OfferList(props) {
  const { data: offers, isLoading: isLoadingOffers } = useOfferList();
  const { data: preoffers, isLoading: isLoadingPreoffers } = usePreofferList();
  const { data: raisePetitions, isLoading: isLoadingRaisePetitions } =
    useRaisePetitionList();
  const [offerVisibility, setOfferVisibility] = useState('public');
  const loading = isLoadingOffers || isLoadingPreoffers || isLoadingRaisePetitions;

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading Offers..."
    >
      <Helmet>
        <title>Live Offers</title>
        <meta
          name="description"
          content="Companies currently raising funds on Spaced Ventures. All our startups are reviewed carefully and either operate or plan to operate in the aerospace industry. Our platform gives direct access to investment deals."
        />
      </Helmet>
      <div className="one-section-pg">
        <OffersListingBanner title="Invest in Space" />
        <OfferTypeTabs offerVisibility={offerVisibility} setOfferVisibility={setOfferVisibility} />
        {offerVisibility === 'public' && <PublicOffers loading={loading} offers={offers} preoffers={preoffers} raisePetitions={raisePetitions} />}
        {offerVisibility === 'private' && <PrivateOffers loading={loading} offers={offers} preoffers={preoffers}/>}
        <OfferListLoader loading={loading} />
      </div>
    </LoadingOverlay>
  );
}
