

import React from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import FundingProgressBar from './FundingProgressBar';
import InvestmentButton from './InvestmentButton';
import Investor from '../Investor';
import Customer from '../Customer';
import { subDays } from 'date-fns'
import {
  FormattedNumberWithSymbol
} from '../../../../components/AmountWithCurrency';
import RemainingTimeBadge from '../../../../components/RemainingTimeBadge';
import CompanySocialLinks from '../CompanySocialLinks';
import { OFFER_TYPE } from '../../../../config/constants';
import { investmentLimitByInvestorType } from '../../../../jsUtils/offerUtils';
import regDIcon from "../../../../assets/images/logos/icon/regd-logo.svg";
import { toTitlecase } from '../../../../jsUtils/stringUtils';

function NotableInvestors({ investors }) {
  if (!investors || investors.length === 0) {
    return null;
  }

  return (
    <div className="offer-investors">
      <span className="offer-investors-title">{investors.length >= 2 ? "Notable Investors" : "Notable Investor"}</span>
      {investors.map((investor, i) => (<Investor investor={investor} key={i} />))}
    </div>
  )
}

function Customers({ customers }) {
  if (!customers || customers.length === 0) {
    return null;
  }

  return (
    <div className="offer-customers">
      <span className="offer-investors-title">Customers</span>
      <div className="offer-customers-box">
        {customers.map((customer, i) => (<Customer customer={customer} key={i} />))}
      </div>
    </div>
  )
}

function CompanyTagline({ company }) {
  if(!company.tagline){
    return null;
  }

  return (
    <div className='container offer-view-banner-tagline-container'>
      <div className='col-md-10 px-sm-0 px-3'>
        <h5 className='company-tagline'>{company.tagline}</h5>
      </div>
    </div>
  )
}

function SpvInvestmentSpots({ offer }){
  const investmentLimit = investmentLimitByInvestorType(offer).find(v => v.type === 'spv');

  if(!investmentLimit){
    return null;
  }

  return (
    <div className={`fs-14 weight-400 ${ offer.investor_type === OFFER_TYPE.investor_direct_and_spv ? 'col-6 border-right border-secondary' : 'col-12'}`}>
      <div className='d-block'>{investmentLimit.remainingSpots} spots remaining in the Special Purpose Vehicle (SPV)</div>
      <div className='color-grey'>
        <FormattedNumberWithSymbol number={investmentLimit.minAmount} numberFormattingOpts={{stripZeros: true}} /> min. investment
      </div>
    </div>
  )
}

function DirectInvestmentSpots({ offer }){
  if(offer.investor_type === OFFER_TYPE.investor_spv){
    return null;
  }

  const investmentLimit = investmentLimitByInvestorType(offer).find(v => v.type === 'direct');

  if(!investmentLimit){
    return null;
  }

  return (
    <div className={`fs-14 weight-400 ${ offer.investor_type === OFFER_TYPE.investor_direct_and_spv ? 'col-6' : 'col-12'}`}>
      <div className='d-block'>{investmentLimit.remainingSpots} spots remaining as a Direct Investment</div>
      <div className='color-grey'>
        <FormattedNumberWithSymbol number={investmentLimit.minAmount ? investmentLimit.minAmount : offer.deal.minimum_investment} numberFormattingOpts={{stripZeros: true}} /> min. investment
      </div>
    </div>
  )
}

export default function RegD506bOffer({ offer, user, isAdminUser }) {
  const userLoggedIn = user.hasOwnProperty("id");
  const company = offer.company;
  const customers = offer.customers;
  const deal = offer.deal;
  const notableInvestors = offer.pitch?.notable_investors;
  const offerCloseDate = new Date(deal.close_date);
  const today = new Date();
  const isClosingSoon = subDays(offerCloseDate, 10) <= today && offerCloseDate >= today;

  return (
    <div className='regd-offer'>
      <div className='container offer-view-banner-title-container'>
        <div className="px-sm-0 px-3">
          {isClosingSoon && offer.status !== 'closed' && <div className='offerView-badges mb-2'>
            <RemainingTimeBadge closeDate={offerCloseDate} prefix="Closing Soon:" />
          </div>}
          <div className="d-flex align-items-start flex-wrap pb-3">
            <div className="d-flex flex-wrap align-items-center">
              <img src={regDIcon} alt="regd offer" className='regd-offer-logo'/>
              <h1 className="reg-d-offername mr-3">{offer.company.name}</h1>
            </div>
            {company.url && <div className="d-flex align-items-start"><a className="social-icon external-link-reg-d f-white" target="_blank" rel="noopener noreferrer" href={offer.company.url}>{<FaExternalLinkAlt />}</a></div>}
          </div>  
        </div>
      </div>
      <CompanyTagline company={company} />
      <div className="container offer-view-banner-content-container">
        <div className="deal-info row">
          <div className="col-lg-6 pl-lg-4 deal-info-col1 mt-lg-0 mt-4">
            <div className="w-100 mb-3">
              <FundingProgressBar offer={offer} />
              <div className='poppins mt-3'>Broker Dealer: {toTitlecase(offer.broker_dealer)}</div>
              <div className='dotted-separator my-3'/>
              <div className="w-100 offer-social-icons mt-2 d-flex justify-content-between align-items-end">
                <CompanySocialLinks showSocialIcons={true} theme={"light"} company={company} following_company={offer.following_company} />
              </div>
              <div className='row investment-btn-container bg-black mt-5 p-3'>
                <SpvInvestmentSpots offer={offer} />
                <DirectInvestmentSpots offer={offer} />
                <div className='col-12'>
                  <InvestmentButton offer={offer} user={user} />
                </div>
              </div>
              <p className="invested-amount">
                {
                  !isAdminUser && userLoggedIn && offer.investments?.length > 0 &&
                  <>
                    You’ve invested <NavLink to={'/account/investments'} className="underline text-white"><FormattedNumberWithSymbol number={offer.total_investment} /></NavLink>.&nbsp;
                  </>
                }
              </p>
            </div>
          </div>
          <div className="col-lg-6 pr-lg-4 deal-info-col2">
            <NotableInvestors investors={notableInvestors} />
            <Customers customers={customers} />
          </div>
        </div>
      </div>
    </div>
  );
}
