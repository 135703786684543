import React, { useEffect } from 'react'
import { PageViewFB } from './components/Tracking/facebook';
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import './mediakit.scss';

// Logos
import fullLogoBlack from "../assets/images/logos/full/spaced-logo-full-black.svg"
import fullLogoWhite from "../assets/images/logos/full/spaced-logo-full-white.svg"
import stackedBlack from "../assets/images/logos/vertical/sv-vertical-black.svg"
import stackedWhite from "../assets/images/logos/vertical/sv-vertical-white.svg"
import iconBlack from "../assets/images/logos/icon/spaced-icon-black.svg"
import iconWhite from "../assets/images/logos/icon/spaced-icon-white.svg"
import circleIconBlack from "../assets/images/logos/icon/SV-Orbital-dark.png"
import circleIconWhite from "../assets/images/logos/icon/SV-Orbital-white.png"

const logoData = [
  {
    header: "Full Logo Black",
    link: "https://drive.google.com/drive/folders/1t0Xz8hiAWkHJysOWDderY9BRfTCLxB5r",
    img: fullLogoBlack,
    logoWidth: "100%",
    backgroundColor: "#F5F5F5"
  },
  {
    header: "Full Logo White",
    link: "https://drive.google.com/drive/folders/1t0Xz8hiAWkHJysOWDderY9BRfTCLxB5r",
    img: fullLogoWhite,
    logoWidth: "100%",
    backgroundColor: "#1a1d21"
  },
  {
    header: "Stacked Logo Black",
    link: "https://drive.google.com/drive/folders/1dv_AnB3xCaOy4kM1ZayfSM7DdEWSo7iH",
    img: stackedBlack,
    logoWidth: "70%",
    backgroundColor: "#F5F5F5"
  },
  {
    header: "Stacked Logo White",
    link: "https://drive.google.com/drive/folders/1dv_AnB3xCaOy4kM1ZayfSM7DdEWSo7iH",
    img: stackedWhite,
    logoWidth: "70%",
    backgroundColor: "#1a1d21"
  },
  {
    header: "Icon Black",
    link: "https://drive.google.com/drive/folders/1eSUhx_mZ6SyokInJT7tHkaaWlSrrw8AP",
    img: iconBlack,
    logoWidth: "50%",
    backgroundColor: "#F5F5F5"
  },
  {
    header: "Icon White",
    link: "https://drive.google.com/drive/folders/1eSUhx_mZ6SyokInJT7tHkaaWlSrrw8AP",
    img: iconWhite,
    logoWidth: "50%",
    backgroundColor: "#1a1d21"
  },
  {
    header: "Circle Icon Black",
    link: "https://drive.google.com/drive/u/1/folders/1Om0jD86PmSFGL_g_CUMxfHX20FFyCC1d",
    img: circleIconBlack,
    logoWidth: "30%",
    backgroundColor: "#F5F5F5"
  },
  {
    header: "Circle Icon White",
    link: "https://drive.google.com/drive/u/1/folders/1Om0jD86PmSFGL_g_CUMxfHX20FFyCC1d",
    img: circleIconWhite,
    logoWidth: "30%",
    backgroundColor: "#1a1d21"
  },                   

]


function MediaBanner () {
  return (
    <>
    <div className="media-banner d-flex justify-content-start align-items-end">
      <div className="container ">
        <h1 className="montserrat text-uppercase weight-900 fs-1 pb-5 f-white">Media Kit</h1>
      </div>
    </div>
    </>
  )
}

function LogosSection () {
  return (
    <div className="container main-section">
      <h2 className="montserrat fs-5 weight-800">Logos</h2>
      <div className="mediakit-container m-t-30">
      {logoData.map((item) => {
        return <Logo header={item.header} link={item.link} img={item.img} logoWidth={item.logoWidth} backgroundColor={item.backgroundColor} />
      })}
      </div>
    </div>
  )
}

function Logo ({header,link,img, logoWidth, backgroundColor}) {
  return (
    <>
    <LogoCardStyles logoWidth={logoWidth} backgroundColor={backgroundColor}>
      <h3 className="poppins fs-14 weight-400">{header}</h3>
      <div className="mediakit-img-container">
        <img src={img} />
      </div>
      <a target="_blank" rel="noopener noreferrer" href={link}><ButtonStyles>Download</ButtonStyles></a>
    </LogoCardStyles>
    </>
  )
}

export default function MediaKit () {
  useEffect(() => {
    PageViewFB();    
  }, [])

  return (
    <>
      <Helmet>
        <title>Media Kit</title>
        <meta
          name="description"
          content="All of Spaced Ventures logos including full logo, stacked logo, and icons. All available in both dark and light colour schemes. Includes all assets for press and media purposes."
        />
      </Helmet>
      <div className="media-kit-page">
        <MediaBanner />
        <LogosSection />
      </div>
    </>
  )
}

const ButtonStyles = styled.button`
  width: 100%;
  border: 1px solid #000;
  padding: 10px 40px 10px ;
  background-color: transparent;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-weight: 600;
  border-radius: 2px;
  font-size: 16px;
  color: #000;
  transition: all 0.3s ease 0s;
  margin-top: 13px;

  @media (max-width: 600px) {
    width: 100%;
  }

	&:hover {
        color: #fff;
        background-color: #000;
        border: 1px solid #000;
        border-radius: 2px;
    }
`;


const LogoCardStyles = styled.div`
background-color: #fff;
/* height: 280px; */
box-shadow: 3px 5px 20px #00000029;
border-radius: 8px;
padding: 20px 20px;

    .mediakit-img-container {
      background-color: ${props => props.backgroundColor};
      padding: 0px 50px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: ${props => props.logoWidth};
      }
    }

`