import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, validateYupSchema, yupToFormErrors } from 'formik';
import NumberFormat from "react-number-format";
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import DateTimeField from '../../../components/Formik/DateTimeField'
import CurrencyInputField from '../../../components/Formik/CurrencyInputField'
import { DEAL_TYPE_EQUITY, DEAL_TYPE_DEBT } from '../../../config/constants';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { createOffer, updateOffer } from '../../pages/offer/queries';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import { history } from 'redux/helpers'
import ToastMessage from '../../../components/ToastMessage';

function isValidDealTypeAttr(value, context, dealTypeStr) {
  const dealType = context.resolve(Yup.ref('type'))
  if (dealType === dealTypeStr) {
    return value ? true : false;
  } else {
    return true;
  }
}
const today = new Date();
const minValueError = (text) =>
  `${text ? text : "value"} must be greater than 0`;

const DealInfoSchema = Yup.object().shape({
  name: Yup.string()
    .required('Offer name is required'),
  deal_attributes: Yup.object().shape({
      // minimum_goal: Yup.number()
      //   .moreThan(0, minValueError("offer minimum goal"))
      //   .test("", "", function (val) {
      //     const goal = this.resolve(Yup.ref("goal"));
      //     if (goal !== "" && goal > 0 && val > goal) {
      //       return this.resolve(
      //         this.createError({
      //           message:
      //             "offer minimum goal must be less than or equal to offer goal target",
      //         })
      //       );
      //     }
      //   })
      //   .required('offer minimum goal is required'),
      maximum_goal: Yup.number()
        .moreThan(0, minValueError("offer maximum goal"))
        .test("", "", function (val) {
          const goal = this.resolve(Yup.ref("goal"));
          if (goal !== "" && goal > 0 && val < goal) {
            return this.resolve(
              this.createError({
                message:
                  "offer maximum goal must be greater than or equal to offer goal target",
              })
            );
          }
        })
        .required('offer maximum goal is required'),
      goal: Yup.number()
        .moreThan(0, minValueError("offer target goal"))
        .test("", "", function (val) {
          // const minGoal = this.resolve(Yup.ref("minimum_goal"));
          const maxGoal = this.resolve(Yup.ref("maximum_goal"));

          // if (minGoal !== "" && minGoal > 0 && val < minGoal) {
          //   return this.resolve(
          //     this.createError({
          //       message:
          //         "offer goal target must be greater than or equal to offer minimum goal",
          //     })
          //   );
          // }
          if (maxGoal !== "" && maxGoal > 0 && val > maxGoal) {
            return this.resolve(
              this.createError({
                message:
                  "offer goal target must be less than or equal to offer maximum goal",
              })
            );
          }
        })
        .required('offer target goal is required'),
      minimum_investment: Yup.number()
        .moreThan(0, minValueError("minimum investment"))
        .test("", "", function (val) {
          const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
          if (maxInvestment !== "" && maxInvestment > 0 && val > maxInvestment) {
            return this.resolve(
              this.createError({
                message:
                  "minimum investment must be less than maximum investment",
              })
            );
          }
        })
        .required('minimum investment is required'),
      entity_minimum_investment: Yup.number()
        .moreThan(0, minValueError("entity minimum investment"))
        .test("", "", function (val) {
            const minInvestment = this.resolve(Yup.ref("minimum_investment"));
            if (minInvestment !== "" && minInvestment > 0 && val < minInvestment) {
              return this.resolve(
                this.createError({
                  message:
                  "entity minimum investment must be greater than minimum investment for the offer",
                })
              );
            }
            const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
            if (maxInvestment !== "" && maxInvestment > 0 && val > maxInvestment) {
              return this.resolve(
                this.createError({
                  message:
                  "entity minimum investment must be less than maximum investment for the offer",
                })
              );
            }
          })
          .required('entity minimum investment is required'),
      maximum_investment: Yup.number()
        .moreThan(0, minValueError("maximum investment"))
        .test("", "", function (val) {
          const minInvestment = this.resolve(Yup.ref("minimum_investment"));
          if (minInvestment !== "" && minInvestment > 0 && val < minInvestment) {
            return this.resolve(
              this.createError({
                message:
                  "maximum investment must be greater than minimum investment",
              })
            );
          }
        })
        .required('maximum investment is required'),
      minimum_international_investment: Yup.number()
        .moreThan(0, minValueError("minimum investment"))
        .test("", "", function (val) {
          const allowInternational = this.options.context.international_investors;
          if(allowInternational) {
            if(val === undefined)
              return this.resolve(
                this.createError({
                  message:
                    "minimum international investment is required",
                })
              );
            const minInvestment = this.resolve(Yup.ref("minimum_investment"));
            if (minInvestment !== "" && minInvestment > 0 && val <= minInvestment) {
              return this.resolve(
                this.createError({
                  message:
                    "minimum international investment must be greater than minimum investment for offer",
                })
              );
            }
            const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
            if (maxInvestment !== "" && maxInvestment > 0 && val > maxInvestment) {
              return this.resolve(
                this.createError({
                  message:
                  "maximum international investment must be less than or equal to maximum investment for offer",
                })
              );
            }
          }  else return true;
        }),
      entity_minimum_international_investment: Yup.number()
        .moreThan(0, minValueError("international entity minimum investment"))
        .test("", "", function (val) {
          const allowInternational = this.options.context.international_investors;
          if(allowInternational) {
            if(val === undefined)
              return this.resolve(
                this.createError({
                  message:
                    "minimum international entity investment is required",
                })
              );
            const minInternationalInvestment = this.resolve(Yup.ref("minimum_international_investment"));
            if (minInternationalInvestment !== "" && minInternationalInvestment > 0 && val < minInternationalInvestment) {
              return this.resolve(
                this.createError({
                  message:
                    "minimum international entity investment must be greater than minimum international investment for offer",
                })
              );
            }
            const entityMinInvestment = this.resolve(Yup.ref("entity_minimum_investment"));
            if (entityMinInvestment !== "" && entityMinInvestment > 0 && val <= entityMinInvestment) {
              return this.resolve(
                this.createError({
                  message:
                    "minimum international entity investment must be greater than minimum entity investment",
                })
              );
            }
            const maxInvestment = this.resolve(Yup.ref("maximum_investment"));
            if (maxInvestment !== "" && maxInvestment > 0 && val > maxInvestment) {
              return this.resolve(
                this.createError({
                  message:
                    "minimum international entity investment must be less than maximum investment for the offer",
                })
              );
            }
          } else return true;
        }),
      close_date: Yup.date()
        .required('closing date is required')
        .test("", "", function (value) {
          if (value < today)
            return this.resolve(
              this.createError({ message: "cannot set closing date in the past" })
            );
        }),
      type: Yup.string()
        .oneOf([DEAL_TYPE_DEBT, DEAL_TYPE_EQUITY])
        .required('deal type is required'),
      security_type: Yup.string().test(
        "",
        "security type is required",
        function (value) {
          const dealType = this.resolve(Yup.ref("type"));
          if (dealType === DEAL_TYPE_DEBT) {
            return value === "crowdsafe" || value === "convertible_debt";
          } else {
            return true;
          }
        }
      ),
      discount: Yup.number()
        .nullable()
        .test("", "discount is required", function (value) {
          return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
        }),
      valuation_cap: Yup.number()
        .nullable()
        .moreThan(0, minValueError("valuation cap"))
        .test("", "valuation cap is required", function (value) {
          return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
        }),
      maturity_date: Yup.date()
        .nullable(),
        // .test("", "maturity date is required", function (value) {
        //   return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
        // }),
      interest_rate: Yup.number()
        .nullable(),
        // .test("", "interest rate is required", function (value) {
        //   return isValidDealTypeAttr(value, this, DEAL_TYPE_DEBT);
        // }),
      valuation: Yup.number()
        .nullable()
        .moreThan(0, minValueError("valuation"))
        .test("", "valuation is required", function (value) {
          return isValidDealTypeAttr(value, this, DEAL_TYPE_EQUITY);
        }),
      price_per_share: Yup.number()
        .nullable()
        .moreThan(0, minValueError("price per share"))
        .test("", "price per share required", function (value) {
          return isValidDealTypeAttr(value, this, DEAL_TYPE_EQUITY);
        }),
      share_type: Yup.string()
        .nullable()
        .test("", "share type is required", function (value) {
            const dealType = this.resolve(Yup.ref("type"));
            if (dealType === DEAL_TYPE_EQUITY) {
              return value === "common" || value === "preferred" ? true : false;
            } else {
              return true;
            }
          }),
    }),
})

export default function OfferInfoOverview({ companySlug, offer, editable }) {
  const [dealType, setDealType] = useState('');
  const {deal = {}} = offer

  useEffect(() => {
    if (deal?.type) setDealType(deal?.type);
  }, [deal])

  if (deal.maturity_date)
    deal.maturity_date = new Date(deal.maturity_date).toISOString().split('T')[0]

  const createOfferMutation = useMutation(createOffer, {
    onSuccess: (newOffer) => {
      ToastMessage.success("Offer created successfully.")
      history.push(`/admin/offers/${newOffer.slug}/edit`)
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
  })

  const updateOfferMutation = useMutation(updateOffer, {
    onSuccess: () => {
      ToastMessage.success("Offer updated successfully.")
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
  });

  return (
    <div id="dealForm">
          <Formik
            enableReinitialize
            initialValues={{
              name: offer?.name || "",
              allow_credit_card: true, //offer?.allow_credit_card || false,
              unlisted: offer?.listed_at ? false : true,
              international_investors: offer?.international_investors || false,
              deal_attributes: {
                // minimum_goal: parseFloat(deal?.minimum_goal) || '',
                goal: parseFloat(deal?.goal) || '',
                maximum_goal: parseFloat(deal?.maximum_goal) || '',
                minimum_investment: parseFloat(deal?.minimum_investment) || '',
                entity_minimum_investment: parseFloat(deal?.entity_minimum_investment) || '',
                maximum_investment: parseFloat(deal?.maximum_investment) || '',
                minimum_international_investment : parseFloat(deal?.minimum_international_investment) || '',
                entity_minimum_international_investment: parseFloat(deal?.entity_minimum_international_investment) || '',
                close_date: deal?.close_date || '',
                valuation_cap: parseFloat(deal?.valuation_cap) || null,
                security_type: deal?.security_type || '',
                discount: parseFloat(deal?.discount) || null,
                maturity_date: deal?.maturity_date || null,
                interest_rate: parseFloat(deal?.interest_rate) || null,
                price_per_share: parseFloat(deal?.price_per_share) || null,
                share_type: deal?.share_type || '',
                type: deal?.type || '',
                valuation: parseFloat(deal?.valuation) || null
              }
            }}
            validate={(value) => {
              try {
                validateYupSchema(value, DealInfoSchema, true, value);
              } catch (err) {
                return yupToFormErrors(err);
              }
              return {};
            }}

            onSubmit={async values => {
                values.listed_at = values.unlisted ? null : (offer?.listed_at || new Date());

                if (offer?.id) {
                  values.deal_attributes.id = deal?.id;
                  updateOfferMutation.mutate({offer_slug: offer.slug, values})
                }
                else{
                  createOfferMutation.mutate({company_slug: companySlug, values})
                }
            }}
          >
            {({ values, touched, errors, isSubmitting, handleBlur, setFieldValue, setFieldTouched, handleChange }) => (
              <Form>
                <div className="deal-form-content">
                    <div className="row">
                      <div className="col">
                        <div className="form-label-group">
                          <Field
                            id="offer-name"
                            name="name"
                            className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                            placeholder="Offer Name*"
                          />
                          <label htmlFor="offer-name">Offer Name*</label>
                          <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="col-md-4">
                        <CurrencyInputField
                          id="minimum_goal"
                          name="deal_attributes.minimum_goal"
                          label="Offering goal min*"
                          placeholder="Offering goal min*"
                        />
                      </div> */}

                      <div className="col-md-6">
                        <CurrencyInputField
                          id="goal"
                          name="deal_attributes.goal"
                          label="Offering goal target*"
                          placeholder="Offering goal target*"
                        />
                      </div>

                      <div className="col-md-6">
                        <CurrencyInputField
                          id="maximum_goal"
                          name="deal_attributes.maximum_goal"
                          label="Offering goal max*"
                          placeholder="Offering goal max*"
                        />
                      </div>
                    </div>



                    <div className="row">
                      <div className="col-md-6">
                        <CurrencyInputField
                          id="minimum_investment"
                          name="deal_attributes.minimum_investment"
                          label="Investment minimum*"
                          placeholder="Investment minimum*"
                        />
                      </div>

                      <div className="col-md-6">
                        <CurrencyInputField
                          id="maximum_investment"
                          name="deal_attributes.maximum_investment"
                          label="Investment maximum*"
                          placeholder="Investment maximum*"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <CurrencyInputField
                          id="entity_minimum_investment"
                          name="deal_attributes.entity_minimum_investment"
                          label="Entity minimum investment*"
                          placeholder="Entity minimum investment*"
                        />
                      </div>
                    </div>


                    <div className="row">
                      <div className="col">
                        <div className="form-label-group react-datepicker-group">
                          <DateTimeField
                            isInValid={errors?.deal_attributes?.close_date && touched?.deal_attributes?.close_date}
                            errors={errors}
                            touched={touched}
                            name="deal_attributes.close_date"
                            initialValue={values?.deal_attributes?.close_date}
                          />
                        </div>
                      </div>
                    </div>



                    <div className="row">
                      <div className="col">
                        <div className="form-label-group">
                          <Field type="text"
                            id="type"
                            name="deal_attributes.type"
                            className={`form-control ${touched?.deal_attributes?.type && errors?.deal_attributes?.type ? "is-invalid" : ""}`}
                            placeholder="Deal type"
                            component="select"
                            onChange={(e) => { handleChange(e); setDealType(e.target.value) }}
                          >
                            <option value="">Deal type</option>
                            <option value={DEAL_TYPE_EQUITY}>Equity</option>
                            <option value={DEAL_TYPE_DEBT}>Debt</option>
                          </Field>
                          <label htmlFor="type">Deal type</label>
                          <ErrorMessage name="deal_attributes.type" component="div" className="invalid-feedback text-left" />
                        </div>
                      </div>
                    </div>


                  { dealType === DEAL_TYPE_EQUITY &&
                    <>

                        <strong><p className='montserrat'>Equity deal info</p></strong>
                        <div className="row">
                          <div className="col-md-6">
                            <CurrencyInputField
                              id="valuation"
                              name="deal_attributes.valuation"
                              label="Valuation*"
                              placeholder="Valuation*"
                            />
                          </div>

                          <div className="col-md-6">
                            <CurrencyInputField
                              id="price_per_share"
                              name="deal_attributes.price_per_share"
                              label="Price per share*"
                              placeholder="Price per share*"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-label-group">
                              <Field type="text"
                                id="share_type"
                                name="deal_attributes.share_type"
                                className={`form-control ${touched?.deal_attributes?.share_type && errors?.deal_attributes?.share_type ? "is-invalid" : ""}`}
                                placeholder="Share type*"
                                component="select"
                              >
                                <option value="">Share type*</option>
                                <option value="common">Common</option>
                                <option value="preferred">Preferred</option>
                              </Field>
                              <label htmlFor="share_type">Share type*</label>
                              <ErrorMessage name="deal_attributes.share_type" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>
                        </div>

                    </>
                  }

                  { dealType === DEAL_TYPE_DEBT &&
                    <>

                        <strong><p>Convertible debt deal info</p></strong>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-label-group">
                              <Field
                                type="number"
                                id="discount"
                                name="deal_attributes.discount"
                                className={`form-control ${touched?.deal_attributes?.discount && errors?.deal_attributes?.discount ? "is-invalid" : ""}`}
                                placeholder="Discount*"
                              />
                              <label htmlFor="discount">Discount*</label>
                              <ErrorMessage name="deal_attributes.discount" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <CurrencyInputField
                              id="valuation_cap"
                              name="deal_attributes.valuation_cap"
                              label="Valuation cap"
                              placeholder="Valuation cap"
                            />
                          </div>
                        </div>



                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-label-group">
                              <Field type="date"
                                id="maturity_date"
                                name="deal_attributes.maturity_date"
                                className={`form-control ${touched?.deal_attributes?.maturity_date && errors?.deal_attributes?.maturity_date ? "is-invalid" : ""}`}
                                placeholder="Maturity date"
                              />
                              <label htmlFor="maturity_date">Maturity date</label>
                              <ErrorMessage name="deal_attributes.maturity_date" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-label-group">
                              <Field
                                type="number"
                                id="interest_rate"
                                name="deal_attributes.interest_rate"
                                className={`form-control ${touched?.deal_attributes?.interest_rate && errors?.deal_attributes?.interest_rate ? "is-invalid" : ""}`}
                                placeholder="Interest rate"
                              />
                              <label htmlFor="interest_rate">Interest rate</label>
                              <ErrorMessage name="deal_attributes.interest_rate" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>
                        </div>



                        <div className="row">
                          <div className="col">
                            <div className="form-label-group">
                              <Field type="text"
                                id="security_type"
                                name="deal_attributes.security_type"
                                className={`form-control ${touched?.deal_attributes?.security_type && errors?.deal_attributes?.security_type ? "is-invalid" : ""}`}
                                placeholder="Type of security"
                                component="select"
                              >
                                <option value="">Type of Security</option>
                                <option value="crowdsafe">CrowdSAFE</option>
                                <option value="convertible_debt">Convertible Debt</option>
                              </Field>
                              <label htmlFor="security_type">Type of security</label>
                              <ErrorMessage name="deal_attributes.security_type" component="div" className="invalid-feedback text-left" />
                            </div>
                          </div>
                        </div>

                    </>
                  }
                  {/* <div className="row">
                    <div className="col-md-6">
                      <label className="switch mb-3">
                        <Field
                          id="allow_credit_card"
                          type="checkbox"
                          name="allow_credit_card"
                          />
                        <span className="slider round"></span>
                        <span style={{marginLeft:'70px'}} className="checkbox-tagline">Allow credit card payments</span>
                      </label>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-6">
                      <label className="switch mb-3">
                        <Field
                          id="unlisted"
                          type="checkbox"
                          name="unlisted"
                          />
                        <span className="slider round"></span>
                        <span style={{marginLeft:'70px'}} className="checkbox-tagline">Unlisted</span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="switch mb-3">
                        <Field
                          id="international_investors"
                          type="checkbox"
                          name="international_investors"
                          />
                        <span className="slider round"></span>
                        <span style={{marginLeft:'70px'}} className="checkbox-tagline">Allow international investors</span>
                      </label>
                    </div>
                  </div>

                  {values.international_investors && <div className="row">
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field type="number"
                          name="deal_attributes.minimum_international_investment"
                        >
                          {({
                            field, // { name, value, onChange, onBlur }
                            form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => {
                            return <NumberFormat
                              name={field.name}
                              value={field.value}
                              onBlur={field.onBlur}
                              id="minimum_international_investment"
                              thousandSeparator={true}
                              className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
                              placeholder="Minimum investment for international investors"
                              decimalScale={2}
                              onValueChange={(valuesObj) => {
                                const {floatValue} = valuesObj;
                                form.setFieldTouched(field.name)
                                form.setFieldValue(field.name, floatValue)
                              }}
                            />
                          }}
                        </Field>

                        <label htmlFor="minimum_international_investment">Minimum investment for international investors</label>
                        <ErrorMessage name="deal_attributes.minimum_international_investment" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field type="number"
                          name="deal_attributes.entity_minimum_international_investment"
                        >
                          {({
                            field, // { name, value, onChange, onBlur }
                            form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => {
                            return <NumberFormat
                              name={field.name}
                              value={field.value}
                              onBlur={field.onBlur}
                              id="entity_minimum_international_investment"
                              thousandSeparator={true}
                              className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}
                              placeholder="Minimum investment for international investors"
                              decimalScale={2}
                              onValueChange={(valuesObj) => {
                                const {floatValue} = valuesObj;
                                form.setFieldTouched(field.name)
                                form.setFieldValue(field.name, floatValue)
                              }}
                            />
                          }}
                        </Field>
                        <label htmlFor="entity_minimum_international_investment">Minimum investment for international entities</label>
                        <ErrorMessage name="deal_attributes.entity_minimum_international_investment" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                  </div> }


                  <div className="form-group">
                    <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
                      Save
                    </button>
                  </div>
                  <FormikPromptIfDirty />
                </div>
              </Form>
            )}
          </Formik>
    </div>
  );
}
