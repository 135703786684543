import React from "react";
import IndividualInvestorInfo from "../AccountSettings/InvestorInfo";
import EntityInfo from "../AccountSettings/EntityInfo";
import { CustomScrollSpy } from "../../../../admin/components/shared/CustomScrollSpy";

export default function InvestorSettings() {
  const settingsSideBarItems = [
    {
      id: "investor-info",
      key: "investor-info",
      name: "Individual Investor Info",
      visibility: true,
      component: <IndividualInvestorInfo key="investor-info" />,
    },
    {
      id: "entity-info",
      key: "entity-info",
      name: "Entity Info",
      visibility: true,
      component: <EntityInfo key="entity-info" />
    },
  ];
  return (
    <>
      <div
        className="tab-pane show active"
        id="investor-settings"
        role="tabpanel"
      >
        <div className="settings-detail-container">
          <section className="main-content">
            <CustomScrollSpy pitchSidebarItems={settingsSideBarItems} />
          </section>
        </div>
      </div>
    </>
  );
}
