import PropTypes from "prop-types";
import React from "react";
import "./HeaderBanner.scss";

const HeaderBanner = ({children}) => {
  return (
    <div className="header-banner">
      {children}
    </div>
  )
}

HeaderBanner.defaultProps = {};

HeaderBanner.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string,
  tabRenderer: PropTypes.func,
  contentRenderer: PropTypes.func,
};

export default HeaderBanner;
