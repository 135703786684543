import React from 'react'
import { NavLink } from 'react-router-dom'
import { history } from 'redux/helpers';

export default function OfferNavLinkItems ({offer = null, navBarItems}){
  const routeBack = (section) => {
    let pathNameArray = history.location.pathname.split('/');
    let urlWithoutUpdateId = history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))
    //checking if the url has update id.
    if(!isNaN(pathNameArray[pathNameArray.length-1])){
      history.push(`${urlWithoutUpdateId.substring(0, urlWithoutUpdateId.lastIndexOf('/'))}/${section}`)
    }
  }
  return(
      navBarItems.map((option, i) => {
        let badge;

        if(option.value === 'updates' && offer){
          badge = offer.offer_updates_count + 1; // +1 for offer launch update.
        }else if(option.value === 'discussion' && offer?.discussions_count > 0){
          badge = offer.discussions_count;
        }

        return <NavLink key={option.value}
          to={{
            pathname: `${option.value}`,
            state: {scroll: true}
          }}
          onClick={()=>{routeBack(option.value)}}
          //used this way because when we change the structur of 'to' attribute the activeClassName stops working
          //searched A lot for the solution to this but couldn't get any specific way to make both working together
          className={ history.location.pathname.includes(option.value) ? 'active' : ''}
          key={i}
          >
          <span className={`tab-button`}>
            {option.label}
            { badge && <span className="badge badge-light ml-1">{badge}</span> }
          </span>
        </NavLink>
      })
  )
}