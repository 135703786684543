import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useParams } from 'react-router';
import ErrorBoundary from 'components/ErrrorBoundary';
import {
  HorizontalRoutedTabs,
} from 'admin/components';
import { usePreoffer } from './queries';
import OverviewEditor from './OverviewEditor'
import StatusIndicator from './StatusIndicator';
import StatusManager from './StatusManager';
import PitchEditor from './PitchEditor'
import './../../pages/Page.OfferEdit.scss';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import InvestmentInterestEsignTemplate from './InvestmentInterestEsignTemplate';
import ResourceDocuments from '../../components/ResourceDocuments';

function Editor(){
  let { path, url } = useRouteMatch();
  const { authData } = React.useContext(AuthContext);
  let { preofferSlug } = useParams()
  const {data:preoffer={}, isLoading} = usePreoffer(preofferSlug)

  const preofferSections = React.useMemo(
    () => {
      const editorTabs = [
        { tabId: 'overview', title: 'Overview', targetId: 'overview-panel', pathName: 'overview',
          decoratorClass: preoffer.name ? 'complete' : '',
          content: <OverviewEditor />
        },
      ]

      if(preofferSlug){
        editorTabs.push({
          tabId: 'pitch', title: 'Pitch', targetId: 'pitch-panel', pathName: 'pitch',
          decoratorClass: preoffer.pitch_complete ? 'complete' : '',
          content: <PitchEditor />
        })
      }

      if(preoffer?.id){
         editorTabs.push({
          tabId: 'documents', title: 'Documents', targetId: 'documents-panel', pathName: 'documents',
          decoratorClass: preoffer.documents_length > 0 ? 'complete' : '',
          content: <ResourceDocuments resourceType='preoffer' resourceId={preoffer.id} />
        })

        editorTabs.push({
          tabId: 'esign-template', title: 'Esign Template', targetId: 'esign-panel', pathName: 'esign-template',
          decoratorClass: preoffer.esign_template_id ? 'complete' : '',
          content: <InvestmentInterestEsignTemplate preoffer={preoffer} />
        })
      }

      return editorTabs;
    },
    [preoffer]
  );

  if (isLoading)
    return 'Loading...'

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_OFFER)) {
    return <div>
      You are not authorised to edit Offer.
    </div>
  }

  return (
    <>
      <EditorHeader preoffer={preoffer} />
      <div className='offer-editor'>
        <div className="small-section-bottom">
          <div className='left-spaced-tabs'>
            <HorizontalRoutedTabs
              basePath={path}
              baseUrl={url}
              tabs={preofferSections}
              tabIdAccessor={'tabId'}
              targetPanelIdAccessor={'targetId'}
              defaultTabId="overview"
            />
          </div>
        </div>
      </div>
    </>
  )
}

function EditorHeader({preoffer}) {
  return (
    <div className="custom-topbar offer-editor-topbar">
      <div className="offer-summary">
        {preoffer.id ? 'Edit ' : 'Create '}
        <span className="offer-name">{preoffer.name || 'New Preoffer'}</span> for {preoffer.company.name}
      </div>
      <div className="action-button-container">
        <StatusIndicator preoffer={preoffer} />
        <StatusManager preoffer={preoffer} />
      </div>
    </div>
  );
}

Editor.defaultProps = {};

Editor.propTypes = {};

export default Editor;