import React from 'react';import { youtubeVideoThumbnail } from '../../jsUtils/stringUtils';
import { distanceFromNow, formatDate } from '../../jsUtils/dateTimeUtils';
import { statusLabel } from '../LiveEvent/StatusUtil';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

export default function ListItem({ event }) {
  const imageUrl = event.preview_image_url || youtubeVideoThumbnail(event.youtube_url);
  const statueText = statusLabel(event.status);
  const history = useHistory();

  function eventInPastOrFuture(scheduledTime) {
      // returns positive number if scheduled time has past
    if (moment().diff(scheduledTime, 'days') > 0) {
      return `streamed ${distanceFromNow(scheduledTime)} ago`
    } else if (moment().diff(scheduledTime, 'days') <= 0) {
      return `streaming in ${distanceFromNow(scheduledTime)}`
    } else {
      return `scheduled at ${formatDate(scheduledTime, 'MMM dd, yyyy, hh:mm a' ) ?? ""}`
    }
  }

  return(
    <div className='col-12 col-md-4 col-lg-4 d-flex'>
      <div className='card mb-4 px-0 border-0 rounded-0 cursor-pointer br-bt w-100' onClick={() =>{  history.push(`/resources/${event.slug}`); }}>
        { statueText && (
          <span className='poppins fs-12 weight-600 color-success event-status px-2'>
            {statusLabel(event.status)}
          </span>)
        }
        <MediaImageContainerStyles banner={imageUrl} />
        {/* <img style={{height: '220px'}} className='card-img-top flex-fill rounded-0' src={imageUrl} alt={event.title}/> */}
        <div className='card-body flex-fill px-0 py-2 pb-4'>
            <div className='card-text mb-1 poppins fs-default weight-700 m-t-5'>{event.title}</div>
          <div className='card-text poppins fs-14'>{event.status === "live" ? "Streaming now" : eventInPastOrFuture(event.scheduled_time)}</div>
        </div>
      </div>
    </div>
  );
}


const MediaImageContainerStyles = styled.div`
  background: linear-gradient(to top, #111, transparent 60%),
  url(${(props) => props.banner});
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  background-color: #111; 
`