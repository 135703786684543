import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import LoadingOverlay from 'react-loading-overlay';
import RichTextRenderer from 'admin/components/RichEditor/renderer'
import Discussion from 'admin/pages/Page.Discussion';
import { formatDateWithoutTimezone } from 'jsUtils/dateTimeUtils';
import LikeButton from './LikeButton';
import YoutubeEmbed from '../../../admin/components/shared/YoutubeEmbed';
import LiveChat from '../../../components/LiveChat';
import { offerUpdateApi } from 'api/offerUpdate';
import { liveEventApi } from '../../../api/liveEvent';

export default function View({ offer }) {
  const company = offer.company;
  let { offerId, offerUpdateId } = useParams();
  let { hash } = useLocation();
  const commentRef = useRef(null);
  const viewRef = useRef(null);

  const { isLoading, error, data: offerUpdate } = useQuery(['offerUpdate', offerUpdateId], () => {
    return offerUpdateApi.show(offerUpdateId);
  });

  useEffect(() => {
    let element = hash === '#comments' ? commentRef : viewRef;

    setTimeout(() => {
      window.scrollTo({
        behavior: element.current ? "smooth" : "auto",
        top: element.current ? element.current.offsetTop : 0
      });
    }, 100);
  });

  if (isLoading) {
    return <LoadingOverlay active={isLoading} spinner text='Loading offer update...' />
  }

  if (error) {
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const liveEvent = offerUpdate.resourceable;
  const positionText = liveEvent ? 'Live Event' : 'Update';
  const title = liveEvent ? liveEvent.title : offerUpdate.title;
  let contentView;

  if(liveEvent){
    contentView = (
      <div className="col-12 px-0">
        <div className='mb-4'>
          <YoutubeEmbed videoUrl={liveEvent.youtube_url}/>
        </div>
        <div className='mb-4 mt-1'>
          <div className='fs-7 poppins weight-700 d-block mb-2'>Description</div>
          <div className='row col-12 mb-4 display-linebreak'>{liveEvent.description}</div>
        </div>
        <div className='mb-4 mt-1'>
          <LiveChat.ChatHistory liveEvent={liveEvent} getChatSession={ liveEventApi.getChatSession }>
            <div className='fs-7 poppins weight-700 d-block mb-2'>Chat History</div>
          </LiveChat.ChatHistory>
        </div>
      </div>
    )
  }else{
    contentView =
      <RichTextRenderer content={offerUpdate.content} backgroundColor="#fff" />
  }

  return (
    <div className="bg-white" style={{ height: '100%' }} ref={viewRef}>
      <div className="offerupdates-content py-5">
        <div className="col-12 px-0 mb-5 text-left">
          <Link to={`/offers/${offerId}/updates`} className="mx-auto zeitung-micro weight-300 btn btn-sm btn-outline-dark rounded-0 px-4 py-2">
            <FaArrowLeft size={18} /><span className="ml-2">Updates</span>
          </Link>
        </div>
        <div className="col-12 px-0">
          <div className="col-12 fs-11 zeitung-mono">{offerUpdate.position && `${positionText} #${offerUpdate.position}`}</div>
          <div className="col-12 fs-6 weight-800 montserrat mb-1 pb-1">{title}</div>
          <div className="col-12 my-3 px-0">
            <div className="row border-bottom pb-3">
              <div className="mx-2">
                <img src={company.logo_url} alt="" className="logo logo-circle" width="48px" height="48px" />
              </div>
              <div>
                <div className="fs-10 weight-700 montserrat">{company.legal_name}</div>
                <div className="fs-10 montserrat">{formatDateWithoutTimezone(offerUpdate.published_at)}</div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0 mt-2">
            {contentView}
          </div>
          { !liveEvent && (
              <div className="col-12 mt-4 mb-2 pb-2 px-0 border-dash-b">
                <div className="row">
                  <LikeButton offerUpdate={offerUpdate} />
                </div>
              </div>
          )}
        </div>
        { !liveEvent && (
            <div className="col-12 mt-4 px-0 offer_update_discussion" ref={commentRef}>
              <h2 className="fs-7 weight-700 mb-4">Comments</h2>
              <Discussion discussableType="offer_updates" discussableId={offerUpdate.id} companyId={company.slug} companyName={company.name} postButtonLabel="Post Comment" />
            </div>
         )}
      </div>
    </div>
  )
}


