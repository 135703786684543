import React from "react";
import PropTypes from 'prop-types';

function StatusBadge({ status, text, cssClass = ''}) {
  const statusStyle = {
    valid: 'success',
    invalid: 'danger',
    pending: 'secondary',
    failed: 'danger',
    verified: 'success',
    cleared: 'success',
    warning: 'warning'
  }

  return (
    <span className={`badge badge-${statusStyle[status] || 'secondary'} py-1 px-2 b-0 rounded-0 ${cssClass}`}>
      {(text || status ).toUpperCase()}
    </span>
  )

}

StatusBadge.defaultProps = {};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
  cssClass: PropTypes.string
 };

export default StatusBadge;