import React from "react";
import {
  ResponsiveTable,
  AdminInviteForm,
  TableActionsContainer,
  TableActionItem,
  Paginator,
} from "admin/components";
import { FaPen, FaTrash } from 'react-icons/fa';
import { useMutation } from 'react-query';
import ToastMessage from 'components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer'
import { useSpacedAdminList, deleteSpacedAdmin } from "../queries/index";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import usePagination from "hooks/usePagination";
import {toTitlecase} from "jsUtils/stringUtils";
import "./AdminsList.scss"
import AdminRoleEditForm from "./AdminRoleEditForm";

function AdminsList() {
  const pageConfig = usePagination();
  const adminList = useSpacedAdminList(pageConfig.current);
  const inviteAdminModal = React.useContext(PortalizedModalContext);
  const roleEditModal = React.useContext(PortalizedModalContext);
  const deleteConfirmModal = React.useContext(PortalizedModalContext);

  const deleteAdminUserMutation = useMutation(deleteSpacedAdmin, {
    onSuccess: (values) => {
      adminList.refetch();
      ToastMessage.success(`Admin role deleted successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      deleteConfirmModal.closeModal();
    }
  })

  const handleEdit = (role) => () => {
    roleEditModal.setModalContent(
      `Edit Role for ${toTitlecase(role.user.name)}`,
      <AdminRoleEditForm
        role={role}
        onSettle={() => {
          roleEditModal.closeModal()
          adminList.refetch();
        }} />,
      'sm'
    );
    roleEditModal.openModal();
  }

  const handleDelete = (role) => () => {
    deleteConfirmModal.setModalContent(
      "Delete Admin Role?",
      <>
        <p>{`Are you sure you want to delete the admin role for- ${toTitlecase(role.user.name)}?`}</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteAdminUserMutation.mutate({role_id: role.id})
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteConfirmModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (adminList.isSuccess)
        return adminList.data.data
      else
        return []
    },
    [adminList]
  );


  const columns = React.useMemo(
    () => {
      if (adminList.isSuccess){
        return [
          {
            Header: 'Admin name',
            accessor: 'user.name',
            Cell: ({value}) => toTitlecase(value)
          },{
            Header: 'Email',
            accessor: 'user.email'
          },
          {
            Header: 'Role',
            accessor: 'name'
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ row, value }) => (
              <TableActionsContainer>
                <TableActionItem
                  tooltipText="Edit"
                  onClick={handleEdit(row.original)}
                >
                  <FaPen />
                </TableActionItem>

                <TableActionItem
                  tooltipText="Delete"
                  onClick={handleDelete(row.original)}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            )
          }
        ]
      }
      else{
        return []
      }
    },
    [adminList]
  );

  if (adminList.isLoading)
    return 'Loading...'

  return (
    <>
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={adminList?.data?.pagy}
        loading={adminList.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={adminList.isLoading}
        loadingMsg="Loading admins..."
        noDataMsg="No admins found."
        classes="admins-list-table use-md-width"
      />
    </>
  )
}

export default AdminsList;
