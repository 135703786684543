import React from "react";
const OfferCardCompanyDescription = function ({ company }) {
  const {tagline, description} = company;
  return (
    <div>
      <h6
        style={{ minHeight: "35px" }}
        className="fs-default weight-700 poppins line-clamp-2 lh-sm"
      >
        {tagline && tagline}
      </h6>
      <p
        style={{ minHeight: "78px" }}
        className="weight-400 poppins line-clamp-4 fs-14"
      >
        {description && description}
      </p>
    </div>
  );
};

export default OfferCardCompanyDescription;
