import React from 'react';
import { parse } from 'date-fns';

export default function TimeLineDate({date}){
  if(!date){ return <></>};

  const d = parse(date, 'yyyy-MM-dd', new Date());

  return (
    <span className="pl-md-4 fs-10 zeitung-mono">
      <b>{d.toLocaleString('default', { month: 'short' }) + ' '}</b>{d.getDate() + ',  ' + d.getFullYear()}
    </span>
  );
}
