import React from 'react';
import YoutubeEmbed from '../../admin/components/shared/YoutubeEmbed';
import { platformLiveEventApi } from '../../api/platformLiveEvent';
import ChatHistory from '../LiveChat/ChatHistory';
import { statusLabel } from '../LiveEvent/StatusUtil';

export default function ArchivedEvent({ event }) {
  return (
    <div>
      <div className='live-event current-platform-event bg-light-black px-2 px-md-0'>
        <div className='container'>
          <div class="d-flex align-items-start">
            <h2 className='color-white poppins fs-7 weight-800 mb-3'>{event.title}</h2>
            <span className='poppins fs-12 weight-800 ml-2 px-3 py-1 align-middle color-white achived-indicator'>
              {statusLabel(event.status) || 'Past Event'}
            </span>
          </div>
        </div>
        <div className="container">
          <div className='row my-2'>
            <div className="col-12 col-lg-8 video-container">
              <div className='for-youtube-vid-container'>
                <YoutubeEmbed videoUrl={event.youtube_url}/>
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <div className='chat-container'>
                <ChatHistory liveEvent={event} getChatSession={platformLiveEventApi.getChatSession }/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
