import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { history } from 'redux/helpers'

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    backgroundColor: '#EEEEEE',
    borderRadius: 0,
    position: 'fixed',
    padding: window.innerWidth > 640 ? 0 : '0 0 72px 0',
    top: '0',
    left: window.innerWidth > 640 ? '65px' : '0px',
    width: window.innerWidth > 640 ? 'calc(100% - 65px)' : '100%',
    height: '100%',
    minHeight:'100vh',
    zIndex: '3'
  }
}

export default function OverviewModal({onRequestClose, children}) {
  const closeRequestHandler = () => {
    console.log('close request handler called');
    if (typeof onRequestClose === 'function')
      onRequestClose();
    else
      history.goBack();
  }

  return(
    <Modal
      portalClassName="fadein-modal admin-overview-modal"
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={closeRequestHandler}
      style={modalStyles}
    >
      <button
        className="drawer-close-btn"
        style={{
          position: 'absolute',
          height: '30px',
          width:'20px',
          right: '16px',
          top: '16px',
          zIndex: 'inherit',
        }}
        onClick={closeRequestHandler}
      >
          <MdClose color="#000" size="25px" />
      </button>
      {children}
    </Modal>
  );
}
