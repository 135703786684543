/**
 * Relace link to html.
 * @param {String} htmlText
 * @returns
 */
function linkify(htmlText){
  const urlPattern = /\b(?:https?):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;


  return htmlText.replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
            .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')

}

/**
 *  Remove href links
 * @param {String} htmlText
 * @returns
 */
function removeLinks(htmlText){
  const urlPattern = /\b(?:https?):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;


  return htmlText.replace(urlPattern, '').replace(pseudoUrlPattern, '')
}

function chatUserName(participant){
  if(!participant){
    return '';
  }

  if(participant.role === 'moderator'){
    return `[SV Admin] ${participant.user_name}`;
  }

  if(participant.affiliations.length){
    const title = participant.affiliations.map(a => `${a.title}${a.approved ? '' : ' - pending'}`).join(', ');
    return `[${title}] ${participant.user_name}`;
  }

  return participant.user_name;
}

export default { linkify, removeLinks, chatUserName };