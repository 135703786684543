import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import ReactTooltip from 'react-tooltip';

export default function AmlSectionsContainer({
  title,
  children,
  tooltipText = "Please contact support for more info",
  classes = "",
}) {
  return (
    <div className={`bold-card ${classes}`}>
      <div className="bold-card-header d-flex align-items-center">{title}
        <div data-for="amlTooltip" data-tip={tooltipText}>
          <BsInfoCircle className="ml-1" size="0.75em" />
        </div>
        <ReactTooltip id="amlTooltip" backgroundColor="#f5f5f5" textColor="#111" className="tooltip-width" />
      </div>

      <div className="bold-card-body">{children}</div>
    </div>
  );
}
