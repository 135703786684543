import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';
import { downloadFile } from 'jsUtils/fileUtils';

const offerStatusApiMap = {
  live: 'publish',
  paused: 'pause',
  closed: 'close',
}

const useOffersList = ({q=null, page=1, per_page=10}) => {
  return useQuery(
    ['admin', 'offers', 'list', q, page, per_page],
    () => {
      return axiosClient
        .get('admin/offers', {params: {q, page, per_page}})
    }, {
      keepPreviousData: true,
    }
  );
}

const useOffer = (offer_slug) => {
  return useQuery(
    ['admin', 'offers', offer_slug],
    () => {
      return axiosClient
        .get(`offers/${offer_slug}`)
        // .get(`admin/offers/${offer_slug}`)
    }
  );  
}

const createOffer  = ({company_slug, values}) => {
  return axiosClient.post(`/admin/companies/${company_slug}/offers`, values);
}

const updateOffer = ({offer_slug, values}) => {
  return axiosClient.put(`/admin/offers/${offer_slug}`, values);
}

const useOfferPitch = (offer_slug) => {
  return useQuery(
    ['admin', 'offers', offer_slug, 'pitch'],
    () => {
      return axiosClient
        .get(`/offers/${offer_slug}/pitch`)
        // .get(`admin/offers/${offer_slug}/pitch`)
    }
  );  
}

const useOfferPitchCustomers = (pitchable_id) => {
  return useQuery(
    ['admin', 'pitchable', pitchable_id, 'customers'],
    () => {
      return axiosClient
        .get(`/admin/offers/${pitchable_id}/customers`)
    }, {
      enabled: pitchable_id !== undefined
    }
  );
}

const addCustomer = ({offer_id, values}) => {
  return axiosClient.post(`/admin/offers/${offer_id}/customers`, values);
}

const updateCustomer = ({customer_id, values}) => {
  return axiosClient.put(`/admin/customers/${customer_id}`, values);
}

const deleteCustomer = ({customer_id}) => {
  return axiosClient.delete(`/admin/customers/${customer_id}`);
}

const deletePitchSection = ({section_id, type}) => {
  return axiosClient.delete(`/admin/pitch_sections/${section_id}`, {data: {type}})
}

const useOfferInvestorList = (offer_slug, type="all") => {
  console.log("useOfferInvestorList", {offer_slug, type});
  return useQuery(
    ['admin', 'offers', offer_slug, 'investors-list', type],
    () => {
      return axiosClient
        .get(`/offers/${offer_slug}/investors`, {params: {type}})
    }
  );
}

const useExportOfferInvestorsList = ({offer_slug, type, opts={enabled: false}}) => {
  return useQuery(
    ['admin', 'offers', offer_slug, 'export-investors', type],
    () => {
      return axiosClient
        .get(`/offers/${offer_slug}/investors.csv`, {params: {type}})
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `offer-${offer_slug}-investors-${type}.csv`)
      }
    }
  );
}

const useCloseableInvestments = (offer_slug) => {
  return useQuery(
    ['admin', 'offers', offer_slug, 'closeable-investments'],
    () => {
      return axiosClient
        .get(`admin/offers/${offer_slug}/investments/eligible_for_closing`)
    }
  );
}

const useClosedInvestments = (offer_slug) => {
  return useQuery(
    ['admin', 'offers', offer_slug, 'closed-investments'],
    () => {
      return axiosClient
        .get(`admin/offers/${offer_slug}/closes`)
    }
  );
}

const useExportCloses = ({close_id, opts={enabled:false}, file_name='closes-list'}) => {
  return useQuery(
    ["closes", close_id, 'rollingClosesCsv'],
    () => {
      return axiosClient
        .get(`admin/closes/${close_id}/investors.csv`)
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `${file_name}.csv`)
      },
      onError:(error)=>{
        console.log(error,"not found")
      }
    }
  )
}

const closeInvestments = ({offer_slug, values}) => {
  return axiosClient.post(`admin/offers/${offer_slug}/closes`, values)
}

function updateOfferStatus({offer_slug, nextStatus}) {
  return axiosClient.patch(`/admin/offers/${offer_slug}/${offerStatusApiMap[nextStatus]}`)
}

function updatePitch({pitchable_id, values}) {
  return axiosClient.put(`/admin/offers/${pitchable_id}/pitch`, values)
}

const exportInvestorDocumemtPackageUrl = (offer_id) => {
  return axiosClient.getUri({
    url: `admin/offers/${offer_id}/export_client_documents`
  });
}

export {
  useOffer,
  useOffersList,
  createOffer,
  updateOffer,
  useOfferInvestorList,
  useExportOfferInvestorsList,
  useCloseableInvestments,
  useClosedInvestments,
  closeInvestments,
  useExportCloses,
  useOfferPitch,
  updatePitch,
  updateOfferStatus,
  useOfferPitchCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  deletePitchSection,
  exportInvestorDocumemtPackageUrl,
};