import React, {useEffect, useRef} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useQuery } from 'react-query';
import rocketLaunch from 'assets/images/icons/laptop-launch-wht.svg';
import ListItem from './ListItem';
import { offerUpdateApi } from 'api/offerUpdate';
import TimeLineDate from './TimeLineDate';
import { formatDate } from 'jsUtils/dateTimeUtils';

export default function List({ offer, setViewMode }) {
  const { isLoading, error, data: offerUpdates } = useQuery(['offerUpdates', offer.id], () => {
    return offerUpdateApi.list(offer.slug);
  });
  const listRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: listRef.current ? "smooth" : "auto",
        top: listRef.current ? listRef.current.offsetTop : 0
      });
    }, 100);
  });

  if(isLoading){
    return <LoadingOverlay active={isLoading} spinner text='Loading offer updates...' />
  }

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const groupedOfferUpdates = offerUpdates.reduce((group, offerUpdate) => {
    const { published_at } = offerUpdate;
    group[published_at] = group[published_at] ?? [];
    group[published_at].push(offerUpdate);
    return group;
  }, {});

  return (
    <div className="p-4 mt-3 offerupdates-content" ref={listRef}>
      {
        Object.entries(groupedOfferUpdates).map(
         ([published_at, updatesGroup]) => <ListItem
            key={published_at}
            offer={offer}
            offerUpdates={updatesGroup}
            publishedAt={published_at}
            setViewMode={setViewMode}
          />
        )
      }
      <div className="row mb-3" >
          <div className="timeline-date col-12 mt-2 mb-2 pl-0">
            <TimeLineDate date={formatDate(offer.published_at, 'yyyy-MM-dd')} />
          </div>
          <div className="col-1 col-md-1 timeline d-none d-md-block">
            <div className="knob"/>
            <div className="progress-line h-100"/>
            <div className="knob"/>
          </div>
          <div className="col-11 text-center p-5 bg-black">
            <img style={{ width: '48px' }} src={rocketLaunch} alt="Offer Launched!"></img>
            <h4 className="fs-6 weight-900 text-center mt-2 f-white montserrat">Offer Launched!</h4>
          </div>
      </div>
    </div>
  )
}
