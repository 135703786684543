export const sectorDescriptions = (sector) => {
  switch (sector) {
    case "Sat Applications":
      return "Companies that use satellites for Earth-focused business"
      break;
    case "Sat Applications: Communications":
      return "Facilitating earth-based communication and connectivity"
      break;
    case "Sat Applications: Earth Observation":
      return "Earth-centric data capture, analysis, and insight"
      break;
    case "Sat Applications: Connectivity":
      return "Facilitating global communication and connection"
      break;
    case "Sat Applications: Location Intelligence":
      return "Global positioning and tracking services"
      break;
    case "Services":
      return "Companies that provide services to other space vehicle owners"
      break;
    case "Services: Comms Services":
      return "Comms and data relay services for space operators"
      break;
    case "Services: Mission Services":
      return "Mission Support and Services for space operators"
      break;
    case "Services: Physical Services":
      return "Services for operators that require physical interaction with vehicles"
      break;
    case "Services: Professional Services":
      return "Consulting, research, and testing services for space operators"
      break;
    case "Emerging Markets":
      return "Companies pushing the boundaries of commercial space"
      break;
    case "Emerging Markets: Habitability":
      return "Solutions for human habitability problems in space"
      break;
    case "Emerging Markets: Resource Extraction":
      return "Extracting and refining organic/inorganic resources in space"
      break;
    case "Emerging Markets: Materials":
      return "Material development and manufacturing for space"
      break;
    case "Subsystems":
      return "Companies that make components and systems for space operators"
      break;
    case "Subsystems: Communications":
      return "Components used for communication in space"
      break;
    case "Subsystems: Power Systems":
      return "Components used of energy generation and storage"
      break;
    case "Subsystems: Other Subsystems":
      return "Other Components used in space vehicles"
      break;
    case "Subsystems: Info Tech":
      return "Computing components and Software used in space"
      break;
    case "Subsystems: Propulsion":
      return "Components for rocket and in-space propulsion"
      break;            
    case "Transportation":
      return "Companies that are in the business of transporting payloads (human and non-human) to space"
      break;
    case "Transportation: Deep Space":
      return "Transportation services beyond the earth's orbit. Including landers and rovers"
      break;
    case "Transportation: Heavy Launch":
      return "Launch and deployment of combined payloads generally greater than 500kg"
      break;
    case "Transportation: Launch Support":
      return "Auxillary Services supporting launch company operations"
      break;
    case "Transportation: Light Launch":
      return "Launch and deployment of combined payloads generally less than 500kg"
      break;
    case "Traditional":
      return "Established contractors and subcontractors in the space industry"
      break;
    case "Traditional: Contractors":
      return "Full and partial space vehicle contracting "
      break;
    case "Traditional: Diversified":
      return "Contractors with many cross-sector lines of business"
      break;      
    default:
      return ""
  }

}