import React, { useState } from 'react'
import { FaFileAlt } from "react-icons/fa";
import { fileIcon } from "../../../assets/images";
import { toTitlecase } from '../../../jsUtils/stringUtils';
import PopupModal from '../PopupModal';
import PdfViewer from '../../../components/PdfViewer';

function DocumentViewer({ url, name, onClose }) {
  return (
    <PopupModal onRequestClose={onClose} size="lg">
      <PdfViewer url={url} name={name} zoom={1.5}/>
    </PopupModal>
  )
}

export default function FileBar({ url, name, customIcon, downloadable }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  const openDocumentHandler = (e, url) => {
    e.preventDefault();

    if (downloadable) {
      window.open(url);
    } else {
      togglePopup()
    }
  }

  if (!(url && name)) return null;
  return (
    <li>
      <div className="file-bar bg-white cursor-pointer" >
        <div className="w-100" onClick={(e) => openDocumentHandler(e, url)}>
          {customIcon && <img src={fileIcon} className="file-bar-icon" alt="file icon" />}
          {!customIcon && <FaFileAlt className="file-bar-icon" />}
          <span>{toTitlecase(name)}</span>
        </div>
        {showPopup && <DocumentViewer url={url} name={name} onClose={togglePopup} />}
      </div>
    </li>
  )
}
