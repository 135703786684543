import axiosClient from 'config/axiosClient';

export const companyApi = {
  create,
  update,
  purge,
  get,
  list,
  fetchSectors,
  listOffers,
  follow,
  followersList
};

export default companyApi;

function create(attrs) {
  return axiosClient.post('/companies', attrs);
}

function update(id, attrs) {
  return axiosClient.put(`/companies/${id}`, attrs);
}

function purge(id) {
  return axiosClient.delete(`/companies/${id}`);
}

function get(id) {
  return axiosClient.get(`/companies/${id}`);
}

function list(page, per_page) {
  return axiosClient.get(`/companies/?&page=${page}&per_page=${per_page}`);
}

function fetchSectors() {
  return axiosClient.get("/sectors");
}

function listOffers(companyId) {
  return axiosClient.get(`/companies/${companyId}/offers`);
}

function follow(companyId){
  return axiosClient.post(
    `/companies/${companyId}/followers`,
    {},
    { errorMessage: 'You must login or sign up to follow a company.' }
  )
}

function followersList(companyId, page, perPage){
  if(!page){
    page = 1;
  }

  if(!perPage){
    perPage = 25;
  }

  return axiosClient.get(
    `/companies/${companyId}/followers.json`, {params: {page: page, per_page: perPage}}
  );
}
