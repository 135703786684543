import React, { useContext } from "react";
import { parseJSON, format } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { PortalizedModalContext } from "../../../../contexts/portalizedModalContext";
import FileSelect from "components/HookForm/FileSelect";
import SelectField from "components/HookForm/SelectInput";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import TextField from "components/HookForm/TextInput";
import { addEntityDocument } from "../../OfferForm/queries";
import ToastMessage from "../../../../components/ToastMessage";
import { getAttachmentTypes } from "../../../../jsUtils/exceptionUtils";
import LoadingOverlay from "react-loading-overlay";

export default function UploadDocument({
  category,
  clientId,
  lastUploaded,
  isVerified,
  clientType,
  isHeaderBtn
}) {
  const queryClient = useQueryClient();

  const uploadFileModal = useContext(PortalizedModalContext);
  const addDocument = useMutation(addEntityDocument, {
    onSuccess: (values) => {
      uploadFileModal.closeModal();
      methods.reset();

      queryClient.invalidateQueries(['accreditation_request', clientId], {
        exact: true,
      });
      queryClient.invalidateQueries(['aml_exception', clientId], {
        exact: true,
      });

      ToastMessage.success(`Document added successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error);
      openModal();
    },
  });
  const mediaMentionValidationSchema = Yup.object().shape({
    file: Yup.object()
      .required("Document is required.")
      .typeError("Document is required."),
    attachment_type: Yup.string().required("Exception type is required."),
    name: Yup.string().required("Document title is required.")
  });
  const methods = useForm({
    resolver: yupResolver(mediaMentionValidationSchema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      attachment_type: "",
      file: null,
      name: ""
    },
  });
  const onSubmit = (event) => {
    const payload = {
      category: category,
      attachment_type: event.attachment_type,
      name: event.name,
      attachment: { data: event.file.data, filename: event.file.fileName },
    };
    addDocument.mutate({
      clientId: clientId,
      data: payload,
    });
    uploadFileModal.closeModal();
  };

  const openModal = () => {
    uploadFileModal.setModalContent(
      `Upload Document`,
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FileSelect
              name="file"
              placeholder="Choose a file"
              fileType=".pdf, .jpg, .jpeg, .png"
            />
            <SelectField
              name="attachment_type"
              options={getAttachmentTypes(clientType, category)}
              placeholder="Type of document"
            />
            <TextField name="name" placeholder="Document title" type="text" labelStyle="zero-zindex" />
            <div className="portalized-modal-footer">
              <button
                type="submit"
                className="zeitung-mono weight-300 btn-black"
                disabled={addDocument.isLoading}
              >
                Confirm
              </button>
              <button
                className="zeitung-mono weight-300 mr-3 btn-black-outline"
                onClick={() => uploadFileModal.closeModal()}
              >
                Cancel
              </button>
            </div>
          </form>
        </FormProvider>
      </>,
      "md"
    );
    uploadFileModal.openModal();
  };

  return (
  <LoadingOverlay active={addDocument.isLoading} spinner text="Loading">
    <div className={ isHeaderBtn ? "" : "bg-white pb-2 pt-3" }>
      <div className="d-flex flex-wrap align-items-end">
        <button
          disabled={isVerified}
          onClick={openModal}
          className="btn-black px-3 py-2 poppins weight-700 fs-9"
        >
          Upload document
        </button>
        {!isVerified &&
          lastUploaded &&
          `Last uploaded: ${format(
            parseJSON(new Date(lastUploaded)),
            "MMM dd, yyyy hh:mm a"
          )}`}
      </div>
    </div>
  </LoadingOverlay>
  );
}
