import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from "react-router";
import { useMutation, useQueryClient } from 'react-query';
import { format, parseJSON } from 'date-fns';
import { FaEye, FaTrash, FaPen } from "react-icons/fa";
import Overview from './Overview';
import { 
  OverviewModal,
  ResponsiveTable,
  Paginator,
} from 'admin/components'
import ToastMessage from 'components/ToastMessage';
import { rollbarInstance } from 'jsUtils/rollbar';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import ErrorBoundary from 'components/ErrrorBoundary';
import { toTitlecase, humanize } from 'jsUtils/stringUtils';
import { history } from 'redux/helpers';

import { useRaisePetitionList, deleteRaisePetition } from './queries';
import usePagination from 'hooks/usePagination';
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../components/AmountWithCurrency';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import TableActionsContainer from '../../components/TableActionsContainer';
import TableActionItem from '../../components/TableActionItem';
import LinkedData from '../../../components/LinkedData';
import { usePortalizedModal } from '../../../components/PortalModal';
import { ButtonOutline } from '../../components';
function List() {
  const queryClient = useQueryClient();
  let { path, url } = useRouteMatch();
  const pageConfig = usePagination();
  const raisePetition = useRaisePetitionList(pageConfig.current)
  const deleteModal = usePortalizedModal("deleteRaisePetition");

  const openShowModal = React.useCallback(
    (row) => { 
      history.push(`${url}/${row.original.slug}`)
    },
    []
  )

  const closeShowModal = React.useCallback(
    () => {
      history.push(`${url}`)
    },
    []
  )

  const openEditorModal = (row) => {
    history.push(`${url}/${row.original.slug}/edit`)
  }

  const deleteMutation = useMutation(deleteRaisePetition, {
    onSuccess: () => {
      queryClient.invalidateQueries(['raise-petition', 'list']);
      ToastMessage.success(`Raise petition deleted successfully.`);
      deleteModal.close();
    },
    onError: (error) => {
      console.log(error);
      renderRailsErrors(error);
      rollbarInstance.warning("Error deleting raise petition", {error});
      deleteModal.close();
    },
  });

  const deleteHandler  = (petitionId, name) => {
    deleteModal.open({
      title: 'Delete petition?',
      body: <>
        <p>Are you sure you want to delete the raise petition - {name}?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteMutation.mutate({
                raise_petition_slug: petitionId,
              })
            }}
          >
            Confirm
          </button>
          <button className="btn-black-outline"
            onClick={deleteModal.close}
          >
            Cancel
          </button>
        </div>
      </>,
      onRequestClose: deleteModal.close,
      size: 'sm'
    });
  }

  const tableData = React.useMemo(
    () => {
      if (raisePetition.isSuccess)
        return raisePetition.data.data
      else
        return []
    },
    [raisePetition]
  );


  const columns = React.useMemo(
    () => {
      if (raisePetition.isSuccess){
        return [
          {
            Header: 'Petition Name', 
            accessor: 'name',
            Cell: ({row, value}) => (
              <LinkedData onClick={() => openShowModal(row)}>
                {value}
              </LinkedData>
            )
          },
          {
            Header: 'Company',
            accessor: 'company',
            Cell: ({value}) => (
              <div className="d-flex align-items-start">
                <img src={value.logo_url} alt='' className="company-table-logo mr-2" />
                {toTitlecase(value.name)}
              </div>
           ) 
          },
          {
            Header: 'Created',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: <>Goal&nbsp;<CurrencyBadge /></>,
            accessor: 'goal_amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: <>Amt pledged&nbsp;<CurrencyBadge /></>,
            accessor: 'pledged_amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: 'Status', 
            accessor: 'status', 
            Cell: ({value}) => humanize(value)
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
              <TableActionsContainer>
                <TableActionItem tooltipText="Edit" onClick={() => {openEditorModal(row)}}>
                  <FaPen />
                </TableActionItem>
                <TableActionItem tooltipText="View" onClick={() => openShowModal(row)}>
                  <FaEye />
                </TableActionItem>
                <TableActionItem
                  tooltipText="Delete"
                  onClick={() =>
                    deleteHandler(row.original.slug, row.original.name)
                  }
                  disabled={row.original.status!=="draft"}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            )
          },
        ]
      }
      else{
        return []
      }
    },
    [raisePetition]
  );

  return (
    <div className="bg-lightgrey">
      <ErrorBoundary>
        <div className="admin-main-content-header">
          <div className="amch-inner1">
            <div className="input-group ig-field">
              <input type="search" placeholder="Search" />
            </div>
          </div>
        </div>
        <Paginator
          theme='dark'
          onPage={pageConfig.setPage}
          meta={raisePetition?.data?.pagy}
          loading={raisePetition.isLoading}
        />
        <ResponsiveTable
          columns={columns}
          data={tableData}
          loading={raisePetition.isLoading}
          loadingMsg="Loading Petitions..."
          noDataMsg="No Petitions found."
          classes="use-xxl-width"
        />
        <Switch>
          <Route path={`${path}/:raisePetitionSlug`}
            component={() => (
              <OverviewModal onRequestClose={closeShowModal}>
                <Overview />
              </OverviewModal>
            )}
          />
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

List.defaultProps = {};

List.propTypes = {};

List.displayName = "RaisePetitionsList";

export default List;