import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, useParams } from "react-router";
import { useMutation, useQueryClient } from 'react-query';
import { FaTrash } from "react-icons/fa";
import { format, parseJSON } from 'date-fns';
import ErrorBoundary from 'components/ErrrorBoundary';
import Editor from './Editor';
import {
  EditorModal,
  OverviewHeader,
  AttributeValue,
  TwoColumnLayout,
  OverviewSubheader,
  ButtonDark,
  TextButton,
  ResponsiveTable,
  Paginator,
} from 'admin/components';
import ToastMessage from 'components/ToastMessage';
import StatusIndicator from './StatusIndicator';
import { toTitlecase } from 'jsUtils/stringUtils';
import { rollbarInstance } from 'jsUtils/rollbar';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { useRaisePetition, useRaisePetitionPledgeList, useExportRaisePetitionPledgeList, deleteRaisePetitionPledge } from './queries';
import usePagination from 'hooks/usePagination';
import { history } from 'redux/helpers';
import { queryClient } from 'index';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import AmountWithCurrency, {
  AmountRange,
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../components/AmountWithCurrency';
import { PortalizedModalContext } from "contexts/portalizedModalContext";

function Overview() {
  const { authData } = React.useContext(AuthContext);
  let { path, url } = useRouteMatch();
  let { raisePetitionSlug } = useParams()
  const {data:raisePetition, isLoading } = useRaisePetition(raisePetitionSlug)
  const canEditOffer = authData.permittedActions.has(ACTIONS.WRITE_UPDATE_RAISE_PETITION)

  const closeEditorModal = () => {
    history.push(`${url}`)
  }

  if (isLoading)
    return 'Loading...'

  return (
    <ErrorBoundary>
      <OverviewHeader 
        left={toTitlecase(raisePetition.name)}
        right={<StatusIndicator raisePetition={raisePetition} theme='dark' />}
      />
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          {/* <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#offer-overview" role="tab">Overview</a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#pledge-petitioners" role="tab">Petitioners</a>
          </li>
        </ul>
        <div className="tab-content px-3">
          {/* <div className="tab-pane fade show active" id="offer-overview" role="tabpanel">
            <OverviewSubheader left='Petition Overview' right={canEditOffer ? <TextButton title='Edit' onClick={openEditorModal} /> : null } />
            <TwoColumnLayout
              left={<AttributeValue name='Petition name' value={toTitlecase(raisePetition.name)} />}
              right={<AttributeValue name='Company name' value={toTitlecase(raisePetition.company.name)} />}
            />

            <TwoColumnLayout
              left={
                <>
                  <AttributeValue name='Petition goal' value={<AmountWithCurrency number={raisePetition.goal_amount} />} />
                </>
              }
            />
          </div> */}

          <div className="tab-pane fade show active" id="pledge-petitioners" role="tabpanel">
            <OverviewSubheader left='Performance' />
            <div className="two-col-layout">
              <div className="first-col p-3">
                <AttributeValue
                  name={
                    <>
                      <span>Pledged:</span>
                      <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                        <AmountRange
                          start={raisePetition.pledged_amount}
                          end={raisePetition.goal_amount}
                          separator="/"
                        />
                      </span>
                    </>
                  }
                  value={
                    <div style={{ width: '300px', backgroundColor: '#fff', flexDirection: 'row'}}>
                      <div style={{width: `${Math.min(Math.max(raisePetition.pledged_amount*100 / raisePetition.goal_amount, .5), 100) || 0}%`, height: '18px', backgroundColor: '#5AB4AD'}} />
                    </div>
                  }
                />
              </div>
              <div className="second-col p-3">
                <AttributeValue name='Number of pledges' value={raisePetition.pledge_count} />
              </div>
            </div>
            <Pledges raisePetitionSlug={raisePetition.slug} />
          </div>
        </div>
      </div>
      <Switch>
        <Route path={`${path}/edit`} component={(props) => (
            <EditorModal onRequestClose={closeEditorModal}>
              <Editor />
            </EditorModal>
          )}
        />
      </Switch>
    </ErrorBoundary>
  )
}

function Pledges({raisePetitionSlug}) {
  const queryClient = useQueryClient();
  const { authData } = React.useContext(AuthContext);
  const canDownloadSubscribersCSV = authData.permittedActions.has(ACTIONS.READ_DOWNLOAD_RAISE_PETITION_PLEDGES)
  const pageConfig = usePagination();
  const pledges = useRaisePetitionPledgeList(raisePetitionSlug, pageConfig.current)
  const deleteModal = React.useContext(PortalizedModalContext);

  const _exportPreofferSubscriberList = useExportRaisePetitionPledgeList(raisePetitionSlug)
  const exportSubscribers = async () => {
    queryClient.refetchQueries(
      ['raise-petition', raisePetitionSlug, 'exportpledgelist'],
      {
        enabled: true,
      }
    );
  }

  const deleteMutation = useMutation(deleteRaisePetitionPledge, {
    onSuccess: () => {
      queryClient.invalidateQueries(['raise-petition', raisePetitionSlug, 'pledge-list']);
      queryClient.invalidateQueries(['raise-petition', raisePetitionSlug]);
      ToastMessage.success(`Pledge deleted successfully.`);
      deleteModal.closeModal();
    },
    onError: (error) => {
      console.log(error);
      renderRailsErrors(error);
      rollbarInstance.warning("Error deleting raise petition pledge", {error});
      deleteModal.closeModal();
    },
  });

  const deleteHandler  = (pledgeId, petitionerName) => {
    deleteModal.setModalContent(
      'Delete petition?',
      <>
        <p>Are you sure you want to delete the pledge from {petitionerName}?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteMutation.mutate({
                raise_petition_slug: raisePetitionSlug,
                pledge_id: pledgeId,
              })
            }}
          >
            Confirm
          </button>
          <button className="btn-black-outline"
            onClick={() => deleteModal.closeModal()}
          >
            Cancel
          </button>
        </div>
      </>,
      'sm'
    );
    deleteModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (pledges.isSuccess)
        return pledges.data.data
      else
        return []
    },
    [pledges]
  );

  const columns = React.useMemo(
    () => {
      if (pledges.isSuccess){
        return [
          {
            Header: 'Petitioner name',
            accessor: 'user.name'
          },
          {
            Header: 'Email',
            accessor: 'user.email',
          },
          {
            Header: <>Amount&nbsp;<CurrencyBadge /></>,
            accessor: 'amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: 'Petitioned At',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd, yyyy, hh:mm a')
          },
          {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
              <>
                <button className="btn btn-sm btn-link single-action" onClick={
                  (e) => {
                    deleteHandler(row.original.id, row.original.user.name)
                    e.stopPropagation()
                  }
                }>
                  <FaTrash className='fs-14 color-black'/>
                </button>
              </>
            )
          },
        ]
      }
      else{
        return []
      }
    },
    [pledges]
  );

  return (
    <ErrorBoundary>
      <OverviewSubheader
        left='Petitioners'
        right={
          canDownloadSubscribersCSV ?
            <ButtonDark title='Export All' onClick={exportSubscribers}/> : null
        }
      />
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={pledges?.data?.pagy}
        loading={pledges.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={pledges.isLoading}
        loadingMsg="Loading pledges..."
        noDataMsg="No pledges found."
      />
    </ErrorBoundary>
  );
}
Overview.defaultProps = {};

Overview.propTypes = {};

export default Overview;