import React from "react";
import OverviewSubheader from "../../components/OverviewSubheader";
import {FaExclamationTriangle} from "react-icons/fa"
import AttributeValue from "../../components/AttributeValue";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import { toTitlecase } from "../../../jsUtils/stringUtils";

function Issue({ issue }) {
  const showExtraDetail = issue?.extra_detail?.length >= 1;
  const extraDetails = issue?.extra_detail && issue?.extra_detail[0]
  return (
    <div>
      <div className={`highlighted-tile border mb-1 d-flex flex-column justify-content-around`}>
        <div>
          <FaExclamationTriangle color={issue?.type == "kyc" ? "#ffc107" : "#A3050D"} className="mr-1" /> {issue.message}
        </div>
      </div>
      {showExtraDetail && 
        extraDetails?.list ?
          <TwoColumnLayout
            left={<AttributeValue name="List" value={toTitlecase((extraDetails?.list).toLowerCase() ?? "-")}/>}
            right={<AttributeValue name="Score" value={extraDetails?.score ?? "-"}/>}/>
       : extraDetails &&
          <div className={`highlighted-tile border mb-1 d-flex flex-column justify-content-around`}>
            <div>Extra Detail: {extraDetails}</div>
          </div>
        }
    </div>
  )
}

function Issues({ issues, name}){
  return (
    <>
      <OverviewSubheader left={ `${name} Results from Automated Check` } />
      {!issues.length && <span className="highlighted-tile">{ `No ${name} issues found by Service Provider(NC).` }</span>}
      {issues.map((issue, index) => <Issue key={index} issue={issue} />)}
    </>
  )
}

export default function KycAMLIssueList({ amlException }) {
  const kycIssues = amlException.issues.filter(i => i.type === 'kyc')
  const amlIssues = amlException.issues.filter(i => i.type === 'aml')

  return (
    <>
      <Issues name="KYC" issues={kycIssues} />
      <Issues name="AML" issues={amlIssues} />
    </>
  );
}