import React,{ useState} from 'react';
import ButtonDark from 'admin/components/ButtonDark';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs'

function ReadMore({children, id, buttonType = "button"}) {
  const [ open, setOpen ]  = useState(false)
  return (
    <div id={id} accordionid={id} collapseid={id}>
      <div className="more-content">
        { open && <div className="more-content-detail mb-2">
          {children}        
        </div> }
        {buttonType == "button" &&
                <ButtonDark
                title={`${open ? 'Read less' : 'Read more'}`}
                onClick={()=> setOpen(!open)}
              /> }
        {
          buttonType == "text" && 
          <div onClick={()=> setOpen(!open)} className="pl-2 cursor-pointer more-content-detail poppins fs-11" style={{color: "#888"}}>{open ? "less": "more"} details{open ? <BsChevronUp className="ml-1"/> : <BsChevronDown className="ml-1"/>}</div>
        }  
      </div>
    </div>
  )
}

export default ReadMore;