import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from 'redux/user'

const PasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Required'),
  password: Yup.string()
    .required('Required')
    .min(6, 'At least 6 characters required'),
});

export default function UpdatePasswordForm({ onSubmit=null }) {
  return(
      <Formik
        initialValues={{
          old_password: '',
          password: '',
          passwordConfirmation: ''
        }}
        validationSchema={PasswordSchema}
        onSubmit={async (values, { props, setSubmitting }) => {
          const {passwordConfirmation, ...attrs} = values
          updatePassword(attrs, onSubmit)
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.old_password && errors.old_password ? "is-invalid" : ""}`}
                name="old_password"
                id="old_password"
                placeholder="Old Password"
              />
              <label htmlFor="old_password">Old Password</label>
              <ErrorMessage name="old_password" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                name="password"
                id="password"
                placeholder="Password"
              />
              <label htmlFor="password">Password</label>
              <ErrorMessage name="password" component="div" className="invalid-feedback text-left"/>
            </div>
            <div className='portalized-modal-footer'>
              <button type="submit" className="btn-black" disabled={isSubmitting}>
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
  );
}
