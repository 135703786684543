import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';

import RichEditor from '../RichEditor';
import { createEditorStateWithContent, createEditorSaveContent} from 'admin/components/RichEditor/editorUtils';
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

const MarketSchema = Yup.object().shape({
  content: Yup.object()
    .test(
      'has text',
      'Article content is required',
      value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
    ),
  market_size: Yup.string()
    .required('Market size is required'),
});

export default function MarketForm({
  pitch:{market_section={}, pitchable_id}, updateMethod
}) {
  const { offerSlug } = useParams()
  const queryClient = useQueryClient();
  
  if(market_section === null) market_section = {};

  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['admin', 'offers', offerSlug, 'pitch'],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
    <div id="marketForm">
      <Formik
        initialValues={{
          id: market_section.id,
          content: createEditorStateWithContent(market_section.content),
          market_size: market_section.market_size || '',
        }}
        validationSchema={MarketSchema}
        enableReinitialize

        onSubmit={async values => {
          const { id, market_size, content } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                market_section_attributes: {
                  id,
                  market_size,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Market</label>
                <div className="form-group">
                  <div className="form-label-group">
                    <Field type="text"
                      id="market_size"
                      name="market_size"
                      className={`form-control ${touched.market_size && errors.market_size ? "is-invalid" : ""}`}
                      placeholder="Market size in USD"
                    />
                    <label htmlFor="market_size">Market size in USD</label>
                    <ErrorMessage name="market_size" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={market_section.id} sectionType="market"/>

            <LastUpdatedAt updatedAt={market_section.updated_at}/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  );
}
