import React from "react";
import { useAmlException } from "investor/queries";
import KYCReviewNote from "./KYCReviewNote";
import AMLReviewNote from "./AMLReviewNote";
import UploadDocument from "./UploadDocument";
import UploadedDocuments from "./UploadedDocuments";
import Instructions from "./Instructions";
import { rollbarInstance } from 'jsUtils/rollbar';

export default function AmlException({ clientId }) {
  const { isLoading, data: amlException, isError, error } = useAmlException(clientId);

  if(isLoading){
    return (<div className="weight-600">Loading...</div>);
  }

  if(isError){
    rollbarInstance.warning(`Error while loading AML data for client:${clientId}`, error);
    return null;
  }

  const clientType = amlException.client.entity_type;
  const entityDocuments = amlException.entity_documents;
  const client = amlException.client;
  const clientCountry = amlException.client.country;

  return (
    <div className="mb-4">
      <h4 className="m-b-15 fs-18 weight-800">{clientType === 'person' ? "Identity": "Entity"} Verification</h4>
      { amlException.status !== 'cleared' &&  <Instructions clientCountry={clientCountry} clientType={clientType} amlException={amlException} /> }
      <AMLReviewNote amlException={amlException} clientType={clientType}/>
      <KYCReviewNote clientCountry={clientCountry} amlException={amlException} clientType={clientType} clientId={clientId}/>
      {amlException.status !== 'cleared' &&
        <div className="content-layer bg-white p-4 border border-dark my-4">
          <p className="d-flex flex-wrap align-items-center mb-1 weight-700">Upload documents for our team to review</p>
          <UploadDocument
            category="aml_exception"
            clientId={clientId}
            clientType={clientType}
            isVerified={false}
          />
        </div>
      }
      { (amlException.status !== 'cleared' && entityDocuments.length > 0) && <UploadedDocuments documents={entityDocuments} /> }
    </div>
  );
}
