import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { createRaisePetition } from './queries';
import OverviewForm from './OverviewForm';
import { history } from 'redux/helpers';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

function OverviewCreator({companyId, onCreateSuccess}) {
  const queryClient = useQueryClient();
  const createRaisePetitionMutation = useMutation(
    createRaisePetition,
    {
      onSuccess: (raisePetition) => {
        ToastMessage.success("Raise petition created.")
        queryClient.invalidateQueries(
          ['raise-petition', 'list'],
          {
            exact: false,
          }
        )
        onCreateSuccess();
        history.push(`/admin/raise-petitions/${raisePetition.slug}`)
      },
      onError: (error) => {
        ToastMessage.error('Raise petition create failed.')
        renderRailsErrors(error)
      },
    }
  )

  const onSubmit = (values) => {
    createRaisePetitionMutation.mutate({
      values: {
        ...values,
        company_id: companyId,
      }
    })
  }

  return (
    <OverviewForm companyId={companyId} onSubmit={onSubmit}/>
  );
}

export default OverviewCreator;
