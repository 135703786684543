import React from "react";

import { formatDate } from "../../../jsUtils/dateTimeUtils";

import { ResponsiveTable } from "admin/components";
import OverviewSubheader from "../OverviewSubheader";
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from "../../../components/AmountWithCurrency";

const InvestmentPayments = ({ payments }) => {
  if (!payments){
    payments = [];
  }

  const columns = [
    {
      Header: <>Amount&nbsp;<CurrencyBadge /></>,
      accessor: "amount",
      Cell: ({ value }) => <FormattedNumberWithSymbol number={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => value ?? "-",
    },
    {
      Header: "Failure Reason",
      accessor: "failure_reason",
      className: 'failure-reason-column',
      Cell: ({ value }) => value ?? "-",
    },
    {
      Header: "Notified At",
      accessor: "failure_notification_sent_at",
      Cell: ({ value }) => value ? formatDate(value) : "-",
    },
  ];

  return (
    <>
      <OverviewSubheader left="Payments" />
      <div className="reset-px-3">
        <ResponsiveTable
          columns={columns}
          data={payments}
          loading={false}
          loadingMsg="Loading payments..."
          noDataMsg="No payments found."
        />
      </div>
    </>
  );
};

export default InvestmentPayments;
