import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useUserAffiliations from 'redux/userAffiliations';
import './../admin/components/affiliation/affiliation.scss';
import { AFFILIATION_LABELS } from './../config/constants';
import { MdClose } from 'react-icons/md';
import { toTitlecase } from '../jsUtils/stringUtils';

const UserAffiliationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
})

export default function UserAffiliationsEditor({ companyId, companyName, onClose }) {
  const userAffiliations = useUserAffiliations();
  const { affiliations } = userAffiliations;

  return (
      <div className="affiliation-inner">
        <div>
          <p className="fs-10 instruction-text-for-mobile">How are you affiliated with {companyName}?</p>
          <div className="affiliation-badges">
            {affiliations.map(affiliation => {
              return <AffiliationBadge key={affiliation.id} affiliation={affiliation} companyId={companyId} onClose={onClose}/>
            })}
          </div>
        </div>
        <Formik
          initialValues={{
            title: '',
          }}
          validationSchema={UserAffiliationSchema}
          onSubmit={async (values, { props, resetForm, setSubmitting }) => {
            setSubmitting(true);
            await userAffiliations.createAffiliation(companyId, values, () => onClose())
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <div className="form-inner-row">
                <div className="form-label-group">
                  <Field type="select"
                    id="title"
                    className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                    name="title"
                    placeholder="Affliation Title"
                    component="select"
                  >
                    <option value="">Select affiliation title</option>
                    {
                      AFFILIATION_LABELS.map(al => <option
                        key={al.value}
                        value={al.value}
                        disabled={userAffiliations.affiliations.some(a => a.title === al.value)}>
                        {al.label}
                      </option>
                      )
                    }
                  </Field>
                  <label htmlFor="title">Affliation Title</label>
                  <ErrorMessage name="title" component="div" className="invalid-feedback text-left" />
                </div>
              </div>
              <div className="portalized-modal-footer">
                <button className="btn-black" disabled={isSubmitting}>Create</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
  );
}

const AffiliationBadge = ({affiliation, companyId, onClose}) => {
  const userAffiliations = useUserAffiliations();

  const removeAffiliation = (affiliationId) => {
    const confirmation = window.confirm("Are you sure you want to remove the affiliation?")
    if (confirmation) {
      userAffiliations.deleteAffiliation(companyId, affiliationId, () => onClose())
    }
  }

  return (
    <span className={`custom-badge ${affiliation.approved ? '' : 'pending'}`}>
      {`${toTitlecase(affiliation.title)} ${affiliation.approved ? '' : '(Pending)'}`}
      <MdClose color="#111" size="13px" onClick={() => removeAffiliation(affiliation.id)} />
    </span>
  )
}
