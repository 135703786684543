import React from 'react'
import { PortalizedModalContext } from './Context'

export function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => { ref.current = value }, [value])

  return ref.current
}

export const usePortalizedModal = (__modalId__) => {
  const [ modals, setModals ] = React.useState([])
  const prevModals = usePrevious(modals) || []

  const context = React.useContext(PortalizedModalContext);
  
  React.useEffect(() => {
    const addedModals = modals.filter(modal => !prevModals.includes(modal))
    const removedModals = prevModals.filter(modal => !modals.includes(modal))

    addedModals.forEach(modal => { context.openModal(modal) })
    removedModals.forEach(modal => { context.closeModal(modal) })
  }, [modals])
  
  const open = (__modalId__) => (modal, label = '', role = 'dialog', userDismissable = true) => {
    let resolver;
    const modalPromise = new Promise((resolve) => { resolver = resolve })
    const newModal = {__modalId__, modal, resolver, label, role, userDismissable}
    setModals(currentModals => [...currentModals, newModal ])

    return modalPromise
  }
  
  const close = (__modalId__) => (result) => {
    setModals(currentModals => {
      const thisModal = currentModals.find(modal => modal.__modalId__ === __modalId__)
      const newModalEntries = [...currentModals]
      newModalEntries.splice(newModalEntries.indexOf(thisModal), 1)
      thisModal.resolver(result)
      return newModalEntries
    })
  }
  
  return { open: open(__modalId__), close: close(__modalId__) };
}