import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './SideDrawer.scss'
import { FaLinkedin, FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { logoutUser } from 'redux/session'
import { AuthContext } from "contexts/authContext";
import { whiteLogoSmall } from '../../../assets/images/index';

const InvestorSideDrawer = ({ onClose, show }) => {
  const { authData, clearAuthContext } = React.useContext(AuthContext);
  const currentUser = authData?.currentUser
  const hasIssuerRole = currentUser?.roles.findIndex(role => role.category === "issuer") != -1
  let drawerClasses = 'side-drawer';

  if (show) {
    drawerClasses = 'side-drawer open';
  }

  const logoutThisUser = () => {
    logoutUser();
    clearAuthContext();
    onClose();
  }

  return (
    <nav className={drawerClasses}>
      <div className="side-drawer-header">
        <NavLink onClick={onClose} to={'/'}>
          <img src="https://spacedventure-marketing.s3.amazonaws.com/Icon+Stroke+White.png" className='logo' alt='logo' />
        </NavLink>
        <button className="drawer-close-btn" onClick={onClose}>
          <MdClose color="#fff" size="25px" />
        </button>
      </div>
      <ul className="mobile-drawer-list">
        {/* <li>
          <NavLink onClick={onClose} exact to="/offerings" activeClassName="drawer-nav-selected">Invest</NavLink>
        </li> */}
        {
          hasIssuerRole &&
          <li><a target="_blank" href={process.env.REACT_APP_ISSUER_APP_URL} rel="noopener noreferrer">Company</a></li>
        }
        <li>
          <a href='/account/settings'>Account</a>
        </li>
        <li>
          <NavLink onClick={onClose} exact to="/account/settings" className='chlid-list-item' activeClassName="drawer-nav-selected">My Account</NavLink>
        </li>
        <li>
          <NavLink onClick={onClose} exact to="/account/investor-info" className='chlid-list-item' activeClassName="drawer-nav-selected">Investor Info</NavLink>
        </li>        
        <li>
          <NavLink onClick={onClose} exact to="/account/investments" className='chlid-list-item ' activeClassName="drawer-nav-selected">My Investments</NavLink>
        </li>
        <li>
          <a target="_blank" href="https://www.33fg.com/" className='chlid-list-item mb-2' rel="noopener noreferrer">Mach33</a>
        </li>        
        <li className='entry-buttons'>
          <Link onClick={logoutThisUser} to="#" className='logout-btn'>Log Out</Link>
        </li>
        {/* <li>
          <label className='drawer-items-label'>Learn</label>
        </li> */}
        {/* <li>
          <NavLink onClick={onClose} to="/resources" className='chlid-list-item' activeClassName="drawer-nav-selected">Resources</NavLink>
        </li> */}
        {/* <li>
          <NavLink onClick={onClose} to="/accredited-investors" className='chlid-list-item' activeClassName="drawer-nav-selected">For Investors</NavLink>
        </li> */}
        {/* <li>
          <NavLink onClick={onClose} to="/raise" className='chlid-list-item mb-2' activeClassName="drawer-nav-selected">For Founders </NavLink>
        </li> */}
        {/* <li>
          <label className='drawer-items-label'>Resources</label>
        </li> */}
        {/* <li>
          <a target="_blank" href="https://blog.spacedventures.com/" className='chlid-list-item' rel="noopener noreferrer">Blog</a>
        </li> */}

        {/* <li>
          <a target="_blank" href="https://spacedventures.zendesk.com/hc/en-us" className='chlid-list-item' rel="noopener noreferrer">FAQs</a>
        </li> */}
        {/* <li>
          <NavLink onClick={onClose} to="/mediakit" className='chlid-list-item' activeClassName="drawer-nav-selected">Media Kit</NavLink>
        </li> */}
        {/* <li>
          <NavLink onClick={onClose} to="/newsletter" className='chlid-list-item mb-2' activeClassName="drawer-nav-selected">Newsletter</NavLink>
        </li> */}
        {/* <li>
          <label className='drawer-items-label'>Company</label>
        </li>
        <li>
          <NavLink onClick={onClose} to="/about" className='chlid-list-item' activeClassName="drawer-nav-selected">About Us</NavLink>
        </li>
        <li>
          <NavLink onClick={onClose} to="/press" className='chlid-list-item' activeClassName="drawer-nav-selected">Press</NavLink>
        </li>
        <li>
          <NavLink onClick={onClose} to="/privacy-policy" className='chlid-list-item' activeClassName="drawer-nav-selected">Privacy Policy</NavLink>
        </li>
        <li>
          <NavLink onClick={onClose} to="/terms-of-service" className='chlid-list-item mb-2' activeClassName="drawer-nav-selected">Terms of Service</NavLink>
        </li> */}
      </ul>
      {/* <div className="social-icon-box">
        <a href="https://www.facebook.com/SpacedVentures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaFacebookSquare /></a>
        <a href="https://twitter.com/SpacedVentures" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitterSquare /></a>
        <a href="https://www.linkedin.com/company/spaced-ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedin /></a>
        <a href="https://www.instagram.com/spaced.ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagramSquare /></a>
        <a href="https://www.youtube.com/channel/UCw2y-5R3Ywh7Hjy_Anw8wEg" target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutubeSquare /></a>
      </div> */}
    </nav>
  )
}


export default InvestorSideDrawer;