import React from 'react';
import "./OverviewHeader.scss";

export default function OverviewHeader({left, right}) {
  return(
    <div className="overview-header">
      <div className="row align-items-center">
        <div className="col-sm-6 overview-header-left d-flex">
          <h5 className="weight-700 mb-0 fs-7 montserrat mr-2">{left}</h5>
        </div>
        <div className="col-sm-6 overview-header-right mb-md-0 mb-3">
          {right}
        </div>
      </div>
    </div>
  );
}
