import React, {useState, useEffect} from 'react';
import produce from "immer";
import ToastMessage from '../../components/ToastMessage';
import { closeIcon, checkBlackIcon, deleteIcon, editIcon } from '../../assets/images/index';
import ActionButton from '../../ActionButton';
import OverviewSubheader from 'admin/components/OverviewSubheader'
import TextButton from 'admin/components/TextButton'
import {affiliationApi} from 'api/affiliation'
import UpdateAffiliationForm from './affiliation/update';
import CreateAffiliationForm from './affiliation/create';
import { toTitlecase } from '../../jsUtils/stringUtils';

export default function Affiliations({companyId}) {
  const [affiliations, setAffiliations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createAffiliation, setCreateAffiliation] = useState(false);
  const [updateAffiliation, setUpdateAffiliation] = useState(null);

  useEffect(() => {
    fetchAffiliations();
  }, [])

  const fetchAffiliations = () => {
    setLoading(true)
    affiliationApi.list(companyId).then(
      res => {
        setAffiliations(res);
      },
      error => {
        console.log(error);
        ToastMessage.error('Could not fetch affiliations, try again later.');
      }
    ).finally(() => {
      setLoading(false);
    })
  }

  const deleteAffiliation = (id) => {
    const confirmation = window.confirm('Are you sure you want to delete affiliation?')
    if (confirmation){
      setLoading(true)
      affiliationApi.purge(companyId, id).then(
        res => {
          setAffiliations(affiliations.filter(a => a.id !== id))
        },
        error => {
          console.log(error);
          ToastMessage.error('Could not delete affiliation, try again later.');
        }
      ).finally(() => {
        setLoading(false);
      })
    }
  }

  const onUpdateSuccess = async (affiliation) => {
    setAffiliations(
      produce(affiliations, draftState => {
        const currentIndex = affiliations.findIndex(a => a.id === affiliation.id);
        draftState.splice(currentIndex, 1, affiliation)
      })
    );
    setUpdateAffiliation(null);
  }

  const onCreateSuccess = async (affiliation) => {
    setAffiliations(
      produce(affiliations, draftState => {
        draftState.push(affiliation)
      })
    );
    setCreateAffiliation(false);
  }

  const approveAffiliation = (id) => {
    setLoading(true)
    affiliationApi.approve(companyId, id).then(
      affiliation => {
        setAffiliations(
          produce(affiliations, draftState => {
            const currentIndex = affiliations.findIndex(a => a.id === affiliation.id);
            draftState.splice(currentIndex, 1, affiliation)
          })
        );
      },
      error => {
        console.log(error);
        ToastMessage.error('Could not approve affiliation, try again later.');
      }
    ).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <OverviewSubheader left='Affiliated users' right={<TextButton title='Add Affiliation' onClick={() => setCreateAffiliation(true)}/>} />
      {createAffiliation && <CreateAffiliationForm companyId={companyId} onClose={() => setCreateAffiliation(false)} onSuccess={onCreateSuccess}/>}
      {updateAffiliation && <UpdateAffiliationForm companyId={companyId} affiliation={affiliations.find(a=> a.id === updateAffiliation)} onClose={() => setUpdateAffiliation(null)} onSuccess={onUpdateSuccess}/>}
      <div className="table-responsive action-table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Member name</th>
              <th>Email</th>
              <th>Affiliation</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              loading && <tr style={{height: '40px'}}>
                  <td className='text-center'>Loading affiliations</td>
                </tr>
            }
            {
              !loading && affiliations.length === 0 && <tr style={{height: '40px'}}>
                  <td className='text-center'>No affiliations created</td>
                </tr>
            }
            {
              affiliations.map(a =>
                <tr key={a.id} className="bg-white">
                  <td>{toTitlecase(a.username)}</td>
                  <td>{a.email}</td>
                  <td>{toTitlecase(a.title)}</td>
                  <td>
                    <div className="table-actions-box-square">
                      {
                        a.approved ?
                          <ActionButton tooltipText="Edit" icon={editIcon} clickHandler={() => setUpdateAffiliation(a.id)} />
                          :
                          <ActionButton tooltipText="Approve" icon={checkBlackIcon} clickHandler={() => approveAffiliation(a.id)} />
                      }
                      <ActionButton tooltipText="Delete" icon={deleteIcon} clickHandler={() => deleteAffiliation(a.id)} />
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
