import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import RichEditor from '../RichEditor';
import { createEditorStateWithContent, createEditorSaveContent} from 'admin/components/RichEditor/editorUtils';
import { closeIcon } from "../../../assets/images/index";
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import './OfferForm.scss'
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';

const TractionSchema = Yup.object().shape({
  content: Yup.object()
    .test(
      'has text',
      'Article content is required',
      value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
    ),
  points: Yup.array()
    .of(Yup.string().required("Can't be blank"))
    .required('Must have tractios points') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Minimum of 1 traction point')
    .max(5, 'Maximum of 5 traction point'),
  });

export default function TractionForm({
  pitch:{traction_section={}, pitchable_id}, updateMethod
}) {
  const { offerSlug } = useParams()
  const queryClient = useQueryClient();
  
  if(traction_section === null) traction_section = {};

  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['admin', 'offers', offerSlug, 'pitch'],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
    <div id="tractionForm">
      <Formik
        enableReinitialize
        initialValues={{
          content: createEditorStateWithContent(traction_section.content),
          points: traction_section.points || [],
          id: traction_section.id,
        }}
        validationSchema={TractionSchema}
        onSubmit={async values => {
          const { id, points, content } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                traction_section_attributes: {
                  id,
                  points,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <FieldArray
                  name='points'
                  render={arrayHelpers => (
                    <>
                      { values?.points?.map((point, index) => {
                          return (
                            <div className="traction-point mb-3" key={index.toString()}>
                              <div className="form-label-group show-org-placeholder mb-0 mr-3">
                                <Field
                                  id={`points-${index}`}
                                  name={`points.${index}`}
                                  placeholder="Traction Point"
                                  className={`form-control ${errors?.points && errors?.points[index] && typeof(errors?.points) !== 'string' ? "is-invalid" : ""}`} />
                                <label htmlFor={`points-${index}`}>Traction Point</label>
                                <ErrorMessage name={`${typeof(errors?.points) !== 'string' ? `points.${index}` : null}`} component="div" className="invalid-feedback text-left" />
                              </div>
                              <img src={closeIcon} onClick={() => arrayHelpers.remove(index)} className="cursor-pointer" alt="close icon" />
                            </div>
                          )
                        })
                      }
                      {values.points.length < 5 && <span onClick={() => arrayHelpers.push('')} className={`cursor-pointer fs-10 d-block poppins ${typeof(errors?.points) === 'string' ? "is-invalid" : ""}`}>+ Add new point</span>}
                    </>
                  )}
                >
                </FieldArray>
                <ErrorMessage name={`${typeof(errors?.points === 'string') ? 'points' : null}`} component="div" className="invalid-feedback text-left" />
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={traction_section.id} sectionType="traction"/>

            <LastUpdatedAt updatedAt={traction_section.updated_at} />
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  );
}
