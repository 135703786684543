import React from 'react';

export default function TableChart({data, options={}, onClickRow, onPageChange, containerId}) {
  if(data) {
    window.google.charts.setOnLoadCallback(function() {
      const chartData = new window.google.visualization.DataTable()
      data[0].forEach(e => {
        chartData.addColumn(e.type, e.label)
      });
      chartData.addRows(data.slice(1, data.length))

      const chartDataView = new window.google.visualization.DataView(chartData)
      chartDataView.setColumns([1, 2, 3, 4]); //only show these column

      const tableChart = new window.google.visualization.Table(document.getElementById(containerId))

      const selectHandler = () => {
        const selection = tableChart.getSelection()
        if (selection.length === 1) {
          const [selectedItem] = selection
          const { row } = selectedItem
          onClickRow(chartData.getValue(row, 0))
        }
      }

      const errorHandler = (error) => {
        document.getElementById(containerId).innerHTML = `
          <div class="flex-cover">
            No companies match your query.
          </div>
        `
      }

      const pageHandler = ({page}) => { onPageChange(page) }

      window.google.visualization.events.addListener(tableChart, 'select', selectHandler);
      window.google.visualization.events.addListener(tableChart, 'error', errorHandler);
      window.google.visualization.events.addListener(tableChart, 'page', pageHandler);

      tableChart.draw(chartDataView, options);
    });
  }
  return null;
};
