import React from "react";
import {
  progressBarPercentage,
  raisedFundsPercentage,
} from "jsUtils/offerUtils";

function PreofferCardSummary({ preoffer }) {
  const fundsPercentage = raisedFundsPercentage(
    preoffer.committed,
    preoffer.goal_amount
  );

  return (
    <div className="medium-progress-bar">
      <div className="progress-box">
        <div className="progressbar">
          <div
            className="progress"
            style={{ width: `${progressBarPercentage(fundsPercentage)}%` }}
          ></div>
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="funded">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-2 text-lowercase">of goal</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-count">
            {preoffer.preoffer_subscribers_count}
          </span>
          <span className="investors-text ml-2">Interested</span>
          <span className="mx-1">|</span>
          <span className="investors-text">{preoffer.type}</span>
        </div>
      </div>
    </div>
  );
}

export default PreofferCardSummary;
