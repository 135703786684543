import React from "react";
import OverviewSubheader from "../OverviewSubheader";
import _ from 'underscore.string'
import ReactTooltip from "react-tooltip";
import {BsInfoCircle} from 'react-icons/bs'
import ReadMore from "../../../investor/components/pitch/ReadMore";

const AMLDescriptionsMap = {
  subscription_agreement_not_signed: "The Subscription Agreement has not been signed by the investor.",
  aml_pending: "There is an issue with this investor's Identity Verification and an AML Exception has been created.",
  funds_not_cleared: "This clearing failure is applied to the investment right away and is'nt removed until the funds have been received and cleared, which can take up to 10 days.",
  oversubscribed: "The funds received does not match the investment amount.",
  ofac_hit: "Investor may be on a blocked list that is monitored by OFAC. This clearing failure can also be created in error. Please contact Fund America support about this investment.",
  accreditation_pending: "Accreditation is pending.",
  trade_review_pending: "Trade reviews require broker-dealers.",
  source_of_funds_mismatch: "Funds mismatch. Please contact Fund America support",
}

const ClearingFailures = ({ investment }) => {
  const { clearing_failures } = investment

  if(!clearing_failures?.length) {
    return null
  }

  return (
    <>
      <OverviewSubheader left={`Clearing Failures (${clearing_failures.length})`} />
      <div>
        {
          clearing_failures.map(function(cf, i) {
            return <div key={i} className="mt-2" style={{backgroundColor: "#fff", padding: "10px", borderRadius: "2px"}}>
            <div className="font-weight-bold px-2 pb-1 fs-9">{_.titleize(_.humanize(cf))}</div>
           <ReadMore id={`read-more-clearing-failure-${i}`} buttonType="text"> <div className="px-2 poppins fs-10">{AMLDescriptionsMap[cf]}</div></ReadMore>
            </div>
          })
        }
      </div>
    </>
  );
};

export default ClearingFailures;
