import React from 'react';

export default function ButtonDark({title, onClick, iconName, disabled = false}) {
  return (
    <button
      disabled={disabled}
      className={`btn-black rounded-0 px-3 py-2 fs-9 ${iconName && 'd-flex align-items-center'}`}
      onClick={onClick}
    >
      {iconName && iconName }
      <span className={`${iconName && 'ml-1'}`}>{title}</span>
    </button>
  )
}
