import React, { useEffect } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import RichEditor from '../RichEditor';
import { createEditorStateWithContent, createEditorSaveContent} from 'admin/components/RichEditor/editorUtils';
import { closeIcon } from "../../../assets/images/index";
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import { useBusinessModelList } from './queries';
import { useParams } from 'react-router-dom';

const BusinessModelSchema = Yup.object().shape({
  content: Yup.object()
    .test(
      'has text',
      'Article content is required',
      value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
    ),
  business_model_ids: Yup.array()
    .of(Yup.string().required('Business model is required'))
    .required('Must have business model')
    .min(1, 'Minimum of 1 BusinessModel point'),
  });

export default function BusinessModelForm({
  pitch:{business_model_section={}, business_models=[], pitchable_id}, updateMethod
}) {
  const { offerSlug } = useParams()
  const queryClient = useQueryClient();

  const {data:allBusinessModels=[], isLoading} = useBusinessModelList()

  if(business_model_section === null) business_model_section = {};

  const pushBusinessModelId = (arrayHelpers, id, values) => {
    if (id.match(/^\d+$/) && values.business_model_ids?.findIndex(item => item == id) < 0)
      arrayHelpers.push(parseInt(id))
  }

  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['admin', 'offers', offerSlug, 'pitch'],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
    <div id="businessModelForm">
      <Formik
        enableReinitialize
        initialValues={{
          id: business_model_section.id,
          content: createEditorStateWithContent(business_model_section.content),
          business_model_ids: business_models.map(model => model.id),
        }}
        validationSchema={BusinessModelSchema}
        onSubmit={async values => {
          const { id, business_model_ids, content } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                business_model_ids: business_model_ids,
                business_model_section_attributes: {
                  id,
                  content: createEditorSaveContent(content)
                }
              }
            }
          })
        }}
      >
        {(formikBag) => {
          const { values, touched, errors, isSubmitting, setFieldValue, setFieldTouched } = formikBag
          return <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left d-block"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <div className="form-label-group">
                <FieldArray
                  name='business_model_ids'
                  render={arrayHelpers => (
                    <>
                      <Field
                        component="select"
                        id='business_model_ids'
                        placeholder='Select business model'
                        className={`form-control ${touched.business_model_ids && errors.business_model_ids ? "is-invalid" : ""}`}
                        onChange={ (e) => pushBusinessModelId(arrayHelpers, e.target.value, values) }
                        onBlur= { (value) => setFieldTouched("business_model_ids", value) }>
                          <option value="">Select Business Model</option>
                          {allBusinessModels?.map(businessModel =>
                            <option value={businessModel.id} key={businessModel.id}>{businessModel.name}</option>)}
                      </Field>
                      <label htmlFor="business_model_ids">Business model</label>
                      <ErrorMessage name="business_model_ids" component="div" className="invalid-feedback text-left d-block"/>

                      {values?.business_model_ids?.map((businessModelId, index) => {
                          return(
                            <div key={index} className="traction-point mt-3 mb-3">
                              <div className="form-label-group show-org-placeholder mb-0 mr-3">
                                <Field
                                  readOnly
                                  id={`business_model_ids-${index}`}
                                  name={`business_model_ids.${index}`}
                                  value={allBusinessModels.find(a => a.id === businessModelId)?.name}
                                  className="form-control" />
                                <label htmlFor={`business_model_ids-${index}`}>Business Model</label>
                              </div>
                              <img src={closeIcon} onClick={() => arrayHelpers.remove(index)} className="cursor-pointer" alt="close icon" />
                            </div>
                          )
                        })
                      }
                    </>
                  )}
                >
                </FieldArray>
                </div>
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={business_model_section.id} sectionType="business_model"/>
            <LastUpdatedAt updatedAt={business_model_section.updated_at }/>
            <FormikPromptIfDirty />
          </Form>
        }}
      </Formik>
    </div>
  );
}
