import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch } from "react-router";
import { Redirect, NavLink } from "react-router-dom";
import { useTabs } from "components/ReactTabs";

const OfferFilteringMobileTabs = ({ tabs, activeTab, baseUrl }) => {
  return (
    <div className="horizontal-tabs-dropdown-container">
      <div className="horizontal-tabs-dropdown dropdown black-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
        >
          {activeTab.title}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul className="nav flex-column">
            {tabs.map((tab, index) => (
              <li key={index}>
                <NavLink
                  to={`${baseUrl}/${tab.pathName}`}
                  className={`nav-link ${tab.decoratorClass}`}
                  activeClassName="active"
                  exact
                >
                  {tab.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

function HorizontalRoutedTabs({
  basePath,
  baseUrl,
  tabs,
  tabIdAccessor,
  targetPanelIdAccessor,
  defaultTabId,
  tabRenderer = (tab) => tab.title,
  contentRenderer = (tab) => tab.content,
}) {
  const {
    tabs: tablist,
    getContainerProps,
    getTabProps,
    getPanelProps,
  } = useTabs({
    tabs,
    tabIdAccessor,
    targetPanelIdAccessor,
    defaultTabId,
  });

  const defaultTab = tablist.find((tab) => tab[tabIdAccessor] === defaultTabId);
  return (
    <>
      <div className="pills-wrapper">
        <div className="container">
          <nav>
            <ul className="nav nav-tabs" id="nav-tab" {...getContainerProps}>
              {tablist.map((tab) => (
                <li className="nav-item" {...getTabProps(tab[tabIdAccessor])}>
                  <NavLink
                    to={`${baseUrl}/${tab.pathName}`}
                    className={`nav-link ${tab.decoratorClass}`}
                    activeClassName="active"
                    exact
                  >
                    {tabRenderer(tab)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      <div className="tab-content">
        <Switch>
          {tablist.map((tab) => (
            <Route
              key={tab.pathName}
              path={`${basePath}/${tab.pathName}`}
              component={(props) => (
                <div
                  className="tab-pane active"
                  key={tab[tabIdAccessor]}
                  {...getPanelProps(tab[tabIdAccessor])}
                >
                  <OfferFilteringMobileTabs
                    tabs={tablist}
                    activeTab={tab}
                    baseUrl={baseUrl}
                  />
                  {contentRenderer(tab)}
                </div>
              )}
            />
          ))}
          <Redirect to={`${basePath}/${defaultTab.pathName}`} />
        </Switch>
      </div>
    </>
  );
}

HorizontalRoutedTabs.defaultProps = {};

HorizontalRoutedTabs.propTypes = {
  basePath: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  tabIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string,
  defaultTabId: PropTypes.string.isRequired,
  tabRenderer: PropTypes.func,
  contentRenderer: PropTypes.func,
};

export default HorizontalRoutedTabs;
