import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import buttonRocketIconBlack from '../../assets/images/icons/rocket-icon-blk.svg'
import buttonRocketIconWhite from '../../assets/images/icons/rocket-icon-wht.svg'
import {Link} from "react-router-dom";


export default function ButtonRocket ({text, width, link = "/"  }) {
  const [icon, setIcon] = useState(false);
  return (
    <ButtonContainer width={width} >
   <Link to={link}>
      <button onMouseEnter={() => setIcon(true)} onMouseLeave={() => setIcon(false)} className=" btn-block btn-black-c">
        <div className="rocket-btn">
          <div className="rocket-btn-text">{text}</div>
          <img className="rocket-btn-icon" src={icon ? buttonRocketIconBlack : buttonRocketIconWhite } alt="rocket"></img>
        </div>
      </button>
   </Link>
    </ButtonContainer>
  )
}


const ButtonContainer = styled.div`
  width: ${props => props.width};

  button {
    padding-left: 27px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 776px) {
    width: 100%;
  }

  @media (max-width: 776px) {
    button {
      text-align: center;
      padding-left: 11px;
    }

    .rocket-btn-icon {
      display: none;
    }

    .rocket-btn {
      justify-content: center;
    }
  }

`;