import React from "react";
import {
  progressBarPercentage,
  raisedFundsPercentage,
} from "jsUtils/offerUtils";
import { OFFER_TYPE } from "../../config/constants";

const OfferCardSummary = ({ offer }) => {
  const raisedAmount = offer?.deal?.raised ? parseInt(offer?.deal?.raised) : 0;
  const targetGoal = offer?.deal?.goal || 0;
  const investorsCount = offer?.investor_count || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, targetGoal);

  return (
    <div className="medium-progress-bar">
      <div className="progress-box">
        <div className="progressbar">
          <div
            className="progress"
            style={{ width: `${progressBarPercentage(fundsPercentage)}%` }}
          ></div>
        </div>
      </div>
      <div className="progressbar-summary">
        <div className="funded">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-1">funded</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-count">{investorsCount}</span>
          <span className="investors-text ml-1">Investors</span>
        </div>
        <span className="mx-1">|</span>
        <div className="investors">
          <span className="investors-count">{OFFER_TYPE.labels[offer.type]}</span>
        </div>
      </div>
    </div>
  );
};

export default OfferCardSummary;
