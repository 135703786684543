import React from 'react'
import { useParams, Link } from "react-router-dom";
import {
  usePreoffer
} from 'investor/queries';
import { useUser } from '../redux/user';
import DealInfo from './preoffer/DealInfo';
import InvestmentForm from './preoffer/InvestmentForm';

export default function PreofferIndicateInterest(props) {
  let { preofferId } = useParams();
  const user = useUser();
  const { data: preoffer, isLoading: isLoadingPreoffer } = usePreoffer(preofferId);


  if (isLoadingPreoffer || user.isLoading) {
    return 'Loading...';
  }
  const isSecondary = preoffer.category === "secondary";
  const preofferTypeText = isSecondary ? "Secondary" : "Pre-launch"
  return (
    <>
      <div className="investment-header">
        <div className="container pb-3 pt-first-section">
          <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">
          <Link to={`/prelaunch/${preoffer.slug}`} target="_blank" rel="noopener noreferrer">{preoffer.company?.name}</Link> {preofferTypeText}
          </h1>
        </div>
      </div>
      <div className="investor-formbox investment-form-screen">
        <div className="container">
          <div className="row">
            <div className="col-md-8 py-5 pr-xl-5 investor-formbox-inner1">
              <div className="pr-xl-5">
              </div>
              <div className="invest-form-fields pr-xl-5">
                <InvestmentForm preoffer={preoffer} user={user} />
              </div>
            </div>
            <div className="col-md-4 py-5 bg-lightgrey investor-formbox-inner2">
              <DealInfo preoffer={preoffer} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

