import React from 'react';
import { useParams } from "react-router";
import ToastMessage from '../../../../../components/ToastMessage';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RichEditor,
  createEditorStateWithContent,
  createEditorSaveContent,
  FormikPromptIfDirty,
  Icon,
} from 'admin/components'
import DeleteSectionButton from './../DeleteSectionButton';
import LastUpdatedAt from './../LastUpdatedAt';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import LoadingOverlay from 'react-loading-overlay';

let ProductSchema = Yup.object().shape({
  // content: Yup.object()
  //   .test(
  //     'has text',
  //     'Article content is required',
  //     value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
  //   ),
  title: Yup.string()
    .required('Summary title is required')
    .max(150),
  image: Yup.string()
    .required('Product image is required'),
  desc: Yup.string()
    .required('Summary is required')
    .max(150),
});

export default function ProductForm({
  pitch:{product_section={}, pitchable_id}, updateMethod
}) {
  if(product_section === null) product_section = {};

  const { preofferSlug } = useParams()
  
  const fileReader = new FileReader();
  let image_url = product_section.image_url
  // if product image already added, remove required from Product Schema
  if(image_url)
    ProductSchema.fields.image = Yup.string()

  const defaultValues = {
    id: product_section.id,
    title: product_section.title || '',
    desc: product_section.desc || '',
    content: createEditorStateWithContent(product_section.content),
    image: "",
  }

  const queryClient = useQueryClient();
  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['preoffer#pitch', preofferSlug],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
  <LoadingOverlay active={updatePitchMutation.isLoading} spinner text="Loading">
    <div id="productForm" className="small-section-bottom">
      <Formik
        initialValues={defaultValues}
        validationSchema={ProductSchema}
        enableReinitialize

        onSubmit={async values => {
          const {content, title, image, desc, id} = values
          let updatedValues = {
            pitch: {
              product_section_attributes:{
                id,
                title,
                desc,
                image,
                content: createEditorSaveContent(content),
              }
            }
          }
          if(!image) delete updatedValues.pitch.product_section_attributes.image

          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: updatedValues,
          })
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group h-100">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={(value) => setFieldValue("content", value)}
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left"/>
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <div className="form-group">
                  <div className="form-label-group show-org-placeholder">
                    <Field
                      id="product-title"
                      name="title"
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                      rows="5"
                      component="textarea"
                      placeholder="Summary title (max 150 chars)"
                    />
                    <label htmlFor="product-title">Summary title (max 150 chars)</label>
                    <ErrorMessage name="title" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="image">
                    <div className="text-center align-middle" style={{
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundColor: "#f7f7f7",
                      border: "1px solid #f7f7f7",
                      display: "table-cell",
                      height: "162px",
                      width: "380px",
                      backgroundSize: "cover",
                      backgroundImage: `url( ${values.image?.data || image_url})`
                    }}>
                      <div style={{
                        backgroundColor: "#f7f7f7",
                        opacity: .6,
                        display: "inline-block",
                        padding: "8px",
                        fontSize: "13px",
                      }}>
                        <Icon name="fileUpload" />
                        <div>
                          Upload product image
                        </div>
                      </div>
                    </div>
                  </label>
                  <input type="file"
                    id="image"
                    name="image"
                    className={`form-control d-none ${touched.image && errors.image ? "is-invalid" : ""}`}
                    onChange={
                      (event) => {
                        setFieldTouched("image", true)
                        const file = event.currentTarget.files[0]
                        if(!file) return
                        fileReader.readAsDataURL(event.currentTarget.files[0])
                        fileReader.onload = function () {
                          setFieldValue("image", {data: fileReader.result})
                        };
                        fileReader.onerror = function (error) {
                          console.log('image file Error: ', error);
                        };
                      }
                    }
                  />
                  <ErrorMessage name="image" component="div" className="invalid-feedback text-left"/>
                </div>

                <div className="form-group">
                  <div className="form-label-group show-org-placeholder">
                    <Field
                      id="desc"
                      name="desc"
                      className={`form-control ${touched.desc && errors.desc ? "is-invalid" : ""}`}
                      rows="5"
                      component="textarea"
                      placeholder="Summary description (max 150 chars)"
                    />
                    <label htmlFor="desc">Summary description (max 150 chars)</label>
                    <ErrorMessage name="desc" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={ product_section.id } sectionType="product"/>
            <LastUpdatedAt updatedAt={ product_section.updated_at }/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  </LoadingOverlay>
  );
}
