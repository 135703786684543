import React from 'react';

export default function ChatSessionClosed({ status }){
  return(
    <div className="poppins fs-14 fs-default py-4 text-center">
      <span className='fs-default'>
        { status === 'pre_live' ? 'Live Event starting soon.' : 'Live event is closed.'}
      </span>
    </div>
  )
}