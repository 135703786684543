import React, {useState, useEffect} from 'react';
import produce from "immer";
import ToastMessage from '../../components/ToastMessage';
import { deleteIcon, editIcon } from '../../assets/images/index';
import OverviewSubheader from 'admin/components/OverviewSubheader'
import TextButton from 'admin/components/TextButton'
import {teamMemberApi} from 'api/team_member'
import TeamMemberForm from './TeamMemberForm';
import { toTitlecase } from '../../jsUtils/stringUtils';
import ActionButton from '../../ActionButton';

export default function TeamMembers({companyId}) {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createTeamMember, setCreateTeamMember] = useState(false);
  const [updateTeamMember, setUpdateTeamMember] = useState(null);

  useEffect(() => {
    fetchTeamMembers();
  }, [])

  const fetchTeamMembers = () => {
    setLoading(true)
    teamMemberApi.list(companyId).then(
      res => {
        setTeamMembers(res);
      },
      error => {
        console.log(error);
        ToastMessage.error('Could not fetch teamMembers, try again later.');
      }
    ).finally(() => {
      setLoading(false);
    })
  }

  const deleteTeamMember = (id) => {
    const confirmation = window.confirm('Are you sure you want to delete team member?')
    if (confirmation){
      setLoading(true)
      teamMemberApi.purge(companyId, id).then(
        res => {
          setTeamMembers(teamMembers.filter(a => a.id !== id))
        },
        error => {
          console.log(error);
          ToastMessage.error('Could not delete team member, try again later.');
        }
      ).finally(() => {
        setLoading(false);
      })
    }
  }

  const onSuccess = async (affiliation, update=true) => {
    if (update){
      setTeamMembers(
        produce(teamMembers, draftState => {
          const currentIndex = teamMembers.findIndex(a => a.id === affiliation.id);
          draftState.splice(currentIndex, 1, affiliation)
        })
      );
      setUpdateTeamMember(null);
    }
    else {
      setTeamMembers(
        produce(teamMembers, draftState => {
          draftState.push(affiliation)
        })
      );
      setCreateTeamMember(false);
    }

  }

  return (
    <>
      <OverviewSubheader left='Team members' right={<TextButton title='Add Team Member' onClick={() => setCreateTeamMember(true)}/>} />
      {createTeamMember && <TeamMemberForm companyId={companyId} onClose={() => setCreateTeamMember(false)} onSuccess={onSuccess}/>}
      {updateTeamMember && <TeamMemberForm companyId={companyId} teamMember={teamMembers.find(a=> a.id === updateTeamMember)} onClose={() => setUpdateTeamMember(null)} onSuccess={onSuccess}/>}
      <div className="table-responsive action-table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Member name</th>
              <th>Role</th>
              <th>Bio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              loading && <tr style={{height: '40px'}}>
                  <td className='py-0 text-center'>Loading team members</td>
                </tr>
            }
            {
              !loading && teamMembers.length === 0 && <tr style={{height: '40px'}}>
                  <td className='py-0 text-center'>No team members created</td>
                </tr>
            }
            {
              teamMembers.map(a =>
                <tr key={a.id} className="bg-white">
                  <td>{toTitlecase(a.name)}</td>
                  <td>{a.role}</td>
                  <td>{a.bio.substring(0,80)}</td>
                  <td>
                    <div className="table-actions-box-square">
                      <ActionButton tooltipText="Edit" icon={editIcon} clickHandler={() => setUpdateTeamMember(a.id)} />
                      <ActionButton tooltipText="Delete" icon={deleteIcon} clickHandler={() => deleteTeamMember(a.id)} />
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
