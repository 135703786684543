

import React from 'react';
import { OFFER_TYPE } from '../../../config/constants';
import RegCFOffer from './RegCf';
import RegD506bOffer from './RegD506b';

export default function Overview({ offer, user, isAdminUser }) {

  if(offer.type === OFFER_TYPE.reg_cf){
    return <RegCFOffer offer={offer} user={user} isAdminUser={isAdminUser} />
  } else if(offer.type === OFFER_TYPE.reg_d506b){
    return <RegD506bOffer offer={offer} user={user} isAdminUser={isAdminUser} />
  } else{
    return null;
  }
}
