

import React from 'react';
import Overview from './Overview';
import LiveEventView from '../../../components/LiveEvent/LiveEventView';
import LiveEventMobileView from '../../../components/LiveEvent/mobile/LiveEventMobileView';
import { AuthContext } from '../../../contexts/authContext';
import useWindowResize from '../../../hooks/useWindowResize';

export default function OfferHeader({ offer, user }) {
  const { isAdmin } = React.useContext(AuthContext);
  const [width] = useWindowResize();

   if(!offer){
    return <></>;
  }

  if(offer.live_event){
    if(width < 769){
      return (<LiveEventMobileView offer={offer} user={user} isAdminUser={isAdmin()}/>);
    }else{
      return (<LiveEventView offer={offer} user={user} isAdminUser={isAdmin()}/>);
    }
  }else{
    return (<Overview offer={offer} user={user} isAdminUser={isAdmin()}/>);
  }
}
