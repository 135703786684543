/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage } from "formik";
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import TimeAgo from 'javascript-time-ago'
import en from 'config/timeAgo-en.json'
import "./Page.Discussion.scss";
import RichEditor from './../../admin/components/RichEditor'
import RichTextRenderer from './../../admin/components/RichEditor/renderer'
import { createEditorStateWithContent, createEditorSaveContent} from './../../admin/components/RichEditor/editorUtils'
import useDiscussionBoard from 'redux/discussionboard';
import { discussionBoardApi } from '../../api/discussionboard';
import { FaEdit, FaFileAlt, FaTrash, FaExclamationTriangle } from 'react-icons/fa';
import { history } from 'redux/helpers'
import UserAffiliationManager from '../../components/UserAffiliationManager';
import { AuthContext } from './../../contexts/authContext'
import { toTitlecase } from '../../jsUtils/stringUtils'
import { useUser } from 'redux/user';
import useUserAffiliations from 'redux/userAffiliations';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import ReadMore from "./../../investor/components/pitch/ReadMore";
import { formatDate } from 'jsUtils/dateTimeUtils';

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const DISCUSSION_STATES = {
  UNSET: 'unset',
  EDITING: 'editing',
  RESPONDER_OPEN: 'responder_open',
}

const DiscussionContext = React.createContext();

export default function DiscussionBoard({
  discussableType,
  discussableId,
  companyId,
  companyName,
  openForPostings = true,
  postButtonLabel,
}){
  const discussionBoard = useDiscussionBoard();
  const { discussions } = discussionBoard;
  const user = useUser();

  useEffect( () => {
    discussionBoard.loadDiscussions(discussableType, discussableId);
  }, [])

  return (
    <DiscussionContext.Provider value={{
        discussableType,
        discussableId,
        companyId,
        companyName,
        openForPostings,
        postButtonLabel,
        currentUserId: user.id
      }}
    >
      <div className="small-section-bottom col-12">
        <div className="discussion-section-width">
          <DiscussionForm />
          { discussions?.map(d => <Discussion key={d.id} discussion={d} />) }
        </div>
      </div>
    </DiscussionContext.Provider>
  )
}

function DiscussionForm() {
  const discussionBoard = useDiscussionBoard();
  const discussionContext = React.useContext(DiscussionContext)
  const { isUserLoggedin, isAdmin } = React.useContext(AuthContext);
  const userAffiliations = useUserAffiliations();
  const affiliations = userAffiliations.affiliations;
  const isIssuerOrFounder = affiliations.find(a => ['issuer', 'founder'].includes(a.title) && a.approved)
  const  canCreatePost = discussionContext.openForPostings || isAdmin() || isIssuerOrFounder;

  useEffect(() => {
    if(isUserLoggedin()){
      userAffiliations.loadUserAffiliations(discussionContext.companyId)
    }
  }, [discussionContext.companyId]);

  if (!isUserLoggedin()) {
    return (
      <div className="discussion-participation">
        <p>Sign up or Login to participate in the discussion board</p>
        <div className="d-flex flex-wrap align-items-center justify-content-center">
          <button className="zeitung-mono weight-300 btn-black text-uppercase py-2" onClick={() => history.push('/signup')}>Sign Up</button>
          <button className="btn zeitung-mono weight-300" onClick={() => history.push('/login')}>Login</button>
        </div>
      </div>
    )
  }

  if (!canCreatePost) {
    return (
      <div className="discussion-participation ">
        <div class="no-reply-warning">
          <FaExclamationTriangle />
          <div className="ml-1">There may be a delayed response because this offer is closed. </div>
        </div>
      </div>
    )
  }

  return (
    <div className="discussion-topic-post">
      <Formik
            initialValues={{ content: createEditorStateWithContent(null) }}
            validationSchema ={Yup.object().shape({
            content: Yup.object()
              .test(
                'has text',
                "Post can't be blank",
                value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
              ),
            })}
            onSubmit={async (data, {setSubmitting, resetForm, setStatus, status}) => {
              setSubmitting(true);
              const content = createEditorSaveContent(data.content)
              await discussionBoard.postDiscussion(discussionContext.discussableType, discussionContext.discussableId, {content})
              resetForm();
              setSubmitting(false);
            }}
              >
              {( { values, errors, status, isSubmitting, setFieldValue, setFieldTouched, touched, handleChange, handleBlur, handleSubmit } ) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-label-group" style={{height: '140px'}}>
                    <RichEditor
                      id="post-content"
                      className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                      editorState = { values.content }
                      onChange={ (value) => setFieldValue("content", value) }
                      onBlur={() => setFieldTouched("content")}
                      placeholder="Post a new question or comment"
                      hideExternalPlugins={true}
                    />
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left" />
                  </div>
                  <div className="d-flex flex-no-wrap align-items-start justify-content-end">
                    <div class="mr-3 text-right">
                      <UserAffiliationManager companyId={discussionContext.companyId} companyName={discussionContext.companyName} />
                    </div>
                    <button className="btn-black" disabled={isSubmitting} type="submit">{ discussionContext.postButtonLabel || 'Post Topic' }</button>
                  </div>
                </form>
              )}
        </Formik>
        <div className="divider"></div>
    </div>
  )
}

function ReplyForm ({ type='comment', repliableId, onSuccess, onCancel }) {
  const discussionBoard = useDiscussionBoard();
  const discussionContext = React.useContext(DiscussionContext)
  const { isUserLoggedin } = React.useContext(AuthContext);

  if (!isUserLoggedin()) {
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={{ content: createEditorStateWithContent(null) }}
        validationSchema ={Yup.object().shape({
          content: Yup.object()
            .test(
              'has text',
              "Comment can't be blank",
              value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
            ),
          })}
        onSubmit={async (data, { setSubmitting, resetForm, setStatus, status }) => {
          setSubmitting(true);
          const content = createEditorSaveContent(data.content)
          await discussionBoard.postReply(discussionContext.discussableType, discussionContext.discussableId, repliableId, {content}, type);
          resetForm();
          setSubmitting(false);
          onSuccess();
        }}
      >
        {({ values, errors, status, isSubmitting, setFieldValue, setFieldTouched, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="main-comment-reply">
                <div className="form-label-group mt-3">
                  <div style={{height:'140px'}}>
                    <RichEditor
                      id={`content reply-content-${repliableId}`}
                      className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                      editorState = { values.content }
                      onChange={ (value) => setFieldValue("content", value) }
                      onBlur={() => setFieldTouched("content")}
                      placeholder="Write a reply"
                      hideExternalPlugins={true}
                    />
                  </div>
                  <ErrorMessage name="content" component="div" className="invalid-feedback text-left" />
                  <div className="d-flex flex-wrap justify-content-end mt-3">
                    <button
                      className="btn-black-outline rounded-0 px-3 py-2 montserrat fs-9 mt- mr-3"
                      onClick={onCancel}
                      style={{alignSelf: 'flex-end'}}>Cancel</button>
                    <button className="btn-black" disabled={isSubmitting} type="submit" style={{alignSelf: 'flex-end'}}>Reply</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

// TODO: Merge DiscussionEditor with ReplyForm

function DiscussionEditor ({ discussion, onEditSuccess, onCancel }) {
  const discussionBoard = useDiscussionBoard();
  const discussionContext = React.useContext(DiscussionContext)
  const { isUserLoggedin } = React.useContext(AuthContext);

  if (!isUserLoggedin()) {
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={{ content: createEditorStateWithContent(discussion.content) }}
        validationSchema ={Yup.object().shape({
          content: Yup.object()
            .test(
              'has text',
              "Comment can't be blank",
              value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
            ),
          })}
        onSubmit={async (data, { setSubmitting, resetForm, setStatus, status }) => {
          setSubmitting(true);
          const content = createEditorSaveContent(data.content)
          await discussionBoard.updateDiscussion(discussionContext.discussableType, discussionContext.discussableId, discussion.id, {content});
          resetForm();
          onEditSuccess();
          setSubmitting(false);
        }}
      >
        {({ values, errors, status, isSubmitting, setFieldValue, setFieldTouched, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="main-comment-reply">
                <div className="form-label-group mt-3">
                  <div style={{height:'140px'}}>
                    <RichEditor
                      id={`content reply-content-${discussion.id}`}
                      className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                      editorState = { values.content }
                      onChange={ (value) => setFieldValue("content", value) }
                      onBlur={() => setFieldTouched("content")}
                      placeholder="Write a reply"
                      hideExternalPlugins={true}
                    />
                  </div>
                  <ErrorMessage name="content" component="div" className="invalid-feedback text-left" />
                  <div className="d-flex flex-wrap justify-content-end mt-3">
                    <button
                      className="btn-black-outline rounded-0 px-3 py-2 montserrat fs-9 mt- mr-3"
                      onClick={onCancel}
                      style={{alignSelf: 'flex-end'}}>Cancel</button>
                    <button className="btn-black" disabled={isSubmitting} type="submit" style={{alignSelf: 'flex-end'}}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

function DeleteDiscussionButton ({discussionId}) {
  const discussionBoard = useDiscussionBoard();
  const discussionContext = React.useContext(DiscussionContext)
  const {discussableType, discussableId} = discussionContext;
  const confirmationModal = React.useContext(PortalizedModalContext);

  const deleteDiscussion = () =>{
    confirmationModal.setModalContent(
      'Delete Post',
      <>
        <p className='instruction-text-for-mobile'>Are you sure you want to delete this post/comment?</p>
        <div class="portalized-modal-footer">
          <button class="btn-black" onClick={() => {
            discussionBoard.deleteDiscussion(discussableType, discussableId, discussionId)
            confirmationModal.closeModal();
          }}>Confirm</button>
          <button class="btn-black-outline" onClick={confirmationModal.closeModal}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    confirmationModal.openModal();
  }

  return (
    <button onClick={deleteDiscussion} className="btn-black"><FaTrash size="10" /></button>
  )
}

function DiscussionAuditItem ({auditItem}) {
  return (
    <div key={auditItem.created_at} className="mt-2" style={{backgroundColor: "#fff", padding: "10px", borderRadius: "2px"}}>
      <div className="font-weight-bold px-2 pb-1 fs-9">
        {formatDate(auditItem.created_at, 'MMM dd, yyyy, hh:mm a')}
        &nbsp;by {auditItem.user}
      </div>
      <ReadMore id={`read-more-clearing-failure-${auditItem.created_at}`} buttonType="text">
        {
          Object.keys(auditItem.audited_changes).map(key => {
            if (key !== "content") return null;

            return Array.isArray(auditItem.audited_changes[key]) // key is 'content'
              ? <div className='pl-2'>
                  <RichTextRenderer content={auditItem.audited_changes[key][1]} backgroundColor="#fff" />
                </div>
              :
                <div className='pl-2'>
                  <RichTextRenderer content={auditItem.audited_changes[key]} backgroundColor="#fff" />
                </div>
          })
        }
      </ReadMore>
    </div>
  )
}

function DiscussionAuditLog({discussionId}) {
  const { isLoading, error, data: audits } = useQuery(['discussion-audits', discussionId], () => {
    return discussionBoardApi.auditLog(discussionId);
  });

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  if (isLoading) {
    return <div className='text-center montserrat weight-500 fs-8 mt-4'>Loading...</div>
  }

  if (audits.length == 0) {
    return (
      <div className='text-center montserrat weight-500 fs-8 mt-4'>No audit records found.</div>
    )
  }

  return audits.map(auditItem => <DiscussionAuditItem  auditItem={auditItem} />)
}

function AuditDiscussionButton ({discussionId}) {
  const auditModal = React.useContext(PortalizedModalContext);

  const showAuditLog = () =>{
    auditModal.setModalContent(
      'Audit Log',
      <DiscussionAuditLog key={discussionId} discussionId={discussionId}/>,
      'sm'
    );
    auditModal.openModal();
  }

  return (
    <div className="inner2">
      <button onClick={showAuditLog} className="btn-black"><FaFileAlt size="10" /></button>
    </div>
  )
}

function Discussion ({discussion}) {
  const { isAdmin, isUserLoggedin } = React.useContext(AuthContext);
  const discussionContext = React.useContext(DiscussionContext)
  const {currentUserId} = discussionContext;
  const [showAllReplies, setShowAllReplies] = useState(false);

  const [state, setState] = React.useState(DISCUSSION_STATES.UNSET)
  const {id, username, user_id, created_at, badges, content, comments, deleted, edited} = discussion

  const canDelete = React.useMemo(() => {
    return (isAdmin() || user_id == currentUserId ) && !deleted
  }, [user_id, currentUserId, deleted])
  const canAudit = isAdmin()
  const canEdit = user_id == currentUserId && !deleted;

  return (
    <>
      <div className="topic-container">
        <div className="topic-container-header">
          <div className="inner1">
            <h3 className="montserrat fs-9 weight-700 mr-2 mb-2">{toTitlecase(username)}</h3>
            <span className="text-muted fs-10 mr-3">{timeAgo.format(new Date(created_at), 'round')}</span>
            { !deleted && edited && <span className="text-muted fs-10 mr-3">Edited</span> }
            { badges.map(b => <div key={b} className="custom-badge text-capitalize">{ b && b.toLowerCase()}</div>) }
          </div>
          <div className="inner2">
            {
              canDelete && <DeleteDiscussionButton discussionId={id} />
            }
            { canEdit && <button
                className="btn-black"
                disabled={state === DISCUSSION_STATES.EDITING}
                onClick={() => setState(DISCUSSION_STATES.EDITING)}
              >
                <FaEdit size="10" />
            </button>}
          </div>
          {
            canAudit && <AuditDiscussionButton discussionId={id} />
          }
        </div>
        <div className="richTextEditor-content">
          {
            state === DISCUSSION_STATES.EDITING
              ? <DiscussionEditor discussion={discussion} onEditSuccess={() => setState(DISCUSSION_STATES.UNSET)} onCancel={() => setState(DISCUSSION_STATES.UNSET)} />
              : <RichTextRenderer  content={content} backgroundColor="#fff" />
          }
        </div>

        {
          state == DISCUSSION_STATES.RESPONDER_OPEN
            ? <div> <ReplyForm type="comment" repliableId={id} onSuccess={() => setState(DISCUSSION_STATES.UNSET)} onCancel={() => setState(DISCUSSION_STATES.UNSET)} /> </div>
            : isUserLoggedin() && !deleted && <button
                className="btn-black mr-auto mt-3"
                onClick={() => setState(DISCUSSION_STATES.RESPONDER_OPEN)}
                style={{alignSelf: 'flex-start'}}>
                  Write Reply
              </button>
        }
        <div className="comments-viewer-btn-box">
          { !showAllReplies && comments.length-3 > 0 &&
            <button onClick={() => setShowAllReplies(true)} className="comments-viewer-btn">View {comments.length-3} more replies</button>
          }
        </div>
        <Comments
          showAllReplies={showAllReplies}
          comments={comments}
          discussionId={id}
        />
      </div>
    </>
  )
}

function Comments({ showAllReplies, comments, discussionId }) {
  if(!showAllReplies) comments = comments.slice(0,3);

  return(
    comments?.map( c => <Comment key={c.id} comment={c} discussionId={discussionId} /> )
  )
}

function Comment({comment, discussionId }) {
  const { isAdmin, isUserLoggedin } = React.useContext(AuthContext);
  const discussionContext = React.useContext(DiscussionContext)
  const { currentUserId } = discussionContext;
  const [state, setState] = React.useState(DISCUSSION_STATES.UNSET)
  const canDelete = (isAdmin() || comment.user_id == currentUserId) && !comment.deleted 
  const canEdit = comment.user_id == currentUserId && !comment.deleted
  const canAudit = isAdmin()

  return (
    <div className="comment-indent" key={comment.id}>
      <div className="comment-container">
        <div className="single-user-comment-box">
          <div className="single-user-comment-box-header">
            <div className="inner1">
              <h3 className="montserrat fs-9 weight-700 mr-2 mb-2">{toTitlecase(comment?.username)}</h3>
              <span className="text-muted fs-10 mr-3">{timeAgo.format(new Date(comment.created_at), 'round')}</span>
              { !comment.deleted && comment.edited && <span className="text-muted fs-10 mr-3">Edited</span> }
              { comment?.badges?.map(b => <div key={b} className="custom-badge text-capitalize">{ b && b.toLowerCase()}</div>) }
            </div>
            <div className="inner2">
            {
              canDelete && <DeleteDiscussionButton discussionId={comment.id} />
            }
            {
              canEdit &&
                <button
                  className="btn-black"
                  disabled={state === DISCUSSION_STATES.EDITING}
                  onClick={ () => setState(DISCUSSION_STATES.EDITING) }
                >
                    <FaEdit size="10" />
                </button>
            }
            </div>
            {
              canAudit && <AuditDiscussionButton discussionId={comment.id} />
            }
          </div>
          <div className="richTextEditor-content">
            {
              state === DISCUSSION_STATES.EDITING
                ? <DiscussionEditor discussion={comment} onEditSuccess={() => setState(DISCUSSION_STATES.UNSET)} onCancel={() => setState(DISCUSSION_STATES.UNSET)} />
                : <RichTextRenderer content={comment.content} backgroundColor="#fff"/>
            }
          </div>
        </div>
        <div className="single-user-comment-box bg-lightgrey2">
          <div className="single-user-comments-box-inner">
            {comment.replies?.map(reply => {
              return <Reply key={reply.id}  discussionId={discussionId} commentId={comment.id}  reply={reply} />})}
          </div>
          { state == DISCUSSION_STATES.RESPONDER_OPEN ?
            <ReplyForm type="reply" repliableId={comment.id} onSuccess={() => setState(DISCUSSION_STATES.UNSET)} onCancel={() => setState(DISCUSSION_STATES.UNSET)}/>
            :
            isUserLoggedin() && !comment.deleted && <button
              className="btn-black mr-auto mt-3"
              onClick={() => setState(DISCUSSION_STATES.RESPONDER_OPEN)}
              style={{alignSelf: 'flex-start'}}>
                Write Reply
            </button>
          }
        </div>
      </div>
    </div>
  )
}

function Reply ({ reply}) {
  const { isAdmin } = React.useContext(AuthContext);
  const discussionContext = React.useContext(DiscussionContext)
  const { currentUserId } = discussionContext;
  const [state, setState] = React.useState(DISCUSSION_STATES.UNSET)

  const {id, username, user_id, created_at, badges, content, deleted, edited} = reply;
  const canAudit = isAdmin()
  const canDelete = (isAdmin() || user_id == currentUserId) && !deleted;
  const canEdit = user_id == currentUserId && !deleted;

  return (
    <>
      <div className="single-comment">
        <div className="single-comment-header">
          <div className="inner1">
            <h3 className="montserrat fs-9 weight-700 mr-2 mb-2">{toTitlecase(username)}</h3>
            <span className="text-muted fs-10 mr-3">{timeAgo.format(new Date(created_at), 'round')}</span>
            { !deleted && edited && <span className="text-muted fs-10 mr-3">Edited</span> }
            { badges?.map(b => <div key={b} className="custom-badge text-capitalize">{ b && b.toLowerCase()}</div>) }
          </div>
          <div className="inner2">
          {
            canDelete && <DeleteDiscussionButton discussionId={id} />
          }
            {
              canEdit &&
                <button
                  className="btn-black"
                  disabled={state === DISCUSSION_STATES.EDITING}
                  onClick={ () => setState(DISCUSSION_STATES.EDITING) }
                >
                  <FaEdit size="10" />
                </button>
            }
            </div>
            {
              canAudit && <AuditDiscussionButton discussionId={id} />
            }
        </div>
        <div className="richTextEditor-content">
          {
            state === DISCUSSION_STATES.EDITING
              ? <DiscussionEditor discussion={reply} onEditSuccess={() => setState(DISCUSSION_STATES.UNSET)} onCancel={() => setState(DISCUSSION_STATES.UNSET)} />
              : <RichTextRenderer content={content}/>
          }
        </div>
      </div>
    </>
  )
}
