import React, { useEffect, useState, useRef, useContext } from 'react'
import { Switch, Route, useParams, useRouteMatch, Redirect } from "react-router-dom";
import formatMoney from 'accounting-js/lib/formatMoney.js'
import styled from 'styled-components';
import './Page.OfferView.scss'
import { history } from 'redux/helpers'
import OfferUpdates from '../../pages/components/OfferUpdates';
import RichEditor from 'admin/components/RichEditor';
import { createEditorStateWithContent } from 'admin/components/RichEditor/editorUtils';
import { fetchCurrentUser, useUser } from 'redux/user';
import Scrollspy from 'react-scrollspy'
import DealTerms from '../components/DealTerms';
import LoadingOverlay from 'react-loading-overlay';
import Discussion from './Page.Discussion';
import { parseYoutubeVideoId } from 'jsUtils/stringUtils';
import DocumentSection from '../components/shared/DocumentSection';
import { AuthContext } from './../../contexts/authContext';
import OfferSummary from '../components/OfferSummary';
import { Helmet } from "react-helmet";
import OfferNavLinkItems from '../components/shared/OfferNavLinkItems';
import OfferNavMobileDropdown from '../components/shared/OfferNavMobileDropdown';
import PitchSectionLabel from '../components/shared/PitchSectionLabel/PitchSectionLabel';
import CompanySectionTable from '../components/shared/CompanySectionTable';
import { FaYoutube } from 'react-icons/fa';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { getIcon, getBusinessModelIcon } from 'jsUtils/offerUtils';
import { pitchProblemIcon, pitchSolutionIcon, pitchProductIcon, pitchTractionIcon, pitchTeamIcon, pitchVisionIcon, pitchUseFundsIcon, pitchRisksIcon, pitchCompetitionIcon, pitchMarketIcon, highlightVideoIcon } from '../../assets/images';
import Tooltip from '../../pages/components/Tooltip/Tooltip';
import _ from 'underscore.string'
import { OfferHeader, InvestmentButton as RegCfInvestmentButton } from '../components/OfferView';
import RegD506bInvestmentButton from '../components/OfferView/RegD506b/InvestmentButton';
import {BsInfoCircle} from 'react-icons/bs'
import {ACTIONS}  from 'config/adminAbilities';
import { OFFER_TYPE } from '../../config/constants';

import { useOffer, useOfferPitch } from './offer/queries';

export default function OfferView(props){
  let { path } = useRouteMatch();
  let { offerId } = useParams();
  const [showSidebar, setShowSidebar] = useState(false);
  const [nonStickySidebar, setNonStickySidebar] = useState(false);
  const { authData } = React.useContext(AuthContext);
  const scrollspyContainerRef = useRef(null);
  const navSpyBoxRef = useRef(null);
  const user = useUser();
  const canPreview = authData.permittedActions.has(ACTIONS.PREVIEW_OFFER)

  const {data: offer = {}, isLoading: isLoadingOffer} = useOffer(offerId);
  const {data: pitch = {}, isLoading: isLoadingPitch} = useOfferPitch(offerId);

  const company = offer?.company;

  const offerNavBarItems = [
    { label: 'PITCH', value: 'pitch' },
    { label: 'DISCUSSION', value: 'discussion' },
    { label: 'UPDATES', value: 'updates' }
  ]

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => {window.removeEventListener('scroll', scrollEventListener);}
  }, []);

  const scrollEventListener = () => {
    //we added 34px more of padding to the top of scrollspy navbar
    const scrollSpyNavPaddingTop = 34;
    const scrollspyContainerOffsetTop = scrollspyContainerRef?.current?.offsetTop + document?.getElementById('app-navbar')?.clientHeight + scrollSpyNavPaddingTop;
    const navSpyListHeight = document?.querySelector('.sidebar')?.clientHeight;
    const footerOffsetTop = document?.querySelector('footer')?.offsetTop;
    const pageScroll = window.pageYOffset;
    if (pageScroll > (scrollspyContainerOffsetTop)) {
      setShowSidebar(true);
    } else {
        setShowSidebar(false);
    }

    if (pageScroll > (footerOffsetTop - navSpyListHeight - 56)) {
      setNonStickySidebar(true);
    }
    else {
      setNonStickySidebar(false);
    }
  }

  useEffect(()=> {
    fetchCurrentUser({hideToast: true, from: "OfferView"})
  }, [])

  if(isLoadingOffer || isLoadingPitch)
    return 'Loading...'

  if (offer.status == 'live' || offer.status == 'closed') {
    // do nothing, user can view the page
  }
  else if (canPreview || (offer.investments && offer.investments.length > 0)) {
    // do nothing, user with preview permission, or invsted investors can view non live offers
  }
  else {
    history.push(`/offers`)
  }

  let pitchSidebarItems = [];

  (function buildSidebarItems() {
    if(!pitch) return [];

    pitchSidebarItems = [];

    if(offer.type === OFFER_TYPE.reg_d506b){
      if(pitch.highlights?.length > 0 || pitch.main_video_section) {
        pitchSidebarItems.push({
          id: 'highlights', key: 'highlights', name: 'Highlights', visibility: true,
          component: <Highlights main_video_section={pitch.main_video_section} highlights={pitch.highlights} key="highlights"/>
        });
      }

      if(offer.documents.length || offer.security_document || offer.other_documents.length){
        pitchSidebarItems.push({
          id: 'documents', key: 'documents', name: 'Documents', visibility: true,
          component: <DocumentSection offer={offer} key="documents"/>
        });
      }

      pitchSidebarItems.push({
        id: 'deal-terms', key: 'deal-terms', name: 'Deal Terms', visibility: true,
        component: <DealInfoSection offer={offer} key="deal-terms"/>
      });

      if (offer.pitch?.risks_and_disclosures_section) {
        pitchSidebarItems.push({
          id: 'risks-and-disclosure', key: 'risks-and-disclosure', name: 'Risks & Disclosures', visibility: true,
          component: <RiskAndDisclosureSection risks_and_disclosures_section={pitch.risks_and_disclosures_section} key="risks-and-disclosure"/>
        });
      }
      return pitchSidebarItems;
    }

    if(pitch.highlights?.length > 0 || pitch.main_video_section)
      pitchSidebarItems.push({
        id: 'highlights', key: 'highlights', name: 'Highlights', visibility: true,
        component: <Highlights main_video_section={pitch.main_video_section} highlights={pitch.highlights} key="highlights"/>
      });

    pitchSidebarItems.push({
      id: 'summary', key: 'summary', name: 'Summary', visibility: true,
      component: <OfferSummary pitch={pitch} key="summary"/>
    });

    if(pitch.media_mentions?.length > 0)
      pitchSidebarItems.push({
        id: 'mediamentions', key: 'mediamentions', name: 'Media Mentions', visibility: true,
        component: <MediaMentions media_mentions={pitch.media_mentions} key="mediamentions"/>
      });

    if (offer.status !== 'closed')
      pitchSidebarItems.push({
        id: 'deal-terms', key: 'deal-terms', name: 'Deal Terms', visibility: true,
        component: <DealInfoSection offer={offer} key="deal-terms"/>
      });

    if(pitch.problem_section)
      pitchSidebarItems.push({
        id: 'problem', key: 'problem', name: 'Problem', visibility: true,
        component: <ProblemSection problem_section={pitch.problem_section} key="problem"/>
      });

    if(pitch.solution_section)
      pitchSidebarItems.push({
        id: 'solution', key: 'solution', name: 'Solution', visibility: true,
        component: <SolutionSection solution_section={pitch.solution_section} key="solution"/>
      });

    if(pitch.product_section)
      pitchSidebarItems.push({
        id: 'product', key: 'product', name: 'Product', visibility: true,
        component: <ProductSection product_section={pitch.product_section} key="product"/>
      });

    if(pitch.traction_section)
      pitchSidebarItems.push({
        id: 'traction', key: 'traction', name: 'Traction', visibility: true,
        component: <TractionSection traction_section={pitch.traction_section} key="traction"/>
      });

    if(pitch.customers?.length > 0 || pitch.customers_section)
      pitchSidebarItems.push({
        id: 'customers', key: 'customers', name: 'Customers', visibility: true,
        component: <CustomerSection customers_section={pitch.customers_section} customers={pitch.customers} key="customers"/>
      });

    if(pitch.business_model_section || pitch.business_models?.length > 0)
      pitchSidebarItems.push({
        id: 'business-model', key: 'business-model', name: 'Business model', visibility: true,
        component: <BusinessModelSection business_model_section={pitch.business_model_section} business_models={pitch.business_models} key="business-model"/>
      });

    if(pitch.market_section)
      pitchSidebarItems.push({
        id: 'market', key: 'market', name: 'Market', visibility: true,
        component: <MarketSection market_section={pitch.market_section} key="market"/>
      });

    if(pitch.competition_section)
      pitchSidebarItems.push({
        id: 'competition', key: 'competition', name: 'Competition', visibility: true,
        component: <CompetitionSection competition_section={pitch.competition_section} key="competition"/>
      });

    if(pitch.team_section)
      pitchSidebarItems.push({
        id: 'team', key: 'team', name: 'Team', visibility: true,
        component: <TeamSection team_section={pitch.team_section} key="team"/>
      });

    if(pitch.vision_section)
      pitchSidebarItems.push({
        id: 'vision', key: 'vision', name: 'Vision', visibility: true,
        component: <VisionSection vision_section={pitch.vision_section} key="vision"/>
      });

    if(pitch.use_of_funds_section)
      pitchSidebarItems.push({
        id: 'use-of-fund', key: 'use-of-fund', name: 'Use of Funds', visibility: true,
        component: <UseOfFundSection use_of_funds_section={pitch.use_of_funds_section} key="use-of-fund"/>
      });

    pitchSidebarItems.push({
      id: 'company-overview', key: 'company-overview', name: 'Company', visibility: true,
      component: <CompanyOverview company={offer.company} following_company={offer.following_company} key="company-overview"/>
    });

    if(offer.status !== 'closed' && pitch.risks_and_disclosures_section )
      pitchSidebarItems.push({
        id: 'risks-and-disclosure', key: 'risks-and-disclosure', name: 'Risks & Disclosures', visibility: true,
        component: <RiskAndDisclosureSection risks_and_disclosures_section={pitch.risks_and_disclosures_section} key="risks-and-disclosure"/>
      });

    if(offer?.formc_url || offer?.documents.length || offer?.security_document || offer?.other_documents?.length)
      pitchSidebarItems.push({
        id: 'documents', key: 'documents', name: 'Documents', visibility: true,
        component: <DocumentSection offer={offer} key="documents"/>
      });

    return pitchSidebarItems;
  })();

  // if (!offer.hasOwnProperty('id')){ return null }

  const offerViewBannerHeight = document.getElementById('companyBanner')?.clientHeight;
  const offerNavbarHeight = document.getElementById('offerNavBar')?.clientHeight;

  return (
    <LoadingOverlay
        active={isLoadingOffer || isLoadingPitch}
        spinner
        text='Loading ...'
      >
      <Helmet>
        <title>{offer.company.name} Offer</title>
        <meta
          name="description"
          content={`${offer.company.name} fundraise on Spaced Ventures`}
        />
      </Helmet>
      <div className="offer-view-page">
        <section className="offer-view-banner pt-first-section" id="companyBanner">
           <OfferHeader offer={offer} user={user}/>
        </section>
        <div className="offer-navbar" id="offerNavBar">
          <div className="container">
            <div className="tab-buttons">
              <OfferNavLinkItems offer={offer} navBarItems={offerNavBarItems}/>
            </div>
            { offer.type === OFFER_TYPE.reg_cf && <RegCfInvestmentButton offer={offer} user={user} /> }
            { offer.type === OFFER_TYPE.reg_d506b && <RegD506bInvestmentButton offer={offer} user={user} /> }
          </div>
        </div>
        <Switch>
          <Route path={`${path}/pitch`} component={() => (
            <>
            <OfferNavMobileDropdown offer={offer} navBarItems={offerNavBarItems} currentTab={'PITCH'}/>
            <section className="main-content">
              <div className="container scrollspy-container" ref={scrollspyContainerRef}>
                <div className="bg-white">
                  <div className="row" style={{minHeight:`calc(100vh - ${offerViewBannerHeight}px - ${offerNavbarHeight}px + 56px)`}}>
                    <div className={`scrollspy-sidebar px-3 ${nonStickySidebar ? 'non-sticky-sidebar' : ''}`}>
                      <nav id="nav-spy" ref={navSpyBoxRef}>
                        <Scrollspy
                          offset={-56}
                          className={`sidebar nav ${showSidebar ? 'show-sidebar' : ''}`}
                          currentClassName="active disabled-item"
                          items={ pitchSidebarItems.map(item => item.id) }
                          >
                          {pitchSidebarItems.map(item => {
                            return item.visibility && (
                              <li className="sidebar-item nav-item" key={item.key}>
                                <a href={`#${item.id}`}>{item.name}</a>
                              </li>
                            );
                          })}
                        </Scrollspy>
                      </nav>
                    </div>
                    <div className="scrollspy-content px-3">
                      <div className="pitch-content" data-spy="scroll" data-target="#nav-spy">
                        {pitchSidebarItems.map((item, index) => {
                          return <div key={index}>{item.component}</div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </>
          )} />

          <Route path={`${path}/discussion`} component={() => (
            <>
            <OfferNavMobileDropdown offer={offer} navBarItems={offerNavBarItems} currentTab={'DISCUSSION'}/>
            <section className="main-content">
              <div className="container">
                <div className="bg-white">
                <div className="row" style={{height: '100%'}}>
                  <Discussion
                    discussableType="offers"
                    discussableId={offer.slug}
                    companyId={offer.company.slug}
                    companyName={company?.name}
                    openForPostings={offer.status != 'closed'}
                  />
                </div>
              </div>
              </div>
            </section>
            </>
          )} />

          <Route exact path={`${path}/updates`} component={() => {
            return (
              <>
                <OfferNavMobileDropdown offer={offer} navBarItems={offerNavBarItems} currentTab={'UPDATES'}/>
                <section className="main-content">
                  <div className="container">
                    <OfferUpdates.List offer={offer}/>
                  </div>
                </section>
              </>
            )
          }} />

          <Route path={`${path}/updates/:offerUpdateId`} component={() => {
            return (
              <>
                <OfferNavMobileDropdown offer={offer} navBarItems={offerNavBarItems} currentTab={'UPDATES'}/>
                <section className="main-content">
                  <div className="container">
                    <OfferUpdates.View offer={offer}/>
                  </div>
                </section>
              </>
            )
          }} />

          <Redirect from={`${path}`} to={`${path}/pitch`} />
        </Switch>
      </div>
    </LoadingOverlay>
  )
}

const TractionPoint = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  border-bottom: 1px dashed #fff;
  color: #fff;
`

const Customer = (props) => {
  const { customer } = props

  return (
    <div className="customer">
        <div className="customer-logo-box"><img src={`${customer.logo_url}`} alt="" className="logo"/></div>
        <div className="customer-summary">
          <div className="name">{customer.name}</div>
          <div className="status">{customer.status?.replace(/_/g, ' ')}</div>
        </div>
    </div>
  )
}

const BusinessModel = (props) => {
  const { businessModel  } = props

  return (
    <div className="business-model">
        <div className="bm-logo-box">
          <img src={getBusinessModelIcon(businessModel?.name)} alt={businessModel?.name}/>
        </div>
        <div className="business-summary">
          <div className="name">{businessModel.name}</div>
          <div className="description">{businessModel.desc}</div>
        </div>
    </div>
  )
}

const Competition = (props) => {
  const { competition  } = props

  return (
    <>
      <div className='content-summary-icon'>
        <img src={pitchCompetitionIcon} alt="content summary icon" />
      </div>
      <div className='content-summary-text'>{competition}</div>
    </>
  )
}

const MarketSize = (props) => {
  const { market } = props

  const showMarketSize = (size) => {
    if(size) {
      if (/^\d+$/.test(size))
        return formatMoney(size);
      else
        return size;
    } else {
      return "";
    }
  }

  return (
    <div className="market-size">
        <div className="market-size-logo-box">
          <img src={pitchMarketIcon} alt="Market size" />
        </div>
        <div className="market-size-summary">
          <div className="name">{ showMarketSize(market) }</div>
          <div className="description">Approximate Total Market Size</div>
        </div>
    </div>
  )
}

const Article = (props) => {
  const content = createEditorStateWithContent(props.content)

  return (
    <RichEditor
      editorState = { content }
      readOnly={true}
      onChange={() => createEditorStateWithContent(props.content)}
    />
  )
}

function DealInfoSection({ offer }){
  return (
    <div id="deal-terms" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'Deal Terms'} />
        <div className="pitch-section-body">
          <DealTerms offer={offer} />
        </div>
      </div>
    </div>
  )
}

const SingleHighlight = ({highlight})=>{
  return(
    <div className="col-md-6">
      <div className="single-highlight">
        <div className="single-highlight-iconbox">
            <img className="single-highlight-icon" src={getIcon(highlight.highlight_type)} alt={'icon'}/>
        </div>
        <div className="single-highlight-header">
          <h5 className="single-highlight-title">{highlight.title}</h5>
        </div>
        <div className="single-highlight-body">
          <div className="description-box">
            <p>{highlight.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PitchMainVideo = ({openModal, mainVideoId}) =>{
  return(
    <div className="col-md-6" onClick={()=> openModal()}>
      <div className="single-highlight">
        <div className="single-highlight-iconbox">
          <img src={highlightVideoIcon} className="single-highlight-icon" alt="icon" />
        </div>
        <div className="single-highlight-body">
          <div className="highlight-video-box">
            <div className="highlight-video-box-icon"><FaYoutube /></div>
            {/* /0, /1, /2, /3 represents the thumbnail number */}
            <img src={`https://img.youtube.com/vi/${mainVideoId}/0.jpg`} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  )
}
function Highlights({main_video_section = {}, highlights = []}){
  const showMainVideoModal = useContext( PortalizedModalContext );
  const mainVideoLink = main_video_section?.link;
  const mainVideoId = parseYoutubeVideoId(mainVideoLink)

  function openModal(){
    showMainVideoModal.setModalContent(
      '',
      <div className="video-modal-inner">
        <div className="for-youtube-vid-container">
          <div className="youtube-vid-container">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${mainVideoId}?rel=0&modestbranding=1&autohide=1&showinfo=0`}
              autoPlay="1"
              title="Highlight"
              frameBorder="0"
              allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>,
      'md',
      ['headless-modal']
    )
    showMainVideoModal.openModal()
  }

  return (
    <div id="highlights" className="component-box">
      <div className="pitch-section-width">
        <div className="label">
          <div className="label-inner">
            <div className="label-text">Highlights</div>
          </div>
        </div>
        <div className="pitch-section-body">
          <div className="row pt-3 highlights-row">
            {highlights.map((highlight, index) => (
              <SingleHighlight key={index} highlight={highlight}/>
            ))}
            {mainVideoId && <PitchMainVideo openModal={openModal} mainVideoId={mainVideoId}/> }
          </div>
        </div>
      </div>
    </div>
  )
}
const SingleMedia = ({mediaMention})=>{
  return(
    <a target="_blank" href={mediaMention.link} className="media-mention-box mb-3" rel="noopener noreferrer">
      <div className="media-thumbnail">
        <img src={mediaMention.logo_url} alt="img" />
      </div>
      <div className="media-mentioned-description">
        <h5 className="fs-18">{mediaMention.title}</h5>
        <div className="d-inline-flex">
          <div className="poppins fs-14"> {mediaMention.media_name}</div>
          <Tooltip text={mediaMention.description}>
            <div className="d-flex align-items-start">
              <BsInfoCircle style={{marginLeft: '7px'}} color="#ccc" size="0.75em"  />
            </div>
          </Tooltip>
        </div>
      </div>
    </a>
  )
}

function MediaMentions({media_mentions = []}){
  return (
    <div id="mediamentions" className="component-box">
      <div className="pitch-section-width">
        <div className="label">
          <div className="label-inner">
            <div className="label-text">Media Mentions</div>
          </div>
        </div>
        <div className="pitch-section-body">
          {media_mentions.map((mediaMention, i) => (
            <SingleMedia mediaMention={mediaMention} key={i}/>
          ))}
        </div>
      </div>
    </div>
  )
}

function ProblemSection({ problem_section = {} }){
  return (
    <div id="problem" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'problem'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchProblemIcon} alt="summary icon" />
            </div>
            <div className='content-summary-text'>{problem_section?.title}</div>
          </div>
            <div className="more-content-detail">
              <Article content={problem_section?.content}/>
            </div>
        </div>
      </div>
    </div>
  )
}

function SolutionSection({ solution_section }){
  return (
    <div id="solution" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'solution'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchSolutionIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{solution_section?.title}</div>
          </div>
            <div className="more-content-detail"><Article content={solution_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function ProductSection({ product_section }) {
  return (
    <div id="product" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'product'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchProductIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{product_section?.title}</div>
          </div>
          <img src={product_section?.image_url} alt="" className="product-image"/>
          <div className="product-description italic-style">
            {product_section?.desc}
          </div>
            <div className="more-content-detail mt-4"><Article content={product_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function TractionSection({ traction_section }) {
  return (
    <div id="traction" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'traction'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchTractionIcon} alt="content summary icon" />
            </div>
            <div className='traction-points'>
              {traction_section?.points?.map(
                point => <TractionPoint key={point}>
                  <div className="traction-content-summary-inner">{point}</div>
                </TractionPoint>
              )}
            </div>
          </div>
          <div className="more-content-detail"><Article content={traction_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function CustomerSection({ customers_section, customers }) {
  return (
    <div id="customers" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'customers'} />
        <div className="pitch-section-body">
          {customers?.map((c, i) => (
            <div className="content-summary content-summary-sm" key={i}>
              <Customer customer={c}></Customer>
            </div>
          ))}
          <div className="more-content-detail"><Article content={customers_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function BusinessModelSection({ business_model_section, business_models }) {
  return (
    <div id="business-model" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'business model'} />
        <div className="pitch-section-body">
          {business_models?.map((model, index) => (
            <div key={index} className="content-summary content-summary-sm">
              <BusinessModel businessModel={model} />
            </div>
          ))}
          <div className="more-content-detail"><Article content={business_model_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function MarketSection({ market_section }) {
  return (
    <div id="market" className="component-box ">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'market'} />
        <div className="pitch-section-body">
          <div className="content-summary content-summary-sm">
            <MarketSize market={market_section?.market_size} />
          </div>
            <div className="more-content-detail"><Article content={market_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function CompetitionSection({ competition_section }) {
  return (
    <div id="competition" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'competition'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <Competition competition={competition_section.title} />
          </div>
          <div className="more-content-detail"><Article content={competition_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function VisionSection({ vision_section }) {
  return (
    <div id="vision" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'vision'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchVisionIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>
              {vision_section?.title}
            </div>
          </div>
            <div className="more-content-detail"><Article content={vision_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function TeamSection({ team_section }) {
  return (
    <div id="team" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'team'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchTeamIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>
              {team_section?.title}
            </div>
          </div>
            <div className="more-content-detail"><Article content={team_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function UseOfFundSection({ use_of_funds_section }) {
  return (
    <div id="use-of-fund" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'use of funds'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchUseFundsIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{use_of_funds_section?.title}</div>
          </div>
            <div className="more-content-detail"><Article content={use_of_funds_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function RiskAndDisclosureSection({ risks_and_disclosures_section }) {
  return (
    <div id="risks-and-disclosure" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'risks & disclosures'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchRisksIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{risks_and_disclosures_section?.title}</div>
          </div>
            <div className="more-content-detail"><Article content={risks_and_disclosures_section?.content}/></div>
        </div>
      </div>
    </div>
  )
}

function CompanyOverview({ company, following_company = false }) {
  return(
    <div id='company-overview' className="component-box" >
      <div className="pitch-section-width">
        <PitchSectionLabel label={'Company'} />
        <div className="pitch-section-body">
          <div className="component-box-header">
            <div className="cbh-logo">
              <img src={ company?.logo_url } alt="" className="logo" />
            </div>
            <div className="customer-summary">
              <div className="name">{ company?.legal_name ? company?.legal_name : '-' }</div>
              <div className="custom-badge">{company?.parent_sector_name}/{company?.sector_name}</div>
            </div>
          </div>
          <div className="company-inner-section">
            <div className="company-inner-section-description">
              <p>{company.description}</p>
            </div>
          </div>
          <CompanySectionTable following_company={following_company} company={company}/>
        </div>
      </div>
    </div>
  )
}
