import React from "react";
import { useParams } from "react-router-dom";
import OverviewHeader from "../../components/OverviewHeader";
import { useAccreditationRequestDetails } from "./queries";
import EntityDocumentList from "./EntityDocumentList";
import ActionButtons from "./ActionButtons";
import InvestmentList from "../Aml/InvestmentsList";
import VerificationDetail from "./VerificationDetail";
import NoteList from "./NoteList";
import InvestorDetail from "./InvestorDetail";
import VerificationLetter from "./VerificationLetter";

export default function Overview() {
  const { accreditation_request_id: accreditationRequestId, client_id: clientId } = useParams();
  const { data: accreditationRequest, isLoading, isError } = useAccreditationRequestDetails(clientId, accreditationRequestId);

  if (isLoading) return <span>Loading...</span>;

  if (isError) return null;

  return (
    <div className="aml-details-container">
      <OverviewHeader left={`Accreditation Details for ${accreditationRequest.client.name}`} right={
        accreditationRequest.status !== 'cleared' && <ActionButtons accreditationRequest={accreditationRequest} />
      } />
      <div className="tab-pane fade show active" role="tabpanel">
        <InvestorDetail accreditationRequest={accreditationRequest} />
        <VerificationDetail accreditationRequest={accreditationRequest} />
        <EntityDocumentList accreditationRequest={accreditationRequest} />
        <NoteList accreditationRequest={accreditationRequest} />
        <VerificationLetter accreditationRequest={accreditationRequest} />
        <InvestmentList clientId={accreditationRequest.client_id} />
      </div>
    </div>
  );
}
