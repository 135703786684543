import create from 'zustand';
import produce from "immer";
import { affiliationApi } from 'api/affiliation';

const initialState = {
  loading: false,
  creatingAffiliation: false,
  affiliations: [],
};

const useUserAffiliations = create(set => ({
  ...initialState,

  loadUserAffiliations: (companyId) => {
    set({loading: true});
    affiliationApi.userAffiliations(companyId).then(
      (affiliations) => {
        set({affiliations});
      },
      (error) => {

      }
    ).finally(
      () => set({loading: false})
    )
  },

  createAffiliation: (companyId, attrs, onSubmit) => {
    set({postingDiscussion: true});
    affiliationApi.createUserAffiliation(companyId, attrs).then(
      (affiliation) => {
        set(state => produce(state, draftState => {draftState.affiliations.push(affiliation)}))
        if(onSubmit) onSubmit();
      },
      (error) => {

      }
    ).finally(
      () => set({postingDiscussion: false})
    )
  },

  deleteAffiliation: (companyId, affiliationId, onSubmit) => {
    set({deletingAffiliation: true});
    affiliationApi.purge(companyId, affiliationId).then(
      (res) => {
        set(state => produce(state, draftState => {
          draftState.affiliations = draftState.affiliations.filter(a => a.id !== affiliationId)}))
        if(onSubmit) onSubmit();
      },
      (error) => {

      }
    ).finally(
      () => set({deletingAffiliation: false})
    )
  },

}))

export default useUserAffiliations;