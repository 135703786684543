import React from 'react';
import { useParams } from 'react-router-dom';
import { usePlatformEvent } from "./queries";
import EditHeader from 'admin/components/EditHeader'
import Form from './Form';

export default function EventEditor() {
  let { eventSlug } = useParams()
  const {data: event, isLoading } = usePlatformEvent(eventSlug)

  if(isLoading) {
    return <>Loading...</>
  }

  return (
    <>
      <EditHeader left={`Edit Event - ${event.title}`} />
      <div className="settings-tab-box">
        <div className="settings-tab-box-nav">
          <ul className="nav nav-tabs" id="event-edit-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="event-info-tab" data-toggle="tab" href="#event-info-form" role="tab">Overview</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="company-edit-tab-content">
          <div className="tab-pane fade show active" id="event-info-form" role="tabpanel">
            <Form liveEvent={event} />
          </div>
        </div>
      </div>
    </>
  )
}
