import React from 'react';
import styled from 'styled-components';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchTractionIcon } from '../../../../assets/images'

function TractionSection({ 
  pitch:{traction_section}
}) {
  if(!traction_section) return null;

  return (
    <div id="traction-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'traction'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchTractionIcon} alt="content summary icon" />
            </div>
            <div className='traction-points'>
              {
                traction_section.points?.map((p, index) => (
                  <TractionPoint>
                    <div className="traction-content-summary-inner">{p}</div>
                  </TractionPoint>
                ))
              }  
            </div> 
          </div>
            <ArticleContent content={traction_section.content}/>
        </div>
      </div>
    </div>
  )
}

const TractionPoint = styled.div`
font-family: Montserrat;
font-size: 18px;
padding: 16px 0;
display: flex;
flex-wrap: wrap;
align-items: baseline;
border-bottom: 1px dashed #fff;
color: #fff;
  `

export default TractionSection;