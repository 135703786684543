import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const CheckboxListedAtField = ({ name, placeholder, tagline = "Unlisted" }) => {
  const { control } = useFormContext()
  const [originalListedAt, setOriginalListedAt] = React.useState(null)

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="form-group">
          <label className="switch">
            <input
              {...field}
              checked={field.value == ""}
              id={field.name}
              type="checkbox"
              onChange={e => {
                if((field.value instanceof Date)) { setOriginalListedAt(field.value) }
                field.onChange(e.currentTarget.checked ? "" :  (originalListedAt || new Date()))
              }}
              className={`form-control ${invalid ? "is-invalid" : ""}`}
            />
            <span className="slider round"></span>
            <span style={{ marginLeft: '70px' }} className="checkbox-tagline">{tagline}</span>
            <ErrorMessage error={error} />
          </label>
        </div>
      )}
    />
  )
}

CheckboxListedAtField.defaultProps = {};

CheckboxListedAtField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default CheckboxListedAtField;