import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';
import RichEditor from '../../admin/components/RichEditor';
import { createEditorStateWithContent, createEditorSaveContent} from '../../admin/components/RichEditor/editorUtils';


const RichTextInput = ({name, placeholder, containerStyle}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="form-group" style={containerStyle}>
          <RichEditor
            className={`form-control ${isTouched && invalid ? "is-invalid" : ""}`}
            editorState = { field.value }
            onChange={ field.onChange }
            onBlur={ field.onBlur }
            placeholder = { placeholder }
          />
          <ErrorMessage error={error}/>
        </div>
      )}
    />
  )
}

RichTextInput.defaultProps = {};

RichTextInput.propTypes = {
  name: PropTypes.string.isRequired
};

RichTextInput.createState = createEditorStateWithContent;

RichTextInput.contentForSave = createEditorSaveContent;

export default RichTextInput;