import axiosClient from 'config/axiosClient';

export const affiliationApi = {
  list,
  userAffiliations,
  createUserAffiliation,
  create,
  update,
  purge,
  approve,
};

function list(companyId) {
  return axiosClient.get(`/companies/${companyId}/affiliations/`);
}

function userAffiliations(companyId) {
  return axiosClient.get(`/companies/${companyId}/affiliations/user_affiliations`);
}

function createUserAffiliation(companyId, attrs) {
  return axiosClient.post(`/companies/${companyId}/affiliations/user_affiliation/`, attrs);
}

function create(companyId, attrs) {
  return axiosClient.post(`/companies/${companyId}/affiliations/`, attrs);
}

function update(companyId, affiliationId, attrs) {
  return axiosClient.put(`/companies/${companyId}/affiliations/${affiliationId}`, attrs);
}

function purge(companyId, affiliationId) {
  return axiosClient.delete(`/companies/${companyId}/affiliations/${affiliationId}`);
}

function approve(companyId, affiliationId) {
  return axiosClient.put(`/companies/${companyId}/affiliations/${affiliationId}/approve`);
}
