import React from "react";
import VerificationField from "./VerificationField";

const findDocument = (attachmentType, documents) => {
  return documents.find(
    (document) => document.attachment_type === attachmentType
  );
};

function findVerificatonField(name, fields) {
  return fields.find(f => f.name === name);
}

function verificationFieldsData(clientType) {
  if (clientType === 'person') {
    return [{
      title: 'Address',
      description: 'Upload an image of a piece of valid government ID OR a piece of mail (such as a utility bill) that lists the address dated within the last 90 days.',
      fieldName: 'address',
      attachmentType: 'proof_of_address'
    }, {
      title: 'Date of birth',
      description: "Upload an image of a piece of valid government ID, such as a Driver's License.",
      fieldName: 'dob',
      attachmentType: 'government_id'
    },
    {
      title: 'Tax ID / SSN',
      description: 'Upload proof of your tax ID number such as a image of your SSN card.',
      fieldName: 'tax_id',
      attachmentType: 'tax_id'
    },
    {
      title: 'Legal name',
      description: "Upload an image of a piece of valid government ID, such as a Driver's License.",
      fieldName: 'legal_name',
      attachmentType: 'government_id'
    }]
  }

  if (clientType === 'company') {
    return [
      {
        title: 'Entity verification documents',
        description: 'Please upload 1 of the below documents:',
        documentTypes: [
          'Signed operating agreement',
          'Articles of incorporation',
          'Certificate of formation',
          'Members’ list/authorized signers’ list (if not included in the documents above)'],
        fieldName: 'entity_verification',
        attachmentType: 'entity_verification'
      }
    ]
  };

  return [];
}

export default function VarificationFields({ clientId, clientType, amlException }) {
  const fieldsData = verificationFieldsData(clientType);

  return (
    <>
      {
        fieldsData.map((f, index) => {
          return (<VerificationField
            key={index}
            title={f.title}
            description={f.description}
            field={findVerificatonField(f.fieldName, amlException.verification_fields)}
            document={findDocument(f.attachmentType, amlException.entity_documents)}
            documentTypes={f.documentTypes}
          />)
        })
      }
    </>
  );
}
