import React from 'react';

const defaultInitialState = { 
  page: 1, 
  per_page: 25,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updatePage':
      return { ...state, page: action.payload }
    case 'updatePerPage':
      return { ...state, per_page: action.payload }
    case 'resetDefault':
      return defaultInitialState
    default:
     return defaultInitialState
  }
}

const usePagination = (initialState = defaultInitialState) => {
  
  const [pageConfig, dispatch] = React.useReducer(reducer, initialState)
  
  const setPage = React.useCallback(
    (page) => {
      dispatch({type: 'updatePage', payload: page});
    },
    [dispatch]
  )

  const setPerPage = React.useCallback(
    (per_page) => {
      dispatch({type: 'updatePerPage', payload: per_page});
    },
    [dispatch]
  )

  const resetDefault = React.useCallback(
    () => {
      dispatch({type: 'resetDefault'});
    },
    [dispatch]
  )

  return {
    current: pageConfig,
    setPage,
    setPerPage,
    resetDefault,
  }
}

export default usePagination;