import React, { useRef } from 'react';

const FixedButtonBox = ({ isSubmitting = false, buttonTitle, disabled = false, errorMessage, labelWithButton = null, className="" }) => {
  const submissionBoxContainerRef = useRef(null)
  const submissionBoxRef = useRef(null)

  return (
    <div ref={submissionBoxContainerRef} style={{height: `${submissionBoxRef?.current?.clientHeight}`}} id="submition-box-container" className={`submition-box-container static-position`}>
      <div id="submition-box" ref={submissionBoxRef} className="submition-box">
        <div className={`submition-box-inner justify-content-start ${className}`}>
          <button type="submit" className="poppins weight-700 btn-black" disabled={disabled || isSubmitting}>
            { buttonTitle }
          </button>
          <div className="failed-error-message ml-3">{errorMessage}</div>
          <div className="zeitung-micro fs-9 mt-sm-0 mt-2">{labelWithButton}</div>
        </div>
      </div>
    </div>
  )
}

export default FixedButtonBox;