import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';
import { downloadFile } from 'jsUtils/fileUtils';

const useCompaniesList = ({q=null, page=1, per_page=10}) => {
  return useQuery(
    ['admin', 'companies', 'list', q, page, per_page],
    () => {
      return axiosClient
        .get('/companies', {params: {q, page, per_page}})
    }, {
      keepPreviousData: true,
    }
  );
}

const useCompany = (company_slug) => {
  return useQuery(
    ['admin', 'companies', company_slug],
    () => {
      return axiosClient
        .get(`/companies/${company_slug}`)
    }
  );  
}

const createCompany  = ({values}) => {
  return axiosClient.post(`/companies`, values);
}

const updateCompany = ({company_slug, values}) => {
  return axiosClient.put(`/companies/${company_slug}`, values);
}

const deleteCompany = ({company_slug}) => {
  return axiosClient.delete(`/companies/${company_slug}`);
}

const useSectorsList = () => {
  return useQuery(
    ['sectorsList'],
    () => {
      return axiosClient.get("/sectors");
    }
  );  
}

const useOffersListForCompany = (company_slug) => {
  return useQuery(
    ['admin', 'companies', company_slug, 'offers'],
    () => {
      return axiosClient.get(`/companies/${company_slug}/offers`);
    }
  )
}

export {
  useCompaniesList,
  useCompany,
  useOffersListForCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  useSectorsList,
};