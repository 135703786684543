import axiosClient from 'config/axiosClient';

export const platformLiveEventApi = {
  create,
  fetch,
  list,
  update,
  destroy,
  deletePreviewImage,
  suspendedParticipants,
};

function create({ attrs }) {
  return axiosClient.post(`/admin/platform_live_events`, { platform_live_event: attrs })
}

function fetch(id) {
  return axiosClient.get(`/admin/platform_live_events/${id}`)
}

function list({ page }) {
  return axiosClient.get('/admin/platform_live_events', { params: { page: page } })
}

function update({ id, attrs }) {
  return axiosClient.put(`/admin/platform_live_events/${id}`, { platform_live_event: attrs })
}

function destroy(id) {
  return axiosClient.delete(`/admin/platform_live_events/${id}`)
}

function deletePreviewImage(id) {
  return axiosClient.put(`/admin/platform_live_events/${id}/delete_preview_image`)
}

function suspendedParticipants(liveEventId){
  return axiosClient.get(`/admin/platform_live_events/${liveEventId}/suspended_users`);
}
