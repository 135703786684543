import React, { useState, useEffect, useContext } from 'react';
import { useUser } from 'redux/user'
import EntityDetailsForm from '../../../../../admin/components/EntityDetailsForm/index'
import { formatTaxID } from '../../../../../jsUtils/stringUtils';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import AmlException from '../../../../../admin/components/shared/AmlException';
import PaymentInfo from '../PaymentInfo';
import ClientFieldRow from '../ClientFieldRow';
import CustomAnchorLocation from '../../../../../components/CustomAnchorLocation';
import SuitabilityQuestionaireSection from '../SuitabilityQuestionSection';
import AccreditationVerification from '../../../../../admin/components/shared/AccreditationVerificaton';

export default function EntityInfo() {
  const user = useUser();
  const entityAccounts = user?.clients?.filter(client => ['company', 'trust'].indexOf(client.entity_type) > -1)  || [];

  return (
    <div id="entity-info">
      <h4 className="weight-900 mb-3 lh-md d-flex flex-wrap align-items-center">Entity Accounts</h4>
      {entityAccounts.length === 0 ? (
        <div className="d-flex align-items-center bg-lightgrey p-3" style={{ maxWidth: '700px' }}>
          <span className="poppings weight-400">No entity accounts present.</span>
        </div>
      ) : (
        entityAccounts.map((entity) => <Entity key={entity.id} entity={entity} />)
      )}
    </div>
  )
}

function Entity({ entity, individualAccount }) {
  const [showModal, setShowModal] = useState(false);
  const existingEntityModal = useContext(PortalizedModalContext);
  const hasAmlException = entity.aml_exception?.id ? true : false;

  function onSubmit() {
    existingEntityModal.closeModal();
  }
  useEffect(() => {
    if (showModal) {
      existingEntityModal.setModalContent(
        `Entity Details`,
        <EntityDetailsForm
          submitButtonText="Update"
          entityDetail={entity}
          onSubmit={onSubmit} />,
        'md'
      );
      existingEntityModal.openModal()
    }
    setShowModal(false)
  }, [showModal]);

  const onEdit = () => { setShowModal(true) };

  return (
    <div className="single-entity-info-box">
      <div className="mb-5">
        <h5 className="weight-700 poppins fs-7">
          {entity.name}
          {entity.aml_exception?.status === 'cleared' && <span className="custom-badge ml-3 bg-white text-dark border-dark px-3 border">Verified</span>}
        </h5>
        {hasAmlException && (
          <div className="aml-section-wrapper">
            <AmlException
              clientId={entity.id}
              clientType={entity.entity_type}
            />
          </div>
        )}
        <AccreditationVerification client={entity} individualAccount={individualAccount}/>
        <CustomAnchorLocation link="entity-settings-aml-link" />
        <div className={entity.aml_exception?.status === 'failed' ? 'border-warning p-4 pb-2 aml-client-box' : ''}>
          <ClientFieldRow field="Entity legal name" value={entity.name} onEdit={onEdit} />
          <ClientFieldRow field="Business or tax ID number" value={entity.tax_id_number ? formatTaxID(entity.tax_id_number) : "-"} onEdit={onEdit} />
          <ClientFieldRow field="Adress" value={[entity.street_address_1, entity.city, entity.region, entity.postal_code].filter(Boolean).join(', ')} onEdit={onEdit} />
          <ClientFieldRow field="Phone number" value={entity.phone} onEdit={onEdit} />
          { entity.entity_type === 'company' && <>
            <ClientFieldRow field="Date of formation" value={entity.date_of_formation} onEdit={onEdit} />
            <ClientFieldRow field="Place of formation" value={entity.country_of_formation} onEdit={onEdit} />
            </>
          }
        </div>
      </div>
      {entity.suitability_questionaire_signed_at && <SuitabilityQuestionaireSection client={entity} />}
      <PaymentInfo name={entity.name} clientId={entity.id} />
    </div>
  )
}
