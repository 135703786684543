import React from 'react';
import YoutubeEmbed from '../../admin/components/shared/YoutubeEmbed';
import GaolProgressBar from './GoalProgressBar';
import { PusherChannelProvider } from '../../contexts/PusherChannelContext';
import OfferProgressBar from './OfferProgressBar';
import LiveChat from '../LiveChat';
import { liveEventApi } from '../../api/liveEvent';

export default function LiveEventView({offer, user, isAdminUser}) {
  const liveEvent = offer.live_event;

  return (
    <PusherChannelProvider >
      <div className='live-event px-2 px-md-0'>
        <div className="container offer-view-banner-title-container">
          <GaolProgressBar offer={offer} user={user}/>
        </div>
        <div className="container offer-view-banner-content-container">
          <div className="row">
            <div className="col-12 col-lg-8 video-container">
              <div className="for-youtube-vid-container">
                <YoutubeEmbed videoUrl={liveEvent.youtube_url}/>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className='chat-container'>
                <LiveChat.ChatBox liveEvent={liveEvent} company={offer.company} user={user} getChatSession={liveEventApi.getChatSession}/>
              </div>
            </div>
          </div>
        </div>
        <div className="container offer-view-banner-content-container pt-0 pb-2">
          <OfferProgressBar offer={offer} isAdminUser={isAdminUser} />
        </div>
      </div>
    </PusherChannelProvider>
  )
}
