import React from 'react';
import AchAccount from '../../../../../admin/components/PaymentAccountCards/AchAccount';
import CcAccount from '../../../../../admin/components/PaymentAccountCards/CC';
import AddACHButton from '../../../../../admin/components/AchPaymentForm/AddAchAccountButton';
import AddCcAccountButton from '../../../../../admin/components/CreditCardPaymentForm/AddCcAccountButton';
import { useClientPaymentMethodsList } from '../../../../../investor/queries';

export default function PaymentInfo({clientId, name}){
  const {
    data:paymentMethods,
    loading:loadingPaymentMethods,
    error:paymntMethodError,
    refetch:refetchPaymentMethods
  } = useClientPaymentMethodsList({client_id: clientId})

  const achAccounts = paymentMethods?.filter(pm => pm.type === "ach") || [];
  const creditCards = paymentMethods?.filter(pm => pm.type === "credit_card") || [];

  return(
    <div id="payment-info">
      <h4 className="weight-700 mt-5">{ name && `${name}'s`} Payment Methods</h4>
      { achAccounts.length > 0 ?
          achAccounts.map(account => {
            return <AchAccount
                key={account.id}
                clientId={clientId}
                account={account}
              />
          }) : <NoAchMessage />
      }
      <br />
      { creditCards.length > 0 ?
          creditCards.map(creditCard => {
            return <CcAccount
                key={creditCard.id}
                clientId={clientId}
                creditCard={creditCard}
              />
          }) : <NoCcMessage />
      }

      <div className='d-flex flex-column flex-sm-row'>
        { achAccounts.length == 0 && <AddACHButton clientId={clientId} /> }
        { creditCards.length == 0 && <AddCcAccountButton clientId={clientId} /> }
      </div>
    </div>
  )
}

const NoAchMessage = () =>{
  return (
    <div className="bg-lightgrey p-3 fs-10 poppins">No ACH accounts added.</div>
  )
}

const NoCcMessage = () =>{
  return (
    <div className="bg-lightgrey p-3 fs-10 poppins">No credit cards added.</div>
  )
}
