import React from "react";
import OverviewSubheader from "../../components/OverviewSubheader";

function NoteItem({ note }) {
  return (
    <div className="highlighted-tile mb-1">
      <div className="inner2">{note}</div>
    </div>
  )
}

export default function NoteList({ accreditationRequest }) {
  return (
    <>
      <OverviewSubheader left="Status Notes" />
      { !accreditationRequest.notes.length  && <span className="highlighted-tile">No status notes available.</span> }
      { accreditationRequest.notes.map(note => <NoteItem note={note} />)}
    </>
  );
}