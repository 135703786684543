import React from "react";
import OverviewSubheader from "../../components/OverviewSubheader";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import AttributeValue from "../../components/AttributeValue";
import { toTitlecase } from "../../../jsUtils/stringUtils";
import { formatDate } from "jsUtils/dateTimeUtils";

export default function AMLOverview({ amlException }) {
  return (
    <>
      <OverviewSubheader left="KYC/AML Overview" />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue
              name="Status"
              value={toTitlecase(amlException.status)}
            />
            <AttributeValue
              name="KYC Status"
              value={toTitlecase(amlException.detail?.sp_kyc_status || '')}
            />
            <AttributeValue
              name="AML Status"
              value={toTitlecase(amlException.detail?.sp_aml_status || '')}
            />

          </>
        }
        right={
          <>
            <AttributeValue
              name="Date created"
              value={formatDate(amlException.created_at)}
            />
            <AttributeValue
              name="Last requested"
              value={amlException.requested_at ? formatDate(amlException.requested_at) : ''}
            />
          </>
        }
      />
    </>
  );
}