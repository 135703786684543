import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { FaLinkedin, FaGoogle } from 'react-icons/fa';
import ToastMessage from './ToastMessage';
import { createSession, setLoading, setError } from 'redux/session';
import { AuthContext } from '../contexts/authContext';
import { userApi } from '../api/user';
import { history } from '../redux/helpers';
import { OAUTH_CONFIG } from '../config/constants';
import { rollbarInstance } from '../jsUtils/rollbar';
import { runGAEvent } from '../pages/components/Tracking';
import LinkedInTag from 'react-linkedin-insight';
import { _setAuthStatus } from '../redux/user';

function onGoogleLogin(type){
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
  const form = document.createElement('form');
  form.setAttribute('method', 'GET');
  form.setAttribute('action', oauth2Endpoint);

  const params = {
    client_id: OAUTH_CONFIG.google_client_id,
    redirect_uri: `${window.location.origin}/${type}/oauth/google`,
    response_type: 'token',
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    include_granted_scopes: 'true',
    state: 'pass-through value'
  };

  for (let p in params) {
    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}

function onLinkedinLogin(type){
  const oauth2Endpoint = 'https://www.linkedin.com/oauth/v2/authorization';
  const form = document.createElement('form');
  form.setAttribute('method', 'GET');
  form.setAttribute('action', oauth2Endpoint);

  const params = {
    client_id: OAUTH_CONFIG.linkedin_client_id,
    redirect_uri: `${window.location.origin}/${type}/oauth/linkedin`,
    response_type: 'code',
    scope: 'r_liteprofile r_emailaddress'
  };

  for (let p in params) {
    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}

export default function OauthLogin({type}){
  const { setCurrentUser } = React.useContext(AuthContext);
  const location = useLocation();

  const onSuccess = (sessionUser) => {
    delete sessionUser.token
    setCurrentUser(sessionUser)
    createSession(sessionUser)
    _setAuthStatus(true);
    ToastMessage.success("Logged in successfully.")

    setLoading(false)

    if(type === 'signup'){
      runGAEvent(`${sessionUser.signup_mode} signup`, "form submission", "success")
      LinkedInTag.track("9186833");
    }

    if(localStorage.getItem('afterLoginPath')){
      const afterLoginPath = localStorage.getItem('afterLoginPath');
      localStorage.removeItem('afterLoginPath');
      return history.push(afterLoginPath);
    }

    if (sessionUser.admin) {
      history.push('/admin/companies')
    }
    else {
      const afterLoginPath = location?.state?.afterLoginPath;
      afterLoginPath
        ? history.push(afterLoginPath)
        : history.push('/')
    }
  };

  const onError = (error) =>{
    setLoading(false)
    setError(error)
    rollbarInstance.error("Error authenticating with OAuth", {error})
    if(type === 'login' && error?.provider){
      ToastMessage.error(
        `This ${error.provider} account does not have a Spaced Ventures user account. Please sign up with ${error.email}.`
      )
      history.push('/signup')
    }
  }

  const pathname = location.pathname;

  useEffect(() => {
    let params;

    if(pathname.indexOf('oauth/google') > -1){
      params = {
        provider: 'google',
        access_token: (new URLSearchParams(location.hash)).get('access_token')
      }
    }else if (pathname.indexOf('oauth/linkedin') > -1){
      params = {
        provider: 'linkedin',
        access_token: (new URLSearchParams(location.search)).get('code'),
        redirect_uri: `${window.location.origin}${location.pathname}`
      }
    }

   if(params?.access_token){
      setLoading(true);

      if(type === 'login'){
        userApi.oauthLogin(params.provider, params).then(onSuccess, onError);
      } else {
        userApi.oauthSignup(params.provider, params).then(onSuccess, onError);
      }
    } else if(params?.provider) {
      ToastMessage.error('Invalid credentials.')
    }

  }, [pathname]);

  const btnTextPrefix = type === 'login' ? 'Log in' : 'Sign up';

  const setAfterLoginPath = () => {
    const afterLoginPath = location?.state?.afterLoginPath;
    if(!localStorage.getItem("afterLoginPath")) {
      localStorage.setItem("afterLoginPath", afterLoginPath);
    }
  }

  return(
    <div className='row mt-2 d-flex justify-content-around'>
      <div className='col-12 col-md-6 mt-3 mb-md-0'>
        <button className="d-block mx-auto btn-google" onClick={ () => {
            onGoogleLogin(type);
            setAfterLoginPath();
          }}>
          <FaGoogle className='mr-2 icon'/>
          <span className='fs-14 text'>{ `${btnTextPrefix} with Google`}</span>
        </button>
      </div>
      <div className='col-12 col-md-6 mt-3 mb-md-0'>
        <button className="d-block mx-auto btn-linkedin pull-right" onClick={ () => {
            onLinkedinLogin(type);
            setAfterLoginPath();
          }}>
          <FaLinkedin className='mr-2 icon'/>
          <span className='fs-14 text'>{ `${btnTextPrefix}  with LinkedIn` }</span>
        </button>
      </div>
    </div>
  )
}
