import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Page.EmailVerification.scss';
import { planeIcon } from '../../assets/images/index'
import { useUser, resend_confirmation_email } from 'redux/user';
import { history } from 'redux/helpers'

const VaidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Enter your registered email address here')
});


export default function EmailVerification(){
  const user = useUser();

  if (user?.is_confirmed)
    history.push('/')

    return(
        <div className="email-verification-wrapper">
            <div className="email-verification-content-box">
                <img src={planeIcon} className="ev-icon" alt="plane icon" />
                <h3 className="ev-title">Prepare for landing!</h3>
                <p className="ev-description">We’re almost there! Before we proceed, please verify your email through the verification link we just emailed you.</p>
                <p className="ev-description">You should receive the verification email within a few minutes. If you don’t receive the email after 5 minutes, please enter the email you signed up with to receive another link or email support@spacedventures.com.</p>
                <Formik
                    initialValues={{email: ''}}
                    validationSchema={VaidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        resend_confirmation_email(values)
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form className="py-3 px-0 px-md-5">
                            <div className="form-label-group">
                                <Field type="email"
                                    className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                />
                                <label htmlFor="email">Email</label>
                                <ErrorMessage name="email" component="div" className="invalid-feedback text-left"/>
                            </div>
                            <button disabled={isSubmitting} className="btn-black-outline-modal ev-button" type="submit">
                                Resend Email
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}