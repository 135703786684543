import { createReduxModule } from 'hooks-for-redux'
import ToastMessage from '../components/ToastMessage';
import { userApi } from 'api/user'
import { history } from 'redux/helpers'
import { runGAEvent } from '../pages/components/Tracking';
import { fbEvent, fbStandardEvent } from '../pages/components/Tracking/facebook';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { rollbarInstance } from 'jsUtils/rollbar';
import LinkedInTag from 'react-linkedin-insight';

const storeKey = 'user'
const initialState = {
  error: null,
  loading: false,
  ach_authorizations: [],
  investment_limit_info: {},
  creditCards: [],
  authenticated: null
};

export const [useUser, { _updateUser, _clearUser, _setError, _setLoading, _setAuthStatus, _setAchAuthorizations,
  _addAchAuthorization, _setAchAuthorization, _setUserInvestment, _removeAchAuthorization,
  _setInvestmentLimits, _pushClient, _updateClient, _setDocuments, _setAmlException, _clearInvestment,
  _clearAmlException, _clearUserClients, _setCreditCards, _deleteCreditCard, _clearAchAutorization, _clearCreditCards }, userStore] = createReduxModule(storeKey, initialState, {
    _updateUser: (state, user) => {
      return {
        ...state,
        ...user
      }
    },

    _clearUser: (state) => {
      return {};
    },

    _setError: (state, error) => {
      return { ...state, error }
    },

    _setAuthStatus: (state, authenticated) => {
      return {
        ...state,
        authenticated
      }
    },

    _setLoading: (state, loading) => {
      return { ...state, loading }
    },

    _setAchAuthorizations: (state, ach_authorizations) => {
      return { ...state, ach_authorizations }
    },

    _addAchAuthorization: (state, achAccount) => {
      const user = JSON.parse(JSON.stringify(state))
      user.ach_authorizations.push(achAccount)
      return {
        ...state,
        ...user
      }
    },
    _setAchAuthorization: (state, achAccount) => {
      return {
        ...state,
        achAccount
      }
    },

    _clearAchAutorization: (state) => {
      return {
        ...state,
        achAccount: null
      }
    },

    _clearUserClients: (state) => {
      return {
        ...state,
        clients: [],
      }
    },

    _setUserInvestment: (state, investment) => {
      return { ...state, investment }
    },

    _removeAchAuthorization: (state, achAccountId) => {
      const user = JSON.parse(JSON.stringify(state))
      user.ach_authorizations = user.ach_authorizations.filter(account => account.id !== achAccountId)
      return {
        ...state,
        ...user
      }
    },

    _setInvestmentLimits: (state, data) => {
      const user = JSON.parse(JSON.stringify(state));
      user.investment_limit_info = data;
      return {
        ...state,
        ...user
      }
    },

    _updateSelfAttestation: (state, values) => {
      return {
        ...state,
        ...values
      }
    },

    _pushClient: (state, data) => {
      const user = JSON.parse(JSON.stringify(state));
      user.clients.push(data);
      return {
        ...state,
        ...user
      }
    },
    _updateClient: (state, data) => {
      const user = JSON.parse(JSON.stringify(state));
      const index = user?.clients.findIndex(client => client.id === data.id)
      user.clients[index] = data;
      return {
        ...state,
        ...user
      }
    },

    _setDocuments: (state, entityDocuments) => {
      return {
        ...state,
        entityDocuments
      }
    },

    _setAmlException: (state, amlException) => {
      return {
        ...state,
        amlException
      }
    },

    _clearInvestment: (state) => {
      return {
        ...state,
        investment: null
      }
    },
    _clearAmlException: (state) => {
      return {
        ...state,
        amlException: null
      }
    },

    _setCreditCards: (state, creditCard) => {
      const user = JSON.parse(JSON.stringify(state));
      user.creditCards.push(creditCard)
      return {
        ...state,
        ...user,

      }
    },

    _deleteCreditCard: (state, creditCardId) => {
      const user = JSON.parse(JSON.stringify(state));
      const creditCards = user.creditCards.filter(creditCard => creditCard.id !== creditCardId)
      user.creditCards = creditCards
      return {
        ...state,
        ...user,
      }
    },

    _clearCreditCards: (state) => {
      return {
        ...state,
        creditCards: []
      }
    },
  })

export const createUser = (userAttributes) => {
  _setLoading(true);
  userApi.create(userAttributes).then(
    data => {
      _setLoading(false);
      ToastMessage.success("User account created")
      console.log("I CREATED A USER")
      runGAEvent("user signup", "signup form submission", "success")
      LinkedInTag.track("9186833");
      fbStandardEvent("CompleteRegistration");
      history.push('/email-verification');
    },
    error => {
      _setLoading(false);
      _setError({ signup: error })
      renderRailsErrors(error)
    }
  )
}

export const updateUser = (id, updatedAttributes, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Updating user profile info")
  userApi.update(id, updatedAttributes).then(
    userData => {
      _updateUser(userData)
      if (onSubmit) onSubmit();
      _setLoading(false);
      rollbarInstance.info("Updated user profile info")
      ToastMessage.success("Profile updated")
    },
    error => {
      _setLoading(false);
      _setError({ update: error })
      rollbarInstance.error("Error updating user profile info", { error })
      ToastMessage.error("Something went wrong. Please try again")
    }
  )
};

export const updatePassword = (updatedAttributes, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Updating user password")
  userApi.update_password(updatedAttributes).then(
    _ => {
      _setLoading(false);
      if (onSubmit) onSubmit();
      rollbarInstance.info("Updated user profile info")
      ToastMessage.success("Password updated successfully")
    },
    error => {
      _setLoading(false);
      _setError({ updatePassword: error })
      rollbarInstance.error("Error updating user profile info", { error })
      ToastMessage.error("Something went wrong. Please try again")
    }
  )
};

export const fetchCurrentUser = (options = {}) => {
  _setLoading(true);
  return userApi.me(options).then(
    data => {
      _updateUser(data)
      _setLoading(false);
    },
    error => {
      _setLoading(false);
      _setError({ fetch: error })
    }
  )
};

export const resend_confirmation_email = (attrs) => {
  _setLoading(true);
  rollbarInstance.info("Resending confirmation email")
  userApi.resend_confirmation_email(attrs).then(
    response => {
      _setLoading(false);
      rollbarInstance.info("Resent confirmation email")
      ToastMessage.success("Confirmation email sent")
    },
    error => {
      rollbarInstance.error("Error resending confirmation email", { error })
      _setLoading(false);
      renderRailsErrors(error)
    }
  );
}

export const confirm_email = (token) => {
  _setLoading(true);
  rollbarInstance.info("Confirming email")
  userApi.confirm_email({ token }).then(
    response => {
      _setLoading(false);
      history.push('/login')
      rollbarInstance.info("Confirmed email")
      ToastMessage.success("Email confirmed")
    },
    error => {
      _setError({ email_confirmation: error.status })
      _setLoading(false);
      rollbarInstance.error("Error Confirming email", { error })
      renderRailsErrors(error)
    }
  )
}

export const updateInvestmentTerms = (id, investmentTermsAttr) => {
  _setLoading(true);
  rollbarInstance.info("Updating disclaimers acceptance")
  userApi.update(id, investmentTermsAttr).then(
    userData => {
      _updateUser(userData)
      _setLoading(false);
      rollbarInstance.info("Updated disclaimers acceptance")
      ToastMessage.success("Welcome to Spaced Ventures")
      history.push('/');
    },
    error => {
      _setError({ update: error })
      _setLoading(false);
      rollbarInstance.error("Error updating disclaimers acceptance", { error })
      ToastMessage.error("Something went wrong. Please try again")
    }
  )
};

export const createInvestorAccount = (userId, attrs, offerId, redirect, onSubmit, nextStep) => {
  _setLoading(true);
  rollbarInstance.info("Creating individual investor account")
  userApi.create_investor_account(userId, { client: attrs }).then(
    investment_account => {
      _setLoading(false);
      ToastMessage.success("Investment account created");
      rollbarInstance.info("Created individual investor account")
      fetchCurrentUser();
      runGAEvent("investment account created", "form submission", "success");
      fbEvent("investment account created");

      if(redirect){
        let pageUrl;

        if(nextStep === 'invest'){
          pageUrl = `/offers/${offerId}/invest/accounts/${investment_account.id}`
        }else if(nextStep === 'broker_dealer_terms'){
          pageUrl = `/broker-dealer-terms/offers/${offerId}`;
        } else {
          pageUrl = `/offers/${offerId}/accounts/${investment_account.id}/limit-calculator`;
        }

        history.push(pageUrl);
      }

      if (onSubmit) onSubmit();
    },
    error => {
      _setLoading(false);
      rollbarInstance.error("Error creating individual investor account", { error })
      Object.keys(error.message).map(key => {
        if (error.message[key].length > 0) {
          error.message[key].map(ErrorMessage => {
            return ToastMessage.error(`${key} ${ErrorMessage}`)
          })
        }
        else return ToastMessage.error("Something went wrong")
      })
      console.error(error)
    })
}

export const updateInvestorAccount = (investorId, attrs, onSubmit, userScope) => {
  _setLoading(true);
  rollbarInstance.info("Updating investor personal info")
  return userApi.update_investor_account(investorId, attrs, userScope).then(
    () => {
      _setLoading(false);
      if (onSubmit) onSubmit();
      fetchCurrentUser();
      ToastMessage.success("Investment account updated");
      rollbarInstance.info("Updated individual investor account")
    },
    error => {
      _setLoading(false);
      rollbarInstance.error("Error updating investor personal info", { error })
      Object.keys(error?.message).map(key => {
        if (error.message[key].length > 0) {
          error.message[key].map(ErrorMessage => {
            return ToastMessage.error(`${key} ${ErrorMessage}`)
          })
        }
        else return ToastMessage.error("Something went wrong")
      })
      console.error(error)
    })
}

export const createAchPayment = (clientId, attrs, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Creating ACH payment method")
  userApi.create_ach_payment(clientId, attrs).then(
    (response) => {
      _addAchAuthorization(response)
      _setLoading(false);
      if (onSubmit) onSubmit(response.id);
      ToastMessage.success("ACH payment method added");
      rollbarInstance.info("Created ACH payment method")
      runGAEvent("ACH payment created", "form submission", "success");
      fbEvent("ACH payment created");

    },
    error => {
      console.error(error)
      rollbarInstance.error("Error creating ACH payment method", { error })
      _setLoading(false);
      Object.keys(error.ach_authorization).map(key => {
        if (error.ach_authorization[key].length > 0) {
          error.ach_authorization[key].map(ErrorMessage => {
            return ToastMessage.error(`${key} ${ErrorMessage}`)
          })
        }
        else return ToastMessage.error("Something went wrong")
      })
    })
}

export const createCreditCardPayment = (clientId, attrs, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Creating CC payment method")
  userApi.create_credit_card_payment(clientId, attrs).then(
    (response) => {
      _setLoading(false);
      _setCreditCards(response)
      if (onSubmit) onSubmit(response.id);
      rollbarInstance.info("Created CC payment method")
      ToastMessage.success("Credit card payment method added");

    },
    error => {
      console.error(error)
      rollbarInstance.error("Error creating CC payment method", { error })
      _setLoading(false);

      Object.keys(error.tokenized_credit_card).map(key => {
        if (error.tokenized_credit_card[key].length > 0) {
          error.tokenized_credit_card[key].map(ErrorMessage => {
            return ToastMessage.error(`${key} ${ErrorMessage}`)
          })
        }
        else return ToastMessage.error("Something went wrong")
      })
    })
}

export const deleteAchPayment = (achId) => {
  _setLoading(true);
  rollbarInstance.info("Deleting ACH payment method")
  userApi.delete_ach_payment(achId).then(
    (response) => {
      _removeAchAuthorization(achId)
      _setLoading(false);
      rollbarInstance.info("Deleted ACH payment method")
      ToastMessage.success("ACH payment method deleted");
      runGAEvent("ACH payment deleted", "form submission", "success");
    },
    error => {
      _setLoading(false);
      rollbarInstance.error("Error deleting ACH payment method", { error })
      ToastMessage.error(error.error)
    })
}

export const fetchAchAuthorizations = (investorId) => {
  _setLoading(true);
  userApi.fetch_ach_authorizations(investorId).then(
    (response) => {
      _setAchAuthorizations(response);
      _setLoading(false);
    },
    error => {
      _setLoading(false);
      ToastMessage.error("Error fetching ACH payment");
      console.error(error)
    })
}

export const forgotPassword = (attrs, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Init forgot password")
  userApi.forgot_password(attrs).then(
    () => {
      _setLoading(false);
      if (onSubmit) onSubmit();
      rollbarInstance.info("Sent password reset link")
      ToastMessage.success("Reset password link sent");
    },
    error => {
      _setLoading(false);
      rollbarInstance.error("Error Sending password reset link", { error })
      ToastMessage.error("Error sending reset password link");
      console.error(error)
    })
}

export const resetPassword = (attrs, onSubmit = null) => {
  _setLoading(true);
  rollbarInstance.info("Resetting password")
  userApi.reset_password(attrs).then(
    () => {
      _setLoading(false);
      if (onSubmit) onSubmit();
      history.push('/login');
      rollbarInstance.info("Reset password success")
      ToastMessage.success("Password updated");
    },
    error => {
      _setLoading(false);
      rollbarInstance.error("Error resetting password", { error })
      ToastMessage.error("Error updating password");
      return error?.error?.map(e => {
        ToastMessage.error(e);
      });
      console.error(error)
    })
}

export const updateInvestementLimit = (userId, attrs, onSubmit) => {
  _setLoading(true)
  rollbarInstance.info("Updating investment limits")
  return userApi.updateInvestementLimit(userId, attrs).then(
    response => {
      _updateUser(response);
      if (onSubmit) onSubmit();
      _setLoading(false);
      ToastMessage.success("Investment limit updated");
      rollbarInstance.info("Updated investment limits")
      runGAEvent("investment limit updated", "form submission", "success");
      fbEvent("investment limit updated");
    },
    error => {
      _setLoading(false)
      rollbarInstance.error("Error updating investment limits", { error })
      renderRailsErrors(error);
    }
  )
}

export const createEntityInvestment = (userId, attrs, onSubmit) => {
  _setLoading(true)
  rollbarInstance.info("Creating entity")
  return userApi.createEntityInvestment(userId, attrs).then(
    response => {
      _pushClient(response);
      if (onSubmit) onSubmit(response.id);
      _setLoading(false);
      ToastMessage.success(`Entity created ${response.name}`);
      rollbarInstance.info("Created entity")
      runGAEvent("entity created", "form submission", "success");
      fbEvent("entity created");
    },
    error => {
      _setLoading(false)
      rollbarInstance.error("Error creating entity", { error })
      renderRailsErrors(error);
    }
  )
}

export const updateEntityAccount = (clientId, attrs, onSubmit, userScope) => {
  _setLoading(true)
  rollbarInstance.info("Updating entity")
  return userApi.updateEntityAccount(clientId, attrs, userScope).then(
    response => {
      _updateClient(response);
      if (onSubmit) onSubmit(response.id);
      _setLoading(false);
      ToastMessage.success(`Updated ${response.name}`);
    },
    error => {
      _setLoading(false)
      rollbarInstance.error("Error updating entity", { error })
      renderRailsErrors(error)
    }
  )
}

export const getInvestment = (investmentId) => {
  _setLoading(true)
  return userApi.getInvestment(investmentId).then(
    response => {
      _setUserInvestment(response)
      _setLoading(false)
    }, error => {
      _setLoading(false)
    }
  )
}

export const fetchEntityDocuments = (clientId) => {
  _setLoading(true)
  return userApi.getEntityDocuments(clientId).then(
    response => {
      _setDocuments(response)
      _setLoading(false);
    }, error => {
      _setLoading(false);
    }
  )
}

export const fetchAmlException = (investmentId) => {
  _setLoading(true)
  return userApi.getAmlException(investmentId).then(
    response => {
      _setAmlException(response)
      _setLoading(false)
    }, error => {
      console.log(error)
      _setLoading(false)
    }
  )
}

export const fetchAchAuthorization = (achId) => {
  _setLoading(true)
  return userApi.fetch_ach_authorization(achId).then(
    response => {
      _setAchAuthorization(response)
      _setLoading(false)
    }, error => {
      console.log(error)
      _setLoading(false)
    }
  )
}

export const uploadDocument = (clientId, formData, onSubmit = null) => {
  _setLoading(true)
  rollbarInstance.info("Uploading entity document")
  return userApi.postEntityDocument(clientId, formData).then(
    response => {
      if (onSubmit) onSubmit();
      rollbarInstance.info("Uploaded entity document")
      _setLoading(false)
    }, error => {
      console.log(error)
      rollbarInstance.error("Error uploading entity document", { error })
      _setLoading(false)
    }
  )
}

export const updateSelfAttestation = (values) => {
  _updateUser(values)
}

export const updateUserDetail = (values) => {
  _updateUser(values)
}

export const updateBrokerDealerTerms = (values) => {
  _updateUser(values)
}

export const updateClient = (values) => {
  _updateClient(values)
}