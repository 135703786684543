import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import {
  progressBarPercentage,
  raisedFundsPercentage
} from '../../../jsUtils/offerUtils';
import AmountWithCurrency, {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../components/AmountWithCurrency';

export default function FundingProgressBar({ offer, bgLight=false, showRaisedAmount=true }) {
  const raisedAmount = offer.deal?.raised ? parseInt(offer.deal?.raised) : 0;
  const targetGoal = offer.deal?.goal || 0;
  const maximumGoal = offer.deal?.maximum_goal || 0;
  const investorsCount = offer.investor_count || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, targetGoal);
  const fundsPercentageMax = raisedFundsPercentage(raisedAmount, maximumGoal);

  return (
    <>
      {showRaisedAmount && <div className="amount-raised">
        <span className="amount">
          <FormattedNumberWithSymbol number={raisedAmount} numberFormattingOpts={{precision: 0}} />
        </span>
        <span className="raised-text">&nbsp;Raised</span>
        <CurrencyBadge />
      </div>}
      <div className="progressbar-summary">
        <div className="mb-3 mb-sm-2">
          <span className="funded-precentage">{ fundsPercentage }%</span>
          <span className="funded-text ml-2">of target: {formatMoney(targetGoal, {precision : 0})}</span>
        </div>
      </div>
      <div className="progress-box">
        <div className={`progressbar ${bgLight ? 'bg-light' : ''}`}><div style={{width: `${progressBarPercentage(fundsPercentage)}%`}} className="progress"></div></div>
        <div className={`progressbar-mini ${bgLight ? 'bg-light' : ''}`}><div style={{width: `${progressBarPercentage(fundsPercentageMax)}%`}} className="progress"></div></div>
      </div>
      <div className="progressbar-summary">
        <div className="funded">
          <span className="funded-precentage">{ fundsPercentageMax }%</span>
          <span className="funded-text ml-2">of maximum goal raised: {formatMoney(maximumGoal, {precision : 0})}</span>
        </div>
        <div className="investors">
          <span className="investors-count">{ investorsCount }</span>
          <span className="investors-text ml-2">Investors</span>
        </div>
      </div>
    </>
  )
}