import React from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";

function ResponsiveTable({
  columns,
  data,
  tableSummary = null,
  loading = false,
  loadingMsg = "Loading data from rstable <update me before commit>...",
  noDataMsg = "No data available.",
  classes = "",
  onRowClick
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (loading) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center p-3">
          <span
            role="status"
            className="spinner-border spinner-border-sm text-dark mr-2"
          ></span>
          {loadingMsg}
        </div>
      </>
    );
  }

  if (rows.length < 1)
    return (
      <div className="d-flex justify-content-center align-items-center p-3">
        {noDataMsg}
      </div>
    );

  return (
    <div className="bg-lightgrey3">
      <div className="table-responsive px-3 action-table">
        <table
          {...getTableProps()}
          className={`table table-borderless ${classes}`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: `${column.className} py-0 fs-10`,
                        style: column.style,
                      },
                    ])}
                    scope="col"
                    style={{ fontWeight: 500 }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={ () => onRowClick ? onRowClick(row.original) : null } className="f-black fs-10 bg-white">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
            {tableSummary && tableSummary}
          </tbody>
        </table>
      </div>
    </div>
  );
}

ResponsiveTable.defaultProps = {};

ResponsiveTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ResponsiveTable;
