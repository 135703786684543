import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import ToastMessage from '../../../components/ToastMessage';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import PopupModal from '../../components/PopupModal'
import { deletePitchSection } from '../../pages/offer/queries';

export default function DeleteSectionButton({sectionId, sectionType, onSubmit = null}) {
  const [ShowConfirmation,setShowConfirmation] = useState(false);
  const queryClient = useQueryClient();

  const { offerSlug } = useParams()

  const deletePitchSectionMutation = useMutation(
    deletePitchSection,
    {
      onSuccess: () => {
        ToastMessage.success("Pitch section deleted.")
        queryClient.invalidateQueries(
          ['admin', 'offers', offerSlug, 'pitch'],
          {
            exact: true
          }
        )
      },
      onError: (error) => {
        ToastMessage.error("Pitch section delete failed.")
        renderRailsErrors(error)
      },
    }
  )

  if (!sectionId) return null;

  return (
    <>
      <button type='button'
        className="mx-3 weight-300 btn btn-outline-dark rounded-0"
        onClick={() => {
          setShowConfirmation(true);
        }}>Delete Section</button>
      {ShowConfirmation && <PopupModal onRequestClose={()=>setShowConfirmation(false)} title='Please confirm'>
        <div>
          <div className="px-3">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className="w-1-dashed p-3 text-right">
            <button className="weight-300 btn-black px-3 poppins" onClick={()=>{
            deletePitchSectionMutation.mutate({section_id: sectionId, type: sectionType})
            setShowConfirmation(false);
            }}>Confirm</button>
          </div>
        </div>
      </PopupModal>
      }
    </>
  );
}