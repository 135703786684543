import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from "react-router";
import { format, parseJSON } from 'date-fns';
import Overview from './Overview';
import {
  OverviewModal,
  ResponsiveTable,
  TableActionsContainer,
  TableActionItem,
  Paginator,
} from 'admin/components'
import ErrorBoundary from 'components/ErrrorBoundary';
import { toTitlecase, humanize } from 'jsUtils/stringUtils';
import { history } from 'redux/helpers';
import ToastMessage from 'components/ToastMessage';
import { usePreofferList, deletePreoffer } from './queries';
import usePagination from 'hooks/usePagination';
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../components/AmountWithCurrency';
import { useMutation } from 'react-query';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer'
import { FaTrash, FaEye, FaPen } from 'react-icons/fa';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import LinkedData from '../../../components/LinkedData';
import SearchInput from '../../../components/SearchInput';
import useSearchInputManager from '../../../hooks/useSearchInputManager';

function List() {
  let { path, url } = useRouteMatch();
  const pageConfig = usePagination();
  const searchInputManager = useSearchInputManager()

  React.useEffect(()=> {
    pageConfig.setPage(1);
  }, [searchInputManager.queryString])

  const preofferList = usePreofferList({q: searchInputManager.queryString, ...pageConfig.current})

  const deleteConfirmModal = React.useContext(PortalizedModalContext);

  const openShowModal = React.useCallback(
    (row) => {
      history.push(`${url}/${row.original.slug}`)
    },
    []
  )

  const closeShowModal = React.useCallback(
    () => {
      history.push(`${url}`)
    },
    []
  )

  const openEditorModal = (row) => {
    history.push(`${url}/${row.original.slug}/edit`)
  }

  const deletePreofferMutation = useMutation(deletePreoffer, {
    onSuccess: (values) => {
      preofferList.refetch();
      ToastMessage.success(`Prelaunch deleted successfully.`);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
  })

  const handleDelete = (preoffer_id) => () => {
    deleteConfirmModal.setModalContent(
      "Delete Prelaunch?",
      <>
        <p>Are you sure you want to delete the prelaunch?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deletePreofferMutation.mutate({preoffer_id})
              deleteConfirmModal.closeModal();
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteConfirmModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (preofferList.isSuccess)
        return preofferList.data.data
      else
        return []
    },
    [preofferList]
  );


  const columns = React.useMemo(
    () => {
      if (preofferList.isSuccess){
        return [
          {
            Header: 'Offer Name',
            accessor: 'name',
            Cell: ({row, value}) => (
              <LinkedData onClick={() => openShowModal(row)}>
                {value}
              </LinkedData>
            )
          },
          {
            Header: 'Company',
            accessor: 'company',
            Cell: ({value}) => (
              <div className="d-flex align-items-start">
                <img src={value.logo_url} alt='' className="company-table-logo mr-2" />
                {toTitlecase(value.name)}
              </div>
           )
          },
          {
            Header: 'Created',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: <>Goal&nbsp;<CurrencyBadge /></>,
            accessor: 'goal_amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: <>Amt funded&nbsp;<CurrencyBadge /></>,
            accessor: 'committed',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: <>Valuation&nbsp;<CurrencyBadge /></>,
            accessor: 'valuation',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
           {
            Header: <>Min. Investment&nbsp;<CurrencyBadge /></>,
            accessor: 'minimum_investment_amount',
            Cell: ({value}) => <FormattedNumberWithSymbol number={value} />
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({value}) => humanize(value)
          },
          {
            Header: 'Type',
            accessor: 'type'
          },
           {
            Header: 'Listed',
            accessor: 'listed_at',
            Cell: ({value}) => value ? 'Yes' : 'No'
          },
          {
            Header: 'NDA Enabled',
            accessor: 'nda',
            Cell: ({value}) => value ? 'Yes' : 'No'
          },
          {
            Header: 'Secondary',
            accessor: 'category',
            Cell: ({value}) => value === "secondary" ? 'Yes' : 'No'
          },
          {
            Header: "",
            accessor: "id",
            Cell: ({ row, value }) => (
              <TableActionsContainer>
                <TableActionItem tooltipText="Edit" onClick={() => openEditorModal(row)}>
                  <FaPen />
                </TableActionItem>
                <TableActionItem tooltipText="View" onClick={() => openShowModal(row)}>
                  <FaEye />
                </TableActionItem>
                <TableActionItem
                  tooltipText="Delete"
                  onClick={handleDelete(row.original.slug)}
                  disabled={row.original.status !== "draft"}
                >
                  <FaTrash />
                </TableActionItem>
              </TableActionsContainer>
            ),
          },
        ]
      }
      else{
        return []
      }
    },
    [preofferList]
  );

  return (
    <div className="bg-lightgrey">
      <ErrorBoundary>
        <div className="admin-main-content-header">
          <SearchInput {...searchInputManager} />
        </div>
        <Paginator
          theme='dark'
          onPage={pageConfig.setPage}
          meta={preofferList?.data?.pagy}
          loading={preofferList.isLoading}
        />
        <ResponsiveTable
          columns={columns}
          data={tableData}
          loading={preofferList.isLoading}
          loadingMsg="Loading preoffers..."
          noDataMsg="No preoffers found."
          classes="use-xxl-width"
        />
        <Switch>
          <Route path={`${path}/:preofferSlug`}
            component={(props) => (
              <OverviewModal onRequestClose={closeShowModal}>
                <Overview />
              </OverviewModal>
            )}
          />
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

List.defaultProps = {};

List.propTypes = {};

export default List;