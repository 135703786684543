import React from 'react';
import YoutubeEmbed from '../../../../admin/components/shared/YoutubeEmbed';
import gif1 from '../../../../assets/images/graphics/petition-graphic-1.svg';
import gif2 from '../../../../assets/images/graphics/petition-graphic-2.svg';
import gif3 from '../../../../assets/images/graphics/petition-graphic-3.svg';

function Item({title, text, img}){
  return (
    <div className="how-it-works-card d-flex align-items-start mb-3 ">
      <div><img  className="how-it-works-card-img" src={img} alt={title}/></div>
      <div className="ml-4 d-flex justify-content-start align-items-start flex-column">
        <h3 style={{marginBottom: '0px'}} className="montserrat fs-7 weight-900">{title}</h3>
        <p className="m-t-5 fs-default poppins weight-400">{text}</p>
      </div>
    </div> 
  )
}

export default function HowItWorks ({company}) {
  const data = [
    {
      title: "Make Your Pledge",
      text: `Pledge to invest in ${company?.name}.`,
      img: gif1
    },
    {
      title: "Get Their Attention",
      text: `When enough interest builds, we'll notify ${company?.name}.`,
      img: gif2
    },
    {
      title: `Invest`,
      text: "Turn pledges into investments if they raise on Spaced Ventures.",
      img: gif3
    },
  ]
  return (
    <>
      <div className="container main-section">
        <h2 className="fs-5 weight-900 pb-2 center-mobile">How does a Petition to Raise work?</h2>
        <div className='row m-t-40'>
          <div className='col-lg-7'>
            <div className='for-youtube-vid-container'>
              <YoutubeEmbed videoUrl="https://www.youtube.com/watch?v=cxWh1D_J0a4" />
            </div>
          </div>
          <div className='col-lg-5 '>
              <div className="how-it-works-petition">
              {data.map((value, key) => {
                return (
                  <Item key={key} title={value.title} text={value.text} img={value.img} />
                )
              })}
            </div>            
          </div>   
        </div> 
    </div>  
    </>
  )
}