import React, { useState } from 'react';
import { useParams } from "react-router";
import ToastMessage from '../../../../components/ToastMessage';
import { useMutation, useQueryClient } from 'react-query';
import { deletePitchSection } from './../queries';
import PopupModal from './../../../components/PopupModal';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import LoadingOverlay from 'react-loading-overlay';

export default function DeleteSectionButton({sectionId, sectionType}) {
  const queryClient = useQueryClient();

  const { preofferSlug } = useParams()

  const deletePitchSectionMutation = useMutation(
    deletePitchSection,
    {
      onSuccess: () => {
        ToastMessage.success("Pitch section deleted.")
        queryClient.invalidateQueries(
          ['preoffer#pitch', preofferSlug],
          {
            exact: true
          }
        )
      },
      onError: (error) => {
        ToastMessage.error("Pitch section delete failed.")
        renderRailsErrors(error)
      },
    }
  )

  const [ShowConfirmation,setShowConfirmation]=useState(false);
  if (!sectionId) return null;

  return (
    <LoadingOverlay active={deletePitchSectionMutation.isLoading} spinner text="Loading" className='d-inline'>
      <button type='button'
        className="mx-3 zeitung-mono weight-300 btn btn-outline-dark rounded-0"
        onClick={() => {
          setShowConfirmation(true);
        }}>Delete Section</button>
      {ShowConfirmation && <PopupModal onRequestClose={()=>setShowConfirmation(false)} title='Please confirm'>
        <div>
          <div className="px-3">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className="w-1-dashed p-3 text-right">
            <button className="zeitung-mono weight-300 btn-black px-3"
              onClick={() => {
                deletePitchSectionMutation.mutate({section_id: sectionId, type: sectionType})
                setShowConfirmation(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </PopupModal>
      }
    </LoadingOverlay>
  );
}