import React from 'react'
import { format, parseJSON } from 'date-fns';
import LoadingOverlay from 'react-loading-overlay';
import SingleInvestmentDetailsRow from '../../admin/components/shared/SingleInvestmentDetailsRow';
import AmountWithCurrency from '../../components/AmountWithCurrency';
import { humanize } from '../../jsUtils/stringUtils';
import { getTimeZone } from '../../jsUtils/dateTimeUtils';
import { useQueryClient, useMutation } from 'react-query'
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { deletePreofferSubscription, updatePreofferSubscription } from "../../investor/queries";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import ToastMessage from 'components/ToastMessage';
import { history } from 'redux/helpers';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import HookForm from '../../components/HookForm';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatMoneyClean } from 'jsUtils/currencyUtils';

export default function IndicativeInterestDetail({ subscription }) {
  const esign_request = subscription.esign_request
  const isSecondary = subscription.preoffer.category === "secondary";

  return (
    <>
      <div className="mb-2 investment-section">
        <h5 className="montserrat weight-700 mb-2 pr-4">{ isSecondary ? "My Secondary Allocation" : "My Pre-launch Interest"}</h5>
        <SingleInvestmentDetailsRow
          title="Amount"
          desc={
            <div className='my-investment-amount'>
              <AmountWithCurrency number={subscription.amount} />
            </div>}
        />
        <SingleInvestmentDetailsRow title={'Invested as'} desc={subscription.client.name} />
        <SingleInvestmentDetailsRow title={'Created at'} desc={format(parseJSON(subscription.created_at), 'MMM dd, yyyy hh:mm a ') + getTimeZone()} />
        {esign_request?.status &&
          <SingleInvestmentDetailsRow title={'eSign Status'} desc={humanize(esign_request.status)} />
        }
        {esign_request?.signed_document_url &&
          <SingleInvestmentDetailsRow title={'eSiged Document'} desc={<a href={esign_request.signed_document_url } target='_blank'>View</a>} />
        }
        <EditDetail subscription={subscription} />
      </div>
      <CancellationDetail subscription={subscription} />
    </>
  )
}

function CancellationDetail({ subscription }) {
  const queryClient = useQueryClient();
  const [loader, setLoader] = React.useState(false);
  const modal = React.useContext(PortalizedModalContext);
  const {preoffer: {id: preoffer_id, status: preoffer_status}, id: subscription_id} = subscription

  const deleteMutation = useMutation(deletePreofferSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portfolio', 'prelaunch']);
      ToastMessage.success("Your interest has been cancelled.");
      setLoader(false);
      history.push("/account/investments#prelaunches");
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      modal.closeModal();
    }
  });

  if (preoffer_status != 'live'){
    return <></>
  }

  const onCancelPress = (investmentId) => {
    modal.setModalContent(
      `Cancel your interest`,
      <>
        <p className='instruction-text-for-mobile'>
          This action cannot be undone. Are you sure you want to cancel your
          interest on Spaced Ventures?
        </p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              deleteMutation.mutate({preoffer_id, subscription_id})
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => modal.closeModal()}
          >
            Close
          </button>
        </div>
      </>,
      "sm"
    );
    modal.openModal();
  };
  
  return (
    <LoadingOverlay
      active={loader}
      spinner
      text='Loading ...'
    >
      <div className="mb-4 investment-handler-btn">
        <button onClick={() => onCancelPress(subscription.id)} className="weight-700 btn-black-outline btn-block fs-9 px-2"><FaTrashAlt size="18" className='mr-1 mb-1'/> Cancel</button>
      </div>
    </LoadingOverlay>
  )
}


function EditDetail({ subscription }) {
  const queryClient = useQueryClient();
  const modal = React.useContext(PortalizedModalContext);
  const { preoffer, id: subscription_id } = subscription
  const { id: preoffer_id, status: preoffer_status } = preoffer;

  const updateMutation = useMutation(updatePreofferSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portfolio', 'prelaunch']);
      queryClient.invalidateQueries(['preoffer#subscription']);
      queryClient.invalidateQueries(['preoffer', preoffer.slug]);
      ToastMessage.success("Your interest has been updated.");
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      modal.closeModal();
    }
  });

  const EditSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Invalid amount').positive()
      .integer("Please enter an amount without cents")
      .max(1000000, 'Maximum indication amount is $1,000,000. Email venture@spacedventures.com if you’re interested in a higher allocation.')
      .min(preoffer.minimum_investment_amount, `Minimum investment is ${formatMoneyClean(preoffer.minimum_investment_amount)}`),
  });

  const defaultValues = {
    amount: subscription.amount,
  };

  const methods = useForm({
    resolver: yupResolver(EditSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: defaultValues
  });

  const onSubmit = (values) => {
    updateMutation.mutate({
      preoffer_id,
      subscription_id,
      values: { subscriber: values }
    })
  }

  if (preoffer_status != 'live'){
    return <></>
  }

  const onEditPress = (investmentId) => {
    modal.setModalContent(
      `Edit your interest`,
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1" autoComplete="off">
          <h5 className="montserrat weight-700 m-b-15">Amount</h5>
          <div className="row mt-2">
            <div className="col input-bg-white">
              <HookForm.CurrencyInputField name="amount" placeholder="Amount" />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="poppins weight-700 btn-black p-2"
              disabled={updateMutation.isLoading}
            >
              Update
            </button>
          </div>
        </form>
      </FormProvider>,
      "sm"
    );
    modal.openModal();
  };

  return (
      <div class="investment-handler-btn">
        <button onClick={() => onEditPress(subscription.id)} className="weight-700 btn-black-outline btn-block fs-9 px-2">
          <FaEdit size="18" className='mr-1 mb-1'/> Edit
        </button>
      </div>
  )
}