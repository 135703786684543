import React, { useEffect } from 'react'
import { PageViewFB } from './components/Tracking/facebook';
import { Helmet } from "react-helmet";

import ApplyToRaise from './components/ApplyToRaise.js';
import SpaceExperts from './components/SpaceExperts';
import LaunchingSoon from './components/LaunchingSoon';

import capTable from '../assets/images/graphics/simple-cap-table-v4.png'
import capTableBlack from '../assets/images/graphics/CapManagement@2x.png'
import followOnGraphicTable from '../assets/images/graphics/round-infographic-v3.png'
import followOnGraphicTableWhite from '../assets/images/graphics/FollowOnGraphicWhite2x.png'
import styled from 'styled-components'

function RaiseBanner({ children }) {
  return (
    <div className="raise-banner">
      <div className="container">
        <div className="page-header-1">
          <h2 className="hero-header-main"><span className="text-muted weight-400 fs-6">Issuers gain access to</span><br></br> Money +<br></br> Expertise +<br></br> Network.</h2>
          <div className="raise-subheader">
            <p className="raise-subheader-text">By building in access to industry experts and a growing network of space enthusiasts, raising your early funding with Spaced Ventures could set your company up for long term success.</p>
          </div>
          <div className="py-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

function RaiseRocket() {
  return (
    <div className="rocket-image rocket-image-container">
      <div className="container">
        <div className="rocket-text">
          <h2 className="fs-4 weight-900">Funding launches</h2>
          <p className="fs-6 m-t-25 weight-300">We know aerospace. We know startups. And we know how hard it is to raise funds as an aerospace startup.</p>
          <p className="fs-6 m-t-25 weight-300">A well-timed Regulation Crowdfunding raise through Spaced Ventures could set your company up for long term success. Our companies get direct access to advice from space industry experts as well as talent from the world’s largest network of space enthusiasts and investors.</p>
          
        </div>
      </div>
    </div>
  )
}

function CapManagement() {
  return (
    <div className="cap-management-container">
      <div className="container main-section">
        <div className="cap-grid">
          <div className="cap-text">
            <h2 className="fs-4 weight-900">SPV for Cap Table Management</h2>
            <p className="fs-6 weight-300 m-t-25">The use of SPVs in your Regulation Crowdfunding round allows hundreds and potentially thousands of investors to be represented by a single line on your cap table. Spaced Ventures investors do not typically have voting or information rights, so you’ll be able to maintain steady control of your company and its operating costs.</p>
          </div>
          <img className="cap-table-infographic" src={capTableBlack} alt="spaced ventures cap table management"></img>
        </div>
      </div>
    </div>
  )
}

function FollowOn() {
  return (
    <div className="follow-on-container">
      <div className="container main-section">
        <FollowOnContainerStyles>
        <img className="follow-on-infographic" src={followOnGraphicTableWhite} alt="follow on rounds at spaced ventures"></img>
          <div className="follow-on-text f-white item-2-grid-reverse-mobile">
            <h2 className="fs-4 weight-900">Follow-on Investment</h2>
            <p className="fs-6 weight-300 m-t-25">You can raise a venture round before, during or after your Spaced Ventures campaign. A Regulation Crowdfunding round is a valuable complement to traditional capital and not intended to replace it.</p>
          </div>
        </FollowOnContainerStyles>
      </div>
    </div>
  )
}
export default function Raise () {
  useEffect(() => {
    console.log("use effect ran for google analytics on raise")
    PageViewFB();    
  }, [])

  useEffect(() => {
    console.log('current path:', window.location.pathname)
  })    
    return (
        <>
        <Helmet>
          <title>Raise</title>
          <meta
            name="description"
            content="Raise financing for your space company on Spaced Ventures. By building in access to industry experts and a growing network of space enthusiasts, raising your early funding with Spaced Ventures could set your company up for long term success." />
        </Helmet>    
          <div className="raise-img-container">
            <RaiseBanner>
              <ApplyToRaise icon={true} text="Apply to Raise" link="https://airtable.com/shrG4Ttt2vlSBx7tc" width="240px" />
            </RaiseBanner>
          </div>
          <RaiseRocket />
          <FollowOn />
          <CapManagement />
          <div className="bg-pattern-2">
            <SpaceExperts 
              tileBoxBorder='#111'
              tileBoxShadow='#111'
              imgBorder='#fff'          
              header="Space Experts on Call" 
              text="The space industry can be difficult to navigate, especially if you’re new to it. Our advisors can help by sharing insights from their decades of experience with government contracts, space missions, complex engineering projects and traditional capital strategy. And the best part? They stay committed to helping you build your space business even after you successfully raise." />
            <LaunchingSoon 
                eventCategory="newsletter signup"
                eventAction="raise cta"
                eventLabel="raise page bottom cta"
                text="Apply to Raise" 
                link="https://airtable.com/shrknqgPojK47LugC" /> 
          </div>  
        </>
    )

}



const FollowOnContainerStyles = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
  justify-items: center;
  /* grid-auto-flow: dense; */
  /* direction: rtl; */

  @media (max-width: 990px) {
  .item-2-grid-reverse-mobile {
   order: 1;
  }

  .follow-on-infographic {
    order: 2;
  }
}`