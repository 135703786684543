import React from 'react';
import { FaLinkedin, FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';
import { NavLink, Link } from 'react-router-dom';

export default function(){

var d = new Date();
var fullYear = d.getFullYear();
  return (
    <footer className="investor-footer">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-6 footer-top-inner1 mb-lg-0 mb-4">
              <img src="https://spacedventure-marketing.s3.amazonaws.com/Wordmark+White.png" alt="white logo" className="footer-logo" />
              <div className="company-info">
                {/* <div className="company-info-inner1"></div>
                <div className="company-info-inner2">
                  <p className="mt-5">Brownsville | Toronto | Florida |<br /> hello@spacedventures.com</p>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 footer-top-inner2">
              <div className="row h-100">
                <div className="col-md-4 mb-md-0 mb-4 footer-top-inner2-a">
                  <div className='bg-darkgrey p-3 h-100'>
                    <h4 className="fs-10 f-white montserrat text-uppercase weight-700 mb-2">RAISE + INVEST</h4>
                    <ul>
                      {/* <li><Link to="/raise">For Founders</Link></li> */}
                      <li><Link to="/offers">Offers</Link></li>
                      {/* <li><a href="https://airtable.com/shrG4Ttt2vlSBx7tc" target="_blank" rel="noopener noreferrer">Apply to Raise</a></li> */}
                    </ul>
                  </div>
                </div>    
                <div className="col-md-4 mb-md-0 mb-4 footer-top-inner2-b">
                  <div className='bg-darkgrey p-3 h-100'>
                    <h4 className="fs-12 f-white montserrat text-uppercase weight-700 mb-2">COMPANY</h4>
                    <ul>
                      {/* <li><Link to="/about">About Us</Link></li> */}
                      {/* <li><Link to="/press">Press</Link></li> */}
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      {/* <li><Link to="/terms-of-service">Terms of Service</Link></li> */}
                    </ul>
                  </div>
                </div> 
                <div className="col-md-4 footer-top-inner2-c">
                  <div className='bg-darkgrey p-3 h-100'>
                    <h4 className="fs-12 f-white montserrat text-uppercase weight-700 mb-2">RESOURCES</h4>
                    <ul>
                      {/* <li><a href="https://blog.spacedventures.com/" target="_blank" rel="noopener noreferrer">Blog</a></li> */}
                      {/* <li><a target="_blank" href="https://spacedventures.zendesk.com/hc/en-us" rel="noopener noreferrer">FAQs</a></li> */}
                      <li><Link to="/support">Support</Link></li>
                      {/* <li><Link to="/mediakit">Media Kit</Link></li> */}
                      {/* <li><Link to="/newsletter">Newsletter</Link></li> */}
                    </ul>
                  </div>
                </div>                     
              </div>
            </div>
          </div>
        </div>
        <div className="footer-middle">
          <div className="row">
            <div className="col-lg-8 pt-2 footer-middle-inner1">
              <div className='bg-darkgrey p-3 h-100'>
                {/* <p className="fs-11">© {fullYear} Spaced Ventures. This site is owned by Spaced Ventures Inc., which is the parent company of SV Portal LLC. (collectively, “Spaced Ventures”). SV Portal LLC is a SEC registered funding portal and member of FINRA. SV Portal LLC uses pages of this site to list Regulation Crowdfunding offerings that are available to everyone 18 years or older.</p> */}
                {/* <p className="fs-11 mb-0">Investments in Reg CF private companies are particularly risky and you should only consider investing if you can afford to lose your entire investment and are willing to live with the ups and downs within the industry in which you invest. Spaced Ventures does not solicit investments nor does it provide investment advice, recommendations or suitability determinations. Investing involves the risk of loss and liquidity risk. Past performance is not a guarantee of future results.</p> */}
                <p className="mt-5">Toronto | Florida | New York<br /> support@33fg.com</p>
              </div>
            </div>
            <div className="col-lg-4 pt-2 footer-middle-inner2">
              <div className='bg-darkgrey p-3 h-100 d-flex flex-wrap align-content-end'>
                {/* <div className="social-icon-box mb-1 w-100">
                  <a href="https://www.facebook.com/SpacedVentures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaFacebookSquare/></a>
                  <a href="https://twitter.com/SpacedVentures" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitterSquare/></a>
                  <a href="https://www.linkedin.com/company/spaced-ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedin/></a>
                  <a href="https://www.instagram.com/spaced.ventures/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagramSquare/></a>
                  <a href="https://www.youtube.com/channel/UCw2y-5R3Ywh7Hjy_Anw8wEg" target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutubeSquare/></a>
                </div> */}
                <p className="text-white mb-0">Copyright Mach33 {fullYear}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}