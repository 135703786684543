import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ToastMessage from '../../components/ToastMessage';
import * as Yup from 'yup';
import PopupModal from './PopupModal';
import {teamMemberApi} from 'api/team_member';
import './affiliation/affiliation.scss';
import './TeamMemberForm.scss';

const TeamMemberSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  role: Yup.string().required('Role is required'),
  bio: Yup.string().required('Bio is required')
})

export default function CreateAffiliationForm({companyId, teamMember={}, onClose, onSuccess}) {
  const [loading, setLoading] = useState(false);
  const updateExisting = teamMember.hasOwnProperty('id')

  return(
    <PopupModal onRequestClose={onClose}>
      <div className="affiliation-inner">
          <div className="modal-header border-bottom-0 pr-5">
            <h5 className="modal-title title montserrat weight-700">{`${updateExisting ? 'Update' : 'Create'}`} Team Member</h5>
          </div>
          <Formik
            initialValues={(({ name, role, bio }) => ({ name, role, bio }))(teamMember)}
            validationSchema={TeamMemberSchema}
            onSubmit={async (values, { props, setSubmitting }) => {
              setLoading(true);
              if(updateExisting){
                teamMemberApi.update(companyId, teamMember.id, values).then(
                  res => {
                    onSuccess(res, updateExisting)
                  },
                  error => {
                    ToastMessage.error(error.message);
                    console.log(error);
                  }
                ).finally(() => {
                  setLoading(false);
                })
              }
              else {
                teamMemberApi.create(companyId, values).then(
                  res => {
                    onSuccess(res, updateExisting)
                  },
                  error => {
                    ToastMessage.error(error.message);
                    console.log(error);
                  }
                ).finally(() => {
                  setLoading(false);
                })
              }
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <div className="form-inner-row px-3">
                  <div className="form-label-group">
                    <Field type="text"
                      id="name"
                      className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                      name="name"
                      placeholder="Name"
                    />
                    <label htmlFor="name">Name</label>
                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left"/>
                  </div>
                  <div className="form-label-group">
                    <Field type="text"
                      id="role"
                      className={`form-control ${touched.role && errors.role ? "is-invalid" : ""}`}
                      name="role"
                      placeholder="Role"
                    />
                    <label htmlFor="role">Role</label>
                    <ErrorMessage name="role" component="div" className="invalid-feedback text-left"/>
                  </div>
                  <div className="form-label-group">
                    <Field type="text"
                      id="bio"
                      className={`form-control ${touched.bio && errors.bio ? "is-invalid" : ""}`}
                      name="bio"
                      placeholder="Bio"
                      component="textarea"
                    />
                    <label htmlFor="bio">Bio</label>
                    <ErrorMessage name="bio" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
                <div className="affiliation-form-footer">
                  <button type="submit" className="poppins weight-300 btn-black" disabled={isSubmitting}>{`${updateExisting ? 'Update' : 'Create'}`}</button>
                </div>
              </Form>
            )}
          </Formik>
      </div>
    </PopupModal>
  );
}
