import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';
import { offerApi } from './../api/offer'
import { runGAEvent } from './components/Tracking';
import { fbEvent } from './components/Tracking/facebook';
import { usePreofferList } from 'investor/queries';
import { Link } from 'react-router-dom';
import OfferCard from './components/OfferCard';
import PreOfferCard from './components/PreOfferCard';
import OfferListLoader from '../admin/components/shared/OfferListLoader';
import { OfferCardPreviewRow } from './components/OfferCardPreview';

export default function OfferListMarketing(props) {
  const [offers, setOffers] = useState(null)
  const [loading, setLoading] = useState(false)
  const {data: preoffers, isLoading: isLoadingPreoffers} = usePreofferList();

  useEffect(() => {
    setLoading(true)
    offerApi.live_or_closed().then(
      offers => {
        setOffers(offers)
        setLoading(false)
      },
      error => {
        setLoading(false)
      }
    )
  }, [])

  if(loading|| isLoadingPreoffers){
    return(
      <OfferListLoader /> 
    )
  }
  const liveItems = [];
  const closedItems = []
  let closedOffers = []
  let liveOffers = [];
  let topThreeLiveOffers = [];

  if(offers?.live){
    liveOffers = offers.live.sort((o1, o2) => o1.live_event ? -1 : 1);

    liveOffers.forEach(offer => {
      if (topThreeLiveOffers.length <= 2) {
        topThreeLiveOffers.push(offer)
      }
    });

    topThreeLiveOffers.forEach(offer => {
      liveItems.push(<OfferCard key={`offer-${offer.id}`} offer={offer} onClick={() => {
        runGAEvent(`${offer?.company?.name} home page offer card`, "clicked", "card");
        fbEvent(`${offer?.company?.name} home page offer card clicked`);
      }}/>)
    });
  }

  
  if (offers?.closed) {
    closedOffers = offers.closed.sort((a, b ) => b.deal.raised -  a.deal.raised).slice(0, 3)
    closedOffers.forEach(offer => {
      closedItems.push(<OfferCard key={`offer-${offer.id}`} offer={offer} onClick={() => {
        runGAEvent(`${offer?.company?.name} home page offer card`, "clicked", "card");
        fbEvent(`${offer?.company?.name} home page offer card clicked`);
      }}/>)
    })
  }
  
  if(preoffers.live.length > 0) {
    preoffers.live.forEach(preoffer => {
      if(liveItems.length < 3)
        liveItems.push(<PreOfferCard key={`preoffer-${preoffer.id}`} preoffer={preoffer} />)
    })
  }

  if (liveItems.length < 3 && liveItems.length >= 1) {
    liveItems.push(<OfferCardPreviewRow numberOfCardsToShow={3 - liveItems.length} />)
  }

  if (liveItems.length >= 1) {  
    return (<CardsRow title="Trending Companies" subheader=" Currently live on Spaced Ventures." offers={liveItems} link="/offers" />)
} else {
    return (<CardsRow title="Recent Raises" subheader="Companies that raised on Spaced Ventures" offers={closedItems} link="/offers" />)
}
}

function CardsRow ({title, subheader, offers, link}) {
 return (
  <div className="live-offer-list bg-cover-top-left flex-center offers-marketing-home">
  <div className="container">
    <div className="live-offers-promo m-b-20">
          <> 
            <h2 className="weight-900 fs-5 mb-0">{title}</h2>
            <span
              className="poppins weight-400"
              style={{ color: "#888" }}
            >
              {subheader}
            </span>
          </>
    </div>
    <div className="card-container">
      {offers}
    </div>
    <div className="d-flex justify-content-center align-items-center mt-5">
      <BlackButtonLink to={link}>
        <BlackButtonStyle>View All</BlackButtonStyle>
      </BlackButtonLink>
    </div>
  </div>
</div>)
}

const BlackButtonLink = styled(Link)`
  text-decoration: none;
  color: #111;

  :hover {
    text-decoration: none;
  }
`

const BlackButtonStyle = styled.button`
  background-color: #111;
  border: 1px solid #111;
  font-weight: 600;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  transition: all 0.3s ease 0s;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  cursor: pointer;
  border-radius: 2px;
  font-family: 'Poppins', sans-serif;
  @media (max-width: 700px) {
    margin-top: 25px;
    max-width: 100%;
    width: 100%;
  }
  :hover {
  background-color: #fff;
  border: 1px solid #111;
  color: #111;
  }

`;