// TODO: Implement loader and remove react-loading-overlay to remove depricated warnings

import React from 'react'
import LoadingOverlay from 'react-loading-overlay';

export default function PageLoadingSpinner({isLoading, message}){
  return (
    <>
       { isLoading ? <LoadingOverlay
          active={isLoading}
          spinner
          text={ message || 'Loading ...' }
        /> : null
      }
    </>
  )
}
