import React from "react";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { FaPlus } from "react-icons/fa";
import WireTransferForm from "./WireTransferForm";

const DefaultSubmitButtonText = "Add Wire Method";

const AddWireTransferButton = ({
  clientId,
  onSuccess,
  onError,
  submitButtonText = DefaultSubmitButtonText,
  subHeaderText = "",
  showSidebar = false,
  buttonElement = null,
  extras = null,
}) => {
  const AddWireTransferModal = React.useContext(PortalizedModalContext);

  const successCallback = React.useCallback(
    (newWireMethod) => {
      if (typeof onSuccess === "function") {
        onSuccess(newWireMethod);
      }
      AddWireTransferModal.closeModal();
    },
    [onSuccess, AddWireTransferModal]
  );

  const errorCallback = React.useCallback(
    (error) => {
      if (typeof onError === "function") {
        onError(error);
      }
    },
    [onError]
  );

  const addWireMethod = () => {
    AddWireTransferModal.setModalContent(
      `Add Wire Method`,
      <WireTransferForm
        clientId={clientId}
        onSuccess={successCallback}
        onError={errorCallback}
        submitButtonText={submitButtonText}
        subHeader={subHeaderText}
        showSidebar={showSidebar}
        extras={extras}
      />,
      "md"
    );
    AddWireTransferModal.openModal();
  };

  if (buttonElement) {
    return React.createElement(buttonElement, { onClick: addWireMethod });
  }

  return (
    <button
      type="button"
      className="btn-black px-3 py-2 poppins weight-700 fs-9 my-4"
      onClick={addWireMethod}
    >
      <FaPlus className="mb-1" />
      &nbsp;&nbsp;Wire Transfer
    </button>
  );
};

export default AddWireTransferButton;
