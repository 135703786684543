import React from 'react'
import { history } from 'redux/helpers'

export default function AccreditationVerificationSection({ client }) {
  if(!client){
    return null;
  }

  const onClickHandler = () => {
    history.push('/account/investor-info');
  }

  return (
    <div className="investment-detail-requirement mb-2">
      <h6 className="montserrat weight-700 fs-8 mb-3">Accreditation Verification</h6>
      <p>Please submit documents to verify your accreditation.</p>
      <span onClick={() => onClickHandler()} className="underline text-white fs-10 weight-600 cursor-pointer">Submit Documents</span>
    </div>
  )
}