import React from 'react';

export default function ActionBox({ modal, message, content, action }){
  return(
    <>
      <p>{message}</p>
      <p>{content}</p>
      <div className="portalized-modal-footer">
        <button className="btn-black" onClick={(action)}>Confirm</button>
        <button className="btn-black-outline" onClick={() => modal.closeModal()}>Cancel</button>
      </div>
    </>
  )
}