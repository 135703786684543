import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';
import { downloadFile } from 'jsUtils/fileUtils';

const useRaisePetitionList = ({page=1, per_page=10}) => {
  return useQuery(
    ['raise-petition', 'list', page],
    () => {
      return axiosClient
        .get(`/admin/raise_petitions/?&page=${page}&per_page=${per_page}`)
    }, {
      keepPreviousData: true,
    }
  );  
}

const useRaisePetition = (slug) => {
  return useQuery(
    ['raise-petition', slug], 
    () => {
      return axiosClient
        .get(`/admin/raise_petitions/${slug}`)
    }
  );  
}

const useRaisePetitionPledgeList = (raise_petition_slug, pageConfig = {page:1, per_page:25}) => {
  return useQuery(
    ['raise-petition', raise_petition_slug, 'pledge-list', pageConfig.page],
    () => {
      return axiosClient
        .get(`/admin/raise_petitions/${raise_petition_slug}/pledges.json`, {params: pageConfig})
    },
    {
      keepPreviousData: true,
    }
  );
}

const useExportRaisePetitionPledgeList = (raise_petition_slug, opts={enabled: false}) => {
  return useQuery(
    ['raise-petition', raise_petition_slug, 'exportpledgelist'],
    () => {
      return axiosClient
        .get(`/admin/raise_petitions/${raise_petition_slug}/pledges.csv`)
    },
    {
      ...opts,
      onSuccess: (data) => {
        downloadFile(data, `${raise_petition_slug}-pledges.csv`)
      }
    }
  );
}


const createRaisePetition = ({values}) => {
  return axiosClient.post(`/admin/raise_petitions`, values)
}

const updateRaisePetition = ({raise_petition_slug, values}) => {
  return axiosClient.put(`/admin/raise_petitions/${raise_petition_slug}`, values)
}

// event: one of [publish, pause, close]
const updateRaisePetitionStatus = ({raise_petition_slug, status}) => {
  return axiosClient.put(`/admin/raise_petitions/${raise_petition_slug}/${status}`)
}

const deleteRaisePetition = ({raise_petition_slug}) => {
  return axiosClient.delete(`/admin/raise_petitions/${raise_petition_slug}`)
}

const deleteRaisePetitionPledge = ({raise_petition_slug, pledge_id}) => {
  return axiosClient.delete(`/admin/raise_petitions/${raise_petition_slug}/pledges/${pledge_id}`)
}

export {
  useRaisePetitionList,
  useRaisePetition,
  useRaisePetitionPledgeList,
  useExportRaisePetitionPledgeList,
  createRaisePetition,
  updateRaisePetition,
  updateRaisePetitionStatus,
  deleteRaisePetition,
  deleteRaisePetitionPledge,
};