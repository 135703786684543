import React from 'react'
import { history } from 'redux/helpers'


export default function SubscriptionAgreementSection ({investment, investmentId}){
  const onClickHandler = (offerSlug, InvestmentId) => {
    history.push(`/offers/${offerSlug}/subscription-agreement/${InvestmentId}`)
  }

  return (
    <div className="investment-detail-requirement">
      <h6 className="montserrat weight-700 fs-8 mb-3">Sign subscription agreement</h6>
      <p>Please sign your subscription agreement to complete the investment.</p>
      <span onClick={() => onClickHandler(investment.offer_slug, investmentId)} className="underline text-white fs-10 weight-600 cursor-pointer">Sign subscription agreement</span>
    </div>
  )
}