import React from 'react'
import {acceptMaterialChange} from '../queries/index'
import { useMutation, useQueryClient } from "react-query";
import ToastMessage from '../../components/ToastMessage';

export default function MaterialChangeActionRequired({ material_change, investmentId }) {

  const queryClient = useQueryClient();
  const acceptMaterialChangeMutation = useMutation(acceptMaterialChange, {
    onSuccess: () => {
      queryClient.invalidateQueries("investment", investmentId)
      ToastMessage.success('Material change Accepted');
    },
    onError: (error) => {
      ToastMessage.error('There was a problem. Please contact support@spacedventures.com');
    }
  })

  const onClickHandler = () => {
    acceptMaterialChangeMutation.mutate(material_change.id)
  }

  return (
    <div className="investment-detail-requirement mb-2">
      <h6 className="montserrat weight-700 fs-8 mb-3">Accept Material Change</h6>
      <p><b>Description:</b> {material_change.description}</p>
      <span onClick={() => onClickHandler()} className="underline text-white fs-10 weight-600 cursor-pointer">Accept Material Change</span>
    </div>
  )
}