import React from 'react';
import InvestmentDetailTableRow from '../components/InvestmentDetailTableRow';
import AmountWithCurrency from '../../components/AmountWithCurrency';
import Tooltip from '../components/Tooltip/Tooltip';

export default function CompnyInfo({ preoffer}) {
  const company = preoffer.company;
  const isSecondary = preoffer.category === "secondary";

  const offerShortHand = {
    'RegD': 'Reg D',
    'RegCf': 'Reg CF',
  }  


  return (
    <div className="deal-terms-table">
      <div className="table-headers">
        <div className="header-primary">
          <h5 className="montserrat weight-700 mr-2">Company Info</h5>
        </div>
        <div className="header-secondary justify-content-between">
          <div className="mr-2">How it works</div>
          {isSecondary ? 
          <Tooltip xAlignment="-130px" text={`A secondary sale is when an existing shareholder of a private company sells their shares to a third-party buyer, and the proceeds go to the seller, not the company. This is different from a primary sale of equity, where the company issues new shares to investors and uses the money for its own purposes. In this case, we are the third-party buyer.`}>
          <div className="info-icon"></div>
          </Tooltip>          
          :
          <Tooltip xAlignment="-130px" text={`Be the first to know when ${company.name} launches. An indication of interest involves no obligation or commitment of any kind.`}>
            <div className="info-icon"></div>
          </Tooltip>          
          }

        </div>
      </div>
      <div  className="table-rows">
        <InvestmentDetailTableRow
          label="Offer Type"
          value={offerShortHand[preoffer.type] ?? "-"}
        />
        <InvestmentDetailTableRow
          label="Company Name"
          value={company.name}
        />
        <InvestmentDetailTableRow
          label="Sector"
          value={company.sector_name}
        />
       {preoffer.valuation ? <InvestmentDetailTableRow
          label="Valuation"
          value={preoffer.valuation ? <AmountWithCurrency number={preoffer.valuation} /> : '-'}
        /> : null }
      </div>
    </div>
  )
}
