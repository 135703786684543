import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useUser, updateUser } from 'redux/user'

const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required'),
  last_name: Yup.string()
    .required('Required')
});

export default function UpdateNameForm({onSubmit=null}) {
  const user = useUser();

  return (
      <Formik
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
        }}
        validationSchema={Schema}
        onSubmit={async (values, { props, setSubmitting }) => {
          const { ...attrs } = values
          updateUser(user.id, attrs, onSubmit)
        }}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form>
            <div className="form-label-group">
              <Field
                className={`form-control ${touched.first_name && errors.first_name ? "is-invalid" : ""}`}
                id="first_name"
                name="first_name"
                placeholder="first_name"
              />
              <label htmlFor="first_name">First Name</label>
              <ErrorMessage name="first_name" component="div" className="invalid-feedback text-left" />
            </div>
            <div className="form-label-group">
              <Field
                className={`form-control ${touched.last_name && errors.last_name ? "is-invalid" : ""}`}
                id="last_name"
                name="last_name"
                placeholder="last_name"
              />
              <label htmlFor="last_name">Last Name</label>
              <ErrorMessage name="last_name" component="div" className="invalid-feedback text-left" />
            </div>
            <div className='portalized-modal-footer'>
              <button type="submit" className="btn-black" disabled={isSubmitting}>
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
  );
}
