import React from 'react';
import { useClientSuitabilityQuestionaire } from '../../../../investor/queries';
import InvestmentCardRow from '../../../../admin/components/shared/InvestmentCardRow';
import { formatDate } from '../../../../jsUtils/dateTimeUtils';
import { history } from '../../../../redux/helpers';

export default function SuitabilityQuestionaireSection({ client }) {
  const { isLoading, isError, data } = useClientSuitabilityQuestionaire(client.id)

  if (isLoading || isError) {
    return null;
  }

  const openEditPage = () => {
    history.push(`/accounts/${client.id}/suitability-questionaire`);
  }

  return (
    <div className="suitability-questionaire-info mt-5">
      <h4 className="m-b-15 fs-18 weight-800">Suitability Questionnaire</h4>
      <div className="payment-card mb-3">
        <div className='payment-card-header d-flex justify-content-between align-items-center'>
          <div>Suitability Questionnaire for {client.name}</div>
          <button onClick={openEditPage} className="btn btn-sm color-white">
            <span className="zeitung-micro fs-10 weight-700">Edit</span>
          </button>
        </div>
        <InvestmentCardRow title='Created At' value={formatDate(data.signed_at)} />
        <InvestmentCardRow title='Updated At' value={formatDate(data.updated_at)} />
      </div>
    </div>
  )
}