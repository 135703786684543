import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams, Link } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './Page.OfferInvest.scss';
import "./Pages.common.scss";
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import { history } from 'redux/helpers'
import ToastMessage from '../../components/ToastMessage';
import * as Yup from 'yup';
import LimitCalculatorForm from '../components/LimitCalculatorForm/LimitCalculatorForm';
import FixedButtonBox from '../components/shared/FixedButtonBox';
import { useUser, fetchCurrentUser, _deleteCreditCard, _clearCreditCards } from 'redux/user';
import { userApi } from 'api/user';
import { fetchInvestmentLimit, useInvestmentLimit } from 'redux/investmentLimit';
import DealTermsSm from '../components/DealTerms/DealTermsSm';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import FormEffect from '../components/shared/FormEffect';
import LoadingOverlay from 'react-loading-overlay';
import FileBar from '../components/shared/FileBar';
import { calculatePricePerShare } from 'jsUtils/offerUtils'
import { DEAL_TYPE_EQUITY } from 'config/constants';
import CcAccount from '../components/PaymentAccountCards/CC';
import WireTransferCard from '../components/PaymentAccountCards/WireTransferCard';
import AchAccount from '../components/PaymentAccountCards/AchAccount';
import OfferTerms from '../components/OfferTerms';
import InvestmentSummary from '../components/InvestmentSummary';
import { rollbarInstance } from 'jsUtils/rollbar';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import FormikValidationsRollbarReporter from '../components/shared/FormikValidationsRollbarReporter';
import CurrencyInputField from '../../components/Formik/CurrencyInputField';
import EntityDetailsForm from '../components/EntityDetailsForm';
import { useClientPaymentMethodsList, useOffer, useOfferPitch } from '../../investor/queries';
import AddACHButton from '../components/AchPaymentForm/AddAchAccountButton';
import AddCcAccountButton from '../components/CreditCardPaymentForm/AddCcAccountButton';
import useGeoInfo from '../../lib/useGeoInfo';
import AddWireTransferButton from '../components/WireTransferForm/AddWireTransferButton';
import { OFFER_TYPE } from '../../config/constants';
import { investmentLimitByInvestorType } from '../../jsUtils/offerUtils';
import { makeInvestment } from '../../investor/queries';
import { useMutation } from 'react-query';
import { renderRailsErrors } from '../../jsUtils/railsErrorRenderer';

const MAX_CC_INVESTMENT = 5000;

const UpdateLimitLink = ({ updateLimitModal }) => {
  return (
    <span className="text-primary cursor-pointer zeitung-micro update-limit-text" onClick={() => updateLimitModal()}>Update my limit &#62;</span>
  )
}

export default function OfferInvestPage(props) {
  const { Country } = useGeoInfo();
  let { offerId, defaultInvestmentAccountId } = useParams();
  const [showUpdateLimitLink, setUpdateLinkVisibility] = useState(false);
  const [refetchUser, setRefetchUser] = useState(false);
  const [selectedAchAccountId, setAchAccountId] = useState(null);
  const [selectedCreditCardAccountId, setCreditCardAccountId] = useState(null);
  const [selectedWireAccountid, setWireAccountId] = useState(null);
  const [showClientFormModal, setShowClientFormModal] = useState(false);
  const [entityInvestors, setEntityInvestors] = useState([]);
  const [investmentAccountId, setInvestmentAccountId] = useState(defaultInvestmentAccountId);
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [investmentTerm, setInvestmentTerm] = useState(false);
  const fieldValidationButtonRef = useRef(null)
  const user = useUser();
  const currentClient = user.clients.find(client => client.id == investmentAccountId)
  const currentClientCountry = Country.findByISO2Code(currentClient?.country);
  const investmentLimit = useInvestmentLimit().investment_limit_info;
  const isAccredited = user?.investor_type === 'accredited';
  const newEntityModal = useContext(PortalizedModalContext);
  const updateLimitModal = useContext(PortalizedModalContext);
  const { data: offer = {}, isLoading:isLoadingOffer } = useOffer(offerId)

  const {
    data: paymentMethods,
    loading: loadingPaymentMethods,
    error: paymntMethodError,
    refetch: refetchPaymentMethods
  } = useClientPaymentMethodsList({ client_id: investmentAccountId });

  const makeInvestmentMutation = useMutation(makeInvestment, {
    onSuccess: (investment) => {
      ToastMessage.success("Investment Amount Confirmed");
      const client = getInvestingAccount();
      navigateToNextStep(client)(investment.id)
    },
    onError: (error) => {
      renderRailsErrors(error);
    }
  })

  const achAccounts = paymentMethods?.filter(pm => pm.type === "ach") || [];
  const creditCards = offer.type === OFFER_TYPE.reg_d506b ? [] : paymentMethods?.filter(pm => pm.type === "credit_card") || [];
  const wireTransferMethod = paymentMethods?.find(pm => pm.type === "wire");

  function onSubmit(entityInvestorId) {
    setInvestmentAccountId(entityInvestorId);
    setWireAccountId(null);
    setCreditCardAccountId(null)
    newEntityModal.closeModal();
  }

  const onLimitUpdateSubmit = () => {
    setRefetchUser(true);
    updateLimitModal.closeModal();
  }

  useEffect(() => {
    if (showClientFormModal) {
      const entityText = showClientFormModal === 'new-trust-account' ? 'Trust' : 'LLC';
      const entityType = showClientFormModal === 'new-trust-account' ? 'trust' : 'company';

      newEntityModal.setModalContent(
        `Investing as ${entityText}`,
        <EntityDetailsForm
          submitButtonText={`Add ${entityText}`}
          entityDetail={ { entity_type: entityType }}
          allowInternationalInvestors={offer.international_investors}
          onSubmit={onSubmit} />,
        'md'
      );
      newEntityModal.openModal()
    }
    setShowClientFormModal(false)
  }, [showClientFormModal]);

  const openUpdateLimitModal = () => {
    updateLimitModal.setModalContent(
      `Calculate your Investor Limit`,
      <LimitCalculatorForm
        onSubmit={onLimitUpdateSubmit}
      />,
      'lg'
    );
    updateLimitModal.openModal();
  }

  useEffect(() => {
    rollbarInstance.info("Mounted Page.OfferInvest.js", { offerId })
  }, [])

  useEffect(() => {
    getInvestmentLimits();
    if (user?.id) {
      userApi.getEntityInvestors(user.id).then(
        response => {
          setEntityInvestors(response)
        },
        error => {
          console.log(error)
          rollbarInstance.error("Error fetching entities", { error })
        }
      )
    }
  }, [user])

  useEffect(() => {
    if (refetchUser) {
      fieldValidationButtonRef.current.click();
      fetchCurrentUser()
      setRefetchUser(false)
    }
  }, [refetchUser])

  const navigateToNextStep = (client) => {
    return (investmentId) => {
      let nextUrl;

      if (offer.type === OFFER_TYPE.reg_d506b && client.suitability_questionaire_expired) {
        nextUrl = `/offers/${offerId}/accounts/${client.id}/suitability-questionaire/${investmentId}`;
        rollbarInstance.info("Navigating Suitability Questionaire", { investmentId })
      } else {
        nextUrl = `/offers/${offerId}/subscription-agreement/${investmentId}`;
        rollbarInstance.info("Navigating Subscription Agreement Signing", { investmentId })
      }

      history.push({ pathname: nextUrl })
    }
  }

  function getInvestmentLimits() {
    const attrs = {
      annual_income: user?.investment_limit?.annual_income || 0,
      net_worth: user?.investment_limit?.net_worth || 0,
      outside_investment_amount: user?.investment_limit?.outside_investment_amount || 0
    }
    fetchInvestmentLimit(attrs);
  }

  function getInvestingAs() {
    if (investmentAccountId === defaultInvestmentAccountId) return "Myself / Individual";
    else return entityInvestors.find(investor => (investor.id == investmentAccountId))?.name
  }

  function getPaymentMethod() {
    if (selectedAchAccountId)
      return 'ach';
    else if (selectedCreditCardAccountId)
      return 'credit_card';
    else if (selectedWireAccountid)
      return 'wire'
    return null;
  }

  function getInvestingAccount() {
    return user.clients.find(client => client.id == investmentAccountId)
  }

  function investingAsChangeHandler(event, handleChange) {
    if (['new-llc-account', 'new-trust-account'].indexOf(event.target.value) > -1 ) {
      setShowClientFormModal(event.target.value);
      return;
    }
    handleChange(event);
    setInvestmentAccountId(event.target.value);
    setCreditCardAccountId(null);
    setAchAccountId(null);
  }

  const remainingInvestment = offer?.type === OFFER_TYPE.reg_d506b ? Infinity : investmentLimit?.remaining_investment;
  const minLimit = offer.deal?.minimum_investment;
  const minimumInternationalLimit = offer?.deal?.minimum_international_investment ?? offer.deal?.minimum_investment;
  const maxInvLimit = offer.deal?.maximum_investment;
  const formCUrl = offer?.formc_url;
  const securityDocument = offer.security_document;
  const offerDocuments = offer.documents;
  const otherDocuments = offer.other_documents;
  const raisedAmount = offer.deal?.raised;
  const maxGoal = offer.deal?.maximum_goal;
  const remainingGoal = maxGoal - raisedAmount;
  const adminFee = offer.admin_fee;
  const loading = user.loading || isLoadingOffer;
  const currentInvestorCountry = Country.findByISO2Code(currentClient?.country ?? "US")
  const disableInvestmentButton = currentInvestorCountry.iso2 !== 'US' && !offer.international_investors
  const offerType = offer?.type;
  const limits = investmentLimitByInvestorType(offer);

  if(offer?.type === OFFER_TYPE.reg_d506b && user && !user.broker_dealer_terms_accepted ){
    history.push(`/broker-dealer-terms/offers/${offerId}`);
    return null;
  }

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading ...'
      >

        <div className="investment-header">
          <div className="container pb-3 pt-first-section">
            <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">
              Invest in <Link to={`/offers/${offer.slug}`} target="_blank" rel="noopener noreferrer">{offer.company?.name}</Link>
            </h1>
          </div>
        </div>
        <div className="investor-formbox investment-form-screen">
          <Formik
            enableReinitialize
            initialValues={{
              investment_amount: investmentAmount,
              terms: investmentTerm,
              investing_as: investmentAccountId
            }}
            validationSchema={Yup.object().shape({
              investment_amount: Yup.string()
                .required("Investment amount is required")
                .test("", "", function (value) {
                  const amount = parseFloat(value)

                  if (amount > remainingInvestment && remainingInvestment < maxInvLimit && !isAccredited) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: `Can't invest more than the remaining investment limit of ${formatMoney(remainingInvestment)}` }))
                  } else if (isNaN(value)) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: 'Investment amount must be a number' }))
                  } else if (!(/^[1-9]\d*(\.\d{1,2})?$/g.test(value))) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: "Investment amount must be whole number or up to 2 decimal places" }))
                  }
                  const usResident = currentClient?.country === 'US' ? true : false;
                  const getMinInv = () => {
                    if (usResident) return minLimit;
                    return minimumInternationalLimit;
                  }
                  if (getInvestingAs() !== 'Myself / Individual' && getInvestingAccount()?.country === 'US' && amount < offer.deal?.entity_minimum_investment) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: `Minimum investment for US entities is ${formatMoney(offer.deal?.entity_minimum_investment, { precision: 2 })}` }))
                  } else if (getInvestingAs() !== 'Myself / Individual' && getInvestingAccount()?.country !== 'US' && amount < offer?.deal?.entity_minimum_international_investment) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: `Minimum investment for international entities is ${formatMoney(offer.deal?.entity_minimum_international_investment, { precision: 2 })}` }))
                  } else if (amount < getMinInv()) {
                    setUpdateLinkVisibility(false)
                    if (currentClient.country === 'US')
                      return this.resolve(this.createError({ message: `Amount is less than the minimum required investment of ${formatMoney(getMinInv())}` }))
                    else
                      return this.resolve(this.createError({ message: `The minimum for investors from ${currentClientCountry.name} is ${formatMoney(getMinInv())}` }))
                  } else if (amount > remainingGoal && remainingGoal < maxInvLimit) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: `Can't invest more than the remaining limit of ${formatMoney(remainingGoal)} for the offer.` }))
                  } else if (amount > maxInvLimit) {
                    setUpdateLinkVisibility(false)
                    return this.resolve(this.createError({ message: `Can't invest more than the max investment limit of ${formatMoney(maxInvLimit)} for the offer.` }))
                  } else if (amount >= getMinInv() && amount <= remainingInvestment) {
                    setUpdateLinkVisibility(true)
                  } else {
                    setUpdateLinkVisibility(false)
                  }
                }),
              terms: Yup.boolean()
                .oneOf([true], "You must agree to the above terms to complete your investment."),
            })}

            onSubmit={async (values, { }) => {
              let attrs;
              let invAmount = values.investment_amount.toFixed(2)
              if (selectedWireAccountid) {
                attrs = { offer_id: offer.id, amount: invAmount, payment_method_id: selectedWireAccountid }
              } else if (selectedCreditCardAccountId) {
                const ccAmtWithFee = invAmount * (1 + (adminFee.cc / 100))

                if (ccAmtWithFee > MAX_CC_INVESTMENT) {
                  ToastMessage.error(`Can't invest more than ${formatMoney(MAX_CC_INVESTMENT)} using credit card payment method including the Processing Fee of ${adminFee.cc}%.\nYour current transaction amount amount is ${formatMoney(ccAmtWithFee)}`);
                  return
                }
                attrs = { offer_id: offer.id, amount: invAmount, payment_method_id: selectedCreditCardAccountId }
              } else if (selectedAchAccountId) {
                attrs = { offer_id: offer.id, amount: invAmount, payment_method_id: selectedAchAccountId }
              } else {
                ToastMessage.error("No payment method selected. Please add a new payment method or select an existing payment method.");
                return
              }
              makeInvestmentMutation.mutate({investmentAccountId, values: attrs})
            }}
          >
            {({ values, touched, errors, isSubmitting, handleChange, validateField, submitCount, isValid }) => (
              <Form>
                <FormEffect
                  submitCount={submitCount}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  errors={errors}
                />
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 py-5 pr-xl-5 investor-formbox-inner1">
                      <div className="pr-xl-5">
                      </div>
                      <div className="invest-form-fields pr-xl-5">
                        <h5 className="montserrat weight-700 m-b-15">Investment amount</h5>
                        <div className="mt-3 m-b-45">
                          <CurrencyInputField
                            onChange={setInvestmentAmount}
                            id="investment_amount"
                            name="investment_amount"
                            label="Investment amount"
                            placeholder="Investment Amount"
                            showError={false}
                          />

                          <button type="button" hidden={true} ref={fieldValidationButtonRef} onClick={() => validateField('investment_amount')}></button>

                          {errors.investment_amount &&
                            <div className="failed-error-message text-dark zeitung-micro investment-limit-feedback">
                              <span className="fem-icon color-danger mr-1"><FaExclamationCircle /></span>
                              <span className="fem-text mr-1">{offer?.deal?.type === DEAL_TYPE_EQUITY && <span className="weight-700">{calculatePricePerShare(offer, values?.investment_amount)} Shares -</span>} {errors.investment_amount}</span>
                              {(remainingInvestment !== Infinity && showUpdateLimitLink) && <UpdateLimitLink updateLimitModal={openUpdateLimitModal} />}
                            </div>
                          }

                          {!errors.investment_amount && values.investment_amount &&
                            <div className="failed-error-message text-dark zeitung-micro investment-limit-feedback">
                              <span className="fem-icon color-success mr-1"><FaCheckCircle /></span>
                              <span className="fem-text mr-1">{offer?.deal?.type === DEAL_TYPE_EQUITY && <span className="weight-700">{calculatePricePerShare(offer, values?.investment_amount)} Shares </span>} {offerType === OFFER_TYPE.reg_cf && <span> Amount is within your investment limit.</span>}</span>
                              {(remainingInvestment !== Infinity && showUpdateLimitLink) && <UpdateLimitLink updateLimitModal={openUpdateLimitModal} />}
                            </div>
                          }

                        </div>
                        <div className="m-b-45">
                          <h5 className="montserrat weight-700 m-b-15">Investing as</h5>
                          <div className="form-group">
                            <Field type="select"
                              id="investing_as"
                              className={`form-control custom-dropdown ${touched.investing_as && errors.investing_as ? "is-invalid" : ""}`}
                              name="investing_as"
                              placeholder="Investing As"
                              component="select"
                              onChange={(e) => investingAsChangeHandler(e, handleChange)}>
                              <option value={defaultInvestmentAccountId}>Myself / Individual</option>
                              {entityInvestors.map(entityInvestor => {
                                if (offer?.international_investors)
                                  return <option key={entityInvestor.id} value={entityInvestor.id}>{entityInvestor.name}</option>
                                else if (entityInvestor.country === 'US')
                                  return <option key={entityInvestor.id} value={entityInvestor.id}>{entityInvestor.name}</option>
                              })}
                              <option value='new-llc-account'>Add New LLC</option>
                              <option value='new-trust-account'>Add New Trust</option>
                            </Field>
                          </div>
                        </div>
                        <div className="m-b-45">
                          <h5 className="montserrat weight-700 m-b-15">Payment method</h5>
                          {achAccounts.length === 0 && creditCards.length === 0 && !wireTransferMethod &&
                            <div style={{ minHeight: '150px' }} className="p-4 d-flex flex-wrap align-items-center justify-content-center bg-lightgrey2">
                              <h6 className="poppins weight-700 fs-9">Please add a payment method</h6>
                            </div>
                          }
                          {achAccounts.length > 0 &&
                            achAccounts.map(account => {
                              return <AchAccount
                                key={account.id}
                                clientId={investmentAccountId}
                                account={account}
                                selected={account.id === selectedAchAccountId}
                                onDelete={(deletedAchId) => {
                                  if (selectedAchAccountId === deletedAchId)
                                    setAchAccountId(null)
                                }}
                                onSelect={(selectedAchId) => {
                                  setCreditCardAccountId(null);
                                  setWireAccountId(null)
                                  setAchAccountId(selectedAchId);

                                }}
                              />
                            })
                          }
                          {creditCards.length > 0 &&
                            creditCards.map(creditCard => {
                              return <CcAccount
                                key={creditCard.id}
                                clientId={investmentAccountId}
                                creditCard={creditCard}
                                selected={creditCard.id === selectedCreditCardAccountId}
                                onDelete={(deletedCcId) => {
                                  if (selectedCreditCardAccountId === deletedCcId)
                                    setCreditCardAccountId(null)
                                }}
                                onSelect={(selectedAchId) => {
                                  setAchAccountId(null);
                                  setCreditCardAccountId(selectedAchId);
                                  setWireAccountId(null)
                                }}
                              />
                            })
                          }
                          {wireTransferMethod &&
                            <WireTransferCard
                              key={wireTransferMethod.id}
                              clientId={investmentAccountId}
                              wire={wireTransferMethod}
                              selected={wireTransferMethod.id === selectedWireAccountid}
                              onDelete={(deletedWireId) => {
                                if (selectedWireAccountid === deletedWireId)
                                  setWireAccountId(null)
                              }}
                              onSelect={(selectedWireId) => {
                                setAchAccountId(null);
                                setCreditCardAccountId(null);
                                setWireAccountId(selectedWireId);
                              }}
                            />
                          }
                          <div className='d-flex flex-column flex-sm-row'>
                            {currentInvestorCountry.iso2 === 'US' && achAccounts.length === 0 &&
                              <AddACHButton
                                clientId={investmentAccountId}
                                onSuccess={(ach) => {
                                  setAchAccountId(ach.id)
                                  setCreditCardAccountId(null)
                                  setWireAccountId(null);

                                }}
                              />
                            }
                            {offer.allow_credit_card && creditCards.length == 0 &&
                              <AddCcAccountButton
                                clientId={investmentAccountId}
                                onSuccess={(ccAccount) => {
                                  setCreditCardAccountId(ccAccount.id)
                                  setAchAccountId(null);
                                  setWireAccountId(null);
                                }}
                              />
                            }
                            {!wireTransferMethod &&
                              <AddWireTransferButton
                                clientId={investmentAccountId}
                                onSuccess={(wireAccount) => {
                                  setWireAccountId(wireAccount.id)
                                  setAchAccountId(null);
                                  setCreditCardAccountId(null)
                                }}
                              />
                            }
                          </div>
                        </div>

                        <div className="m-b-45">
                          <h5 className="montserrat weight-700 m-b-15">Terms</h5>
                          <div className="bordered-items-box mb-4">
                            <OfferTerms offerType={offerType} openUpdateLimitModal={openUpdateLimitModal} />
                            <div className="bordered-item">
                              <label className={`custom-checkbox form-group d-flex flex-wrap align-items-center position-relative ${errors.terms ? 'is-invalid' : ''}`}>
                                <input id="terms" type="checkbox" name="terms" className="mr-2" onChange={(e) => { handleChange(e); setInvestmentTerm(e.target.checked); }} />
                                <span className="custom-checkbox-check"></span>
                                <span className="custom-checkbox-tagline">I agree to all the terms above.</span>
                              </label>
                              <ErrorMessage name="terms" component="div" className="invalid-feedback zeitung-micro text-left" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pr-xl-5 m-b-30">
                        <InvestmentSummary
                          investingAs={getInvestingAs}
                          invAmount={values.investment_amount}
                          getPaymentType={getPaymentMethod}
                          investmentAccountId={investmentAccountId}
                          adminFee={adminFee}
                          limits={limits}
                          offerType={offerType}
                        />
                      </div>
                      <FixedButtonBox
                        isSubmitting={isSubmitting}
                        buttonTitle={
                          disableInvestmentButton
                            ? `NOT AVAILABLE IN ${currentInvestorCountry.name.toUpperCase()}`
                            : "Invest"
                        }
                        disabled={disableInvestmentButton}
                      />
                    </div>
                    <div className="col-md-4 py-5 bg-lightgrey investor-formbox-inner2">
                      <DealTermsSm offer={offer} currentInvestorCountry={currentInvestorCountry} />
                      <div className="mt-5">
                        <h5 className="montserrat weight-700 mb-3">Documents</h5>
                        <ul className="uploaded-files">
                          {formCUrl && <FileBar name="Form C" url={formCUrl} />}
                          {securityDocument && <FileBar name={securityDocument?.name} url={securityDocument?.attachment_url} />}
                          {offerDocuments?.map((doc, index) => {
                            return <FileBar key={index} name={doc.name} url={doc.attachment_url} downloadable={doc.downloadable} />
                          })}
                          {otherDocuments?.map((doc, index) => {
                            return <FileBar key={index} name={doc.name} url={doc.attachment_url} downloadable={doc.downloadable}/>
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <FormikValidationsRollbarReporter form="Investment" />
              </Form>
            )}
          </Formik>
        </div>
      </LoadingOverlay>
    </>
  )
}
