import React from "react";
import { ErrorMessage, Field } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const YearPickerField = ({ ...props }) => {
  const [value, setValue] = React.useState(
    props?.initialValue ? new Date(props.initialValue, 0, 1) : null
  );

  return (
    <>
      <Field type="date" name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          return (
            <DatePicker
              maxDate={props.max ? new Date(props.max, 0, 1) : ""}
              minDate={props.min ? new Date(props.min, 0, 1) : ""}
              isClearable
              onSelect={(date) => {
                form.setFieldValue(props.name, date.getFullYear());
                setValue(date);
              }}
              onChange={(date) => {
                if (!date) {
                  form.setFieldValue(props.name, "");
                  setValue(null);
                }
              }}
              showYearPicker
              dateFormat="yyyy"
              selected={value}
              id={field.name}
              name={field.name}
              dropdownMode="select"
              shouldCloseOnSelect
              className={`form-control ${
                meta.touched && meta.error ? "is-invalid" : ""
              }`}
            />
          );
        }}
      </Field>
      <label htmlFor={props.name}>{props.placeholder ?? ""}</label>
      <ErrorMessage
        name={props.name}
        component="div"
        render={(msg) => (
          <div className="invalid-feedback text-left date-picker-error">
            {msg}
          </div>
        )}
      />
    </>
  );
};

export default YearPickerField;
