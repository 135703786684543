import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from "react-router";
import _ from 'lodash';
import { format, parseJSON } from 'date-fns';
import ButtonDark from 'admin/components/ButtonDark'
import EditorModal from 'admin/components/EditorModal'
import CompanyOverview from 'admin/components/CompanyOverview'
import CompanyEditor from 'admin/components/CompanyEditor'
import CompanyCreator from 'admin/components/CompanyCreator'
import ToastMessage from 'components/ToastMessage'
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { FaEye, FaPlus, FaTrash, FaPen } from 'react-icons/fa';
import {
  OverviewModal,
  ResponsiveTable,
  Paginator,
} from 'admin/components'
import TableActionsContainer from '../components/TableActionsContainer';
import TableActionItem from '../components/TableActionItem';
import LinkedData from "./../../components/LinkedData";
import { deleteCompany, useCompaniesList } from './company/queries';
import usePagination from 'hooks/usePagination';
import { history } from 'redux/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { renderRailsErrors } from '../../jsUtils/railsErrorRenderer';
import LoadingOverlay from 'react-loading-overlay';
import useSearchInputManager from '../../hooks/useSearchInputManager';
import SearchInput from '../../components/SearchInput';

export default function ListCompaniesPage(props) {
  let { path, url } = useRouteMatch();
  const queryClient = useQueryClient();
  const searchInputManager = useSearchInputManager()
  const pageConfig = usePagination();
  const [q, setQ] = useState('')

  const companiesList = useCompaniesList({q: searchInputManager.queryString, ...pageConfig.current})

  const deleteConfirmModal = React.useContext(PortalizedModalContext);
  const [editing, setEditing] = useState(false)

  const debouncedSearchQuery = React.useCallback(_.debounce((queryString) => {
    setQ(queryString)
  }, 500), [])

  const openShowModal = React.useCallback(
    (slug) => {
      history.push(`${url}/${slug}`)
    },
    []
  )

  const openEditorModal = React.useCallback(
    (slug) => {
      history.push(`${url}/${slug}/edit`)
    },
    []
  )

  const deleteCompanyMutation = useMutation(deleteCompany, {
    onSuccess: () => {
      ToastMessage.success("Company deleted.");
      queryClient.invalidateQueries(['admin', 'companies']);
    },
    onError: (error) => {
      renderRailsErrors(error)
    },
    onSettled: () => {
      deleteConfirmModal.closeModal();
    }
  })

  const handleDelete = (company_slug) => () => {
    deleteConfirmModal.setModalContent(
      "Delete Company?",
      <>
        <p>Are you sure you want to delete the company?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              // destroyCompany(company_id);
              deleteCompanyMutation.mutate({company_slug})
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => deleteConfirmModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteConfirmModal.openModal();
  }

  const tableData = React.useMemo(
    () => {
      if (companiesList.data?.data)
        return companiesList.data?.data;
      else
        return []
    },
    [companiesList]
  );

  const columns = React.useMemo(
    () => {
      if (companiesList.data?.data?.length){
        return [
          {
            Header: "Company name",
            accessor: 'name',
            Cell: ({row, value}) => (
              <LinkedData onClick={() => openShowModal(row.original.slug)}>
                <span className="d-flex align-items-start">
                  <img src={row.original.logo_url} alt='' className="company-table-logo mr-2" />
                  <div>{row.original.name}</div>
                </span>
              </LinkedData>
            ),
          },
          {
            Header: "Location",
            accessor: 'country',
          },
          {
            Header: 'Created',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: "",
            accessor: "id",
            Cell: ({ row, value }) => (
              <TableActionsContainer>
                <TableActionItem
                  tooltipText="Edit"
                  // onClick={() => {openShowModal(row.original.slug); setEditing(true)}}
                  onClick={() => openEditorModal(row.original.slug)}
                >
                  <FaPen />
                </TableActionItem>
                <TableActionItem tooltipText="View" onClick={() => openShowModal(row.original.slug)}>
                  <FaEye />
                </TableActionItem>
                <TableActionItem tooltipText="Delete" onClick={handleDelete(row.original.slug)}>
                  <FaTrash className='fs-14 color-black'/>
                </TableActionItem>
              </TableActionsContainer>
            ),
          },
        ]
      }
      else{
        return []
      }
    },
    [companiesList]
  );

  if (companiesList.isLoading) {
    return (
      <LoadingOverlay
        active={companiesList.isLoading}
        spinner
        text='Loading ...'
      />
    )
  }

  return (
    <div className="bg-lightgrey">
      <div  className="admin-main-content-header">
        <div className="amch-inner1">
          <div className="input-group ig-field">
            <SearchInput {...searchInputManager} />
          </div>
        </div>
        <div className="amch-inner2">
          <ButtonDark
            iconName={<FaPlus />}
            title='Company'
            onClick={() => {
              history.push(`${url}/create`)
            }}
          />
        </div>
      </div>
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={companiesList.data?.pagy}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={companiesList.isLoading}
        loadingMsg="Loading companies..."
        noDataMsg="No companies found."
      />
      <Switch>
        <Route exact path={`${path}/create`}
          component={() => (
            <EditorModal>
              <CompanyCreator />
            </EditorModal>
          )}
        />
        <Route exact path={`${path}/:companySlug`}
          component={() => (
            <OverviewModal>
              <CompanyOverview onEdit={()=> setEditing(true)} />
            </OverviewModal>
          )}
        />
        <Route exact path={`${path}/:companySlug/edit`}
          component={() => (
            <EditorModal>
              <CompanyEditor />
            </EditorModal>
          )}
        />
      </Switch>
    </div>
  )
}
