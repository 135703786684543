import React, {useContext } from 'react';
import { useQuery } from 'react-query';
import { PortalizedModalContext } from "../../../contexts/portalizedModalContext";
import { chatMessageApi } from '../../../api/chatMessage';
import ResponsiveTable from '../../../admin/components/ResponsiveTable';
import { formatDate } from '../../../jsUtils/dateTimeUtils';

export default function BannedParticipants({ liveEvent, setDataChangedAt }) {
  const suspendParticipantModal = useContext(PortalizedModalContext);

  const participants = useQuery(
    [liveEvent.channel_id, 'bannedParticipants'],
    () => chatMessageApi.suspendedParticipants(liveEvent.chat_session_id)
  );

  const columns = [
    {
      Header: 'Name',
      accessor: 'user_name'
    },
    {
      Header: 'Time of Ban',
      accessor: 'suspended_at',
      Cell: ({value}) => value ? formatDate(value) : ''
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({value, row}) => (
        <button className="btn btn-sm btn-link p-0" onClick={ ()=> suspendParticipant(row.original) }>Unban</button>
      )
    }
  ];

  const tableData = React.useMemo(
    () => {
      if (participants.isSuccess)
        return participants.data
      else
        return []
    },
    [participants]
  );

  if(participants.error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const suspendParticipant = (chatParticipant) => {
    suspendParticipantModal.setModalContent(
      `Suspend Participant : ${chatParticipant.user_name}`,
      <>
        <p>{`Suspend Participant : ${chatParticipant.user_name}`}</p>
        <div className="portalized-modal-footer">
          <button className="btn-black" onClick={() => {
            chatMessageApi.suspendParticipant(chatParticipant.id).then(() => setDataChangedAt(new Date()));
            suspendParticipantModal.closeModal();
          }}>
            Confirm
          </button>
          <button className="btn-black-outline" onClick={() => suspendParticipantModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    suspendParticipantModal.openModal()
  }

  return(
    <ResponsiveTable
      columns={columns}
      data={tableData}
      loading={participants.isLoading}
      loadingMsg="Loading banned users..."
      noDataMsg="No banned users found."
    />
  );
}
