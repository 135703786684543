import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import ResetPasswordForm from './components/ResetPasswordForm';
import './Pages.Common.Style.scss';
import { useUser } from 'redux/user';
import LoadingOverlay from 'react-loading-overlay';

export default function ResetPasswordPage() {

  const { token } = useParams()
  return (
    <LoadingOverlay
      active={useUser().loading}
      spinner
      text='Loading ...'
    >

      <div id="resetPasswordPage" className="banner-img-container text-center flex-center pt-first-section">
        <ResetPasswordForm token={token} />
        <hr className="w-1-dashed w-100" />
        {/* <span className="zeitung-micro fs-7 w-100 question">Do not have an account? <Link to="/signup">Sign up</Link></span> */}
      </div>

    </LoadingOverlay>
  )
}
