import React, { useContext, useEffect, useState } from 'react'
import { deleteIconOutline } from '../../../../assets/images';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import InvestmentCardRow, { InvestmentCardActionRow } from '../../shared/InvestmentCardRow';
import { useQueryClient, useMutation } from 'react-query';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { deleteCcAccount } from '../../../../investor/queries';
import ToastMessage from "components/ToastMessage";

export default function CcAccount({
  clientId,
  creditCard,
  selected,
  onSelect = null,
  onDelete = null,
  children
}) {
  const queryClient = useQueryClient();
  const deleteModal = useContext(PortalizedModalContext);
  const deleteCcMutation = useMutation(deleteCcAccount, {
    onSuccess: () => {
      typeof onDelete === "function" && onDelete(creditCard.id);
      queryClient.refetchQueries(['client-payment-methods', clientId])
      ToastMessage.success("Credit card deleted.");
    },
    onError: (error) => {
      renderRailsErrors(error);
    }
  })

  const deleteCc =() =>{
    deleteModal.setModalContent(
      `Delete Credit Card`,
      <>
        <p className='instruction-text-for-mobile'>Do you want to delete your credit card?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteCcMutation.mutate({clientId})
              deleteModal.closeModal();
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={() => {
            deleteModal.closeModal();
          }}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteModal.openModal();
  }

  return (
    <div className="payment-card">
      <div className='payment-card-header'>Credit Card</div>
      <InvestmentCardRow title={'Full Name'} value={creditCard?.account_name} />
      <InvestmentCardRow title={'Last 4 Digits'} value={creditCard?.account_number_last_four} />
      <InvestmentCardRow title={'Credit Card Type'} value={creditCard?.details?.card_type} />
      <InvestmentCardActionRow>
        {children ??
          <div className="inner1 d-flex flex-wrap align-items-center">
          { selected !== undefined &&
            <label style={{ lineHeight: '16px' }} className="custom-radio-btn fs-10 mb-2 mr-3">Use this credit card
              <input
                type="radio"
                name="radio"
                checked={selected}
                onChange={() => typeof onSelect === "function" && onSelect(creditCard.id)}
              />
              <span className="radiobtn"></span>
            </label>
          }
          <div onClick={deleteCc} className="delete-ach d-flex align-items-center mb-2 cursor-pointer">
            <img src={deleteIconOutline} width="16" className="mr-1" alt="trash icon" />
            <span className="zeitung-micro fs-10 weight-300">delete</span>
          </div>
        </div>
        }
      </InvestmentCardActionRow>
    </div>
  );
}
