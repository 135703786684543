import React from 'react';
import { format, parseJSON } from 'date-fns';
import ErrorBoundary from 'components/ErrrorBoundary';
import {
  OverviewSubheader,
  ResponsiveTable,
  Paginator,
} from 'admin/components';
import usePagination from 'hooks/usePagination';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import { usePreofferNDADocumentsList, exportPreofferNDADocumentsList } from './queries';

export default function NDADocuments({preofferId}) {
  const { authData } = React.useContext(AuthContext);
  const canDownloadSubscribersCSV = authData.permittedActions.has(ACTIONS.READ_DOWNLOAD_PREOFFER_SUBSCRIBERS)
  const pageConfig = usePagination();
  const ndaDocuments = usePreofferNDADocumentsList(preofferId, pageConfig.current)

  const tableData = React.useMemo(
    () => {
      if (ndaDocuments.isSuccess)
        return ndaDocuments.data.data
      else
        return []
    },
    [ndaDocuments]
  );

  const columns = React.useMemo(
    () => {
      if (ndaDocuments.isSuccess){
        return [
          {
            Header: 'User name',
            accessor: 'user_name'
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Subscribed At',
            accessor: 'created_at',
            Cell: ({value}) => format(parseJSON(value), 'MMM dd yyyy')
          },
          {
            Header: 'Doc Status',
            accessor: 'esign_request',
            Cell: ({value}) => {
              return value ? value.status : "NA"}
          },
        ]
      }
      else{
        return []
      }
    },
    [ndaDocuments]
  );

  return (
    <ErrorBoundary>
      <OverviewSubheader
        left='NDA Documents'
        right={
          canDownloadSubscribersCSV ?
            <a href={exportPreofferNDADocumentsList(preofferId)} className="btn-black rounded-0 px-3 py-1 fs-9 text-decoration-none" download>Export All</a> : null
        }
      />
      <Paginator
        theme='dark'
        onPage={pageConfig.setPage}
        meta={ndaDocuments?.data?.pagy}
        loading={ndaDocuments.isLoading}
      />
      <ResponsiveTable
        columns={columns}
        data={tableData}
        loading={ndaDocuments.isLoading}
        loadingMsg="Loading subscribers..."
        noDataMsg="No subscribers found."
      />
    </ErrorBoundary>
  );
}