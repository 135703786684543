import React, {useState} from 'react'
import PropTypes from 'prop-types'

const Tooltip = ({ text, children, xAlignment = '40%', yAlignment = '92%', className }) => {
  const styles = {
    container: {
      position: 'relative',
      display: 'flex'
    },
    tooltip: {
      fontWeight:'300',
      boxSizing: 'border-box',
      position: 'absolute',
      width: '260px',
      top: yAlignment,
      left: xAlignment,
      zIndex: 999,
      marginLeft: '-80px',
      borderRadius: '2px',
      backgroundColor: '#f7f7f7',
      padding: '20px',
      marginBottom: '5px',
      color: '#111',
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'zeitung, sans-serif',
    }
  }
  const [hovering, setHovering] = useState(false);

  const mouseOver = () => {
    setHovering(true)
  }

  const mouseOut = () => {
    setHovering(false)
  }

  return (
    <div style={styles.container}>
      {hovering && <div className={className} style={styles.tooltip}>{text}</div>}
      <div onMouseOver={mouseOver} onMouseOut={mouseOut}>{children}</div>
    </div>
  )

}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired
}

export default Tooltip;