import { setStore, createStore } from "hooks-for-redux";
import { applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middlewares/logger'

const enabledStoreLogging = false;
  // process.env.REACT_APP_ENVIRONMENT === 'development' ||
  // process.env.REACT_APP_ENVIRONMENT === 'sandbox';

function configureStore(preloadedState) {
  const middlewares = []

  if(enabledStoreLogging) middlewares.push(loggerMiddleware)

  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer]

  if(enabledStoreLogging) enhancers.push(monitorReducersEnhancer)

  const composedEnhancers = composeWithDevTools(...enhancers)

  return setStore(createStore({}, composedEnhancers))
}

const store = configureStore()
export default store;