import React from 'react'
import Scrollspy from 'react-scrollspy';

export const CustomScrollSpy = ({
  pitchSidebarItems,
  bannerId = null,
  navId = null,
}) => {
  const scrollspyContainerRef = React.useRef(null);
  const [nonStickySidebar, setNonStickySidebar] = React.useState(false);
  const navSpyBoxRef = React.useRef(null);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const bannerHeight = document.getElementById(bannerId)?.clientHeight ?? 0;
  const navbarHeight = document.getElementById(navId)?.clientHeight ?? 0;
  let rowStyle = {}
  if(bannerId && navId)
    rowStyle = {minHeight:`calc(100vh - ${bannerHeight}px - ${navbarHeight}px + 56px)`};

  React.useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => {window.removeEventListener('scroll', scrollEventListener);}
  }, []);

  const scrollEventListener = () => {
    const scrollspyContainerOffsetTop = scrollspyContainerRef?.current?.offsetTop;
    const navSpyListHeight = document?.querySelector('.sidebar')?.clientHeight;
    const footerOffsetTop = document?.querySelector('footer')?.offsetTop;
    const pageScroll = window.pageYOffset;

    if (pageScroll > (scrollspyContainerOffsetTop)) {
        setShowSidebar(true);
    } else {
        setShowSidebar(false);
    }

    if (pageScroll > (footerOffsetTop - navSpyListHeight - 56)) {
        setNonStickySidebar(true);
    }
    else {
        setNonStickySidebar(false);
    }
  }
  return(
    <div className="container scrollspy-container" ref={scrollspyContainerRef}>
      <div className="bg-white">
        <div className="row" style={rowStyle}>
          <div className={`scrollspy-sidebar ${nonStickySidebar ? 'non-sticky-sidebar' : ''}`}>
            <nav id="nav-spy" ref={navSpyBoxRef}>
              <Scrollspy offset={-56} items={ pitchSidebarItems.map(item => item.id) } className={`sidebar nav ${showSidebar ? 'show-sidebar' : ''}`} currentClassName="active disabled-item">
                {pitchSidebarItems.map(item => {
                  return item.visibility && <li className="sidebar-item nav-item" key={item.key}><a href={`#${item.id}`}>{item.name}</a></li>
                })}
              </Scrollspy>
            </nav>
          </div>
          <div className="scrollspy-content">
            <div className="pitch-content" data-spy="scroll" data-target="#nav-spy">
              {pitchSidebarItems.map(item => {
                return item.component
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}