import React from 'react';
import MainVideoForm from './MainVideo';
import Problem from './Problem';
import Solution from './Solution';
import Product from './Product';
import Traction from './Traction';
import Customers from './Customers';
import BusinessModel from './BusinessModel';
import Market from './Market';
import Competition from './Competition';
import Vision from './Vision';
import Team from './Team';
import UseOfFund from './UseOfFund';
import RiskAndDisclosure from './RiskAndDisclosure';
import Highlights from './Highlights';
import NotableInvestors from './NotableInvestors';
import MediaMentions from './MediaMentions';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import  './Pitch.scss';
import LoadingOverlay from 'react-loading-overlay';
import { useOfferPitch, updatePitch } from '../../pages/offer/queries';

export default function Pitch({offer}){
  const {data:pitch={}, isLoading} = useOfferPitch(offer.slug);

  const { authData } = React.useContext(AuthContext);

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_PITCH)) {
    return <div>
      You are not authorised to edit Pitch.
    </div>
  }

  if(!pitch) return null;

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading">
      <div className="pitch-editor">
        <div className="px-md-3 px-0 pitch-sidenav">
          <div className="nav flex-md-column nav-pills" id="v-pills-tab" role="tablist">
            <a href="#pitch-video" data-toggle="tab" role="tab"
              className={`nav-link active ${pitch.main_video_section?.id ? 'complete' : 'incomplete'}`}>
              Main Video
            </a>

            <a href="#pitch-highlights" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.highlights?.length > 0 ? 'complete' : 'incomplete'}`}>
              Highlights
            </a>

            <a href="#pitch-notable-inv" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.notable_investors?.length > 0 ? 'complete' : 'incomplete'}`}>
              Notable Inv.
            </a>

            <a href="#pitch-media" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.media_mentions?.length > 0 ? 'complete' : 'incomplete'}`}>
              Media
            </a>

            <a href="#pitch-problem" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.problem_section?.id ? 'complete' : 'incomplete'}`}>
              Problem
            </a>

            <a href="#pitch-solution" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.solution_section?.id ? 'complete' : 'incomplete'}`}>
              Solution
            </a>

            <a href="#pitch-product" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.product_section?.id ? 'complete' : 'incomplete'}`}>
              Product
            </a>

            <a href="#pitch-traction" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.traction_section?.id ? 'complete' : 'incomplete'}`}>
              Traction
            </a>

            <a href="#pitch-customers" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.customers_section?.id ? 'complete' : 'incomplete'}`}>
              Customers
            </a>

            <a href="#pitch-businessmodel" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.business_model_section?.id ? 'complete' : 'incomplete'}`}>
              Business Model
            </a>

            <a href="#pitch-market" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.market_section?.id ? 'complete' : 'incomplete'}`}>
              Market
            </a>

            <a href="#pitch-competition" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.competition_section?.id ? 'complete' : 'incomplete'}`}>
              Competition
            </a>

            <a href="#pitch-vision" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.vision_section?.id ? 'complete' : 'incomplete'}`}>
              Vision
            </a>

            <a href="#pitch-team" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.team_section?.id ? 'complete' : 'incomplete'}`}>
              Team
            </a>

            <a href="#pitch-use-of-funds" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.use_of_funds_section?.id ? 'complete' : 'incomplete'}`}>
              Use of Funds
            </a>

            <a href="#pitch-risks-and-disclosures" data-toggle="tab" role="tab"
              className={`nav-link ${pitch.risks_and_disclosures_section?.id ? 'complete' : 'incomplete'}`}>
              Risks & Disclosures
            </a>
          </div>
        </div>

        <div className="px-md-3 px-0 pitch-right-content">
          <div className="tab-content" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="pitch-video" role="tabpanel">
              <MainVideoForm pitch={pitch} updateMethod={updatePitch}/>
            </div>
            <div className="tab-pane fade" id="pitch-highlights" role="tabpanel">
              <Highlights offer_id={offer?.slug} pitch={pitch} />
            </div>
            <div className="tab-pane fade" id="pitch-notable-inv" role="tabpanel">
              <NotableInvestors offer_id={offer?.slug} pitch={pitch} />
            </div>
            <div className="tab-pane fade" id="pitch-media" role="tabpanel">
              <MediaMentions offer_id={offer?.slug} pitch={pitch} />
            </div>
            <div className="tab-pane fade" id="pitch-problem" role="tabpanel">
              <Problem pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-solution" role="tabpanel">
              <Solution pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-product" role="tabpanel">
              <Product pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-traction" role="tabpanel">
              <Traction pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-customers" role="tabpanel">
              <Customers pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-businessmodel" role="tabpanel">
              <BusinessModel pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-market" role="tabpanel">
              <Market pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-competition" role="tabpanel">
              <Competition pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-vision" role="tabpanel">
              <Vision pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-team" role="tabpanel">
              <Team pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-use-of-funds" role="tabpanel">
              <UseOfFund pitch={pitch} updateMethod={updatePitch} />
            </div>
            <div className="tab-pane fade" id="pitch-risks-and-disclosures" role="tabpanel">
              <RiskAndDisclosure pitch={pitch} updateMethod={updatePitch} />
            </div>
          </div>
        </div>
      </div>
      </LoadingOverlay>
  )
}