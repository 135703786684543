import React from 'react';
import { getIcon } from 'jsUtils/offerUtils';
import { highlightVideoIcon } from '../../../../assets/images'

const Highlight = ({highlight})=>{
  return(
    <div className="col-md-6">
      <div className="single-highlight">
        <div className="single-highlight-iconbox">
            <img className="single-highlight-icon" src={getIcon(highlight.highlight_type)} alt={'icon'}/>
        </div>
        <div className="single-highlight-header">
          <h5 className="single-highlight-title">{highlight.title}</h5>
        </div>
        <div className="single-highlight-body">
          <div className="description-box">
            <p>{highlight.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Highlights({pitch}) {
  return (
    <div id="highlights-section-component" className="component-box">
      <div className="pitch-section-width">
        <div className="label">
          <div className="label-inner">
            <div className="label-text">Highlights</div>
          </div>
        </div>
        <div className="pitch-section-body">
          <div className="row pt-3 highlights-row">
            {
              pitch?.highlights?.map(
                highlight => <Highlight key={highlight.id} highlight={highlight}/>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}