import React from 'react';
import { useParams } from 'react-router-dom';
import EditForm from '../components/CompanyInfo/EditForm';
import EditHeader from 'admin/components/EditHeader'
import { useCompany } from '../pages/company/queries';
import LoadingOverlay from 'react-loading-overlay';

export default function CompanyEditor({ onSubmitSuccess }) {
  let { companySlug } = useParams()
  const {data:company, isLoading } = useCompany(companySlug)

  if (isLoading) {
    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading ...'
      />
    )
  };

  return (
    <>
      <EditHeader left={`Edit ${company.name}`}/>
      <div className="settings-tab-box">
        <div className="settings-tab-box-nav">
          <ul className="nav nav-tabs" id="company-edit-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="company-info-tab" data-toggle="tab" href="#company-info-form" role="tab">Overview</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="company-edit-tab-content">
          <div className="tab-pane fade show active" id="company-info-form" role="tabpanel">
            <EditForm company={company} onSubmitSuccess={onSubmitSuccess}/>
          </div>
        </div>
      </div>
    </>
  )
}

