import React, { useEffect } from 'react'
import styled from 'styled-components';
import spacedXCover from '../../assets/images/graphics/space-x-guide-cover-4.png';

import futureOfSpace from '../../assets/images/cards/banner/Wave-Motion.jpg';
import payloads from '../../assets/images/cards/banner/payloads.png';
import pressureVessels from '../../assets/images/cards/banner/pressure-vessels.jpg';
import hyperKelpQA from '../../assets/images/cards/banner/Q_A_with_HyperKep.png';
import roundtable from '../../assets/images/cards/banner/roundtable.png';
import relativityCover from '../../assets/images/graphics/relativity-cover-2.png';
import { runGAEvent } from './Tracking';
import { SimpleComingSoonCard, SimpleOfferCardPreviewRow } from './OfferCardPreview';


const resourceData = [
  {
    header: "Relativity Independent Investment Guide",
    description: "A deep dive into Relativity’s technology, products, cash position and market dynamics based on publicly available information.",
    img: relativityCover,
    link: "https://spacedventure-marketing.s3.amazonaws.com/Relativity+Independent+Investment+Guide+12_4_23.pdf",
  },  
  {
    header: "SpaceX Independent Investment Guide",
    link: "https://spacedventure-marketing.s3.amazonaws.com/SpaceX-Independent-Investment-Guide-11-1.pdf",
    img: spacedXCover,
    description: "A thorough investor guide to SpaceX’s technology, products, services and finances, based on deep research and publicly available information.",
  },   
]

const blogData = [
  {
    header: "The future of edge computing in space",
    link: "https://blog.spacedventures.com/article/the-future-of-edge-computing-in-space",
    img: futureOfSpace,
    description: "The satellites we’re sending to orbit today have a major setback. We fixed it.",
  },      
  {
    header: "A Different Approach to Launching Payloads",
    link: "https://blog.spacedventures.com/article/different-approach-to-launching-payloads",
    img: payloads,
    description: "Wave Motion is building a new way to launch payloads into space without compromising speed and cost.",
  },  
  {
    header: "Pressure Vessels in the Present and Future Space Economy",
    link: "https://blog.spacedventures.com/article/pressure-vessels-in-the-present-and-future-space-economy",
    img: pressureVessels,
    description: "Pressure vessels are all around us and serve as critical components in today’s society. They have many applications underwater, on the ground, in the air, and beyond.",
  },  
  {
    header: "An Interview with Graeme Rae & Costas Soler - HyperKelp Inc.",
    link: "https://blog.spacedventures.com/article/graeme-rae-costas-soler-hyperkelp-interview",
    img: hyperKelpQA,
    description: "We interviewed CEO & CTO of HyperKelp Inc., Graeme Rae and Costas Soler.",
  },
  {
    header: "Roundtable: Life as a NewSpace Startup Founder - Co-hosted by Orbital Index",
    link: "https://blog.spacedventures.com/article/roundtable-life-as-a-newspace-startup-founder-co-hosted-by-orbital-index",
    img: roundtable,
    description: "An interactive roundtable discussion with the founders of three early-stage NewSpace startups.",
  },        
]

function Resource ({header,link,img, logoWidth, backgroundColor, description}) {
  return (
    <>
    <ResourceCardStyles logoWidth={logoWidth} backgroundColor={backgroundColor} banner={img}>
      <MediaImageContainerStyles banner={img} />
      <div className=" d-flex flex-column flex-fill justify-content-between">
        <div style={{"height": "170px"}} className="d-flex flex-column">
          <h3 className="poppins mt-4 fs-14 weight-700">{header}</h3>
          <div><p className=" mt-2 fs-14">{description}</p></div>
        </div>
          <a onClick={ () => runGAEvent(`${header} downloaded on resource page`, "button", "clicked")} target="_blank" rel="noopener noreferrer" href={link}><ButtonStyles>Get Research</ButtonStyles></a>
      </div>
      
    </ResourceCardStyles>
    </>
  
  ) }


export default function InvestorResources ({setResourcesExist}) {
  useEffect(() => {
    const resourcesCountExists = resourceData.length > 0;
    setResourcesExist(resourcesCountExists)
  })
  if (resourceData.length < 1) { return null }
  return (
    <div>
      <h2 className="fs-5 weight-700 poppins mb-4">Research</h2>
      <div className="card-container mb-5">
        { resourceData.map((item) => {
          return <Resource header={item.header} link={item.link} img={item.img} description={item.description} />
        })}

        {resourceData.length === 1 &&  <SimpleOfferCardPreviewRow numberOfCardsToShow={2}/>}
        {resourceData.length === 2 &&  <SimpleOfferCardPreviewRow numberOfCardsToShow={1}/>}
      </div>
    </div>
  )
}


const ButtonStyles = styled.button`
width: 100%;
border: 1px solid #000;
padding: 10px 40px 10px ;
background-color: transparent;
text-transform: capitalize;
font-family: 'Poppins';
font-weight: 600;
border-radius: 2px;
font-size: 16px;
color: #000;
transition: all 0.3s ease 0s;
margin-top: 13px;

@media (max-width: 600px) {
  width: 100%;
}
  
&:hover {
      color: #fff;
      background-color: #000;
      border: 1px solid #000;
      border-radius: 2px;
  }
`;
  
  
const ResourceCardStyles = styled.div`
  background-color: #fff;
  box-shadow: 3px 5px 20px #00000029;
  border-radius: 8px;
  padding: 20px 20px;
  /* height: 400px; */
  `    

const MediaImageContainerStyles = styled.div`
  background: linear-gradient(to top, #111, transparent 60%),
  url(${(props) => props.banner});
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  background-color: #111; 
`


function BlogCard ({header,link,img, logoWidth, backgroundColor, description}) {
  return (
    <>
    <ResourceCardStyles logoWidth={logoWidth} backgroundColor={backgroundColor} banner={img}>
      <MediaImageContainerStyles banner={img} />
      <div style={{"height": "250px"}} className="flex-fill justify-content-between d-flex flex-column">
        <div className="d-flex flex-column">
          <h3 className="poppins mt-4 fs-14 weight-700">{header}</h3>
          <div><p className=" mt-2 fs-14">{description}</p></div>
        </div>
          <a onClick={ () => runGAEvent(`${header} view from resource page`, "button", "clicked")} target="_blank" rel="noopener noreferrer" href={link}><ButtonStyles>View</ButtonStyles></a>
      </div>
      
    </ResourceCardStyles>
    </>
  
  ) }


  export function BlogResources () {
    if (blogData.length < 1) { return null }
    return (
      <div>
        <h2 className="fs-5 weight-700 poppins mb-4">Articles</h2>
        <div className="card-container mb-5">
          { blogData.map((item) => {
            return <BlogCard header={item.header} link={item.link} img={item.img} description={item.description} />
          })}
        </div>
      </div>
    )
  }  