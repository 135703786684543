import React, {useContext, useState} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { FaExternalLinkAlt, FaPen, FaTrash, FaRocketchat } from "react-icons/fa";
import { formatDate } from 'jsUtils/dateTimeUtils';
import { ResponsiveTable } from 'admin/components';
import OverviewSubheader from 'admin/components/OverviewSubheader';
import { liveEventApi } from 'api/liveEvent';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import TextButton from 'admin/components/TextButton';
import Form from './Form';
import EditorModal from 'admin/components/EditorModal';
import EditHeader from 'admin/components/EditHeader';
import StatusUpdateButton from './StatusUpdateButton';
import ChatModeration from './ChatModeration';
import { statusTitle } from '../../../components/LiveEvent/StatusUtil';
import TableActionsContainer from '../TableActionsContainer';
import TableActionItem from '../TableActionItem';

export default function List({ offerId }) {
  const [currentLiveEvent, setCurrentLiveEvent] = useState(null);
  const [moderatedLiveEvent, setModeratedLiveEvent] = useState(null);
  const deleteModal = useContext( PortalizedModalContext );
  const queryClient = useQueryClient();
  const { isLoading, error, data: liveEvents } = useQuery(['liveEvents', offerId], () => {
    return liveEventApi.list(offerId);
  });

  if(error){
    return <div className='text-center text-danger montserrat weight-500 fs-8 mt-4'>Something went wrong please try again after some time.</div>
  }

  const deleteHandler  = (id) => {
    deleteModal.setModalContent(
      'Delete Live Event',
      <>
        <p>Are you sure you want to delete the live event?</p>
        <div class="portalized-modal-footer">
          <button class="btn-black"
            onClick={() => {
              deleteLiveEvent(id);
              deleteModal.closeModal();
            }}>Confirm</button>
          <button class="btn-black-outline" onClick={() => deleteModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    deleteModal.openModal();
  }

  const deleteLiveEvent = (id) => {
    const liveEvent = liveEvents.find((e) => e.id === id);

    if(!liveEvent){
      return;
    }

    liveEventApi.destroy(liveEvent.id).then(() => {
      queryClient.invalidateQueries(['liveEvents', offerId]);
      ToastMessage.success(`${liveEvent.title} deleted.`);
    }).catch(function (error) {
      ToastMessage.error(error.message);
    })
  };

  const editLiveEvent = (id) => {
    const liveEvent = liveEvents.find((e) => e.id === id);
    if(liveEvent){
      setCurrentLiveEvent(liveEvent);
    }
  };

  const openChatModeration = (id) => {
    const liveEvent = liveEvents.find((e) => e.id === id);

    if(liveEvent){
      if(liveEvent.status === 'upcoming'){
        ToastMessage.success('Can not moderate upcoming event');
      } else {
        setModeratedLiveEvent(liveEvent);
      }
    }
  }

  const columns = [
    {
      Header: 'Title',
      accessor: 'title'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => statusTitle(value)
    },
     {
      Header: 'Scheduled Time',
      accessor: 'scheduled_time',
      Cell: ({value}) => value ? formatDate(value) : ''
    },
    {
      Header: 'Goal Amount',
      accessor: 'goal_amount'
    },
     {
      Header: 'Raised Amount',
      accessor: 'raised_amount'
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({value, row}) => (
        <TableActionsContainer>
          <StatusUpdateButton liveEvent={row.original} offerId={offerId}/>
          <TableActionItem tooltipText="Event Chat" onClick={ ()=> { openChatModeration(value) } }>
            <FaRocketchat className="fs-14 color-black" />
          </TableActionItem>
          <TableActionItem tooltipText="Event Video" onClick={() => window.open(row.original.youtube_url, "_blank", "noopener noreferrer")}>
            <FaExternalLinkAlt className='fs-14 color-black'/>
          </TableActionItem>
          <TableActionItem tooltipText="Edit Event" onClick={ ()=> { editLiveEvent(value) } }>
            <FaPen className='fs-14 color-black'/>
          </TableActionItem>
          <TableActionItem tooltipText="Delete Event" onClick={ ()=> { deleteHandler(value) } }>
            <FaTrash className='fs-14 color-black'/>
          </TableActionItem>
        </TableActionsContainer>
      )
    }
  ];

  return(
    <>
       { currentLiveEvent &&
          (<EditorModal onRequestClose={ () => setCurrentLiveEvent(null) }>
            <EditHeader left={ currentLiveEvent.id ? 'Update Live Event' : 'New Live Event'} />
            <div className='container'>
               <Form offerId={offerId} liveEvent={currentLiveEvent} setCurrentLiveEvent={setCurrentLiveEvent}/>
            </div>
          </EditorModal>)
      }
      { moderatedLiveEvent &&
        (<EditorModal onRequestClose={ () => setModeratedLiveEvent(null) }>
          <EditHeader left={ `Live Chat Moderation - ${moderatedLiveEvent.title}`} />
          <div className='d-block m-4 p-2'>
            <ChatModeration liveEvent={moderatedLiveEvent} getChatSession={liveEventApi.getChatSession}/>
          </div>
        </EditorModal>)
      }
      <OverviewSubheader
        left='Live Events'
        right={
          <TextButton title='New Live Event' onClick={ () => setCurrentLiveEvent({}) } />
        }
      />
      <div className="action-table">
        <ResponsiveTable
          columns={columns}
          data={liveEvents || []}
          loading={isLoading}
          loadingMsg="Loading live events..."
          noDataMsg="No live events available for this offer."
        />
      </div>
    </>
  );
}
