import React from "react";
import AmlSectionsContainer from "./AmlSectionsContainer";
import { DisapprovedBadge } from "./Badges";

function explainerText (clientCountry, clientType, areVerificationReviewedByAdmin) {

  const countryShortHand = {
    "GB": "Great Britain",
    "CA": "Canada",
    "US": "United Stated"
  }

  if (clientType === "person" && areVerificationReviewedByAdmin && clientCountry === "US") {
    return (
      <div className="aml-instructions-container">
        <p>Review the <DisapprovedBadge /> sections below and upload the requested document.</p>
        <div><b>Once a document is uploaded:</b></div>
        <ul>
          <li>It will appear in the Uploaded Documents section and our team will review the document.</li>
          <li>The document status will be updated to invalid if it does not resolve the issue and a rejection reason will be provided. Follow the instructions provided in the rejection reason to upload another document.</li>
        </ul>
        <div><b>Important:</b></div>
        <ul>
          <li>Review the information in your <a href="#account-settings-aml-link">investor details</a> and check that it's correct and matches the information shown in the document you upload.</li>
        </ul>
      </div>
    )
  }

  if (clientType === "person" && !areVerificationReviewedByAdmin && clientCountry === "US") {
    return (
      <div className="aml-instructions-container">
        <p>Highlighted below in yellow are the results of the automated identity verification check we conducted. </p>
        <p>Most issues can be resolved by uploading a piece of valid government ID.</p>
        {/* <p>If your unsure how to continue, don't worry, our team reviews every identification issue raised by the automated check and will provide more specific instructions on how to resolve your issue soon.</p>
        <p>Please check back soon for more details or upload a piece of government ID now to speed up the process.</p> */}
      </div>
    )
  }

  if (clientCountry !== "US" && clientType === "person") {
    return (
      <div className="aml-instructions-container">
        <p>Investors from {countryShortHand[clientCountry] ?? "outside the US"} are required to upload an image of a valid passport.</p>
      </div>
    )
  }

  if (clientType === 'company') {
    return (
      <div className="aml-instructions-container">
        <div>
        To verify your entity please upload the following documents: </div>
        <ol>
          <li>Certificate of Formation</li>
          <li>Articles of Organization</li>
          <li>Operating Agreement</li>
          <li>Members’ list/authorized signers’ list (if not included in the documents above)</li>
        </ol>
        <p>Once uploaded our team will review and mark the documents as valid or invalid.</p>
        <p>IMPORTANT: Please review the information in your <a href="#entity-settings-aml-link">entity profile</a> and check that it's correct and matches any documents you upload.</p>
      </div>
    )
  }

  if (clientType === 'trust') {
    return (
      <div className="aml-instructions-container">
        <div>
        To verify your trust please upload the following documents: </div>
        <ol>
          <li>Full trust document/agreement or a certification of trust</li>
          <li>List of all trustees, grantors (if still living), and protectors, including their full legal name, DOB, SSN, and residential address</li>
          <li>For irrevocable trusts, their EIN</li>
        </ol>
        <p>Once uploaded our team will review and mark the documents as valid or invalid.</p>
        <p>IMPORTANT: Please review the information in your <a href="#entity-settings-aml-link">trust profile</a> and check that it's correct and matches any documents you upload.</p>
      </div>
    )
  }
}

export default function Instructions({clientCountry, clientType, amlException }) {
  const {verification_fields} = amlException;
  const areVerificationReviewedByAdmin = verification_fields.every(field => field.status === "valid" || field.status === "invalid" );
  const instructions = explainerText(clientCountry, clientType, areVerificationReviewedByAdmin);
  const clientTypesText = {
    'person': 'Individual',
    'company': 'Entity details',
    'trust': 'Trust details'
  };

  return (
    <AmlSectionsContainer
      title={`Instructions to verify your ${clientTypesText[clientType]}`}
      classes="thick-bordered my-2"
      tooltipText={clientType === 'person' ? 'We review the results of your identity verification check and will help you resolve the issue.' : "We need to verify your entity. Please follow the below instructions to verify your entity." }
    >
      <div className="content-layer bg-muted-black">
       { instructions }
      </div>
    </AmlSectionsContainer>
  );
}
