import PropTypes from "prop-types";
import React from "react";

const HeaderTitle = ({title}) => {
  return(
    <h4 className="rounded pl-3 weight-900 mb-4 lh-md d-flex flex-wrap align-items-center bg-black text-white mt-5 p-2">
      {title}
    </h4>
  );
};

HeaderTitle.defaultProps = {};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderTitle;
