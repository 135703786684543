import React from "react";
import { useQuery } from "react-query";
import axiosClient from "config/axiosClient"
import usePagination from "hooks/usePagination";
import {
  ResponsiveTable,
  TableActionsContainer,
  TableActionItem,
  Paginator,
  OverviewSubheader,
} from "admin/components";
import { FaExternalLinkAlt } from "react-icons/fa";
import { formatDate } from "../../../../jsUtils/dateTimeUtils";
import { humanize, separateWordsAtCapitalLetters } from "../../../../jsUtils/stringUtils";
import LinkedData from "../../../../components/LinkedData";
import { history } from 'redux/helpers';

const linkTo = (invite) => {
  switch (invite.host_type) {
    case "PlatformLiveEvent":
      return `${window.location.origin}/resources/${invite.host_details.slug}`
    default:
      return window.location.origin
      break;
  }
}

export default function PrivateInvites() {
  const pageConfig = usePagination();
  const invites = useQuery(
    ['private-invites', pageConfig.current.page],
    () => {
      return axiosClient.get(`/invitations`, {params: {...pageConfig.current}});
    },
    { keepPreviousData : true }
  );

  const openEventDetails = React.useCallback((row) => {
    history.push(`/resources/${row.original.host_details.slug}`);
  }, []);

  const tableData = React.useMemo(
    () => {
      if (invites?.isSuccess)
        return invites.data.data
      else
        return []
    },
    [invites]
  );

  const columns = [
    {
      Header: 'Title',
      accessor: 'host_details.title',
      Cell: ({value, row}) => (
        <LinkedData onClick={() => openEventDetails(row)}>
          {value} <FaExternalLinkAlt className='fs-14 color-black'/>
        </LinkedData>
      )
    },    
    {
      Header: 'Invitation to',
      accessor: 'host_type',
      Cell: ({value}) => separateWordsAtCapitalLetters(value) || value
    },
    {
      Header: 'Status',
      accessor: 'host_details.status',
      Cell: ({value}) => humanize(value) || value
    }, 
    {
      Header: 'Scheduled time',
      accessor: 'host_details.scheduled_time',
      Cell: ({value}) => formatDate(value, 'MMM dd, yyyy, hh:mm a') 
    },
    // {
    //   Header: '',
    //   accessor: 'id',
    //   Cell: ({value, row}) => (
    //     // <TableActionsContainer>
    //       <TableActionItem
    //         tooltipText="Open linked page"
    //         onClick={() => window.open(linkTo(row.original), "_blank")}
    //         >
    //         <FaExternalLinkAlt className='fs-14 color-black'/>
    //       </TableActionItem>
    //     // </TableActionsContainer>
    //   )
    // }
  ];

  if (invites?.isLoading) {
    return (
      <div className="left-offset-container">
        Loading...
      </div>
    )
  }

  return (
    <div
      className="tab-pane show active"
      id="private-invites"
      role="tabpanel"
    >
      <div className="left-offset-container admin-main-content">
        <OverviewSubheader left='Invites' />
        <Paginator
          theme="dark"
          onPage={pageConfig.setPage}
          meta={invites?.data?.pagy}
          loading={invites?.isLoading}
        />
        <ResponsiveTable
          columns={columns}
          data={tableData}
          loading={invites?.isLoading}
          loadingMsg="Loading invites..."
          noDataMsg="No invites found."
        />
      </div>
    </div>
  );
}
