import React from "react";
import AccountInfo from "./AccountInfo";
import PaymentInfo from "./PaymentInfo";
import InvestmentLimit from "./InvestmentLimit";
import { AuthContext } from "./../../../../contexts/authContext";
import { CustomScrollSpy } from "../../../../admin/components/shared/CustomScrollSpy";

export default function AccountSettings() {
  const { isAdmin } = React.useContext(AuthContext);

  const settingsSideBarItems = [
    {
      id: "account-info",
      key: "account-info",
      name: "Account Info",
      visibility: true,
      component: <AccountInfo key="account-info" />,
    },
    // !isAdmin() && {
    //   id: "payment-info",
    //   key: "payment-info",
    //   name: "Payment Info",
    //   visibility: true,
    //   component: <PaymentInfo />,
    // },
    !isAdmin() && {
      id: "investment-limit",
      key: "investment-limit",
      name: "Investment Limit",
      visibility: true,
      component: <InvestmentLimit key="investment-limit"/>,
    },
  ];
  return (
    <>
      <div
        className="tab-pane show active"
        id="account-settings"
        role="tabpanel"
      >
        <div className="settings-detail-container">
          <section className="main-content">
            <CustomScrollSpy pitchSidebarItems={settingsSideBarItems} />
          </section>
        </div>
      </div>
    </>
  );
}
