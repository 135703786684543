import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchCompetitionIcon } from '../../../../assets/images';

function CompetitionSection({ 
  pitch:{competition_section}
}) {
  if(!competition_section) return null;

  return (
    <div id="competition-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'competition'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <Title text={competition_section.title} />
          </div>
            <ArticleContent content={competition_section.content}/>
        </div>
      </div>
    </div>
  )
}

const Title = ({text}) => {
  return (
    <>
      <div className='content-summary-icon'>
        <img src={pitchCompetitionIcon} alt="content summary icon" />
      </div>
      <div className='content-summary-text'>{text}</div>
    </>
    
  )
}

export default CompetitionSection;