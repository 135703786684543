import React from 'react'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import { progressBarPercentage, raisedFundsPercentage } from '../../../jsUtils/offerUtils';
import { OFFER_TYPE } from '../../../config/constants';

function RegCFProgressBar({offer}){
  const raisedAmount = offer?.deal?.raised ? parseInt(offer?.deal?.raised) : 0;
  const targetGoal = offer?.deal?.goal || 0;
  const maximumGoal = offer?.deal?.maximum_goal || 0;
  const investorsCount = offer?.investor_count || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, targetGoal);
  const fundsPercentageMax = raisedFundsPercentage(raisedAmount, maximumGoal);

  return (
     <div className="investment-detail-banner-content">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="amount-raised mb-2">
            <span className="amount"><b>{formatMoney(offer?.deal?.raised, {precision : 0})}</b></span>
            <span className="raised-text">&nbsp;Raised</span>
          </div>
          <div>
            <span className="status-badge">{offer?.status}</span>
           </div>
        </div>
        <div className="progressbar-summary">
          <div className="funded mb-2">
            <span className="funded-precentage">{ fundsPercentage }%</span>
            <span className="funded-text ml-2">of target: {formatMoney(targetGoal, {precision : 0})}</span>
          </div>
        </div>
        <div className="progress-box">
          <div className={`progressbar`}><div style={{width: `${progressBarPercentage(fundsPercentage)}%`}} className="progress"></div></div>
          <div className={`progressbar-mini`}><div style={{width: `${progressBarPercentage(fundsPercentageMax)}%`}} className="progress"></div></div>
        </div>
        <div className="progressbar-summary">
          <div className="funded">
            <span className="funded-precentage">{ fundsPercentageMax }%</span>
            <span className="funded-text ml-2">of maximum goal raised: {formatMoney(maximumGoal, {precision : 0})}</span>
          </div>
          <div className="investors">
            <span className="investors-count">{ investorsCount }</span>
            <span className="investors-text ml-2">Investors</span>
          </div>
        </div>
      </div>
  )
}

function RegDProgressBar({offer}){
  const raisedAmount = parseInt(offer.deal.raised) || 0;
  const goal = offer.deal.goal || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, goal);


  return (
     <div className="investment-detail-banner-content">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="amount-raised mb-2">
            <span className="amount"><b>{formatMoney(offer?.deal?.raised, {precision : 0})}</b></span>
            <span className="raised-text">&nbsp;Raised</span>
          </div>
          <div>
            <span className="status-badge">{offer?.status}</span>
           </div>
        </div>
        <div className="progressbar-summary">
          <div className="funded mb-2">
            <span className="funded-precentage">{ fundsPercentage }%</span>
            <span className="funded-text ml-2">of target: {formatMoney(goal, {precision : 0})}</span>
          </div>
        </div>
        <div className="progress-box">
          <div className={`progressbar`}><div style={{width: `${progressBarPercentage(fundsPercentage)}%`}} className="progress"></div></div>
        </div>
        <div className="progressbar-summary">
          <div className="investors">
            <span className="investors-count">{ offer?.investor_count || 0 }</span>
            <span className="investors-text ml-2">Investors</span>
          </div>
        </div>
      </div>
  )
}

export default function InvestmentBanner({ investment, offer }){
  const raisedAmount = offer?.deal?.raised ? parseInt(offer?.deal?.raised) : 0;
  const targetGoal = offer?.deal?.goal || 0;
  const maximumGoal = offer?.deal?.maximum_goal || 0;
  const investorsCount = offer?.investor_count || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, targetGoal);
  const fundsPercentageMax = raisedFundsPercentage(raisedAmount, maximumGoal);

  return(
    <div className="investment-detail-banner">
      <div className="investment-detail-banner-thumb mb-lg-0 mb-3">
        <div className="investment-detail-thumbnail-box">
          <img src={investment.company_logo_url} className="investment-detail-thumbnail" alt="company logo" />
        </div>
      </div>
      { offer?.type === OFFER_TYPE.reg_cf && <RegCFProgressBar offer={offer}/> }
      { offer?.type === OFFER_TYPE.reg_d506b && <RegDProgressBar offer={offer}/> }
    </div>
  )
}