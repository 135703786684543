import React, { useCallback, useState } from "react";
import _ from 'lodash';
import List from "./List";
import { useQueryClient } from "react-query";

export default function AccreditationRequestsListing(props) {
  const [filter, setFilter] = useState({ searchQuery: '' });
  const queryClient = useQueryClient();

  const setFilterValues = (type, value) => {
    if (type === 'search') {
      setFilter({ searchQuery: value, status: filter.status })
    } else if (type === 'status') {
      setFilter({ searchQuery: filter.searchQuery, status: value })
    }
  }

  useCallback(_.debounce(() => {
    queryClient.invalidateQueries("accreditation_requests");
  }, 1000), [filter])

  return (
    <div className="bg-lightgrey">
      <div className="admin-main-content-header">
        <div className="amch-inner1">
          <div className="input-group ig-field mr-1">
            <input
              type="search"
              placeholder="Search"
              value={filter?.searchQuery}
              onChange={(e) => { setFilterValues('search', e.target.value) }
              } />
          </div>
          <select value={filter.status} onChange={(e) => { setFilterValues('status', e.target.value) }} className="custom-dropdown2">
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="need_more_info">Need More Info</option>
            <option value="rejected">Rejected</option>
            <option value="verified">Verified</option>
          </select>
        </div>
      </div>
      <List searchQuery={filter?.searchQuery} searchStatus={filter?.status} />
    </div>
  );
}
