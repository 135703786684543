import React from 'react';
import PropTypes from 'prop-types';
import { useTabs } from 'components/ReactTabs'
import "./HorizontalTabs.scss";

function HorizontalTabs({
  tabs,
  tabIdAccessor,
  targetPanelIdAccessor,
  defaultTabId,
  tabRenderer = (tab) => tab.title,
  contentRenderer = (tab) => tab.content,
}) {
  const defaultTab = defaultTabId || tabs[0]?.[tabIdAccessor]
  const {
    activeTab,
    tabs:tablist,
    getContainerProps,
    getTabProps,
    getPanelProps,
  } = useTabs({tabs,
    tabIdAccessor, targetPanelIdAccessor, defaultTabId: defaultTab})
    const getCurrentTab = tabs.filter(tab=> tab.tabId === activeTab)
    const currentTabTitle = getCurrentTab[0].title;
  return (
    <>
    <div className="horizontal-tabs-dropdown-container">
      <div className="horizontal-tabs-dropdown dropdown black-dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">{currentTabTitle}</button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul className="nav flex-column">
            {tablist.map(tab =>
              <li {...getTabProps(tab[tabIdAccessor])}>
                <span>
                  { tabRenderer(tab) }
                </span>
              </li>
              )}
          </ul>
        </div>
      </div>
    </div>
    <div className="pills-wrapper">
      <div className='container'>
        <nav>
          <ul className="nav nav-tabs" id="nav-tab" {...getContainerProps}>
            {
              tablist.map(tab => (
                <li className="nav-item" {...getTabProps(tab[tabIdAccessor])}>
                  <span className={`nav-link ${activeTab === tab[tabIdAccessor] ? 'active': ''}`}>
                    { tabRenderer(tab) }
                  </span>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </div>
    <div className="tab-content">
      {
        tablist.map(tab => (
          <div className={`tab-pane ${ activeTab === tab[tabIdAccessor] ? 'show active' : 'fade'}`} {...getPanelProps(tab[tabIdAccessor])}>
            { contentRenderer(tab) }
          </div>    
        ))
      }
    </div>
  </>
  );
}

HorizontalTabs.defaultProps = {};

HorizontalTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string.isRequired,
  targetPanelIdAccessor: PropTypes.string,
  tabRenderer: PropTypes.func,
  contentRenderer: PropTypes.func,
};

export default HorizontalTabs;