export const toTitlecase = (string) => string?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })

export const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const internationalPhoneNumberMask = [
  /[0-9]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const getphoneNumberMask = (countryCode = 'US') => {
  return countryCode !== 'US' ? internationalPhoneNumberMask : phoneNumberMask
}

export const ssnNumberMaskUS = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const ssnNumberMaskGB = [
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/
]

export const entityTaxtIdMaskGB = [
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  "-",
  /[0-9a-zA-Z]/
]

export const ssnNumberMaskCA = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/
]

export const getSSNMasking = (country) =>{
  if(country === 'US'){
    return ssnNumberMaskUS;
  }

  if(country === 'CA'){
    return ssnNumberMaskCA;
  }

  if(country === 'GB'){
    return ssnNumberMaskGB;
  }
}

export const getEntityTaxIdMask = (country) =>{
  if(country === 'US'){
    return ssnNumberMaskUS;
  }

  if(country === 'CA'){
    return ssnNumberMaskCA;
  }

  if(country === 'GB'){
    return entityTaxtIdMaskGB;
  }
}
export const taxIdNumberMask = [
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const formatWebsiteUrl = (url) => {
  return url ? url.replace(/https?:\/\//, '').replace(/www./, '') : '-';
}
export const removeUrlProtocol = (url) => {
  return url && url.replace(/^https?:\/\//, '');
}
export const humanize = (str) => {
  return str
  .replace(/^[\s_]+|[\s_]+$/g, '')
  .replace(/[_\s]+/g, ' ')
  .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}
export const  formatSSN=(ssn, country)=> {
  let _ssn = "";
  const isCA = country === 'CA' ? true  : false;
  const isGB = country === 'GB' ? true : false;
    for (let i = 0; i < ssn.length; i++) {
      if(i < ssn.length - 3 )
        _ssn += 'X';
      else
        _ssn += ssn[i];
      if(isGB){
        if(i==1 || i==3 || i==5 || i==7)
          _ssn+='-';
      } else{
        if(i==2 || i== (isCA ? 5 : 4))
          _ssn+='-';
      }
    }
    return _ssn;
  }

export const  formatTaxID=(taxId)=> {
  let _taxId = "";
  const subTaxId = taxId.substr(0, taxId.length - 3);
    for (let i = 0; i < subTaxId.length; i++) {
      _taxId += 'X';
      if(i==1)
      _taxId+='-'
  }
  return _taxId + taxId.substr(subTaxId.length, taxId.length);
}

export const parseYoutubeVideoId = (videoUrl) => {
  // https://www.youtube.com/watch?v=hlkb623McOU
  // https://youtu.be/hlkb623McOU
  // https://youtu.be/Q23lj-4en38
  const YOUTUBE_URL_REGEX = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-z0-9_-]{11})/gi

  const validVideoUrl = (videoUrl)?.match(YOUTUBE_URL_REGEX);

  if (!validVideoUrl) return null;

  return validVideoUrl[0].match(/[a-zA-z0-9-]+$/)[0];
}

export const youtubeVideoThumbnail = (videoUrl) => {
  if(videoUrl.indexOf('streamyard.com') > -1){
    return "https://spacedventure-marketing.s3.amazonaws.com/social-sharing-placeholder.png";
  }

  const id = videoUrl ? parseYoutubeVideoId(videoUrl) : null;

  if(id){
    return `https://img.youtube.com/vi/${id}/0.jpg`
  }
}

export const getStatesLabel = (countryCode) =>{
  return countryCode === 'CA'
  ? 'Province'
  : countryCode === 'GB'
  ? 'Region'
  : 'State'
}
export const getStatesOption = (countryCode) =>{
  return countryCode === 'CA'
  ? 'Provinces'
  : countryCode === 'GB'
  ? 'Regions'
  : 'States'
}

export const getTaxIdLabel = (countryCode) =>{
  if(countryCode === 'US') {
    return "SSN";
  }

  if(countryCode === 'CA') {
    return "SIN";
  }

   if(countryCode === 'GB') {
    return "NIN";
  }

  return "Tax ID Number";
}

export const getZipCodelabel = (countryCode) =>{
  return countryCode === 'US'
    ? 'Zip code'
    : 'Postal code'
}

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const truncateString = (str, n) => {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}
const pluralRules = new Intl.PluralRules("en-US");

export function pluralize(count, singular, plural) {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case "one":
      return `${count} ${singular}`;
    case "other":
      return `${count} ${plural}`;
    default:
      throw new Error("Unknown: " + grammaticalNumber);
  }
}

export function convertNumberToShortHand(value) {

  var length = (Math.abs(parseInt(value, 10)) + '').length,
      index = Math.ceil((length - 3) / 3),
      suffix = ['K', 'M', 'B', 'T'];

  if (length < 4) return value;

  return (value / Math.pow(1000, index))
         .toFixed(1)
         .replace(/\.0$/, '') + suffix[index - 1];

}

export function separateWordsAtCapitalLetters(inputString) {
  return inputString.split(/(?=[A-Z])/).join(' ');
}