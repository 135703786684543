import React, { useEffect, useState } from 'react';
import { history } from 'redux/helpers'
import { useLocation } from "react-router-dom";
import { AuthContext } from './../../../contexts/authContext';
import { runGAEvent } from '../../../pages/components/Tracking';
import { fbEvent } from '../../../pages/components/Tracking/facebook';
import useGeoInfo from '../../../lib/useGeoInfo';
import { COUNTRIES_FOR_INTERNATION_OFFERS } from '../../../lib/useGeoInfo/constants';

function nextPageUrl(user, offer) {
  if (!user || !offer) {
    return '/offerings/all';
  }

  const individualInvestmentAccount = user.clients.find(c => c.entity_type === 'person');

  if (!individualInvestmentAccount) {
    return `/investor-details/offers/${offer.slug}`;
  }

  if (user.investment_limit) {
    return `/offers/${offer.slug}/invest/accounts/${individualInvestmentAccount.id}`;
  } else {
    return `/offers/${offer.slug}/accounts/${individualInvestmentAccount.id}/limit-calculator`;
  }
}

export default function InvestmentButton({ offer, user, cssClass = '', openNewWindow = false }) {
  const { Country } = useGeoInfo();
  const { isAdmin } = React.useContext(AuthContext);
  let userLoggedIn = user.hasOwnProperty("id");
  let liveOffer = offer.status === 'live';
  const [navbarInvBtnClass, setNavbarInvBtnClass] = useState('invisible-offer-nav-btn');
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => { window.removeEventListener('scroll', scrollEventListener); }
  }, []);

  const scrollEventListener = () => {
    const offerNavbarOffset = document.getElementById('companyBanner')?.clientHeight + document.getElementById('app-navbar')?.clientHeight;
    const pageScroll = window.pageYOffset;
    if (pageScroll >= offerNavbarOffset) {
      setNavbarInvBtnClass('')
    }
    else {
      setNavbarInvBtnClass('invisible-offer-nav-btn')
    }
  }

  if (!userLoggedIn && offer.status !== 'closed') {
    return (
      <button type="button" className={`investment-btn ${navbarInvBtnClass} ${cssClass}`}
        onClick={() => {
          history.push({ pathname: '/login', state: { afterLoginPath: location.pathname } })
        }}>
        SIGN UP TO INVEST
      </button>
    )
  }

  // user is admin or offer is not live
  if (isAdmin() || !liveOffer) {
    return null;
  }

  // max goal reached
  if (Number(offer.deal?.maximum_goal) <= Number(offer.deal?.raised)) {
    return <div className="max-goal-reached zeitung-mono f-white">Maximum goal reached!</div>
  }

  // When offer is not allowed in the investor country, we disable invest button
  if (userLoggedIn) {
    const currentClient = user.clients.find(client => client.entity_type === 'person');
    const currentInvestorCountry = Country.findByISO2Code(currentClient?.country ?? "US")
    //if (currentInvestorCountry.iso2 !== 'US' && !offer.international_investors) {
    const disableBtn = offer.international_investors ? COUNTRIES_FOR_INTERNATION_OFFERS.indexOf(currentInvestorCountry.iso2) === -1 : currentInvestorCountry.iso2 !== 'US';
    if (disableBtn) {
      return (
        <button disabled={true} type="button" className={`investment-btn ${navbarInvBtnClass} ${cssClass}`}>
          NOT AVAILABLE IN {currentInvestorCountry.name.toUpperCase()}
        </button>
      )
    }
  }

  const handleBtnOnClick = () => {
    runGAEvent(`${offer.company.name} invest button`, "button", "clicked");
    fbEvent(`${offer.company.name} invest button clicked`);

    const url = nextPageUrl(user, offer);

    if (openNewWindow) {
      window.open(url);
    } else {
      history.push(url);
    }
  };

  // default case user can make a investment
  return (
    <button type="button" className={`investment-btn ${navbarInvBtnClass} ${cssClass}`}
      onClick={handleBtnOnClick}>
      INVEST
    </button>
  )
}
