import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from "../../components/ToastMessage";
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import TextField from "components/HookForm/TextInput";
import SelectField from "components/HookForm/SelectInput";
import {
  createUserInvite
} from "../queries/index";

const MODERATOR_ROLE_NAMES = [
  "Admin", "Content Editor", "Marketer",
  "Customer Service", "Compliance", "Advisor", "Operations",
];

const AdminInviteSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  role_category: Yup.string().required(),
  role_name: Yup.string()
    .oneOf(MODERATOR_ROLE_NAMES)
    .required(),
});

function AdminInviteForm({onInviteSuccess}) {
  const queryClient = useQueryClient();

  const createUserInviteMutation = useMutation(
    createUserInvite,
    {
      onSuccess: (values) => {
        methods.reset()
        queryClient.invalidateQueries(
          ['spacedAdminInvitesList'],
          {
            exact: false,
          }
        )
        if (typeof onInviteSuccess === 'function')
          onInviteSuccess(values)
        ToastMessage.success('User invite sent.');
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  )

  const methods = useForm({
    resolver: yupResolver(AdminInviteSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      email: '',
      role_category: "moderator",
    },
  });

  const onSubmit = (values) => {
    createUserInviteMutation.mutate({
      values
    })
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <TextField
                name="email"
                placeholder="Email*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SelectField
                name="role_name"
                placeholder="Select Role*"
                options={MODERATOR_ROLE_NAMES}
              />
            </div>
          </div>
          <div className="portalized-modal-footer">
            <button type="submit"
              className="btn-black"
              disabled={methods.isSubmitting}
            >
              Send Invite
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default AdminInviteForm;
