import axiosClient from 'config/axiosClient';

export const discussionBoardApi = {
  loadDiscussions,
  postDiscussion,
  updateDiscussion,
  deleteDiscussion,
  postReply,
  auditLog,
};

function loadDiscussions(discussableType, discussableId) {
  return axiosClient.get(`/${discussableType}/${discussableId}/discussions`)
}

function postDiscussion(discussableType, discussableId, attrs) {
  return axiosClient.post(`/${discussableType}/${discussableId}/discussions`, attrs)
}

function updateDiscussion(discussableType, discussableId, discussionId, attrs) {
  return axiosClient.put(`/${discussableType}/${discussableId}/discussions/${discussionId}`, attrs)
}

function deleteDiscussion(discussableType, discussableId, commentId) {
  return axiosClient.delete(`/${discussableType}/${discussableId}/discussions/${commentId}`)
}

function postReply(discussableType, discussableId, commentId, attrs) {
  return axiosClient.post(`/${discussableType}/${discussableId}/discussions/${commentId}/reply`, attrs)
}

function auditLog(discussionId) {
  return axiosClient.get(`/admin/discussions/${discussionId}/audits`)
}