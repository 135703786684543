import React from 'react'
import { Switch, Route, useRouteMatch, Redirect, useLocation } from "react-router-dom"
import { NavLink, Link } from 'react-router-dom';

import About from './pages/About';
import Raise from './pages/Raise';
import SpacedbasePage from './pages/Page.Spacedbase'
import TermsOfServices from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

import AdminListPage from './admin/pages/Page.AdminList'
import CompaniesListPage from './admin/pages/Page.CompaniesList'
import InvestorsListPage from './admin/pages/Page.InvestorsList'
import CompanyCreatePage from './admin/pages/Page.CompanyCreate'
// import CompanyEditPage from './admin/pages/Page.CompanyEdit'
import OfferViewPage from './admin/pages/Page.OfferView'
import PreofferViewPage from './admin/pages/Page.PreofferView'
import RaisePetitionViewPage from './pages/Page.RaisePetitionView';
import OfferInvestPage from './admin/pages/Page.OfferInvest'
import InvestorDetailsPage from './admin/pages/Page.InvestorDetails'
import LimitCalculator from './admin/components/LimitCalculatorForm/LimitCalculatorForm';
import CompanyProfilePage from './admin/pages/Page.CompanyProfile'
import OfferListPage from './admin/pages/Page.OfferList'
import PreOffersListPage from './admin/pages/preoffer/List'
import RaisePetitionListPage from './admin/pages/raise_petition/List'
import SettingsPage from './pages/Page.Settings'
import PlatformLiveEventList from './admin/pages/Page.PlatformLiveEventList'
import PlatformEvents from './pages/Page.PlatformEvents'
import AmlListing from './admin/pages/Aml';
import AccreditationRequestsListing from './admin/pages/AccreditationRequest';
import { logoutUser } from 'redux/session'
import './AdminApp.scss';
import {
  companyIcon,
  settingsIcon,
  offerIcon,
  preOfferIcon,
  investorIcon,
  logoutIcon,
  adminIcon,
  aiVerificationIcon,
  liveEventsIcon,
  petitionIcon,
  amlExceptionIcon,
} from "../src/assets/images/index";
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { AuthContext } from './contexts/authContext';
import { Helmet } from "react-helmet";
import Tooltip from './pages/components/Tooltip/Tooltip';

function NoMatch() {
  let location = useLocation();
  const badRoute = location.pathname

  if (badRoute === "/") {
    return <Redirect to='/admin/companies' />
  }

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <h3>
        No matching route found.
      </h3>
      <NavLink className="btn-black rounded-0 px-3 py-2 fs-9" to="/admin/companies">Home</NavLink>
    </div>
  );
}

export default function AdminApp() {
  let { path } = useRouteMatch()
  const { authData, clearAuthContext } = React.useContext(AuthContext);

  if (!authData.isAdmin) {
    return <Redirect to='/login' />
  }

  const logoutThisUser = () => {
    logoutUser();
    clearAuthContext();
  }

  return (
    <div className='admin-home'>
      <Helmet>
        <title>Admin App</title>
        <meta
          name="description"
          content="Spaced Ventures Admin App" />
      </Helmet>
      <div className="admin-nav">
        <NavLink to="/admin/companies" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-13px" text="Companies" className="admin-nav-tooltip">
            <img src={companyIcon} alt="company icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/offers" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Offers" className="admin-nav-tooltip">
            <img src={offerIcon} alt="offer icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/prelaunch" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Pre-launch" className="admin-nav-tooltip">
            <img src={preOfferIcon} alt="preoffer icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/raise-petitions" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Petitions" className="admin-nav-tooltip">
            <img src={petitionIcon} alt="preoffer icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/investors" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Users" className="admin-nav-tooltip">
            <img src={investorIcon} alt="investor icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/platform-live-events" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="SV Events" className="admin-nav-tooltip">
            <img src={liveEventsIcon} alt="platform-live-event icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/aml-exceptions" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="ID Verification" className="admin-nav-tooltip">
            <img src={amlExceptionIcon} alt="aml-exceptions" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/accreditation_requests" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Accredited Verification" className="admin-nav-tooltip">
            <img src={aiVerificationIcon} alt="ai verification icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/admins" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Accounts" className="admin-nav-tooltip">
            <img src={adminIcon} alt="admin icon" />
          </Tooltip>
        </NavLink>
        <NavLink to="/admin/settings" activeClassName="active">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Settings" className="admin-nav-tooltip">
            <img src={settingsIcon} style={{ width: '30px' }} alt="offer icon" />
            </Tooltip>
        </NavLink>
        <Link to="#" onClick={logoutThisUser} className="nav-last-item">
          <Tooltip xAlignment="44px" yAlignment="-11px" text="Log out" className="admin-nav-tooltip">
            <img src={logoutIcon} alt="offer icon" />
          </Tooltip>
        </Link>
      </div>
      <div className='admin-main-content'>
        <Switch>
          <Route exact path="/about" component={About} />
          <Route exact path="/raise" component={Raise} />
          <Route exact path="/terms-of-service/" component={TermsOfServices} />
          <Route exact path="/privacy-policy/" component={PrivacyPolicy} />
          <Route path="/spacedbase" component={SpacedbasePage} />
          <Route exact path={`${path}admin/admins`} component={AdminListPage} />
          <Route path={`${path}admin/preview/raise-petitions/:raisePetitionId`} component={RaisePetitionViewPage} />
          <Route path={`${path}admin/preview/prelaunch/:preofferId`} component={PreofferViewPage} />
          <Route path={`${path}admin/preview/offers/:offerId`} component={OfferViewPage} />
          <Route path={`${path}admin/companies`} component={CompaniesListPage} />
          <Route path={`${path}admin/offers`} component={OfferListPage} />
          <Route exact path={`${path}admin/investors`} component={InvestorsListPage} />
          <Route exact path={`${path}admin/companies/:companyId/offers/:offerId/invest`} component={OfferInvestPage} />
          <Route exact path={`${path}admin/investor-details`} component={InvestorDetailsPage} />
          <Route exact path={`${path}admin/limit-calculator`} component={() => <LimitCalculator fullPageView={true} />} />
          <Route exact path={`${path}admin/companies/create/`} component={CompanyCreatePage} />
          {/* <Route path={`${path}admin/companies/:id/edit`} component={CompanyEditPage} /> */}
          <Route path={`${path}company/:id`} component={CompanyProfilePage} />
          <Route path={`${path}admin/companies/:id`} component={CompanyProfilePage} />
          <Route path={`${path}admin/prelaunch`} component={PreOffersListPage} />
          <Route path={`${path}admin/raise-petitions`} component={RaisePetitionListPage} />
          <Route path={`${path}admin/platform-live-events`} component={PlatformLiveEventList} />
          <Route path={`${path}admin/aml-exceptions`} component={AmlListing} />
          <Route path={`${path}admin/accreditation_requests`} component={AccreditationRequestsListing} />
          <Route path={`${path}admin`} component={SettingsPage} />
          <Route exact path="/resources/" component={PlatformEvents} />
          <Redirect to={`${path}admin/companies`} />
        </Switch>
      </div>
    </div>
  )
}
