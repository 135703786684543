import * as React from 'react';

export function useTabs({tabs, tabIdAccessor, targetPanelIdAccessor, defaultTabId}) {
  const findTab = (tabIdentifier) => {
    return tabs.find(
        (tab) => tab[tabIdAccessor] === tabIdentifier
      );
  }

  const [activeTab, setActiveTab] = React.useState(
    defaultTabId || tabs[0]?.[tabIdAccessor]
  );

  const getContainerProps = () => ({
    role: "tablist",
  });

  const getTabProps = (tabIdentifier) => {
    const targetId = findTab(tabIdentifier)?.[targetPanelIdAccessor]
    
    return {
      role: "tab",
      'aria-selected': activeTab === tabIdentifier,
      'aria-controls': targetId,
      id: tabIdentifier,
      key: tabIdentifier,
      onClick: e => setActiveTab(tabIdentifier)
    }
  };

  const getPanelProps = (tabIdentifier) => {
    const id  = findTab(tabIdentifier)?.[targetPanelIdAccessor]
    return {
      id,
      key: id,
      'aria-current': activeTab === tabIdentifier,
      role: 'tabpanel',
      'aria-labelledby': tabIdentifier,
    }
  };

  return {
    activeTab,
    tabs,
    getContainerProps,
    getTabProps,
    getPanelProps,
  };
}
