import React, { useEffect } from 'react';
import { Parallax } from 'react-parallax';
import { PageViewFB } from './components/Tracking/facebook';
import NewsLetter from './components/NewsLetter';
import SpaceExperts from  './components/SpaceExperts';
import rocketLaunch from "../assets/images/icons/laptop-launch-lg.png";
import startupShortfall from '../assets/images/graphics/space-shortfall.png'
import guidanceGraphic from '../assets/images/graphics/square-infographic-v1.png';
import strengthBg from '../assets/images/banners/1024/accredit-strength-1024@2x.jpg';
import { Helmet } from "react-helmet";
import ButtonWithoutIcon from './components/Buttons';
import styled from 'styled-components'
import { useUser } from 'redux/user';


function AccreditedBanner({ children }) {
  return (
    <div className="raise-banner">
      <div className="container">
        <div className="page-header-1">
          <h2 className="accredited-header-main ">Fund the future.</h2>
          <div className="accredited-subheader">
            <p className="accredited-subheader-text">Innovation requires early-stage funding, but space startups don’t have many options.  </p>
          </div>
          <div className="py-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

function SpaceGrowing() {
  return (
    <div className="section-divider-white-bottom">
      <SpaceGrowingContainerStyles>
        <div className="container ">
          <div className="pitch-text" >
            <h4 className="text-uppercase fs-8 weight-300 zeitung-mono">Why Spaced Ventures?</h4> 
            <h2 className="fs-4 weight-900 zeitung-micro">Space is Growing</h2>
             <p className="fs-6 lh-md m-t-20 weight-700 zeitung-micro"><a href="https://www.morganstanley.com/ideas/investing-in-space" target="_blank" rel="noopener noreferrer">Morgan Stanley</a> and <a href="https://www.cnbc.com/2017/10/31/the-space-industry-will-be-worth-nearly-3-trillion-in-30-years-bank-of-america-predicts.html" target="_blank" rel="noopener noreferrer">Merrill Lynch</a>  both believe that space will be a $1+ trillion dollar industry by 2040.</p>
            <p className="fs-6 lh-md weight-300 m-t-25">At Spaced Ventures, we believe it’s time to innovate out of the current government and billionaire-only space funding model. We’re proud to provide a platform that allows regular people to invest as little as $100 to show their support for space entrepreneurs all around the world.</p> 
          </div>
        </div>
      </SpaceGrowingContainerStyles>
    </div>
  )
}

function CapitalFormation() {
  return (
    <div className="section-divider boat-banner attr-parent">
      <div className="container ">
        <div className="cap-container section-top">
          <div className="cap-form-text-container">
            <h2 className="fs-4 weight-900">Inefficient capital formation created a space funding gap</h2>
            <p className="fs-6 weight-300 m-t-35">Space is a dynamic, capital intensive industry but its current capital formation is nascent. 98% of space investment has gone to companies in Series A and beyond, while the vast majority of space startups have little or no options for seed funding.</p>
            <p className="fs-6 weight-700 m-t-30"> We believe the current system delays innovation, but Crowdfunded investments through Spaced Ventures offers a solution to fill this gap.</p>
          </div>
          <div className="startup-shortfall">
            <div className="capital-journey-caption zeitung-micro weight-700 fs-6">Space startup's <a href="https://www.spaceitbridge.com/guest-editorial-the-space-startups-capital-journey-why-its-different-and-how-its-changing.htm" target="_blank" rel="noopener noreferrer">"capital journey"</a></div>
            <img className="cap-formation-infographic m-t-20" src={startupShortfall} alt="startup short fall in space industry" />
            {/* <div className="image-caption zeitung fs-12 text-muted">"This is a graphical representation of <a href="https://www.spaceitbridge.com/guest-editorial-the-space-startups-capital-journey-why-its-different-and-how-its-changing.htm" target="_blank" rel="noopener noreferrer">'the space startup’s capital journey'</a>"</div> */}
          </div>
        </div>
      </div>
      <div className="attr-container-position">
        <div className="attribution-spacing">
          <a className="attribution-link" href="https://www.instagram.com/johnpisani_photos" target="_blank" rel="noopener noreferrer">@johnpisani_photos - Faring Catcher</a>
        </div>
      </div>
    </div>
  )
}

function MoneyGuidance() {
  return (
    <div className="container duo-section-grid main-section">
      <div className="guidance-text container-615-width">
        <h4 className="text-uppercase fs-8 weight-300 zeitung-mono">Why Spaced Ventures?</h4>
        <h2 className="fs-4 weight-900">Front row seat to innovation</h2>
        <p className="fs-6 weight-300 m-t-35">Space is so much more than the next rocket launch. But if you’re not an industry insider, that’s all it can feel like.</p>
        <p className="fs-6 weight-300 m-t-25">By investing in a company on our platform, you gain a unique view behind the curtain. Through our newsletters and company updates, you can learn more about different industry subsectors and what it’s like to run a space startup. Join us and become an integral part of the space innovation ecosystem.</p>
      </div>
      <img className="guidance-graphic" src={guidanceGraphic} alt="space financing" />
    </div>
  )
}

function StrengthNumbers() {
  return (
    <div>
      <Parallax bgImage={strengthBg} strength={500} className="">
        <div className="d-flex align-items-center">
          <div className="container  d-flex justify-content-end main-section">
            <div className="container-615-width f-white">
              <h4 className="text-uppercase fs-8 weight-300 zeitung-mono">Why Spaced Ventures?</h4>
              <h2 className="weight-900">Building Strength in Numbers</h2>
              <p className="fs-6 weight-300 m-t-35">On Spaced Ventures, you can have candid discussions with other investors who love space and evaluate a startup's potential together. We are building the world’s largest community of space investors because the only way to innovate at the scale of space is to open up the space funding ecosystem to everyone.</p>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  )
}


export default function Accredited({ toggleModal }){
  const user = useUser();
  const currentClient = user?.clients?.find(client => client?.entity_type === 'person')
  useEffect(() => {
    console.log("use effect ran for google analytics on accredited")
    PageViewFB();       
  }, [])

  return (
      <>
      <Helmet>
        <title>Investors</title>
        <meta
          name="description"
          content="Innovation requires early-stage funding, but space startups don’t have many options. At Spaced Ventures, we believe it’s time to innovate out of the current government and billionaire-only space funding model. We’re proud to provide a platform that allows regular people to invest as little as $100 to show their support for space entrepreneurs all around the world." />
      </Helmet>          
        <div className="accredited-img-container">  
          <AccreditedBanner>
          {/* <NewsLetter 
            icon={true} 
            text="Sign up as Accredited Investor" 
            width="80%"
            eventCategory="newsletter sign up"
            eventAction="accredited cta"
            eventLabel="accredited banner top cta" 
            toggleModal={toggleModal}
            accredited={true}
            /> */}

            {!currentClient && <ButtonWithoutIcon
              text="Join Spaced Ventures"
              link="/signup"
              width="36%"
              align="center"
              eventCategory="user signup"
              eventAction="accredited cta"
              eventLabel="accredited top cta"          
            />}
          </AccreditedBanner>
        </div>
          <CapitalFormation />
          <SpaceGrowing />
       
          <SpaceExperts 
            tileBoxBorder='#fff' 
            tileBoxShadow='#fff' 
            imgBorder='#111'
            color="#fff"  
            bgColor="#111" 
            subheader="Why Spaced Ventures?" 
            header="Over 300 Years of Experience in the Space Industry" 
            text="Aerospace is a technical and risky industry. Very few funding portals have the capability of understanding all of the market and technological risks of deals to be able to determine whether they raise concerns about investor protection. We can do this at Spaced Ventures because our investment board has over 300 years of combined experience in the space industry." />
          
          <MoneyGuidance />
          <StrengthNumbers />
          <div className="pattern-bg-newsletter">
        <div className="main-section container">
          <div className="container d-flex flex-column justify-content-center align-items-center">
            <img className="laptop-launch-icon" src={rocketLaunch} alt="spaced ventures launching soon"></img>
            <h2 className="fs-5 weight-900 text-center m-t-20 pb-3">Join Spaced Ventures. <br></br>Don’t miss out.</h2>
          

            {!currentClient && <ButtonWithoutIcon
              text="Join Spaced Ventures"
              link="/signup"
              width="36%"
              align="center"
              eventCategory="user signup"
              eventAction="accredited cta"
              eventLabel="accredited bottom cta"          
            /> }
          </div>
        </div>
        </div>
      </>
  )
}



const SpaceGrowingContainerStyles = styled.div`
  background: #111;
  overflow: hidden;
  padding-bottom: 70px;
  color: #fff;
`