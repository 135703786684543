import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastMessage from "../../../../../components/ToastMessage";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import TextArea from "components/HookForm/TextArea";
import SelectField from "components/HookForm/SelectInput";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { getIcon } from "jsUtils/offerUtils";
import _ from 'underscore.string'
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import {
  addPitchSection,
  updatePitchSection,
  deletePitchSection,
} from "../../queries";
import {
  editIcon,
  closeIcon,
} from "../../../../../assets/images";
import LoadingOverlay from "react-loading-overlay";

const highlightsValidationSchema = Yup.object().shape({
  highlight_title: Yup.string().required("Title is required.").max(40, 'Title max length is 40 characters'),
  highlight_description: Yup.string().required("Description is required.").max(60, 'Description max length is 60 characters'),
  highlight_type: Yup.string().required("Description type is required."),
});

const Highlight = ({highlight, highlightEditHandler, highlightDeleteHandler})  =>{
  return(
    <div key={highlight.id} className="single-existing-highlight mb-3">
      <div className="single-existing-pitch-content">
        <div className="existing-highlight-icon-box fs-4">
          <img src={getIcon(highlight.highlight_type)} alt={'icon'}/>
        </div>
        <div className="existing-highlight-content-box">
          <div className="inner1">
            <p className="existing-highlight-title poppins weight-700 mb-0">
              {_.prune(highlight.title, 25, '...')}
            </p>
            <span className="existing-highlight-subtitle poppins fs-10">
              {_.prune(highlight.description, 25, '...')}
            </span>
          </div>
        </div>
      </div>
      <div className="existing-pitch-actions">
          <img
            onClick={() => highlightEditHandler(highlight) }
            src={editIcon}
            alt="edit icon"
          />
          <img
            onClick={() => highlightDeleteHandler(highlight.id)}
            src={closeIcon}
            alt="close icon"
          />
        </div>
    </div>
  )
}

const Highlights = ({ pitch:{highlights=[], pitchable_id} }) => {
  const { preofferSlug } = useParams()
  const deleteHighlightModal = useContext( PortalizedModalContext );
  const [highlightForEdit, setHighlightForEdit] = useState(null);
  const queryClient = useQueryClient();

  useEffect(()=>{
    if(highlightForEdit){
      methods.setValue('highlight_title',highlightForEdit.title)
      methods.setValue('highlight_description',highlightForEdit.description)
      methods.setValue('highlight_type',highlightForEdit.highlight_type)
    }else{
      methods.reset()
    }
  },[highlightForEdit])

  const deleteHighlight = useMutation(deletePitchSection, {
    onSuccess: () => {
      queryClient.invalidateQueries(['preoffer#pitch', preofferSlug]);
      ToastMessage.success(`Highlight deleted successfully.`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function highlightDeleteHandler (id){
    deleteHighlightModal.setModalContent(
      `Delete Highlight`,
      <>
        <div className="px-3 pt-3">
          <p>Are you sure you want to delete the highlight?</p>
        </div>
        <div className="alert-modal-footer pb-3">
          <button className="zeitung-mono weight-300 mr-3 btn-black-outline" onClick={() => deleteHighlightModal.closeModal()}>Cancel</button>
          <button className="zeitung-mono weight-300 btn-black"
            onClick={() => {
              deleteHighlight.mutate({
                section_id: id,
                type: "highlight",
              });
              if(id === highlightForEdit?.id)
                setHighlightForEdit(null)
              deleteHighlightModal.closeModal()
            }}
          >Confirm</button>
        </div>
      </>
    );
    deleteHighlightModal.openModal();
  }
  const saveHighlight = useMutation(
    highlightForEdit ? updatePitchSection : addPitchSection,
    {
      onSuccess: (values) => {
        methods.reset()
        highlightForEdit && setHighlightForEdit(null)
        queryClient.invalidateQueries(['preoffer#pitch', preofferSlug]);
        ToastMessage.success(`Highlight ${highlightForEdit ? 'updated' : 'added'}  successfully.`);
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  );

  const onSubmit = (values) => {
    saveHighlight.mutate({
      pitchable_id: pitchable_id,
      section_id: highlightForEdit?.id,
      values: {
        type: "highlight",
        pitch_section: {
          title: values.highlight_title,
          description: values.highlight_description,
          highlight_type: values.highlight_type,
        },
      },
    });
  };

  const methods = useForm({
    resolver: yupResolver(highlightsValidationSchema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      highlight_title: "",
      highlight_description: "",
      highlight_type: "",
    },
  });

  return (
  <LoadingOverlay active={saveHighlight.isLoading || deleteHighlight.isLoading} spinner text="Loading">
    <div className="highlights-maker">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="add-highlights mb-4"
        >
          <h6 className="fs-10 montserrat weight-600">
            Add new highlight
          </h6>
          <TextField name="highlight_title" placeholder="Title" type="text" />
          <TextArea
            name="highlight_description"
            placeholder="Description"
            type="text"
          />
          <SelectField
            name="highlight_type"
            options={["monetary", "award", "technology", "team", "traction"]}
            placeholder="Type"
          />
          <button type="submit" className="btn-black poppins">
            {highlightForEdit ? "UPADTE HIGHLIGHT" : "ADD HIGHLIGHT"}
          </button>
          {highlightForEdit && (
            <button
              onClick={() => {
                setHighlightForEdit(null);
              }}
              type="button"
              className="btn-black w-25 ml-3"
            >
              CANCEL
            </button>
          )}
        </form>
      </FormProvider>
      {highlights.length > 0 &&
        <div className="existing-highlights">
          <h6 className="fs-10 montserrat weight-600">
            Existing highlights
          </h6>
          {highlights.map((highlight) => (
            <Highlight
              key={highlight.id}
              highlight={highlight}
              highlightEditHandler={setHighlightForEdit}
              highlightDeleteHandler={highlightDeleteHandler}
            />
          ))}
        </div> }
    </div>
  </LoadingOverlay>
  );
};

Highlights.defaultProps = {};

Highlights.propTypes = {
  // pitchable_id: PropTypes.string.isRequired,
  pitch: PropTypes.object.isRequired,
};

export default Highlights;
