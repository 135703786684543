import React from 'react';

export default function LineChart({data, options={}, containerId}) {
  if(data?.length <= 1){
    document.getElementById(containerId).innerHTML = `
      <div class="flex-cover">
        No companies match your query.
      </div>
    `
    return null
  }

  if(data) {
    window.google.charts.setOnLoadCallback(function() {
      const chartData = new window.google.visualization.arrayToDataTable(data)

      const lineChart = new window.google.visualization.LineChart(document.getElementById(containerId))

      const errorHandler = (error) => {
        document.getElementById(containerId).innerHTML = `
          <div class="flex-cover">
            No companies match your query.
          </div>
        `
       }

      window.google.visualization.events.addListener(lineChart, 'error', errorHandler);

      lineChart.draw(chartData, options);
    });
  }
  return null;
};

