import React from 'react';
import ToastMessage from '../../../components/ToastMessage';
import { FaExternalLinkAlt, FaLink } from 'react-icons/fa';
import { humanize } from 'jsUtils/stringUtils';
import { OFFER_STATUS_CONSTANTS } from 'config/constants';
import {
  ButtonOutline,
} from 'admin/components';

export default function StatusIndicator({raisePetition, theme='light'}){
  const { status } = raisePetition;

  let classNames = 'user-status-circle';
  if(status === OFFER_STATUS_CONSTANTS.LIVE) {
    classNames += ' bg-success';
  }
  else if(status === OFFER_STATUS_CONSTANTS.CLOSED) {
    classNames += ' bg-danger';
  }
  else {
    classNames +=  ' bg-white';
  }
  return (
    <>
      <span className="btn-status text-capitalize fs-10 montserrat">
        <span className={classNames} />
        {humanize(status)}
      </span>

      <ButtonOutline
        theme={theme}
        additionalClasses="mr-2"
        icon={<FaLink className="mr-2" />}
        text="Copy Link"
        onClick={
          () => {
            try {
              navigator.clipboard.writeText(`${window.location.origin}/raise-petitions/${raisePetition.slug}`).then(
                success => ToastMessage.success("Copied"),
                error => ToastMessage.error("Copy action not supported.")
              )
            }
            catch {
              ToastMessage.error("Copy action not supported.")
              alert(`Your public link is - ${window.location.origin}/raise-petitions/${raisePetition.slug}`)
            }
          }
        }
      />

      <ButtonOutline
        theme={theme}
        icon={<FaExternalLinkAlt className="mr-2" />}
        text="Preview"
        onClick={
          () => window.open( `/admin/preview/raise-petitions/${raisePetition?.slug}`, "_blank")
        }
      />
    </>
  )
}