import React from 'react';
import styled from 'styled-components';

export default function PageTitleBanner({ title, height = '300px' }) {
  return (
    <PageBannerStyles className='d-flex' height={height}>
      <div className='container align-self-end justify-content-start'>
        <h1 className='montserrat text-uppercase weight-900 f-white'>{title}</h1>
      </div>
    </PageBannerStyles>
  )
}

const PageBannerStyles = styled.div`
  height: ${props => props.height};
  background-color: #111;

  h1 {
    font-size: 50px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    height: 150px;
    
    h1 {
      font-size: 25px;
      padding-bottom: 0;
    }
  }
`;