import React from 'react';
import gif1 from '../../assets/images/gifs/1SpacedHow.gif';
import gif2 from '../../assets/images/gifs/2Process-Animation.gif';
import gif3 from '../../assets/images/gifs/3Spacedhow.gif';


const data = [
  {
    text: "All our startups are reviewed carefully and either operate or plan to operate in the aerospace industry.",
    img: gif1
  },
  {
    text: "Our platform gives direct access to investment deals.",
    img: gif2
  },
  {
    text: "Though startup investing is risky, you may get a return if the companies you select do well and succeed.",
    img: gif3
  },
]

function Item({title, text, img}){
  return (
    <div className="how-it-works-card ">
      <img className="how-it-works-card-img" src={img} alt={title}/>
      <div>
        <p className="m-t-20 fs-8 zeitung-micro weight-700 ">{text}</p>
      </div>
    </div> 
  )
}

export default function HowItWorks () {
  return (
    <>
      <div className="container main-section center-mobile center-align-text">
        <h2 className="fs-5 weight-900 pb-2">How Spaced Ventures Works</h2>
        <div className="how-it-works m-t-50">
          {data.map((value, key) => {
            return (
              <Item key={key} title={value.title} text={value.text} img={value.img} />
            )
          })}
        </div>
      </div>
    </>
  )
}