import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const CheckboxField = ({name, label}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <label className={`custom-checkbox form-group d-flex flex-wrap align-items-center position-relative ${isTouched && invalid ? 'is-invalid' : ''}`}>
            <input
              type="checkbox"
              {...field}
              id={field.name}
              name={field.name}
              className="mr-2"
            />
            <span className="custom-checkbox-check"></span>
            <span className="custom-checkbox-tagline">{label}</span>
          </label>
          <ErrorMessage error={error}/>
        </>
      )}
    />
  )
}

CheckboxField.defaultProps = {};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckboxField;
