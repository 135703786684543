import axiosClient from 'config/axiosClient';

export const spacedbaseApi = {
  fetch,
};

function fetch(params={}) {
  Object.keys(params).forEach(k => (params[k] === null || params[k] === undefined || params[k] === '') && delete params[k]);
  return axiosClient.get('/spacedbase', {params});
}
