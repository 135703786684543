import { useEffect } from "react";
import ToastMessage from "../../../../components/ToastMessage";
import { scrollIntoView } from "../../../../jsUtils/formUtils";
export default function Effect({
  submitCount,
  isValid,
  isSubmitting,
  errors,
  type = "",
}) {
  const effect = () => {
    const keys = Object.keys(errors);
    if (submitCount > 0 && isSubmitting && !isValid) {
      if (type === "scroll") {
        scrollIntoView(keys[0]);
      } else if (type === "toast") {
        keys.map((key) => ToastMessage.error(errors[key]));
      } else {
        scrollIntoView(keys[0]);
        keys.map((key) => ToastMessage.error(errors[key]));
      }
    }
  };
  useEffect(effect, [submitCount, errors, isSubmitting]);
  return null;
}
