import React from 'react';
import _ from 'underscore.string'


import { INVESTMENT_STATUSES } from "../../../config/constants";
import { formatDate } from "../../../jsUtils/dateTimeUtils";
import { toTitlecase } from "jsUtils/stringUtils";


const InvestmentStatus = ({ investment: { status, cancelled_at, cancellation_requested_at } }) => {
    if (status === INVESTMENT_STATUSES.CANCELLED) {
      return (
        <span>
          Status: {toTitlecase(status)}
        </span>
      );
    } else {
      return <span>Status: {_.titleize(_.humanize(status))} {cancellation_requested_at && <span>(Cancellation requested)</span>}</span>;
    }
  };

export default InvestmentStatus