import React from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from 'components/HookForm/TextInput';
import TextArea from 'components/HookForm/TextArea';
import CurrencyInputField from 'components/HookForm/CurrencyInputField';

const RaisePetitionSchema = Yup.object().shape({
  name: Yup.string().required("Petition name is required."),
  goal_amount: Yup.number()
    .positive()
    .integer()
    .typeError("Please enter petition goal amount.")
    .required("Petition goal is required."),
  company_id: Yup.number().positive().integer().required("Missing company id, please reload page."),
  message_fb: Yup.string().max(200, "Message too long, must be 200 chars or less"),
  message_linkedin: Yup.string().max(200, "Message too long, must be 200 chars or less"),
  message_twitter: Yup.string().max(200, "Message too long, must be 200 chars or less"),
});

function OverviewForm({raisePetition={}, companyId, onSubmit}) {
  const {
    name = '',
    company_id = '',
    goal_amount = '',
    message_fb = '',
    message_linkedin = '',
    message_twitter = '',
  } = raisePetition;

  const methods = useForm({
    resolver: yupResolver(RaisePetitionSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    criteriaMode: 'firstError',
    defaultValues: {
      name, company_id, goal_amount,
      message_fb, message_linkedin, message_twitter
    },
  });

  methods.setValue('company_id', companyId);

  return (
    <div className="deal-form-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* temporary hack for button style fix, will have to fix this once we add the full page context modals */}
          <div className='portalized-modal-wrapper'>
            <TextField
              name="name"
              placeholder="Petition Name*"
            />

            <CurrencyInputField
              id="goal_amount"
              name="goal_amount"
              label="Goal Amount*"
              placeholder="Goal Amount*"
            />
            {/* <TextArea
              name="message_fb"
              placeholder="Fb social message"
              type="text"
            />
            <TextArea
              name="message_linkedin"
              placeholder="LinkedIn social message"
            /> */}
            <TextArea
              name="message_twitter"
              placeholder="Twitter social message"
            />
            <div className='portalized-modal-footer'>
              <button type="submit"
                className="btn-black"
                disabled={methods.isSubmitting}
              >
                Save
              </button>
            </div>
          </div> 
        </form>
      </FormProvider>
    </div>
  );
}

export default OverviewForm;
