import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { useUser } from "redux/user";
import LoadingOverlay from "react-loading-overlay";
import "./WireTransferForm.scss";
import { addWireAccount } from "../../../investor/queries";
import ToastMessage from "components/ToastMessage";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { WIRE_TRANSFER_FEES } from "../../../config/constants";
import { formatCurrency } from "../../../jsUtils/currencyUtils";
import InvestmentCardRow from "../shared/InvestmentCardRow";
export default function WireTransferForm({
  clientId,
  onSuccess = null,
  onError = null,
  submitButtonText = "Submit",
  subHeader = "",
  showSidebar = false,
}) {
  const queryClient = useQueryClient();
  const user = useUser();
  const loading = user.loading;

  const addWireMutation = useMutation(addWireAccount, {
    onSuccess: (account) => {
      typeof onSuccess === "function" && onSuccess(account);
      queryClient.refetchQueries(["client-payment-methods", clientId]);
      ToastMessage.success("Wire method added.");
    },
    onError: (error) => {
      typeof onError === "function" && onError(error);
      renderRailsErrors(error);
    },
  });

  return (
    <LoadingOverlay active={loading} spinner text="Loading ...">
      <div className="wire-formbox">
        <div className="container pl-0">
          <div className="row">
            <div
              className={`wire-formbox-inner1-col ${
                showSidebar ? "col-md-9 py-5 pr-xl-5" : "col-12"
              }`}
            >
              <div
                className={`wire-formbox-inner1 ${showSidebar ? "pr-xl-5" : ""}`}
              >
                <div className="wire-frombox-inner1-body">
                  {subHeader && (
                    <span className="zeitung-micro fs-10 weight-300 instruction-text-for-mobile">
                      {subHeader}
                    </span>
                  )}
                  <div>
                      <p className="poppins text-muted fs-10">When sending the wire, please include your investment amount + the below applicable wire fee.</p>
                    <div className="payment-card" style={{border: '1px solid #eee'}}>
                      <InvestmentCardRow title="USA Wire Fee" value={formatCurrency(WIRE_TRANSFER_FEES.USA, {precision: 0})}/>
                      <InvestmentCardRow title="International Wire Fee" value={formatCurrency(WIRE_TRANSFER_FEES.INTERNATIONAL, {precision: 0})}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showSidebar && (
              <div className="col-md-3 py-5 bg-lightgrey wire-formbox-inner2">
                <h5 className="poppins weight-700">How do we use this info?</h5>
                <p>
                  We use a third party payment provider to carry out the
                  payment.
                </p>
              </div>
            )}
          </div>
          <div className="portalized-modal-footer">
            <button
              type="submit"
              onClick={() =>
                addWireMutation.mutate({
                  clientId,
                })
              }
              disabled={addWireMutation.isLoading}
              className="btn-black"
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}
