import React, { useCallback, useState } from "react";
import _ from 'lodash';
import AmlList from "./List";
import ErrorBoundary from "components/ErrrorBoundary";
import { useQueryClient } from "react-query";

export default function AmlListing(props) {
  const [filter, setFilter] = useState({ searchQuery: '', status: 'failed'});
  const queryClient = useQueryClient();

  const setFilterValues = (type, value) => {
    if(type === 'search'){
      setFilter({ searchQuery: value, status: filter.status })
    } else if (type === 'status'){
      setFilter({ searchQuery: filter.searchQuery, status: value })
    }
  }

  useCallback(_.debounce(() => {
   queryClient.invalidateQueries("aml_exceptions");
  }, 1000), [filter])


  return (
    <div className="bg-lightgrey">
      <ErrorBoundary>
        <div className="admin-main-content-header">
          <div className="amch-inner1">
            <div className="input-group ig-field mr-1">
              <input
                type="search"
                placeholder="Search"
                value={ filter?.searchQuery }
                onChange={(e) => { setFilterValues('search', e.target.value ) }
                } />
            </div>
            <select value={filter.status} onChange={(e) => { setFilterValues('status', e.target.value ) }} className="custom-dropdown2">
              <option value="">All</option>
              <option value="failed">Failed</option>
              <option value="cleared">Cleared</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>
        <AmlList searchQuery={filter?.searchQuery} searchStatus={filter?.status} />
      </ErrorBoundary>
    </div>
  );
}
