import React from 'react';
import { CurrencyBadge } from '../../../components/AmountWithCurrency';
import { humanize } from '../../../jsUtils/stringUtils';
import { formatDate } from '../../../jsUtils/dateTimeUtils';
import { FormattedNumberWithSymbol } from '../../../components/AmountWithCurrency';
import { ResponsiveTable } from '../../components';


export default function InvestmentsList({ investments, onRowClick }) {
  const columns = [
    {
      Header: 'Offer Name',
      accessor: 'offer_name'
    },
    {
      Header: 'Company',
      accessor: 'company_name',
      Cell: ({ row, value }) => {
        return (
          <>
            <img src={row.original.company_logo_url} alt='logo' className="company-table-logo mr-2" />
            <span>{value}</span>
          </>
        )
      }
    }, {
      Header: 'Invested As',
      accessor: 'investing_as'
    },
    {
      Header: 'Investment Date',
      accessor: 'created_at',
      Cell: ({ row, value }) => formatDate(value)
    },
    {
      Header: () => <span>Amount&nbsp;<CurrencyBadge /></span>,
      accessor: 'amount',
      Cell: ({ value, row }) => {
        return <FormattedNumberWithSymbol number={value} />
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value, row }) => humanize(value)
    },
    {
      Header: 'Service Provider',
      accessor: 'service_provider',
      Cell: ({ value, row }) => humanize(value)
    }
  ];

  return (<ResponsiveTable columns={columns} data={investments} onRowClick={(investment) => onRowClick(investment)} />)
}