import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikPromptIfDirty from 'admin/components/shared/FormikPromptIfDirty';
import * as Yup from 'yup';
import YoutubeEmbed from 'admin/components/shared/YoutubeEmbed';
import DeleteSectionButton from './DeleteSectionButton';
import LastUpdatedAt from '../shared/LastUpdatedAt';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { renderRailsErrors } from '../../../jsUtils/railsErrorRenderer';
import ToastMessage from '../../../components/ToastMessage';

const YOUTUBE_URL_REGEX = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-z0-9_-]{11})/gi

const MainVideoSchema = Yup.object().shape({
  link: Yup.string()
    .required('Video Url is required')
    .url('Invalid video url')
    .matches(YOUTUBE_URL_REGEX, {
        excludeEmptyString: true,
        message: 'Only valid Youtube vidoe urls are supported'
      }),
});

export default function MainVideoForm({
  pitch:{main_video_section={}, pitchable_id}, updateMethod
}) {
  const queryClient = useQueryClient();
  const { offerSlug } = useParams()

  if(main_video_section === null) main_video_section = {};

  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['admin', 'offers', offerSlug, 'pitch'],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
    <div id="videoForm">
      <Formik
        initialValues={{
          id: main_video_section.id,
          link: main_video_section.link || '',
        }}
        validationSchema={MainVideoSchema}
        enableReinitialize

        onSubmit={async values => {
          const { id, link } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                main_video_section_attributes: {
                  id,
                  link,
                }
              }
            }
          })
        }}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group">
                  <div className="form-label-group">
                    <Field
                      className={`form-control ${touched.video && errors.video ? "is-invalid" : ""}`}
                      id="link"
                      name="link"
                      placeholder="YouTube video link"
                    />
                    <label htmlFor="link">Youtube video link</label>
                    <ErrorMessage name="link" component="div" className="invalid-feedback text-left d-block"/>
                  </div>
                  {
                    values.link && !errors.link &&
                    <YoutubeEmbed videoUrl={values.link} />
                  }
                </div>
                </div>
            </div>
            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={main_video_section.id} sectionType="main_video"/>

            <LastUpdatedAt updatedAt={ main_video_section.updated_at }/>
            <FormikPromptIfDirty />
          </Form>
        )}
      </Formik>
    </div>
  );
}
