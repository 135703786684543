import React, { useEffect } from 'react'
import { PageViewFB } from './components/Tracking/facebook';
import { Helmet } from "react-helmet";
import '../pages/Page.support.scss';
import '../pages/Page.Press.scss';

function SupportBanner () {
  return (
    <>
    <div className="support-banner d-flex justify-content-start align-items-end">
      <div className="container ">
        <h1 className="montserrat text-uppercase weight-900 fs-1 pb-5 f-white">Support</h1>
      </div>
    </div>
    </>
  )
}

function SupportSection () {
  return (
    <>
    <div className="poppins bg-lightgrey py-5 px-4 radius-2 support-card">
      <h2 className="weight-700">Contact Us</h2>
      <p className="mb-2">If you require assistance or have any questions, please don't hesitate to reach out to our support team at support@33fg.com. We strive to provide prompt assistance, and you can typically expect a response within 1-3 business days.</p>
      <p>Thank you for choosing Spaced Ventures!</p>
    </div>
    </>
  )
}

export default function Support () {
  return (
    <div className="support-page ">
      <SupportBanner />
      <div className="container h-100 support-page-section about-img-container">
        <div className="row d-flex justify-content-start align-items-center ">
          <SupportSection />
        </div>
      </div>
    </div>
  )
}