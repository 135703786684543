import React from 'react'
import { history } from 'redux/helpers'
import { AiFillWarning } from "react-icons/ai";

function AgreementSection ({subscription, preoffer}){
  const onClickHandler = () => {
    history.push(`/prelaunches/${preoffer.slug}/interest-agreement/${subscription.id}`);
  }

  return (
    <div className="investment-detail-requirement">
      <h6 className="montserrat weight-700 fs-8 mb-3">Sign Pre-launch agreement</h6>
      {/* <p>Please sign your agreement to complete indicative interest.</p> */}
      <span onClick={onClickHandler} className="underline text-white fs-10 weight-600 cursor-pointer">Sign Pre-launch agreement</span>
    </div>
  )
}

export default function ActionRequiredSection({ subscription, preoffer }) {
  if(!preoffer.esign_template_id){
    return null;
  }

  if(subscription.esign_request?.status === 'signed'){
    return null;
  }

  return (
    <div>
      <div className="p-3 bg-black mb-3 text-white box-required-actions">
        <h6 className="montserrat weight-700 fs-8 mb-3 title-with-icon">
          <AiFillWarning color="#fff" /> <span>Action Required</span>
        </h6>
        <p className="fs-10">Actions required on the following items to complete your pre-launch interest:</p>
        <AgreementSection  preoffer={preoffer} subscription={subscription} />
      </div>
    </div>
  )
}