import React from 'react'
import FileBar from '../../admin/components/shared/FileBar'

export default function Documents({ documents }) {
  if(!documents || documents.length === 0){
    return null;
  }

  return (
    <>
      <h5 className="montserrat weight-700 mb-3">Documents</h5>
      <ul className="uploaded-files">
        {documents.map((d, i) => <FileBar key={i} name={d.name} url={d.attachment_url} downloadable={d.downloadable} />)}
      </ul>
    </>
  )
}