import React from "react";
import { SuccessBadge, FailedBadge, PendingBadge } from "./Badges";

export default function VerificationField({
  title,
  description,
  field,
  document,
  documentTypes
}) {
  return (
    <div className="content-layer bg-white">
      <p className="d-flex flex-wrap align-items-center mb-1">
        <b className="fs-default poppins weight-900">{title}</b>
        {field.status === 'pending' && <PendingBadge />}
        {field.status === 'invalid' && <FailedBadge />}
        {field.status === 'valid' && <SuccessBadge />}
      </p>
      { field.status === 'invalid' && (
      <div>
        <div className="poppins fs-9 weight-700">Instructions</div>
        <p className="d-flex flex-wrap poppins">{description}</p>
      </div>
      )}
      { documentTypes && field.status !== 'valid' &&  (
          <div className="d-flex flex-wrap mt-1">
            <ul className="ml-3">{
              documentTypes.map((t, i) => (<li key={i}>{t}</li>))
            }
            </ul>
          </div>
      )}
      {field.note && <div className="d-flex flex-wrap mt-1 flex-column"> <div className="mr-1 poppins fs-9 weight-700">Note from our team</div>{field.note}</div>}
    </div>
  );
}
