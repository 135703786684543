import React, { useEffect, useState } from "react";
import { userApi } from "../../../../api/user";
import "./MyInvestments.scss";
import { history } from "redux/helpers";
import LoadingOverlay from "react-loading-overlay";
import { FaExclamationTriangle } from "react-icons/fa";
import { humanize } from "../../../../jsUtils/stringUtils";
import { FormattedNumberWithSymbol } from "../../../../components/AmountWithCurrency";
import { formatMoneyClean } from "../../../../jsUtils/currencyUtils";
import HeaderTitle from "./HeaderTitle";
import { useQuery } from "react-query";
import { Link } from "react-router-dom"

export const PrelaunchInterestCard = ({ interest, type = "prelaunch" }) => {
  const typeText = type === "secondary" ? "Secondary Commitment" : "Pre-launch Interest";
  return (
    <div className="masonry-grid-item">
      <div className="masonry-grid-item-inner">
        <div className="portfolio-card">
          <div
            className="portfolio-card-banner"
            style={{
              background: `linear-gradient(to top,#111,transparent 60%), url(${interest.company.banner_url})`,
            }}
          >
            <div className="portfolio-card-thumb-container">
              <div className="portfolio-card-thumb">
                <img
                  className="portfolio-card-thumb-image"
                  src={interest.company.logo_url}
                  alt="offer"
                />
              </div>
            </div>
            <div className="portfolio-card-banner-content">
              <h4 className="title">{interest.company.name}</h4>
              <div className="portfolio-card-badge-container">
                <div className="card-badge-layout">
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {interest.company.sector_name}
                    </span>
                  </div>
                  <div className="card-badge mb-2 mr-2">
                    <span className="card-badge-text montserrat">
                      {interest.company.parent_sector_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-card-body cursor-pointer"
            onClick={() => history.push(`/prelaunches/${interest.preoffer.slug}/subscription/${interest.id}`)}
          >
            <div className="p-3 rounded preoffer-card-hover">
              <div>Your {typeText}</div>
              <div className="weight-900 fs-6">{formatMoneyClean(interest.amount)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Prelaunches = () => {
  const portfolio_prelaunches = useQuery(
    ['portfolio', 'prelaunch'],
    () => userApi.portfolioPrelaunches()
  );

  const {data: prelaunches = [], isLoading: loading} = portfolio_prelaunches;
  const onlyPrelaunches = prelaunches?.filter((item) => item.preoffer.category === 'none');

  return (
      <div id="prelaunches" className="pt-5">
        <HeaderTitle title="Pre-launches" />
        <LoadingOverlay active={false} spinner text="Loading...">
          {onlyPrelaunches.length > 0 ? (
            <div className="masonry-grid two-cols">
              {onlyPrelaunches.map((prelaunch, index) => (
                <PrelaunchInterestCard key={index} interest={prelaunch} />
              ))}
            </div>
          ) : (
            <div className="no-data-found py-4 rounded bg-lightgrey fs-9 poppins">
             You haven't subscribed to any Pre-launches. <Link to={`/offerings/pre-launches`}>View Pre-launches</Link>
            </div>
          )}
        </LoadingOverlay>
      </div>
  );
};

export default Prelaunches;
