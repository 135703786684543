import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchUseFundsIcon } from '../../../../assets/images';

function UseOfFundSection({ 
  pitch:{use_of_funds_section}
}) {
  if(!use_of_funds_section) return null;

  return (
    <div id="use-of-fund-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'use of funds'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchUseFundsIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{use_of_funds_section.title}</div>
          </div>
            <ArticleContent content={use_of_funds_section.content}/>
        </div>
      </div>
    </div>
  )
}

export default UseOfFundSection;