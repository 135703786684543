import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js'
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchMarketIcon } from '../../../../assets/images'

function MarketSection({ 
  pitch:{market_section}
}) {
  if(!market_section) return null;

  return (
    <div id="market-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'market'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <MarketSize market={market_section.market_size} />
          </div>
            <ArticleContent content={market_section.content}/>
        </div>
      </div>
    </div>
  )
}

function MarketSize(props) {
  const { market } = props

  const showMarketSize = (size) => {
    if(size) {
      if (/^\d+$/.test(size))
        return formatMoney(size);
      else
        return size;
    } else {
      return "";
    }
  }
  return (
    <div className="market-size">
        <div className="market-size-logo-box">
          <img src={pitchMarketIcon} alt="Market size" />
        </div>
        <div className="market-size-summary">
          <div className="name">{ showMarketSize(market) }</div>
          <div className="description">Approximate Total Market Size</div>
        </div>
    </div>
  )
}

export default MarketSection;