import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import buttonRocketIcon from '../../assets/images/icons/button-rocket-icon-lg.png'
import {Link} from 'react-router-dom'
import { FaLongArrowAltRight, FaInfoCircle } from 'react-icons/fa';
import { runGAEvent } from "../components/Tracking/index";

const NewsLetter = ({ 
    text = "Sign up to Learn More", 
    width = "100%", 
    align = "left", 
    icon = true, 
    eventCategory = "newsletter sign up",
    eventAction = "newsletter cta",
    eventLabel = "cta",
    toggleModal,
    accredited = false
  }) => {

  const triggerNewsletter = () => {
    runGAEvent(eventCategory, eventAction, eventLabel)
    toggleModal(accredited)
  }

  return (
    <NewsLetterContainer  width={width} align={align}>

      {icon ? (
        <button onClick={triggerNewsletter} className="zeitung-mono weight-300 btn-block btn-black">
          <div className="newsletter-btn">
            <div className="newsletter-btn-text">{text}</div>
            {icon && <img className="newsletter-btn-icon" src={buttonRocketIcon} alt="rocket"></img>}
          </div>
        </button>
      ) : ( 
          <button onClick={triggerNewsletter} className="zeitung-mono weight-300 btn-block btn-black">
            <div className="newsletter-btn-no-icon">
              <div className="newsletter-btn-text">{text}</div>
            </div>
          </button>
      )}

    </NewsLetterContainer>  
  )
}

export default NewsLetter;

const NewsLetterContainer =  styled.div`
  width: ${props => props.width};

  button {
    text-align: ${props => props.align};
    padding-left: ${props => props.align === 'left' ? "27px" : "0px"};
  }

  .newsletter-btn-no-icon {
    text-align: center;
  }

  @media (max-width: 776px) {
    width: 100%;
  }

  @media (max-width: 776px) {
    button {
      text-align: center;
      padding-left: 11px;
    }

    .newsletter-btn-icon {
      display: none;
    }

    .newsletter-btn {
      justify-content: center;
    }
  }

`;