import React from 'react';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import {
  progressBarPercentage,
  raisedFundsPercentage
} from '../../../../jsUtils/offerUtils';
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../../../components/AmountWithCurrency';

export default function FundingProgressBar({ offer }) {
  const raisedAmount = parseInt(offer.deal.raised) || 0;
  const goal = offer.deal.goal || 0;
  const fundsPercentage = raisedFundsPercentage(raisedAmount, goal);

  return (
    <>
      <div className="amount-raised">
        <span className="amount">
          <FormattedNumberWithSymbol number={raisedAmount} numberFormattingOpts={{ precision: 0 }} />
        </span>
        <span className="raised-text">&nbsp;Raised</span>
        <CurrencyBadge />
      </div>
      <div className="progressbar-summary">
        <div className="mb-3 mb-sm-2">
          <span className="funded-precentage">{fundsPercentage}%</span>
          <span className="funded-text ml-2">of target: {formatMoney(goal, { precision: 0 })}</span>
        </div>
      </div>
      <div className="progress-box progressbar-gold">
        <div className="progressbar">
          <div style={{ width: `${progressBarPercentage(fundsPercentage)}%` }} className="progress"></div>
        </div>
      </div>
    </>
  )
}