import { createReduxModule } from 'hooks-for-redux'
import ToastMessage from '../components/ToastMessage';
import { offerApi } from 'api/offer'
import {DEFAULT_PAGE_SIZE} from './../config/constants';
import { fbEvent } from '../../src/pages/components/Tracking/facebook'
import { runGAEventWithValue } from '../pages/components/Tracking';
import { rollbarInstance } from 'jsUtils/rollbar';
import { renderRailsErrors } from '../jsUtils/railsErrorRenderer';

const storeKey = 'offers'
const initialState = {
  list: [],
  listMeta: {},
  businessModels: [],
  competitions: [],
  fetchingOffers: false,
  hasMore: false,
  loading: false,
  isOfferCreatedOrUpdated: false
};

const initialStateForCurrentOffer = {
  closeableInvestments: [],
  closedInvestments: [],
  loading: false,
  closeableInvLoading: false,
  closedInvLoading:false,

}

export const [useCurrentOffer, { __setLoading, __updateOffer, __setCloseableInvLoading, __setClosedInvLoading,
  __clearCurrentOffer, __resetCurrentOffer, __setCurrentOffer, __setOfferPitch,
  __setOfferCustomers, __removeCustomerFromOffer, __addCustomerFromOffer,
  __updateDealOnOffer,
  __changeOfferStatus,
  __deletePitchSection, __setCloseableInvestments, __setClosedInvestments,
  __updateClosedInvestment, __updateCloseableInvestments,
}, currentOfferStore] = createReduxModule('currentOffer', initialStateForCurrentOffer, {
  __setLoading: (state, loading) => {
    return {
      ...state,
      loading: loading
    }
  },
  __updateOffer:(state, attrs)=>{
    return{
      ...state,
      ...attrs
    }
  },

  __setCloseableInvLoading: (state, loading) => {
    return {
      ...state,
      closeableInvLoading: loading
    }
  },

  __setClosedInvLoading: (state, loading) => {
    return {
      ...state,
      closedInvLoading: loading
    }
  },

  __clearCurrentOffer: (state) => {
    return initialStateForCurrentOffer;
  },

  __resetCurrentOffer: (state, offer) => {
    return {
      ...state,
      ...offer
    };
  },

  __setCurrentOffer: (state, offer) => {
    const currentPitch = state.pitch ? JSON.parse(JSON.stringify(state.pitch)) : null
    offer.pitch = currentPitch
    offer.publishable = currentPitch?.offer_publishable
    return {
      ...state,
      ...offer
    }
  },

  __setOfferPitch: (state, pitch) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.pitch = pitch
    offer.publishable = pitch.offer_publishable
    return {
      ...state,
      ...offer
    }
  },

  __setOfferCustomers: (state, customers) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.customers = customers
    return {
      ...state,
      ...offer
    }
  },

  __removeCustomerFromOffer: (state, customerId) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.customers = offer.customers.filter(c => c.id !== customerId)
    return {
      ...state,
      ...offer
    }
  },

  __addCustomerFromOffer: (state, customer) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.customers = offer.customers.filter(c => c.id !== customer.id).concat(customer)
    return {
      ...state,
      ...offer
    }
  },

  __updateDealOnOffer: (state, deal) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.deal = deal
    offer.publishable = deal.offer_publishable
    return {
      ...state,
      ...offer
    }
  },

  __changeOfferStatus: (state, nextStatus) => {
    const offer = JSON.parse(JSON.stringify(state))
    offer.status = nextStatus
    return {
      ...state,
      ...offer
    }
  },

  __deletePitchSection: (state, type) => {
    const offer = JSON.parse(JSON.stringify(state))
    delete offer.pitch[type];
    if(type.replace('_section', '') === 'business_model') delete offer.pitch['business_models']
    return {
      ...state,
      ...offer
    }
  },

  __setCloseableInvestments: (state, closeableInvestments) => {
    return {
      ...state,
      closeableInvestments
    }
  },

  __setClosedInvestments: (state, closedInvestments) => {
    return {
      ...state,
      closedInvestments
    }
  },

  __updateClosedInvestment: (state, closedInvestment) => {
      const offer = JSON.parse(JSON.stringify(state))
      offer.closedInvestments.push(closedInvestment)
      return {
        ...state,
        ...offer
      }
    },
  __updateCloseableInvestments:(state, investmentIds) =>{
      const offer = JSON.parse(JSON.stringify(state))
      offer.closeableInvestments = offer.closeableInvestments.filter(investment => !investmentIds.includes(investment.id))
      return{
        ...state,
        ...offer
      }
    }
})

export const [useOffers, {
  _setLoading, _setOfferList, _setFetchingOffers, _setHasMore,
  _addOffer, _removeOffer,
  _setBusinessModelList,
  _setCompetitionList,
  _setIsCreatedOrUpdated,
  setError,
}, offersStore] = createReduxModule(storeKey, initialState, {
  _setLoading: (state, loading) => {
    return {
      ...state,
      loading: loading
    }
  },

  _setOfferList: (state, list) => {
    // const updatedList = state.list.concat(list)
    return {
      ...state,
      list: list.data,
      listMeta: list.pagy,
    }
  },

  _setFetchingOffers: (state, fetchingOffers) => {
    return {
      ...state,
      fetchingOffers,
    }
  },

  _setHasMore: (state, hasMore) => {
    return {
      ...state,
      hasMore,
    }
  },

  _addOffer: (state, offer) => {
    let list = state.list.filter( o => o.id !== offer.id ).concat(offer)
    return {
      ...state,
      list
    }
  },

  _removeOffer: (state, offerId) => {
    let list = state.list.filter( o => o.id !== offerId )
    return {
      ...state,
      list
    }
  },

  _setBusinessModelList: (state, businessModels) => {
    return {
      ...state,
      businessModels: businessModels
    }
  },

  _setCompetitionList: (state, competitions) => {
    return {
      ...state,
      competitions: competitions
    }
  },

  _setIsCreatedOrUpdated: (state, createdOrUpdated) => {
    return {
      ...state,
      isOfferCreatedOrUpdated: createdOrUpdated
    }
  },

  setError: (state, error) => {
    return {...state, error}
  },
})

export const listOffers = (page=1, per_page=DEFAULT_PAGE_SIZE) => {
  if(offersStore.getState().fetchingOffers) return

  _setFetchingOffers(true)
  _setLoading(true)

  offerApi.list(page, per_page).then(
    res => {
      _setOfferList(res)
      _setFetchingOffers(false)
      _setLoading(false)
    },
    error => {
      setError({listOffer: error})
      _setFetchingOffers(false)
      _setLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const searchOffers = (pattern, page=1, per_page=DEFAULT_PAGE_SIZE) => {
  // if(offersStore.getState().fetchingOffers) return

  _setFetchingOffers(true)
  _setLoading(true)

  offerApi.search(pattern, page, per_page).then(
    res => {
      _setOfferList(res)
      _setFetchingOffers(false)
      _setLoading(false)
    },
    error => {
      setError({listOffer: error})
      _setFetchingOffers(false)
      _setLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}


export const createOffer = (companyId, attrs) => {
  _setLoading(true)
  __setLoading(true)
  offerApi.create(companyId, attrs).then(
    offer => {
      _setLoading(false)
      __setLoading(false)
      _addOffer(offer)
      __setCurrentOffer(offer)
      _setIsCreatedOrUpdated(true)
      ToastMessage.success("Offer created successfully.")
      // history.push(`/admin/companies/${companyId}/offers/${offer.id}/edit`)
    },
    error => {
      setError({create: error})
      _setLoading(false)
      __setLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const updateOffer = (id, attrs) => {
  _setLoading(true)
  __setLoading(true)
  offerApi.update(id, attrs).then(
    offer => {
      _setLoading(false)
      __setLoading(false)
      _addOffer(offer)
      __setCurrentOffer(offer)
      _setIsCreatedOrUpdated(true)
      ToastMessage.success("Offer updated successfully.")
    },
    error => {
      _setLoading(false)
      __setLoading(false)
      setError({update: error})
      ToastMessage.error(error.message)
    }
  );
};

export const setCurrentOffer = (offer) => {
  __setLoading(false)
  _addOffer(offer)
  __setCurrentOffer(offer)
  _setIsCreatedOrUpdated(true)
}

export const fetchOffer = (id) => {
  _setLoading(true)
  __setLoading(true)
  offerApi.fetch(id).then(
    offer => {
      _setLoading(false)
      __setLoading(false)
      __setCurrentOffer(offer)
    },
    error => {
      _setLoading(false)
      __setLoading(false)
      setError({fetchOffer: error})
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}


export const fetchOfferPitch = (offerId) => {
  _setLoading(true)
  __setLoading(true)

  offerApi.fetchPitch(offerId).then(
    pitch => {
      _setLoading(false)
      __setLoading(false)
      __setOfferPitch(pitch)
    },
    error => {
      _setLoading(false)
      __setLoading(false)
      setError({fetchPitch: error})
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const updateOfferPitch = (offerId, pitchAttrs) => {
  _setLoading(true)
  offerApi.updatePitch(offerId, pitchAttrs).then(
    pitch => {
      _setLoading(false)
      __setOfferPitch(pitch)
      ToastMessage.success("Pitch section updated successfully.")
    },
    error => {
      _setLoading(false)
      setError({fetch: error})
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const addCustomer = (offerId, attrs) => {
  _setLoading(true)
  return offerApi.addCustomer(offerId, attrs).then(
    customer => {
      _setLoading(false)
      __addCustomerFromOffer(customer)
      ToastMessage.success("Customer added successfully.")
    },
    error => {
      _setLoading(false)
      console.error(error)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  );
}

export const purgeCustomer = (customerId) => {
  _setLoading(true)
  return offerApi.purgeCustomer(customerId).then(
    response => {
      _setLoading(false)
      __removeCustomerFromOffer(customerId)
      ToastMessage.success("Customer deleted.")
    },
    error => {
      _setLoading(false)
      console.log(error)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  );
}

export const updateCustomer = (customerId, attrs) => {
  _setLoading(true)
  return offerApi.updateCustomer(customerId, attrs).then(
    customer => {
      _setLoading(false)
      __addCustomerFromOffer(customer)
      ToastMessage.success("Customer updated successfully.")
    },
    error => {
      _setLoading(false)
      console.error(error)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  );
}

export const fetchBusinessModels = () => {
  _setLoading(true)
  return offerApi.fetchBusinessModels().then(
    bms => {
      _setLoading(false)
      _setBusinessModelList(bms)
    },
    error => {
      _setLoading(false)
      console.error(error)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  );
}

export const fetchCompetitions = () => {
  _setLoading(true)
  return offerApi.fetchCompetitions().then(
    c => {
      _setLoading(false)
      _setCompetitionList(c)
    },
    error => {
      _setLoading(false)
      console.error(error)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  );
}

export const updateOfferStatus = (offerId, nextStatus) => {
  _setLoading(true)
  return offerApi.changeOfferStatus(offerId, nextStatus).then(
    response => {
      _setLoading(false)
      __changeOfferStatus(nextStatus)
      ToastMessage.success("Offer status updated successfully.")
    },
    error => {
      _setLoading(false)
      console.error(error)
      return error?.base?.map(e => {
        ToastMessage.error(e);
      })
    }
  );
}
export const signSubscriptionAgreeement  = (investmentId, values, onSubmit) =>{
  _setLoading(true);
  offerApi.signInvestorAgreementContent(investmentId, values).then(
    res => {
      _setLoading(false);
      onSubmit(res, null);
    },
    error => {
      _setLoading(false);
      onSubmit(null, error)
    }
  )
}

export const makeInvestment = (attrs, investmentAccountId, onSubmit) => {
  _setLoading(true)
  rollbarInstance.info("Creating new investment", {attrs})
  return offerApi.makeInvestment(attrs, investmentAccountId).then(
    investment => {
      if(onSubmit) onSubmit(investment.id);
      fbEvent(`${investment.company_name} Investment`, investment.amount);
      runGAEventWithValue(`${investment.company_name} Investment`, "form submission", "success", investment.amount);
      runGAEventWithValue("New Investment", "form submission", "success", investment.amount);
      fbEvent("New Investment", investment.amount);
      rollbarInstance.info("Created new investment", {investment})
      ToastMessage.success("Investment Amount Confirmed");
      _setLoading(false)
    },
    error => {
      _setLoading(false)
      console.error(error)
      rollbarInstance.error("Error creating investment", {attrs, error})
      renderRailsErrors(error);
    }
  )
}

export const deletePitchSection = (id, type, onSubmit) => {
  _setLoading(true)
  return offerApi.deletePitchSection(id, type).then(
    response => {
      if(onSubmit) onSubmit()
      __deletePitchSection(type + '_section');
      ToastMessage.success("Section deleted successfully");
      _setLoading(false);
    },
    error => {
      ToastMessage.error("Failed to delete section");
      _setLoading(false)
      console.error(error)
    }
  )
}

export const fetchCloseableInvestments = (offer_slug) => {
  __setCloseableInvLoading(true)
  offerApi.fetchCloseableInvestments(offer_slug).then(
    res => {
      __setCloseableInvestments(res);
      __setCloseableInvLoading(false)
    },
    error => {
      __setCloseableInvLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const fetchClosedInvestments = (offer_slug) => {
  __setClosedInvLoading(true)
  offerApi.fetchClosedInvestments(offer_slug).then(
    res => {
      __setClosedInvestments(res);
      __setClosedInvLoading(false)
    },
    error => {
      __setClosedInvLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}

export const closeInvestments = (offer_slug, attrs) => {
  _setLoading(true)
  offerApi.closeInvestments(offer_slug, attrs).then(
    res => {
      __updateClosedInvestment(res);
      __updateCloseableInvestments(attrs.close.investment_ids)
      _setLoading(false)
    },
    error => {
      _setLoading(false)
      ToastMessage.error("Something went wrong. Please try again!")
    }
  )
}
