import React, {useContext} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { liveEventApi } from 'api/liveEvent';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import StatusUtil from '../../../components/LiveEvent/StatusUtil';
import { FaRegClock, FaVideo, FaVideoSlash, FaStopCircle } from "react-icons/fa";
import Tooltip from '../../../pages/components/Tooltip/Tooltip';

const STATUS_ICONS  = {
  "pre_live": <FaRegClock />,
  "live": <FaVideo />,
  "closed": <FaVideoSlash />,
  "completed": <FaStopCircle />
}


export default function StatusUpdateButton({ liveEvent, offerId}) {
  const queryClient = useQueryClient();
  const confirmationModal = useContext( PortalizedModalContext );

  const statusUpdateHandler  = (nextStatus) => {
    const title = nextStatus.text;
    const message = `Are you sure you want to ${nextStatus.text}?`

    confirmationModal.setModalContent(
      title,
      <>
        <p>{message}</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              updateStatus.mutate({ id: liveEvent.id, attrs: { status: nextStatus.status } });
              confirmationModal.closeModal();
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => confirmationModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    confirmationModal.openModal();
  }

  const updateStatus = useMutation(liveEventApi.update ,
    {
      onSuccess: (values) => {
        ToastMessage.success(`Event ${values.title} is ${StatusUtil.statusTitle(values.status)}.`, {
          toastId: "LIVE_EVENT_STATUS"
        });

        const liveEvents = queryClient.getQueryData(['liveEvents', offerId]);
        const updatedEventindex = liveEvents.findIndex((e) => e.id === liveEvent.id);

        if(liveEvents[updatedEventindex]){
           liveEvents[updatedEventindex] = values;
         }

        queryClient.setQueryData(['liveEvents', offerId], liveEvents);
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  )

  const nextStatus = StatusUtil.nextStatus(liveEvent.status);

  if(nextStatus){
    return (
       <Tooltip xAlignment="0px" yAlignment="50%" text={nextStatus.text} className="single-action-tooltip">
        <button className="btn btn-sm btn-link single-action" onClick={ () => {
          statusUpdateHandler(nextStatus);
        }}
        >
          {STATUS_ICONS[nextStatus.status]}
        </button>
       </Tooltip>
    )
  }

  return <></>;
}