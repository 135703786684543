import React, { useState, useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastMessage from "../../../components/ToastMessage";
import { useMutation, useQueryClient } from "react-query";
import TextField from "components/HookForm/TextInput";
import CheckboxSwitchField from "components/HookForm/CheckboxSwitchField";
import TextArea from "components/HookForm/TextArea";
import FileSelect from "components/HookForm/FileSelect";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import _ from 'underscore.string'
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import {
  addPitchSection,
  updatePitchSection,
  deletePitchSection,
} from "./queries";
import {
  editIcon,
  closeIcon,
} from "../../../assets/images/index";
import LoadingOverlay from "react-loading-overlay";
import { usePortalizedModal } from "../../../components/PortalModal";
import { useParams } from "react-router-dom";

  const Investor =({investor, investorEdithandler, investorDeletehandler})=>(
    <div key={investor.id} className="single-existing-notable-inv mb-3">
      <div className="single-existing-pitch-content">
        <div className="existing-notable-inv-icon-box">
          <img src={investor.photo_url} alt="icon" />
        </div>
        <div className="existing-notable-inv-content-box">
          <div className="inner1">
            <p className="existing-notable-inv-title poppins weight-700 mb-0">
              {investor.name}
            </p>
            <span className="existing-notable-inv-subtitle poppins fs-10">
              {_.prune(investor.title, 25, '...')}
            </span>
            <span className="existing-notable-inv-subtitle poppins fs-10">
              {investor.current ? "Current" : "Past"}
            </span>
          </div>
        </div>
      </div>
      <div className="existing-pitch-actions">
        <img
          onClick={() => investorEdithandler(investor) }
          src={editIcon}
          alt="edit icon"
        />
        <img
          onClick={() => investorDeletehandler(investor.id) }
          src={closeIcon}
          alt="close icon"
        />
      </div>
    </div>
  )

const notableValidationSchema = Yup.object().shape({
  notable_investor_name: Yup.string().required("Name is required.").max(50, 'Name max length is 50 characters'),
  notable_investor_title: Yup.string().required("Title is required.").max(40, 'Title max length is 40 characters'),
  investor_photo: Yup.object()
    .required("Investor photo is required.")
    .typeError("Investor photo is required."),
  quote: Yup.string(),
  current: Yup.boolean().required('Investor status is required.').default(false),
});

const NotableInvestors = ({ pitch:{notable_investors=[], pitchable_id} }) => {
  const { offerSlug } = useParams();
  const deleteInvestorModal = usePortalizedModal("deleteNotableInvestorConfirm");
  const [investorForEdit, setInvestorForEdit] = useState(null);
  const queryClient = useQueryClient();

  useEffect(()=>{
    if(investorForEdit){
      methods.setValue("notable_investor_name",investorForEdit.name)
      methods.setValue("notable_investor_title", investorForEdit.title)
      methods.setValue("investor_photo",{ data : investorForEdit.photo_url })
      methods.setValue("quote", investorForEdit.quote)
      methods.setValue("current", investorForEdit.current)
    }else{
      methods.reset()
    }
  },[investorForEdit])

  const deleteInvestor = useMutation(deletePitchSection, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin', 'offers', offerSlug, 'pitch']);
      ToastMessage.success(`Notable investor deleted successfully.`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function investorDeletehandler (id){
    deleteInvestorModal.open({
      title: 'Delete Investor',
      body: <>
        <p>Are you sure you want to delete the investor?</p>
        <div class="portalized-modal-footer">
          <button class="btn-black"
            onClick={() => {
              deleteInvestor.mutate({
                id: id,
                sectionType: "notable_investor",
              });
              if(id === investorForEdit?.id)
                setInvestorForEdit(null);
              deleteInvestorModal.close()
            }}>Confirm</button>
          <button class="btn-black-outline" onClick={deleteInvestorModal.close}>Cancel</button>
        </div>  
      </>,
      size: 'sm',
      onRequestClose: deleteInvestorModal.close,
    });
  }

  const saveNotableInv = useMutation(
    investorForEdit ? updatePitchSection : addPitchSection,
    {
      onSuccess: (values) => {
        methods.reset()
        investorForEdit && setInvestorForEdit(null);
        queryClient.invalidateQueries(['admin', 'offers', offerSlug, 'pitch']);
        ToastMessage.success(`Notable Investor ${investorForEdit ? 'updated' : 'added'} successfully.`);
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  );

  const onSubmit = (values) => {
    let id = investorForEdit?.id ?? offerSlug;
    let pitch_section = {
      name: values.notable_investor_name,
      title: values.notable_investor_title,
      quote: values.quote,
      current: values.current,
      photo: values.investor_photo
    }
    if(investorForEdit?.photo_url === values.investor_photo.data)
      delete pitch_section.photo

    saveNotableInv.mutate({
      id: id,
      values: {
        type: "notable_investor",
        pitch_section: pitch_section,
      },
    });
  };

  const methods = useForm({
    resolver: yupResolver(notableValidationSchema),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      notable_investor_name: "",
      notable_investor_title: "",
      investor_photo: null,
      quote: "",
      current: false,
    },
  });

  return (
    <LoadingOverlay active={saveNotableInv.isLoading || deleteInvestor.isLoading} spinner text="Loading">
      <div className="notable-inv-maker">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="add-notable-inv mb-4"
          >
            <h6 className="fs-10 montserrat weight-600">
              Add new notable investor
            </h6>
            <TextField
              name="notable_investor_name"
              placeholder="Investor name"
              type="text"
            />
            <TextField
              name="notable_investor_title"
              placeholder="Investor title"
              type="text"
            />
            {/* fileType is used to restrict the type of files to be selected
              types = image/*, audio/* , video/*
            */}
            <FileSelect
              name="investor_photo"
              placeholder="Upload investor image"
              fileType="image/*"
            />
            <TextArea name="quote" placeholder="Quote" type="text" />
            <CheckboxSwitchField  name="current" placeholder="Invested in Current Round" />
            <button type="submit" className="btn-black">
              {investorForEdit
                ? "UPADTE NOTABLE INVESTOR"
                : "ADD NOTABLE INVESTOR"}
            </button>
            {investorForEdit && (
              <button
                onClick={() => {
                  methods.reset()
                  setInvestorForEdit(null);
                }}
                type="button"
                className="btn-black w-25 ml-3"
              >
                CANCEL
              </button>
            )}
          </form>
        </FormProvider>
        {notable_investors.length > 0 && 
          <div className="existing-notable-inv">
            <h6 className="fs-10 montserrat weight-600">
              Existing notable investor
            </h6>
            {notable_investors.map((investor) => (
              <Investor
                key={investor.id}
                investor={investor}
                investorEdithandler={setInvestorForEdit}
                investorDeletehandler={investorDeletehandler}
              />
            ))}
          </div> }
      </div>
    </LoadingOverlay>
  );
};

NotableInvestors.defaultProps = {};

NotableInvestors.propTypes = {
  offer_id: PropTypes.string.isRequired,
  pitch: PropTypes.object.isRequired,
};

export default NotableInvestors;
