import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { FaTrash } from 'react-icons/fa';
import HookForm from 'components/HookForm';
import { platformLiveEventApi } from 'api/admin/platformLiveEvent';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { parseDate } from 'jsUtils/dateTimeUtils';
import { statusOptions } from '../../../components/LiveEvent/StatusUtil';
import ToastMessage from '../../../components/ToastMessage';

const FormSchema = Yup.object().shape({
  title: Yup.string().required().max(200, `Maximum 200 characters allowed`),
  description: Yup.string().required().max(500, `Maximum 500 characters allowed`),
  status: Yup.string().oneOf(['upcoming', 'pre_live', 'live', 'closed', 'completed']).default('upcoming'),
  scheduled_time: Yup.date().required(),
  video_url: Yup.string().nullable().when('status', {
    is: (status) => status !== 'upcoming',
    then: Yup.string().required().url('enter valid youtube url'),
    otherwise: Yup.string().optional().url('enter valid youtube url'),
  }),
  listed_at: Yup.mixed(),
});

function transformFieldValues(data, forForm = false) {
  const values = {
    title: data.title,
    description: data.description,
    status: data.status,
    scheduled_time: data.scheduled_time,
    video_url: data.video_url,
    listed_at: data.listed_at,
  };

  if (forForm) {
    values.scheduled_time = parseDate(values.scheduled_time);
    values.listed_at = values.listed_at ? parseDate(values.listed_at) : '';
  }
  else {
    values.listed_at = values.listed_at == "" ? null : values.listed_at;
  }

  values.preview_image = data.preview_image;

  return values;
}

export default function Form({ liveEvent, afterCreate, page }) {
  useEffect(() => {
    if (liveEvent) {
      methods.reset(transformFieldValues(liveEvent, true));
    }
  }, [liveEvent])

  const isEditForm = liveEvent.id;
  const defaultValues = {
    title: '',
    description: '',
    status: 'upcoming',
    scheduled_time: null,
    video_url: '',
    listed_at: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: defaultValues
  });

  const queryClient = useQueryClient();
  const saveLiveEvent = useMutation(
    isEditForm ? platformLiveEventApi.update : platformLiveEventApi.create,
    {
      onSuccess: (values) => {
        queryClient.invalidateQueries(['platformLiveEvents']);
        ToastMessage.success(
          `SV Live event successfully ${liveEvent ? 'updated' : 'added'}.`
        );
        if(afterCreate) { afterCreate(values) };
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  )

  const onSubmit = data => {
    saveLiveEvent.mutate({
      id: liveEvent.id,
      attrs: transformFieldValues(data)
    })
  };

  const deletePreviewImage = useMutation(platformLiveEventApi.deletePreviewImage, {
    onSuccess: (values) => {
      queryClient.invalidateQueries(['platformLiveEvents']);
    },
    onError: (error) => {
      renderRailsErrors(error);
    },
  });

  return (
    <div className="settings-tab-box">
      <div className="row col-12">
        <div className="col-6 mini-container">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1" autoComplete="off">
              <div className="row mt-3">
                <div className="col input-bg-white">
                  <HookForm.TextInput name="title" placeholder="Title" />
                </div>
              </div>
              <div className="row">
                <div className="col input-bg-white">
                  <HookForm.TextArea name="description" placeholder="Description" rows={6} />
                </div>
              </div>
              <div className="row">
                <div className="col-6 input-bg-white">
                  <HookForm.DateTimeField name="scheduled_time" placeholder="Scheduled Time" />
                </div>
                <div className="col-6 input-bg-white">
                  <HookForm.SelectInput
                    name="status"
                    placeholder="Status"
                    options={statusOptions()}
                    labelClass="z-index-1000"
                    className="ml-1"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col input-bg-white">
                  <HookForm.TextInput name="video_url" placeholder="Youtube/Streamyard embed URL" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <HookForm.FileSelect
                    name="preview_image"
                    placeholder="Upload Preview image"
                    fileType="image/jpeg,image/png"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col input-bg-white">
                  <HookForm.CheckboxListedAtField name="listed_at" placeholder="Unlisted" tagline="Invite Only (Private/Unlisted)" />
                </div>
              </div>
              <div className="form-group mt-3">
                <button type="submit"
                  className="mx-auto zeitung-mono weight-300 btn btn-outline-dark rounded-0"
                  disabled={saveLiveEvent.isLoading}
                >
                  {isEditForm ? 'Update' : 'Add'}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="col-6 mini-container">
          {liveEvent?.preview_image_url && (
            <div>
              <h5>Preview Image</h5>
              <img src={liveEvent.preview_image_url} alt="preview" width={400} />
              <span onClick={ () => {
                deletePreviewImage.mutate(liveEvent.id)
              }}><FaTrash /></span>
            </div>)
          }
        </div>
      </div>
    </div>
  );
}
