import React from 'react';
import { Switch, Route, useRouteMatch, NavLink, Redirect } from "react-router-dom"
import AccountSettings from './AccountSettings';
import MyInvestments from './MyInvestments';
import './Settings.scss';
import { AuthContext } from './../../../contexts/authContext'
import InvestorSettings from './InvestorSettings';
import MyProfileOverview from './MyProfileOverview';
import PrivateInvites from './PrivateInvites';

export default function Settings() {
  let { url, path } = useRouteMatch();
  const { isAdmin } = React.useContext(AuthContext);

  return (
    <>
      <div className='settings-banner'>
        <div className='settings-banner-container'>
          <MyProfileOverview />
          <ul className="nav nav-tabs" id="settings-tabs" role="tablist">
            {
              !isAdmin() &&
                <li className="nav-item">
                  <NavLink to={`${url}/investments`} className="nav-link" activeClassName="active">
                    PORTFOLIO
                  </NavLink>
                </li>
            }
            {
              !isAdmin() && 
                <li className="nav-item">
                  <NavLink to={`${url}/investor-info`} className="nav-link" activeClassName="active">
                    INVESTOR INFO
                  </NavLink>
                </li>
            }
            <li className="nav-item">
              <NavLink to={`${url}/settings`} className="nav-link" activeClassName="active">
                SETTINGS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`${url}/private-invites`} className="nav-link" activeClassName="active">
                INVITES
              </NavLink>
            </li>
          </ul>
        </div>
      </div> 
      
      <div className="tab-content zeitung-micro" id="myTabContent">
        <Switch>
          {!isAdmin() && <Route path={`${path}/investments`} component={MyInvestments} />}
          {!isAdmin() && <Route path={`${path}/investor-info`} component={InvestorSettings} />}
          <Route path={`${path}/settings`} component={AccountSettings} />
          <Route path={`${path}/private-invites`} component={PrivateInvites} />
          <Redirect to={`${path}/settings`} />
        </Switch>
      </div>
    </>
  )
}
