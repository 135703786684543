import React, { useEffect } from 'react';
import { userApi } from 'api/user/';
import {useUser, _updateUser, _setAuthStatus} from 'redux/user'
import LoadingOverlay from 'react-loading-overlay';
import { ACTIONS, permittedActions } from 'config/adminAbilities';
import { history } from 'redux/helpers'
import { setHubspotIdentity } from '../pages/components/Tracking/hubspot';

const AuthContext = React.createContext({currentUser: {}});

const AuthProvider = ({ children }) => {
  // const [loading, setLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const {authenticated, email} = useUser()
  const [authData, setAuth] = React.useState({
    currentUser: {},
    isAuthenticated: false,
    isAdmin: false,
    permittedActions: new Set(),
  });

  React.useEffect( () => {
      setLoading(true)
      userApi.me({hideToast: true, from: "authContext"}).then(
        user => {
          setCurrentUser(user)
          _updateUser(user)
          _setAuthStatus(true)
          setLoading(false)
          setHubspotIdentity({ userId: user.id, email: user.email });
        },
        error => {
          setAuth({
            ...authData,
            currentUser: {},
            isAuthenticated: false,
            isAdmin: false,
            permittedActions: new Set(),
          })
          setLoading(false)
          console.error(error)
        }
      )
  }, []);

  React.useEffect(
    () => {
      // redux has email for authenticated user from start of login session,
      // but subsequently got a 419(indicated invalid/expired auth cookie) setting [redux/user]authenticated to false
      // -- invalidating the session on browser
      if(email && authenticated == false) {
        clearAuthContext()
        history.push('/login');
      }
    },
    [authenticated]
  )

  const setCurrentUser = (user) => {
    setAuth({
      ...authData,
      currentUser: user,
      isAuthenticated: true,
      isAdmin: user?.roles.some(r => r.category === "moderator") || false,
      permittedActions: permittedActions(user.roles),
    });
  };

  const clearAuthContext = () => {
    setAuth({
      ...authData,
      currentUser: {},
      isAuthenticated: false,
      isAdmin: false,
      permittedActions: new Set(),
    })
  }

  const isAdmin = () => {
    return authData.isAdmin
  }

  const isUserLoggedin = () => {
    return authData.isAuthenticated
  }

  return (
    <AuthContext.Provider value={{ authData, setCurrentUser, isAdmin, clearAuthContext, isUserLoggedin }}>
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading ...'
      >
      {!loading && children}
      </LoadingOverlay>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext }
