import React from "react";
import { FaFireAlt } from "react-icons/fa";
import { subDays } from "date-fns";
import { distanceFromNow } from "../jsUtils/dateTimeUtils";

const RemainingTimeBadge = ({ closeDate, prefix = "" }) => {
  const today = new Date();
  const offerCloseDate = new Date(closeDate);
  const isClosingSoon =
    subDays(offerCloseDate, 10) <= today && offerCloseDate >= today;
  return (
    <div>
      {isClosingSoon && <FaFireAlt />}
      {prefix}
      <span>{distanceFromNow(offerCloseDate, isClosingSoon)} left</span>
    </div>
  );
};

export default RemainingTimeBadge;
