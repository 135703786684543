import React, { useState, useEffect, useRef } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import formatMoney from "accounting-js/lib/formatMoney.js";
import confetti from "canvas-confetti";
import ReactTooltip from "react-tooltip";
import { progressBarPercentage, raisedFundsPercentage } from '../../../jsUtils/offerUtils';
import InvestmentButton from '../../../admin/components/OfferView/InvestmentButton';
import { usePusherChannel } from '../../../contexts/PusherChannelContext';

export default function GoalProgessBar({offer, user}){
  const pusherChannel = usePusherChannel(offer.live_event.channel_id);
  const company = offer.company;
  const liveEvent = offer.live_event;
  const [liveStats, setLiveStats] = useState({
    counterStartValue: parseInt(liveEvent.raised_amount),
    raisedAmount: parseInt(liveEvent.raised_amount),
    investmentAmount: 0,
    userTotalInvestment: offer.investments?.reduce((sum, investment) => sum + parseFloat(investment.amount), 0),
  });
  const raisedAmountRef = useRef(null);
  const tooltipRef = useRef(null);
  const toggleTooltipView = ()=> {
    const knob = tooltipRef.current.getElementsByClassName('knob')[0];

    if(knob.style.display == 'none') {
      knob.style.display = 'block';
    } else {
      knob.style.display = 'none';
    }
  }

  useEffect(() => {
    pusherChannel.bind('new-investment', showLatestInvestment);

    return () => {
      pusherChannel.unbind('new-investment', () => {});
    }
  }, []);

  const showLatestInvestment = (data) => {
    setLiveStats({
      counterStartValue: (parseInt(data.live_event.raised_amount) - parseInt(data.user_investment.amount)),
      raisedAmount: parseInt(data.live_event.raised_amount),
      investmentAmount: parseInt(data.user_investment.amount),
      userTotalInvestment: (user?.id == data.user_investment.user_id && data.user_investment.total_investment),
    });

    tooltipRef.current.dispatchEvent(new CustomEvent('show-new-investment'));
    toggleTooltipView();
    setTimeout(() => {
      ReactTooltip.hide(tooltipRef.current);
      toggleTooltipView();
    }, 4000);

    const raisedAmountEleBounds = raisedAmountRef.current.getBoundingClientRect();
    showConfetti({
      y: (raisedAmountEleBounds.bottom / window.innerHeight).toFixed(2),
      x: ((raisedAmountEleBounds.left + (raisedAmountEleBounds.width /2)) / window.innerWidth).toFixed(2)
    });
  }

  const showConfetti = ({x, y}) => {
    confetti({
      particleCount: 70,
      spread: 90,
      origin: { y: y, x: x },
      gravity: 0.3,
      colors: ['#fff', '#eee',  '#f5f5f5', '#cccccc', '#888888']
    });
  }

  const fundsPercentage = raisedFundsPercentage(liveStats.raisedAmount, liveEvent.goal_amount) || 0;

  return(
    <div className='d-flex flex-column pt-3 pb-4'>
      <div className='d-flex flex-wrap px-2'>
        <div className='col-10 pl-0'>
          <span className="align-middle weight-900 fs-7 mr-3">{company.name} </span>
        </div>
        <div className='col-2 pr-0 text-right'>
          {company.url && <a className="company-link fs-default" target="_blank" rel="noopener noreferrer" href={company.url}>{<FaExternalLinkAlt className='fs-8 color-white'/>}</a> }
        </div>
      </div>
      <div className='px-2 mb-2'>
        <span className='weight-900 fs-18' ref={raisedAmountRef}>
          {formatMoney(liveEvent.goal_amount, {precision : 0})} EVENT TARGET
        </span>
        {
          liveStats.investmentAmount > 0 &&
          <span className='fs-default poppins float-right'>
            <span className="color-success align-middle mr-1">Latest:</span>
            <span className="align-middle">{formatMoney(liveStats.investmentAmount, {precision : 0})}</span>
          </span>
        }
      </div>
      <div className='px-2 mb-4'>
        <ReactTooltip id="new-investment" effect="solid" type="dark" className="new-investment-tooltip">
          <span className="d-block fs-14 weight-400 poppins text-center">New Investment</span>
          <span className="d-block fs-18 weight-900 montserrat text-center">{formatMoney(liveStats.investmentAmount, {precision : 0})}</span>
        </ReactTooltip>
        <div className="progress-box">
          <div className="progressbar">
            <div style={{width: `${progressBarPercentage(fundsPercentage)}%`}} className="progress">
              <span className="float-right" data-tip data-for="new-investment" data-event="show-new-investment" data-place="top" ref={tooltipRef}>
                <span className="knob"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='px-2'>
        <InvestmentButton offer={offer} user={user} cssClass="mt-0" openNewWindow={true}/>
        <div className="fs-14 poppins mt-2">
          <span className={ liveStats.userTotalInvestment > 0  ? 'mr-2' : '' }>{ liveStats.userTotalInvestment > 0 && `You invested: ${formatMoney(liveStats.userTotalInvestment, { precision : 0 })}`}</span>
          <span className="text-muted">{formatMoney(offer.deal.minimum_investment, {precision: 0})} Min. Investment</span>
        </div>
      </div>
    </div>
  )
}