import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect } from 'react-table';
import { isEqual } from 'lodash';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <label className="custom-checkbox2">
          <input ref={resolvedRef} {...rest} type="checkbox" className="mr-2" />
          <span className="custom-checkbox-check"></span>
        </label>
      </>
    );
  }
);

const useSelectedRows = (data, setData) => {
  const [rows, setRows] = React.useState(data)

  useEffect(() => {
    if (!isEqual(data, rows)) {
      setData(data)
      setRows(data)
    }
  }, [data])
}

function ResponsiveTableWithRowSelect({
  columns,
  data,
  setSelectedRows,
  onClickRow,
  selectableId = 'id',
  loading = false,
  loadingMsg="Loading data from rstable <update me before commit>...",
  noDataMsg = "No data available.",
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    { 
      columns,
      data 
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          className: "row-select",
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
    });

    useSelectedRows(selectedFlatRows?.map(row => row.original[selectableId]), setSelectedRows)

    const rowClickHandler = React.useCallback(
    (row) => {
      onClickRow && onClickRow(row)
    },
    [onClickRow]
  );

  if (loading)
    return <div className='d-flex justify-content-center align-items-center p-3'>{loadingMsg}</div>

  if (rows.length < 1 )
    return <div className='d-flex justify-content-center align-items-center p-3'>{noDataMsg}</div>

  return (
    <div className="bg-lightgrey">
      <div className="table-responsive px-3 action-table">
        <table {...getTableProps()} className="table table-borderless table-hover table-row-select">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps([
                      {
                        className: `py-0 fs-10 ${column.className}`,
                        style: column.style,
                      },
                    ])} 
                    scope="col" 
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => rowClickHandler(row)} className="f-black fs-10 bg-white">
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

ResponsiveTableWithRowSelect.defaultProps = {};

ResponsiveTableWithRowSelect.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onClickRow: PropTypes.func,
  setSelectedRows: PropTypes.func
};

export default ResponsiveTableWithRowSelect;