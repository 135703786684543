import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from 'redux/user';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter Password')
    .min(8, 'At least 8 characters required'),
  confirm_password: Yup.string()
    .required('Please Enter Confirm Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default function ForgotPasswordForm({ token }) {

  return (
    <div id="loginForm" className="container m-width-medium mt-30 mb-30">
      <h1 className="weight-900 fs-1">Reset Password</h1>
      <Formik
        initialValues={{
          password: '',
          confirm_password: ''
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={async (values, { setSubmitting }) => {
          values.token = token
          resetPassword(values)
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form className="py-3 px-0 px-md-5">
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                name="password"
                id="password"
                placeholder="password"
              />
              <label htmlFor="password">Password</label>
              <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
            </div>
            <div className="form-label-group">
              <Field type="password"
                className={`form-control ${touched.confirm_password && errors.confirm_password ? "is-invalid" : ""}`}
                name="confirm_password"
                id="confirm_password"
                placeholder="confirm_password"
              />
              <label htmlFor="confirm_password">Confirm Password</label>
              <ErrorMessage name="confirm_password" component="div" className="invalid-feedback text-left" />
            </div>
            <div className="vertical-space-50"></div>
            <button type="submit" id="loginBtn" className="mx-auto zeitung-mono weight-300 btn btn-block btn-dark" disabled={isSubmitting}>
              Reset Password
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
