import React from 'react'

export default function ButtonOutline(props) {
  const { text, onClick, disabled, icon, theme='light', additionalClasses=''} = props

  return (
    <button
      className={`weight-300 btn ${theme == 'light' ? 'btn-outline-light' : 'btn-outline-dark'} rounded-0 d-inline-flex justify-content-center align-items-center ${additionalClasses}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon} {text} 
    </button>
  )
}