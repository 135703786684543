import React, { useContext } from 'react'
import { ResponsiveTable } from 'admin/components'
import { useQuery, useQueryClient, useMutation } from "react-query";
import { FaEye, FaEnvelope, FaTrash } from "react-icons/fa";
import ToastMessage from '../../components/ToastMessage';
import { PortalizedModalContext } from '../../contexts/portalizedModalContext';
import TableActionsContainer from './TableActionsContainer';
import TableActionItem from './TableActionItem';
import { getMaterialChanges, cancelAllMaterialChange, createMaterialChange, sendMaterialChangeReminder, getAllMaterialChangeAnnoucements } from '../queries/index'
import OverviewSubheader from './OverviewSubheader';
import TextButton from 'admin/components/TextButton';
import { formatDate, formatDateWithoutTimezone } from '../../jsUtils/dateTimeUtils';
import { renderRailsErrors } from '../../jsUtils/railsErrorRenderer';
import MaterialChangeAnnouncements from './MaterialChangeAnnouncements';
import MaterialChangeCreator from './MaterialChangeCreator'


export default function MaterialChanges ({offer}) {
  const offerId = offer?.slug;
  const queryClient = useQueryClient();
  const sendReminderModal = useContext( PortalizedModalContext );
  const showAllMCModal = useContext( PortalizedModalContext );
  const newMCModal = useContext( PortalizedModalContext );
  const cancelModal = useContext( PortalizedModalContext );

  const materialChangesQuery = useQuery({
    queryKey: ["material_changes"],
    queryFn: () => getMaterialChanges(offer.slug).then((res) => res.data)
  })

const {isLoading, isError, data, error } = materialChangesQuery;

const sendReminderMutation = useMutation(sendMaterialChangeReminder, {
  onSuccess: () => {
    queryClient.invalidateQueries(["material_changes"])
    ToastMessage.success('Material change reminder sent');
  },
  onError: (error) => {
    renderRailsErrors(error);
  }
})

const cancelAllPendingInvestments = useMutation(cancelAllMaterialChange, {
  onSuccess: (data) => {
    queryClient.invalidateQueries(["material_changes"])
    ToastMessage.success('Pending Investments Cancelled');
  },
  onError: (error) => {
    renderRailsErrors(error);
  }
})

const showAllAnnoucements = (value) => {
    showAllMCModal.setModalContent(
      'All Investments',
      <>
        <MaterialChangeAnnouncements id={value} />
      </>,
      'md'
    )  
  showAllMCModal.openModal();
}

const cancelInvestmentsConfirmation = (value) => {
  cancelModal.setModalContent(
    'Cancel All Pending Investments',
    <>
        <p>Are you sure you want to cancel all pending Investments in this Material Change?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              cancelAllPendingInvestments.mutate(value)
              cancelModal.closeModal();
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => cancelModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
  )  
  cancelModal.openModal();
}

const newMaterialChange = (value) => {
  newMCModal.setModalContent(
      'New Material Change',
      <>
      <MaterialChangeCreator offerId={offerId} onCreateSuccess={newMCModal.closeModal} />
      </>,
      'md'
    )  
  showAllMCModal.openModal();
}

const sendReminderEmails = (value) => {
  sendReminderModal.setModalContent(
      'Send Reminder Email',
      <>
        <p>Are you sure you want to send a reminder for this Material Change?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              sendReminderMutation.mutate(value)
              sendReminderModal.closeModal();
            }}>Confirm</button>
          <button className="btn-black-outline" onClick={() => sendReminderModal.closeModal()}>Cancel</button>
        </div>
      </>,
      'sm'
    );
    sendReminderModal.openModal();
}

  const columns = [
    {
     Header: 'description',
     accessor: 'description'
   },{
     Header: 'status',
     accessor: 'status'
   },   {
    Header: 'Date Created',
    accessor: 'created_at',
    Cell: ({value}) => {return formatDate(value) || ''}
  },
  {
    Header: 'Deadline',
    accessor: 'deadline',
    Cell: ({value}) => formatDateWithoutTimezone(value) || ''
  },
  {
    Header: '',
    accessor: 'id',
    Cell: ({value, row}) => {
      return(
        <TableActionsContainer>
          { !row.original.resourceable_type && <>
              <TableActionItem tooltipText="Show ALl" onClick={ ()=> showAllAnnoucements(value) }>
                <FaEye className='fs-14 color-black'/>
              </TableActionItem>
              <TableActionItem disabled={row.original.status === "complete" ? true : false} tooltipText="Send Reminder" onClick={ ()=> sendReminderEmails(value)}>
                <FaEnvelope className='fs-14 color-black'/>
              </TableActionItem>
              <TableActionItem disabled={row.original.status === "complete" ? true : false} tooltipText="Cancel All" onClick={ ()=> cancelInvestmentsConfirmation(value)}>
                <FaTrash  className='fs-14 color-black'/>
              </TableActionItem>
            </>
          }
        </TableActionsContainer>
      )
    }
  }

  ]

  if ( isLoading) return <h1>Loading...</h1>
  if ( isError) {
    ToastMessage.error(error?.message ?? "Something went wrong")
    return <pre>{JSON.stringify(error)}</pre>
  }

  return (
    <div>
      <OverviewSubheader left='Material Changes' right={<TextButton title='New Material Change' onClick={ () => newMaterialChange()} />}/>
      <div className="action-table">
        <ResponsiveTable
          columns={columns}
          data={ data || []}
          loading={ isLoading}
          loadingMsg="Loading material changes..."
          noDataMsg="No Material Changes have been posted yet."
        />
      </div>     
    </div>
  )
}