import React from 'react'

function PaymentsDisclaimer() {  
    return (
      <div className="poppins terms-services-container">
        <div className="container lg-main-section lh-md ">
          <div className='fs-7 weight-700 mb-2'>
            Introduction to Electronic Funds Transfers
          </div>
          <div>
            <p>
              Spaced Ventures processes Electronic Funds Transfers (EFTs) as an efficient method to receive electronic deposits from investors,
              to purchase and redeem investments and to issue refunds to investors.
              EFTs are processed through the vehicles described below:
            </p>
            
            <ul className='ml-4'>
              <li>The Automated Clearing House (ACH)</li>
              <li>The Fedwire</li>
              <li>Credit Card</li>
            </ul>
          </div>

          <div className='fs-7 weight-700 mt-5 mb-2'>
            Electronic Funds Transfers Agreement and Disclosure
          </div>
          <div>
            <p>
              This Electronic Funds Transfers Agreement and Disclosure (this Agreement) is the contract which covers you and our
              rights and responsibilities concerning the EFTs services offered to you by Spaced Ventures. In this Agreement, 
              the words “you,” “your,” and “yours” mean an individual who signs up on the Spaced Ventures Online Platform 
              (Platform) and utilizes the Platform to participate in offerings (and any authorized users). The words “we,” 
              “us,” and “our” mean Spaced Ventures.  EFTs are electronically initiated transfers of money from your bank 
              account or credit card account, which you utilize the Platform to initiate, and which allow you to participate 
              in the Spaced Ventures offerings. By using any Spaced Ventures service, you agree to the terms and conditions in 
              this Agreement and any amendments for the EFTs services offered.
            </p>
            <p>
              In order to use the payment functionality of Spaced Ventures, you authorize us to share your identity, 
              bank account data and credit card account data with North Capital Private Securities (NCPS), 
              a broker-dealer registered with the Securities and Exchange Commision and the Financial Industry 
              Regulatory Authority, Inc., and with North Capital Investment Technology (NCIT) for the purpose of 
              processing your EFTs, and you are responsible for the accuracy and completeness of that data. You 
              understand that you will access and are responsible for managing your bank account data and credit 
              card account data through the Platform. You also authorize us and NCPS and NCIT to process EFTs as 
              you direct through Spaced Ventures. You acknowledge that none of NCPS, NCIT or we will be liable for 
              any loss, expense or cost arising out of EFT services provided through your use of Spaced Ventures, 
              which are based on your instruction; NCPS and NCIT are third party beneficiaries to this Agreement.
            </p>
          </div>
        </div>
      </div>
    );
  
}

export default PaymentsDisclaimer;