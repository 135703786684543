import React from 'react';
import { createEditorStateWithContent } from './editorUtils';
import RichEditor from '.';

const Renderer = (props) => {
  let content = props.content;

  if(props.maxChars){
    content = { blocks: [], entityMap: props.content.entityMap };
    let charCounter = 0;

    props.content.blocks.forEach(b => {
      if(charCounter <= props.maxChars){
        content.blocks.push(b);
        charCounter = charCounter + b.text.length;
      }
    });
  }

  return (
    <RichEditor
      editorState = { createEditorStateWithContent(content) }
      readOnly={true}
      backgroundColor={props.backgroundColor || '#f7f7f7'}
    />
  )
}

export  default Renderer;