import React from 'react';
import { useFormikContext } from 'formik';
import { rollbarInstance } from 'jsUtils/rollbar';

export default function FormikValidationsRollbarReporter({form}) {
  const { isValid, errors } = useFormikContext()

  React.useEffect(() => {
    if (!isValid) {
      rollbarInstance.warning("Form validation errors", {form, errors})
    }
  }, [isValid, errors])

  return null
};
