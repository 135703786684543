import React from "react";
import CustomContentLoader from "../admin/components/shared/CustomContentLoader";
export default function OfferSectionLoader({}) {
  return (
    <div className="live-offers-promo mb-4">
      <CustomContentLoader
        loading={true}
        width={500}
        height={53}
        listCount={1}
        shape={
          <>
            <rect x="0" y="1" rx="2" ry="2" width="170" height="36" />
            <rect x="0" y="43" rx="2" ry="2" width="300" height="23" />
          </>
        }
      />
    </div>
  );
}
