import React  from 'react'
import { statusLabel } from './StatusUtil'

export default function LiveEventIndicator ({liveEvent}) {
  return (
    <div className="live-event-indicator">
      { statusLabel(liveEvent.status) }
    </div>
  )
}
