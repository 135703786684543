import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';

const useOfferList = () => {
  return useQuery(
    ['Investor::offers', 'live_or_closed'],
    () => {
      return axiosClient
        .get(`/offers/grouped_by_status`);
    }, {
    staleTime: 0,
  });
}

const usePreofferList = () => {
  return useQuery(
    ['Investor::preoffers', 'live_or_closed'],
    () => {
      return axiosClient
        .get(`/preoffers/grouped_by_status`);
    }, {
    staleTime: 0,
  }
  );
}

const usePreoffer = (slug) => {
  return useQuery(
    ['preoffer', slug],
    () => {
      return axiosClient
        .get(`/preoffers/${slug}`)
    }
  );
}

const usePreofferPitch = (preoffer_id) => {
  return useQuery(
    ['preoffer#pitch', preoffer_id],
    () => {
      return axiosClient
        .get(`/preoffers/${preoffer_id}/pitch`)
    }
  );
}

const usePreofferNDARequirement = (slug) => {
  return useQuery(
    ['preoffer#nda_requirement', slug],
    () => {
      return axiosClient
        .get(`/preoffers/${slug}/nda_requirement`)
    },{
      staleTime: 0,
    }
  );
}


const usePreofferSubscription = (subscriptionId) => {
  return useQuery(
    ['preoffer#subscription', subscriptionId],
    () => {
      return axiosClient
        .get(`/preoffer_subscribers/${subscriptionId}`)
    }
  );
}

const usePreofferSubscriptionEsignRequest = (subscriptionId) => {
  return useQuery(
    ['preoffer#request_esign', subscriptionId],
    () => {
      return axiosClient
        .get(`/preoffer_subscribers/${subscriptionId}/request_esign`)
    }, {
      staleTime: 1000,
    }
  );
}

const useInvestment = (id) => {
  return useQuery(
    ['investment', id],
    () => {
      return axiosClient
        .get(`investments/${id}`)
    })
}

const useAmlException = (id) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['aml_exception', id],
    () => {
      return axiosClient
        .get(`clients/${id}/aml_exception`)
    })
}

const useOffer = (slug) => {
  return useQuery(
    ['offer', slug],
    () => {
      return axiosClient
        .get(`/offers/${slug}`)
    }, {
      enabled: slug !== undefined,
      staleTime: 0,
    }
  );
}

const useOfferPitch = (id) => {
  return useQuery(
    ['offerPitch', id],
    () => {
      return axiosClient.get(`/offers/${id}/pitch`)
    }, {
    enabled: id !== undefined
  }
  )
}

const updateInvestment = ({ investmentId, attrs }) => {
  return axiosClient.patch(`investments/${investmentId}`, attrs)
}

const subscribePreoffer = ({ preoffer_id, values }) => {
  return axiosClient.post(`/preoffers/${preoffer_id}/subscribers`, values)
}

const deletePreofferSubscription = ({ preoffer_id, subscription_id }) => {
  return axiosClient.delete(`/preoffers/${preoffer_id}/subscribers/${subscription_id}`)
}

const updatePreofferSubscription = ({ preoffer_id, subscription_id, values }) => {
  return axiosClient.put(`/preoffers/${preoffer_id}/subscribers/${subscription_id}`, values)
}

const signPreofferSubscription = ({ subscriptionId, values }) => {
  return axiosClient.post(`/preoffer_subscribers/${subscriptionId}/signed`, values)
}

const acceptInvite = ({ invite_token, values }) => {
  return axiosClient
    .post(`user_invitations/${invite_token}/accept`, values)
}

const useRaisePetitionList = () => {
  return useQuery(
    ['RaisePetitions'],
    () => {
      return axiosClient
        .get(`/raise_petitions`);
    }, {
    staleTime: 0,
  }
  );
}

const useRaisePetition = (petitionId, isAdmin = false) => {
  return useQuery(
    ['raise-petitions', petitionId],
    () => {
      return axiosClient
        .get(`${isAdmin ? '/admin/' : '/'}raise_petitions/${petitionId}`);
    }, {
    staleTime: 0,
  }
  );
}

const useRaiseChartData = (petitionId, isAdmin = false) => {
  return useQuery(
    ['raise-petition-chart-data', petitionId],
    () => {
      return axiosClient
        .get(`/raise_petitions/${petitionId}/chart_data`);
    }, {
    staleTime: 0,
  }
  );
}

const createPledge = ({ values }) => {
  return axiosClient.post(`/raise_petition_pledges`, values)
}

const updatePledge = ({ values, pledge_id }) => {
  return axiosClient.put(`/raise_petition_pledges/${pledge_id}`, values);
}

const useClientPaymentMethodsList = ({ client_id }) => {
  return useQuery(
    ['client-payment-methods', client_id],
    () => {
      return axiosClient
        .get(`/clients/${client_id}/payment_methods`)
    }
  );
};

const addAchAccount = ({ clientId, values }) => {
  return axiosClient.post(`/clients/${clientId}/ach_authorization`, values);
}

const addWireAccount = ({ clientId }) => {
  return axiosClient.post(`/clients/${clientId}/wire`);
}

const deleteWireAccount = ({ clientId }) => {
  return axiosClient.delete(`/clients/${clientId}/wire`);
}

const deleteAchAccount = ({ clientId }) => {
  return axiosClient.delete(`/clients/${clientId}/ach_authorization`);
}

const addCcAccount = ({ clientId, values }) => {
  return axiosClient.post(`/clients/${clientId}/credit_card`, values);
}

const deleteCcAccount = ({ clientId }) => {
  return axiosClient.delete(`/clients/${clientId}/credit_card`);
}

const signSubscriptionAgreement = (investmentId) => {
  return axiosClient.post(`/investments/${investmentId}/subscription_agreement/sign`);
}

const requestSubscriptionAgreementSignature = (investmentId) => {
  return axiosClient.get(`/investments/${investmentId}/subscription_agreement/request_signature`);
}

const useRequestSubscriptionAgreementSignature = (investmentId) => {
  return useQuery(
    ['request_subdoc_signature', investmentId],
    () => {
      return axiosClient
        .get(`/investments/${investmentId}/subscription_agreement/request_signature`);
    }, {
    staleTime: 10000
  }
  );
}

const useClientAuditLogs = (clientId) => {
  return useQuery(
    ['client_audit_logs', clientId],
    () => {
      return axiosClient.get(`/admin/audit_logs/client/${clientId}`)
    })
}

//Investor Id is User Id
const useAdminInvestmentAccounts = (investorId) => {
  return useQuery(
    ['admin/investment_accounts', investorId],
    () => {
      return axiosClient.get(`/admin/users/${investorId}/clients`)
    }, {
    cacheTime: 10000
  })
}

//Investor Id is User Id
const useAdminInvestmentLimit = (investorId) => {
  return useQuery(
    ['admin/calc_investment_limit', investorId],
    () => {
      return axiosClient.get(`/admin/users/${investorId}/calculate_investment_limit`)
    }, {
    cacheTime: 10000
  })
}

const useInvestor = (investorId) => {
  return useQuery(
    ['investor', investorId],
    () => {
      return axiosClient.get(`investors/${investorId}`)
    }, {
    cacheTime: 10000
  })
}

const useArchivedIndividualAccount = () => {
  return useQuery(
    ['individual_archived_account'],
    () => {
      return axiosClient.get(`/users/not-required/clients/archived?entity_type=person`)
    }, {
    onSuccess: (data) => {
      if (data) {
        data.id = undefined
      }
      return data;
    }
  })
}

const useClientSuitabilityQuestionaire = (clientId) => {
  return useQuery(
    ['Investor::suitability_questionaire', clientId],
    () => {
      return axiosClient
        .get(`/clients/${clientId}/suitability_questionaire`);
    }, {
    staleTime: 0,
  });
}

const useAccreditationRequest = (clientId) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['accreditation_request', clientId],
    () => {
      return axiosClient
        .get(`/clients/${clientId}/accreditation_requests`)
    })
}

const acceptBrokerDealerTerms = (attrs) => {
  return axiosClient.put(`/users/broker_dealer_terms`, { user: attrs });
}


const preofferNDASigned = (id) => {
  return axiosClient.get(`/preoffers/${id}/nda_documents/signed`);
}

const useRequestPreofferNDA = (id) => {
  return useQuery(
    ['Preoffers::nda_request', id],
    () => {
      return axiosClient
        .post(`/preoffers/${id}/nda_documents`);
    }, {
    staleTime: 1000,
  });
}

const signPreofferNDA = ({preofferId, id}) => {
  return axiosClient.put(`/preoffers/${preofferId}/nda_documents/${id}/sign`);
}

const makeInvestment = ({investmentAccountId, values}) => {
  return axiosClient.post(`/clients/${investmentAccountId}/investments`, values)
}

export {
  useOfferList,
  useOffer,
  usePreofferList,
  usePreoffer,
  usePreofferPitch,
  usePreofferNDARequirement,
  subscribePreoffer,
  usePreofferSubscription,
  deletePreofferSubscription,
  updatePreofferSubscription,
  usePreofferSubscriptionEsignRequest,
  signPreofferSubscription,
  preofferNDASigned,
  useRequestPreofferNDA,
  signPreofferNDA,
  updateInvestment,
  useInvestment,
  useAmlException,
  useOfferPitch,
  acceptInvite,
  useRaisePetitionList,
  useRaisePetition,
  useRaiseChartData,
  createPledge,
  updatePledge,
  useClientPaymentMethodsList,
  deleteAchAccount,
  addAchAccount,
  addWireAccount,
  deleteWireAccount,
  addCcAccount,
  deleteCcAccount,
  signSubscriptionAgreement,
  requestSubscriptionAgreementSignature,
  useRequestSubscriptionAgreementSignature,
  useClientAuditLogs,
  useAdminInvestmentAccounts,
  useInvestor,
  useAdminInvestmentLimit,
  useArchivedIndividualAccount,
  useClientSuitabilityQuestionaire,
  useAccreditationRequest,
  acceptBrokerDealerTerms,
  makeInvestment,
};
