import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import PropTypes from 'prop-types';
import { yupResolver } from "@hookform/resolvers/yup";
import ToastMessage from "../../../components/ToastMessage";
import { history } from "redux/helpers";
import LimitCalculator from "../LimitCalculatorForm/LimitCalculatorForm";
import { useMutation, useQueryClient } from "react-query";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import FormattedNumberInput from "components/HookForm/FormattedNumberInput";
import { updateInvestment } from "investor/queries";
import { useUser, fetchCurrentUser } from "redux/user";
import formatMoney from "accounting-js/lib/formatMoney.js";
import { fetchInvestmentLimit, useInvestmentLimit } from "redux/investmentLimit";
import { calculatePricePerShare } from 'jsUtils/offerUtils'
import { DEAL_TYPE_EQUITY } from 'config/constants';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";

function amountValidationSchema(
  investmentAmount,
  remainingGoal,
  maxInvLimit,
  remainingInvestment,
  minLimit,
  setPPSVisibility,
  setUpdateLinkVisibility,
  setUpdateModal
) {
  return Yup.object().shape({
    amount: Yup.string()
      .required("Investment amount is required")
      .test("", "", function (value) {
        if (!/^[1-9]\d*(\.\d{1,2})?$/g.test(value)) {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message:
                "Investment amount must be whole number or up to 2 decimal places",
            })
          );
        }
        const amount = parseFloat(value);

        if(amount == investmentAmount){
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message: `Can't edit investment with the same amount`,
            })
          );
        } else if (amount < minLimit) {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message: `Amount is less than the minimum required investment of ${formatMoney(minLimit)}`,
            })
          );
        } else if (amount > remainingGoal && remainingGoal < maxInvLimit) {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message: `Can't invest more than the remaining limit of ${formatMoney(remainingGoal)} for the offer.`,
            })
          );
        } else if (
          amount > remainingInvestment &&
          remainingInvestment < maxInvLimit
        ) {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message: `Can't invest more than the remaining investment limit of ${formatMoney(remainingInvestment)}`,
            })
          );
        } else if (amount > maxInvLimit) {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
          return this.resolve(
            this.createError({
              message: `Can't invest more than the max investment limit of ${formatMoney(maxInvLimit)} for the offer.`,
            })
          );
        } else if (amount >= minLimit && amount <= remainingInvestment) {
          setUpdateModal(true)
          setUpdateLinkVisibility(true);
          setPPSVisibility(true);
          return true;
        } else {
          setUpdateLinkVisibility(false);
          setPPSVisibility(false);
        }
      }),
  });
}
const limitCalcModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  content: {
    borderRadius: 0,
    position: "fixed",
    padding: 0,
    transform: "translateX(-50%)",
    width: window.innerWidth > 991 ? "960px" : "calc(100% - 0px)",
    top: window.innerWidth > 991 ? "100px" : "0px",
    right: "40px",
    bottom: window.innerWidth > 991 ? "40px" : "0px",
    left: "50%",
    border: 0,
    zIndex: "2",
  },
};

const EditInvestmentForm = ({ offer, investment, setLoader }) => {
  const editInvestmentModal = React.useContext(PortalizedModalContext);
  const updateLimitModal = React.useContext(PortalizedModalContext);
  const queryClient = useQueryClient();
  const user = useUser();
  const [showPricePerShare, setPPSVisibility] = useState(false);
  const [showUpdateLimitLink, setUpdateLinkVisibility] = useState(false);
  const [shouldFetchOffer, setShouldFetchOffer] = useState(false);
  const [updateModal, setUpdateModal]= useState(false);
  const investmentLimit = useInvestmentLimit().investment_limit_info;
  const remainingInvestment = investmentLimit?.remaining_investment;
  const minLimit = offer.deal?.minimum_investment;
  const maxInvLimit = offer.deal?.maximum_investment;
  const raisedAmount = offer.deal?.raised;
  const maxGoal = offer.deal?.maximum_goal;
  const remainingGoal = maxGoal - raisedAmount;
  const amount = investment?.amount;

  function getInvestmentLimits() {
    const attrs = {
      annual_income: user?.investment_limit?.annual_income || 0,
      net_worth: user?.investment_limit?.net_worth || 0,
      outside_investment_amount:
        user?.investment_limit?.outside_investment_amount || 0,
    };
    fetchInvestmentLimit(attrs);
  }

  const onLimitUpdateSubmit = () => {
    setShouldFetchOffer(true);
    updateLimitModal.closeModal();
  };

  const UpdateLimitLink = () => {
    return (
      <span
        className="text-primary cursor-pointer zeitung-micro update-limit-text"
        onClick={() => {
          updateLimitModal.setModalContent(
            `Calculate your Investor Limit`,
            <LimitCalculator
              onSubmit={onLimitUpdateSubmit}
            />,
            'lg'
          );
          updateLimitModal.openModal();
        }}
      >
        Update my limit &#62;
      </span>
    );
  };

  const methods = useForm({
    resolver: yupResolver(
      amountValidationSchema(
        amount,
        remainingGoal,
        maxInvLimit,
        remainingInvestment,
        minLimit,
        setPPSVisibility,
        setUpdateLinkVisibility,
        setUpdateModal
      )
    ),
    mode: "all",
    reValidateMode: "all",
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: { amount },
  });

  useEffect(() => {
    getInvestmentLimits();
  }, [user]);

  useEffect(() => {
    if (shouldFetchOffer) {
      fetchCurrentUser();
      setShouldFetchOffer(false);
    }
  }, [shouldFetchOffer]);

  useEffect(() => {
    setUpdateModal(false)
    if (investment) {
      editInvestmentModal.setModalContent(
        `Edit Investment`,
        <div className="px-3 pb-3 pt-2">
          {investment.rolling_close_date && <div className="mb-2 fs-10">
           <b>WARNING: </b>
             Editing your investment during a rolling close will remove it from the close, the investment will be included in another rolling close in the future.
            </div>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <FormattedNumberInput
                    name="amount"
                    placeholder="Amount*"
                    classes={"mb-0"}
                  />
                  {showPricePerShare && offer?.deal?.type === DEAL_TYPE_EQUITY && <span className="fs-10 mr-1 weight-700">{calculatePricePerShare(offer, methods.getValues("amount"))} Shares -</span>}
                  {showUpdateLimitLink && <span className="fs-10">Amount is within your investment limit. <UpdateLimitLink /></span>}
                </div>
              </div>
              <div className="form-group my-3">
                <button
                  type="submit"
                  className="mx-auto weight-300 btn btn-outline-dark rounded-0"
                  disabled={!showPricePerShare}
                >
                  Confirm
                </button>
              </div>
            </form>
          </FormProvider>
        </div>,
        'sm'
      );
    }
  }, [showUpdateLimitLink, showPricePerShare, updateModal]);

  const updateInvestmentMutation = useMutation(updateInvestment, {
    onSuccess: (values) => {
      setLoader(false);
      methods.reset();
      queryClient.invalidateQueries("investment", investment.id);
      ToastMessage.success(`Your investment has been updated.`);
      history.push({ pathname: `/offers/${offer?.slug}/subscription-agreement/${investment.id}`, state: { isEditInvestment: true } })
    },
    onError: (error) => {
      setLoader(false);
      renderRailsErrors(error)
      console.log(error);
    },
  });

  const onSubmit = (values) => {
    setLoader(true);
    editInvestmentModal.closeModal();
    updateInvestmentMutation.mutate({
      investmentId: investment.id,
      attrs: {
        investment: {
          amount: parseFloat(values.amount).toFixed(2),
        },
      },
    });
  };

  return (
    <>
      {/* { investment.payment_method != "credit_card" &&
        <span className="edit-investment-btn cursor-pointer" onClick={() => {
          setUpdateModal(true)
          editInvestmentModal.openModal()
        }}>
          <FaPen />
        </span>
      } */}
    </>
  );
}


EditInvestmentForm.defaultProps = {};

EditInvestmentForm.propTypes = {
  investment: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired
};

export default EditInvestmentForm;