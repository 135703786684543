import React from 'react';
import { useParams } from "react-router";
import ToastMessage from '../../../../../components/ToastMessage';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RichEditor,
  createEditorStateWithContent,
  createEditorSaveContent,
  FormikPromptIfDirty,
} from 'admin/components'
import DeleteSectionButton from './../DeleteSectionButton';
import LastUpdatedAt from './../LastUpdatedAt';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import LoadingOverlay from 'react-loading-overlay';

const ProblemSchema = Yup.object().shape({
  // content: Yup.object()
  //   .test(
  //     'has text',
  //     'Article content is required',
  //     value => value && value.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0
  //   ),
  title: Yup.string()
    .required('Summary is required')
    .max(150),
});

export default function ProblemForm({
  pitch:{problem_section={}, pitchable_id}, updateMethod
}) {
  if(problem_section === null) problem_section = {};

  const { preofferSlug } = useParams()

  const defaultValues = {
    id: problem_section.id,
    title: problem_section.title || '',
    content: createEditorStateWithContent(problem_section.content),
  }

  const queryClient = useQueryClient();
  const updatePitchMutation = useMutation(
    updateMethod,
    {
      onSuccess: (updatedPitch) => {
        ToastMessage.success('Pitch section updated.')
        queryClient.setQueryData(
          ['preoffer#pitch', preofferSlug],
          updatedPitch
        )
      },
      onError: (error) => {
        ToastMessage.error('Pitch section update failed.')
        renderRailsErrors(error)
      },
    }
  )

  return(
  <LoadingOverlay active={updatePitchMutation.isLoading} spinner text="Loading">
    <div id="problemForm">
      <Formik
        initialValues={defaultValues}
        validationSchema={ProblemSchema}
        enableReinitialize

        onSubmit={async values => {
          const { title, content, id } = values
          updatePitchMutation.mutate({
            pitchable_id: pitchable_id,
            values: {
              pitch: {
                problem_section_attributes: {
                  id,
                  title,
                  content: createEditorSaveContent(content),
                }
              }
            }
          })
        }}
      >
        {(formikBag) => {
          const { values, touched, errors, isSubmitting, setFieldValue, setFieldTouched } = formikBag
          return <Form>
            <div className="col-xs-12 row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label>Article content</label>
                    <div className="richEditorContainerForPitchSections">
                      <RichEditor
                        className={`form-control ${touched.content && errors.content ? "is-invalid" : ""}`}
                        editorState = { values.content }
                        onChange={ (value) => setFieldValue("content", value) }
                        onBlur={() => setFieldTouched("content")}
                      />
                    </div>
                    <ErrorMessage name="content" component="div" className="invalid-feedback text-left" />
                </div>
              </div>

              <div className="col-lg-5">
                <label>Brief summary</label>
                <div className="form-group">
                  <div className="form-label-group show-org-placeholder">
                    <Field
                      className={`form-control ${touched.title && errors.title ? "is-invalid" : ""}`}
                      name="title"
                      id="problem-title"
                      rows="5"
                      component="textarea"
                      placeholder="Brief summary (max 150 chars)"
                    />
                    <label htmlFor="problem-title">Brief summary (max 150 chars)</label>
                    <ErrorMessage name="title" component="div" className="invalid-feedback text-left"/>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="weight-300 btn btn-outline-dark rounded-0" disabled={isSubmitting}>
              Save Section
            </button>

            <DeleteSectionButton sectionId={ problem_section.id } sectionType="problem"/>
            <LastUpdatedAt updatedAt={ problem_section.updated_at }/>
            <FormikPromptIfDirty />
          </Form>
        }}
      </Formik>
    </div>
  </LoadingOverlay>
  );
}
