import React, { useEffect, useState, useContext } from 'react';
import Pusher from "pusher-js";
import LoadingOverlay from 'react-loading-overlay';
import { PUSHER_CONFIG } from "../config/constants";

const PusherChannelContext = React.createContext(null);

function PusherChannelProvider ({ children }){
  const [pusher, setPusher] = useState(null);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_CONFIG.app_key, {
      cluster: PUSHER_CONFIG.cluster,
      encrypted: true
    });
    pusher.connection.bind("error", console.log);
    setPusher(pusher);

   return () => {
      pusher.disconnect();
      setPusher(null);
    }
  }, []);

  return (
    <PusherChannelContext.Provider value={pusher}>
      <LoadingOverlay active={!pusher} spinner text='Loading ...'>
        {pusher && children}
      </LoadingOverlay>
    </PusherChannelContext.Provider>
  );
};

function usePusherChannel(channelId) {
  const pusher = useContext(PusherChannelContext);

  if (!pusher) {
    throw new Error("userPusherChannel must be used within a PusherChannelProvider");
  }

  const channel = pusher.channels['channels'][channelId] || pusher.subscribe(channelId);

  useEffect(() => {
   return () => channel.unsubscribe(channelId);
  }, [channelId]);

  return channel;
}


export { PusherChannelContext, PusherChannelProvider, usePusherChannel };
