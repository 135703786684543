

import React from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import {  NavLink } from "react-router-dom";
import FundingProgressBar from '../FundingProgressBar';
import InvestmentButton from '../InvestmentButton';
import Investor from '../Investor';
import Customer from '../Customer';
import { subDays } from 'date-fns'
import AmountWithCurrency, {
  FormattedNumberWithSymbol,
} from '../../../../components/AmountWithCurrency';
import RemainingTimeBadge from '../../../../components/RemainingTimeBadge';
import ShareButtons from '../../../../investor/features/raise_petition/components/ShareButtons';
import FollowButton from '../../../../pages/components/FollowButton';
import CompanySocialLinks from './../CompanySocialLinks';

export default function RegCFOffer({ offer, user, isAdminUser }) {
  const userLoggedIn = user.hasOwnProperty("id");
  const company = offer.company;
  const customers = offer.customers;
  const notableInvestors = offer.pitch?.notable_investors;
  const offerCloseDate = new Date(offer?.deal?.close_date);
  const today = new Date();
  const isClosingSoon = subDays(offerCloseDate, 10) <= today && offerCloseDate >= today;
  return (
    <>
      <div className='container offer-view-banner-title-container'>
        <div className="px-sm-0 px-3">
          { isClosingSoon && offer.status!=='closed' && <div className='offerView-badges'>
            <RemainingTimeBadge closeDate={offerCloseDate} prefix="Closing Soon:" />
          </div> }
          <h1 className="company-title reg-cf-offername">
            <span className="mr-3">{offer.company.name}</span>
            {company.url && <a className="social-icon" target="_blank" rel="noopener noreferrer" href={offer.company.url}>{<FaExternalLinkAlt/>}</a> }
          </h1>
        </div>
      </div>
      {company?.tagline && <div className='container offer-view-banner-tagline-container'>
        <div className='col-md-10 px-sm-0 px-3'>
          <h5 className='company-tagline'>{company?.tagline}</h5>
        </div>
      </div> }
      <div className="container offer-view-banner-content-container">
        <div className="deal-info row">
          <div className="col-lg-6 pl-lg-4 deal-info-col1 mt-lg-0 mt-4">
            <div className="w-100 mb-3">
              <FundingProgressBar offer={offer}/>
              <InvestmentButton offer={offer} user={user}/>
              <p className="invested-amount">
                {
                  !isAdminUser && userLoggedIn && offer.investments?.length > 0 &&
                    <>
                      You’ve invested <NavLink to={'/account/investments'} className="underline text-white"><FormattedNumberWithSymbol number={offer.total_investment} /></NavLink>.&nbsp;
                    </>
                }
                <span className="minimum-investment-amount">
                  <FormattedNumberWithSymbol number={offer.deal?.minimum_investment} numberFormattingOpts={{precision: 0}} /> min for US investors.
                  {
                    offer.international_investors && <>
                      &nbsp;<FormattedNumberWithSymbol number={offer.deal?.minimum_international_investment} numberFormattingOpts={{precision: 0}} /> min for CAD, UK investors.
                    </>
                  }
                </span>
              </p>
            </div>
            <div className="w-100 offer-social-icons mt-2 mt-lg-5 d-flex justify-content-between align-items-end">
              <ShareButtons showTitle={true} offering={offer} />
              <CompanySocialLinks showSocialIcons={false} theme={"light"} company={company} following_company={offer.following_company} />
            </div>
          </div>
          <div className="col-lg-6 pr-lg-4 deal-info-col2">
            { notableInvestors?.length > 0 && <div className="offer-investors">
            <span className="offer-investors-title">{notableInvestors?.length >= 2 ? "Notable Investors" : "Notable Investor"}</span>
              {notableInvestors?.map((investor, i) => (<Investor investor={investor} key={i}/>))}
            </div> }
            {customers?.length > 0 && <div className="offer-customers">
              <span className="offer-investors-title">Customers</span>
              <div className="offer-customers-box">
                {customers?.map((customer, i) =>(<Customer customer={customer} key={i}/>))}
              </div>
            </div> }
          </div>
        </div>
      </div>
    </>
  );
}
