import React from 'react';

export default function TwoColumnLayout({left, right}) {
  return(
    <div className="two-col-layout">
      { left && <div className="first-col p-3">{left}</div> }
      { right && <div className="second-col p-3">{right}</div> }
    </div>
  );
}
