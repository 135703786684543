import React from 'react';
import { useQueryClient } from 'react-query';
import { fetch } from 'redux/investor';
import { useAdminInvestmentAccounts, useInvestor } from '../../investor/queries';
import { toTitlecase } from '../../jsUtils/stringUtils';
import OverviewHeader from './OverviewHeader';
import { resend_confirmation_email } from 'redux/user'
import { ResendLink } from './shared/ResendLink'
import InvestmentOverview from "../components/InvestmentOverview";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import IndividualDetail from './InvestorDetail/IndividualDetail';
import InvestmentLimitDetail from './InvestorDetail/InvestmentLimitDetail';
import InvestmentList from './InvestorDetail/InvestmentList';
import EntityDetail from './InvestorDetail/EntityDetail';


function getInvestorFullName(investor) {
  return investor.name || `${investor.first_name} ${investor.last_name}`;
}

const resendEmail = email => (resend_confirmation_email({ email }))

const getResendLink = (investor, text) => (!investor.is_confirmed && <ResendLink onClickResend={() => resendEmail(investor.email)} text={text} />)

const filterByAccountType = (accounts, type) =>{
  let values = []

  if(type === 'entity'){
    values = accounts.filter(account => account.entity_type !== 'person')
  } else {
    values = accounts.filter(account => account.entity_type === 'person')
  }

  return values.sort((a1, a2) => {
    return a1 === 'north_capital' ? 1 : -1;
  });
}

export default function InvestorOverview({ investorId, filterValue }) {
  const investorQuery = useInvestor(investorId);
  const investmentAccountsQuery = useAdminInvestmentAccounts(investorId);
  const investmentDetailModal = React.useContext(PortalizedModalContext);
  const queryClient = useQueryClient()

  const showInvestmentDetail = (investment) => {
    investmentDetailModal.setModalContent(
      `${toTitlecase(getInvestorFullName(investor) || "")} Investment Info`, // <-- title string goes here
      <InvestmentOverview
        investmentId={investment.id}
        onCancel={() => investmentDetailModal.closeModal()}
        refreshInvestments={() => {
          queryClient.invalidateQueries(['investor', investorId],);
          investmentDetailModal.closeModal();
        }}
      />,
      "lg" //size
    );
    investmentDetailModal.openModal();
  }

  if (investorQuery.isLoading || investmentAccountsQuery.isLoading) return "Loading details...";

  const investor = investorQuery.data;
  const individualAccounts = filterByAccountType(investmentAccountsQuery.data, 'person');
  const entityAccounts = filterByAccountType(investmentAccountsQuery.data, 'entity');

  return (
    <>
      <OverviewHeader left={toTitlecase(getInvestorFullName(investor))} right={getResendLink(investor, "Uncomfirmed - Resend link")} />
      <div className="overview-content">
        <ul className="nav nav-tabs px-3" role="tablist" style={{ backgroundColor: '#fff' }}>
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#investor-overview" role="tab">Individual Account</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#investor-entities" role="tab">Entities</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#investor-investments" role="tab">Investments</a>
          </li>
        </ul>
        <div className="tab-content px-3">
          <div className="tab-pane fade show active" id="investor-overview" role="tabpanel">
            {individualAccounts.map((account, index) => <IndividualDetail key={index} account={account} />)}
            {investor.investment_limit && <InvestmentLimitDetail filterValue={filterValue} investor={investor} />}
            {
              !investor.investment_limit && <div className="p-2 bg-lightgrey2 text-center"><span className="mb-0">No data found.</span></div>
            }
          </div>
          <div className="tab-pane fade show" id="investor-entities" role="tabpanel">
            {entityAccounts.map((account, index) => <EntityDetail key={index} account={account} />)}
            {
              entityAccounts.length === 0 && <div className="p-2 bg-lightgrey2 text-center"><span className="mb-0">No entities found.</span></div>
            }
          </div>
          <div className="tab-pane fade show" id="investor-investments" role="tabpanel">
            <InvestmentList investments={investor.investments} onRowClick={(investment) => showInvestmentDetail(investment)} />
          </div>
        </div>
      </div>
    </>
  )
}