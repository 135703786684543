import React, { useContext } from "react";
import { deleteIconOutline } from "../../../../assets/images";
import { useQueryClient, useMutation } from "react-query";
import { WIRE_TRANSFER_FEES } from "config/constants";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import InvestmentCardRow, {
  InvestmentCardActionRow,
} from "../../shared/InvestmentCardRow";
import { deleteWireAccount } from "../../../../investor/queries";
import ToastMessage from "../../../../components/ToastMessage";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";

export default function WireTransferCard({
  wire,
  clientId,
  selected = false,
  onSelect,
  onDelete,
}) {
  const deleteModal = useContext(PortalizedModalContext);

  const queryClient = useQueryClient();
  const deleteWireMutation = useMutation(deleteWireAccount, {
    onSuccess: () => {
      typeof onDelete === "function" && onDelete(wire.id);
      queryClient.refetchQueries(["client-payment-methods", clientId]);
      ToastMessage.success("Wire method deleted.");
    },
    onError: (error) => {
      renderRailsErrors(error);
    },
  });

  const wireAccountRemoveHandler = () => {
    deleteModal.setModalContent(
      `Delete Wire Method`,
      <>
        <p className="instruction-text-for-mobile">
          Do you want to delete your wire method?
        </p>
        <div className="portalized-modal-footer">
          <button
            className="btn-black"
            onClick={() => {
              deleteWireMutation.mutate({ clientId });
              deleteModal.closeModal();
            }}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => {
              deleteModal.closeModal();
            }}
          >
            Cancel
          </button>
        </div>
      </>,
      "sm"
    );
    deleteModal.openModal();
  };

  return (
    <div className="payment-card mt-4 mb-2">
      <div className="payment-card-header">Wire Transfer Method</div>
      <InvestmentCardRow
        title={"USA wire fee"}
        value={`$${WIRE_TRANSFER_FEES.USA}`}
      />
      <InvestmentCardRow
        title={"International wire fee"}
        value={`$${WIRE_TRANSFER_FEES.INTERNATIONAL}`}
      />
      <InvestmentCardActionRow>
        <div className="inner1 d-flex flex-wrap align-items-center">
          {selected !== undefined && (
            <label
              style={{ lineHeight: "16px" }}
              className="custom-radio-btn fs-10 mb-2 mr-3"
            >
              Use wire transfer
              <input
                type="radio"
                name="radio"
                checked={selected}
                onChange={() =>
                  typeof onSelect === "function" && onSelect(wire.id)
                }
              />
              <span className="radiobtn"></span>
            </label>
          )}
          <div
            onClick={wireAccountRemoveHandler}
            className="delete-ach d-flex align-items-center mb-2 cursor-pointer"
          >
            <img
              src={deleteIconOutline}
              width="16"
              class="mr-1"
              alt="trash icon"
            />
            <span className="zeitung-micro fs-10 weight-300">delete</span>
          </div>
        </div>
      </InvestmentCardActionRow>
    </div>
  );
}
