import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from "react-router-dom";
import { FaComment } from "react-icons/fa";
import { formatDateWithoutTimezone } from 'jsUtils/dateTimeUtils';
import RichTextRenderer from 'admin/components/RichEditor/renderer'
import YoutubeEmbed from '../../../admin/components/shared/YoutubeEmbed';
import TimeLineDate from './TimeLineDate';
import LikeButton from './LikeButton';

function OfferUpdateItem({ offer, offerUpdate, viewDetail }){
  const company = offer.company;

  return (
    <div className="col-11 p-3 mt-2 border bg-white">
      <div className="row">
        <div className="col-12 mb-1 pb-1">
          <h2 onClick={() => viewDetail()} className="cursor-pointer montserrat fs-6 weight-800" >{offerUpdate.title}</h2>
        </div>
        <div className="col-12 my-2">
          <div className="row border-bottom ml-1 pb-3">
            <div className="mr-2">
              <img src={ company.logo_url } alt="" className="logo logo-circle" width="48px" height="48px"/>
            </div>
            <div>
              <div className="fs-10 weight-700 montserrat">{company.legal_name}</div>
              <div className="fs-10 montserrat">{ formatDateWithoutTimezone(offerUpdate.published_at)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-2">
          <RichTextRenderer content={offerUpdate.content} maxChars={500} backgroundColor="#fff" />
        </div>
        <div className="col-12 mt-5 mb-2">
          <div className="row">
            <div className="col-6 text-left">
              <LikeButton offerUpdate={offerUpdate} />
              <button className="btn-plain fs-14" onClick={ () => viewDetail('comments')}>
                <FaComment/><span className="ml-1 montserrat">Comment {offerUpdate.discussions_count > 0 && `(${offerUpdate.discussions_count})`}</span>
              </button>
            </div>
            <div className="col-6 text-right">
              <button type="button"
                className="mx-auto zeitung-mono weight-300 btn btn-sm btn-outline-dark rounded-0 px-4"
                onClick={() => viewDetail()}
              >
                Read more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function LiveEventItem({ offer, offerUpdate, viewDetail }){
  const company = offer.company;
  const liveEvent = offerUpdate.resourceable;

  return (
    <div className="col-11 p-3 mt-2 border bg-black">
      <div className="row">
        <div className="col-12 mb-1 pb-1">
          <h2 className="montserrat fs-6 weight-800 color-white" >{liveEvent.title}</h2>
        </div>
        <div className="col-12 my-2">
          <div className="row ml-1 pb-3">
            <div className="mr-2">
              <img src={ company.logo_url } alt="" className="logo logo-circle" width="48px" height="48px" />
            </div>
            <div>
              <div className="fs-10 weight-700 montserrat color-white">{company.legal_name}</div>
              <div className="fs-10 montserrat color-white">{formatDateWithoutTimezone(offerUpdate.published_at)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-2">
          <YoutubeEmbed videoUrl={liveEvent.youtube_url}/>
        </div>
        <div className="col-12 mt-4 color-white">{liveEvent.description.substring(0, 500)}...</div>
        <div className="col-12 mt-2 text-right">
          <button type="button"
            className="mx-auto zeitung-mono weight-300 btn btn-sm btn-outline-light rounded-0 px-4"
            onClick={() => viewDetail()}
          >
            Read more
          </button>
        </div>
      </div>
    </div>
  )
}

const ItemView = ({offer, offerUpdate}) => {
  const View = offerUpdate.resourceable_type === 'LiveEvent' ? LiveEventItem : OfferUpdateItem;
  let history = useHistory();
  const queryClient = useQueryClient();

  const viewDetail = (sectionId) => {
    queryClient.setQueryData(['offerUpdate', offerUpdate.id], offerUpdate);

    let url = `/offers/${offer.slug}/updates/${offerUpdate.id}`;

    if(sectionId){
      url = `${url}#${sectionId}`;
    }

    history.push(url);
  }

  return(
    <>
      <div className="col-1 col-md-1 timeline d-none d-md-block">
        <div className="knob"/>
        <div className="progress-line"/>
        <div className="knob"/>
      </div>
      <View offer={offer} offerUpdate={offerUpdate} viewDetail={viewDetail}/>
    </>
  )
}

export default function ListItem({ offer, offerUpdates, publishedAt }) {
  return (
    <div className="row mb-5">
      <div className="timeline-date col-12 mb-2 pl-0">
        <TimeLineDate date={publishedAt} />
      </div>
      {offerUpdates.map(offerUpdate => <ItemView key={offerUpdate.id} offer={offer} offerUpdate={offerUpdate} /> )}
    </div>
  )
}
