
import React from 'react'
import { useQuery } from "react-query";
import { ResponsiveTable } from 'admin/components';

import { getAllMaterialChangeAnnoucements } from '../queries/index'

export default function MaterialChangeAnnouncements ({id}) {
  const materialChangeAnnoucementsQuery = useQuery({
    queryKey: ["material_changes_announcements", id],
    queryFn: () => getAllMaterialChangeAnnoucements(id).then((res) => res.data)
  })

  const {isLoading, isError, data, error } = materialChangeAnnoucementsQuery;

  const columns = [
    {
    Header: 'client',
    accessor: 'client'
  },{
    Header: 'amount',
    accessor: 'amount'
  },{
    Header: 'accepted',
    accessor: 'accepted',
    Cell: ({value}) =>  value ? "Yes" : "No"
  }
]

if ( isLoading) return <h1>Loading...</h1>
if ( isError) { return <h1>{JSON.stringify(error)}</h1> }

  return (
      <ResponsiveTable
            columns={columns}
            data={ data || []}
            loading={ isLoading}
            loadingMsg="Loading material changes..."
            noDataMsg="No Material Changes have been posted yet."
          />
  )
}