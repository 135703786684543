import React from 'react';
import { format } from 'date-fns';
import parse from 'date-fns/parse'
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import { toTitlecase, humanize } from '../../../jsUtils/stringUtils';
import { formatSSN } from '../../../jsUtils/stringUtils';

export default function IndividualDetail({ account }) {
  return (
    <>
      <OverviewSubheader left={`[${humanize(account.service_provider)}] Individual Investor Info`} />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Legal name' value={toTitlecase(account.name)} />
            <AttributeValue name='Birthday' value={account.date_of_birth ? format(parse(account.date_of_birth, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy') : null} />
            <AttributeValue name='Gender' value={toTitlecase(account.gender)} />
            <AttributeValue name='SSN' value={account?.tax_id_number ? formatSSN(account?.tax_id_number) : "-"} />
            <AttributeValue name='Domicile' value={account.domicile} />
          </>
        }
        right={
          <>
            <AttributeValue name='Address' value={toTitlecase(account.street_address_1)} />
            <AttributeValue name='City' value={toTitlecase(account.city)} />
            <AttributeValue name='State' value={account.region} />
            <AttributeValue name='Country' value={account.country} />
            <AttributeValue name='Zip code' value={account.postal_code} />
            <AttributeValue name='Phone number' value={account.phone} />
          </>
        }
      />
    </>
  )
}