import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


import profileImg1 from '../../assets/images/experts/Hira@2x.jpg'
import profileImg2 from '../../assets/images/experts/Ioana@2x.jpg'
import profileImg3 from '../../assets/images/experts/jose@2x.jpg'
import profileImg4 from '../../assets/images/experts/jenet@2x.jpg'

import profileImg5 from '../../assets/images/experts/RaphaelRoettegen-1.jpg'
import profileImg6 from '../../assets/images/experts/ShariNoonan.jpg'
import profileImg7 from '../../assets/images/experts/ChristineTovee.jpg'
import profileImg8 from '../../assets/images/experts/daniel@2x.jpg'
import profileImg9 from '../../assets/images/experts/PeacockDeborah.jpg'

const expertData = [
  {
    name: "Christine Tovee",
    subheader: "Innovation Leader",
    text: "Ex-CTO, Airbus Group N. America",
    img: profileImg7
  },      
  {
    name: "Daniel Faber",
    subheader: "Aerospace Pioneer",
    text: "Engineer & Space Materials Pioneer. CEO, OrbitFab",
    img: profileImg8
  },
  {
    name: "Raphael Roettgen",
    subheader: "Top Space VC",
    text: "Managing Partner of E2MCHost. Host of Space Business Podcast",
    img: profileImg5
  },
  {
    name: "Dr. Ioana Cozmuta",
    subheader: "15 Years @ Nasa",
    text: "Ph.D. Physics, Stanford Caltech, CEO of G-space & Gravity Free",
    img: profileImg2
  },  
  {
    name: "Jose Morey M.D.",
    subheader: "Forbes Tech Council",
    text: "Johns Hopkins. CMIO Liberty BioSecurity",
    img: profileImg3
  },
  {
    name: "Deborah Peacock",
    subheader: "Engineer+Harvard Law",
    text: "Patent Attorney & Angel Investor",
    img: profileImg9
  },  
  {
    name: "Dr. Fredrick Jenet",
    subheader: "Astrophysicist",
    text: "Ph.D. Caltech, Founder of Expanding Frontiers",
    img: profileImg4
  },

  {
    name: "Dr. Hira Virdee",
    subheader: "Oxford Physicist",
    text: "Ph.D. Physics, CEO of Lumi.space",
    img: profileImg1
  },     
]

function Expert({name, subheader, text, img}) {
  return (
  
      <div className="industry-expert-card h-100">
        <IndustryExpertBanner img={img}>
        </IndustryExpertBanner>
        <div className="industry-experts-text-container ">
          <div className="industry-experts-subheader-container zeitung-mono"> 
            <span>{subheader}</span>
          </div>
          <h2 className="fs-9 weight-700 zeitung-micro">{name}</h2>
          <p className="industry-experts-text m-t-10 zeitung-micro weight-300 fs-10">{text}</p>
        </div>
      </div>

  )
}

export default function IndustryExperts() {
  return (
    <div className="main-section">
      <div className="container center-mobile">
        <h2 className="fs-5 weight-900 pb-2">Over 300 Years of Experience in the Space Industry</h2>
        <p className="weight-300">Spaced Ventures partners with some of the greatest minds of our generation to enable space startups to raise capital.</p>
      </div>
      <div className="container">
      <ExpertsCardContainerStyles>
          
          {expertData.map((item, key) => {
            return <Expert key={key} name={item.name} subheader={item.subheader} text={item.text} img={item.img} />
          })}
          
      </ExpertsCardContainerStyles>
      </div>
    </div>
  )
}

Expert.propTypes = {
  name: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const IndustryExpertBanner = styled.div`
  background: url(${props => props.img}) top center / 100% no-repeat;
  height: 250px;
`;

const ExpertsCardContainerStyles = styled.div`
  margin-top: 45px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: center;

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 300px));
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 280px));
  }

`;