import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useParams } from 'react-router';
import ErrorBoundary from 'components/ErrrorBoundary';
import {
  HorizontalRoutedTabs,
} from 'admin/components';
import { useRaisePetition } from './queries';
import OverviewEditor from './OverviewEditor'
import StatusIndicator from './StatusIndicator';
import StatusManager from './StatusManager';
import './../../pages/Page.OfferEdit.scss';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';

function Editor(){
  let { path, url } = useRouteMatch();
  const { authData } = React.useContext(AuthContext);
  let { raisePetitionSlug } = useParams()
  const {data:raisePetition={}, isLoading} = useRaisePetition(raisePetitionSlug)

  const sections = React.useMemo(
    () => {
      const editorTabs = [
        { tabId: 'overview', title: 'Overview', targetId: 'overview-panel', pathName: 'overview',
          decoratorClass: raisePetition.name ? 'complete' : '',
          content: <OverviewEditor />
        },
      ]
      return editorTabs;
    },
    [raisePetition]
  );

  if (isLoading)
    return 'Loading...'

  if(!authData.permittedActions.has(ACTIONS.WRITE_UPDATE_RAISE_PETITION)) {
    return <div>
      You are not authorised to edit raise petition.
    </div>
  }

  return (
    <>
      <EditorHeader raisePetition={raisePetition} />
      <div className='offer-editor'>
        <div className="small-section-bottom">
          <div className='left-spaced-tabs'>
            <HorizontalRoutedTabs
              basePath={path}
              baseUrl={url}
              tabs={sections}
              tabIdAccessor={'tabId'}
              targetPanelIdAccessor={'targetId'}
              defaultTabId="overview"
            />
          </div>
        </div>
      </div>
    </>
  )
}

function EditorHeader({raisePetition}) {
  return (
    <div className="custom-topbar offer-editor-topbar">
      <div className="offer-summary">
        {raisePetition.id ? 'Edit ' : 'Create '}
        <span className="offer-name">{raisePetition.name || 'New Raise Petition'}</span> for {raisePetition.company.name}
      </div>
      <div className="action-button-container">
        <StatusIndicator raisePetition={raisePetition} />
        <StatusManager raisePetition={raisePetition} />
      </div>
    </div>
  );
}

Editor.defaultProps = {};

Editor.propTypes = {};

export default Editor;