import { useQuery } from 'react-query';
import axiosClient from 'config/axiosClient';

const usePlatformEventsList = ({page=1, per_page=10}) => {
  return useQuery(
    ['admin', 'platform-events', page, per_page],
    () => {
      return axiosClient
        .get('/admin/platform_live_events', { params: { page, per_page } });
    }, {
    staleTime: 0,
    keepPreviousData: true
  });
}

const usePlatformEvent = (event_id) => {
  return useQuery(
    ['admin', 'platform-event', event_id],
    () => {
      return axiosClient
        .get(`/admin/platform_live_events/${event_id}`);
    }, {
    staleTime: 0,
  }
  );
}

const useSuspendedParticipantsList = (event_id) => {
  return useQuery(
    ['admin', 'platform-event', event_id],
    () => {
      return axiosClient
        .get(`/admin/platform_live_events/${event_id}/suspended_users`);
    }, {
    staleTime: 0,
  }
  );
}

const createPlateformEvent = ({ attrs }) => {
  return axiosClient.post(`/admin/platform_live_events`, { platform_live_event: attrs })
}

const updatePlateformEvent = ({ event_id, attrs }) => {
  return axiosClient.put(`/admin/platform_live_events/${event_id}`, { platform_live_event: attrs })
}

const deletePlateformEvent = (event_id) => {
  return axiosClient.delete(`/admin/platform_live_events/${event_id}`)
}

export {
  usePlatformEventsList,
  usePlatformEvent,
  useSuspendedParticipantsList,
  createPlateformEvent,
  updatePlateformEvent,
  deletePlateformEvent,
};
