import React from 'react';
import { history } from '../../../../redux/helpers';
import { humanize, toTitlecase} from "jsUtils/stringUtils";


export default function SelfAttestationInfo({ user }) {
  if (!user?.investor_type) {
    return
  }

  const handleEditSelfAttestation = () => {
    history.push('/self-attestation/edit')
  }

  return (
    <div className='aml-section-wrapper mb-3'>
      <div className='d-flex justify-content-between align-items-start mb-2'>
        <div className="w-70">
          <h4 className="fs-18 weight-800 lh-md">Self Attestation</h4>
          <p>Determines what types of deals you can view and invest in.</p>
        </div>
         <button className='fs-18 weight-700 btn btn-link color-black' onClick={handleEditSelfAttestation}>Edit</button>
      </div>
      <div className={`mt-1 mb-2 p-3 border-radius-2 ${user.investor_type === 'retail' ? 'bg-black color-white' : 'bg-lightgrey text-muted'}`}>
        <div className="weight-700 d-flex flex-wrap align-items-center">
          Retail Investor
        </div>
      </div>
      <div className={`mt-1 mb-2 p-3 border-radius-2 ${user.investor_type === 'accredited' ? 'bg-black color-white' : 'bg-lightgrey text-muted' }`}>
        <div className="weight-700 d-flex flex-wrap align-items-center">
          Accredited Investor {user.accredited_type !== 'none' ? `(${toTitlecase(humanize(user.accredited_type))})` : '' }
        </div>
      </div>
    </div>
  )
}
