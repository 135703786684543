import React from 'react';
import { Link } from "react-router-dom";
import LoginForm  from './components/LoginForm';
import './Pages.Common.Style.scss';
import { useSession } from 'redux/session';
import LoadingOverlay from 'react-loading-overlay';
import { Helmet } from "react-helmet";

export default function LoginPage() {
  return (
    <LoadingOverlay
      active={useSession().loading}
      spinner
      text='Loading ...'
    >
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Login to Spaced Ventures"
        />
      </Helmet>       
      <div id="loginPage" className="banner-img-container text-center flex-center flex-column pt-first-section">
        <LoginForm />
       
        <div className="d-flex align-items-center justify-content-center mt-2">
          <div className="poppings weight-700 fs-14  pr-2"><Link className="green-link" to="/forgot-password">Forgot your password?</Link></div>
          {/* <div className="poppings weight-700 fs-14 pl-2">Do not have an account? <Link className="green-link" to="/signup">Sign up</Link></div> */}
        </div>   
      </div>
    </LoadingOverlay>
  )
}
