import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from './ErrorMessage';

const TextArea= ({name, type='text', placeholder, rows=3}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="form-label-group">
          <textarea
            {...field}
            id={field.name}
            type={type}
            className={`form-control ${invalid ? "is-invalid" : ""}`}
            placeholder={placeholder}
            rows={rows}
          />
          <label htmlFor={field.name} className='textarea-label-placeholder'>{placeholder}</label>
          <ErrorMessage error={error}/>
        </div>
      )}
    />
  )
}

TextArea.defaultProps = {};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default TextArea;