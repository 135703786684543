import React from 'react';
import AttributeValue from 'admin/components/AttributeValue'
import TwoColumnLayout from 'admin/components/TwoColumnLayout'
import OverviewSubheader from 'admin/components/OverviewSubheader'
import { toTitlecase } from '../../../jsUtils/stringUtils';

function AssociatedPerson({ accreditationRequest}){
  const associatedUser = accreditationRequest.associated_user;
  return (
    <>
      <OverviewSubheader left={'Associated Person'} />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Name' value={toTitlecase(associatedUser.name)} />
          </>
        }
      />
    </>
  )
}

export default function InvestorDetail({ accreditationRequest  }) {
  const client = accreditationRequest.client;

  return (
    <>
      <OverviewSubheader left={'Investor Details'} />
      <TwoColumnLayout
        left={
          <>
            <AttributeValue name='Name' value={toTitlecase(client.name)} />
            <AttributeValue name='Investor Type' value={client.entity_type === 'person' ? 'Person' : `Entity (${client.entity_type})`} />
          </>
        }
        right={
          <>
            <AttributeValue name='Email' value={accreditationRequest.email} />
          </>
        }
      />
      { ['company', 'trust'].indexOf(client.entity_type) > -1 && <AssociatedPerson accreditationRequest={accreditationRequest} />}
    </>
  )
}