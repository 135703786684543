import React, { useEffect } from 'react'
import { runGAEvent } from './components/Tracking/index';
import { PageViewFB } from './components/Tracking/facebook';
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import './spacelaunch.scss';

import {MdPlayArrow} from 'react-icons/md'
import iAriel from '../../src/assets/images/icons/icon-aerialtour.svg';
import iExclusive from '../../src/assets/images/icons/icon-exclusive.svg';
import iPlusOne from '../../src/assets/images/icons/icon-plusone.svg';
import iTravel from '../../src/assets/images/icons/icon-travel.svg';
import aaronSignature from '../../src/assets/images/icons/aaron-sig.svg';
import starShipIcon from '../../src/assets/images/icons/starship-icon.svg';
import skyrocket from '../../src/assets/images/backgrounds/skyrocket@2x.jpg';
import YoutubeEmbed from '../admin/components/shared/YoutubeEmbed';
import RocketLaunch from '../../src/assets/images/graphics/rocket-ship-launch.png';

const CTAdata = [
  {
    header: "Hassle-free Travel & Hotel",
    text: "Our team will take care of the details so you can live in the moment. All you’ll need to do is show up and geek out.",
    img: iTravel
  },
  {
    header: "Unforgettable Aerial Tour",
    text: "Strap into a helicopter/plane and get up close and personal with the Starbase launch site. Can you feel the butterflies?",
    img: iAriel
  },
  {
    header: "Bring A Plus One",
    text: "The best memories are made when you can share them. Who will you be bringing down to South Texas?",
    img: iPlusOne
  },
  {
    header: "Spaced Ventures Exclusive",
    text: "As an early user, you’re already entered to win the prize! We just need you to confirm your participation below.",
    img: iExclusive
  }  
]

function RocketLaunchBanner() {
  return (
    <div className="rocketlaunch-launch-hero">
      <div className="container">
        <div className="rocketlaunch-page-header-1">
          <h2 className="rocketlaunch-hero-header-main f-white ">Seeing is Believing</h2>
          <div className="rocketlaunch-hero-subheader">
            <p className="poppins weight-400 fs-6 f-white">Experience the Next Starship Orbital Launch</p>
            
          </div>
          <div className="pt-3 pb-2">
            <a onClick={() => {runGAEvent("space launch sign up", "clicked", "button")}} href="https://spacedventures.typeform.com/to/JqREO4Ct" target="_blank" rel="noopener noreferrer">
            <HeroButtonStyles>Confirm Participation <MdPlayArrow size={"1.20rem"}/></HeroButtonStyles>
            </a>
          </div>
            <span className="poppins f-white fs-10 weight-400 lh-md italic-style">* No purchase or investment necessary</span>
        </div>
      </div>
    </div>
  )
}

function VideoSection() {
  return (
    <div className="video-section-rocketlaunch">
      <div className="container video-section-spacing-rocketlaunch">
        <div className="section-divider-rocketlaunch pb-5 d-flex justify-content-between">
          <div className="video-container-rocketlaunch">
            <YoutubeEmbed videoUrl="https://youtu.be/1EmeoX7wLpU" />
            <div className="f-white m-t-50">
              <p className="poppins fs-7 weight-700 lh-md">Your chance to witness history in the making, all expenses paid.</p>
              <p className="poppins fs-7 weight-400 lh-md">Can’t wait to see you in Boca Chica!</p>
              <img className="aaron-signature" src={aaronSignature} alt="Aaron Burnett" />
            </div>
          </div>
          <img className="skyrocket" src={skyrocket} alt="Sky rocket" />
        </div>
        <CTASection />
      </div>
    </div>
  )
}

function CTASection () {
  return (
    <>
      <div className="m-t-50">
        <div className="cta-container-rocketlaunch">  
          {
            CTAdata.map((item) => {
              return (
                <div className="f-white poppins text-center">
                  <img src={item.img} alt={item.header} />
                  <h3 className="weight-700 fs-18 m-t-25">{item.header}</h3>
                  <p className="weight-300 fs-default lh-md m-t-5">{item.text}</p>
                </div>
              )
            })
          }
        </div>
        <div className="py-3 rocketlaunch-cta-button-section">
          <a onClick={() => Event("space launch sign up", "clicked", "button")} href="https://spacedventures.typeform.com/to/JqREO4Ct" target="_blank" rel="noopener noreferrer">
              <ButtonStyles>Confirm Participation <MdPlayArrow size={"1.20rem"}/></ButtonStyles>
          </a>
        </div>
      </div>
    </>    
  )
}

function MeetStarship () {
  return (
    <div className="meet-starship-section d-flex align-items-start">
      <div className="container d-flex justify-content-end">
      <div className="meet-text-rocketlaunch f-white">
        <h2 className="meet-launch-header">MEET STARSHIP</h2>
        <p className="poppins weight-400 fs-18 lh-xl m-t-30">According to SpaceX, Starship is a fully reusable transportation system designed to carry both crew and cargo to Earth's orbit, the Moon, Mars and beyond. Starship will be the world’s most powerful launch vehicle ever developed with the ability to carry more than 100 metric tonnes to Earth's orbit. Enough with the stats.</p>
        <p className="poppins weight-400 fs-18 lh-xl">It’s time to watch this thing fly.</p>
      </div>
      </div>
    </div>
  )
}

function MeetStarStruck () {
  return (
    <div className="brownsville-section">
      <div className="container d-flex justify-content-start">
      <div className="meet-text-brownsville f-white">
        <h2 className="meet-launch-header">BE STARSTRUCK</h2>
        <p className="poppins weight-400 fs-18 lh-xl m-t-30">This is your invitation to join the Spaced Ventures team for an experience of a lifetime. In Brownsville, you’ll be surrounded by people who live and breathe aerospace. Can you think of the coolest space conversation you could possibly have? Good, because here, you’ll probably have that.</p>
      </div>
      </div>
    </div>
  )
 }

function SignupSection () {
  return (
  <div className="signup-section-rocketlaunch d-flex align-items-center main-section">
    <div className="container signup-rocketlaunch-section">
      <div className="rocket-image-container-rocketlaunch">
        <img className="rocket-image-launch" src={starShipIcon} alt="starship" />
      </div>
      <div className="cta-text-rocketlaunch">
        <h2 className="montserrat weight-800 fs-3 text-uppercase">Front-row Seat to History</h2>
        <p className="poppins weight-400 fs-18 lh-xl m-t-30">Want to know the best part? As a Spaced Ventures user, you’re already entered to win!</p>
        <p className="poppins weight-400 fs-18 lh-xl m-t-20">We just need you to confirm you want in. Get ready to learn about all the incredible opportunities for innovation this launch will unlock. From the people doing the unlocking, no less. Join us as we stand together and glimpse our future.</p>
        <p className="poppins weight-400 fs-18 lh-xl m-t-20">SEE YOU IN BOCA CHICA!</p>
        <div className="m-t-50">
            <a onClick={() => Event("space launch sign up", "clicked", "button")} href="https://spacedventures.typeform.com/to/JqREO4Ct" target="_blank" rel="noopener noreferrer">
            <WhiteButtonStyles>Confirm Participation <MdPlayArrow size={"1.20rem"}/></WhiteButtonStyles>
            </a>
        </div>        
      </div>
    </div>
  </div>
  )
}

export default function SpaceLaunch () {
  useEffect(() => {
    PageViewFB();
  }, [])

  return (
    <>
      <Helmet>
        <title>Space Launch</title>
        <meta
          name="description"
          content="See a starship launch with Spaced Ventures."
            />
        <meta
          name="robots"
          content="noindex"
            />             
      </Helmet>    
      <RocketLaunchBanner />
      <VideoSection />    
      <MeetStarship />
      <MeetStarStruck />
      <SignupSection />
    </>
  )
}


const ButtonStyles = styled.button`
  width: 320px;
  border: 1px solid #fff;
  padding: 20px 40px 20px ;
  background-color: transparent;
  text-transform: uppercase;
  font-family: 'Poppins';
  font-weight: 600;
  border-radius: 2px;
  color: #fff;
  transition: all 0.3s ease 0s;

  @media (max-width: 600px) {
    width: 100%;
  }

	&:hover {
        color: #000;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 2px;
    }
`;

const WhiteButtonStyles = styled(ButtonStyles)`
    background-color: #fff;
    color: #000;
    &:hover {
        color: #fff;
        background-color: #000;
        border: 1px solid #fff;
        border-radius: 2px;
    }
`;

const HeroButtonStyles = styled(WhiteButtonStyles)`
&:hover {
        color: #fff;
        background-color: #000;
        border: 1px solid #000;
        border-radius: 2px;
    }
`;