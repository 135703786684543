import React from 'react';
import { useParams } from "react-router";
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import { usePreoffer, updatePreoffer } from './queries';
import OverviewForm from './OverviewForm';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';

function OverviewEditor() {
  let { preofferSlug } = useParams()
  const queryClient = useQueryClient();

  const {data:preoffer={}, isLoading} = usePreoffer(preofferSlug)

  const updatePreofferMutation = useMutation(
    updatePreoffer,
    {
      onSuccess: (updatedPreoffer) => {
        ToastMessage.success("Preoffer updated.")
        queryClient.setQueryData(
          ['preoffer', preofferSlug],
          updatedPreoffer
        )
      },
      onError: (error) => {
        ToastMessage.error('Preoffer update failed.')
        renderRailsErrors(error)
      },
    }
  )

  const onSubmit = (values) => {
    updatePreofferMutation.mutate({
      preoffer_id: preoffer.slug,
      values
    })
  }

  if(isLoading)
    return 'Loading...'

  return (
    <OverviewForm preoffer={preoffer} onSubmit={onSubmit}/>
  );
}

export default OverviewEditor;
