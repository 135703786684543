import React, {useContext} from 'react';
import { chatMessageApi } from '../../api/chatMessage';
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import UserMessage from './UserMessage';
import ActionBox from './ActionModal';
import InvestmentMessage from './InvestmentMessage';

export default function MessageList({ chatSession, user, moderation }){
  const deleteModal = useContext(PortalizedModalContext);
  const suspendParticipantModal = useContext(PortalizedModalContext);

  let deleteMessage, suspendParticipant;

  if(moderation){
    deleteMessage = (message) => {
      deleteModal.setModalContent(
        'Delete Message',
        <ActionBox
          modal={deleteModal}
          message='Are you sure you want to delete message?'
          content={message.content}
          action={() => {
            chatMessageApi.destroy(message.id);
            deleteModal.closeModal();
          }}
        />,
        'sm'
      );
      deleteModal.openModal();
    }

    suspendParticipant = (message) => {
      suspendParticipantModal.setModalContent(
      `Suspend Participant : ${message.chat_participant.user_name}`,
      <ActionBox
        modal={suspendParticipantModal}
        message={`Suspend Participant : ${message.chat_participant.user_name}`}
        content=""
        action={() => {
          chatMessageApi.suspendParticipant(message.chat_participant.id);
          suspendParticipantModal.closeModal();
        }}
      />,
      'sm'
      );
      suspendParticipantModal.openModal()
    }
  } else {
    deleteMessage = (message) => {
      chatMessageApi.destroy(message.id)
    }
  }

  // Reverse messages and render.
  return (
    chatSession.messages.map((m, i, arr) => {
      const message = arr[arr.length - 1 - i];
      const canDelete = (moderation || message.chat_participant?.user_id == user?.id) && !message.deleted_at;
      const canSuspend =  message.chat_participant?.status != 'suspended'

      if(message.category === 'investment'){
        return <InvestmentMessage  key={message.id} message={message} />
      }else{
        return <UserMessage
          key={message.id}
          message={message}
          moderation={moderation}
          deleteMessage={ canDelete && deleteMessage}
          suspendParticipant={canSuspend && suspendParticipant}
        />
      }
    })
  )
}