import React from 'react';
import AddCcAccountButton from "../CreditCardPaymentForm/AddCcAccountButton"
import AddACHButton from "../AchPaymentForm/AddAchAccountButton";
import { useQueryClient } from 'react-query';

const buttonElement = (text) => {
  return (({onClick}) => {
    return (
      <span className="underline text-white fs-10 weight-600 cursor-pointer mr-2" onClick={onClick}>{text}</span>
    )
  })
}

export default function UpdatePaymentSection ({investment}){
  const queryClient = useQueryClient();
  const getFailureMessage = () => investment.investment_payments[0]?.failure_reason;

  const onAddSuccess = React.useCallback(() => {
    queryClient.invalidateQueries("investment", investment.id);
  }, [investment]);

  return (
    <div className="investment-detail-requirement mb-3">
      <h6 className="montserrat weight-700 fs-8 mb-3">Payment Issue</h6>
      {investment.payment_failed &&
        <p className="fs-10">
          Failure Reason: {getFailureMessage()}
        </p>
      }
      <p>
        There was an issue with the payment info you provided. Please resubmit your payment information to complete your investment.
      </p>

      <AddCcAccountButton
        clientId={investment.client_id}
        buttonElement={buttonElement('Resolve using Credit Card')}
        onSuccess={onAddSuccess}
        extras = {{
          resolves_payment_issues: {
            investment_ids: [investment.id]
          }
        }}
      />

      <AddACHButton
        clientId={investment.client_id}
        buttonElement={buttonElement('Resolve using ACH Account')}
        onSuccess={onAddSuccess}
        extras = {{
          resolves_payment_issues: {
            investment_ids: [investment.id]
          }
        }}
      />
    </div>
  )
}
