import React, {useEffect} from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import ToastMessage from '../../../components/ToastMessage';
import HookForm from 'components/HookForm';
import { liveEventApi } from 'api/liveEvent';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { parseDate } from 'jsUtils/dateTimeUtils';
import { statusOptions } from '../../../components/LiveEvent/StatusUtil';

const FormSchema = Yup.object().shape({
  title: Yup.string().required().max(200, 'Maximum ${max} characters allowed'),
  description: Yup.string().required().max(500, 'Maximum ${max} characters allowed'),
  status: Yup.string().oneOf(['upcoming', 'pre_live', 'live', 'closed', 'completed']).default('upcoming'),
  scheduled_time: Yup.date().required(),
  youtube_url: Yup.string().nullable().when('status', {
    is: (status) => status !== 'upcoming',
    then: Yup.string().required().url('enter valid youtube url'),
    otherwise: Yup.string().optional().url('enter valid youtube url'),
  }),
  goal_amount: Yup.number().typeError('Invalid amount').positive()
});

function transformFieldValues(data, forForm = false){
  const values = {
    title: data.title,
    description: data.description,
    status: data.status,
    scheduled_time: data.scheduled_time,
    youtube_url: data.youtube_url,
    goal_amount: parseFloat(data.goal_amount)
  };

  if(forForm){
    values.scheduled_time = parseDate(values.scheduled_time);
  }

  return values;
}

export default function Form({ offerId, liveEvent, setCurrentLiveEvent }) {
  useEffect(()=>{
    if(liveEvent){
      methods.reset(transformFieldValues(liveEvent, true));
    }
  },[liveEvent])

  const isEditForm = liveEvent.id;
  const defaultValues = {
    title: '',
    description: '',
    status: 'upcoming',
    scheduled_time: null,
    youtube_url: '',
    goal_amount: 0.0
  };
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: defaultValues
  });

  const queryClient = useQueryClient();
  const saveLiveEvent = useMutation(
    isEditForm ? liveEventApi.update : liveEventApi.create,
    {
      onSuccess: (values) => {
        setCurrentLiveEvent(null);
        queryClient.invalidateQueries(['liveEvents', offerId]);
        ToastMessage.success(
          `Live event successfully ${liveEvent ? 'updated' : 'added'}.`
        );
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  )

  const onSubmit = data => {
    saveLiveEvent.mutate({
      id: liveEvent.id || offerId,
      attrs: transformFieldValues(data)
    })
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-1" autoComplete="off">
        <div className="row mt-3">
          <div className="col input-bg-white">
            <HookForm.TextInput name="title" placeholder="Title"/>
          </div>
        </div>
        <div className="row">
          <div className="col input-bg-white">
            <HookForm.TextArea name="description" placeholder="Description" rows={6}/>
          </div>
        </div>
        <div className="row">
          <div className="col-6 input-bg-white">
            <HookForm.DateTimeField name="scheduled_time" placeholder="Scheduled Time"/>
          </div>
          <div className="col-6 input-bg-white">
            <HookForm.SelectInput
              name="status"
              placeholder="Status"
              options={statusOptions()}
              labelClass="z-index-1000"
              className="ml-1"
            />
          </div>
        </div>
        <div className="row">
          <div className="col input-bg-white">
            <HookForm.TextInput name="youtube_url" placeholder="Youtube embed URL"/>
          </div>
        </div>
        <div className="row">
          <div className="col-6 input-bg-white">
            <HookForm.TextInput name="goal_amount" type="number" placeholder="Goal amount"/>
          </div>
        </div>
        <div className="form-group mt-3">
          <button type="submit"
            className="mx-auto zeitung-mono weight-300 btn btn-outline-dark rounded-0"
            disabled={saveLiveEvent.isLoading}
          >
            { isEditForm ? 'Update' : 'Add' }
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
