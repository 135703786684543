import React from 'react'
import OfferNavLinkItems from './OfferNavLinkItems'

const OfferNavMobileDropdown = ({offer=null, navBarItems, currentTab}) =>{
  return(
    <div className="offer-nav-dropdown-container">
      <div className="offer-nav-dropdown dropdown black-dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">{currentTab}</button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div className="nav flex-column">
            <OfferNavLinkItems offer={offer} navBarItems={navBarItems} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferNavMobileDropdown;