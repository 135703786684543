import React from 'react'
import { getPaymentAmount } from '../../../jsUtils/currencyUtils';
import { paymentTypeText } from '../../../jsUtils/offerUtils';
import Tooltip from '../../../pages/components/Tooltip/Tooltip';
import { FaQuestionCircle } from 'react-icons/fa';
import SingleInvestmentDetailsRow from '../shared/SingleInvestmentDetailsRow';
import WirePaymentDetails from './WirePaymentDetails';

export default function PaymentDetail({ investment, investorCountry, adminFee }) {
  const paymentMethod = investment.payment_method;
  const payments = investment.investment_payments;
  const paymentMethodType = paymentMethod?.type;
  let accountName;
  if (paymentMethod) {
    accountName = paymentMethod.account_name && paymentMethod.account_number_last_four ? `${paymentMethod.account_name} (${paymentMethod.account_number_last_four})` : null;
  } else {
    accountName="--NOT FOUND--"
  }

  let tooltipText;

  if (paymentMethodType === 'wire') {
    tooltipText = 'Please wire the investment amount plus the wire fee to pay for your investment.'
  } else if (paymentMethodType === 'credit_card') {
    tooltipText = 'There is a 3.75% Credit Card processing fee in addition to your investment amount. This is the total amount charged that will appear on your credit card statement.'
  }

  return (
    <>
      <h5 className="montserrat weight-700 mb-2">Payment</h5>
      <div className='payment-type-and-ammount'>
        <table>
          <tbody>
            <tr>
              <td>
                <div className='payment-type-and-ammount-col inner1'>
                  <div className='montserrat fs-10'>Payment Type</div>
                  <div className='poppins weight-700'>{paymentTypeText(paymentMethodType)}</div>
                </div>
              </td>
              <td>
                <div className='payment-type-and-ammount-col inner2'>
                  <div className='left'>
                    <div className='montserrat fs-10'>Payment Amount</div>
                    <div className='poppins weight-700'>{getPaymentAmount(paymentMethodType, investment.amount, investorCountry, adminFee)}</div>
                  </div>
                  {
                    tooltipText && (
                      <Tooltip xAlignment="-50px" yAlignment='30px' text={tooltipText}>
                        <div className='right'><FaQuestionCircle /></div>
                      </Tooltip>
                    )
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {accountName && (
        <div className='mt-1'>
          <SingleInvestmentDetailsRow title={'Payment used'} desc={accountName} />
        </div>
      )}
      {payments && (
        <div className='mt-1'>
          <SingleInvestmentDetailsRow title={'Payment status'} desc={payments[0]?.status || "-"} />
        </div>
      )}
      {paymentMethodType === "wire" && (
        <WirePaymentDetails investment={investment} />
      )}
    </>
  )
}