import React, { useEffect } from "react";
import { useField, useFormikContext, ErrorMessage, Field } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { parseDateWithoutTimezone } from "../../jsUtils/dateTimeUtils";
import "./DatePickerField.scss";

const DatePickerField = ({ ...props }) => {
  const { validateField } = useFormikContext();
  const [field] = useField(props);
  const [value, setValue] = React.useState(parseDateWithoutTimezone(props.initialValue) || "");

  useEffect(() => {
    validateField(field.name);
  }, [field.value, field.name, validateField]);
  return (
    <>
      <Field type="date" name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          return (
            <DatePicker
              selected={value}
              id={field.name}
              name={field.name}
              dateFormat="MM/dd/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable={true}
              shouldCloseOnSelect={true}
              className={`form-control ${
                meta.touched && meta.error ? "is-invalid" : ""
              }`}
              value={value}
              onBlur={(e) => form.handleBlur(e)}
              placeholder={props?.placeholder}
              onChange={(date) => {
                const value = date || ''
                form.setFieldTouched(field.name);
                form.setFieldValue(field.name, value);
                setValue(value);
              }}
            />
          );
        }}
      </Field>
      <label className="date-picker-label" htmlFor={props.name}>{props.placeholder ?? ""}</label>
      <ErrorMessage
        name={props.name}
        component="div"
        render={(msg) => (
          <div className="invalid-feedback text-left date-picker-error">
            {msg}
          </div>
        )}
      />
    </>
  );
};

export default DatePickerField;
