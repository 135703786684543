import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import astronomy from '../../assets/images/icons/astronomy-planet-ring-star-lg.png'

class Default extends Component {
  render() {
    return (
      <PageNotFound>
      <div className="page-not-found-content container">
          <img className="not-found-icon" src={astronomy} alt="astronomy icon"/>
          <h2 className="montserrat fs-6 weight-700 m-t-30">You’ve reached the edge of Spaced!</h2>
          <p className="fs-8 m-t-10">The page you’re looking for was not found.</p>
          <Link to="/"><button className="page-not-found-button m-t-15"><div>Travel Back</div></button></Link>
      </div>
      </PageNotFound>
    );
  }
}

export default Default;

const PageNotFound = styled.div`
  background: #111;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;

  .page-not-found-content {
    padding-top: 180px;
  }


  .page-not-found-button {
    background-color: #111;
    font-family: zeitung-mono, sans-serif;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 30px;
    transition: all 0.3s ease 0s;
    border-radius: 4px;
  }

  .page-not-found-button:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #111;
    border-radius: 4px;
  }  

  .not-found-icon {
    width: 104px;
  }

  @media (max-width: 700px) {
    .page-not-found-content {
      padding-top: 150px;
    }
  }
  
`;