import React, { useState } from 'react';

const faqGeneralQuestions = [
	{
		question: "What types of companies are on the Spaced Ventures?",
		answer: "Investors on Spaced Ventures are able to invest in highly-vetted early-stage space companies operating across many different sectors of the emerging space economy. ",
		accordionId: "accordionOne",
		collapseId: "collapseOne"

	},
	{
		question: "How does Spaced Ventures vet its companies?",
		answer: "Companies listed on Spaced Ventures go through a rigorous internal and external due diligence process. Every company is evaluated by Spaced Ventures staff and a panel of industry experts including scientists, engineers and other seasoned entrepreneurs. Companies are evaluated against a comprehensive rubric that covers their core technology, product-market fit, traction, company leadership and advisors and more. Only the top 10% companies that apply are approved to be listed on Spaced Ventures.",
		accordionId: "accordionTwo",
		collapseId: "collapseTwo"

	},
	{
		question: "Is an issuer and company the same thing?",
		answer: "Yes, the companies listed on Spaced Ventures have all agreed to issue part of their shares to the general public through equity crowdfunding. We use the terms “company, issuer and issuing company,” interchangeably.",
		accordionId: "accordionThree",
		collapseId: "collapseThree"

	},			
	{
		question: "What happens prior to a company listing?",
		answer: "Spaced Ventures works with each issuer and assists them in preparing their offering materials. Offerings posted on Spaced Ventures must include all of the information required by the SEC under Regulation Crowdfunding. This includes basic identifying information about the issuer, the business and anticipated business plan of the issuer, the people operating the issuer and the financial condition of the issuer. Investors may decide that they need more information to make a decision, which is why Spaced Ventures provides communication channels for investors to ask questions of each issuing company and its leadership.",
		accordionId: "accordionFour",
		collapseId: "collapseFour"
	},
	{
		question: "What happens after a offering is posted?",
		answer: "Each offering is listed on Spaced Ventures for a set amount of days. Currently, any US resident can make an investment through the portal. Investments are held in escrow until a project successfully reaches its funding goal. If the project does not reach its funding goal, funds are returned to investors. If a project successfully reaches its funding goal within the offering timeline, funds are transferred to the issuer and the investor is issued securities.",
		accordionId: "accordionFive",
		collapseId: "collapseFive"		
	},
	{
		question: "What are the requirements to invest on Spaced Ventures?",
		answer: "Anyone who is over 18 years of age and a resident of the United States of America can invest in companies through Spaced Ventures. In the future, residents of other countries will also be eligible to invest. You can enter your contact information here to be to be notified when we open up to international investors.",
		accordionId: "accordionSeven",
		collapseId: "collapseSeven"		
	},		
	{
		question: "What is a rolling close and how does it affect my investment?",
		answer: `
			<p class="zeitung fs-8">A company may choose to do a rolling close to receive early access to some of the money raised so they can start using it to grow their business. The name comes from the fact that the investments are closed on a rolling basis, as opposed to at the end of the campaign.</p>
			<p class="zeitung fs-8">If you have made an investment in a campaign before the rolling close:</p>
			<ul class="px-4 zeitung fs-8">
			<li>You will receive a notification announcing when the rolling close will happen (5 business days or more in advance)</li>
			<li>If you don’t cancel or lower your investment, it will become finalized at the rolling close date, just like at the end of a regular campaign. You will not be able to change your investment after the rolling close.</li>
			<li>The funds raised to date and closed upon will be sent to the startup, and their campaign will continue.</li>
			<li>If you wish to cancel or lower your investment, you will need to do so more than 72 hours before the announced rolling close date.</li>
		</ul>`,
		accordionId: "accordionEight",
		collapseId: "collapseEight"		
	},
	{
		question: "How to do I cancel my investment?",
		answer: "Investors may cancel their investment up to 72 hours before the deal close. In the case that there is a material change during an offering, investors will be notified and an investor must reconfirm their investment within 5 days. Otherwise the investment will be considered cancelled. ",
		accordionId: "accordionNine",
		collapseId: "collapseNine"		
	},	
	{
		question: "What kind of securities can I invest in?",
		answer: "Investments on Spaced Ventures may be convertible securities or equity. Each type of investment carries different terms that are unique to each issuer.",
		accordionId: "accordionTen",
		collapseId: "collapseTen"		
	},		
	{
		question: "Are these investments risky?",
		answer: "Investments in Regulation Crowdfunding offerings are speculative and involve a high degree of risk. This is in part because the issuers are often early-stage companies with little or no operating history. Investments on Spaced Ventures do not include voting rights and investors will have no role in directing management of the companies they invest in. Investors who cannot afford to lose their entire investment should not invest. In addition, investors should consult their own financial advisor prior to making an investment.",
		accordionId: "accordionEleven",
		collapseId: "collapseEleven"		
	},		
	{
		question: "Are there restrictions on these securities?",
		answer: "Securities purchased in Regulation Crowdfunding transactions generally cannot be resold for a period of one year, unless the securities are transferred: (1) to the issuer of the securities; (2) to an “accredited investor”; (3) as part of an offering registered with the Commission; or (4) to a member of the family of the purchaser or the equivalent, to a trust controlled by the purchaser, to a trust created for the benefit of a member of the family of the purchaser or the equivalent, or in connection with the death or divorce of the purchaser or other similar circumstance.",
		accordionId: "accordionTwelve",
		collapseId: "collapseTwelve"		
	},
	{
		question: "What is an accredited investor?",
		answer: "Generally speaking, individuals with an annual income of $200,000 or a net worth exceeding $1 million can qualify to become accredited investors. These individuals are considered to have some level of sophistication in terms of financial knowledge and can thus invest more money than non-accredited investors can through Regulation Crowdfunding each year. Upon sign-up, all Spaced Ventures users will have the opportunity to indicate whether or not they believe they qualify to invest as an accredited investor. All users, regardless of their investor status, have access to all investment deals on Spaced Ventures.",
		accordionId: "accordionThirteen",
		collapseId: "collapseThirteen"		
	},	
	{
		question: "How do I get ongoing information about my investments?",
		answer: `<div>
		<p class="zeitung fs-8">An issuer that has offered and sold securities through Regulation Crowdfunding must file with the SEC and post an annual report along with their financial statements as certified by the principal executive officer of the company on their  website. An issuer who has available financial statements that have either been reviewed or audited by a public accountant independent of the issuer may instead provide those financial statements on their website (voiding the requirement of certification by their principal executive officer). The report (also known as Form C-AR) must be filed no later than 120 days after the end of the fiscal year covered by the report.</p>
		<p class="zeitung fs-8">As a courtesy to our investors, Spaced Ventures will also send out periodic space industry news and updates on issuing companies through our newsletter. Non-investors may also elect to receive these updates by signing up for them here.</p>
	</div>`,
		accordionId: "accordionFourteen",
		collapseId: "collapseFourteen"		
	},			
	{
		question: "How much can I  invest on Spaced Ventures?",
		answer: `	<div>
		<p class="zeitung fs-8">Individuals are limited in the amounts they are allowed to invest in all Regulation Crowdfunding offerings over the course of a 12-month period:</p>
		<p class="zeitung fs-8">If either of an investor’s annual income or net worth is less than $107,000, then the investor’s investment limit is the greater of:</p>
		<ul class="px-4 zeitung fs-8">
			<li>$2,200 or</li>
			<li>5 percent of the lesser of the investor’s annual income or net worth.</li>	
		</ul>
		<p class="zeitung fs-8">If both annual income and net worth are equal to or more than $107,000, then the investor’s limit is 10 percent of the lesser of their annual income or net worth.</p>
		<p class="zeitung fs-8">During the 12-month period, the aggregate amount of securities sold to an investor through all Regulation Crowdfunding offerings (i.e. all the Regulation Crowdfunding investments you make, not just those you make through Spaced Ventures) may not exceed $107,000, regardless of the investor’s annual income or net worth.</p>
		<p class="zeitung fs-8">Spouses are allowed to calculate their net worth and annual income jointly.</p>
	</div>`,
		accordionId: "accordionFifteen",
		collapseId: "collapseFifteen"		
	},			
	{
		question: "Where can I find more SEC resources?",
		answer: `<p>The SEC has prepared a guide that can be helpful for investors to better understand the specific regulations: <a href='https://www.sec.gov/oiea/investor-alerts-bulletins/ib_crowdfunding-.html'>Crowdfunding for Investors</a></p>`,
		accordionId: "accordionSixteen",
		collapseId: "collapseSixteen"		
	},																	

]

function createMarkup(html) {
  return {__html: html};
}

function Faq({question, answer, accordionId, collapseId, section}){
const [open, setOpen] = useState(false);
	return (	
		<div data-toggle="collapse" data-target={`#${section}-${collapseId}`} aria-expanded="true" aria-controls={`${section}-${collapseId}`} className="faq-container"  onClick={() => setOpen(!open)}>
			<div className="faq-header" aria-expanded={`${open}`}>
				<div className="montserrat faq-question-text">{question}</div>
				<span className="chevron"></span>			
			</div>
				<div id={`${section}-${collapseId}`} className="collapse zeitung fs-8 faq-question">
			
				<div dangerouslySetInnerHTML={createMarkup(answer)} />
			</div>
		</div>
	)
}

function FaqSection({questionsArr, sectionTitle, section}){
	return (
		<div className="m-t-50 ">
			{sectionTitle && <h2 className="montserrat fs-7 weight-900 text-center pb-2">{sectionTitle}</h2>}
			{questionsArr.map((faq) => {
				return <Faq section={section} question={faq.question} answer={faq.answer} collapseId={faq.collapseId} accordionId={faq.accordionId} />
			})}
		</div>
	)
}

export const InvestorFaq = () => (
<div className="page-height-dynamic">
	<div className="pattern-faq">
			<div className="main-section ">
				<div className="container">	
							<h1 className="montserrat text-center weight-900 fs-1">FAQs</h1>
							<FaqSection questionsArr={faqGeneralQuestions} section="general" />	
					</div>
			</div>
	</div>
</div>
)

