import React from "react";
import ReactDOM from "react-dom";
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import './PortalizedModal.scss';
import bgVideo from '../../assets/videos/petition-bg.mp4'

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex:999
  },
  content : {
    borderRadius: 0,
    position: 'fixed',
    padding: 0,
    top: '50%',
    right: 'unset',
    bottom: 'unset',
    left: '50%',
    transform: 'translate(calc(-50% - 1px), -50%)',
    backgroundColor:'rgba(0, 0, 0, 0.0)',
    border:'0',
    zIndex: '2',
    overflowY: 'auto'
  }
}

const CloseBtn = ({onClick}) =>{
  return(
    <button className="drawer-close-btn"
    onClick={onClick}
    >
    <MdClose color="#fff" size="25px" />
  </button>
  )
}

// layoutClasses: "headless-modal" | "video-bg-modal"
// size: "sm" | "md" | "lg" | "admin-page"

export default function PortalizedModal({openModals}) {
  return openModals.map(({modal}) => {
    const showVideoBg = modal.layoutClasses?.includes('video-bg-modal')

    return ReactDOM.createPortal(
      <Modal
        portalClassName={`fadein-modal portalized-modal-${modal.size || 'md'} ${modal.layoutClasses}`}
        ariaHideApp={false}
        isOpen={true}
        onRequestClose={modal.onRequestClose}
        style={modalStyles}
        closeTimeoutMS={500}
      >
        <div className="portalized-modal-wrapper">
          {showVideoBg && <div className="portalized-modal-background-video-container">
            <video autoPlay muted playsInline loop>
              <source src={bgVideo} type="video/mp4" />
              Your browser does not support this video.
            </video>
          </div>}
          <div className="portalized-modal-header">
            {modal.title && <h5 className="portalized-modal-title">{modal.title}</h5>}
            <CloseBtn onClick={modal.onRequestClose}/>
          </div>
          <div className="portalized-modal-body">
            {modal.body}
          </div>
        </div>
      </Modal>,
      document.querySelector("#modal-root")
    );
  })
}
