import React from 'react'
import { format, parseJSON } from 'date-fns';
import { humanize } from '../../../jsUtils/stringUtils';
import { getTimeZone } from '../../../jsUtils/dateTimeUtils';
import EditInvestmentForm from './EditInvestmentForm';
import SingleInvestmentDetailsRow from '../shared/SingleInvestmentDetailsRow';
import AmountWithCurrency from '../../../components/AmountWithCurrency';
import PaymentDetail from './PaymentDetail';
import CancellationDetail from './CancellationDetail';
import RollingCloseDetail from './RollingCloseDetail';
import { AuthContext } from '../../../contexts/authContext';
import { useUser } from '../../../redux/user';
import { MdWarning } from "react-icons/md"
import { formatCurrency } from '../../../jsUtils/currencyUtils';

export default function InvestmentSection({ offer, investment, closeDate, setLoader, adminFee }) {
  const cancellation_last_date = new Date(closeDate)
  const user = useUser();
  const today = new Date();
  const { authData } = React.useContext(AuthContext);
  const currentClient = user.clients?.find(
    (client) => client.id == investment?.client_id
  );
  const canCancelInvestment = investment.cancellation_requested_at === null && investment.cancelled_at === null && investment.status !== 'settled' && investment.status !== 'invested' && cancellation_last_date >= today;
  const canViewRollingClose = investment.close_date && investment.status !== 'settled' && investment.status !== 'invested' && investment.status !== 'cancelled';
  const isInvested = ['settled', 'invested'].indexOf(investment.status) > -1;
  const investmentTypeText = investment?.category === "spv" ? "SPV" : "Direct";

  return (
    <>
      <div className="mb-4 investment-section">
        <h5 className="montserrat weight-700 mb-2 pr-4">My Investment</h5>
        {investment?.status !== 'settled' &&
          <EditInvestmentForm offer={offer} investment={investment} setLoader={setLoader} />}
        <SingleInvestmentDetailsRow
          title={'Investment amount'}
          desc={
            <div className='my-investment-amount'>
              <AmountWithCurrency number={investment?.amount} />
            </div>}
        />
        <SingleInvestmentDetailsRow title={'Invested as'} desc={investment?.investing_as} />
        <SingleInvestmentDetailsRow title={'Investment date'} desc={format(parseJSON(investment?.created_at), 'MMM dd, yyyy hh:mm a ') + getTimeZone()} />
        <SingleInvestmentDetailsRow title={'Investment status'} desc={humanize(investment?.status || '')} />
        <SingleInvestmentDetailsRow title={'Unit price'} desc={formatCurrency(investment?.unit_price) || ''} />
        {investment?.category && investment?.category !== "none" && <SingleInvestmentDetailsRow title={'Investment type'} desc={investmentTypeText ?? '-'} />}
        {(investment.cancellation_requested_at && !investment.cancelled_at) && 
          <div class="border border-warning bg-white">
            <div className="p-1 pl-2 d-flex align-items-center bg-warning">
              <MdWarning /><div className="fs-14">Cancellation in progress</div>
            </div>
            <SingleInvestmentDetailsRow title={'Requested at'} desc={format(parseJSON(investment?.cancellation_requested_at), 'MMM dd, yyyy hh:mm a ') + getTimeZone()} />
          </div>
          }
        {investment.cancelled_at && <SingleInvestmentDetailsRow title={'Cancelled at'} desc={format(parseJSON(investment?.cancelled_at), 'MMM dd, yyyy hh:mm a ') + getTimeZone()} />}
      </div>
      <div className="mb-4 payment-section">
        <PaymentDetail investment={investment} investorCountry={currentClient?.country} adminFee={adminFee}/>
      </div>
      {canCancelInvestment && <CancellationDetail rollingCloseDate={investment.close_date} investment={investment} closeDate={closeDate} setLoader={setLoader} />}
      {canViewRollingClose && <RollingCloseDetail investment={investment} />}
      {isInvested && (
        <div className='investment-handler-btn'>
          <a href={investment.subscription_agreement_signed_pdf_url} className='btn-black-outline btn-block unstyled' target="_blank" rel="noopener noreferrer">
            Subscription Agreement
          </a>
          <a href={`${process.env.REACT_APP_API_BASE_URL}/investments/${investment.id}/trade_confirmation.pdf`} className='btn-black-outline btn-block mt-3 unstyled' target="_blank" rel="noopener noreferrer">
            Trade Confirmation
          </a>
        </div>
        )
      }
    </>
  )
}