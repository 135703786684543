import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {DEFAULT_PAGE_SIZE} from './../../config/constants'
import OverviewModal from 'admin/components/OverviewModal'
import InvestorOverview from 'admin/components/InvestorOverview'
import Paginator from 'admin/components/Paginator'
import { FaUserAstronaut } from "react-icons/fa";
import { toTitlecase } from '../../jsUtils/stringUtils';
import { useInvestor, exportList, list, _clearInvestors } from 'redux/investor';
import LoadingOverlay from 'react-loading-overlay';
import { formatDate } from '../../jsUtils/dateTimeUtils';
import {resend_confirmation_email} from 'redux/user'
import { confirmEmail, bulkSendReminderEmail, archiveUser, deleteUser, unArchiveUser } from "./../queries/index";
import { PortalizedModalContext } from "contexts/portalizedModalContext";
import { useMutation } from "react-query";
import ToastMessage from '../../components/ToastMessage';
import { renderRailsErrors } from 'jsUtils/railsErrorRenderer';
import { AuthContext } from 'contexts/authContext';
import {ACTIONS}  from 'config/adminAbilities';
import { ResponsiveTableWithRowSelect, ResponsiveTable } from 'admin/components';
import {
  CurrencyBadge,
  FormattedNumberWithSymbol,
} from '../../components/AmountWithCurrency';
import TableActionsContainer from '../components/TableActionsContainer';
import TableActionItem from '../components/TableActionItem';
import { FaCheckCircle, FaEnvelope, FaArchive, FaTrash } from 'react-icons/fa';
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HookForm from '../../components/HookForm';

const InvestorRow = function({ investor, onClick }){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase(investor.entity_name)}</div></td>
      <td className="d-flex align-items-center">{investor.email || '-'}</td>
      <td className="d-flex align-items-center">{investor.service_provider }</td>
      <td className="d-flex align-items-center">{formatDate(investor.created_at) || '-'}</td>
      <td className="d-flex align-items-center">{formatDate(investor.client_created_at) || '-'}</td>
      <td className="d-flex align-items-center">{formatDate(investor.first_investment_at, "MMM dd, yyyy, hh:mm a")}</td>
      <td className="d-flex align-items-center">{investor.city}</td>
      <td className="d-flex align-items-center">{investor.region}</td>
      <td className="d-flex align-items-center">{toTitlecase(investor.entity_type)}</td>
      <td className="d-flex align-items-center">{investor.investments_count || '-'}</td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={investor.total_investment} /></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={investor.average_investment} /></td>
      <td className="d-flex align-items-center">{investor.accredited ? 'Yes' : 'No' }</td>
    </tr>
  )
}

const UserRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>props.onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase([props.first_name, props.last_name].filter(Boolean).join(' '))|| '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center">{props.signup_mode}</td>
    </tr>
  )
}

const MemberRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>props.onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase(props.entity_name) || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.client_created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.investment_limit_created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.city || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.region || '-'}</div></td>
      <td className="d-flex align-items-center">{props.entity_type === 'person' ? 'Individual' : 'Entity'}</td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.annual_income} /></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.net_worth} /></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.outside_investment_amount} /></td>
      <td className="d-flex align-items-center"><div>{props.accredited ? 'Yes' : 'No' }</div></td>
      <td className="d-flex align-items-center"><div>{props.job_title || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.job_industry || '-'}</div></td>
    </tr>
  )
}

const VerifiedAccreditedRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>props.onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase(props.entity_name) || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.client_created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.city || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.region || '-'}</div></td>
      <td className="d-flex align-items-center">{props.entity_type === 'person' ? 'Individual' : 'Entity'}</td>
      <td className="d-flex align-items-center"><div>{props.accredited ? 'Yes' : 'No' }</div></td>
    </tr>
  )
}


const InvestmentRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase([props.first_name, props.last_name].filter(Boolean).join(' '))|| '-'}</div></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.amount} /></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.country  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.region || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.city || '-'}</div></td>
      <td className="d-flex align-items-center">{props.street_address_1 || '-'}</td>
      <td className="d-flex align-items-center"><div>{props.accredited ? 'Yes' : 'No' }</div></td>
      <td className="d-flex align-items-center"><div>{props.status || '-'}</div></td>
    </tr>
  )
}

const AdvocateRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>props.onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase(props.entity_name) || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.client_created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.city || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.region || '-'}</div></td>
      <td className="d-flex align-items-center">{props.entity_type === 'person' ? 'Individual' : 'Entity'}</td>
      <td className="d-flex align-items-center"><div>{props.investments_count || '-'}</div></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.total_investment} /></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.average_investment} /></td>
      <td className="d-flex align-items-center"><div>{props.job_title || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.job_industry || '-'}</div></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.outside_investment_amount} /></td>
    </tr>
  )
}

const PrelaunchSubscriberRow = function(props){
  return (
    <tr className="f-black fs-10" style={{backgroundColor: '#fff'}} onClick={()=>props.onClick()}>
      <td className="d-flex align-items-center"><FaUserAstronaut color='#676767' className="mr-2 astronaut-icon" /> <div className='investor-name'>{toTitlecase([props.first_name, props.last_name].filter(Boolean).join(' '))|| '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.email || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.created_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{formatDate(props.first_subscription_at)  || '-'}</div></td>
      <td className="d-flex align-items-center"><div>{props.subscription_count || '-'}</div></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.total_interest_amount} /></td>
      <td className="d-flex align-items-center"><FormattedNumberWithSymbol number={props.average_interest_amount} /></td>
    </tr>
  )
}

const resendEmail = email =>{
  const attrs = {
    "email": `${email}`
  }
  return resend_confirmation_email(attrs)
}

export default function ListInvestorsPage(props) {
  const data = useInvestor().data;
  const listMeta = useInvestor().pagy;
  const loading = useInvestor().loading
  const [currentInvestorId, setCurrentInvestorId] = useState(null)
  const [q, setQ] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const { authData } = React.useContext(AuthContext);
  const canDownloadInvestorsCSV = authData.permittedActions.has(ACTIONS.READ_DOWNLOAD_INVESTORS)
  const [bulkReminderEmailUserIds, setBulkReminderEmailUserIds] = useState([]);
  const bulkReminderEmailModal = React.useContext(PortalizedModalContext);

  useEffect( () => {
    list(filterValue, q, 1, DEFAULT_PAGE_SIZE);
    return () => {
      _clearInvestors();
    }
  }, [filterValue])

  useEffect( () => {
    if(currentInvestorId)
      document.body.style.overflow = 'hidden';
    else
      document.body.style.overflow = 'unset';

  }, [currentInvestorId])

  const _paginateInvestors = (page, per_page = DEFAULT_PAGE_SIZE) => {
    list(filterValue, q, page, per_page)
  }

  const debouncedSearch = React.useCallback(_.debounce((q, filterValue) => {
    list(filterValue, q, 1, DEFAULT_PAGE_SIZE);
  }, 500), [])

  const  handleClick = (e) => {
    setQ('')
    setFilterValue(e.target.value)
  }

  const bulkReminderEmailConfirmation = useMutation(
    bulkSendReminderEmail,
    {
      onSuccess: (res) => ToastMessage.success(`Reminder email sent successfully to ${res.length} user(s).`),
      onError: (error) => {
        ToastMessage.error('Could not send Reminder emails.')
        renderRailsErrors(error)
      },
    }
  )

  const onBulkReminderEmail = () => {
    bulkReminderEmailModal.setModalContent(
      'Reminder Email',
      <div>
        <p>Are you sure you want to send reminder email to {bulkReminderEmailUserIds.length} user(s)?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              bulkReminderEmailConfirmation.mutate({ user_ids: bulkReminderEmailUserIds })
              bulkReminderEmailModal.closeModal()
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={bulkReminderEmailModal.closeModal}>Cancel</button>
        </div>
      </div>,
      'sm'
    );
    bulkReminderEmailModal.openModal();
  }

  return (
  <LoadingOverlay
    active={loading}
    spinner
    text='Loading ...' >
    <div className="bg-lightgrey">
      <div  className="admin-main-content-header align-items-center">
        <div className="amch-inner1">
          <div className="input-group ig-field mr-3">
          <input
            type="search"
            placeholder="Search"
            value={q}
            onChange={(e) => {
              const q = e.currentTarget.value;
              setQ(q)
              debouncedSearch(q, filterValue)
            }}/>
          </div>
          <select onChange={(e)=>{handleClick(e)}} className="custom-dropdown2">
            <option value="">Investors</option>
            <option value="investments">Investments</option>
            <option value="users">Users</option>
            <option value="unconfirmed">Incomplete</option>
            <option value="members">Members</option>
            <option value="advocates">Advocates</option>
            <option value="self_attested">Self Attested</option>
            <option value="verified">Verified Accredited</option>
            <option value="archived">Archived Users</option>
            <option value="preoffer_subscribers">Prelaunch Subscribers</option>
          </select>
        </div>
        <div >
        {
          filterValue && filterValue === "unconfirmed" &&
            <button
              type="button"
              className="btn btn-outline-dark mr-5 text-capitalize"
              onClick={() => onBulkReminderEmail()}
              disabled={bulkReminderEmailUserIds.length === 0}
            >
              Resend Follow Up Email
            </button>
        }
        {
          canDownloadInvestorsCSV &&
            <span className="cursor-pointer underline fs-10 poppins" onClick={() => exportList(filterValue, q)}>Export list</span>
        }
        </div>
      </div>
      <Paginator
        theme='dark'
        onPage={_paginateInvestors}
        meta={listMeta}
      />
      <div className="table-responsive px-3 investor-filter-table">
        {listTable(filterValue, data, setCurrentInvestorId, setBulkReminderEmailUserIds, listMeta)}
        {
        currentInvestorId &&
          <OverviewModal onRequestClose={ () => setCurrentInvestorId(null) }>
            <InvestorOverview filterValue={filterValue} investorId={currentInvestorId} />
          </OverviewModal>
        }
      </div>
    </div>
  </LoadingOverlay>
  )
}
const listTable=(filterValue, data, setCurrentInvestorId, setBulkReminderEmailUserIds, listMeta)=>{
  switch (filterValue) {
    case 'users':
      return <UserListTable investors={data} onClickRow={setCurrentInvestorId} listMeta={listMeta} />
    case 'unconfirmed':
      return <IncompleteListTable investors={data} onRowSelect={setBulkReminderEmailUserIds} listMeta={listMeta} />
    case 'members':
      return <MemberListTable investors={data} onClickRow={setCurrentInvestorId} />
    case 'advocates':
      return <AdvocateListTable investors={data} onClickRow={setCurrentInvestorId} />
    case 'investments':
      return <InvestmentsTable investments={data} onClickRow={setCurrentInvestorId} />
    case 'self_attested':
      return <UserListTable investors={data} onClickRow={setCurrentInvestorId} />
    case 'archived':
      return <ArchivedUsersListTable investors={data} onClickRow={setCurrentInvestorId} listMeta={listMeta} />
    case 'verified':
      return <VerifiedAccreditedListTable investors={data} onClickRow={setCurrentInvestorId} />
    case 'preoffer_subscribers':
      return <PrelaunchSubscribersTable subscribers={data} onClickRow={setCurrentInvestorId} />
    case '':
      return <InvestorListTable investors={data} onClickRow={setCurrentInvestorId} />
    default :
    break;
  }
}

export function IncompleteListTable({investors, onRowSelect, listMeta}) {
  const confirmEmailModal = React.useContext(PortalizedModalContext);
  const deleteUserModal = React.useContext(PortalizedModalContext);
  const confirmEmailMutation = useMutation(
    confirmEmail,
    {
      onSuccess: () => {
        ToastMessage.success("User email confirmed.")
        list("unconfirmed", "", listMeta.page, DEFAULT_PAGE_SIZE)
      },
      onError: (error) => {
        ToastMessage.error('Could not confirm user email.')
        renderRailsErrors(error)
      },
    }
  )
  const deleteUserMutation = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        ToastMessage.success("User Deleted")
        list("unconfirmed", "", listMeta.page, DEFAULT_PAGE_SIZE)
      },
      onError: (error) => {
        ToastMessage.error('Could not delete user.')
        renderRailsErrors(error.message)
      },
    }
  )
  
  const onDeleteUser = (investorId) => {
    deleteUserModal.setModalContent(
      'Delete User',
      <div>
        <p>Are you sure you want to delete user? This can't be undone</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteUserMutation.mutate({ userId: investorId })
              deleteUserModal.closeModal()
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={deleteUserModal.closeModal}>Cancel</button>
        </div>
      </div>,
      'sm'
    );
    deleteUserModal.openModal();
  }    

  const onConfirmEmail = (investorId) => {
    confirmEmailModal.setModalContent(
      'Confirm User Email',
      <div>
        <p>Are you sure you want to confirm user email manually?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              confirmEmailMutation.mutate({ userId: investorId })
              confirmEmailModal.closeModal()
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={confirmEmailModal.closeModal}>Cancel</button>
        </div>
      </div>,
      'sm'
    );
    confirmEmailModal.openModal();
  }

  const incompleteInvestorsColumn = React.useMemo(
    () => {
      return [
        {
          Header: "Name",
          className: "d-flex align-items-center",
          Cell: ({ row }) => (
            <>
              <FaUserAstronaut color="#676767" className="mr-2 astronaut-icon" />
              <div className="investor-name">{toTitlecase([row.original.first_name, row.original.last_name].filter(Boolean).join(" "))|| "-"}</div>
            </>
          )
        },
        {
          Header: "Email",
          accessor: "email",
          className: "d-flex align-items-center",
          Cell: ({value}) => value || "-"
        },
        {
          Header: "Signed up on",
          accessor: "created_at",
          className: "d-flex align-items-center",
          Cell: ({value}) => formatDate(value)  || "-"
        },
        {
          Header: "Confirmed",
          accessor: "confirmed_at",
          className: "d-flex align-items-center text-center",
          style: { flexBasis: "100px", minWidth: "fit-content" },
          Cell: ({value}) => value ? "Yes" : "No"
        },
        {
          Header: "",
          accessor:'id',
          Cell: ({ row }) => (
            <TableActionsContainer>
                <TableActionItem tooltipText="Resend Link" onClick={(e)=>{  e.stopPropagation(); resendEmail(row.original.email);}}>
                  <FaEnvelope />
                </TableActionItem>
                <TableActionItem tooltipText="Confirm Email" onClick={(e) => { e.stopPropagation(); onConfirmEmail(row.original.id);}}>
                <FaCheckCircle />
                </TableActionItem>
                <TableActionItem tooltipText="Delete User" onClick={(e) => { e.stopPropagation(); onDeleteUser(row.original.id);}}>
                <FaTrash />
                </TableActionItem>                
            </TableActionsContainer>
          )
        }
      ]
    }, []);

  return (
    <ResponsiveTableWithRowSelect
      columns={incompleteInvestorsColumn}
      data={investors || []}
      setSelectedRows={(items) => onRowSelect(items)}
      loading={false}
      loadingMsg="Loading investors..."
      noDataMsg="No investors found."
    />
  );
}

export function UserListTable({investors, onRowSelect, onClickRow, listMeta}) {
  const archiveUserModal = React.useContext(PortalizedModalContext);
  const deleteUserModal = React.useContext(PortalizedModalContext);
  const archiveUserMutation = useMutation(
    archiveUser,
    {
      onSuccess: () => {
        ToastMessage.success("User archived.")
        list("users", "", listMeta.page, DEFAULT_PAGE_SIZE)
        methods.reset();
        archiveUserModal.closeModal();
      },
      onError: (error) => {
        ToastMessage.error('Could not archive user email.')
        renderRailsErrors(error.message)
        methods.reset();
        archiveUserModal.closeModal();
      },
    }
  )
  const deleteUserMutation = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        ToastMessage.success("User Deleted.")
        list("users", "", listMeta.page, DEFAULT_PAGE_SIZE)
        archiveUserModal.closeModal();
      },
      onError: (error) => {
        ToastMessage.error('Could not delete user.')
        renderRailsErrors(error.message)
      },
    }
  )  

  const archiveUserValidationSchema = Yup.object().shape({
    reason: Yup.string().required()
  });

  const methods = useForm({
    resolver: yupResolver(archiveUserValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    criteriaMode: "firstError",
    defaultValues: {
      reason: "",
    },
  });  

  const onArchiveEmail = (investorId) => {
    const onSubmit = (values) => {
      archiveUserMutation.mutate({ userId: investorId, values })
    }
    archiveUserModal.setModalContent(
      'Archive User',
      <>
        <p>
          Please provide a reason for archiving user
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <HookForm.TextInput
                    name="reason"
                    placeholder="Reason for archiving"
                  />
                </div>
              </div>
            <div className="portalized-modal-footer">
              <button
                type="submit"
                disabled={archiveUserMutation.isLoading}
                className="btn-black"
              >
                Archive
              </button>
              <button
                className="btn-black-outline"
                onClick={() => {
                  methods.reset();
                  archiveUserModal.closeModal();
                }}
              >
                Close
              </button>
            </div>
          </form>
        </FormProvider>
      </>,
      'sm'
    );
    archiveUserModal.openModal();
  }

  const onDeleteUser = (investorId) => {
    deleteUserModal.setModalContent(
      'Delete User',
      <div>
        <p>Are you sure you want to delete user? This can't be undone</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              deleteUserMutation.mutate({ userId: investorId })
              deleteUserModal.closeModal()
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={deleteUserModal.closeModal}>Cancel</button>
        </div>
      </div>,
      'sm'
    );
    deleteUserModal.openModal();
  }  

  const usersColumn = React.useMemo(
    () => {
      return [
        {
          Header: "Name",
          className: "d-flex align-items-center",
          Cell: ({ row }) => (
            <>
              <FaUserAstronaut color="#676767" className="mr-2 astronaut-icon" />
              <div className="investor-name">{toTitlecase([row.original.first_name, row.original.last_name].filter(Boolean).join(" "))|| "-"}</div>
            </>
          )
        },
        {
          Header: "Email",
          accessor: "email",
          className: "d-flex align-items-center",
          Cell: ({value}) => value || "-"
        },
        {
          Header: "Signed up on",
          accessor: "created_at",
          className: "d-flex align-items-center",
          Cell: ({value}) => formatDate(value)  || "-"
        },
        {
          Header: "Sign up method",
          accessor: "signup_mode",
          className: "d-flex align-items-center",
          Cell: ({value}) =>  value || "-"
        },                  
        {
          Header: "",
          accessor:'id',
          Cell: ({ row }) => (
            <TableActionsContainer>
                <TableActionItem tooltipText="Archive User" onClick={(e)=>{  e.stopPropagation(); onArchiveEmail(row.original.id); }}>
                  <FaArchive />
                </TableActionItem>
                <TableActionItem tooltipText="Delete User" onClick={(e) => { e.stopPropagation(); onDeleteUser(row.original.id);}}>
                <FaTrash />
                </TableActionItem>
            </TableActionsContainer>
          )
        }        
      ]
    }, []);

  return (
    <ResponsiveTable
      onRowClick={(item) => onClickRow ? onClickRow(item.id) : null }
      columns={usersColumn}
      data={investors || []}
      setSelectedRows={(items) => onRowSelect(items)}
      loading={false}
      loadingMsg="Loading investors..."
      noDataMsg="No investors found."
    />
  );
}

export function ArchivedUsersListTable({investors, onRowSelect, onClickRow, listMeta}) {
  const unArchiveUserModal = React.useContext(PortalizedModalContext);
  const unArchiveUserMutation = useMutation(
    unArchiveUser,
    {
      onSuccess: () => {
        ToastMessage.success("User removed from archive")
        list("archived", "", listMeta.page, DEFAULT_PAGE_SIZE)
        unArchiveUserModal.closeModal();
      },
      onError: (error) => {
        ToastMessage.error('Could not unarchive user')
        renderRailsErrors(error.message)
      },
    }
  )

  const onUnArchiveEmail = (investorId) => {
    unArchiveUserModal.setModalContent(
      'Activate User - Remove from Archive',
        <div>
        <p>Are you sure you want remove use from Archive?</p>
        <div className="portalized-modal-footer">
          <button className="btn-black"
            onClick={() => {
              unArchiveUserMutation.mutate({ userId: investorId })
              unArchiveUserModal.closeModal()
            }}
          >Confirm</button>
          <button className="btn-black-outline" onClick={unArchiveUserModal.closeModal}>Cancel</button>
        </div>
      </div>,
      'sm'
    );
    unArchiveUserModal.openModal();
  }

  const archivedUsersColumn = React.useMemo(
    () => {
      return [
        {
          Header: "Name",
          className: "d-flex align-items-center",
          Cell: ({ row }) => (
            <>
              <FaUserAstronaut color="#676767" className="mr-2 astronaut-icon" />
              <div className="investor-name">{toTitlecase([row.original.first_name, row.original.last_name].filter(Boolean).join(" "))|| "-"}</div>
            </>
          )
        },
        {
          Header: "Email",
          accessor: "email",
          className: "d-flex align-items-center",
          Cell: ({value}) => value || "-"
        },
        {
          Header: "Signed up on",
          accessor: "created_at",
          className: "d-flex align-items-center",
          Cell: ({value}) => formatDate(value)  || "-"
        },
        {
          Header: "Reason",
          accessor: "reason_archived",
          className: "d-flex align-items-center",
          Cell: ({value}) => value  || "-"
        },              
        {
          Header: "",
          accessor:'id',
          Cell: ({ row }) => (
            <TableActionsContainer>
                <TableActionItem tooltipText="Remove from Archive" onClick={(e)=>{  e.stopPropagation(); onUnArchiveEmail(row.original.id); }}>
                  <FaArchive />
                </TableActionItem>
            </TableActionsContainer>
          )
        }        
      ]
    }, []);

  return (
    <ResponsiveTable
      onRowClick={(item) => onClickRow ? onClickRow(item.id) : null }
      columns={archivedUsersColumn}
      data={investors || []}
      setSelectedRows={(items) => onRowSelect(items)}
      loading={false}
      loadingMsg="Loading investors..."
      noDataMsg="No investors found."
    />
  );
}

export function MemberListTable({investors, onClickRow}) {
  return (
    <table className="table table-borderless table-hover table-row-clickable">
      <thead>
        <tr>
          <th scope="col" className="py-0 fs-10">Name</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Signed up on</th>
          <th scope="col" className="py-0 fs-10">Inv. acct created</th>
          <th scope="col" className="py-0 fs-10">Limit</th>
          <th scope="col" className="py-0 fs-10">City</th>
          <th scope="col" className="py-0 fs-10">State</th>
          <th scope="col" className="py-0 fs-10">Entity type</th>
          <th scope="col" className="py-0 fs-10">Annual income&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Net worth&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Outside inv&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Accredited</th>
          <th scope="col" className="py-0 fs-10">Title</th>
          <th scope="col" className="py-0 fs-10">Industry</th>
        </tr>
      </thead>
      <tbody>
      {investors?.length ?
        investors?.map( (investor,index) => {
          return <MemberRow key={index} {...investor} onClick={() => onClickRow ? onClickRow(investor.id) : null }/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No investors.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export function VerifiedAccreditedListTable({investors, onClickRow}) {
  return (
    <table className="table table-borderless table-hover table-row-clickable">
      <thead>
        <tr>
          <th scope="col" className="py-0 fs-10">Name</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Signed up on</th>
          <th scope="col" className="py-0 fs-10">Inv. acct created</th>
          <th scope="col" className="py-0 fs-10">City</th>
          <th scope="col" className="py-0 fs-10">State</th>
          <th scope="col" className="py-0 fs-10">Entity type</th>
          <th scope="col" className="py-0 fs-10">Accredited</th>
        </tr>
      </thead>
      <tbody>
      {investors?.length ?
        investors?.map( (investor,index) => {
          return <VerifiedAccreditedRow key={index} {...investor} onClick={() => onClickRow ? onClickRow(investor.id) : null }/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No investors.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export function InvestmentsTable({ investments }) {
  return (
    <table className="table table-borderless table-hover">
      <thead>
        <tr>
          <th scope="col" className="py-0 fs-10">Full legal name</th>
          <th scope="col" className="py-0 fs-10">Inv. amount&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Created at</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Country</th>
          <th scope="col" className="py-0 fs-10">State</th>
          <th scope="col" className="py-0 fs-10">City</th>
          <th scope="col" className="py-0 fs-10">Address</th>
          <th scope="col" className="py-0 fs-10">Accredited</th>
          <th scope="col" className="py-0 fs-10">Status</th>
        </tr>
      </thead>
      <tbody>
      {investments?.length ?
        investments?.map( (investment,index) => {
          return <InvestmentRow key={index} {...investment}/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No investment.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export function AdvocateListTable({investors, onClickRow}) {
  return (
    <table className="table table-borderless table-hover table-row-clickable">
      <thead>

        <tr>
          <th scope="col" className="py-0 fs-10">Name</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Signed up on</th>
          <th scope="col" className="py-0 fs-10">Inv. acct created</th>
          <th scope="col" className="py-0 fs-10">City</th>
          <th scope="col" className="py-0 fs-10">State</th>
          <th scope="col" className="py-0 fs-10">Entity type</th>
          <th scope="col" className="py-0 fs-10">Number of inv.</th>
          <th scope="col" className="py-0 fs-10">Total inv.&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Average inv.&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Title</th>
          <th scope="col" className="py-0 fs-10">Industry</th>
          <th scope="col" className="py-0 fs-10">Outside inv.&nbsp;<CurrencyBadge /></th>
        </tr>
      </thead>
      <tbody>
      {investors?.length ?
        investors?.map( (investor,index) => {
          return <AdvocateRow key={index} {...investor} onClick={() => onClickRow ? onClickRow(investor.id) : null }/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No investors.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export function PrelaunchSubscribersTable({subscribers, onClickRow}) {
  return (
    <table className="table table-borderless table-hover table-row-clickable">
      <thead>
        <tr>
          <th scope="col" className="py-0 fs-10">Name</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Signed up on</th>
          <th scope="col" className="py-0 fs-10">First subscription at</th>
          <th scope="col" className="py-0 fs-10">Subscription count</th>
          <th scope="col" className="py-0 fs-10">Total interest Amt.&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Average interest Amt.&nbsp;<CurrencyBadge /></th>
        </tr>
      </thead>
      <tbody>
      {subscribers?.length ?
        subscribers?.map( (subscriber,index) => {
          return <PrelaunchSubscriberRow key={index} {...subscriber} onClick={() => onClickRow ? onClickRow(subscriber.id) : null }/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No subscribers.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export function InvestorListTable({investors, onClickRow}) {
  return (
    <table className="table table-borderless table-hover table-row-clickable">
      <thead>
        <tr>
          <th scope="col" className="py-0 fs-10">Name</th>
          <th scope="col" className="py-0 fs-10">Email</th>
          <th scope="col" className="py-0 fs-10">Service Provider</th>
          <th scope="col" className="py-0 fs-10">Signed up on</th>
          <th scope="col" className="py-0 fs-10">Inv. acct created</th>
          <th scope="col" className="py-0 fs-10">First inv. at</th>
          <th scope="col" className="py-0 fs-10">City</th>
          <th scope="col" className="py-0 fs-10">State</th>
          <th scope="col" className="py-0 fs-10">Entity type</th>
          <th scope="col" className="py-0 fs-10">Number of inv.</th>
          <th scope="col" className="py-0 fs-10">Total inv.&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Average inv.&nbsp;<CurrencyBadge /></th>
          <th scope="col" className="py-0 fs-10">Accredited</th>
        </tr>
      </thead>
      <tbody>
      {investors?.length ?
        investors?.map( (investor,index) => {
          return <InvestorRow key={index} investor={investor} onClick={() => onClickRow ? onClickRow(investor.id) : null }/>
        }) :
        <tr>
          <td className="text-center" colSpan="20">
            No investors.
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
}
