import React from 'react';
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
const restrictGA =
process.env.REACT_APP_ENVIRONMENT == 'development' ||
process.env.REACT_APP_ENVIRONMENT == 'next' ||
process.env.REACT_APP_ENVIRONMENT == 'sandbox' ||
process.env.REACT_APP_ENVIRONMENT == 'staging';

// should be refactored out of app
export const Event = (category, action, label) => {
  if (restrictGA) return console.log("development mode GA event");
  console.log(`GA EVENT ${category} ${action} ${label} was ran`);
  ReactGA.event({
    action: action,
    category: category,
    label: label
  });
};

export const runGAEvent = (category, action, label) => {
  if (restrictGA) return console.log("development mode GA event");
  console.log(`GA_EVENT: ${category}, ${action}, ${label} was ran`);
  ReactGA.event({
    action: action,
    category: category,
    label: label
  });
}


export const runGAEventWithValue = (category, action, label, value) => {

  if (restrictGA) return console.log("development mode GA event");

  ReactGA.initialize('UA-170798936-1');
  const numValue = parseInt(value);
 
  console.log(`GA_EVENT: ${category}, ${action}, ${label} was ran with ${numValue}`);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: numValue
  });
}