import PropTypes from "prop-types";
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { FaTwitterSquare, FaLinkedin, FaFacebookSquare } from "react-icons/fa";

const ShareButtons = ({offering, targetUrl, showTitle=false}) => {

  return (
      <div>
      {showTitle && <div className="fs-19 f-white weight-400 mb-1">Share to:&nbsp;</div>}
        <FacebookShareButton
          url={targetUrl}
          className="mr-1"
          quote={offering.message_fb || document.title}
        >
          <FaFacebookSquare className="petition-social-icons" />
        </FacebookShareButton>
      
        <TwitterShareButton
          url={targetUrl}
          className="mr-1"
          title={offering.message_twitter || document.title}
          via="SpacedVentures"
        >
          <FaTwitterSquare className="petition-social-icons" />
        </TwitterShareButton>
        <LinkedinShareButton
          title={document.title}
          summary={offering.message_linkedin || document.title}
          source="SpacedVentures.com"
          url={targetUrl}
        >
          <FaLinkedin className="petition-social-icons" />
        </LinkedinShareButton>
      </div>
  );
}

ShareButtons.defaultProps = {
  targetUrl: window.location.href,
};

ShareButtons.propTypes = {
  offering: PropTypes.object.isRequired,
};
export default ShareButtons;
