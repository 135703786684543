import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useQueryClient, useMutation } from 'react-query';
import creditCardType from 'credit-card-type';
import { preventDefaultSubmit, scrollIntoView } from '../../../jsUtils/formUtils';
import FormEffect from '../shared/FormEffect'
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover, FaCreditCard } from 'react-icons/fa';
import { toTitlecase } from 'jsUtils/stringUtils';
import { addCcAccount } from "../../../investor/queries";
import ToastMessage from "components/ToastMessage";
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import "./CreditCardPaymentForm.scss";

const ccFormSchemaGenerator = () =>{
  const currentYear = (new Date()).getFullYear().toString().slice(2)

  return (
    Yup.object().shape({
      billing_name: Yup.string()
        .required("Full name is required")
        .max(255, "Entity name too long, max-length 255 characters"),

      credit_card_number: Yup.string()
        .required('Credit card is number required')
        .matches(/^[0-9]+$/, 'Must only consist of digits')
        // matches VISA|MasterCard|Discover1|Discover2
        .matches(/^4[0-9]{12}(?:[0-9]{3})|5[1-5][0-9]{14}|^(?:6011\d{12})|(?:65\d{14})$/, 'Invalid Credit Card or Card Issuer not supported'),

      credit_card_exp_month: Yup.number()
        .min(1,'Invalid Input')
        .max(12,'Invalid Input')
        .required('Expiry month (MM) is required.'),

      credit_card_exp_year: Yup.number()
        .min(currentYear,'Invalid Input')
        .max(99,'Expiry year (YY) is required.')
        .required('Expiry year (YY) is required.'),

      credit_card_cvv: Yup.string()
        .required("Security code is required")
        .matches(/^[0-9]+$/, 'Must only consist of digits')
        .length(3,'Must be exactly 3 digits'),

      agree: Yup.boolean()
        .oneOf([true], 'Please agree to continue'),
    })
  )
};

function shortCardType(cardNumber) {
  const cardType = creditCardType(cardNumber)[0];

  if(!cardType) { return }

  const values = {
    'visa': 'VI',
    'mastercard': 'MC',
    'american-express': 'AMEX',
    'discover': 'DI'
  }

  return values[cardType.type];
}

function CreditCardTypeIcon({cardNumber}){
  if(!cardNumber) {
    return (<FaCreditCard className='color-grey'/>);
  }

  const cardType = creditCardType(cardNumber)[0];

  if(cardType?.type === 'visa'){
    return (<FaCcVisa className="color-black" />)
  }

  if(cardType?.type === 'mastercard'){
    return (<FaCcMastercard className="color-black" />)
  }

  if(cardType?.type === 'american-express'){
    return (<FaCcAmex className="color-black" />)
  }

  if(cardType?.type === 'discover'){
    return (<FaCcDiscover className="color-black" />)
  }

  return (<FaCreditCard className='color-grey'/>);
}

export default function CcForm({
  clientId,
  submitButtonText = "Submit",
  subHeader = "",
  onSuccess = null,
  onError = null,
  extras = null
}){
  const queryClient = useQueryClient();
  const addCcMutation = useMutation(addCcAccount, {
    onSuccess: (creditCard) => {
      typeof onSuccess === "function" && onSuccess(creditCard);
      queryClient.refetchQueries(['client-payment-methods', clientId])
      ToastMessage.success("Credit card added.");
    },
    onError: (error) => {
      typeof onError === "function" && onError(error);
      renderRailsErrors(error);
    }
  })

  return (
      <div className="credit-card-formbox">
       <Formik
          initialValues={{
            billing_name: '',
            credit_card_number :'',
            credit_card_cvv: '',
            credit_card_exp_month: '',
            credit_card_exp_year: '',
            credit_card_type: '',
            agree: false,
          }}
          validationSchema={ccFormSchemaGenerator}
          onSubmit={async (values) => {
            values.credit_card_type = shortCardType(values.credit_card_number)
            if (extras) {
              values = {
                ...values,
                ...extras,
              }
            }
            addCcMutation.mutate({
              clientId, values
            })
          }}
        >
          {({ values, touched, errors, submitCount, isSubmitting, isValid, handleChange }) => (
            <Form onKeyDown={preventDefaultSubmit}>
              <FormEffect
                submitCount={submitCount}
                isValid={isValid}
                isSubmitting={isSubmitting}
                errors={errors}
                name="agree"
                onSubmissionError={() => scrollIntoView("info-certify-checkbox")}
              />
              <div className="container pl-0">
              { subHeader && <span className="zeitung-micro fs-10 weight-300 instruction-text-for-mobile">{subHeader}</span>}
                <div className="mt-3">
                  <div className="form-label-group">
                    <Field
                      className={`form-control ${touched?.billing_name && errors?.billing_name ? "is-invalid" : ""}`}
                      type="text"
                      id="billing_name"
                      name="billing_name"
                      placeholder="Full Name"
                    />
                    <label htmlFor="billing_name">Full name</label>
                    <ErrorMessage name="billing_name" component="div" className="invalid-feedback text-left" />
                  </div>
                  <div className="d-flex align-items-start">
                    <div className="form-label-group flex-1">
                      <Field
                        className={`form-control ${touched?.credit_card_number && errors?.credit_card_number ? "is-invalid" : ""}`}
                        type="text"
                        id="credit_card_number"
                        name="credit_card_number"
                        placeholder="Credit Card Number"
                      />
                      <label htmlFor="credit_card_number">Credit Card Number</label>
                      <ErrorMessage name="credit_card_number" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="card-icons mb-3 ml-2">
                      <CreditCardTypeIcon cardNumber={values.credit_card_number}/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-label-group">
                        <Field
                          type="number"
                          id="credit_card_exp_month"
                          name="credit_card_exp_month"
                          className={`form-control mr-2  ${touched?.credit_card_exp_month && errors?.credit_card_exp_month ? "is-invalid" : ""}`}
                          placeholder="Expiry Date (MM)"
                        />
                        <label htmlFor="credit_card_exp_month">Expiry (MM)</label>
                        <ErrorMessage name="credit_card_exp_month" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-label-group">
                        <Field
                          type="number"
                          id="credit_card_exp_year"
                          name="credit_card_exp_year"
                          className={`form-control ${touched?.credit_card_exp_year && errors?.credit_card_exp_year ? "is-invalid" : ""}`}
                          placeholder="Expiry Date (YY)"
                        />
                        <label htmlFor="credit_card_exp_year" >Expiry (YY)</label>
                        <ErrorMessage name="credit_card_exp_year" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <Field
                          className={`form-control ${touched?.credit_card_cvv && errors?.credit_card_cvv ? "is-invalid" : ""}`}
                          type="text"
                          id="credit_card_cvv"
                          name="credit_card_cvv"
                          placeholder="Security Code (CCV)"
                        />
                        <label htmlFor="credit_card_cvv">Security code (CCV)</label>
                        <ErrorMessage name="credit_card_cvv" component="div" className="invalid-feedback text-left" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="hint poppins weight-300 m-t-10 instruction-text-for-mobile" style={{ fontSize: 13, lineheight: 19, color: "#777777" }}>
                  By checking this box you authorize this transaction, agree to our terms of use and EFT disclosure and that you understand that the fees associated with this transaction are non-refundable. Full details on terms of use and EFT disclosure can be reviewed
                    <Link to="/payments-disclaimer" target="_blank"> here</Link>.</p>
                  <label className={`custom-checkbox form-group d-flex flex-wrap position-relative mt-4 ${errors.terms ? 'is-invalid' : ''}`}>
                    <input id="info-certify-checkbox" type="checkbox" name="agree" className="mr-2" onChange={handleChange} />
                    <span className="custom-checkbox-check"></span>
                    <span className="custom-checkbox-tagline" style={{ fontSize: 13, lineheight: 19, color: "#777777" }}>I authorize this transaction and agree to EFT terms of use</span>
                    <div style={{ marginLeft: '32px' }} className="failed-error-message">{errors.agree}</div>
                  </label>
                </div>
                <div className="portalized-modal-footer">
                  <button type='submit' disabled={addCcMutation.isLoading} className="btn-black">{submitButtonText}</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
  )
}