import React, { useContext } from "react";
import { useQueryClient, useMutation } from 'react-query';
import ToastMessage from "../../../components/ToastMessage";
import { PortalizedModalContext } from "../../../contexts/portalizedModalContext";
import { requestAccreditationVerification } from "./queries";

export default function ActionButtons({ accreditationRequest }) {
  const queryClient = useQueryClient();
  const requestAiReviewModel = useContext(PortalizedModalContext);

  const requestAiReviewMutation = useMutation(() => requestAccreditationVerification(accreditationRequest.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["accreditation_request", accreditationRequest.id], { exact: false });
        ToastMessage.success('Accreditation vetification successfully re-requested');
      },
      onError: (error, variables, context) => {
        ToastMessage.error(
          error?.error || 'Something went wrong. Please try after sometime.'
        );
      },
      onSettled: requestAiReviewModel.closeModal
    }
  );

  const updateAiReviewHandler = ({ mutation, message, description }) => {
    requestAiReviewModel.setModalContent(
      message,
      <>
        <p>Are you sure you want to {message}?</p>
        <p>{description}</p>
        <div className="portalized-modal-footer">
          <button
            disabled={mutation.isLoading}
            className="btn-black"
            onClick={() => mutation.mutate()}
          >
            Confirm
          </button>
          <button
            className="btn-black-outline"
            onClick={() => { requestAiReviewModel.closeModal() }}
          >
            Close
          </button>
        </div>
      </>,
      "sm"
    );
    requestAiReviewModel.openModal();
  };

  const requestAiReviewDesc = "Accredited Verification costs $30 for each request. Please ensure all documents required are ready for review.";

  return (
    <div className="w-100 text-right">

      <button
        disabled={requestAiReviewMutation.isLoading}
        onClick={() => updateAiReviewHandler({
          mutation: requestAiReviewMutation,
          message: 'Request AI Review',
          description: requestAiReviewDesc
        })}
        className="btn btn-outline-dark text-capitalize d-inline-flex align-items-center justify-content-center ml-2"
      >
        Request AI Review
      </button>
    </div>
  )
}
