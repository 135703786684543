import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import { pitchProblemIcon } from '../../../../assets/images';

function ProblemSection({ 
  pitch:{problem_section}
}){
  if(!problem_section) return null;

  return (
    <div id="problem-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'problem'} />
        <div className="pitch-section-body">
          <div className="content-summary">
            <div className='content-summary-icon'>
              <img src={pitchProblemIcon} alt="content summary icon" />
            </div>
            <div className='content-summary-text'>{problem_section.title}</div>
          </div>
            <ArticleContent content={problem_section.content}/>
        </div>
      </div>
    </div>
  )
}

export default ProblemSection;