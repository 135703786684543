import React from "react";

export default function usePortalizedModal() {
  const [modal, setModal] = React.useState(false);
  const [content, setContent] = React.useState({});

  const setModalContent = (title, content, size, modalType) => setContent({title, content, size, modalType});
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false)

  return { modal, openModal, closeModal, content, setModalContent };
};
