import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { phoneNumberMask } from 'jsUtils/stringUtils';
import ErrorMessage from './ErrorMessage';

const PhoneField= ({name, placeholder="Phone number*"}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="form-label-group">
          <MaskedInput
            {...field}
            mask={phoneNumberMask}
            id={field.name}
            placeholder={placeholder}
            type="text"
            className={`form-control ${isTouched && invalid ? "is-invalid" : ""}`}
          />
          <label htmlFor={field.name}>{placeholder}</label>
          <ErrorMessage error={error}/>
        </div>
      )}
    />
  )
}

PhoneField.defaultProps = {};

PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default PhoneField;