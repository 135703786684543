import axiosClient from 'config/axiosClient';

export const teamMemberApi = {
  list,
  create,
  update,
  purge,
};

function list(companyId) {
  return axiosClient.get(`/companies/${companyId}/team_members/`);
}

function create(companyId, attrs) {
  return axiosClient.post(`/companies/${companyId}/team_members/`, attrs);
}

function update(companyId, teamMemberId, attrs) {
  return axiosClient.put(`/companies/${companyId}/team_members/${teamMemberId}`, attrs);
}

function purge(companyId, teamMemberId) {
  return axiosClient.delete(`/companies/${companyId}/team_members/${teamMemberId}`);
}
