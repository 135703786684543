import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessage from './ErrorMessage';

const SITE_KEY = "6LflgKUZAAAAAJfjzKlNyMPO5UAHgrXOWCsYYJXp"

const GoogleRecaptcha= ({name}) => {
  const { control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field, //{ onChange, onBlur, value, name, ref }
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            sitekey={SITE_KEY}
            onChange={field.onChange}
          />
          <ErrorMessage error={error}/>
        </>
      )}
    />
  )
}

GoogleRecaptcha.defaultProps = {};

GoogleRecaptcha.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default GoogleRecaptcha;
