import React from "react";
import { parseJSON, format } from "date-fns";
import AmlSectionsContainer from "./AmlSectionsContainer";
import { ApprovdeBadge, DisapprovedBadge, PendingBadge } from "./Badges";
import VerificationFields from "./VerificationFields";

function DetailedSection({clientCountry, amlException, clientId, clientType }) {
  const issues = amlException.issues.filter(i => i.type === 'kyc');
  const { verification_fields } = amlException;
  const areVerificationReviewedByAdmin = verification_fields.every(field => field.status === "valid" || field.status === "invalid");

  if(issues.length === 0 && !areVerificationReviewedByAdmin){
    return null
  }

  return (
    <AmlSectionsContainer title="Results of KYC Check" tooltipText="The automated Know Your Client (KYC) check confirms that your legal name, birthdate, address and tax id number are accurate.">
      <div className="content-layer bg-golden">
        <b>{format(parseJSON(new Date(amlException.updated_at)), "MMM dd, yyyy hh:mm a")}</b>
        <ul className="mt-2 ml-3">
          {issues.map((issue, index) => (<li key={index}>{issue.message}</li>))}
        </ul>
      </div>
      {areVerificationReviewedByAdmin && clientCountry === "US" &&
        <div className="p-1">
          <VerificationFields clientId={clientId}
            clientType={clientType}
            amlException={amlException}
          />
        </div>
      }
    </AmlSectionsContainer>
  )
}

export default function KYCReviewNote({clientCountry, amlException, clientType, clientId }) {
  const status = amlException.kyc_status;

  return (
    <div className="mt-1 mb-2 p-3 bg-lightgrey">
      <div className="weight-700 mb-2 lh-md d-flex flex-wrap align-items-center">
        <span>KYC Status</span>
        {status === 'pending' && <PendingBadge />}
        {status === 'cleared' && <ApprovdeBadge />}
        {status === 'failed' && <DisapprovedBadge />}
      </div>
      {status !== 'cleared' && <DetailedSection clientCountry={clientCountry} amlException={amlException} clientType={clientType} clientId={clientId} />}
    </div>
  );
}
