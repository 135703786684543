import React from 'react';
import ReactDOM from 'react-dom';
import { history } from 'redux/helpers';
import 'redux/configureStore'
import { Provider } from "hooks-for-redux";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
//import { ReactQueryDevtools } from "react-query/devtools";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga4";

import { Router } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { tagManagerArgs } from './pages/components/Tracking/gtm';
import TagManager from 'react-gtm-module'
import AuthProvider from './contexts/authContext';

TagManager.initialize(tagManagerArgs)
ReactGA.initialize("G-D13CFFNY2S");

localStorage.removeItem("AUTH_TOKEN");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: Infinity,
      useErrorBoundary: true,
      retry: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <Router history={history}>
        <ScrollToTop history={history}/>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <App />
            </AuthProvider>
            {/* <ReactQueryDevtools initialIsOpen /> */}
          </QueryClientProvider>
      </Router >
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
