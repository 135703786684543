import React, { useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useHistory } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { offerUpdateApi } from 'api/offerUpdate';
import { renderRailsErrors } from "jsUtils/railsErrorRenderer";
import { AuthContext } from 'contexts/authContext';
import { PortalizedModalContext } from 'contexts/portalizedModalContext';

export default function LikeButton({offerUpdate}){
  let history = useHistory();
  const { isUserLoggedin } = useContext(AuthContext);
  const loginModal = useContext(PortalizedModalContext);
  const queryClient = useQueryClient();

  const likeMutation = useMutation(offerUpdateApi.like,
    {
      onSuccess: (values) => {
        offerUpdate.likes_count = values.likes_count;
        offerUpdate.user_liked = values.user_liked;
        queryClient.setQueryData(['offerUpdate', offerUpdate.id], offerUpdate);

        const offerUpdates = queryClient.getQueryData(['offerUpdates', offerUpdate.offer_id]);

        if(offerUpdates){
          const index = offerUpdates.findIndex(o => o.id == offerUpdate.id);

          if(index > - 1){
            console.log(index)
            offerUpdates[index] = offerUpdate;
            queryClient.setQueryData(['offerUpdates', offerUpdate.offer_id], offerUpdates);
          }
        }
      },
      onError: (error) => {
        renderRailsErrors(error);
      },
    }
  );

  const likeUpdate = () => {
    if(isUserLoggedin()){
      likeMutation.mutate({ id: offerUpdate.id })
    } else {
      loginModal.setModalContent(
        'Like Offer Update',
        <>
          <p>You must login or sign up to like an offer update.</p>
          <div className="portalized-modal-footer">
            <button className='btn-black' onClick={() => {
              loginModal.closeModal()
              history.push('/login')
            }}>Login</button>
            <button className='btn-black-outline' onClick={() => {
                loginModal.closeModal()
                history.push('/signup')
              }}>Sign Up</button>
          </div>
        </>,
        'sm'
      )
      loginModal.openModal();
    }
  }

  return (
    <button className="btn-plain fs-14 mr-3" onClick={likeUpdate}>
      <FaHeart className={offerUpdate.user_liked && 'color-danger'}/>
        <span className={`ml-1 montserrat ${offerUpdate.user_liked && 'color-danger'}`}>
          { offerUpdate.user_liked ? 'Liked' : 'Like' } {offerUpdate.likes_count > 0 && `(${offerUpdate.likes_count})`}
        </span>
    </button>
  );
}