import React from 'react';
import AmountWithCurrency from '../../../components/AmountWithCurrency';

const DEFAULT_INVESTMENT_LIMIT_AMOUNT = 2200;

export default function InvestmentLimitSidebar ({ investmentLimitInfo, isAccredited=false, isSettingsView=false }) {
  const investmentLimits = [
    {
      title: 'Total investment limit', subtitle: '',
      amount: investmentLimitInfo?.max_investment ?? DEFAULT_INVESTMENT_LIMIT_AMOUNT,
      theme: 'light',
    },
    {
      title: 'Invested on Spaced Ventures', subtitle: '',
      amount: investmentLimitInfo?.current_investment,
      theme: 'light',
    },
    {
      title: 'Invested outside Spaced Ventures', subtitle: '',
      amount: investmentLimitInfo?.outside_investment,
      theme: 'light',
    },
    {
      title: 'Remaining investment limit', subtitle: '',
      amount: investmentLimitInfo?.remaining_investment ?? DEFAULT_INVESTMENT_LIMIT_AMOUNT ,
      theme: 'dark',
    }
  ];

  if (isAccredited){
    return (
      <div key="accredited-limit" className="limit-info-box f-white">
        <div className="info-content text-center px-4 py-3">
          No Limit
        </div>
      </div>
    )
  }

  return (
        investmentLimits.map((limit, index) => (
          <div key={index} className={isSettingsView ? 'col-md-6' : ''}>
            <div key={limit.title} className={`limit-info-box ${limit.theme == 'light' ? 'f-black' : 'f-white'}`}>
              <div className="info-title">{limit.title}</div>
              <div className="info-subtitle">{limit.subtitle}</div>
              <div className="info-content">
                <AmountWithCurrency number={limit.amount} />
              </div>
            </div>
          </div>
        ))
  )
}