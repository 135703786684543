import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from 'react-query';
import { useCurrentOffer, fetchOffer } from 'redux/offers'
import { useUser } from 'redux/user'
import LoadingOverlay from 'react-loading-overlay';
import { history } from 'redux/helpers';
import HookForm from '../components/HookForm';
import { userApi } from '../api/user';
import { formatDate, parseDateWithFormat } from '../jsUtils/dateTimeUtils';
import { updateClient } from '../redux/user';
import { renderRailsErrors } from '../jsUtils/railsErrorRenderer';
import { useClientSuitabilityQuestionaire } from '../investor/queries';

function IdentificationDetails() {
  return (
    <div className='mt-4'>
      <h4 className='montserrat fs-7 weight-900 color-black mb-1'>Additional identification requirements</h4>
      <div className="row">
        <div className="col-12 col-md-6">
          <HookForm.TextInput
            name="id_number"
            placeholder="Passport or ID number"
          />
        </div>
        <div className="col-12 col-md-6">
          <HookForm.TextInput
            name="id_issuer"
            placeholder="Passport or ID issuer"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <HookForm.DateTimeField
            name="id_issue_date"
            placeholder="Passport or ID issue date"
            dateFormat='MM/dd/yyyy'
            showTimeSelect={false}
            showTimeZoneHint={false}
            showMonthSelect={true}
            showYearSelect={true}
          />
        </div>
        <div className="col-12 col-md-6">
          <HookForm.DateTimeField
            name="id_expiration_date"
            placeholder="Passport or ID expiration date"
            dateFormat='MM/dd/yyyy'
            showTimeSelect={false}
            showTimeZoneHint={false}
            showMonthSelect={true}
            showYearSelect={true}
          />
        </div>
      </div>
    </div>
  )
}

function FinancialDetails() {
  return (
    <div className='mt-4'>
      <h4 className='montserrat fs-7 weight-900 color-black mb-1'>Financial info</h4>
      <div className="row">
        <div className="col-12">
          <HookForm.TextInput
            name="occupation"
            placeholder="Occupation"
          />
        </div>
        <div className="col-12">
          <HookForm.TextInput
            name="employer"
            placeholder="Employer"
          />
        </div>
        <div className="col-12">
          <HookForm.TextInput
            name="source_of_fund_for_investment"
            placeholder="Source of funds for investment"
            hint="i.e. Checking, Sale of house, Savings, Income from business, etc."
          />
        </div>
      </div>
    </div>
  )
}

function IncomeSourceDetails() {
  return (
    <div className='mt-4'>
      <div className='montserrat fs-7 weight-900 color-black mb-1'>Suitability</div>
      <div className="row">
        <div className="col-12 col-md-6">
          <HookForm.CurrencyInputField
            name="net_worth"
            placeholder="Current Net Worth"
          />
        </div>
        <div className="col-12 col-md-6">
          <HookForm.CurrencyInputField
            name="liquid_net_worth"
            placeholder="Current Liquid Net Worth"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <HookForm.CurrencyInputField
            name="income"
            placeholder="Current Income"
          />
        </div>
        <div className="col-12 col-md-6">
          <HookForm.TextInput
            name="source_of_income"
            placeholder="Source of Income"
          />
        </div>
      </div>
    </div>
  )
}

function Questionaires() {
  const investmentObjectiveOptions = [
    {
      value: 'Capital Preservation',
      labelTitle: 'Capital preservation',
      label: 'You seek to preserve capital and are willing to accept a lower rate of return in exchange'
    },
    {
      value: 'Income',
      labelTitle: 'Income',
      label: 'You seek investments with periodic distributions in exchange for capital appreciation'
    },
    {
      value: 'Growth & Income',
      labelTitle: 'Growth & Income',
      label: 'you seek a moderate increase in investment value in combination with some income'
    },
    {
      value: 'Growth',
      labelTitle: 'Growth',
      label: 'you seek growth in the value of your investment'
    },
    {
      value: 'Aggressive Growth',
      labelTitle: 'Aggressive Growth',
      label: 'seek investment opportunities with very high growth potential'
    }
  ];
  const investmentExperienceOptions = [
    {
      value: 'Less than 2 years',
      label: 'Less than 2 years'
    },
    {
      value: 'Between 2 and 5 years',
      label: 'Between 2 and 5 years'
    },
    {
      value: 'Between 5 and 10 years',
      label: 'Between 5 and 10 years'
    },
    {
      value: 'Greater than 10 years',
      label: 'Greater than 10 years'
    }
  ];
  const investmentRiskAcceptanceOptions = [
    {
      value: 'Conservative',
      label: 'Conservative'
    },
    {
      value: 'Moderate',
      label: 'Moderate'
    },
    {
      value: 'Significant',
      label: 'Significant'
    },
    {
      value: 'Speculative',
      label: 'Speculative'
    }
  ];
  const investmentAllocationOptions = [
    {
      value: 'None',
      label: 'None'
    },
    {
      value: 'Less than $250,000 USD',
      label: 'Less than $250,000 USD'
    },
    {
      value: 'Greater than $250,000 USD',
      label: 'Greater than $250,000 USD'
    }
  ];
  const annualLivingExpensesOptions = [
    {
      value: 'Less than $50,000',
      label: 'Less than $50,000'
    },
    {
      value: 'Between $50,000 and $100,000',
      label: 'Between $50,000 and $100,000'
    },
    {
      value: 'Greater than $100,000',
      label: 'Greater than $100,000'
    },
    {
      value: 'Not applicable-if investing as an entity',
      label: 'Not applicable-if investing as an entity'
    }
  ];
  const marginalPersonalTaxRateOptions = [
    { value: 'Less than 20%', label: 'Less than 20%' },
    { value: 'Between 20%-30%', label: 'Between 20%-30%' },
    { value: 'Greater than 30%', label: 'Greater than 30%' },
    { value: 'Not applicable', label: 'Not applicable' }
  ];
  const booleanOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];
  const timeHorizonForInvestmentOptions = [
    {
      value: '1-5 years',
      label: '1-5 years'
    },
    {
      value: '6-10 years',
      label: '6-10 years'
    },
    {
      value: 'Over 10 years',
      label: 'Over 10 years'
    }
  ]

  const headerStyle = 'my-3';
  const labelStyle = 'fs-14 color-black ml-3';
  return (
    <div>
      <HookForm.RadioInputGroup
        name="investment_objective"
        header="My overall investment objective:"
        options={investmentObjectiveOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="investment_experience"
        header="My relevant investment experience:"
        options={investmentExperienceOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="investment_risk_acceptance"
        header="My willingness to accept risk for this investment:"
        options={investmentRiskAcceptanceOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="portfolio_investment_allocation_in_equities"
        header="My current portfolio investment allocation for equities:"
        options={investmentAllocationOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="portfolio_investment_allocation_in_bonds"
        header="My current portfolio investment allocation for bonds:"
        options={investmentAllocationOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="portfolio_investment_allocation_in_real_estate"
        header="My current portfolio investment allocation for real estate:"
        options={investmentAllocationOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="portfolio_investment_allocation_in_others"
        header="My current portfolio investment allocation for other investments (private securities, etc.):"
        options={investmentAllocationOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="annual_living_expenses"
        header="My approximate annual living expenses:"
        options={annualLivingExpensesOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="marginal_personal_tax_rate"
        header="My marginal personal tax rate:"
        options={marginalPersonalTaxRateOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="significant_liquid_net_worth"
        header="I have significant liquid net worth, excluding the funds for my investment, to maintain my quality of life and understand that this investment is illiquid, and I may not be able to access invested funds for an extended amount of time."
        options={booleanOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="understand_investment_in_private_security_risk"
        header="I understand that an investment in private securities is very risky, that I may lose all of my invested capital, that it is an illiquid investment with no short-term exit, and that it may not align with my overall investment strategy as answered in Question 1."
        options={booleanOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="time_horizon_for_investment"
        header="What is your time horizon for this investment?"
        options={timeHorizonForInvestmentOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
      <hr />
      <HookForm.RadioInputGroup
        name="invesment_on_my_own_accord"
        header="I have conducted my own due diligence, I am making this investment on my own accord, and I will not hold anyone other than myself responsible for any losses that may result from this investment choice."
        options={booleanOptions}
        headerStyle={headerStyle}
        labelStyle={labelStyle}
      />
    </div>
  )
}

function SelfDeclarationDetails({ signatureValue }) {
  const [signatureAccepted, setSignatureAccepted] = useState(false);

  const onAcceptSignature = () => {
    if(signatureValue && signatureValue.trim().length > 0 && !signatureAccepted){
      setSignatureAccepted(true);
    } else {
      setSignatureAccepted(false);
    }
  }

  return (
    <div>
      <h5 className="fs-default poppins weight-700 my-3">The information above is accurate and has been completed to the best of my ability:</h5>
      <div className="row">
        <div className="col-12">
          <HookForm.TextInput
            name="full_name"
            placeholder="Full name"
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <div className="form-label-group">
            <input
              type="text"
              name="signed_at_display"
              id="signed_at_display"
              placeholder="Date of Signature "
              className="form-control"
              defaultValue={formatDate(new Date(), 'MM/dd/yyyy')}
              readOnly={true}
            />
            <label htmlFor="signed_at_display">Date of Signature </label>
          </div>
        </div>
      </div>
      <div className="row signature-box">
        <div className="col-7">
          <HookForm.TextInput
            name="signature"
            placeholder="Signature"
            inputStyle={`signature-input ${ signatureAccepted ? 'is-valid': '' }`}
            hint="Please accept signature"
          />
        </div>
        <div className="col-5">
          <button type="button" className={`mx-auto btn-black-outline btn-signature rounded-0 weight-600 accept-signature-text ${ signatureAccepted ? 'signature-accepted' : ''}`} onClick={onAcceptSignature}>
            { signatureAccepted ? 'Signature Accepted': 'Accept Signature' }
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ml-0 mt-2">
          <button type="submit" className="mx-auto btn-login px-5" disabled={!signatureAccepted}>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default function SuitabilityQuestionaire() {
  const { offerId, accountId, investmentId } = useParams();
  const offer = useCurrentOffer();
  const user = useUser();
  const client = user && user.clients.find((c) => c.id == accountId);

  useEffect(() => {
    if (offerId && offer?.slug !== offerId) {
      fetchOffer(offerId)
    }
  }, [offerId])

  const schema = Yup.object().shape({
    id_number: Yup.string().required().min(8, 'Invalid format').max(16, 'Invalid format'),
    id_issuer: Yup.string().required(),
    id_issue_date: Yup.date()
      .required()
      .test("id_issue_date_future", "cannot set issue date in future.", function (value) {
        return value ? value < new Date() : true;
      }),
    id_expiration_date: Yup.date()
      .required()
      .test("id_expiration_date_past", "cannot set IDexpiration date in past.", function (value) {
        return value ? value > new Date() : true;
      })
      .test("id_expiration_date_gt_id_issue_date", "ID expiration date must be greater then issue date.", function (value) {
        const idIssueDate = Yup.ref("id_issue_date")
        return value && idIssueDate ? value > this.resolve(Yup.ref("id_issue_date")) : true;
      }),
    occupation: Yup.string().required(),
    employer: Yup.string().required(),
    source_of_fund_for_investment: Yup.string().required(),
    net_worth: Yup.string().required(),
    liquid_net_worth: Yup.string().required(),
    income: Yup.string().required(),
    source_of_income: Yup.string().required(),
    investment_objective: Yup.string().required(),
    investment_experience: Yup.string().required(),
    investment_risk_acceptance: Yup.string().required(),
    portfolio_investment_allocation_in_equities: Yup.string().required(),
    portfolio_investment_allocation_in_bonds: Yup.string().required(),
    portfolio_investment_allocation_in_real_estate: Yup.string().required(),
    portfolio_investment_allocation_in_others: Yup.string().required(),
    annual_living_expenses: Yup.string().required(),
    marginal_personal_tax_rate: Yup.string().required(),
    significant_liquid_net_worth: Yup.boolean().required(),
    understand_investment_in_private_security_risk: Yup.boolean().required(),
    time_horizon_for_investment: Yup.string().required(),
    invesment_on_my_own_accord: Yup.boolean().required(),
    full_name: Yup.string().required(),
    signature: Yup.string().required()
  });

  const { isLoading, isError, data: suitabilityQuestionaire } = useClientSuitabilityQuestionaire(accountId)

  const mutation = useMutation((attrs) => {
    if (suitabilityQuestionaire && suitabilityQuestionaire.id) {
      return userApi.updateSuitabilityQuestionaires(attrs)
    } else {
      return userApi.createSuitabilityQuestionaires(attrs)
    }
  },
    {
      onSuccess: (values) => {
        client.suitability_questionaire_expired = values.expired;
        updateClient(client);

        if (investmentId) {
          history.push(`/offers/${offer.slug}/subscription-agreement/${investmentId}`);
        } else {
          history.push('/account/investor-info');
        }
      },
      onError: (error) => {
        renderRailsErrors(error)
      },
    }
  );

  const onSubmit = (values) => {
    delete values.id
    delete values.updated_at
    delete values.created_at
    delete values.expired
    delete values.signed_at

    if(values.id_issue_date){
      values.id_issue_date = formatDate(values.id_issue_date, 'yyyy-MM-dd')
    }

    if(values.id_expiration_date){
      values.id_expiration_date = formatDate(values.id_expiration_date, 'yyyy-MM-dd')
    }

    mutation.mutate({ clientId: client.id, attrs: { suitability_questionaire: values } });
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'all',
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      full_name: user?.name,
      signature: user?.name
    },
  });

  const watchSignature = methods.watch("signature", user?.name);

  useEffect(() => {
    if (suitabilityQuestionaire && suitabilityQuestionaire.id) {
      suitabilityQuestionaire.id_issue_date = parseDateWithFormat(suitabilityQuestionaire.id_issue_date, 'yyyy-MM-dd');
      suitabilityQuestionaire.id_expiration_date = parseDateWithFormat(suitabilityQuestionaire.id_expiration_date, 'yyyy-MM-dd');
      suitabilityQuestionaire.signature = user?.name;
      methods.reset(suitabilityQuestionaire)
    }
  }, [suitabilityQuestionaire])

  if (isLoading || isError) {
    return null
  }

  return (
    <>
      <LoadingOverlay
        active={user.loading}
        spinner
        text='Loading ...' >
        <div className="investment-header">
          <div className="container pb-3 pt-first-section">
            <h1 className="fs-2 weight-900 text-white mb-0">
              {offerId && <>Invest in <Link to={`/offers/${offerId}`} target="_blank" rel="noopener noreferrer">{offer.company?.name}</Link> </>}
              {!offerId && <>Suitability Questionaires </>}
            </h1>
          </div>
        </div>
        <div className="limit-calc-wrapper limit-calc-screen">
          <div className="limit-calc-screen-header">
            <h5 className="montserrat fs-6 weight-900 color-black">{client.name}'s Suitability Questionnaire</h5>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row limit-calc-content-row">
                <div className="limit-calc-content px-3">
                  <div className="hint zeitung-micro weight-300 mb-4 fs-10 instruction-text-for-mobile">
                  We are required to conduct a suitability questionnaire for {client.name}. Please fill out the below questions.
                  </div>
                  <IdentificationDetails />
                  <FinancialDetails />
                  <IncomeSourceDetails />
                  <Questionaires />
                  <hr />
                  <SelfDeclarationDetails signatureValue={watchSignature}/>
                 { Object.keys(methods.formState.errors).length > 0 && <div className='mt-2 fs-18 invalid-feedback d-block'>Questionnaire is incomplete</div> }
                </div>
                <div className='limit-calc-sidebar px-3'>
                  <h5 className="montserrat weight-700">Why do we need this?</h5>
                  <p className='fs-10'>We collect this info to determine if an investment is appropriate for you based on your financial situation, investment objectives, and risk tolerance.</p>
                  <p className='fs-10'>This questionnaire only needs to be completed once and is valid for 3 years.</p>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </LoadingOverlay>
    </>
  );
}
