import formatMoney from 'accounting-js/lib/formatMoney.js'
import { WIRE_TRANSFER_FEES } from 'config/constants';

export const formatCurrency = (value, ...rest) => {
  if (value || value === 0)
    return formatMoney(value, ...rest)
  else
    return '-'
}
export const getPaymentAmount = (paymentType, invAmount, currentInvestorCountry, adminFee) => {
  let fee = 0;
  if(paymentType === 'wire')
    fee = currentInvestorCountry === 'US' ? WIRE_TRANSFER_FEES.USA : WIRE_TRANSFER_FEES.INTERNATIONAL
  else if(paymentType === 'credit_card')
    fee = (invAmount * (adminFee?.cc || 3.75)) / 100;
  return formatCurrency(parseFloat(invAmount) + fee, { precision: 2 })
}

export const getProcessingFee = (invAmount, paymentType, currentClientCountry, adminFee) => {
  if(paymentType === 'ach')
    return 0;
  else if(paymentType === 'credit_card')
    return (invAmount * adminFee.cc) / 100;
  else if (paymentType === 'wire'){
    if(currentClientCountry === 'US')
     return WIRE_TRANSFER_FEES.USA;
    return WIRE_TRANSFER_FEES.INTERNATIONAL;
  }
  return 0;
}

export const formatMoneyClean = (amount = 0) => {
  const options = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }
  
  // check if it's a clean dollar amount, if so remove trailing 0s
  if (amount % 1 === 0) {
    options.maximumFractionDigits = 0;
  }
  
  const formatter = Intl.NumberFormat('en-us', options);

  return formatter.format(amount)
}