import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { history } from '../../redux/helpers';
import ToastMessage from '../../components/ToastMessage';
import { signSubscriptionAgreement, useRequestSubscriptionAgreementSignature } from '../../investor/queries'
import { toTitlecase } from '../../jsUtils/stringUtils'
import { rollbarInstance } from 'jsUtils/rollbar';
import PageLoadingSpinner from '../../components/PageLoadingSpinner';
import './Page.OfferInvest.scss';
import "./Pages.common.scss";

export default function OfferSubscriptionAgreement() {
  const { investmentId } = useParams();
  const { isLoading, isError, data: agreement } = useRequestSubscriptionAgreementSignature(investmentId);

  if (isLoading) return <PageLoadingSpinner isLoading={isLoading} message="Loading Subscription Agreement..." />;

  if (isError) {
    ToastMessage.error('Could not fetch investment agreement. Please try reloading the page.');
    return <h5 className="montserrat weight-700">Could not fetch investment agreement. Please try reloading the page.'</h5>;
  }

  return (
    <>
      <div className="investment-header">
        <div className="container pb-3 pt-first-section">
          <h1 className="fs-2 weight-900 text-white mb-0 invest-form-header">Invest in {agreement.offer.company_name}</h1>
        </div>
      </div>
      <div className="investor-formbox subscription-agreement-screen">
        <div className="container">
          <div className="row">
            <div className="col-md-12 py-4">
              <h5 className="montserrat weight-700">Sign to Complete Your Investment</h5>
              <p className="fs-10"><b>Please review and sign the subscription agreement</b> to complete your investment.</p>
              <SubscriberDetail agreement={agreement} />
              <EsignBox agreement={agreement} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function EsignBox({ agreement }) {
  const isEditInvestment = history.location.state?.isEditInvestment;
  const [loading, setLoading] = useState(true);
  const investmentId = agreement.investment_id;

  const mutation = useMutation(
    () => signSubscriptionAgreement(investmentId),
    {
      onSuccess: (data, variables, context) => {
        rollbarInstance.info("Signed subscription agreement", { investment_id: investmentId });
        history.push({
          pathname: `/investments/${investmentId}`,
          state: {
            showModal: true,
            companyName: agreement.offer.company_name,
            isEditInvestment: isEditInvestment,
          },
        });
      },
      onError: (error, variables, context) => {
        rollbarInstance.error("Error signing subscription agreement", {
          investment_id: investmentId,
        });
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
  );

  useEffect(() => {
    const handler = (event) => {
      console.log('Docusign page event', investmentId,  event.data);
      rollbarInstance.info("Docusign page event", { investment_id: investmentId, callback_event: event.data });

      if(["signing_complete", "decline", "cancel"].indexOf(event.data) > -1){
        mutation.mutate();
      } else if(event.data === 'ttl_expired'){
        console.log('URL expireed', agreement?.signature_url)
      } else {
        ToastMessage.error('Something went wrong. Please try reloading the page.')
      }
    }
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [investmentId]);

  return (
    <div className="esign-container">
      <PageLoadingSpinner isLoading={mutation.isLoading || loading} message={ mutation.isLoading ? 'Loading...': "Loading Subscription Agreement..." } />
      <iframe
        title="Subscription Agrrement Signature"
        src={agreement.signature_url}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        onLoad={ () => setLoading(false) }
      />
    </div>
  )
}

function InvestorFieldView({ label, value }) {
  return (
    <div className='freez-field mb-2'>
      <label>{label}:</label>
      <div className='freez-field-val'>{value}</div>
    </div>
  )
}

function SubscriberDetail({ agreement }) {
  const isClientCompany = agreement.client_type === 'company';

  return (
    <div className='subscriber-info mt-4'>
      {isClientCompany && <InvestorFieldView label="Signatory Name" value={toTitlecase(agreement.signatory_name)} />}
      <InvestorFieldView label={isClientCompany ? 'Entity Name' : 'Name'} value={toTitlecase(agreement.entity_name)} />
      <InvestorFieldView label="Email" value={agreement.email} />
    </div>
  )
}