import React, { useEffect, useRef, useState } from 'react'
import navDarkLogo from '../../../assets/images/logos/Spaced-logo-full-black.svg';
import navWhiteLogo from '../../../assets/images/logos/Spaced-logo-full-white.svg';
import { NavLink, Link } from 'react-router-dom';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';
import { useLocation } from 'react-router-dom';
import PromotedEventsBanner from '../../../components/PromotedEventsBanner';

const PublicAppToolbar = ({ drawerClickHandler }) => {
  const location = useLocation();
  const [hasPromotedEvents, setHasPromotedEvents] = useState(false);
  const [stickyToolbar, setStickyToolbar] = useState(false);
  const noFixedToolbar = (location?.pathname?.match(/\/offers\/(:\s*)?/g) || location?.pathname?.match(/\/prelaunch\/(:\s*)?/g) || location?.pathname?.match(/\/company\/(:\s*)?/g)) ? 'no-fixed-header' : ''

  useEffect(() => {
    if(checkStickyNavPages()) {
      window.removeEventListener('scroll', scrollEventListener)
      setStickyToolbar(true);
    }else {
      window.addEventListener('scroll', scrollEventListener);
      setStickyToolbar(false);
    }
    return () => {window.removeEventListener('scroll', scrollEventListener);}
  }, [location.pathname]);

  const scrollEventListener = () => {
    const pageScroll = window.pageYOffset;
    if(pageScroll > 0) {
      setStickyToolbar(true);
    } else {
      if(!checkStickyNavPages()) {
        setStickyToolbar(false);
      }
    }
  }

  const checkStickyNavPages = () => {
    if(location?.pathname?.match(/\/offers/g) ||
      location?.pathname?.match(/\/offerings\/(:\s*)?/g) ||
      location?.pathname?.match(/\/investor-details\/offers\/(:\s*)/g) ||
      location?.pathname?.match(/\/spacedbase/g) ||
      location?.pathname?.match(/\/mediakit/g) ||
      location?.pathname?.match(/\/press/g) ||
      location?.pathname?.match(/\/offers\/(:\s*)\/invest\/accounts\/?[1-9]\d*$/g) ||
      location?.pathname?.match(/\/email-verification/g) ||
      location?.pathname?.match(/\/prelaunch\/(:\s*)?/g) ||
      location?.pathname?.match(/\/raise-petitions\/(:\s*)?/g) ||
      location?.pathname?.match(/\/resources/g) ||
      location?.pathname?.match(/\/company\/(:\s*)?/g))
    {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    location?.pathname !== "/disclaimer" && (
      <header id="app-navbar" className={`${stickyToolbar ? 'scrolled' : ''} ${noFixedToolbar} toolbar ${hasPromotedEvents ? 'promoted-event-header' : ''}`}>
        <PromotedEventsBanner setHasPromotedEvents={setHasPromotedEvents}/>
        <nav className="toolbar-navigation">
          <div className="toolbar-toggle-button">
            <DrawerToggleButton drawerClickHandler={drawerClickHandler} />
          </div>
          <div className="toolbar-logo">
            <Link to="/"><img className="nav-logo" src={navDarkLogo} alt="Spaced Ventures Logo" /><div className='nav-logo fs-10 pl-1'>now Mach33</div></Link>
            <Link to="/"><img className="mobile-nav-logo" src={navWhiteLogo} alt="Spaced Ventures Logo" /></Link>
          </div>
          <div className="spacer" />
          <div className='toolbar-navigation-items'>
            <ul className="tile-list public-app-toolbar">
             {/* <li><NavLink to="/offerings" activeClassName="nav-selected">Invest</NavLink></li> */}
              {/* <li>
                <a href="javascript:void(0)">Learn</a>
                <ul className="tile-list-dropdown">
                  <li><a target="_blank" href="https://blog.spacedventures.com/" rel="noopener noreferrer">Blog</a></li>                  
                  <li><NavLink to="/about" activeClassName="nav-selected">About Us</NavLink></li>
                  <li><NavLink to="/support" activeClassName="nav-selected">Support</NavLink></li>
                  <li><NavLink to="/press" activeClassName="nav-selected">Press</NavLink></li>
                  <li><NavLink to="/mediakit" activeClassName="nav-selected">Media Kit</NavLink></li>
                  <li><NavLink to="/newsletter" activeClassName="nav-selected">Newsletter</NavLink></li>
                  
                </ul>
              </li> */}
              {/* <li><NavLink to="/resources" activeClassName="nav-selected">Resources</NavLink></li> */}
              <li><a target="_blank" href="https://www.33fg.com/" className='chlid-list-item mb-2' rel="noopener noreferrer">Mach33</a></li>
              <li><NavLink to="/login" activeClassName="nav-selected">Login</NavLink></li>
              {/* <li className="separate-nav-item signup-nav-button"><NavLink to="/signup">Sign Up</NavLink></li> */}
            </ul>
          </div>
        </nav>
      </header>
    )
  )
};

export default PublicAppToolbar;