import React from 'react';
import ArticleContent from '../ArticleContent';
import PitchSectionLabel from 'admin/components/shared/PitchSectionLabel/PitchSectionLabel';

function CustomerSection({ 
  pitch:{customers_section, customers}
}) {
  if(!customers_section && !customers?.length) return null;
  
  if(customers === null) customers = [];

  return (
    <div id="customers-section-component" className="component-box">
      <div className="pitch-section-width">
        <PitchSectionLabel label={'customers'} />
        <div className="pitch-section-body">
          {
            customers.map(customer => (
                <div className="content-summary">
                  <Customer key={customer.id} customer={customer} />
                </div>
            ))
          }
          {
            customers_section &&
              <ArticleContent content={customers_section.content}/>
          }
        </div>
      </div>
    </div>
  )
}

function Customer({customer}) {
  return (
    <div className="customer">
        <div className="customer-logo-box"><img src={`${customer.logo_url}`} alt="" className="logo"/></div>
        <div className="customer-summary">
          <div className="name">{customer.name}</div>
          <div className="status">{customer.status?.replace(/_/g, ' ')}</div>
        </div>
    </div>
  )
}

export default CustomerSection;