import React from 'react'
// import FileBar from './FileBar'
import PitchSectionLabel from '../../admin/components/shared/PitchSectionLabel/PitchSectionLabel';
import FileBar from '../../admin/components/shared/FileBar';

function FileThumbnail({ name, url, downloadable }) {
  if (!url) {
    return null;
  }

  return (
    <div className='mb-1'>
      <FileBar name={name} url={url} customIcon={true} downloadable={downloadable}/>
    </div>
  )
}

export default function InvestmentDetailDocumentSection({ title, documents, showDocumentText = true }) {
  if (!documents || documents.length === 0) {
    return null;
  }

  return (
    <>
      { title && <h5 className="montserrat weight-700 ">{title}</h5> }
      <div id="documents" className="component-box">
        <div className="pitch-section-width">
          {showDocumentText && <PitchSectionLabel label={'documents'} />}
          <div className="pitch-section-body">
            <ul className="uploaded-files">
              {
                documents.map(doc => {
                  return <FileThumbnail key={doc.id} name={doc.name} url={doc.attachment_url} downloadable={doc.downloadable} />
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}